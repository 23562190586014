/**
* @name annuitantService
* @desc annuitant Service.
**/
(function () {
    'use strict';

    angular
        .module('CareGuard')
        .factory('annuitantService', annuitantService);

    /**
    * @name annuitantService.
    * @desc annuitant Service.
    */
    function annuitantService() {
        var annuitant = undefined;
        var annuitants = undefined;
        var selectedCallback = undefined;
        var updateCallback = undefined;

        var service = {
            getAnnuitant: getAnnuitant,
            setAnnuitant: setAnnuitant,
            getAnnuitants: getAnnuitants,
            setAnnuitants: setAnnuitants,
            setUpdateCallback: setUpdateCallback,
            fireUpdateCallback: fireUpdateCallback
        };

        return service;

        /**
        * @name setCallback.
        * @desc set callback when record is added.
        * @param {function} func - call function when selected annuitant is changed.
        */
        function setUpdateCallback(func) {
            updateCallback = func;
        };

        /**
        * @name fireCallback.
        * @desc fires callback when record is added.
        * @param {object} case - selected annuitant.
        */
        function fireUpdateCallback(caseData) {
            if (updateCallback) {
                updateCallback(caseData);
            };
        };

        /**
        * @name getAnnuitant.
        * @desc return the annuitant.
        */
        function getAnnuitant() {
            return annuitant;
        };

        /**
        * @name setAnnuitant.
        * @desc store the annuitant.
        * @param {object} data - annuitant to be stored.
        */
        function setAnnuitant(data) {
            annuitant = data;
        };

        /**
        * @name setAnnuitants.
        * @desc store annuitants.
        * @param {array} data - annuitants to be stored.
        */
        function setAnnuitants(data) {
            annuitants = data;
        };

        /**
        * @name getAnnuitants.
        * @desc get annuitants.
        */
        function getAnnuitants() {
            return annuitants;
        };
    };
})();