import statusTemplate from './html/billReviewReprocess/statusTemplate.html';

export const billReviewReprocessColumnDefs = [
    {
        field: 'claimId',
        displayName: 'Claim ID',
        headerCellClass: 'grid-component_header',
        width: '15%'
    },
    {
        field: 'createdBy',
        displayName: 'Created By',
        headerCellClass: 'grid-component_header',
        width: '15%'
    },
    {
        field: 'createdDate',
        displayName: 'Created Date',
        headerCellClass: 'grid-component_header',
        width: '15%'
    },
    {
        field: 'status',
        displayName: 'Status',
        headerCellClass: 'grid-component_header',
        width: '15%',
        cellTemplate: statusTemplate
    },
    {
        field: 'reason',
        displayName: 'Bill Review Reprocess Reason',
        headerCellClass: 'grid-component_header'
    },
    {
        field: 'Actions',
        displayName: '',
        width: '2%',
        headerCellClass: 'grid-component_header grid-component_header_actions',
        cellClass: 'grid-component_actions'
    }
];