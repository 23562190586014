/**
* @name beneficiaryService
* @desc beneficiary Service.
**/
(function () {
    'use strict';

    angular
        .module('CareGuard')
        .factory('beneficiaryService', beneficiaryService);

    /**
    * @name beneficiaryService.
    * @desc beneficiary Service.
    */
    function beneficiaryService() {
        var annuitants = undefined;
        var beneficiary = undefined;
        var beneficiaries = undefined;
        var selectedCallback = undefined;
        var updateCallback = undefined;
        var service = {
            getAnnuitants: getAnnuitants,
            setAnnuitants: setAnnuitants,
            getBeneficiary: getBeneficiary,
            setBeneficiary: setBeneficiary,
            getBeneficiaries: getBeneficiaries,
            setBeneficiaries: setBeneficiaries,
            setUpdateCallback: setUpdateCallback,
            fireUpdateCallback: fireUpdateCallback
        };

        return service;

        /**
        * @name setCallback.
        * @desc set callback when record is added.
        * @param {function} func - call function when selected beneficiary is changed.
        */
        function setUpdateCallback(func) {
            updateCallback = func;
        };

        /**
        * @name fireCallback.
        * @desc fires callback when record is added.
        * @param {object} case - selected annuitant.
        */
        function fireUpdateCallback(caseData) {
            if (updateCallback) {
                updateCallback(caseData);
            };
        };

        /**
        * @name setAnnuitants.
        * @desc store annuitants.
        * @param {array} data - annuitants to be stored.
        */
        function setAnnuitants(data) {
            annuitants = data;
        };

        /**
        * @name getAnnuitants.
        * @desc get annuitants.
        */
        function getAnnuitants() {
            return annuitants;
        };

        /**
        * @name getbeneficiary.
        * @desc return the beneficiary.
        */
        function getBeneficiary() {
            return beneficiary;
        };

        /**
        * @name setbeneficiary.
        * @desc store the beneficiary.
        * @param {object} data - beneficiary to be stored.
        */
        function setBeneficiary(data) {
            beneficiary = data;
        };

        /**
        * @name setbeneficiaries.
        * @desc store beneficiaries.
        * @param {array} data - beneficiaries to be stored.
        */
        function setBeneficiaries(data) {
            beneficiaries = data;
        };

        /**
        * @name getbeneficiaries.
        * @desc get beneficiaries.
        */
        function getBeneficiaries() {
            return beneficiaries;
        };
    };
})();