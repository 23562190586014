/**
* @name chronovoChart
* @desc wrapper for highcharts.
* @usage: <div chronovo-chart="content"></div>
**/
(function () {
    'use strict';

    angular
        .module('CareGuard')
        .directive('chronovoChart', chronovoChart);

    /**
    * @name chronovoChart.
    * @desc wrapper for highcharts.
    */
    function chronovoChart() {
        var directive = {
            restrict: 'AE',
            scope: {
                chronovoChart: '='
            },
            link: postLink
        };

        return directive;

        function postLink($scope, $element, $attrs) {

            //Update when charts data changes
            $scope.$watch('chronovoChart', function (value) {
                if (!value) return;

                // Initiate the chronovoChart.chart if it doesn't exist yet
                $scope.chronovoChart.chart = $scope.chronovoChart.chart || {};

                // use default values if nothing is specified in the given settings
                $scope.chronovoChart.chart.renderTo = $scope.chronovoChart.chart.renderTo || $element[0];
                var chart = new Highcharts.Chart($scope.chronovoChart);

                if (!$scope.chronovoChart.api) $scope.chronovoChart.api = {};
                $scope.chronovoChart.api.refresh = function (series) {
                    for (var i = 0; i < series.length; i++) {
                        chart.series[i].setData(series[i].data, false);
                    }
                    chart.redraw();
                };
                $scope.chronovoChart.api.export = function () {
                    return chart.getSVG().toDataURL;
                };
            });
        };
    };
})();