angular
    .module('CareGuard')
    .factory('ssnValidation', ssnValidation);

ssnValidation.$inject = [];

function ssnValidation() {
    function validateLength(value) {
        if (!value) return true;

       return value.length === 9;
    }

    function validateSSN(value) {
        if (!value) return true;

        if (validateLength(value)) {
            const regSSN = /^(?!(000|666|734|735|736|737|738|739|740|741|742|743|744|745|746|747|748|749|9))\d{3}(?!00)\d{2}(?!0000)\d{4}$/;
            const regFakedSSN = /^(?!(078051120|219099999))\d{9}$/;

            return regSSN.test(value) && regFakedSSN.test(value);
        }

        return false;
    }

    function validateITIN(value) {
        if (!value) return true;

        if (validateLength(value)) {
            const regITIN = /^(9\d{2})([7]\d|8[0-8]|9[0-2]|9[4-9])(\d{4})$/;

            return regITIN.test(value);
        }

        return false;
    }

    return {
        validateLength: validateLength,
        validateSSN: validateSSN,
        validateITIN: validateITIN
    };
}