import { defaultGridOptions } from '../../constant';
import { contactColumnDefsGrid } from './contactColumnDefs';
import { beneficiaryDefsGrid } from './beneficiaryColumnDefs';
import { contactGridFooterTemplate } from './contactGridFooterTemplate';
import rowTemplate from './html/rowTemplate.html';
import beneficiaryFooterTemplate from './html/beneficiaryGridFooterTemplate.html';

angular
    .module('CareGuard')
    .controller('memberController', memberController);

memberController.$inject = [
    '$scope',
    '$state',
    '$timeout',
    '$toastr',
    'LxDialogService',
    'memberService',
    'memberFlagsService',
    'lookupService',
    'memberData',
    'uiGridConstants',
    'formFields',
    'accountService',
    'ssnValidation',
    'pbmGroupService',
    'bankingService',
    'accountStatus',
    'pbmVendor',
    'accountType'];

function memberController(
    $scope,
    $state,
    $timeout,
    $toastr,
    LxDialogService,
    memberService,
    memberFlagsService,
    lookupService,
    memberData,
    uiGridConstants,
    formFields,
    accountService,
    ssnValidation,
    pbmGroupService,
    bankingService,
    accountStatus,
    pbmVendor,
    accountType) {

    let vm = this;

    const deleteMemberDialogID = 'promptDeletingMember';

    const trustAllocationTypeIds = [718, 719];

    vm.lookupdata = {};
    vm.member = {};
    vm.apiMember = {};
    vm.accountStatus = {};
    vm.memberToDelete = {};
    vm.memberflags = [];
    vm.Error = false;
    vm.address = {};
    vm.addressToDelete = {};
    vm.isAllowedToDelete = false;
    vm.isInMemberNeedsReviewRole = false;
    vm.doesMemberNeedReview = 0;
    vm.isSettlementDocumentsReceived = 0;
    vm.isSendStatements = 0;
    vm.isSendMarketingMaterials = 0;
    vm.newBeneficiary = {};
    vm.beneficiaryToDelete = {};
    vm.memberLanguages = {};
    vm.SpokenTypeID = null;
    vm.SpokenLanguageID = null;
    vm.isBeneficiaryEditing = false;
    vm.memberHasClaims = false;
    vm.isCmsEAttestationReady = 0;

    vm.isLiability = isLiability;
    vm.isMember = () => vm.accountStatus && vm.accountStatus.id === accountStatus.Member;
    vm.isProspective = () => vm.accountStatus && vm.accountStatus.id === accountStatus.Prospective;
    vm.isTrustAllocationType = () => !!vm.member.AllocationTypeId && trustAllocationTypeIds.includes(+vm.member.AllocationTypeId);
    vm.isITIN = () => vm.member.SSN && ssnValidation.validateITIN(vm.member.SSN);
    vm.isSSN = () => vm.member.SSN && ssnValidation.validateSSN(vm.member.SSN);
    vm.getCurrentDate = () => moment(new Date()).format('MM/DD/YYYY');

    vm.deleteAddress = deleteAddress;
    vm.validateContacts = validateContacts;
    vm.validateBeneficiaries = validateBeneficiaries;
    vm.saveMember = saveMember;
    vm.deleteMember = deleteMember;
    vm.openPopupToDeleteMember = openPopupToDeleteMember;
    vm.removeBeneficiaryRow = removeBeneficiaryRow;
    vm.MemberNeedsReviewWarning = MemberNeedsReviewWarning;
    vm.onPreferredContactNumberTypeChange = onPreferredContactNumberTypeChange;
    vm.onPhoneNumberChange = onPhoneNumberChange;

    vm.onAccountStatusChange = onAccountStatusChange;
    vm.onChannelSourceChange = onChannelSourceChange;
    vm.onAllocationTypeChange = onAllocationTypeChange;
    vm.onLifeExpectancyMSAFundingAdminFeesDateChange = onLifeExpectancyMSAFundingAdminFeesDateChange;
    vm.onStructureBrokerCompanyChange = onStructureBrokerCompanyChange;
    vm.onPlaintiffAttorneyChange = onPlaintiffAttorneyChange;
    vm.setPointOwnerCompany2 = setPointOwnerCompany2;
    vm.setPlaintiffAttorneyCompany = setPlaintiffAttorneyCompany;
    vm.onPointOwnerIndividual2Change = onPointOwnerIndividual2Change;

    vm.isBeneficiaryTypeDisabled = isBeneficiaryTypeDisabled;
    vm.isReversionaryPercentDisabled = isReversionaryPercentDisabled;
    vm.onChangeReversionaryPercent = onChangeReversionaryPercent;
    vm.onChangeBeneficiaryType = onChangeBeneficiaryType;
    vm.onUpdateJurisdictionState = onUpdateJurisdictionState;

    vm.onCaseLostDescriptionChange = onCaseLostDescriptionChange;

    vm.onPbmChange = onPbmChange;
    vm.onPricingLevelIdChange = onPricingLevelIdChange;

    vm.hasRole = hasRole;

    vm.titles = {
        data: [
            { Name: 'Mr.', Value: 'Mr.' },
            { Name: 'Ms.', Value: 'Ms.' },
            { Name: 'Mrs.', Value: 'Mrs.' }
        ]
    };

    vm.gender = {
        data: [
            { Name: 'Male', Value: 'Male' },
            { Name: 'Female', Value: 'Female' }
        ]
    };

    vm.phones = {
        data: [
            { Name: 'Mobile Phone', Value: 'MobilePhone' },
            { Name: 'Home Phone', Value: 'HomePhone' },
            { Name: 'Work Phone', Value: 'WorkPhone' }
        ]
    };

    vm.claimType = {
        data: [
            { DataSetValue: 'WC' },
            { DataSetValue: 'Liability' }
        ]
    };

    vm.multiSelectTranslation = {
        selectAll: 'Select all',
        selectNone: 'Select none',
        reset: 'Reset',
        search: 'Type here to search...',
        nothingSelected: ''
    };

    vm.YesNoOptions = {
        data: [
            { Name: 'Yes', Value: 1 },
            { Name: 'No', Value: 0 }
        ]
    };

    vm.YesNoBooleanOptions = {
        data: [
            { Name: 'Yes', Value: true },
            { Name: 'No', Value: false }
        ]
    };

    const defaultSendMarketingMaterials = vm.YesNoOptions.data[0].Value;

    const requiredAddressType = 'Mailing';

    let requiredAddressTypeID,
        initialAddresses = [],
        initialBeneficiaries = [],
        validationMessage = '';

    vm.pbmPricingLevels = [];
    vm.pricingLevelDisplayId = null;
    vm.filterPricingLevelsByPbmVendor = filterPricingLevelsByPbmVendor;

    vm.submittedToCMS = {
        data: [
            { DataSetValue: 'Yes' },
            { DataSetValue: 'No' },
            { DataSetValue: 'N/A' }
        ]
    };

    vm.gridOptionsContact = defaultGridOptions({
        columnDefs: contactColumnDefsGrid,
        rowHeight: 48,
        headerHeight: 48,
        minRowsToShow: 2,
        rowTemplate,
        enableVerticalScrollbar: uiGridConstants.scrollbars.NEVER,
        enablePaginationControls: false,
        enableSorting: false,
        enableCellEditOnFocus: true,
        useExternalPagination: false,
        useExternalSorting: false,
        totalItems: 0,
        enableCellEdit: false,
        enableExpandableRowHeader: false,
        gridFooterTemplate: contactGridFooterTemplate,
        showGridFooter: true
    });

    vm.gridOptionsBeneficiary = defaultGridOptions({
        columnDefs: beneficiaryDefsGrid,
        rowHeight: 48,
        headerHeight: 48,
        minRowsToShow: 2,
        rowTemplate,
        enableVerticalScrollbar: uiGridConstants.scrollbars.NEVER,
        enablePaginationControls: false,
        enableSorting: false,
        enableCellEditOnFocus: true,
        useExternalPagination: false,
        useExternalSorting: false,
        totalItems: 0,
        enableExpandableRowHeader: false,
        gridFooterTemplate: beneficiaryFooterTemplate,
        showGridFooter: true
    });

    vm.addContactRow = function () {
        ++vm.gridOptionsContact.minRowsToShow;

        const emptyRow = getEmptyRowEntityFromColumnDefs(contactColumnDefsGrid);
        emptyRow.rowId = generateRowId(vm.gridOptionsContact.data);

        vm.gridOptionsContact.data.push(emptyRow);

        delete vm.member.areAddressesValid;
    };

    vm.addBeneficiaryRow = function () {
        ++vm.gridOptionsBeneficiary.minRowsToShow;

        const emptyRow = getEmptyRowEntityFromColumnDefs(beneficiaryDefsGrid);
        emptyRow.rowId = generateRowId(vm.gridOptionsBeneficiary.data);

        vm.gridOptionsBeneficiary.data.push(emptyRow);

        delete vm.member.areBeneficiariesValid;
    };

    vm.onCheckBoxChange = function (row) {
        if (row.entity.IsCurrent) {
            vm.gridOptionsContact.data.forEach(item => {
                if (item.AddressTypeId === row.entity.AddressTypeId && item.rowId !== row.entity.rowId) {
                    item.IsCurrent = false;
                }
            });
        } else {
            const currentAddressTypeId = row.entity.AddressTypeId;

            let addressWithTheSameType = vm.gridOptionsContact.data.find(item =>
                item.AddressTypeId === currentAddressTypeId
                && item.rowId !== row.entity.rowId);

            if (!addressWithTheSameType) {
                const currentAddressType = vm.lookupdata.addresstype.find(item => item.DataSetID === currentAddressTypeId).DataSetValue;
                row.entity.IsCurrent = true;

                const message = 'The only ' + currentAddressType + ' address can not be unchecked';
                $toastr.show(message, 'warning');
                return;
            }

            addressWithTheSameType.IsCurrent = true;
        }
    };

    vm.onAddressTypeChange = function (row) {
        let currentAddressWithTheSameType = vm.gridOptionsContact.data.find(item =>
            item.AddressTypeId === row.entity.AddressTypeId
            && item.rowId !== row.entity.rowId
            && item.IsCurrent);

        if (currentAddressWithTheSameType) {
            row.entity.IsCurrent = false;
        } else {
            row.entity.IsCurrent = true;
        }
    };

    function validateContactGrid() {
        let invalidFields = {};
        const gridToValidate = vm.gridOptionsContact;
        if (!gridToValidate) return;

        let addressTypeCurrentAddressesAmountMap = {};

        gridToValidate.data.forEach((row, index) => {
            invalidFields[index + 1] = [];
            for (let field in row) {
                const fieldConfig = gridToValidate.columnDefs.find(config => config.field === field);
                if (fieldConfig && fieldConfig.required && !row[field]) {
                    invalidFields[index + 1].push(fieldConfig.displayName);
                }
            }

            if (invalidFields[index + 1].length < 1) {
                delete invalidFields[index + 1]
            }

            if (row.AddressTypeId) {
                if (addressTypeCurrentAddressesAmountMap[row.AddressTypeId]) {
                    addressTypeCurrentAddressesAmountMap[row.AddressTypeId] += row.IsCurrent ? 1 : 0;
                } else {
                    addressTypeCurrentAddressesAmountMap[row.AddressTypeId] = row.IsCurrent ? 1 : 0;
                }
            }
        });

        let isAnyMailingAddress = addressTypeCurrentAddressesAmountMap.hasOwnProperty(requiredAddressTypeID);

        let isCurrentAddressAmountValid = Object.keys(addressTypeCurrentAddressesAmountMap)
            .every(addressTypeId => addressTypeCurrentAddressesAmountMap[addressTypeId] === 1);

        return {
            invalidFields: invalidFields,
            isAnyMailingAddress: isAnyMailingAddress,
            isCurrentAddressAmountValid: isCurrentAddressAmountValid
        };
    }

    function validateBeneficiary() {
        let invalidFields = {};
        const gridToValidate = vm.gridOptionsBeneficiary;
        if (!gridToValidate) return;

        gridToValidate.data.forEach((row, index) => {
            if (!isBeneficiaryValid(row)) {
                invalidFields[index + 1] = true;
            }
        });

        return invalidFields;
    }

    $scope.$watch(() => vm.gridOptionsContact.data, (newVal, oldValue) => {
        if (!newVal) return;

        if (!angular.equals(newVal, oldValue) && vm.memberForm.$submitted) {
            let validationResult = validateContactGrid();

            vm.gridOptionsContact.invalidFields = validationResult.invalidFields;
            vm.gridOptionsContact.isCurrentAddressAmountValid = validationResult.isCurrentAddressAmountValid;
        }
    }, true);

    $scope.$watch(() => vm.gridOptionsBeneficiary.data, (newVal, oldValue) => {
        if (!newVal) return;

        if (!angular.equals(newVal, oldValue) && vm.memberForm.$submitted) {
            vm.gridOptionsBeneficiary.invalidFields = validateBeneficiary();
        }
    }, true);

    function getEmptyRowEntityFromColumnDefs(entity) {
        if (!entity || entity.length < 1) return;

        let defaultRow = {};
        entity.forEach(field => {
            if (field.displayName) {
                defaultRow[field.field] = null;
            }
        });

        defaultRow.MemberID = memberData;

        return defaultRow;
    }

    vm.isRestrictedMember = function () {
        return vm.apiMember.accountTypeId == accountType.AmetrosBankingRestrictedAccount;
    };

    init();
    function init() {
        vm.isAllowedToDelete = accountService.isInRole('MemberDelete');
        vm.isInMemberNeedsReviewRole = accountService.isInRole('MemberNeedsReview');

        if (!memberData) {
            navigate('invalid');
            return;
        }

        const req = {
            LookupCategories: [
                'addresstype',
                'allocationsubtype',
                'beneficiarytype',
                'caselostto',
                'casesize',
                'channelsourcetype',
                'cmsapproved',
                'communicationtype',
                'emailstatus',
                'feeschedule',
                'fundingFrequency',
                'fundType',
                'language',
                'lifeInsuranceCompany',
                'settlementtype',
                'feetype',
                'structuredsettlementtype',
                'statecode'
            ]
        };

        const promises = [lookupService.getLookUpsBatch(req)];

        Promise.all([
            memberService.getById(memberData),
            memberService.getMemberById(memberData)]
        ).then(([{ Data: getMemberByIdResult }, { data: apiMemberData }]) => {
            if (!getMemberByIdResult || !apiMemberData) {
                navigate('invalid');
                return;
            }

            vm.apiMember = apiMemberData;

            promises.push(
                memberService.loadAddresses(memberData),
                memberFlagsService.getMemberFlags(memberData),
                memberService.getContacts(memberData),
                memberService.getMemberLanguages(memberData),
				pbmGroupService.getAllPbmPricingLevels(),
                memberService.getAccountStatuses(),
                memberService.getLeadTypes(),
                memberService.getAccountTypes(),
                memberService.getPbmVendors(),
                memberService.getMailOrderVendors(),
                bankingService.getCurrentBalanceTotalsByMemberIds({ ids: [vm.apiMember.id], fields: `memberId,totalBankBalance,balanceDate,holdAmount,totalAvailableBalance,billAmount,feeAmount` })
            );

            Promise.all(promises).then(([
                lookupData,
                { Data: addresses },
                { Data: memberFlags },
                { Data: contacts },
                { Data: languages },
                { data: pbmAssignments },
                { data: accountStatuses },
                { data: leadTypes },
                { data: accountTypes },
                { data: pbmVendors },
                { data: mailOrderVendors },
                { data: [balances] }
            ]) => {
                for (let key in lookupData) {
                    vm.lookupdata[key.toLowerCase()] = lookupData[key];
                }

                vm.accountStatuses = filterPreMemberStatuses(accountStatuses);
                vm.leadTypes = leadTypes;
                vm.accountTypes = accountTypes;
                vm.mailOrderVendors = mailOrderVendors;
                setAccountBalances(balances, vm.apiMember);
                vm.pbmVendors = filterPbmVendors(pbmVendors, pbmAssignments, vm.apiMember);

                const settlementTypeKey = Object.keys(lookupData).find(x => x.toLowerCase() === 'settlementtype');

                if (settlementTypeKey) {
                    const structure = lookupData[settlementTypeKey].find(x => x.DataSetValue.toLowerCase() === 'structure');

                    if (structure) {
                        vm.structureSettlementTypeId = structure.DataSetID;
                    }
                }

                const communicationType = vm.lookupdata.communicationtype && vm.lookupdata.communicationtype.find(function (item) {
                    return item.DataSetValue === 'Speaking';
                });
                vm.SpokenTypeID = communicationType && communicationType.DataSetID;

                if (memberData) {
                    vm.gridOptionsContact.data = populateAddresses(addresses);
                    angular.copy(vm.gridOptionsContact.data, initialAddresses);
                    vm.gridOptionsContact.minRowsToShow = vm.gridOptionsContact.data.length + 1;

                    vm.memberflags = memberFlags;
                    vm.lookupdata.contactlist = contacts;

                    const addressType = vm.lookupdata.addresstype.find(item => {
                        return item.DataSetValue === requiredAddressType;
                    });

                    requiredAddressTypeID = addressType && addressType.DataSetID;

                    vm.memberLanguages = languages;

                    const spokenLanguages = vm.memberLanguages.find(item => item.CommunicationTypeID === vm.SpokenTypeID);

                    vm.SpokenLanguageID = spokenLanguages && spokenLanguages.LanguageID;

                    vm.member = getMemberByIdResult;
                    vm.memberHasClaims = vm.member.ClaimCount > 0;

                    vm.pbmPricingLevels = filterPricingLevels(pbmVendors, pbmAssignments, vm.apiMember);
                    vm.pbmPricingLevels.forEach((pl, i) => pl.displayId = i);
                    assignMatchingPricingLevel();

                    Promise.resolve(updateMemberInfo()).then((data) => {
                        memberService.setBeneficiaryType(data, vm.member);
                    })['finally'](_ => memberService.setMember(vm.member));
                }
            });
        }).catch(() => navigate('invalid'));
    }

    function filterPreMemberStatuses(statuses) {
        if (!statuses?.length) return [];

        const invalidStatuses = [
            accountStatus.FundingReceived,
            accountStatus.FeesReceived,
            accountStatus.FutureMember,
            accountStatus.FundedFutureMember
        ];

        return statuses.filter(status => !invalidStatuses.includes(status.id));
    }

    function filterPbmVendors(vendors, pricingLevels, member) {
        if (!vendors?.length) return [];

        return vendors.filter(vendor => {
            return pricingLevels.some(level => (level.pbmVendorId === vendor.id && level.isActive)) || (member.pbmVendorId === vendor.id);
        });      
    }

    function filterPricingLevels(vendors, pricingLevels, member) {
        if (!pricingLevels?.length) return [];

        const activePbms = vendors.filter(vendor => {
            return pricingLevels.some(level => (level.pbmVendorId === vendor.id && level.isActive));
        }); 

        const terminatedPbm = !activePbms.some(pbm => pbm.id === member.pbmVendorId);

        return pricingLevels.filter(level => { return level.isActive || (member.pbmVendorId === level.pbmVendorId && terminatedPbm) });
    }

    function setAccountBalances(balances, member) {
        if (!balances) {
            member.currentBalances.totalBankBalance = 0.00;
            member.currentBalances.totalAvailableBalance = 0.00;
            member.currentBalances.billAmount = 0.00;
            member.currentBalances.holdAmount = 0.00;
            member.currentBalances.feeAmount = 0.00;
        } else {
            member.currentBalances = balances;
        }
    }

    function updateMemberInfo() {
        vm.member.areAddressesValid = true;
        vm.member.areBeneficiariesValid = true;
        onAccountStatusChange();
        updateReferralInfo();
        setPreferredContactNumberType();
        return getBeneficiaries();
    }

    function onPreferredContactNumberTypeChange () {
        vm.apiMember.preferredContactNumber = vm.member[vm.member.PreferredContactNumberType] || null;
    }

    function onPhoneNumberChange(type) {
        if (vm.member.PreferredContactNumberType === type) {
            vm.apiMember.preferredContactNumber = vm.member[type];
        }
    }

    function setPreferredContactNumberType() {
        if (vm.apiMember.preferredContactNumber) {
            const preferredContactNumberType = vm.phones.data.find(item => {
                return vm.apiMember.preferredContactNumber === vm.member[item.Value];
            });

            vm.member.PreferredContactNumberType = preferredContactNumberType && preferredContactNumberType.Value;
        }
    }

    function deleteAddress(address, formCtrl) {
        if (vm.gridOptionsContact.data.length === 1) {
            $toastr.show('You can not delete the last address!', 'warning');
        } else {
            if (address.IsCurrent) {
                let addressWithTheSameType = vm.gridOptionsContact.data.find(item =>
                    item.AddressTypeId === address.AddressTypeId
                    && item.rowId !== address.rowId);

                if (addressWithTheSameType) {
                    addressWithTheSameType.IsCurrent = true;
                }
            }

            let index = vm.gridOptionsContact.data.findIndex(x => x.rowId === address.rowId);
            if (index > -1) {
                vm.gridOptionsContact.data.splice(index, 1);
                formCtrl.$setDirty();
            }
        }
    }

    function validateContacts(formCtrl) {
        let contactGridValidationResult = validateContactGrid();

        vm.gridOptionsContact.invalidFields = contactGridValidationResult.invalidFields;
        vm.gridOptionsContact.isCurrentAddressAmountValid = contactGridValidationResult.isCurrentAddressAmountValid;

        if (!vm.gridOptionsContact.isCurrentAddressAmountValid
            || !angular.equals({}, vm.gridOptionsContact.invalidFields)) {
            delete vm.member.areAddressesValid;
            formCtrl['addresses'].$setValidity('required', false);
            validationMessage = validationMessage + 'Please fix errors in Contact section.</br>';
        } else {
            vm.member.areAddressesValid = true;
            formCtrl['addresses'].$setValidity('required', true);
        }

        if (!contactGridValidationResult.isAnyMailingAddress && vm.isMember()) {
            validationMessage = validationMessage + 'Mailing address is required for Member.</br>';
            formCtrl['addresses'].$setValidity('required', false);
        }
    }

    function onCaseLostDescriptionChange(member) {
        if (!member.CaseLostReason) {
            member.CaseLostReasonDescription = null;
        }
    }

    function validateBeneficiaries(formCtrl) {
        vm.gridOptionsBeneficiary.invalidFields = validateBeneficiary();
        if (!angular.equals({}, vm.gridOptionsBeneficiary.invalidFields)) {
            delete vm.member.areBeneficiariesValid;
            formCtrl['beneficiaries'].$setValidity('required', false);
            validationMessage = validationMessage + 'Please fix errors in Beneficiary section.</br>';

        } else {
            vm.member.areBeneficiariesValid = true;
            formCtrl['beneficiaries'].$setValidity('required', true);
        }
    }

    function getArrayOfUniqueElements(array) {
        return [...new Set(array)];
    }

    function saveMember(formCtrl) {
        validationMessage = '';

        if (vm.isRestrictedMember()) {
            $toastr.show('Saving is disabled for restricted members.', 'warning');
            return;
        }

        validateContacts(formCtrl);
        validateBeneficiaries(formCtrl);

        if (formCtrl.$invalid) {
            let wrongFields = [];

            for (let errorType in formCtrl.$error) {
                formCtrl.$error[errorType].forEach(item => {
                    if (formFields.hasOwnProperty(item.$name)) {
                        wrongFields.push(formFields[item.$name]);
                    }
                });
            }

            if (wrongFields.length > 0) {
                wrongFields = getArrayOfUniqueElements(wrongFields);
                const wrongFieldsStr = wrongFields.join(',</br>');
                validationMessage = validationMessage + 'Before submitting please correct the mistakes in the next fields:</br>'
                    + wrongFieldsStr + '.';
            }
            $toastr.show(validationMessage, 'warning');

            return;
        }

        vm.member.EmailStatus = vm.member.EmailStatus || null;

        vm.member.SettlementDocumentsReceived = (vm.isSettlementDocumentsReceived === null || vm.isSettlementDocumentsReceived === '')
            ? null : !!+vm.isSettlementDocumentsReceived;
        vm.member.SendStatements = (vm.isSendStatements === null || vm.isSendStatements === '')
            ? null : !!+vm.isSendStatements;
        vm.member.SendMarketingMaterials = (vm.isSendMarketingMaterials === null || vm.isSendMarketingMaterials === '')
            ? null : !!+vm.isSendMarketingMaterials;
        
        vm.member.CmsEAttestationReady = (vm.isCmsEAttestationReady === null || vm.isCmsEAttestationReady === '')
            ? null : !!+vm.isCmsEAttestationReady;

        //Setting api vm.apiMember variables to vm.Member
        vm.member.AccountStatusID = vm.apiMember.accountStatusId;
        vm.member.LeadTypeID = vm.apiMember.leadTypeId;
        vm.member.AccountTypeID = vm.apiMember.accountTypeId;
        vm.member.MailOrderVendorID = vm.apiMember.mailOrderVendorId;
        vm.member.MemberNotificationDate = vm.apiMember.memberFENotificationDate;
        vm.member.MedicareNotificationDate = vm.apiMember.medicareFENotificationDate;
        vm.member.TerminationDate = vm.apiMember.terminationDate;
        vm.member.MedicarePricingEligibility = vm.apiMember.medicarePricingEligibility;
        vm.member.MemberNeedsReview = vm.apiMember.memberNeedsReview;
        vm.member.IsPaperless = vm.apiMember.isPaperless;
        vm.member.PreferredContactNumber = vm.apiMember.preferredContactNumber;
        vm.member.AnnualFundingDate = vm.apiMember.annualFundingDate;
        //

        return saveMemberAddresses().then(_ => {
            let promises = [];

            promises.push(memberService.saveMember(vm.member).then(r => ({ name: 'member', result: r })));

            if (vm.SpokenLanguageID && vm.SpokenTypeID) {
                const spokenLanguage = Object.keys(vm.memberLanguages).length &&
                    vm.memberLanguages.find(item => {
                        return item.CommunicationTypeID === vm.SpokenTypeID
                    });

                if (spokenLanguage && spokenLanguage.LanguageID !== vm.SpokenLanguageID) {
                    promises.push(memberService.saveMemberLanguage(
                        spokenLanguage.MemberLanguageID,
                        spokenLanguage.MemberID,
                        vm.SpokenLanguageID,
                        spokenLanguage.CommunicationTypeID
                    ).then(r => ({ name: 'language', result: r })));
                } else {
                    //new spoken language
                    promises.push(memberService
                        .saveMemberLanguage(null, memberData, vm.SpokenLanguageID, vm.SpokenTypeID)
                        .then(r => ({ name: 'language', result: r })));
                }
            }

            return Promise.all(promises).then(results => {

                let res = results.reduce((prev, current) => {
                    prev[current.name] = current.result;
                    return prev;
                },
                    {});

                vm.member = res['member'];

                updateReferralInfo();
                setPreferredContactNumberType();
                onPointOwnerIndividual2Change();

                let promise = [];
                if (!angular.equals(initialBeneficiaries, vm.gridOptionsBeneficiary.data)) {
                    promise.push(
                        memberService.saveBeneficiaries(vm.member.MemberID, vm.gridOptionsBeneficiary.data)
                            .then(res => vm.gridOptionsBeneficiary.data = populateBeneficiaries(res))
                    )
                }

                Promise.all(promise)['finally'](() => {
                    memberService.populateBeneficiaryType(vm.member);
                    memberService.setMember(vm.member);
                });
            });
        });
    }

    function saveMemberAddresses() {
        let promises = [];

        if (!angular.equals(initialAddresses, vm.gridOptionsContact.data)) {
            promises.push(memberService.saveAddresses(vm.member.MemberID, vm.gridOptionsContact.data)
                .then(res => {
                    vm.gridOptionsContact.data = populateAddresses(res);
                }));
        }

        return Promise.all(promises);
    }

    function openPopupToDeleteMember(member) {
        if (!member.MemberID) return;

        vm.memberToDelete = member;
        LxDialogService.open(deleteMemberDialogID);
    }

    function deleteMember() {
        memberService.deleteMember(vm.memberToDelete.MemberID).then(function (result) {
            LxDialogService.close(deleteMemberDialogID);
            vm.member = result.Data || {};
            memberService.setMember(vm.member);

            if (!vm.member.MemberID) {
                navigate('layoutNoNav.homepage');
            }
        }).catch(function (fallback) {
            navigate('layout.profile', { id: vm.member.MemberID });
        })['finally'](_ => vm.memberToDelete = {});
    }

    function navigate(state, params) {
        $timeout(function () {
            $state.go(state, params);
        }, 0);
    }

    function getBeneficiaries() {
        return memberService.getBeneficiaries(vm.member.MemberID).then(function (result) {
            vm.gridOptionsBeneficiary.data = populateBeneficiaries(result.Data);
            angular.copy(vm.gridOptionsBeneficiary.data, initialBeneficiaries);

            return result;
        });
    }

    function removeBeneficiaryRow(beneficiary, formCtrl) {
        let index = vm.gridOptionsBeneficiary.data.findIndex(x => x.rowId === beneficiary.rowId);

        if (beneficiary.BeneficiaryType && beneficiary.BeneficiaryType !== 'Contingent') {
            let oppositeBeneficiary = findBeneficiaryWithOppositeType(beneficiary.BeneficiaryType);

            if (oppositeBeneficiary) {
                if (oppositeBeneficiary.BeneficiaryType === 'Reversionary') {
                    oppositeBeneficiary.ReversionaryPercent = 100;
                } else {
                    oppositeBeneficiary.ReversionaryPercent = 0;
                }
            }
        }

        if (index !== -1) {
            vm.gridOptionsBeneficiary.data.splice(index, 1);
            formCtrl.$setDirty();
        }
    }

    function MemberNeedsReviewWarning() {
        if (vm.apiMember.memberNeedsReview) {
            $toastr.show('Are you sure the Member wants to be contacted before each of his/her' +
                ' claims can be processed? You must click \'Save\' to save your changes',
                'warning');
        } else {
            $toastr.show('Are you sure the Member does NOT want to be contacted before each of his/her claims is processed?' +
                ' You must click \'Save\' to save your changes', 'warning');
        }
    }

    function onAccountStatusChange() {
        vm.accountStatus = vm.accountStatuses.find(item => {
            return item.id === vm.member.accountStatusId;
        });
    }

    function isLiability() {
        return vm.member.InsuranceType === 'Liability';
    }

    function onAllocationTypeChange() {
        if (vm.isTrustAllocationType()) {
            vm.isSendStatements = vm.isSendStatements ? vm.isSendStatements : 0;
        } else {
            vm.member.AllocationSubtype = null;
            vm.isSendStatements = vm.isSendStatements ? vm.isSendStatements : 1;
        }
    }

    function onLifeExpectancyMSAFundingAdminFeesDateChange() {
        if (!vm.member.LifeExpectancy) return;

        if (vm.member.MSAFundingDate) {
            vm.member.FinalYearFunding = vm.member.MSAFundingDate.getFullYear() + vm.member.LifeExpectancy;
        }

        if (vm.member.AdminFeesDate) {
            vm.member.FinalYearFee = vm.member.AdminFeesDate.getFullYear() + vm.member.LifeExpectancy;
        }
    }

    function onPointOwnerIndividual2Change() {
        if (!vm.member.PointOwnerIndividual2Id) {
            vm.member.PointOwnerCompany2 = null;
            vm.member.PointOwnerIndividual2Email = null;
            vm.member.PointOwnerIndividual2 = null;

            return;
        }

        const p2Contact = vm.lookupdata.contactlist.find(item => {
            return item.ContactID === +vm.member.PointOwnerIndividual2Id;
        });

        if (p2Contact) {
            vm.member.PointOwnerCompany2 = p2Contact.FirmName;
            vm.member.PointOwnerIndividual2Email = p2Contact.Email;
            vm.member.PointOwnerIndividual2 = p2Contact.FullName;
        }
    }

    function setChannelSource() {
        if (!vm.member.ChannelSourceIndvID) return;

        const channelSource = vm.lookupdata.contactlist.find(item => {
            return item.ContactID === +vm.member.ChannelSourceIndvID;
        });

        if (!channelSource) return;

        vm.member.ChannelSourceIndv = channelSource.FullName;
        vm.member.ChannelSourceCompany = (vm.member.ChannelSourceCompany === null) ? channelSource.FirmName : vm.member.ChannelSourceCompany;
        vm.member.ChannelSourceEmail = (vm.member.ChannelSourceEmail || channelSource.Email)
            && (vm.member.ChannelSourceEmail || channelSource.Email).trim();
        vm.member.ChannelSourceState = vm.member.ChannelSourceState || channelSource.State;
    }

    function onChannelSourceChange() {
        if (!vm.member.ChannelSourceIndvID) {
            vm.member.ChannelSourceIndv = null;
            vm.member.ChannelSourceCompany = null;
            vm.member.ChannelSourceEmail = null;
            vm.member.ChannelSourceState = null;

            return;
        }

        const channelSource = vm.lookupdata.contactlist.find(item => {
            return item.ContactID === +vm.member.ChannelSourceIndvID;
        });

        if (!channelSource) return;

        vm.member.ChannelSourceIndv = channelSource.FullName;
        vm.member.ChannelSourceCompany = channelSource.FirmName;
        vm.member.ChannelSourceEmail = channelSource.Email && channelSource.Email.trim();
        vm.member.ChannelSourceState = channelSource.State;
    }

    function setPlaintiffAttorneyCompany(selectedContactID) {
        vm.member.PlaintiffAttorneyCompany = null;

        if (!selectedContactID) {

            const result = vm.lookupdata.contactlist.find(item => item.ContactID === +selectedContactID);

            if (result) {
                vm.member.PlaintiffAttorneyCompany = result.FirmName;
            }
        }
    }

    function setPointOwnerCompany2() {
        if (!vm.member.PointOwnerIndividual2Id) return;

        const p2Contact = vm.lookupdata.contactlist.find(item => {
            return item.ContactID === +vm.member.PointOwnerIndividual2Id;
        });

        if (!p2Contact) return;

        vm.member.PointOwnerCompany2 = p2Contact.FirmName;
        vm.member.PointOwnerIndividual2Email = p2Contact.Email;
        vm.member.PointOwnerIndividual2 = p2Contact.FullName;
    }

    function onPlaintiffAttorneyChange() {
        vm.member.PlaintiffAttorneyCompany = null;
        vm.member.PlaintiffAttorneyEmail = null;

        if (vm.member.PlaintiffAttorneyIndvID) {
            const result = vm.lookupdata.contactlist.find(item => item.ContactID === +vm.member.PlaintiffAttorneyIndvID);

            if (result) {
                vm.member.PlaintiffAttorneyCompany = result.FirmName;
                vm.member.PlaintiffAttorneyEmail = result.Email && result.Email.trim();
            }
        }
    }

    function onStructureBrokerCompanyChange() {
        if (!vm.member.StructureBrokerIndvID) {
            vm.member.StructureBrokerCompany = null;
            vm.member.PointOwnerCompany = null;
            vm.member.StructureBrokerEmail = null;
            vm.member.PointOwnerIndividual = null;

            return;
        }

        const result = vm.lookupdata.contactlist.find(item => item.ContactID === +vm.member.StructureBrokerIndvID);

        if (result) {
            vm.member.StructureBrokerEmail = result.Email && result.Email.trim();

            if (result.FirmName) {
                vm.member.StructureBrokerCompany = result.FirmName;
                vm.member.PointOwnerCompany = result.FirmName;
            }

            if (!result.FirstName || !result.LastName) {
                vm.member.StructureBrokerIndv = !result.FirstName ?
                    (!result.LastName ? '' : result.LastName) : result.FirstName;
                vm.member.PointOwnerIndividual = !result.FirstName ?
                    (!result.LastName ? '' : result.LastName) : result.FirstName;
            } else {
                vm.member.StructureBrokerIndv = result.FirstName + ' ' + result.LastName;
                vm.member.PointOwnerIndividual = vm.member.StructureBrokerIndv;
            }
        }
    }

    function updateReferralInfo() {
        if (vm.apiMember.isPaperless === null)
            vm.apiMember.isPaperless = false;

        vm.isSettlementDocumentsReceived = (vm.member.SettlementDocumentsReceived === null) ? null :
            vm.member.SettlementDocumentsReceived ? 1 : 0;

        if (vm.member.SendStatements === null) {
            vm.isSendStatements = vm.isTrustAllocationType() ? 0 : 1;
        } else {
            vm.isSendStatements = vm.member.SendStatements ? 1 : 0;
        }

        vm.isSendMarketingMaterials = (vm.member.SendMarketingMaterials === null) ? defaultSendMarketingMaterials :
            vm.member.SendMarketingMaterials ? 1 : 0;

        vm.isCmsEAttestationReady = (vm.member.CmsEAttestationReady === null) ? null :
            vm.member.CmsEAttestationReady ? 1 : 0;

        onStructureBrokerCompanyChange();
        onPlaintiffAttorneyChange();
        setPointOwnerCompany2();
        setChannelSource();
    }

    function isBeneficiaryTypeDisabled(beneficiaryType, beneficiaryRowId) {
        //adding beneficiaries with 'Contingent' type is always allowed
        if (beneficiaryType.DataSetValue === 'Contingent') {
            return false;
        }

        if (vm.member.Payor === 'CNA' && beneficiaryType.DataSetValue === 'Reversionary') {
            return true;
        }

        return vm.gridOptionsBeneficiary.data.some(x => x.rowId !== beneficiaryRowId && x.BeneficiaryType === beneficiaryType.DataSetValue);
    }

    function isReversionaryPercentDisabled(beneficiary) {
        if (!beneficiary.BeneficiaryType || beneficiary.BeneficiaryType !== 'Reversionary') {
            return true;
        }

        let oppositeBeneficiary = findBeneficiaryWithOppositeType(beneficiary.BeneficiaryType);

        return !oppositeBeneficiary;
    }

    function onChangeReversionaryPercent(beneficiary) {
        let oppositeBeneficiary = findBeneficiaryWithOppositeType(beneficiary.BeneficiaryType);

        if (oppositeBeneficiary) {
            oppositeBeneficiary.ReversionaryPercent = (1000 - 10 * beneficiary.ReversionaryPercent) / 10;
        }
    }

    function onChangeBeneficiaryType(beneficiary) {
        if (!beneficiary.BeneficiaryType || beneficiary.BeneficiaryType === 'Contingent') {
            beneficiary.ReversionaryPercent = null;
            return;
        }

        let oppositeBeneficiary = findBeneficiaryWithOppositeType(beneficiary.BeneficiaryType);

        if (oppositeBeneficiary) {
            beneficiary.ReversionaryPercent = 100 - oppositeBeneficiary.ReversionaryPercent;
            return;
        }

        if (beneficiary.BeneficiaryType === 'Estate') {
            beneficiary.ReversionaryPercent = 0;
            return;
        }

        if (beneficiary.BeneficiaryType === 'Reversionary') {
            beneficiary.ReversionaryPercent = 100;
        }
    }

    function onUpdateJurisdictionState() {
        if (Array.isArray(vm.member.JurisdictionState)) {
            vm.member.JurisdictionState = vm.member.JurisdictionState[0];
        }
        memberService.autoPopulatePricingMechanism(vm.member, vm.lookupdata.feeschedule);
    }

    //based on assumption Estate and Reversionary beneficiary types are opposite
    function findBeneficiaryWithOppositeType(beneficiaryType) {
        let oppositeType;
        switch (beneficiaryType) {
            case 'Estate':
                oppositeType = 'Reversionary';
                break;
            case 'Reversionary':
                oppositeType = 'Estate';
                break;
            default:
                throw Error('Unexpected beneficiary type');
        }

        return vm.gridOptionsBeneficiary.data.find(x => x.BeneficiaryType === oppositeType);
    }

    function isBeneficiaryValid(beneficiary) {
        if (!beneficiary.BeneficiaryType || (!beneficiary.FirmName
            && (!beneficiary.FirstName || !beneficiary.LastName))) {
            return false;
        }

        return true;
    }

    function populateBeneficiaries(originalBeneficiaries) {

        return originalBeneficiaries.map((beneficiary, i) => {
            beneficiary.rowId = i + 1;
            beneficiary.MemberID = vm.member.MemberID;

            return beneficiary;
        });
    }

    function populateAddresses(originalAddresses) {

        return originalAddresses.map((address, i) => {
            address.rowId = i + 1;

            return address;
        });
    }

    function generateRowId(data) {
        let maxRowId = data.reduce((maxId, current) => {
            const rowId = current.rowId;
            if (!rowId) {
                return maxId;
            }

            return rowId > maxId ? rowId : maxId;
        },
            0);

        return maxRowId + 1;
    }

    function hasRole(role) {
        return accountService.isInRole(role);
    }

    function onPbmChange() {
        assignMatchingPricingLevel();
    }

    function assignMatchingPricingLevel() {
        var matchingPricingLevel = pbmGroupService.getMatchingGroupForMember(vm.pbmPricingLevels, vm.member);
        if (matchingPricingLevel) {
            vm.member.PricingLevel = matchingPricingLevel.pricingLevelId;
            vm.pricingLevelDisplayId = matchingPricingLevel.displayId;
        } else {
            vm.member.PricingLevel = -1;
            vm.pricingLevelDisplayId = -1;
        }
    }

    function onPricingLevelIdChange() {
		var currentPricingLevel = vm.pbmPricingLevels.find(pl => pl.displayId == vm.pricingLevelDisplayId);
        vm.member.PricingLevel = currentPricingLevel.pricingLevelId;
    }

    function filterPricingLevelsByPbmVendor(pbmPricingLevel) {
        return pbmGroupService.shouldFilterByPbm(pbmPricingLevel, vm.member);
    }
}

