angular
    .module('CareGuard')
    .service('getUrlService', getUrlService);

getUrlService.$inject = ['$state', '$stateParams'];

function getUrlService($state, $stateParams) {

    this.get = (stateName, urlParams = $stateParams) => {
        if (!stateName) return;

        return $state.href(stateName, urlParams, {absolute: true});
    };
}