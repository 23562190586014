angular
    .module('CareGuard')
    .constant('claimCloneTypeEnum', {
        PayInFull: 1,
        PayAtWCFS: 2,
        ManuallyUpdate: 3,

        Names: [
            { Value: 1, Name: "Clone to pay in full" },
            { Value: 2, Name: "Clone to pay at WCFS" },
            { Value: 3, Name: "Clone to manually update" }
        ]
    });