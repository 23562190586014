angular
        .module('CareGuard', [
            'ngSanitize',
            'ngMessages',
            'ngAnimate',
            'ui.router',
            'ui.bootstrap',
            'ui.mask',
            'WebStorage',
            'lumx',
            'angular-loading-bar',
            'googlechart',
            'rzModule',
            'anim-in-out',
            'angularUtils.directives.dirPagination',
            'angucomplete-alt',
            'angularFileUpload',
            'smart-grid',
            'ui.grid',
            'ui.grid.pagination',
            'ui.grid.autoResize',
            'ui.grid.selection',
            'ui.grid.edit',
            'ui.grid.cellNav',
            'angular-flatpickr',
            'cfp.loadingBar',
            'ngFileUpload'
]);