/**
* @name diaryService
* @desc chronograph Service.
**/
(function () {
    'use strict';

    angular
        .module('CareGuard')
        .factory('configService', configService);

    /** Inject services into service. */
    configService.$inject = ['$http', '$q'];

    /**
    * @name diaryService.
    * @desc chronograph Service.
    */
    function configService($http, $q) {
        var baseUrl = '/api/config/';
        var service = {
            get: get,
            getById: getById,
            save: save,
          //  remove: remove,
          //  archive: archive,
            setSelected: setSelected,
            getSelected: getSelected
        };
        var caseData = undefined;


        return service;

        /**
      * @name setSelected.
      * @desc set current selected case.
      * @param {object} caseInfo - selected case.
      */
        function setSelected(caseInfo) {          
            caseData = caseInfo;
        };

        /**
        * @name getSelected.
        * @desc get current selected case.
        */
        function getSelected() {
            return caseData;
        };

        /**
        * @name get.
        * @desc search cases.
        * @param {object} params - search params.
        */
        function get() {
            return $http.get(baseUrl + 'get/smartconfig');
        };

        /**
       * @name getByID.
       * @desc get case given Id.
       * @param {object} ) Id - Id of case.
       */
        function getById(Id) {
            return $http.get(baseUrl + 'getByID/' + Id);
        };

        /**
        * @name save.
        * @desc save case data.
        * @param {object} caseInfo - case to be saved.
        */
        function save(config) {        
            return $http.post(baseUrl + 'save', config);
        };
        

    }
})();