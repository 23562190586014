/**
* @name userService
* @desc User Service.
**/
(function () {
    'use strict';

    angular
        .module('CareGuard')
        .factory('userService', userService);

    /** Inject services into service. */
    userService.$inject = ['$http'];

    /**
    * @name userService.
    * @desc User Service.
    */
    function userService($http) {
        var baseUrl = '/api/users/';
        var selectedUser = undefined;
        var selectedCallback = undefined;
        var addNewCallback = undefined;

        var service = {
            setSelected: setSelected,
            getSelected: getSelected,
            setNewCallback: setNewCallback,
            fireNewCallback: fireNewCallback,
            get: get,
            getById: getById,
            getByRole: getByRole,
            save: save,
            activate: activate,
            getTeams: getTeams,
            getQuota: getQuota,
            saveQuota: saveQuota,
            assignTeam: assignTeam,
            unassignTeam: unassignTeam,
            getRoles: getRoles,
            saveRoles: saveRoles,
            getPermissions: getPermissions,
            savePermissions: savePermissions
        };

        return service;

        /**
        * @name setSelected.
        * @desc set current selected user.
        * @param {object} user - selected user.
        */
        function setSelected(user) {
            selectedUser = user;
            if (selectedCallback)
                selectedCallback(selectedUser);
        };

        /**
        * @name getSelected.
        * @desc get current selected user.
        * @param {function} func - call function when selected user is changed.
        */
        function getSelected(func) {
            selectedCallback = func;
            return selectedUser;
        };

        /**
        * @name setNewCallback.
        * @desc set callback when new user is added.
        * @param {function} func - call function when selected user is changed.
        */
        function setNewCallback(func) {
            addNewCallback = func;
        };

        /**
        * @name fireNewCallback.
        * @desc fires callback when new user is added.
        * @param {object} user - selected user.
        */
        function fireNewCallback(usr) {
            if (addNewCallback)
                addNewCallback(usr);
        };

        /**
        * @name get.
        * @desc get list of users.
        * @param {object} params - search parameter.
        * @return list of users.
        */
        function get(params) {
            return $http.get(baseUrl + 'get', { params: params });
        };

        /**
        * @name getByID.
        * @desc get a user given Id.
        * @param {object} id - id of the user to get.
        * @return single user.
        */
        function getById(id) {
            return $http.get(baseUrl + 'getById/' + id);
        };

        /**
        * @name getByRole.
        * @desc get users with specific role.
        */
        function getByRole(role) {
            return $http.get(baseUrl + 'getByRole/' + role);
        };

        /**
        * @name save.
        * @desc save a user.
        * @param {object} user - user to be saved.
        * @return saved user from database.
        */
        function save(user) {
            return $http.post(baseUrl + 'save', user);
        };

        /**
        * @name activate.
        * @desc activate a user.
        * @param {object} user - user to be saved.
        */
        function activate(user) {
            return $http.post(baseUrl + 'activate', user);
        };

        /**
        * @name getTeams.
        * @desc get teams for a user.
        * @param {string} userId - id of the user.
        */
        function getTeams(userId) {
            return $http.get(baseUrl + 'team/get/' + userId);
        };

        /**
        * @name getQuota.
        * @desc get Quota for a user.
        * @param {string} userId - id of the user.
        */
        function getQuota(userId) {
            return $http.get(baseUrl + 'quota/get/' + userId);
        };

        /**
        * @name assignTeam.
        * @desc assign a user to a team.
        * @param {string} userId - id of the user.
        */
        function assignTeam(user) {
            return $http.post(baseUrl + 'team/add', user);
        };

        /**
        * @name unassignTeam.
        * @desc remove user from a team.
        * @param {object} user - user with team information.
        */
        function unassignTeam(user) {
            return $http.post(baseUrl + 'team/remove', user);
        };

        /**
        * @name getRoles.
        * @desc get roles for a user.
        * @param {string} userId - id of the user.
        */
        function getRoles(userId) {
            return $http.get(baseUrl + 'role/get/' + userId);
        };

        /**
        * @name saveRoles.
        * @desc save user roles.
        * @param {object} user - user data with roles.
        */
        function saveRoles(user) {
            return $http.post(baseUrl + 'role/save', user);
        };

        /**
        * @name getPermissions.
        * @desc get permissions for a user.
        * @param {string} userId - id of the user.
        */
        function getPermissions(userId) {
            return $http.get(baseUrl + 'permission/get/' + userId);
        };

        /**
        * @name savePermissions.
        * @desc save user permissions.
        * @param {object} user - user data with permissions.
        */
        function savePermissions(user) {
            return $http.post(baseUrl + 'permission/save', user);
        };

        /**
      * @name saveQuota.
      * @desc save user MIn and Max case.
      * @param {object} user - user data with permissions.
      */
        function saveQuota(user) {
            return $http.post(baseUrl + 'quota/save', user);
        };
    }
})();