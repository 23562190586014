angular
    .module('CareGuard')
    .controller('notesController', notesController);

notesController.$inject = [
    'memberService',
    'memberFlagsService',
    'notesService',
    'lookupService',
    'memberData',
    'utilService',
    'claimService',
    'accountService',
    '$toastr',
    '$timeout',
    '$state',
    'getUrlService'];

function notesController(
    memberService,
    memberFlagsService,
    notesService,
    lookupService,
    memberData,
    utilService,
    claimService,
    accountService,
    $toastr,
    $timeout,
    $state,
    getUrlService) {

    let vm = this;

    vm.memberflags = [];
    vm.lookupdata = {};
    vm.claimnotetype = {
        notetype: [
            { Name: "Claims", DataSetValue: "Claims" }
        ]
    };
    vm.hasResult = false;
    vm.Error = false;
    vm.notes = [];
    vm.newNote = {};
    vm.newNotes = false;
    vm.noteFilter = {};
    vm.sortBy = 'CreatedDate';
    vm.sortDescending = true;
    vm.hasEditRole = false;
    vm.hasDeleteRole = false;
    vm.accountName = '';

    vm.currentState = $state.current.name;

    vm.getUrlFromState = getUrlFromState;
    vm.showPanel = showPanel;
    vm.clearNotes = clearNotes;
    vm.exportNotes = exportNotes;
    vm.clearFilters = clearFilters;
    vm.deleteNote = deleteNote;
    vm.changeSort = changeSort;
    vm.clearInvalidClaimIDFlag = clearInvalidClaimIDFlag;
    vm.navigate = navigate;

    vm.enableNoteEdit = enableNoteEdit;
    vm.cancelNoteEdit = cancelNoteEdit;
    vm.addNote = addNote;
    vm.updateNote = updateNote;
    vm.filteredNotes = filteredNotes;

    // Since this data is not stored explicitly in the database, I need to hardcode all available dynamics note types here...
    const dynamicsNoteTypes = [
        { DataSetValue: 'Onboarding Email' },
        { DataSetValue: 'Membership Email' },
        { DataSetValue: 'Opportunity Email' },
        { DataSetValue: 'Case Email' },
        { DataSetValue: 'Contact Email' },
        { DataSetValue: 'Onboarding Note' },
        { DataSetValue: 'Contact Note' },
        { DataSetValue: 'Membership Note' },
        { DataSetValue: 'Opportunity Note' },
        { DataSetValue: 'Case Note' },
        { DataSetValue: 'Onboarding Task' },
        { DataSetValue: 'Membership Task' },
        { DataSetValue: 'Opportunity Task' },
        { DataSetValue: 'Case Task' },
        { DataSetValue: 'Contact Task' },
        { DataSetValue: 'Onboarding Phone Call' },
        { DataSetValue: 'Membership Phone Call' },
        { DataSetValue: 'Opportunity Phone Call' },
        { DataSetValue: 'Case Phone Call' },
        { DataSetValue: 'Contact Phone Call' }
    ];

    (function init() {
        const req = {
            LookupCategories: [
                "accountstatus",
                "followupcontacttype",
                "noteType"
            ]
        };

        const promises = [
            lookupService.getLookUpsBatch(req),
            memberService.getById(memberData),
            memberFlagsService.getMemberFlags(memberData),
            notesService.getMemberNotes(memberData),
            accountService.isInRole('EditNote'),
            accountService.isInRole('DeleteNote'),
            accountService.getAccountName()
        ];

        return Promise.all(promises).then(result => {
            for (let key in result[0]) {
                vm.lookupdata[key.toLowerCase()] = result[0][key];
            }

            vm.lookupdata.allNoteTypes = vm.lookupdata.notetype.concat(dynamicsNoteTypes);

            vm.hasResult = true;

            vm.member = result[1].Data;
            vm.memberflags = result[2].Data;

            let notes = result[3].data;

            vm.hasEditRole = result[4];
            vm.hasDeleteRole = result[5];
            vm.accountName = result[6];

            notes.forEach(note => {
                note.IsEditable = isNoteEditable(note);
                note.IsDeletable = isNoteDeletable(note);
                note.FullNoteText = (note.Subject ? note.Subject : '') + ' ' + (note.NoteText ? note.NoteText : '');
            });

            vm.notes = notes;
        });
    })();

    function isNoteEditable(note) {
        return note.NoteMasterType === 'CareHub' && (vm.hasEditRole || note.CreatedBy === vm.accountName);
    }

    function isNoteDeletable(note) {
        return note.NoteMasterType === 'CareHub' && (vm.hasDeleteRole || note.CreatedBy === vm.accountName);
    }

    function getUrlFromState(stateName, params) {
        return getUrlService.get(stateName, params);
    }

    function enableNoteEdit(note) {
        vm.notes.map(n => {
            if (n.isEditActive && n.NoteID !== note.NoteID && n.originalNote) {
                Object.assign(n, n.originalNote);
            }

            n.originalNote = {};
            n.isEditActive = false;
        });

        note.originalNote = {
            ClaimID: note.ClaimID,
            UpdatedBy: note.UpdatedBy,
            UpdateDate: note.UpdateDate,
            Amount: note.Amount,
            NoteType: note.NoteType,
            NoteText: note.NoteText,
            FullNoteText: note.FullNoteText
        }

        note.isEditActive = true;
    }

    function cancelNoteEdit(note) {
        Object.assign(note, note.originalNote);
        note.originalNote = {};
        note.isEditActive = false;
    }

    function updateNote(note) {
        let promise;

        if (note.ClaimID && note.ClaimID !== note.originalNote.ClaimID) {
            promise = validateClaimID(note.ClaimID);
        }

        Promise.resolve(promise).then(res => {
            if (res) {
                const isExistingClaim = res.isExistingClaim;
                const isClaimOfMember = res.isClaimOfMember;

                vm.invalidClaimIDFlag = !isExistingClaim || !isClaimOfMember;

                if (!isExistingClaim) {
                    $toastr.show('The claim identified does not exist. Please enter a valid Claim ID.', 'warning');
                    return;
                }

                if (!isClaimOfMember) {
                    $toastr.show('The claim identified is not associated with this Member. Please enter a valid Claim ID for this account.', 'warning');
                    return;
                }
            }

            // Clear Amount if it's $0.00
            note.Amount = !note.Amount ? null : note.Amount;
            note.NoteText = note.FullNoteText;

            return notesService.updateNote(note);
        }).then(result => {
            note.UpdateDate = result.UpdateDate;
            note.UpdatedBy = result.UpdatedBy;
            note.originalNote = {};
            note.isEditActive = false;
        });
    }

    function deleteNote(noteToDelete) {
        if (!noteToDelete.NoteID) { return; }

        if (confirm(`Are you sure you want to delete Note [${noteToDelete.NoteID}-${noteToDelete.NoteText}] from this Member?`)) {
            notesService.deleteNote(noteToDelete.NoteID).then(result => {
                // Remove note from view model list
                vm.notes = vm.notes.filter(note => note.NoteID !== noteToDelete.NoteID);
            });
        }
    }

    function showPanel(isShowPanel) {
        vm.newNotes = isShowPanel;
    }

    function clearNotes() {
        vm.newNotes = false;
        vm.newNote = {};
        clearInvalidClaimIDFlag();
    }

    function clearFilters() {
        vm.noteFilter = {};
    }

    function filteredNotes() {
        let filteredNotes = [];

        if (vm.noteFilter.allNoteTypes) {
            filteredNotes = vm.notes.filter(note => note.NoteType.toLowerCase() === vm.noteFilter.allNoteTypes.toLowerCase());
        }
        if (vm.noteFilter.claimId) {
            filteredNotes = vm.notes.filter(note => (note.ClaimID || '').toString().includes(vm.noteFilter.claimId));
        }
        if (vm.noteFilter.createdBy) {
            filteredNotes = vm.notes.filter(note => note.CreatedBy && note.CreatedBy.toLowerCase().includes(vm.noteFilter.createdBy.toLowerCase()));
        }
            
        return !vm.noteFilter.allNoteTypes && !vm.noteFilter.claimId && !vm.noteFilter.createdBy ? vm.notes : filteredNotes;
    }

    function exportNotes() {
        if (!vm.notes) { return; }

        const exportOptions = {
            method: 'POST',
            url: '/api/notes/exportNotes/',
            data: vm.filteredNotes()
        };

        utilService.download(exportOptions);
    }

    function addNote(note) {
        note.MemberID = memberData;

        if (note.ClaimID) {
            validateClaimID(note.ClaimID).then(isValidClaimId => {
                vm.invalidClaimIDFlag = !isValidClaimId;

                if (!isValidClaimId) {
                    $toastr.show('The claim identified is not associated with this member. Please enter a valid claim Id for this account.', 'warning');
                    return;
                }

                addNoteData(note);
            });
        } else {
            addNoteData(note);
        }
    }

    function addNoteData(note) {
        notesService.addNote(note).then(result => {
            let updatedNewNote = result;
            updatedNewNote.FullNoteText = result.NoteText;
            updatedNewNote.NoteMasterType = 'CareHub';
            updatedNewNote.IsEditable = isNoteEditable(updatedNewNote);
            updatedNewNote.IsDeletable = isNoteDeletable(updatedNewNote);

            vm.notes.push(updatedNewNote);
            vm.newNote = {};
            vm.newNotes = false;
        });
    }

    function validateClaimID(claimID) {
        return getClaimByClaimId(claimID).then(({ data: { items: claims } }) => {
            return claims?.length;
        });
    }

    function getClaimByClaimId(claimID) {
        return claimService.searchClaims({ claimIDs: [ claimID ], fields: `id`, pageSize: 10000 });
    }

    function clearInvalidClaimIDFlag() {
        vm.invalidClaimIDFlag = null;
    }

    function navigate(state, params) {
        $timeout(() => $state.go(state, params), 0);
    }

    function changeSort(column) {
        vm.sortBy = column;
        vm.sortDescending = !vm.sortDescending;
    }
}