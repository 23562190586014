/**
* @name caseService
* @desc Case Service.
**/
(function () {
    'use strict';

    angular
        .module('CareGuard')
        .factory('caseService', caseService);

    /** Inject services into service. */
    caseService.$inject = ['$http', '$q'];

    /**
    * @name caseService.
    * @desc Case Service.
    */ 
    function caseService($http, $q) {
      var baseUrl = '/api/case/';
      var caseData = undefined;

        var service = {
            get: get,
            getById: getById,
            save: save,
            test: test,
            getCase: getCase,
            setCase: setCase
        };
        
        return service;
        
        /**
        * @name get.
        * @desc search cases.
        * @param {object} params - search params.
        */
        function get(params) {
            return $http.get(baseUrl + 'get', { params: params });
        };

        /**
        * @name getByID.
        * @desc get case given Id.
        * @param {object} ) Id - Id of case.
        */
        function getById(Id) {
            return $http.get(baseUrl + 'getByID/' + Id);
        };
        
        /**
        * @name save.
        * @desc save case data.
        * @param {object} caseInfo - case to be saved.
        */
        function save(caseInfo) {
            return $http.post(baseUrl + 'save', caseInfo);
        };

        /**
        * @name save.
        * @desc save case data.
        * @param {object} caseInfo - case to be saved.
        */
        function test() {
            return $http.post(baseUrl + 'testdata');
        };

        /**
        * @name getCase.
        * @desc get case.
        * @param {object} caseInfo - case to be saved.
        */
        function getCase() {
            return caseData;
        };
        
        /**
        * @name setCase.
        * @desc set case.
        * @param {object} caseInfo - case to be stored.
        */
        function setCase(caseInfo) {
            caseData = caseInfo;
        };
    }
})();