(function (window, angular, undefined) {
    /* Start angularWebStorage */
    'use strict';
    var webStorage = angular.module('WebStorage', []);

    webStorage.provider('webStorageService', function () {
        // You should set a prefix to avoid overwriting any web storage variables from the rest of your app
        // e.g. webStorageServiceProvider.setPrefix('youAppName');
        // With provider you can use config as this:
        // commodify.config(function (webStorageServiceProvider) {
        //    webStorageServiceProvider.prefix = 'yourAppName';
        // });
        this.prefix = 'ws';

        // Cookie options (usually in case of fallback)
        // expiry = Number of days before cookies expire // 0 = Does not expire
        // path = The web path the cookie represents.
        this.cookie = {
            expiry: 30,
            path: '/'
        };

        // Storage type: sessionStorage, localStorage, cookie
        this.storageType = 'sessionStorage';

        // If should fallback to cookie
        this.cookieFallback = true;

        // Setter for the prefix
        this.setPrefix = function (prefix) {
            this.prefix = prefix;
        };

        // Setter for storage type
        this.setStorageType = function (storageType) {
            if (storageType == 'localStorage' || storageType == 'sessionStorage' || storageType == 'cookie')
                this.storageType = storageType;
            else
                throw 'WebStorage: Invalid storage type: ' + storageType;
        };

        // Setter for cookie fallback
        this.setCookieFallback = function (fallback) {
            this.cookieFallback = fallback;
        };

        // Setter for cookie config
        this.setCookieConfig = function (exp, path) {
            this.cookie = {
                expiry: exp,
                path: path
            };
        };
        
        this.$get = ['$rootScope', '$log', function ($rootScope, $log) {

            var prefix = this.prefix;
            var cookie = this.cookie;
            var storageType = this.storageType;
            var cookieFallback = this.cookieFallback;

            // If there is a prefix set in the config lets use that with an appended period for readability
            if (prefix.substr(-1) !== '.') {
                prefix = !!prefix ? prefix + '.' : '';
            }

            // Checks the browser to see if storage is supported
            var browserSupportsStorage = (function () {
                if (storageType == 'cookie') {
                    return browserSupportsCookies;
                }

                try {

                    // check if localStorage or sessionStorage is supported
                    var supported = (storageType in window && window[storageType] !== null);
                    var webStorage = window[storageType];

                    // When Safari (OS X or iOS) is in private browsing mode, it appears as though WebStorage
                    // is available, but trying to call .setItem throws an exception.
                    var key = prefix + '__' + Math.round(Math.random() * 1e7);
                    if (supported) {
                        webStorage.setItem(key, '');
                        webStorage.removeItem(key);
                    }

                    return true;
                } catch (e) {
                    $log.warn(e.message);
                    return false;
                }
            }());

            // Directly adds a value to storage
            // If cookie fallback is enabled then fallback to cookies
            var add = function (key, value) {
                if (storageType == 'cookie') {
                    return addToCookies(key, value);
                }

                if (!browserSupportsStorage && cookieFallback) {
                    return addToCookies(key, value);
                }

                // Let's convert undefined values to null to get the value consistent
                if (typeof value === "undefined") {
                    value = null;
                }

                try {
                    if (angular.isObject(value) || angular.isArray(value)) {
                        value = angular.toJson(value);
                    }
                    var webStorage = window[storageType];
                    webStorage.setItem(prefix + key, value);

                } catch (e) {
                    $log.warn(e.message);
                    return addToCookies(key, value);
                }
                return true;
            };

            // Directly get a value from web storage
            // if cookie fallback is enabled then get from cookie
            var get = function (key) {
                if (storageType == 'cookie') {
                    return getFromCookies(key);
                }

                if (!browserSupportsStorage && cookieFallback) {
                    return getFromCookies(key);
                }

                try {
                    var webStorage = window[storageType];
                    var item = webStorage.getItem(prefix + key);
                    // angular.toJson will convert null to 'null', so a proper conversion is needed
                    // FIXME not a perfect solution, since a valid 'null' string can't be stored
                    if (!item || item === 'null') {
                        return null;
                    }

                    if (item.charAt(0) === "{" || item.charAt(0) === "[") {
                        return angular.fromJson(item);
                    }

                    return item;
                } catch (e) {
                    $log.warn(e.message);
                }
            };

            // Remove an item from web storage
            // if cookie fallback is enabled then remove cookie.
            var remove = function (key) {
                if (storageType == 'cookie') {
                    return removeFromCookies(key);
                }

                if (!browserSupportsStorage && cookieFallback) {
                    return removeFromCookies(key);
                }

                try {
                    var webStorage = window[storageType];
                    webStorage.removeItem(prefix + key);

                } catch (e) {
                    $log.warn(e.message);
                    return removeFromCookies(key);
                }
                return true;
            };

            // Return array of keys for local storage
            var getKeys = function () {
                if (!browserSupportsStorage) {
                    $log.warn('Web storage not supported.');
                    return false;
                }

                var prefixLength = prefix.length;
                var keys = [];
                var webStorage = window[storageType];
                for (var key in webStorage) {
                    // Only return keys that are for this app
                    if (key.substr(0, prefixLength) === prefix) {
                        try {
                            keys.push(key.substr(prefixLength));
                        } catch (e) {
                            $log.warn(e.Description);
                            return [];
                        }
                    }
                }
                return keys;
            };

            // Remove all data for this app from local storage
            // Also optionally takes a regular expression string and removes the matching key-value pairs
            var clearAll = function (regularExpression) {
                if (storageType == 'cookie') {
                    return clearAllFromCookies();
                }

                var regularExpression = regularExpression || "";
                //accounting for the '.' in the prefix when creating a regex
                var tempPrefix = prefix.slice(0, -1) + "\.";
                var testRegex = RegExp(tempPrefix + regularExpression);

                if (!browserSupportsStorage && cookieFallback) {
                    return clearAllFromCookies();
                }

                var prefixLength = prefix.length;
                var webStorage = window[storageType];

                for (var key in webStorage) {
                    // Only remove items that are for this app and match the regular expression
                    if (testRegex.test(key)) {
                        try {
                            remove(key.substr(prefixLength));
                        } catch (e) {
                            return clearAllFromCookies();
                        }
                    }
                }
                return true;
            };

            // Checks the browser to see if cookies are supported
            var browserSupportsCookies = function () {
                try {
                    return navigator.cookieEnabled ||
                      ("cookie" in document && (document.cookie.length > 0 ||
                      (document.cookie = "test").indexOf.call(document.cookie, "test") > -1));
                } catch (e) {
                    $log.warn(e.message);
                    return false;
                }
            };

            // Directly adds a value to cookies
            // Typically used as a fallback is web storage is not available in the browser
            var addToCookies = function (key, value) {

                if (typeof value === "undefined") {
                    return false;
                }

                if (!browserSupportsCookies()) {
                    $log.warn('Cookies not supported.');
                    return false;
                }

                try {
                    var expiry = '',
                        expiryDate = new Date();

                    if (value === null) {
                        // Mark that the cookie has expired one day ago
                        expiryDate.setTime(expiryDate.getTime() + (-1 * 24 * 60 * 60 * 1000));
                        expiry = "; expires=" + expiryDate.toGMTString();
                        value = '';
                    } else if (cookie.expiry !== 0) {
                        expiryDate.setTime(expiryDate.getTime() + (cookie.expiry * 24 * 60 * 60 * 1000));
                        expiry = "; expires=" + expiryDate.toGMTString();
                    }
                    if (!!key) {
                        document.cookie = prefix + key + "=" + encodeURIComponent(value) + expiry + "; path=" + cookie.path;
                    }
                } catch (e) {
                    $log.warn(e.message);
                    return false;
                }
                return true;
            };

            // Directly get a value from a cookie
            var getFromCookies = function (key) {
                if (!browserSupportsCookies()) {
                    $log.warn('Cookies not supported.');
                    return false;
                }

                var cookies = document.cookie.split(';');
                for (var i = 0; i < cookies.length; i++) {
                    var thisCookie = cookies[i];
                    while (thisCookie.charAt(0) === ' ') {
                        thisCookie = thisCookie.substring(1, thisCookie.length);
                    }
                    if (thisCookie.indexOf(prefix + key + '=') === 0) {
                        return decodeURIComponent(thisCookie.substring(prefix.length + key.length + 1, thisCookie.length));
                    }
                }
                return null;
            };

            // Remove cookie
            var removeFromCookies = function (key) {
                addToCookies(key, null);
            };

            // Remove all cookies
            var clearAllFromCookies = function () {
                var thisCookie = null, thisKey = null;
                var prefixLength = prefix.length;
                var cookies = document.cookie.split(';');
                for (var i = 0; i < cookies.length; i++) {
                    thisCookie = cookies[i];

                    while (thisCookie.charAt(0) === ' ') {
                        thisCookie = thisCookie.substring(1, thisCookie.length);
                    }

                    var key = thisCookie.substring(prefixLength, thisCookie.indexOf('='));
                    removeFromCookies(key);
                }
            };

            return {
                isSupported: browserSupportsStorage,
                set: add,
                get: get,
                keys: getKeys,
                remove: remove,
                clearAll: clearAll
            };
        }]
    });
})(window, window.angular);
