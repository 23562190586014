/**
* @name companyGridService
* @desc Company grid Service.
**/
(function () {
    'use strict';

    angular
        .module('CareGuard')
        .factory('companyGridService', companyGridService);

    /**
    * @name companyGridService.
    * @desc Company grid Service.
    */
    function companyGridService() {
        var company = undefined;
        var companies = undefined;
        var selectedCallback = undefined;
        var updateCallback = undefined;

        var service = {
            getCompany: getCompany,
            setCompany: setCompany,
            getCompanies: getCompanies,
            setCompanies: setCompanies,
            setUpdateCallback: setUpdateCallback,
            fireUpdateCallback: fireUpdateCallback
        };

        return service;

        /**
        * @name setCallback.
        * @desc set callback when record is added.
        * @param {function} func - call function when selected company is changed.
        */
        function setUpdateCallback(func) {
            updateCallback = func;
        };

        /**
        * @name fireCallback.
        * @desc fires callback when record is added.
        * @param {object} case - selected company.
        */
        function fireUpdateCallback(caseData) {
            if (updateCallback) {
                updateCallback(caseData);
            };
        };

        /**
        * @name getCompany.
        * @desc return the Company.
        */
        function getCompany() {
            return company;
        };

        /**
        * @name setCompany.
        * @desc store the Company.
        * @param {object} data - company to be stored.
        */
        function setCompany(data) {
            company = data;
        };

        /**
        * @name setCompanies.
        * @desc store Companies.
        * @param {array} data - Companies to be stored.
        */
        function setCompanies(data) {
            companies = data;
        };

        /**
        * @name getCompanies.
        * @desc get Companies.
        */
        function getCompanies() {
            return companies;
        };
    };
})();