angular
    .module('CareGuard')
    .filter('percent', percent);

    function percent() {

        return function (value) {
            if (value != null) {
                return value + '%';
            } else {
                return;
            }
        };
    }