angular
    .module('CareGuard')
    .factory('benefitsService', benefitsService);

benefitsService.$inject = ['$http'];

function benefitsService($http) {
    const baseUrl = '/api/benefits/';
       
    return {
        getBenefits,
        filterBenefits,
    };

    function getBenefits(searchBy, codeType) {
        return $http.get(`${baseUrl}/getBenefits?searchBy=${searchBy}&codeType=${codeType}`);
    }
      
    function filterBenefits({ MemberID, IsPartOfMSA, CodeType, Code, Description, CodeDesc, PageSize, PageIndex, SortColumn, SortOrder }) {
        return $http.get(baseUrl + 'filterBenefits/' + MemberID + '/' + IsPartOfMSA + '/' + CodeType + '/' + Code + '/' + Description + '/' + CodeDesc + '/' + PageSize + '/' + PageIndex + '/' + SortColumn + '/' + SortOrder);
    }
}