angular
    .module('CareGuard')
    .factory('betaPageService', betaPageService);

betaPageService.$inject = ['$state', '$http', '$q', 'accountService', 'betaPageUserPreferences'];

function betaPageService($state, $http, $q, accountService, betaPageUserPreferences) {
    const _baseUrl = '/api/BetaPage';

    const ForceRedirectOptions = {
        None: 1,
        RedirectToBeta: 2,
        RedirectToOriginal: 3
    };

    let _forceRedirectState = ForceRedirectOptions.None;

    const _betaStatesMap = new Map();
    const _originalStatesMap = new Map();

    $state.get().forEach(state => {
        if (state.betaStateRedirect) {

            const betaStateInfo = {
                originalStateName: state.name,
                betaStateName: state.betaStateRedirect.name,
                pageId: state.betaStateRedirect.pageId,
                showBetaByDefault: state.betaStateRedirect.showBetaByDefault
            };

            _betaStatesMap.set(betaStateInfo.betaStateName, betaStateInfo);
            _originalStatesMap.set(betaStateInfo.originalStateName, betaStateInfo);
        }
    });

    const _userPreferencesCache = new Map();

    function changeState(toState) {
        const deferred = $q.defer();

        if (!toState) {
            deferred.resolve();
            return deferred.promise;
        }

        const betaState = _betaStatesMap.get(toState.name) || _originalStatesMap.get(toState.name);

        if (!betaState) {
            deferred.resolve(toState);
            return deferred.promise;
        }

        return resolveNextStateName(betaState).then(stateName => {
            deferred.resolve($state.get(stateName));
            return deferred.promise;
        });
    }

    function resolveNextStateName(betaState) {
        if (_forceRedirectState === ForceRedirectOptions.RedirectToBeta) {
            _forceRedirectState = ForceRedirectOptions.None;

            return $q.when(betaState.betaStateName);
        }

        if (_forceRedirectState === ForceRedirectOptions.RedirectToOriginal) {
            _forceRedirectState = ForceRedirectOptions.None;

            return $q.when(betaState.originalStateName);
        }

        return getBetaPageUserPreferenceThroughCache(betaState.pageId).then(userPreference => {
            if (userPreference === betaPageUserPreferences.BetaPage) {
                return betaState.betaStateName;
            }

            if (userPreference === betaPageUserPreferences.OriginalPage) {
                return betaState.originalStateName;
            }

            if (betaState.showBetaByDefault) {
                return betaState.betaStateName;
            }

            return betaState.originalStateName;
        });
    }

    function getBetaPageUserPreferenceThroughCache(pageId) {
        const userId = +accountService.getUserId();

        const cachedUserPreference = getCachedUserPreference(pageId, userId);

        if (cachedUserPreference) {
            return $q.when(cachedUserPreference);
        }

        return getBetaPageUserPreference(pageId).then(response => {
            const userPreference = response && response.Data && response.Data.DefaultPagePreference;

            if (userPreference !== betaPageUserPreferences.None) {
                addUserPreferenceToCache(userPreference, pageId, userId);
            }

            return userPreference;
        });
    }

    function getCachedUserPreference(pageId, userId) {
        const userCache = _userPreferencesCache.get(userId) || new Map();

        return userCache.get(pageId);
    }

    function addUserPreferenceToCache(userPreference, pageId, userId) {
        const userCache = _userPreferencesCache.get(userId) || new Map();

        _userPreferencesCache.set(userId, userCache);

        userCache.set(pageId, userPreference);
    }

    function navigateToOriginalPage() {
        const stateName = $state.current.name;

        const betaState = _betaStatesMap.get(stateName);

        if (!betaState) {
            throw Error('Page not found for redirect.');
        }

        _forceRedirectState = ForceRedirectOptions.RedirectToOriginal;

        $state.reload();
    }

    function navigateToBetaPage() {
        const stateName = $state.current.name;

        const betaState = _originalStatesMap.get(stateName);

        if (!betaState) {
            throw Error('Page not found for redirect.');
        }

        _forceRedirectState = ForceRedirectOptions.RedirectToBeta;

        $state.reload();
    }

    function isBetaPageOpened() {
        const stateName = $state.current.name;

        return _betaStatesMap.has(stateName);
    }

    function isOriginalPageOpened() {
        const stateName = $state.current.name;

        return _originalStatesMap.has(stateName);
    }

    function getBetaPageUserPreference(pageId) {
        return $http.get(`${_baseUrl}/GetPageUserPreference?pageId=${pageId}`);
    }

    function saveBetaPageUserPreference(pageId, userPreference) {
        return $http.post(`${_baseUrl}/SavePageUserPreference?pageId=${pageId}&userPreference=${userPreference}`);
    }

    function isPageSetAsDefaultForUser() {
        const stateName = $state.current.name;
        const betaState = _betaStatesMap.get(stateName) || _originalStatesMap.get(stateName);

        if (!betaState) return $q.when(false);

        return getBetaPageUserPreferenceThroughCache(betaState.pageId).then(userPreference => {
            if (userPreference === betaPageUserPreferences.BetaPage) {
                return _betaStatesMap.has(stateName);
            }

            if (userPreference === betaPageUserPreferences.OriginalPage) {
                return _originalStatesMap.has(stateName);
            }

            if (betaState.showBetaByDefault) {
                return _betaStatesMap.has(stateName);
            }

            return _originalStatesMap.has(stateName);
        });
    }

    function setCurrentPageAsDefault() {
        const stateName = $state.current.name;
        const betaState = _betaStatesMap.get(stateName) || _originalStatesMap.get(stateName);

        if (!betaState) return $q.reject('Beta page is not found. Cannot set it as default.');

        const userPreference = _betaStatesMap.has(stateName)
            ? betaPageUserPreferences.BetaPage
            : betaPageUserPreferences.OriginalPage;

        return saveBetaPageUserPreference(betaState.pageId, userPreference).then(() => {
            const userId = +accountService.getUserId();

            addUserPreferenceToCache(userPreference, betaState.pageId, userId);
        });
    }

    return {
        isBetaPageOpened,
        isOriginalPageOpened,
        changeState,
        navigateToOriginalPage,
        navigateToBetaPage,
        isPageSetAsDefaultForUser,
        setCurrentPageAsDefault
    };
}
