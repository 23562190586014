angular
    .module('CareGuard')
    .controller('payeeApprovalController', payeeApproval);

payeeApproval.$inject = [
    '$scope',
    '$toastr',
    '$q',
    'payeeService',
    'addressService',
    'payeeStatusEnum',
    'accountService'
];

function payeeApproval(
    $scope,
    $toastr,
    $q,
    payeeService,
    addressService,
    payeeStatusEnum,
    accountService) {

    var vm = this;
    $scope.payeeStatusEnum = payeeStatusEnum;

    vm.payees = [];

    vm.filterData = {};
    vm.getPayees = getPayees;
    vm.refresh = refresh;
    vm.submitPayeeStatusDecision = submitPayeeStatusDecision;
    vm.anyPayeesSelected = anyPayeesSelected;
    vm.allPayeesSelected = false;
    vm.toggleAllPayeesSelection = toggleAllPayeesSelection;
    vm.anyPendingPayees = anyPendingPayees;
    vm.updateMasterCheckBox = updateMasterCheckBox;

    vm.filterData = {
        pageNumber: 1,
        pageSize: 10,
        searchBy: null,
        totalRows: 0,
        pageIndex: 1,
        totalPages: 1

    };
    vm.pageSizes = {
        data: [
            { Name: 5, Value: 5 },
            { Name: 10, Value: 10 },
            { Name: 20, Value: 20 },
            { Name: 50, Value: 50 },
            { Name: 100, Value: 100 },
            { Name: 1000, Value: 1000 }
        ]
    };

    init();

    function init() {
        vm.getPayees();
    }

    function getPayees() {
        return payeeService.getPayeesPendingReview(vm.filterData).then(({ data: { items: payees, ...meta } }) => {
            if (!payees) {
                $toastr.show(`No payees found in Pending status.`, `warning`);
                vm.payees = [];
                return;
            }

            return $q.all(payees.map(payee => addressService.getAddressesByPayeeId(payee.id))).then(results => {
                const payeesWithAddresses = payees.map((payee, index) => {
                    payee.address = results[index].data[0]; //picking first address
                    return payee;
                });

                assignValues({ meta, payeesWithAddresses });

                return $q.when(true);
            });
        });
    }

    function assignValues({ meta, payeesWithAddresses }) {
        vm.payees = payeesWithAddresses.map(payee => {
            payee.friendlyDisplayName = buildFriendlyDisplayName(payee.name);
            return payee;
        });
        updateMasterCheckBox();

        vm.filterData.totalRows = meta.totalRows;
        vm.filterData.totalPages = meta.totalPages;
        vm.filterData.pageNumber = meta.pageNumber;
    }

    function buildFriendlyDisplayName(name) {
        if (!name) return name;

        const maxTextLength = 65;

        return name.length < maxTextLength
            ? name
            : name.substring(0, maxTextLength) + `...`;
    }

    function refresh(pageNumber) {
        if (!pageNumber || pageNumber < 1 || pageNumber > vm.filterData.totalPages) return;
        vm.filterData.pageNumber = pageNumber;
        getPayees();
    };

    function getPendingPayees() {
        return vm.payees.filter(x => x.payeeStatus == payeeStatusEnum.Pending);
    }

    function updateMasterCheckBox() {
        var pendingPayees = getPendingPayees();
        vm.allPayeesSelected = pendingPayees.length && pendingPayees.every(x => x.isSelected);
    }

    function toggleAllPayeesSelection() {
        getPendingPayees().forEach(x => x.isSelected = vm.allPayeesSelected);
    }

    function anyPendingPayees() {
        return getPendingPayees().length > 0;
    }

    function anyPayeesSelected() {
        return vm.payees.some(x => x.isSelected);
    }

    function submitPayeeStatusDecision(newPayeeStatus) {

        var selectedPayees = vm.payees.filter(x => x.isSelected);

        if (selectedPayees.length == 0) {
            $toastr.show('No payees selected', 'error');
            return;
        }

        var currentUser = accountService.getAccountName();

        var selfCreatedPayees = selectedPayees.filter(x => x.createdBy == currentUser).length;
        var payeesToProcess = selectedPayees.filter(x => x.createdBy != currentUser);
        var payeeIdsToProcess = payeesToProcess.map(x => x.id);

        var successCount = 0, failedCount = 0, alreadyReviewedCount = 0;

        var verifyAndUpdateEligiblePayees = !payeeIdsToProcess.length ? $q.when(true) :
            payeeService.getPayeesByIdsByBulk({ ids: payeeIdsToProcess }).then(({ data: payees }) => {
                return $q.all(payees.map(payee => {
                    if (payee.payeeStatus != payeeStatusEnum.Pending) {
                        alreadyReviewedCount++;     //payee was already approved/denied by some other user/session in background
                        return $q.when(true);
                    } else {
                        payee.payeeStatus = newPayeeStatus;
                        return payeeService.updatePayee(payee).then(x => {
                            successCount++;
                        }).catch(x => {
                            failedCount++;
                        });
                    }
                }))
            });

        verifyAndUpdateEligiblePayees.then(_ => {
            selfCreatedPayees &&
                $toastr.show(`Payee creation requires secondary approval. Please find another team member for assistance. ${selfCreatedPayees} payees were skipped from processing.`, 'error');
            successCount &&
                $toastr.show(`${successCount} payees updated successfully.`, 'success');
            alreadyReviewedCount &&
                $toastr.show(`${alreadyReviewedCount} payees were found to be already processed.`, 'warning');
            failedCount &&
                $toastr.show(`Error processing ${failedCount} payees.`, 'error');

            //refresh payees if any of them were updated
            if (successCount || alreadyReviewedCount) {
                getPayees().then(() => {
                    //marking the failed payees back again
                    vm.payees.forEach(payee => {
                        if (selectedPayees.some(x => x.id == payee.id) &&           //payee was selected for processing and
                            payee.payeeStatus == payeeStatusEnum.Pending) {         //is still in pending status (didn't process)
                            payee.isSelected = true;                                //so keep it marked after refresh
                        }
                    });
                    updateMasterCheckBox();
                });
            }
        });
    }
}