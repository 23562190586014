import claimDetailLineItemsTemplate from './lineItemsForClaimDetail.html';
import newClaimLineItemsTemplate from './lineItemsForNewClaim.html';

angular
    .module('CareGuard')
    .directive('lineItemsForClaimDetail', lineItemsForClaimDetail)
    .directive('lineItemsForNewClaim', lineItemsForNewClaim);

function lineItemsForClaimDetail() {
    return {
        restrict: 'E',
        scope: {
            claim: '=',
            member: '=',
            lookupdata: '=',
            claimDetailStatuses: '=?',
            reductions: '=',
            reloadClaim: '&',
            isClaimDetail: '='
        },
        template: claimDetailLineItemsTemplate,
        controller: 'lineItemsController',
        controllerAs: 'vm',
        bindToController: true
    };
}

function lineItemsForNewClaim() {
    return {
        restrict: 'E',
        scope: {
            claim: '=',
            lookupdata: '=',
            claimDetailStatuses: '=',
            reductions: '=',
            isClaimDetail: '='
        },
        template: newClaimLineItemsTemplate,
        controller: 'lineItemsController',
        controllerAs: 'vm',
        bindToController: true
    };
}