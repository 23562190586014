angular
    .module('CareGuard')
    .directive('popup', popup);

popup.$inject = ['LxDialogService', '$q'];

function popup(LxDialogService, $q) {
    const popupDialogIdPrefix = "popupDialogId";

    return {
        restrict: 'E',
        scope: {
            title: '=title',
            text: '=text',
            completeButtonText: '=completeButtonText',
            cancelButtonText: '=cancelButtonText',
            popupCompleteAction: '&completeAction',
            setOpenFunction: '&setOpenFunction'
        },
        transclude: true,
        link: {
            pre: (scope) => {

                const popupDialogId = popupDialogIdPrefix + scope.$id;

                scope.popupDialogId = popupDialogId;

                scope.completeAction = completeAction;

                scope.setOpenFunction({ openFunction: openPopup });

                function openPopup() {
                    LxDialogService.open(popupDialogId);
                }

                function completeAction() {
                    $q.when(scope.popupCompleteAction()).then(() => {
                        LxDialogService.close(popupDialogId);
                    });
                }
            }
        },
        templateUrl: 'app/careguard/directives/popup/popup.html'
    };
}