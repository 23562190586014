/**
* @name GridController
* @desc grid controller.
**/
(function () {
    'use strict';

    angular
        .module('CareGuard')
        .controller('sharepointDocumentsController', sharepointDocumentsController);

    /** Inject services into controller. */
    sharepointDocumentsController.$inject = [
        "sharepointDocumentService",
        "memberService",
        "memberFlagsService",
        "memberID"
    ];

    /**
    * @name GridController.
    * @desc grid controller.   
    */
    function sharepointDocumentsController(
        documentService,
        memberService,
        memberFlagsService,
        memberID) {

        // variables
        var vm = this;

        vm.member = {};
        vm.memberDocuments = {};
        
        vm.pageSizes = {
            data: [
                { Name: 5, Value: 5 },
                { Name: 10, Value: 10 },
                { Name: 20, Value: 20 },
                { Name: 50, Value: 50 },
                { Name: 100, Value: 100 },
                { Name: 500, Value: 500 },
                { Name: 1000, Value: 1000 }
            ]
        };

        vm.filterData = {
            TotalPages: 1,
            TotalRows: undefined,
            PageSize: 20,
            PageIndex: 1,
            SortColumn: 'CreatedOn',
            SortOrder: 'DESC',
            FileName: null
        };

        // functions    
        vm.changeSort = changeSort;
        vm.resetFilterInfo = resetFilterInfo;
        vm.refresh = refresh;
        
        vm.getMemberDocuments = getMemberDocuments;

        init();

        /* 
        * @name init
        * @desc initialize module
        */
        function init() {

            if (memberID) {
                memberService.getById(memberID).then(function (result) {
                    vm.member = result.Data;
                }).then(function(result) {
                    getMemberDocuments(result);
                        });
            }
            else {
                vm.member = undefined;
            }
                
            memberFlagsService.getMemberFlags(memberID).then(function (resultset) {
                vm.memberflags = resultset.Data;

            });

        };

        //Member Documents
        function getMemberDocuments() {
            if (!memberID) return;

            const { MemberNumber: memberNumber, FirstName: firstName, LastName: lastName } = vm.member;
            documentService.getSharepointDocuments({ memberNumber, firstName, lastName, paging: { ...vm.filterData }, filters: { ...vm.filterData } }, { ignoreLoadingBar: false }).then(resultset => {
                vm.memberDocuments = resultset.data.Rows;
                vm.filterData.TotalRows = resultset.data.TotalRows;
                vm.filterData.TotalPages = Math.ceil(vm.filterData.TotalRows / vm.filterData.PageSize);
            });
        };

        //sorts the columns
        function changeSort(column) {
            if (vm.filterData.SortColumn == column) {
                vm.filterData.SortOrder = (vm.filterData.SortOrder == 'ASC' ? 'DESC' : 'ASC');
            }
            else {
                vm.filterData.SortColumn = column;
                vm.filterData.SortOrder = 'ASC';
            }

            if (memberID) {
                getMemberDocuments();
            }
        };

        //refreshes or changes the page of data
        function refresh(page) {
            if (page == null || (page > 0 && page <= vm.filterData.TotalPages)) {
                vm.filterData.PageIndex = vm.filterData.TotalRows == 0 || page == null ? 1 : page;

                getMemberDocuments();
            }
        };

        //resets the filter data
        function resetFilterInfo() {
            vm.filterData.FileName = null;
        };
    };
})();