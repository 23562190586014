/**
* @name quoteController
* @desc quote controller.
**/
(function () {
    'use strict';

    angular
        .module('CareGuard')
        .controller('quoteController', quoteController);

    /** Inject services into controller. */
    quoteController.$inject = ['$state', '$timeout', '$q', '$toastr', 'LxDialogService', 'LxProgressService', 'lifecompanyService', 'chronographService',
                                'lookupService', 'validationService', 'accountService', 'contactService'];

    /**
    * @name quoteController.
    * @desc quote Controller.
    */
    function quoteController($state, $timeout, $q, $toastr, LxDialogService, LxProgressService, lifecompanyService, chronographService,
                             lookupService, validationService, accountService, contactService) {
        var vm = this;

        // variables
        var dialogId = 'dlgNewQuote';
        vm.case = undefined;
        vm.validation = {};
        vm.adjusters = undefined;
        vm.adjusterName = undefined;
        vm.lookupdata = undefined;
        vm.wizard = undefined;
        vm.flags = {
            isMSA: false,
            isOther: false
        };

        // functions
        vm.show = show;
        vm.stepDown = stepDown;
        vm.stepUp = stepUp;
        vm.updateType = updateType;
        vm.save = save;
        vm.goTo = goTo;
        vm.close = close;
        vm.selectCompany = selectCompany;
        vm.selectAdjuster = selectAdjuster;
        vm.request = request;

        init();

        /* 
        * @name init
        * @desc initialize module
        */
        function init() {
            // Load drop downs
            vm.lookupdata = {
                quoteTypes: lookupService.getByCategory('Quote Type'),
                genderTypes: [{ Key: 'M', Value: 'Male' }, { Key: 'F', Value: 'Female' }],
                companies: []
            };

            // update LC if an adjuster is quoting, this will udpate the validation rules
            if (accountService.isInRole('Adjuster')) {
                var companyId = accountService.getCompanyId();
                updateLifeCompanies(companyId);
            } else {
                vm.validation = {
                    Today: moment().format('MM/DD/YYYY'),
                    MinAmount: 10000,
                    MaxAge: 100
                };
            };
        };

        /* 
        * @name show
        * @desc show new quote dialog
        */
        function show() {
            vm.validation.duplicate = false;
            vm.validation.Id = undefined;
            vm.validation.same = undefined;

            // if non-adjuster is creating the quote then require them to input an Adjuster
            if (!accountService.isInRole('Adjuster')) {
                contactService.getAll().then(function (result) {
                    vm.adjusters = result;
                });
            };

            // Create new annuitant record for a new case
            vm.case = {
                Status: lookupService.getDefaultValue('Case Status'),
                Annuitants: [{
                    AnnuitantType: lookupService.getDefaultValue('Annuitant Type'),
                    //FirstName: 'test',
                    //LastName: 'last',
                    //BirthDate: '01/01/1980',
                    //Gender: 'M'
                }],
                PurchaseDate: moment().add(30, 'days').format('MM/DD/YYYY')
            };
            //vm.case.ClaimNumber = 'WC192xx';
            vm.case.SmartQuotes = {
                QuoteType: undefined,
                //StructureAmount: '100000',
                //SeedAmount: '1000',
                //AnnualAmount:'100'
                //AttorneyFees: 0,
                //LienAmount: 0,
                //UpfrontCash: 0
            };
            vm.adjusterName = undefined;
            vm.wizard = {
                step: 1
            };
            LxDialogService.open(dialogId);
        };

        /* 
        * @name stepDown
        * @desc go back in wizard
        */
        function stepDown() {
            vm.wizard.step = 1;
        };

        /* 
        * @name stepUp
        * @desc forward step in wizard
        */
        function stepUp(form) {
            if (form) {
                form.setAttempted(true);
                if (form.$invalid) {
                    $toastr.show('Please fix any errors before continuing.', 'error');
                    return;
                }
            };

            vm.validation.duplicate = false;
            vm.validation.Id = undefined;

            // check for duplicate claim number
            checkClaimNumber().then(function (result) {
                if (result && result.Id) {
                    vm.validation.duplicate = true;
                    vm.validation.id = result.Id;
                    vm.validation.isAccessible = result.IsAccessible;
                    return;
                }
                vm.wizard.step = 2;
                generate();
            });            
        };

        /* 
        * @name checkClaimNumber
        * @desc check for duplicate claim number
        */
        function checkClaimNumber() {
            var dfd = $q.defer();
            var data = {
                ClaimNumber: vm.case.ClaimNumber,
                CompanyId: vm.case.CompanyId
            };

            // check for duplicate claim#
            chronographService.checkClaimNumber(data).then(function (result) {
                dfd.resolve(result);
            }, function (error) {
                dfd.reject();
            });
            return dfd.promise;
        };

        /* 
        * @name generate
        * @desc generates the quote
        */
        function generate() {
            vm.wizard.hasError = false;
            vm.wizard.isSearching = true;
            vm.wizard.canQuote = true;

            // show progress circle
            $timeout(function () {
                LxProgressService.circular.show('#5fa2db', '#progress');
            }, 100);

            // create quote
            chronographService.generate(vm.case).then(function (result) {
                vm.case = result;
                vm.wizard.isSearching = false;
                calcPrice();
            }, function () {
                vm.wizard.hasError = true;
            })
            .finally(function () {
                vm.wizard.isSearching = false;
                $timeout(function () {
                    LxProgressService.circular.hide();
                }, 250);
            });
        };

        /* 
        * @name updateType
        * @desc update when case type changes
        */
        function updateType(val) {
            if (!val || !val.newValue) return;

            var caseType = val.newValue.toLowerCase()
            vm.flags = {};

            switch (caseType) {
                case 'msa':
                    vm.flags.isMSA = true;
                    break;
                default:
                    vm.flags.isOther = true;
                    break;
            };
        };

        /* 
        * @name save
        * @desc save new quote dialog
        */
        function save() {
            var count = Enumerable.from(vm.lookupdata.companies).count('$.IsSelected');
            if (count <= 0) {
                $toastr.show('Must select at least one Life Company.', 'error');
                return;
            };
            if (count > 1) {
                $toastr.show('Must select only one Life Company.', 'error');
                return;
            };

            var quote = vm.case.Quotes[0];
            var selectedCompany = Enumerable.from(vm.lookupdata.companies).where('$.IsSelected').firstOrDefault(undefined);

            // keep a copy, in case there is an error saving so we can reset.
            vm.caseCopy = angular.copy(vm.case, {});

            // add expiration date
            var expdays = 7;
            if (selectedCompany.Rules && selectedCompany.Rules.QuoteExpiration) {
                expdays = selectedCompany.Rules.QuoteExpiration;
            }
            quote.QuoteDate = moment().format('MM/DD/YYYY');
            quote.ExpirationDate = moment().add(expdays, 'day').format('MM/DD/YYYY');
            
            // add life companies to the quote
            addLifeCompanies(quote);

            // add selected LC's amounts to each benefit
            Enumerable.from(quote.Benefits).forEach(function (benefit) {
                benefit.validation = undefined;                
                var comp = Enumerable.from(benefit.LifeCompanies).where('$.Id == "' + selectedCompany.Id + '"').firstOrDefault(undefined);
                if (comp) {
                    benefit.CompanyId = comp.Id;
                    benefit.PaymentAmount = comp.PaymentAmount;
                    benefit.PremiumAmount = comp.PremiumAmount;
                    benefit.GuaranteedPayout = comp.GuaranteedPayout;
                    benefit.ExpectedPayout = comp.ExpectedPayout;
                } else {
                    benefit.CompanyId = undefined;
                    benefit.PaymentAmount = undefined;
                    benefit.PremiumAmount = undefined;
                    benefit.GuaranteedPayout = undefined;
                    benefit.ExpectedPayout = undefined;
                };
            });

            // calculate total cost
            // for msa calculate availabe funds - seed amount + benefit premium
            quote.TotalCost = Enumerable.from(vm.lookupdata.companies).where('$.IsSelected').sum('$.PremiumAmount || 0');
            quote.TotalCash = 0;
            if (quote.QuoteType == 'MSA') {
                quote.TotalCash += quote.SeedAmount || 0;
                quote.SettlementAmount = quote.TotalCost + quote.TotalCash;
            } else {
                quote.TotalCash += quote.AttorneyFees || 0;
                quote.TotalCash += quote.LienAmount || 0;
                quote.TotalCash += quote.CashAmount || 0;
            };
            quote.TotalCost += quote.TotalCash;
            quote.TotalCost = quote.TotalCost || 0;
            
            // save and go to chronograph on sucess
            return chronographService.save(vm.case).then(function (result) {
                vm.case = undefined;
                vm.caseCopy = undefined;
                goTo(result.Id)
            }, function () {
                // error handler
                vm.case = angular.copy(vm.caseCopy, {});
                vm.caseCopy = undefined;
                vm.wizard.hasError = true;
            });
        };

        /* 
        * @name goTo
        * @desc go to the chronograph link
        */
        function goTo(id) {
            if (!id) return;

            close();

            var options = {
                location: true,
                inherit: true,
                relative: $state.$current,
                reload: true,
                notify: true,
            };
            
            $timeout(function () {
                $state.transitionTo('layout.case.chronograph', { Id: id }, options);
            }, 250);
        };

        /* 
        * @name close
        * @desc close new quote dialog
        */
        function close() {
            LxDialogService.close(dialogId);
            vm.flags = {};
            vm.case = undefined;
        };

        /* 
        * @name selectCompany
        * @desc select life company that's passed in and unselect any others
        */
        function selectCompany(comp) {
            for (var key in vm.lookupdata.companies) {
                var obj = vm.lookupdata.companies[key];
                if (comp.Id != obj.Id) {
                    obj.IsSelected = false;
                };
            };
        };

        /* 
        * @name selectAdjuster
        * @desc select adjuster. 
        * @param {object} val - selected object.
        */
        function selectAdjuster(val) {
            if (!val) {
                vm.case.AdjusterId = undefined;
                vm.case.CompanyId = undefined;
                vm.adjusterName = undefined;
                return;
            }
            vm.case.AdjusterId = val.originalObject.Id;
            vm.case.CompanyId = val.originalObject.CompanyId;
            vm.adjusterName = val.originalObject.ContactName;

            // for non adjuster update LC panel, this will update validation rules
            if (!accountService.isInRole('Adjuster')) {
                updateLifeCompanies();
            };
        };

        /* 
        * @name request
        * @desc request the claim be re-assinged. 
        */
        function request() {
        };

        /* 
        * @name addLifeCompanies
        * @desc add Life Companies to the given quote
        */
        function addLifeCompanies(quote) {
            quote.LifeCompanies = [];
            Enumerable.from(vm.lookupdata.companies).forEach(function (comp) {
                quote.LifeCompanies.push({
                    Id: comp.Id,
                    Type: comp.Type,
                    IsAssigned: comp.IsAssigned,
                    IsSelected: comp.IsSelected,
                    PaymentAmount: comp.PaymentAmount,
                    PremiumAmount: comp.PremiumAmount,
                    GuaranteedPayout: comp.GuaranteedPayout,
                    ExpectedPayout: comp.ExpectedPayout,
                    IRR: comp.IRR
                });
            });
        };

        /* 
        * @name updateLifeCompanies
        * @desc update approved panel. 
        * @param {string} companyId - options companyId.
        */
        function updateLifeCompanies(companyId) {
            var id = vm.case != undefined ? vm.case.CompanyId : undefined;
            id = companyId != undefined ? companyId : id;
            if (id) {
                lifecompanyService.getPanel(id).then(function (panel) {
                    if (!panel || panel.length == 0) {
                        $toastr.show('Unable to find an approved Life Company panel.', 'error');
                        return;
                    };
                    vm.validation = {
                        Today: moment().format('MM/DD/YYYY'),
                        MinAmount: Enumerable.from(panel).min('$.Rules && $.Rules.MinPremiumAmount'),
                        MaxAge: Enumerable.from(panel).max('$.Rules && $.Rules.MaxAge')
                    };
                    vm.lookupdata.companies = Enumerable.from(panel).orderBy('$.Name').toArray();
                });
            };
        };

        /* 
        * @name calcPrice
        * @desc calculate best price
        */
        function calcPrice() {            
            var quote = vm.case.Quotes[0];

            // update lookupdata companies with results
            // used in validation service
            Enumerable.from(vm.lookupdata.companies).forEach(function (comp) {
                var lc = Enumerable.from(quote.LifeCompanies).where('$.Id == "' + comp.Id + '"').firstOrDefault(undefined);
                comp.PaymentAmount = lc ? lc.PremiumAmount : 0;
                comp.PremiumAmount = lc ? lc.PremiumAmount : 0;
                comp.GuaranteedPayout = lc ? lc.GuaranteedPayout : 0;
                comp.ExpectedPayout = lc ? lc.ExpectedPayout : 0;
                comp.IRR = lc ? lc.IRR : undefined;
            });
            
            validationService.validateAll({ 'case': vm.case, companies: vm.lookupdata.companies, quote: quote, validateAmount: true });

            // check valid companies
            var companies = [];
            Enumerable.from(vm.lookupdata.companies).forEach(function (comp) {
                comp.IsSelected = false;
                if (comp.validation && comp.validation.error) {
                    comp.PaymentAmount = undefined;
                    comp.PremiumAmount = undefined;
                    comp.GuaranteedPayout = undefined;
                    comp.ExpectedPayout = undefined;
                } else {
                    comp.PaymentAmount = 0;
                    comp.PremiumAmount = 0;
                    comp.GuaranteedPayout = 0;
                    comp.ExpectedPayout = 0;

                    // total all benefit amounts
                    Enumerable.from(quote.Benefits).forEach(function (benefit) {
                        var lc = Enumerable.from(benefit.LifeCompanies).where('$.Id == "' + comp.Id + '"').firstOrDefault(undefined);
                        if (lc) {
                            comp.PaymentAmount += lc.PaymentAmount;
                            comp.PremiumAmount += lc.PremiumAmount;
                            comp.GuaranteedPayout += lc.GuaranteedPayout;
                            comp.ExpectedPayout += lc.ExpectedPayout;
                        };
                    });

                    companies.push(comp);
                };
            });
            
            if (!companies || companies.length == 0) {
                vm.wizard.canQuote = false;
                return;
            };

            // select best life company
            // for MSA best price is lowest cost
            // for non-MSA it's the company with higest payment
            var bestCompany = undefined;
            var enabledCompanies = Enumerable.from(companies).where('$.IsEnabled');
            var defaultCompany = Enumerable.from(enabledCompanies).where('$.IsDefault').firstOrDefault(undefined);
            if (defaultCompany != undefined) {
                bestCompany = defaultCompany;
                bestCompany.IsSelected = true;
            }
            else if (quote.QuoteType == 'MSA' && enabledCompanies != undefined) {
                bestCompany = Enumerable.from(enabledCompanies).minBy('$.PremiumAmount');
                bestCompany.IsSelected = true;
            } else if (enabledCompanies != undefined) {
                bestCompany = Enumerable.from(enabledCompanies).maxBy('$.GuaranteedPayout');
                bestCompany.IsSelected = true;
            };
        };
    };
})();