/**
* @name annuitantGridController
* @desc annuitant grid controller.
**/
(function () {
    'use strict';

    angular
        .module('CareGuard')
        .controller('annuitantGridController', annuitantGridController)

    /** Inject services into controller. */
    annuitantGridController.$inject = ['LxDialogService', 'annuitantService'];

    /**
    * @name annuitantGridController.
    * @desc annuitant grid controller.   
    */
    function annuitantGridController(LxDialogService, annuitantService) {

        // variables
        
        var dialogId = 'dlgAnnuitantModal';
        vm.annuitants = undefined;

        // annuitant grid config
        var events = {
            add: show
        };

        vm.config = {
            selectionMode: 'none',
            rowNum: 15,
            filterable: false,
            dblClick: show,
            events: events
        };

        vm.columns = [
          { label: 'First Name', key: 'FirstName' },
          { label: 'Last Name', key: 'LastName' },
          { label: 'Type', key: 'AnnuitantType' },
          { label: 'Birth Date', key: 'BirthDate', formatFunction: 'date' },
          { label: 'Gender', key: 'Gender' },
        ];

        init();

        /* 
        * @name init
        * @desc initialize module
        */
        function init() {
            vm.annuitants = annuitantService.getAnnuitants();
        };

        /* 
        * @name edit
        * @desc open annuitant modal for adding/editing
        * @param {object} row - optional annuitant row from the grid
        */
        function show(annuitant) {
            annuitantService.setAnnuitant(annuitant);
            LxDialogService.open(dialogId);
        };
    };
})();