/**
* @desc Member On Hold.
**/
(function () {
	'use strict';

	angular
		.module('CareGuard')
		.factory('memberOnHoldService', memberOnHoldService);

	/** Inject services into service. */
	memberOnHoldService.$inject = ['$http'];

    /**
    * @name MemberOnHold Service.
    * @desc MemberOnHold Service.
    */
	function memberOnHoldService($http) {
		var baseUrl = '/api/memberonhold/';

		var service = {
			filterOnHolds: filterOnHolds,
			exportOnHolds: exportOnHolds
			
		};

		return service;


		function filterOnHolds(membernumber, lastname, firstname, pageindex, pagesize, sortcolumn, sortorder) {
			return $http.get(baseUrl + 'filterOnHolds/' + membernumber + '/' + lastname + '/' + firstname + '/' + pageindex + '/' + pagesize + '/' + sortcolumn + '/' + sortorder);
		};
		
		
		function exportOnHolds(membernumber, lastname, firstname) {
			return $http.get(baseUrl + 'exportOnHolds/' + membernumber + '/' + lastname + '/' + firstname + '/' );
		};
	
	};
})();