/**
* @name smartGridController
* @desc smart-grid Controller.
**/
(function () {
    'use strict';

    angular
        .module('smart-grid')
        .controller('smartGridController', smartGridController);

    /** Inject services into directive. */
    smartGridController.$inject = ['TableConfig', 'columnProvider', 'arrayUtility', '$filter'];

    /**
    * @name smartGridController.
    * @desc smart-grid Controller.
    */
    function smartGridController(defaultConfig, columnProvider, arrayUtility, $filter) {
        var vm = this,
            predicate = {},
            lastColumnSort;

        vm.columns = [];
        vm.dataCollection = vm.dataCollection || [];
        vm.displayedCollection = vm.rowCollection || []; //init empty array so that if pagination is enabled, it does not spoil performances
        vm.numberOfPages = 1;
        vm.currentPage = 1;
        vm.selectedRow = undefined;
        vm.totalRows = 0;
        vm.isSearching = false;

        vm.exportData = exportData;
        vm.update = update;
        vm.sort = sort;
        vm.remove = remove;
        vm.save = save;
        vm.search = search;
        vm.refresh = refresh;
        vm.setGlobalConfig = setGlobalConfig;
        vm.changePage = changePage;
        vm.insertColumn = insertColumn;
        vm.removeAll = removeAll;
        vm.removeColumn = removeColumn;
        vm.toggleSelection = toggleSelection;
        vm.toggleSelectionAll = toggleSelectionAll;
        vm.removeDataRow = removeDataRow;
        vm.moveDataRow = moveDataRow;
        vm.dblClick = dblClick;

        /**
        * @name exportData
        * @desc export grid data.
        */
        function exportData() {
            if ((vm.exportFunction && angular.isFunction(vm.exportFunction)) === true) {
                var sortPredicate, sortOrder;
                if (lastColumnSort) {
                    sortPredicate = lastColumnSort.sortPredicate;
                    sortOrder = lastColumnSort.sortDir;
                }
                vm.exportFunction(sortPredicate, sortOrder, predicate, vm.currentPage, vm.rowNum);
            }
        };
        /**
        * @name update
        * @desc update the row data and refresh grid.
        * @param {object} data - row data.
        */
        function update(data) {
            if (!data) return;
            vm.dataCollection = data;
            refresh();
        };

        /**
        * @name sort
        * @desc set column as the column used to sort the data (if it is already the case, it will change the reverse value).
        * @param {ColumnProvider} column - column data.
        */
        function sort(column) {
            var index = vm.columns.indexOf(column);
            if (index !== -1)
            {
                if (column.sortable === true) {
                    column.sortPredicate = column.sortPredicate || column.key;
                    column.sortDir = column.sortDir == 'asc' ? 'desc' : 'asc';
                    lastColumnSort = column;
                   
                    for (var col in vm.columns) {
                        if (vm.columns[col] == column)
                            vm.columns[col].sortDir = column.sortDir;
                        else
                            vm.columns[col].sortDir = undefined;
                       
                    }
                }
            }
            refresh();
        };

        /**
        * @name remove
        * @desc remove an item from the grid
        * @param {attr} attribute to check the value of to remove
        * @param {value} value to be checked for removal
        */
        function remove(attr, value) {
            for (var key in vm.displayedCollection) {
                var item = vm.displayedCollection[key];

                if (item[attr] && item[attr] == value) {
                    vm.displayedCollection.splice(key, 1);
                    break;
                }
            }

            vm.totalRows = vm.totalRows - 1;
        };

        /**
        * @name save
        * @desc add an item to the grid
        * @param {row} row to add to the grid
        */
        function save(attr, value, row) {
            for (var key in vm.displayedCollection) {
                var item = vm.displayedCollection[key];

                if (item[attr] && item[attr] == value) {
                    vm.displayedCollection.splice(key, 1);
                    vm.displayedCollection.unshift(row);
                    return;
                }
            }
            vm.totalRows = vm.totalRows + 1;
            vm.displayedCollection.push(row);
        };

        /**
         * @name search
         * @desc set the filter predicate used for searching.
         * @param {object} input - input data.
         * @param {ColumnProvider} column - column data.
         */
        function search(input, column) {
            //update column
            if (column && vm.columns.indexOf(column) !== -1) {
                column.filterPredicate = input;
            } else {
                for (var j = 0; j < vm.columns.length; j++) {
                    vm.columns[j].filterPredicate = undefined;
                }
            }

            for (var j = 0; j < vm.columns.length; j++) {
                if (vm.columns[j].filterPredicate) {
                    var key = vm.columns[j].filterKey ? vm.columns[j].filterKey : vm.columns[j].key;
                    predicate[key] = vm.columns[j].filterPredicate;
                }
                else {
                    var key = vm.columns[j].filterKey ? vm.columns[j].filterKey : vm.columns[j].key;
                    delete predicate[key];
                }
            }
            vm.currentPage = 1;
            refresh();
        };

        /**
         * @name refresh
         * @desc combine sort, search and limitTo operations on an array
         * @returns Array, an array result of the operations on input array
         */
        function refresh() {
            if ((vm.remoteDataFunction && angular.isFunction(vm.remoteDataFunction)) === true) {
                vm.isSearching = true;
                var sortPredicate, sortOrder;
                if (lastColumnSort) {
                    sortPredicate = lastColumnSort.sortPredicate;
                    sortOrder = lastColumnSort.sortDir;
                }
                var promise = vm.remoteDataFunction(sortPredicate, sortOrder, predicate, vm.currentPage, vm.rowNum);
                if (promise && promise.then) {
                    promise.then(function (returnData) {
                        vm.displayedCollection = returnData.Items;
                        vm.totalRows = returnData.ItemCount;
                        vm.numberOfPages = Math.ceil(returnData.ItemCount / vm.rowNum)
                        vm.isSearching = false;
                    });
                };
                //} else {
                //    output = promise;
                //    scope.numberOfPages = calculateNumberOfPages(output);
                //    scope.totalRows = output.length;
                //    return scope.isPaginationEnabled ? arrayUtility.fromTo(output, (scope.currentPage - 1) * scope.rowNum, scope.rowNum) : output;
                //}
            }
            else {
                // hack to cause a change in vm.columns
                var column = angular.copy(vm.columns, []);
                vm.columns = undefined;
                vm.columns = column;
                var filterFunction = (vm.filterFunction && angular.isFunction(vm.filterFunction)) === true ? vm.filterFunction : $filter('filter');
                var output = sortData(arrayUtility.filter(vm.dataCollection, filterFunction, predicate), lastColumnSort);
                vm.numberOfPages = calculateNumberOfPages(output);
                vm.totalRows = output == undefined ? 0 : output.length;
                vm.displayedCollection = vm.pageable ? arrayUtility.fromTo(output, (vm.currentPage - 1) * vm.rowNum, vm.rowNum) : output;
            }
        };

        /**
         * @name calculateNumberOfPages
         * @desc Calculate number of pages in the array.
         * @param {array} array - array of pages.
         * @returns int
         */
        function calculateNumberOfPages(array) {
            if (!angular.isArray(array)) {
                return 1;
            }
            if (array.length === 0 || vm.rowNum < 1) {
                return 1;
            }
            return Math.ceil(array.length / vm.rowNum);
        };

        /**
         * @name sortData
         * @desc Sort data, based on sortFunction or default.
         * @param {array} array, column
         * @param {ColumnProvider} column - column data.
         * @returns sorted array.
         */
        function sortData(array, column) {
            var sortAlgo = (vm.sortFunction && angular.isFunction(vm.sortFunction)) === true ? vm.sortFunction : $filter('orderBy');
            if (column) {
                return arrayUtility.sort(array, sortAlgo, column.sortPredicate, column.sortDir);
            } else {
                return array;
            }
        };

        /**
         * @name selectRow
         * @desc select a data row.
         * @param {array} array, column
         * @param {ColumnProvider} column - column data.
         */
        function selectRow(array, selectionMode, index, select) {
            var dataRow;
            vm.selectedRow = undefined;
            if ((!angular.isArray(array)) || (selectionMode !== 'multiple' && selectionMode !== 'single')) {
                return;
            }

            if (index >= 0 && index < array.length) {
                dataRow = array[index];
                if (selectionMode === 'single') {
                    //unselect all the others
                    for (var i = 0, l = array.length; i < l; i++) {
                        array[i].isSelected = false;
                    }
                    dataRow.isSelected = select;
                } else if (selectionMode === 'multiple') {
                    dataRow.isSelected = select;
                }

                if (dataRow.isSelected)
                    vm.selectedRow = dataRow;
            };
        };

        /**
        * @name setGlobalConfig
        * @desc set the config (config parameters will be available through scope).
        * @param {object} config - table config.
        */
        function setGlobalConfig(config) {
            angular.extend(vm, defaultConfig, config);
        };

        /**
        * @name changePage
        * @desc change the current page displayed.
        * @param {object} pageNumber - current page number.
        * @param {int} pageSize - number of pages.
        */
        function changePage(pageNumber, pageSize) {
            var oldPage = vm.currentPage;
            if (angular.isNumber(pageNumber)) {
                vm.currentPage = pageNumber;
                vm.rowNum = pageSize;
                refresh();
            }
        };

        /**
        * @name insertColumn
        * @desc insert a new column in vm.collection at index or push at the end if no index.
        * @param {object} columnConfig - column configuration used to instantiate the new Column.
        * @param {int} index - where to insert the column (at the end if not specified).
        */
        function insertColumn(columnConfig, index) {            
            var column = new columnProvider(columnConfig);
            if (column && column.sortDir) {
                lastColumnSort = column;
            }
            arrayUtility.insertAt(vm.columns, index, column);
        };
        
        /**
         * @name removeAll
         * @desc remove all columns from vm.columns.
         */
        function removeAll() {
            vm.columns = [];
        };

        /**
         * @name removeColumn
         * @desc remove the column at columnIndex from vm.columns.
         * @param {int} columnIndex - index of the column to be removed.
         */
        function removeColumn(columnIndex) {
            arrayUtility.removeAt(vm.columns, columnIndex);
        };

        /**
         * @name toggleSelection
         * @desc select or unselect the item of the displayedCollection with the selection mode set in the vm.
         * @param {object} dataRow - json row data object.
         */
        function toggleSelection(dataRow) {
            var index = vm.displayedCollection.indexOf(dataRow);
            if (index !== -1) {
                selectRow(vm.displayedCollection, vm.selectionMode, index, dataRow.isSelected !== true);
            }
        };

        /**
         * @name toggleSelection
         * @desc select/unselect all the currently displayed rows.
         * @param {object} value - row data.
         */
        function toggleSelectionAll(value) {
            var i = 0,
                l = vm.displayedCollection.length;

            if (vm.selectionMode !== 'multiple') {
                return;
            }
            for (; i < l; i++) {
                selectRow(vm.displayedCollection, vm.selectionMode, i, value === true);
            }
        };

        /**
         * @name removeDataRow
         * @desc remove the item at index rowIndex from the displayed collection.
         * @param {int} rowIndex - row index.
         * @returns {object} item just removed or undefined
         */
        function removeDataRow(rowIndex) {
            var toRemove = arrayUtility.removeAt(vm.displayedCollection, rowIndex);
            arrayUtility.removeAt(vm.dataCollection, vm.dataCollection.indexOf(toRemove));
        };

        /**
         * @name moveDataRow
         * @desc move an item from oldIndex to newIndex in displayedCollection.
         * @param {int} oldIndex - old index.
         * @param {int} newIndex - new index.
         */
        function moveDataRow(oldIndex, newIndex) {
            arrayUtility.moveAt(vm.displayedCollection, oldIndex, newIndex);
        };

        /**
        * @name dblClick
        * @desc called when a table row is double clicked.
        * @param {object} row - data row.
        */
        function dblClick(row) {
            if ((vm.dblClick && angular.isFunction(vm.dblClick)) === true) {
                vm.dblClick(row);
            }
        };
    }
})();
