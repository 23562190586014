/**
* @name layoutController
* @desc layout controller.
**/
(function () {
    'use strict';

    angular
        .module('CareGuard')
        .controller('layoutController', layoutController);

    /** Inject services into controller. */
    layoutController.$inject = ['layoutService'];

    /**
    * @name layoutController.
    * @desc layout Controller.
    */
    function layoutController(layoutService) {
        var vm = this;

        vm.annuitant = undefined;

        init();

        /* 
        * @name init
        * @desc initialize module
        */
        function init() {
            vm.annuitant = layoutService.getSelected(update);
        };

        /* 
        * @name update
        * @desc update annuitant
        */
        function update(clmnt) {
            vm.annuitant = clmnt;
        };
    }
})();