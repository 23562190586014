/**
* @name companyDetailController
* @desc Company detail Controller.
**/
(function () {
    'use strict';

    angular
        .module('CareGuard')
        .controller('companyDetailController', companyDetailController)

    /** Inject services into controller. */
    companyDetailController.$inject = ['$toastr', 'LxDialogService', 'lookupService', 'companyService','lifecompanyService'];

    /**
    * @name companyDetailController.
    * @desc Company detail Controller.
    * @param {object} lookupService - lookup service.
    * @param {object} companyService - company service.
    */
    function companyDetailController($toastr, LxDialogService, lookupService, companyService, lifecompanyService) {
        var vm = this;

        // variables
        var dialogId = 'dlgConfirm';
        var lifeCompanyData = undefined;
        vm.company = undefined;
        vm.lifeCompanies = undefined;
        vm.companyCopy = undefined;
        vm.lookupdata = undefined;
        vm.isEditing = false;

        // functions
        vm.add = add;
        vm.edit = edit;
        vm.cancel = cancel;
        vm.save = save;
        vm.confirmRemove = confirmRemove;
        vm.remove = remove;

        init();

        /* 
        * @name init
        * @desc initialize module
        */
        function init() {
            lifeCompanyData = lifecompanyService.get();
            vm.lookupdata = {
                companyTypes: lookupService.getByCategory('Company Type'),
                annuityTypes: lookupService.getByCategory('Annuity Type')
            };
            update();          
        };

        /* 
        * @name update
        * @desc update selected company.
        */
        function update(comp) {
            if (!comp) {
                comp = companyService.getSelected(update);                                        
            }           
            vm.company = comp;            
            vm.isEditing = false;
            updateLifeCompanies();
        };

        /* 
        * @name updateLifeCompanies
        * @desc update life companies for display purposes.
        */
        function updateLifeCompanies() {
            var lifeCompanies = [];
            for (var key in lifeCompanyData) {
                var lc = lifeCompanyData[key];
                var comp = Enumerable.from(vm.company.LifeCompanies).where('($.Id ? $.Id : $) == "' + lc.Id + '"').firstOrDefault(undefined);
                lc.IsSelected = comp != undefined ? true : false;
                lc.IsDefault = comp != undefined ? comp.IsDefault : false;
                lifeCompanies.push(lc);
            }
            vm.lifeCompanies = lifeCompanies;
        };
        
        /* 
        * @name add
        * @desc show form to add new company. 
        */
        function add(form) {
            form.setPristine();
            vm.companyCopy = angular.copy(vm.company, {});
            companyService.fireNewCallback({ Index: -1, IsNew: false });
            vm.company = { IsNew: true };
            vm.isEditing = true;
            updateLifeCompanies();
        };

        /* 
        * @name edit
        * @desc show form to edit existing company. 
        */
        function edit(form) {
            form.setPristine();
            vm.companyCopy = angular.copy(vm.company, {});
            vm.company.IsNew = false;
            vm.isEditing = true;
        };

        /* 
        * @name cancel
        * @desc cancel add/update and hide the modal. 
        */
        function cancel() {
            update(vm.companyCopy);
            companyService.fireNewCallback(vm.companyCopy);
        };

        /* 
        * @name save
        * @desc save (add/update) and hide the modal. 
        */
        function save() {
            if (!vm.company) return;
            
            vm.company.LifeCompanies = [];
            var defaultCount = 0;
            Enumerable.from(vm.lifeCompanies).where('$.IsSelected').forEach(function (val) {
                var lifecompany = {};
                lifecompany.Id = val.Id;
                lifecompany.Type = val.Type;
                if (val.IsDefault != undefined) {
                    lifecompany.IsDefault = val.IsDefault;
                    defaultCount += val.IsDefault == true ? 1 : 0;
                }
                vm.company.LifeCompanies.push(lifecompany);
            });

            if (defaultCount > 1) {
                $toastr.show('May only select one Life Company as default.', 'error');
                return;
            };

            // commit to db
            companyService.save(vm.company).then(function (result) {
                result.IsNew = vm.company.IsNew;
                result.Index = vm.company.Index;
                companyService.fireNewCallback(result);
                companyService.setSelected(vm.company);
            });
        };

        /* 
        * @name confirmRemove
        * @desc show delete confirmation dialog.
        */
        function confirmRemove() {
            LxDialogService.open(dialogId);
        };

        /* 
        * @name remove
        * @desc remove company.
        */
        function remove() {
            companyService.remove(vm.company).then(function (result) {
                if (result == true) {
                    companyService.fireNewCallback({ Index: vm.company.Index, Remove: true });
                    LxDialogService.close(dialogId);
                }
            });
        };
    };
})();