(function() {
  "use strict";

  angular
      .module("CareGuard")
      .controller("formsController", formsController);

    formsController.$inject = [
        'formsService',
        'memberService',
        'memberFlagsService',
        'memberID',
        'utilService'];

    function formsController(
        formsService,
        memberService,
        memberFlagsService,
        memberID,
        utilService) {

        // variables
        var vm = this;
        var today = undefined;

        vm.lookupdata = undefined;
        vm.member = undefined;
        vm.memberflags = undefined;

        vm.forms = undefined;

        vm.selectedForm = undefined;

        //functions
        vm.selectForm = selectForm;

        init();

        function init() {
            //get formLetters (by type) -uses the same sp as legacy
            formsService.getFormLetters('Forms').then(result => vm.forms = result.Data);

            //get todays date as ReceivedDate, for creating claims
            today = new Date();
            var dd = today.getDate();
            var mm = today.getMonth() + 1; //January is 0!
            var yyyy = today.getFullYear();

            if (dd < 10) {
                dd = '0' + dd
            }

            if (mm < 10) {
                mm = '0' + mm
            }
            today = mm + '-' + dd + '-' + yyyy;

            //check if memberID was passed in; if so, get member
            if (memberID > 0) {
                memberService.getById(memberID).then(result => vm.member = result.Data);
                memberFlagsService.getMemberFlags(memberID).then(result => vm.memberflags = result.Data);
            }
        }

          function selectForm(formType, formID) {
              if (!memberID) {
                  throw new Error('Member ID is not defined');
              }

              if (formType !== "Forms") {
                  throw new Error('Unexpected form type');
              }

              vm.selectedForm = vm.forms.find(x => x.FormID == formID);

              if (!vm.selectedForm) {
                  throw new Error(`Form is not found by id = ${formID}`);
              }

              var exportOptions = {
                  method: 'GET',
                  url: `/api/forms/selectForm/${vm.member.MemberID}/${vm.member.MemberNumber}/${vm.selectedForm.FormID}`
              };
              utilService.download(exportOptions);
        }
    }
})();