angular
    .module('CareGuard')
    .controller('loginController', loginController)

loginController.$inject = ['accountService'];

function loginController(accountService) {
    let vm = this;

    vm.showMessage = false;
    vm.message = undefined;
    vm.form = undefined;
    vm.user = {
        UserName: undefined,
        Password: undefined,
        DisplayName: undefined,
        LoggingIn: false,
        SendingEmail: false,
        ShowMessage: false,
        ResetPasswordMessage: undefined
    };

    vm.signOut = signOut;

    vm.legacyCareGuardUrl = null;

    vm.getUserName = getUserName;
    vm.hasRole = hasRole;

    function getUserName() {
        return accountService.getUserName();
    }

    function signOut() {
        accountService.signOut();
    }

    function hasRole(role) {
        return accountService.isInRole(role);
    }
}