angular
    .module('CareGuard')
    .factory('httpRequestInterceptor', httpRequestInterceptor);

httpRequestInterceptor.$inject = [];

function httpRequestInterceptor() {
    function changeRequestConfig(config) {
        const urlToExcludeLoadingBar = 'searchMember';
        const regexpToExcludeLoadingBar = new RegExp(urlToExcludeLoadingBar, ['i']);

        if (config && config.method === 'GET' && regexpToExcludeLoadingBar.test(config.url)) {
            config.ignoreLoadingBar = true
        }

        return config
    }

    return {
        request: changeRequestConfig
    }
}