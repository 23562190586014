angular
    .module('CareGuard')
    .directive('formatSliderInput', formatSliderInput);

formatSliderInput.$inject = ['$filter'];

function formatSliderInput($filter) {
    return {
        restrict: 'A',
        require: 'ngModel',
        link: function($scope, elm, attrs, ngModelController) {
            if (!ngModelController) return;

            const valueWithoutLeadingZeros = $filter('removeLeadingZeros');

            ngModelController.$parsers.unshift(function(value) {
                if (value === '') return value;

                value = +valueWithoutLeadingZeros(value.toString());

                if (attrs.isMinValue && value > +attrs.maxModelValue) {
                    value = +attrs.maxModelValue;
                }

                if (attrs.isMaxValue && value < +attrs.minModelValue) {
                    value = +attrs.minModelValue;
                }

                const min = +attrs.min;
                const max = +attrs.max;

                if (value < min) {
                    value = min;
                } else if (value > max) {
                    value = max;
                }

                ngModelController.$setViewValue(value);
                ngModelController.$render();

                return value;
            });
        }
    }
}