/**
* @name smartGridHeaderRow
* @desc Handles header click event, used for sorting and filtering.
**/
(function () {
    'use strict';

    angular
        .module('smart-grid')
        .directive('smartGridHeaderRow', smartGridHeaderRow);

    /** Inject services into directive. */
    smartGridHeaderRow.$inject = ['$timeout'];

    /**
    * @name smartGridHeaderRow.
    * @desc Handles header click event, used for sorting and filtering.
    */
    function smartGridHeaderRow($timeout) {
        var directive = {
            restrict: 'AC',
            require: '^smartGrid',
            link: postLink
        };

        return directive;

        function postLink($scope, $element, $attrs, $controller) {
            var timer = null;
            $scope.searchValue = undefined;

            // Fire search after 400 milliseconds unless there is new activity in the textbox
            $scope.$watch('searchValue', function (newValue, oldValue) {
                if (newValue != oldValue) {
                    clearTimeout(timer);
                    timer = setTimeout(function () {
                        $scope.$apply(function () {
                            $controller.search(newValue, $scope.column);
                        });
                    }, 400);
                };
            });
        }
    }
})();