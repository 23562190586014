import {defaultGridOptions} from '../../constant';
import {columnDefsGrid} from './columnDefs';
import {checkboxesColumn} from './checkboxesColumn';
import { payBillsRowTemplate } from './payBillsRowTemplate';

import paginationTemplate from './pagination.html';

angular.module('CareGuard')
    .controller('payBillsController', PayBillsController);

PayBillsController.$inject = [
    '$scope',
    '$q',
    'utilService',
    '$toastr',  
    'accountService',
    'LxDialogService',
    'payBillsService',
    'payPeriodEnum',
    'payTypeEnum',
    'lookupService',
    'memberService',
    'claimStatusId',
    'claimService',
    'payeeService',
    'bankingService',
    'transactionType'];

function PayBillsController(
    $scope,
    $q,
    utilService,
    $toastr,
    accountService,
    LxDialogService,
    payBillsService,
    payPeriodEnum,
    payTypeEnum,
    lookupService,
    memberService,
    claimStatusId,
    claimService,
    payeeService,
    bankingService,
    transactionType) {

    let vm = this;
    let fpInstance = {};
    let reversalsIds = [];

    const DEFAULT_TZ = 'UTC';
    const dialogId = 'promptPayBillsModal';
    vm.payBillsConfirmationText = 'Please confirm bill payment';
    vm.paymentReissueConfirmationText = 'Please confirm payment reissue amount'

    vm.fpSelectedDate = '';

    vm.hasRole = hasRole;
    vm.paginationChanged = paginationChanged;
    vm.pageSizeChanged = pageSizeChanged;
    vm.generateBillPayReconciliationClaims = generateBillPayReconciliationClaims;

    vm.claimPaymentVendors = 'DME, Doctor`s Bills';
    vm.selectedPBAId = null;
    vm.selectedBillTypeIds = [];
    vm.amethystSelectedBillTypeIds = [];
    vm.searchParams = {};
    vm.setNonRedeemedPaymentsGrid = setNonRedeemedPaymentsGrid;

    $scope.payTypeEnum = payTypeEnum;

    const searchParamsReference = {
        PageSize: 10000,
        PageNumber: 1,
        SortOrder: 'ASC',
        SortColumn: 'MemberNumber'
    };

    angular.copy(searchParamsReference, vm.searchParams);

    vm.gridOptions = defaultGridOptions({
        columnDefs: columnDefsGrid,
        rowTemplate: payBillsRowTemplate,
        paginationTemplate: paginationTemplate,
        enableSorting: true,
        enableGridMenu: false,
        paginationPageSize: vm.searchParams.PageSize,
        useExternalPagination: true,
        useExternalSorting: true,
        totalItems: 0,
        enableExpandableRowHeader: false,
        onRegisterApi: onRegisterApi
    });

    vm.gridOptions.columnDefs.unshift(checkboxesColumn);

    vm.selectRow = function (row) {
        row.Selected = !row.Selected;
    };

    vm.toggleSelectAllRows = () => {
        if (!vm.areAllRowsSelected) {
            unSelectAllRows();
        } else {
            selectAllRows();
        }
    };

    vm.onChangeSelectAllRows = () => {
        vm.toggleSelectAllRows();
        vm.wasAnyCheckboxeClicked = true;
        getTotals();
    };

    function getTotals() {
        vm.totalPageAmount = 0;
        vm.totalPageAmetrosMargin = 0;
        vm.totalPageVendorMargin = 0;
        vm.gridOptions.data.forEach(row => {
            if (row.Selected) {
                vm.totalPageAmount = vm.totalPageAmount + row.TotalAmount;
                vm.totalPageAmetrosMargin = vm.totalPageAmetrosMargin + (row.AmetrosMargin || 0);
                vm.totalPageVendorMargin = vm.totalPageVendorMargin + (row.VendorMargin || 0);
            }
        })
    }

    vm.checkRowsSelectionAndSumTotalAmount = () => {
        let tmpAreAllRowsSelected = true;
        vm.isAnyRowSelected = false;
        vm.totalPageAmount = 0;
        vm.totalPageAmetrosMargin = 0;
        vm.totalPageVendorMargin = 0;

        for (let i=0; i < vm.gridOptions.data.length; i++) {
            if (!vm.gridOptions.data[i].Selected) {
                tmpAreAllRowsSelected = false;
            } else {
                vm.totalPageAmount = vm.totalPageAmount + vm.gridOptions.data[i].TotalAmount;
                vm.totalPageAmetrosMargin = vm.totalPageAmetrosMargin + (vm.gridOptions.data[i].AmetrosMargin || 0);
                vm.totalPageVendorMargin = vm.totalPageVendorMargin + (vm.gridOptions.data[i].VendorMargin || 0);
                vm.isAnyRowSelected = true;
            }
        }

        vm.wasAnyCheckboxeClicked = true;
        vm.areAllRowsSelected = tmpAreAllRowsSelected;
    };

    function selectAllRows() {
        selectRows(false);
    }

    function selectAllReconciledRows() {
        selectRows(true);
    }

    function selectRows(hasToSelectReconciledOnly) {
        vm.wasAnyCheckboxeClicked = true;

        if (hasToSelectReconciledOnly) {
            vm.gridOptions.data.forEach(row => {
                if (row.IsReconciled) {
                    row.Selected = true
                }
            });

        } else {
            vm.gridOptions.data.forEach(row => row.Selected = true);
        }

        vm.checkRowsSelectionAndSumTotalAmount();
    }

    function unSelectAllRows() {
        vm.gridOptions.data.forEach(row => row.Selected = false);
        vm.totalPageAmount = 0;
        vm.totalPageAmetrosMargin = 0;
        vm.totalPageVendorMargin = 0;
        vm.areAllRowsSelected = false;
        vm.isAnyRowSelected = false;
    }

    (function init() {

        let promises = [];

        promises.push(payBillsService.getRXVendors());
        promises.push(lookupService.getLookUp("claimType"));
        promises.push(claimService.getClaimStatuses());

        return $q.all(promises).then(([pbas, { Data: billTypes }, { data: statuses }]) => {
            
            setPbaData(pbas);
            setClaimTypeData(billTypes);
            vm.claimStatusMap = buildClaimStatusMap(statuses);

            return getNonRedeemedPaymentClaims().then(result => vm.nonRedeemedPaymentClaims = result);
        });
    })();

    vm.onSubmit = (payBillsForm) => {
        if (vm.paymentType === payTypeEnum.BatchPayment && payBillsForm.$invalid) {
            $toastr.show('Please fill the required fields!', 'warning');
            return;
        }

        initGrid();
    };

    function setPbaData(pbas) {
        vm.pbas = pbas;
        vm.selectedPBAId = vm.pbas[0].PayeeId;
        setDefaultPayPeriod(vm.pbas[0]);
    }

    function setClaimTypeData(claimTypes) {
        vm.billTypes = claimTypes;
        vm.amethystBillTypes = vm.billTypes.filter(item => item.DataSetValue != 'Rx');
        vm.billTypes.forEach(item => {
            vm.selectedBillTypeIds.push(item.DataSetID);
        });
        vm.billTypes.forEach(item => {
            if (item.DataSetValue != 'Rx') {
                vm.amethystSelectedBillTypeIds.push(item.DataSetID)
            }
        });
    }

    function initGrid() {
        resetGridAndTotals();
        getDataForGrid();
    }

    function resetGridAndTotals () {
        vm.gridOptions.data = [];
        vm.gridOptions.totalItems = 0;
        angular.copy(searchParamsReference, vm.searchParams);
        vm.wasDataForGridFetched = false;
        vm.wasAnyCheckboxeClicked = false;
        vm.totalPageAmount = 0;
        vm.totalPageAmetrosMargin = 0;
        vm.totalPageVendorMargin = 0;
        vm.grandTotalAmount = 0;
        vm.endDateForPopup = null;
        unSelectAllRows();
    }

    $scope.$watch('vm.paymentType', watchPaymentTypeFn);

    function watchPaymentTypeFn (paymentType, prevPaymentType) {
        if (!paymentType && !prevPaymentType) return;

        vm.selectedClaimId = null;
        vm.selectedMemberNumber = null;
        resetGridAndTotals();

        if (paymentType === payTypeEnum.NonRedeemedPayment)
            setNonRedeemedPaymentsGrid();
    }

    $scope.$watch(watchExpr, watchFn, true);

    function watchExpr() {
        return parseInt(vm.selectedPBAId);
    }

    function watchFn(selectedPBAId, prevSelectedPBAId) {
        
        if (selectedPBAId === prevSelectedPBAId) return;

        if ((prevSelectedPBAId) && selectedPBAId !== prevSelectedPBAId) {
            fpInstance.clear();

            let selectedPBA = vm.pbas.find(pbasItem => selectedPBAId === pbasItem.PayeeId);

            if (selectedPBA) {
                setPayPeriod(selectedPBA);
            }
        }
    }

    function daysInMonth (month, year) {
        return new Date(year, month, 0).getDate();
    }

    function setDefaultPayPeriod(pba) {
        setPayPeriod(pba, true);
    }

    function setPayPeriod(pba, isDefaultPayPeriod = false) {
        if (!pba) return;

        let startDate, endDate;

        const currentDate = new Date();
        const currentDay = currentDate.getDate();
        const currentMonth = currentDate.getMonth() + 1;
        const currentYear = currentDate.getFullYear();
        const daysInCurrentMonth = daysInMonth(currentMonth, currentYear);
        const daysInPreviousMonth = daysInMonth(currentMonth - 1, currentYear);

        vm.dateOpts = {
            mode: "range",
            dateFormat: "m-d-Y",
            maxDate: currentDate
        };

        vm.datePostSetup = function(fpItem) {
            fpInstance = fpItem;
        };

        if (!vm.selectedPBAId) return;

        const periodLength = payPeriodEnum[pba.PayPeriodType];

        if (periodLength === payPeriodEnum.Monthly) {
            if (currentDay > pba.FirstDayValue) {
                startDate = (currentMonth - 1).toString() + '-' + pba.FirstDayValue.toString() + '-' + currentYear.toString();
                endDate = (pba.FirstDayValue === 1 ? currentMonth - 1: currentMonth).toString() + '-'
                    + (pba.FirstDayValue === 1 ? daysInPreviousMonth : pba.FirstDayValue - 1).toString() + '-' + currentYear.toString();
            } else if (currentDay < pba.FirstDayValue) {
                startDate = (currentMonth - 2).toString() + '-' + pba.FirstDayValue.toString() + '-' + currentYear.toString();
                endDate = (currentMonth - 1).toString() + '-' + (pba.FirstDayValue - 1).toString() + '-' + currentYear.toString();
            } else {
                startDate = (currentMonth - 1).toString() + '-' + pba.FirstDayValue.toString() + '-' + currentYear.toString();
                endDate = (currentMonth - 1).toString() + '-' + daysInPreviousMonth.toString() + '-' + currentYear.toString();
            }

        } else if (periodLength === payPeriodEnum.BiWeekly) {
            if (currentDay >= (pba.FirstDayValue + periodLength)) {
                startDate = currentMonth.toString() + '-' + pba.FirstDayValue.toString() + '-' + currentYear.toString();
                endDate = currentMonth.toString() + '-' + (pba.FirstDayValue + periodLength - 1).toString()
                    + '-' + currentYear.toString();

            } else if (pba.FirstDayValue + periodLength > daysInCurrentMonth
                && currentDay > (pba.FirstDayValue - daysInCurrentMonth + periodLength)) {

                startDate = (currentMonth - 1).toString() + '-' + pba.FirstDayValue.toString() + '-' + currentYear.toString();
                endDate = currentMonth.toString() + '-' + (pba.FirstDayValue - daysInCurrentMonth + periodLength).toString()
                    + '-' + currentYear.toString();

            } else {
                startDate = (currentMonth - 1).toString() + '-' + (pba.FirstDayValue + periodLength).toString()
                    + '-' + currentYear.toString();

                const endDateMonth = (pba.FirstDayValue > 1) ? currentMonth : currentMonth -1;
                const endDateDay = (pba.FirstDayValue > 1) ? pba.FirstDayValue - 1 : daysInPreviousMonth;

                endDate = endDateMonth.toString() + '-' + endDateDay.toString() + '-' + currentYear.toString();
            }

        } else if (periodLength === payPeriodEnum.Weekly) {
            if (currentDay > (pba.FirstDayValue + periodLength - 1) && currentDay < (pba.FirstDayValue + 2 * periodLength)) {
                startDate = currentMonth.toString() + '-' + pba.FirstDayValue.toString() + '-' + currentYear.toString();
                endDate = currentMonth.toString() + '-' + (pba.FirstDayValue + periodLength - 1).toString()
                    + '-' + currentYear.toString();
            } else if (currentDay > (pba.FirstDayValue + 2 * periodLength - 1) && currentDay < (pba.FirstDayValue + 3 * periodLength)) {
                startDate = currentMonth.toString() + '-' + (pba.FirstDayValue + periodLength).toString()
                    + '-' + currentYear.toString();
                endDate = currentMonth.toString() + '-' + (pba.FirstDayValue + 2 * periodLength - 1).toString()
                    + '-' + currentYear.toString();
            } else if (currentDay > (pba.FirstDayValue + 3 * periodLength - 1)) {
                startDate = currentMonth.toString() + '-' + (pba.FirstDayValue + 2 * periodLength).toString()
                    + '-' + currentYear.toString();
                endDate = currentMonth.toString() + '-' + (pba.FirstDayValue + 3 * periodLength - 1).toString()
                    + '-' + currentYear.toString();
            } else if (currentDay >= pba.FirstDayValue && currentDay < (pba.FirstDayValue + periodLength)) {
                startDate = (currentMonth - 1).toString() + '-' + (pba.FirstDayValue + 3 * periodLength).toString()
                        + '-' + currentYear.toString();
                    endDate = (currentMonth - 1).toString() + '-' + (pba.FirstDayValue + daysInPreviousMonth - 1).toString()
                        + '-' + currentYear.toString();
            } else if (currentDay > (pba.FirstDayValue - 1) && currentDay < (pba.FirstDayValue + periodLength)) {
                const startDateMonth = (pba.FirstDayValue - periodLength > 0) ? currentMonth : currentMonth - 1;
                const startDateDay = (pba.FirstDayValue - periodLength > 0)
                    ? pba.FirstDayValue - periodLength + 1 : daysInPreviousMonth + pba.FirstDayValue - periodLength;

                startDate = startDateMonth.toString() + '-' + startDateDay.toString() + '-' + currentYear.toString();
                endDate = currentMonth.toString() + '-' + (pba.FirstDayValue - 1).toString() + '-' + currentYear.toString();
            }

        }else {
            if (currentDay > (pba.FirstDayValue + periodLength - 1) && currentDay < (pba.FirstDayValue + 2 * periodLength)) {
                startDate = currentMonth.toString() + '-' + pba.FirstDayValue.toString() + '-' + currentYear.toString();
                endDate = currentMonth.toString() + '-' + (pba.FirstDayValue + periodLength - 1).toString()
                    + '-' + currentYear.toString();
            } else if (currentDay > (pba.FirstDayValue + 2*periodLength - 1) && currentDay < (pba.FirstDayValue + 3*periodLength)) {
                startDate = currentMonth.toString() + '-' + (pba.FirstDayValue + periodLength).toString()
                    + '-' + currentYear.toString();
                endDate = currentMonth.toString() + '-' + (pba.FirstDayValue + 2*periodLength - 1).toString()
                    + '-' + currentYear.toString();
            } else if (currentDay > (pba.FirstDayValue + 3*periodLength - 1) && currentDay < (pba.FirstDayValue + 4*periodLength)) {
                startDate = currentMonth.toString() + '-' + (pba.FirstDayValue + 2*periodLength).toString()
                    + '-' + currentYear.toString();
                endDate = currentMonth.toString() + '-' + (pba.FirstDayValue + 3*periodLength - 1).toString()
                    + '-' + currentYear.toString();
            }  else if (currentDay < pba.FirstDayValue && currentDate > pba.FirstDayValue - periodLength) {
                startDate = currentMonth.toString() + '-' + (pba.FirstDayValue - 2*periodLength).toString()
                    + '-' + currentYear.toString();
                endDate = currentMonth.toString() + '-' + (pba.FirstDayValue - periodLength - 1).toString()
                    + '-' + currentYear.toString();
            } else if (currentDay > (pba.FirstDayValue - 1) && currentDay < (pba.FirstDayValue + periodLength)) {
                const startDateMonth = (pba.FirstDayValue - periodLength > 0) ? currentMonth : currentMonth -1;
                const startDateDay = (pba.FirstDayValue - periodLength > 0)
                    ? pba.FirstDayValue - periodLength + 1 : daysInPreviousMonth + pba.FirstDayValue - periodLength;

                startDate = startDateMonth.toString() + '-' + startDateDay.toString() + '-' + currentYear.toString();
                endDate = currentMonth.toString() + '-' + (pba.FirstDayValue - 1).toString() + '-' + currentYear.toString();
            }
        }

        if (isDefaultPayPeriod) {
            vm.dateOpts.defaultDate = [startDate, endDate];
        } else {
            fpInstance.setDate([startDate, endDate], true);
        }

    }

    function checkPBAsPayPeriodForEquality (array, objectProperty) {
        let isPropertyEqual = true;

        for (let i = 0; i < array.length; i++) {
            if (i < array.length - 1 && array[i][objectProperty] !== array[i+1][objectProperty]) {
                isPropertyEqual = false;
                break;
            }
        }

        return isPropertyEqual;
    }

    function onRegisterApi(gridApi) {
        vm.gridApi = gridApi;

        gridApi.pagination.on.paginationChanged($scope, paginationChanged);
        gridApi.core.on.sortChanged($scope, sortChanged);
    }

    function sortChanged(grid, sortColumns) {
        if (!sortColumns || !sortColumns.length || !sortColumns[0].sort || !vm.paymentType) return;

        while (sortColumns.length > 1) {
            sortColumns[0].unsort();
            sortColumns.shift();
        }

        vm.searchParams.SortOrder = sortColumns[0].sort.direction.toUpperCase();
        vm.searchParams.SortColumn = sortColumns[0].field;

        getDataForGrid();
    }

    function pageSizeChanged() {
        paginationChanged(searchParamsReference.PageNumber, searchParamsReference.PageSize);
    }

    function paginationChanged(newPage, pageSize) {
        vm.searchParams.PageSize = pageSize;
        vm.searchParams.PageNumber = newPage;

        getDataForGrid();
    }

    function setNonRedeemedPaymentsGrid() {
        if (!vm.nonRedeemedPaymentClaims?.ClaimsToPay?.length) return;

        const unsortedFilteredClaims = filterNonRedeemedPayments(vm.nonRedeemedPaymentClaims?.ClaimsToPay);
        const sortedFilteredClaims = sortClaims(unsortedFilteredClaims);

        vm.gridOptions.data = sortedFilteredClaims;
        vm.gridOptions.totalItems = sortedFilteredClaims?.length;
        vm.gridOptions.paymentTypeEnum = payTypeEnum.NonRedeemedPayment;

        vm.grandTotalAmount = sortedFilteredClaims.reduce((accumulator, claim) => accumulator + claim.TotalAmount, 0);
        vm.wasDataForGridFetched = true;
        selectAllRows();
    }

    function filterNonRedeemedPayments(claims) {
        if (vm.nonRedeemedPaymentsFilters?.PayeeName)
            claims = claims.filter(claim => claim.Vendor.toLowerCase().includes(vm.nonRedeemedPaymentsFilters.PayeeName.toLowerCase()));

        if (vm.nonRedeemedPaymentsFilters?.NPI)
            claims = claims.filter(claim => claim.PayeeNPI.includes(vm.nonRedeemedPaymentsFilters.NPI));

        if (vm.nonRedeemedPaymentsFilters?.TIN)
            claims = claims.filter(claim => claim.PayeeTIN.includes(vm.nonRedeemedPaymentsFilters.TIN));

        return claims;
    }

    function sortClaims(arr) {
        return _.orderBy(arr, [vm.searchParams.SortColumn], [vm.searchParams.SortOrder.toLowerCase()]);
    }

    function getDataForGrid() {
        const request = {
            PageIndex: vm.searchParams.PageNumber,
            PageSize: vm.searchParams.PageSize,
            SortColumn: vm.searchParams.SortColumn,
            SortOrder: vm.searchParams.SortOrder,
            MemberNumber: vm.selectedMemberNumber || null
        };
        let promise;

        if (vm.paymentType === payTypeEnum.BatchPayment) {
            request.PayeeIds = new Array(1).fill(vm.selectedPBAId);

            // offsets time according to local time zone in order to have correct UTC time for server
            if (fpInstance.selectedDates.length < 1) {
                request.StartDate = fpInstance.selectedDates[0];
                request.EndDate = fpInstance.selectedDates[1];
            } else {

                vm.startDateForPopup = fpInstance.formatDate(fpInstance.selectedDates[0], "m-d-Y");
                if (fpInstance.selectedDates[0] && fpInstance.selectedDates[0] !== fpInstance.selectedDates[1]) {
                    vm.endDateForPopup = fpInstance.formatDate(fpInstance.selectedDates[1], "m-d-Y");
                }

                const StartDateLocalTzOffset = moment(fpInstance.selectedDates[0]).toDate().getTimezoneOffset() / 60;
                const EndDateLocalTzOffset = moment(fpInstance.selectedDates[1]).toDate().getTimezoneOffset() / 60;

                request.StartDate = moment(fpInstance.selectedDates[0]).tz(DEFAULT_TZ).utcOffset(StartDateLocalTzOffset, true).toDate();
                request.EndDate =  moment(fpInstance.selectedDates[1]).tz(DEFAULT_TZ).utcOffset(EndDateLocalTzOffset, true).toDate();
            }

            promise = payBillsService.getBatchPaymentClaims(request);

        }
        else if (vm.paymentType === payTypeEnum.NonRedeemedPayment) {
            return $q.when(vm.setNonRedeemedPaymentsGrid()); 
        } else {
            
            request.ClaimId = vm.selectedClaimId || null;

            if (vm.paymentType === payTypeEnum.AmethystPayment) {
                request.BillTypeIds = vm.amethystSelectedBillTypeIds;
                promise = payBillsService.getAmethystPaymentClaims(request);
            } else {
                request.BillTypeIds = vm.selectedBillTypeIds;
                promise = payBillsService.getClaimPaymentClaims(request);
            }
        }

        $q.when(promise).then(res => {
            if (!res) return;

            const memberServiceDateList = res.ClaimsToPay.map(claim => {
                return { MemberId: claim.MemberId, ServiceDate: claim.ServiceDate };
            });

            return memberService.getPbmAccumulatorDateHistory(memberServiceDateList)
                .then(response => {

                    if (vm.paymentType === payTypeEnum.BatchPayment) {
                        if (response.data && response.data.length) {
                            for (const responseData of response.data) {
                                const matchingClaims = res.ClaimsToPay.filter(claim =>
                                    claim.MemberId === responseData.memberId && claim.ServiceDate === responseData.serviceDate
                                );

                                if (matchingClaims.length) {
                                    for (const matchingClaim of matchingClaims) {
                                        matchingClaim.FirstPbmSentAmount = responseData.firstPbmSentAmount;
                                        matchingClaim.LastPbmSentAmount = responseData.lastPbmSentAmount;
                                        matchingClaim.PbmHasOverride = responseData.pbmHasOverride;
                                    }
                                }
                            }
                        }
                    }

                    vm.gridOptions.data = res.ClaimsToPay;
                    vm.gridOptions.totalItems = res.TotalRows;
                    vm.grandTotalAmount = res.GrandTotalAmount;
                    vm.gridOptions.paymentTypeEnum = payTypeEnum.NonRedeemedPayment;

                    if (res.ClaimsToPay?.length && vm.paymentType === payTypeEnum.BatchPayment) {
                        selectAllReconciledRows();
                    } else if (res.ClaimsToPay && res.ClaimsToPay.length && vm.paymentType !== payTypeEnum.BatchPayment) {
                        selectAllRows();
                    } else {
                        unSelectAllRows();
                    }
                });
        }).finally(_ => vm.wasDataForGridFetched = true);
    }

    vm.resetFetchDataForGrid = () => vm.wasDataForGridFetched = false;
    vm.totalSelectedAmount = () => vm.totalPageAmount;

    vm.showConfirmationForPayingClaims = () => {
        vm.doesPayingByACH = false;
        LxDialogService.open(dialogId);
    };

    vm.showConfirmationForPayingByACH = () => {
        vm.doesPayingByACH = true;
        LxDialogService.open(dialogId);
    };

    vm.payClaims = () => payClaimsFn(false);
    vm.payByACH = () => payClaimsFn(true);

    function payClaimsFn(achFlag) {

        const isBatchPayment = vm.paymentType === payTypeEnum.BatchPayment;
        const req = getDataForPayment(achFlag, isBatchPayment);
        let promise;

        LxDialogService.close(dialogId);

        if (isBatchPayment) {
            promise = payBillsService.payRxSelectedClaims(req);
        } else if(vm.paymentType === payTypeEnum.AmethystPayment) {
            promise = payBillsService.payAmethystNonRxSelectedClaims(req);
        } else if (vm.paymentType === payTypeEnum.NonRedeemedPayment) {
            promise = payBillsService.payNonRedeemedPaymentClaims(req);
        } else {
            promise = payBillsService.payNonRxSelectedClaims(req);
        }
        
        return $q.when(promise).then(_ => {
            $toastr.show('All selected claims for the specified criteria have been paid!', 'success');
            if (vm.paymentType === payTypeEnum.NonRedeemedPayment) {
                vm.nonRedeemedPaymentClaims = removeReissuedClaims(req.claimsIds);
            }

            return initGrid();
        });
    }

    function removeReissuedClaims(claimIds) {
        let claimsToPay = vm.nonRedeemedPaymentClaims.ClaimsToPay.filter(claim => !claimIds.includes(claim.ClaimID));
        return {
            ClaimsToPay: claimsToPay,
            TotalRows: claimsToPay.length,
            GrandTotalAmount: claimsToPay.reduce((accumulator, claim) => accumulator + claim.TotalAmount, 0)
        }
    }

    function getDataForPayment(achFlag = false, isBatchPayment) {
        const claimsIds = getArrayOfSelectedClaims();
        reversalsIds = [];
        getSelectedReversalIds();
        
        return isBatchPayment ?
            {
                claimsIds,
                reversalsclaimsIds: reversalsIds,
                isAchOnly: achFlag
            }
            :
            {
                claimsIds,
                isAchOnly: achFlag
            }
    }

    function getSelectedReversalIds() {
        vm.gridOptions.data.forEach(row => {
            if (row.Selected) {
               if (row.ReversalFlag === 'pink' || row.ReversalFlag === 'purple' || row.IsReversal) {
                    reversalsIds.push(row.ClaimID);
                }
            }
        });
    }

    function getArrayOfSelectedClaims () {
        if (vm.gridOptions.data.length < 1) return;

        const selectedClaims = [];
        vm.gridOptions.data.forEach(row => {
            if (row.Selected && row.ReversalFlag !== 'pink' && row.ReversalFlag !== 'purple' && !row.IsReversal) {
                selectedClaims.push(row.ClaimID);
            }
        });

        return selectedClaims;
    }

    vm.export = () => {
        const req = {
            Claims: []
        };

        vm.gridOptions.data.forEach(item => {
            if (item.Selected) {
                req.Claims.push(item);
            }
            return req;
        });

        payBillsService.exportClaims(req)
            .then(response => utilService.processResponse({ response }))
        .then(_ => $toastr.show('All selected claims for the specified criteria have been exported!', 'success'));
    };

    vm.readyForReissueExport = () => {
        const req = {
            Claims: []
        }; 

        vm.gridOptions.data.forEach(item => {
            if (item.Selected) {
                req.Claims.push(item);
            }
            return req;
        });

        payBillsService.exportReadyForReissueClaims(req)
            .then(response => utilService.processResponse({ response }))
            .then(_ => $toastr.show('All selected Ready for Reissue claims have been exported!', 'success'));
    };

    function generateBillPayReconciliationClaims() {
        payBillsService.generateBillPayReconciliationClaims(vm.selectedBillTypeIds)
            .then(response => utilService.processResponse({ response }))
            .then(_ => $toastr.show('Reconciliation file has been generated!', 'success'));
    };

    function hasRole(role) {
        return accountService.isInRole(role);
    }

    function getNonRedeemedPaymentClaims() {
        const params = buildClaimParamObject();
        params.ClaimStatusID = claimStatusId.ReadyForReissue;

        return claimService.searchClaims(params).then(({ data, data: { items: claims } }) => {
            if ((Array.isArray(data) && !data.length) || !claims.length) {
                return null;
            }
            const claimData = claims;

            return $q.all(getAdditionalDataPromises(claimData)).then(([{ data: memberData }, { data: payees }, { data: transactions }]) => {
                return mapApiDtoData(claimData, memberData, payees, transactions);
            });
        });
    }

    function mapApiDtoData(claimData, memberData, payees, transactions) {
        const memberMap = buildMemberMap(memberData);
        const claimsToPay = claimData.map(claim =>  {
            return {
                MemberNumber: memberMap.get(claim.memberID)?.memberNumber,
                MemberId: claim.memberID,
                MemberName: memberMap.get(claim.memberID)?.fullName,
                ClaimID: claim.id,
                ClaimStatus: vm.claimStatusMap.get(claim.claimStatusID),
                Vendor: payees.find(payee => payee.id === claim.payeeID)?.name,
                PayeeNPI: payees.find(payee => payee.id === claim.payeeID)?.npiNumber,
                PayeeTIN: payees.find(payee => payee.id === claim.payeeID)?.taxID,
                AchEligible: payees.find(payee => payee.id === claim.payeeID)?.achEligible,
                BillType: vm.billTypes.find(type => type.DataSetID === claim.billTypeID)?.DataSetValue,
                TransactionId: transactions.find(transaction => transaction.claimId === claim.id && transaction.transferType === transactionType.Operational.toUpperCase())?.id,
                ServiceDate: claim.serviceDate,
                ReceivedDate: claim.receivedDate,
                PaidAmount: claim.payableAmount,
                MemberFee: claim.feeAmount,
                TotalAmount: claim.payableAmount,
                PaidDate: claim.paidDate,
                IsExpediteRemittance: claim.isExpediteRemittance,

                //RX Related Fields and non resubmission related fields all set to null
                VendorMargin: null,
                BrandCode: null,
                PharmacyDueAmount: null,
                PharmacyName: null,
                IsReconciled: null,
                AdministrativeFee: null,
                FirstPbmSentAmount: null,
                LastPbmSentAmount: null,
                PbmHasOverride: null
            };
        });

        return {
            ClaimsToPay: claimsToPay,
            TotalRows: claimsToPay.length,
            GrandTotalAmount: claimsToPay.reduce((accumulator, claim) => accumulator + claim.TotalAmount, 0)
        }
    }

    function buildMemberMap(arr) {
        return arr.reduce((map, obj) => map.set(obj.id ?? obj.memberID ?? obj.memberId, Object.assign(map.get(obj.id ?? obj.memberID ?? obj.memberId) || {}, obj)), new Map());
    }

    function buildClaimStatusMap(statuses) {
        return statuses.reduce((map, obj) => map.set(obj.id, obj.status), new Map());
    }

    function getAdditionalDataPromises(claims) {
        let promises = [];

        const memberIds = [...new Set(claims.map(claim => claim.memberID))];
        const payeeIds = [...new Set(claims.map(claim => claim.payeeID))];
        const claimIds = [...new Set(claims.map(claim => claim.id))];
        promises.push(memberService.getMembersByIds({ ids: memberIds, fields: `id,memberNumber,fullName` }));
        promises.push(payeeService.getPayeesByIds({ ids: payeeIds, fields: `id,name,npiNumber,taxID,achEligible` }));
        promises.push(bankingService.getTransactionsByClaimIds(claimIds));
        return promises;
    }

    function buildClaimParamObject() {
        const requestedFields = [
            'id',
            'memberId',
            'payeeID',
            'claimStatusID',
            'claimNumber',
            'billType',
            'billTypeID',
            'serviceDate',
            'receivedDate',
            'billReviewSentDate',
            'billReviewReceivedDate',
            'submittedUNCAmount',
            'billedAmount',
            'payableAmount',
            'feeAmount',
            'paidDate',
            'isReconsideration',
            'stopWhenReceived',
            'billReviewVendorID',
            'transactionId',
            'isExpediteRemittance',
            'achEligible'
        ];

        return { ...vm.filterData, ...vm.searchParams, fields: requestedFields.join(','), orderBy: 'id', isAmethyst: false };
    }

}
