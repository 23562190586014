/**
* @name ruleController
* @desc Rule Controller.
**/
(function () {
    'use strict';

    angular
        .module('CareGuard')
        .controller('ruleController', ruleController)

    /** Inject services into controller. */
    ruleController.$inject = ['lifecompanyService'];

    /**
    * @name ruleController.
    * @desc Rule Controller.
    * @param {object} lifecompanyService - company service.
    */
    function ruleController(lifecompanyService) {
        var vm = this;

        // variables
        vm.company = undefined;
        vm.states = undefined;
        vm.rule = undefined;
        vm.ruleCopy = undefined;
        vm.isEditing = false;

        // functions
        vm.edit = edit;
        vm.cancel = cancel;
        vm.save = save;

        init();

        /* 
        * @name init
        * @desc initialize module
        */
        function init() {
            vm.company = lifecompanyService.getSelected();

            if (!vm.company || !vm.company.Id) return;

            lifecompanyService.getRules(vm.company.Id).then(function (result) {
                vm.company = result;
                if (vm.company && vm.company.Rules) {
                    vm.rule = vm.company.Rules;
                }
            });
        };

        /* 
        * @name edit
        * @desc show form to edit existing rule. 
        */
        function edit() {
            vm.ruleCopy = angular.copy(vm.rule, {});
            vm.isEditing = true;
        };

        /* 
        * @name cancel
        * @desc cancel add/update and hide the modal. 
        */
        function cancel() {
            vm.isEditing = false;
            vm.rule = vm.ruleCopy;
        };

        /* 
        * @name save
        * @desc save (add/update) and hide the modal. 
        */
        function save() {
            if (!vm.rule) return;
            
            vm.company.Rules = vm.rule;

            // commit to db
            lifecompanyService.saveRules(vm.company).then(function (result) {
                vm.company = result;
                vm.rule = vm.company.Rules;
                vm.isEditing = false;
                lifecompanyService.setSelected(vm.company);
            });
        };
    };
})();