/**
* @name chronovoFormatDate
* @desc Format a date in UI using the Angularjs $filter.
* @usage: <span ng-model="datefield" md-format-date="MM/dd/yyyy"></span>
**/
(function () {
    'use strict';

    angular
        .module('CareGuard')
        .directive('chronovoFormatDate', chronovoFormatDate);

    /** Inject services into directive. */
    chronovoFormatDate.$inject = ['$filter'];

    /**
    * @name chronovoFormatDate.
    * @desc input Mask wrapper Directive.
    * @param {object} $filter - angularjs filter service.
    */
    function chronovoFormatDate($filter) {
        var directive = {
            restrict: 'A',
            require: '^ngModel',
            link: postLink
        };

        return directive;

        function postLink($scope, $element, $attrs, $controller) {
            // set defaults
            var dateFormat = $attrs.formatDate || 'MM/dd/yyyy';
            var dateFilter = $filter('date');

            $controller.$formatters.push(function (value) {
                if (angular.isString(value)) {
                    return dateFilter(value, dateFormat);
                }
                return null;
            });

            $controller.$parsers.unshift(function (value) {
                if (value) {
                    //return new Date(value);
                    return dateFilter(value, dateFormat);
                }
                return null;
            });
        }
    }
})();