/**
* @name chronovoOdometer
* @desc Odometer wrapper Directive.
* @usage <span chronovo-odometer="123"></span>
**/
(function () {
    'use strict';

    angular
        .module('CareGuard')
        .provider('chronovoOdometerOptions',chronovoOdometerOptions)
        .directive('chronovoOdometer', chronovoOdometer);

    /** Inject services into directive. */
    chronovoOdometer.$inject = ['chronovoOdometerOptions'];

    /**
    * @name chronovoOdometerOptions.
    * @desc Odometer options.
    */
    function chronovoOdometerOptions() {
        var self;
        self = this;
        self.defaults = {
            value: 0,
            //animation: 'count',
            duration: 300
        };
        this.$get = function () {
            return angular.copy(self.defaults);
        };
        return this;
    };

    /**
    * @name chronovoOdometer.
    * @desc Odometer wrapper Directive.
    */
    function chronovoOdometer(odometerOptions) {
        var directive = {
            restrict: 'A',
            link: postLink
        };

        return directive;

        function postLink($scope, $element, $attrs, $controller) {
            var opts = $scope.$eval($attrs.odometerOptions) || {};
            angular.extend(opts, odometerOptions);
            opts.el = $element[0];
            var odometer = new Odometer(opts);
            $scope.$watch($attrs.chronovoOdometer, function (newVal) {
                odometer.update(newVal);
            });
        };
    }
})();