angular
    .module('CareGuard')
    .factory('fundingEventsService', fundingEventsService);

fundingEventsService.$inject = [
    '$q',
    'bankingService',
    'fundsExhaustionEventTypeEnum'
];

function fundingEventsService(
    $q,
    bankingService,
    fundsExhaustionEventTypeEnum
) {
    function buildFundsExhaustionEventsDataFromMemberId({ memberId }) {
        return $q.all([
            bankingService.getFundsExhaustionEventTypes(),
            bankingService.getFundsExhaustionEventsByMemberId(memberId)
        ]).then(([{ data: eventTypes }, { data: events }]) => {
            if (!eventTypes?.length || !events?.length) return events;

            events = removeInvalidEvents(events);
            const eventTypesMap = buildFundsExhaustionEventTypeMap(eventTypes);

            return events?.map(event => {
                const eventType = eventTypesMap.get(event.eventTypeId);
                return {
                    type: eventType.type,
                    description: eventType.description,
                    bankName: bankingService.getBankNameById(event.bank),
                    day: event.eventDate.substring(0, 10),
                    value: getCalendarDayValueByEventTypeId(event.eventTypeId)
                };
            });
        });
    }

    function removeInvalidEvents(unfilteredEvents) {
        if (!unfilteredEvents?.length) return; 

        const sortedEvents = sortEventsByDate(unfilteredEvents);

        return sortedEvents?.reduce((filteredEvents, event) => {
            if (!filteredEvents.length || _.last(filteredEvents)?.eventTypeId != event.eventTypeId)
                filteredEvents.push(event);

            return filteredEvents;
        }, []);
    }

    function sortEventsByDate(events) {
        return _.sortBy(events, 'eventDate');
    }

    function buildFundsExhaustionEventTypeMap(eventTypes) {
        return eventTypes.reduce((map, obj) => map.set(obj.id, obj), new Map());
    }

    function getCalendarDayValueByEventTypeId(id) {
        let value = null;
        switch (id) {
            case fundsExhaustionEventTypeEnum.PermanantFundsExhaustion:
                value = 100;
                break;
            case fundsExhaustionEventTypeEnum.TemporaryFundsExhaustion:
                value = 600;
                break;
            case fundsExhaustionEventTypeEnum.MemberApproachingFundsExhaustion:
                value = 1100;
                break;
            case fundsExhaustionEventTypeEnum.Replenishment:
                value = 1600;
                break;
            case fundsExhaustionEventTypeEnum.FirstFundingReceived:
                value = 2100;
                break;
        }
        return value;
    }

    function getEarliestEventYear({ events }) {
        if (!events?.length) return; 

        return events.reduce((fromYear, event) => {
            const eventYear = new Date(event.day).getFullYear();
            return eventYear < fromYear ? eventYear : fromYear;
        }, Number.MAX_SAFE_INTEGER);
    }

    function fillEventArray({ events }) {
        if (!events?.length) return;

        const currentMomentDate = moment();
        const currentDate = currentMomentDate.format().substring(0, 10);

        if (events?.length == 1) {
            const singleEventRange = [];
            const onlyEvent = events[0];
            singleEventRange.push(onlyEvent);
            return singleEventRange.concat(fillEventArrayBetweenTwoEvents(onlyEvent, { day: currentDate }));
        }

        let fullEventArray = [];

        for (let i = 0; i < events.length - 1; i++) {
            let currentEvent = events[i];
            let nextEvent = events[i + 1];

            fullEventArray.push(currentEvent);
            fullEventArray = fullEventArray.concat(fillEventArrayBetweenTwoEvents(currentEvent, nextEvent));
        }
        
        const finalEvent = events[events.length - 1];
        const finalEventDate = finalEvent?.day;

        fullEventArray.push(finalEvent);

        if (moment(finalEventDate).isBefore(currentMomentDate))
            fullEventArray = fullEventArray.concat(fillEventArrayBetweenTwoEvents(finalEvent, { day: currentDate }));

        return fullEventArray;
    }

    function fillEventArrayBetweenTwoEvents(startEvent, endEvent) {
        const currentDate = moment(startEvent.day).add(1, 'days');
        const endDate = moment(endEvent.day);
        let events = [];

        while (currentDate.isBefore(endDate)) {
            events.push({
                day: currentDate.format().substring(0, 10),
                value: startEvent.value
            });
            currentDate.add(1, 'days');
        }
        return events;
    };

    return {
        buildFundsExhaustionEventsDataFromMemberId,
        getEarliestEventYear,
        fillEventArray,
    }
}