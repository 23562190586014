(function () {
    'use strict';

    angular
        .module('CareGuard')
        .controller('authResponseController', authResponseController);

    authResponseController.$inject = ['$stateParams', 'externalAuthService', 'xsrfService'];

    function authResponseController($stateParams, externalAuthService, xsrfService) {
        var vm = this;
        vm.error = null;

        init($stateParams);

        function init(stateParams) {
            externalAuthService.exchangeCodeForToken(stateParams.authCode, stateParams.state)
                .then(function (r) {
                    return xsrfService.createXsrfToken();
                })
                .then(function (r) {
                    // OK - redirect to previously asked page or a default one
                    var hash = '';
                    if (stateParams.state) {
                        var hashIndex = stateParams.state.indexOf(encodeURIComponent('#/'));
                        if (hashIndex >= 0) {
                            hash = decodeURIComponent(stateParams.state.substr(hashIndex))
                        }
                    }

                    if (!hash || hash == '#/authResponse') {
                       hash = "#/";
                    }

                    location.hash = hash;  // $location.hash does append hash rather than replace
                })
                .catch(function error(e) {
                    vm.error = e;
                });
        };

        vm.isInvalidResponse = function () {
            return !!this.error;
        };

        vm.redirectToIdentityServer = function() {
            externalAuthService.redirectToIdentityServer();
        }
    }
})();