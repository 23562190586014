angular
    .module('CareGuard')
    .directive('setGridWidth', setGridWidth);

setGridWidth.$inject = ['$timeout'];

function setGridWidth($timeout) {
    return {
        restrict: 'A',
        link: function (scope, elm, attr) {
            if (!attr.uiGrid) return;

            $timeout(function () {
                const tabContentElm = elm.closest('.tab-content');
                const tabContentElmWidth = tabContentElm && tabContentElm.width();
                elm.css('width', tabContentElmWidth + 'px');
            }, 0);
        }
    }
}