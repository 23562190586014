/**
* @name diaryService
* @desc diary Service.
**/
(function () {
    'use strict';

    angular
        .module('CareGuard')
        .factory('diaryService', diaryService);

    /** Inject services into service. */
    diaryService.$inject = ['$http', '$q'];

    /**
    * @name diaryService.
    * @desc chronograph Service.
    */
    function diaryService($http, $q) {
        var baseUrl = '/api/diary/';
        var showCallback = undefined;
        var closeCallback = undefined;
        var service = {
            setFunc: setFunc,
            show: show,
            close: close,
            get: get,
            getById: getById,
            save: save,
            remove: remove,
            archive: archive
        };

        return service;
        
        /**
        * @name setFunc.
        * @desc set callback for show function.
        * @param {object} obj - callback functions.
        */
        function setFunc(obj) {
            if (obj.show) {
                showCallback = obj.show;
            }
            if (obj.close) {
                closeCallback = obj.close;
            }
        };

        /**
        * @name show.
        * @desc fire the show callback.
        * @param {object} data - diary data.
        */
        function show(data) {
            if (showCallback) {
                showCallback(data);
            };
        };

        /**
        * @name close.
        * @desc fire the close callback.
        * @param {object} data - diary data.
        */
        function close(data) {
            if (closeCallback) {
                closeCallback(data);
            };
        };

        /**
        * @name get.
        * @desc search diary entries.
        * @param {object} params - search params.
        */
        function get(params) {
            return $http.get(baseUrl + 'get', { params: params });
        };

        /**
        * @name getByID.
        * @desc get diary entry given Id.
        * @param {object} ) Id - Id of case.
        */
        function getById(Id) {
            return $http.get(baseUrl + 'getByID/' + Id);
        };

        /**
        * @name save.
        * @desc save diary data.
        * @param {object} diary - diary to be saved.
        */
        function save(diary) {
            return $http.post(baseUrl + 'save', diary);
        };

        /**
        * @name archive.
        * @desc archive diary data.
        * @param {object} diary - diary to be saved.
        */
        function archive(diary) {
            return $http.post(baseUrl + 'archive', diary);
        };

        /**
        * @name remove.
        * @desc remove a diary entry
        * @param {object} diary - diary entry to be removed.
        */
        function remove(diary) {
            return $http.post(baseUrl + 'remove', diary);
        };
    };
})();