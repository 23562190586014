window.$ = window.jQuery = require('jquery');
window.angular = require('angular');
window.moment = require('moment');
window.toastr = require('toastr');
window.Enumerable = require('linq');
window.uiMask = require('angular-ui-mask');
window._ = require('lodash');
import flatpickr from 'flatpickr';

require('angular-sanitize');
require('angular-ui-router');
require('libs/pagination/pagination');
require('./node_modules/angular-ui-grid/ui-grid');
require('./node_modules/angular-ui-grid/ui-grid.css');
require('angular-messages');
require('angular-animate');
require('libs/angular/angular-web-storage');

// Bootstrap components
require('angular-ui-bootstrap');
require ('./node_modules/bootstrap-sass/assets/javascripts/bootstrap/dropdown');
require('./node_modules/bootstrap-select/js/bootstrap-select.js');
require('libs/uiBootstrapPagination/ui.bootstrap.pagination.js');

require('./app/careguard/index');

require('moment-timezone');
require('libs/angucomplete-alt');
require('libs/lumx/lumx');
require('libs/lumx/velocity.min');
require('angular-loading-bar');
require('libs/animation/anim-in-out');
require('angular-google-chart');
require('angularjs-slider');
require('angular-file-upload');
require('libs/angular-flatpicker/index');

require('libs/odometer/odometer.min.js');
require('libs/mongo/objectId.js');

require('libs/mask/inputmask');
require('libs/mask/jquery.inputmask.js');
require('libs/mask/jquery.inputmask.extensions.js');
require('libs/mask/jquery.inputmask.date.extensions.js');
require('libs/mask/jquery.inputmask.phone.extensions.js');
require('libs/mask/jquery.inputmask.numeric.extensions.js');
require('libs/mask/jquery.inputmask.regex.extensions.js');
require('libs/tooltips/jquery.tooltipster.min.js');

require('ng-file-upload');