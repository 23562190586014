angular
    .module('CareGuard')
    .factory('billReviewSagaService', billReviewSagaService);

billReviewSagaService.$inject = [
    '$q',
    'billReviewService',
    'claimService',
    'documentService',
    'billReviewReprocessStatus'
];


function billReviewSagaService(
    $q,
    billReviewService,
    claimService,
    documentService,
    billReviewReprocessStatus) {

    function createBillReviewReprocess(claimId, billReviewVendorId, reason, existingEors) {
        let brVendorReset = false;
        let isIrreversibleChange = false;

        return billReviewService.createBillReviewReprocess(claimId, reason).then(({ data: reprocessId }) => {
            return billReviewService.resetClaimForBillReview(claimId, billReviewVendorId)
                .then(() => {
                    brVendorReset = true;

                    return claimService.resetClaimForBillReview(claimId).then(() => {
                        //at this point, the reprocess can be treated as "completed" and can't be undone anymore.
                        isIrreversibleChange = true;

                        return isIrreversibleChange;
                    });
                }).then(() => {

                    if (!existingEors || existingEors.length == 0) {
                        return reprocessId;
                    }

                    var eorPromises = [];
                    existingEors.forEach(eor => {
                        eorPromises.push(
                            documentService.deleteMemberAzureBlobDocument({
                                    memberNumber: eor.memberNumber,
                                    filename: eor.fileName
                            })
                            .then(() => documentService.deleteDocumentDataById(eor.documentId))
                        );
                    });

                    return $q.all(eorPromises)
                        .then(() => reprocessId);

            });
        })
        .then(reprocessId => billReviewService.changeBillReviewReprocessStatus(reprocessId, billReviewReprocessStatus.Active))
        .catch(err => {
            console.log(err);
            //if the change is reversible, the error should be thrown further.
            //Otherwise, it should be suppressed and reprocess should be treated as completed anyways.
            if (!isIrreversibleChange) {

                var rejectedResult = $q.reject(err);

                if (brVendorReset) {
                    return billReviewService.cancelBillReview(claimId, billReviewVendorId)
                        .then(() => rejectedResult);
                }

                return rejectedResult;
            }
        });
    }

    function changeBillReviewVendor(claimId, newBillReviewVendorId, existingEorData) {
        return billReviewService.changeClaimBillReviewVendor(
            claimId,
            newBillReviewVendorId
        ).then((brVendorId) => {
            if (existingEorData) {
                return documentService.deleteDocumentDataById(existingEorData.documentId)
                    .then(() => documentService.deleteMemberAzureBlobDocument({
                        memberNumber: existingEorData.memberNumber,
                        filename: existingEorData.fileName
                    }))
                    .then(() => brVendorId);
            }

            return brVendorId;
        });
    }

    return {
        createBillReviewReprocess,
        changeBillReviewVendor
    };
}
