/**
* @name companyService
* @desc Company Service.
**/
(function () {
    'use strict';

    angular
        .module('CareGuard')
        .factory('companyService', companyService);

    /** Inject services into service. */
    companyService.$inject = ['$http'];

    /**
    * @name companyService.
    * @desc Company Service.
    */
    function companyService($http) {
        var baseUrl = '/api/companies/';
        var selected = undefined;
        var selectedCallback = undefined;
        var addNewCallback = undefined;
        var service = {
            setSelected: setSelected,
            getSelected: getSelected,
            setNewCallback: setNewCallback,
            fireNewCallback: fireNewCallback,
            get: get,
            getAll: getAll,
            getById: getById,
            save: save,
            remove: remove
        };
        
        return service;

        /**
        * @name setSelected.
        * @desc set current selected company.
        * @param {object} comp - selected company.
        */
        function setSelected(comp) {
            selected = comp;
            if (selectedCallback)
                selectedCallback(selected);
        };

        /**
        * @name getSelected.
        * @desc get current selected company.
        * @param {function} func - call function when selected company is changed.
        */
        function getSelected(func) {
            selectedCallback = func;
            return selected;
        };

        /**
        * @name setNewCallback.
        * @desc set callback when new company is added.
        * @param {function} func - call function when selected company is changed.
        */
        function setNewCallback(func) {
            addNewCallback = func;
        };

        /**
        * @name fireNewCallback.
        * @desc fires callback when new company is added.
        * @param {object} comp - selected company.
        */
        function fireNewCallback(comp) {
            if (addNewCallback)
                addNewCallback(comp);
        };
                
        /**
        * @name getAll.
        * @desc get list of all companies.
        */
        function getAll() {
            return $http.get(baseUrl + 'get/all');
        };

        /**
        * @name get.
        * @desc get list of companies.
        * @param {object} params - search parameter.
        */
        function get(params) {
            return $http.get(baseUrl + 'get', { params: params });
        };

        /**
        * @name getByID.
        * @desc get a company given Id.
        * @param {string} id - id of the company to get.
        */
        function getById(id) {
            return $http.get(baseUrl + 'getById/' + id);
        };

        /**
        * @name save.
        * @desc save a company.
        * @param {object} company - company to be saved.
        */
        function save(company) {
            return $http.post(baseUrl + 'save', company);
        };

        /**
        * @name remove.
        * @desc remove a company.
        * @param {object} company - company to be removed.
        */
        function remove(company) {
            return $http.post(baseUrl + 'remove', company);
        };
    }
})();