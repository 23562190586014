/**
* @name smartGridSelectAll
* @desc select all directive for smart-grid.
**/
(function () {
    'use strict';

    angular
        .module('smart-grid')
        .directive('smartGridSelectAll', smartGridSelectAll);

    /**
    * @name smartGridSelectAll.
    * @desc select all directive for smart-grid.
    */
    function smartGridSelectAll() {
        var directive = {
            restrict: 'AC',
            require: '^smartGrid',
            scope: {},
            link: postLink
        };

        return directive;

        function postLink($scope, $element, $attrs, $controller) {
            $scope.isChecked = false;
            $scope.$watch('isChecked', function (newValue, oldValue) {
                if (newValue !== oldValue) {
                    $controller.toggleSelectionAll(newValue);
                }
            });
        }
    }
})();


