/**
* @name memberService
* @desc member Service.
**/
(function () {
    'use strict';

    angular
        .module('CareGuard')
        .factory('memberFlagsService', memberFlagsService);

    /** Inject services into service. */
    memberFlagsService.$inject = ['$q', '$http'];

    /**
    * @name contactService.
    * @desc contact Service.
    */
    function memberFlagsService($q, $http) {
        var baseRoute = 'members';
        var service = {
            getMemberFlags: getMemberFlags,
            getMemberFlagsByMemberId: getMemberFlagsByMemberId,
            addMemberFlag: addMemberFlag,
            deleteMemberFlag: deleteMemberFlag,
            getAllMemberFlagTypes: getAllMemberFlagTypes
        };

        return service;

        /**
        * @name get.
        * @desc gets case Status count     
        */
        function getMemberFlags(memberId) {
            return $q.all([
                getMemberFlagsByMemberId(memberId),
                getAllMemberFlagTypes()
            ]).then(([{ data: flags }, { data: flagTypes }]) => {
                return {
                    flagTypes,
                    Data: flags?.map(flag => {
                        flag.flag = flagTypes?.find(type => type.flagTypeId === flag.flagId)?.flag;
                        return flag;
                    })
                };
            });
        }

        function getMemberFlagsByMemberId(memberId) {
            return $http.get(`${apiUrl}/${baseRoute}/${memberId}/flags`);
        };

        function addMemberFlag(newFlag) {
            return $http.post(`${apiUrl}/${baseRoute}/flags`,newFlag);
        };

        function deleteMemberFlag(rowId) {
            return $http.delete(`${apiUrl}/${baseRoute}/flags/${rowId}`);
        };

        function getAllMemberFlagTypes() {
            return $http.get(`${apiUrl}/${baseRoute}/flags/types`);
        };
    };
})();