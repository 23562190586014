/**
* @name dropZone
* @desc drop zone Directive.
**/
(function () {
  'use strict';

  angular
      .module('CareGuard')
      .directive('dropzone', dropzone);

  /** Inject services into directive. */
  //dropZone.$inject = ['$scope'];

  /**
  * @name dropZone.
  * @desc drag n drop Directive.
  */
  function dropzone() {
    var directive = {
      restrict: "A",
      link: postLink
    };

    return directive;

    function postLink($scope, $element, $attrs, $controller) {
      //console.log("DIRECTIVE REACHED");

      var processDragOverOrEnter;

      processDragOverOrEnter = function (evt) {
        if (evt != null) {
          //console.log("TEST DRAG");
          evt.preventDefault();
        }
        return false;
      };

      $element.bind('dragover', processDragOverOrEnter);
      $element.bind('dragenter', processDragOverOrEnter);

      $element.bind('drop', function (evt) {
        evt.preventDefault();
        evt.stopPropagation();

        var files = evt.originalEvent.dataTransfer.files;
        
        ////ONLY ALLOW 1 FILE AT TIME
        //$scope.addfile(files[0]);
                
        ////loop through files
        //for (var i = 0, f; f = files[i]; i++) {
        //  $scope.addfile(f);
        //  //ONLY ALLOW 1 FILE AT A TIME FOR NOW
        //  //break;
        //}

        //send all files at once
        $scope.addfiles(files, evt);


      });
    }
  }
})();


//var files = evt.originalEvent.dataTransfer.files
//for (var i = 0, f; f = files[i]; i++) {
//  var reader = new FileReader();
//  reader.readAsArrayBuffer(f);

//  reader.onload = (function (theFile) {
//    return function (e) {
//      //var newFile = {
//      //  name: theFile.name,
//      //  type: theFile.type,
//      //  size: theFile.size,
//      //  lastModifiedDate: theFile.lastModifiedDate
//      //}

//      //console.log(newFile.name);
//      $scope.addfile(reader.result);
//    };
//  })(f);
//}
