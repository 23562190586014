/**
* @name documentController
* @desc document Controller.
**/
(function () {
    'use strict';

    angular
        .module('CareGuard')
        .controller('documentController', documentController)



    /** Inject services into controller. */
    documentController.$inject = ['documentService', 'LxDialogService', 'lookupService', 'caseData', 'webStorageService', 'layoutService', 'FileUploader', '$toastr'];

    /**
    * @name globaldocumentController.
    * @desc Global document Controller.
    * @param {object} documentService - document service.
    */
    function documentController(documentService, LxDialogService, lookupService, caseData, webStorage, layoutService, FileUploader, $toastr) {

        // variables
        var vm = this;
        var dialogId = 'dlgAddDocument';
        vm.uploaders;
        vm.documents = {
            selected: undefined,
            searchBy: undefined,
            searchByAttribute: undefined,
            currentPage: 1,
            pageSize: 15,
            itemCount: 1,
            items: []
        };

        // functions
        vm.clear = clear;
        vm.search = search;
        vm.get = get;
        vm.select = select;
        vm.case = undefined;
        vm.documentTypes = undefined;
        vm.close = close;
        vm.upload = upload;

        init();

        /* 
        * @name init
        * @desc initialize module
        */
        function init() {
            //maintain case data during page refresh
            vm.case = caseData;
            get();

            layoutService.setSelected({ Name: vm.case.CaseName });
            vm.documentTypes = lookupService.getByCategory('Document Type');
            documentService.setNewCallback(add);
            documentService.setRemoveCallback(remove);

            vm.uploader = new FileUploader({
                url: '/api/documents/upload',
                removeAfterUpload: true,
                autoUpload: false,
                //headers: {
                //    'X-XSRF-Token': webStorage.get('xsrf'),
                //    'Authorization': 'Bearer ' + webStorage.get('bearer')
                //},
                queueLimit: 5,
                filters: [{
                    name: 'fileSizeFilter',
                    fn: function (item) {
                        return item.size <= 1000000000;
                    }
                }],
                onBeforeUploadItem: function (item) {
                    item.headers = {
                        'X-XSRF-Token': webStorage.get('xsrf'),
                        'Authorization': 'Bearer ' + webStorage.get('bearer')
                    };
                    item.formData = [
                        {
                            type: item.file.Type,
                            name: item.file.Name,
                            ext: item.file.Extension,
                            caseId: vm.case.Id
                        }
                    ]
                },
                onWhenAddingFileFailed: function (item, filter, options) {
                    if (filter && filter.name == 'queueLimit')
                        $toastr.show('Maximum file size (1GB) exceeded.', 'error');
                    if (filter && filter.name == 'folder')
                        $toastr.show('Maximum of 5 files can be uploaded simultaneously.', 'error');
                },
                onAfterAddingFile: function (item) {
                    var res = item.file.name.split(".");
                    item.file.Name = res[0];
                    item.file.Extension = res[1];
                },
                onAfterAddingAll: function (items) {
                    show();
                },
                onSuccessItem: function (item, response, status, headers) {
                    if (response && response.MsgText) {
                        $toastr.show(response.MsgText, response.HasError === true ? 'error' : 'success');
                    }
                    item.isError = response.HasError;
                    item.isSuccess = !response.HasError;
                    item.isUploaded = item.isSuccess;
                },
                onErrorItem: function (item, response, status, headers) {
                    if (response && response.HasError === true) {
                        $toastr.show(response.data.MsgText, 'error');
                    }
                    else {
                        $toastr.show("An unexpected error has occured.", 'error');
                    }
                },
                onCompleteAll: function () {
                    LxDialogService.close(dialogId);
                    vm.get();
                }
            });

            documentService.setSelectedFile(vm.uploader);
        };

        /* 
        * @name clear
        * @desc Clear search bar and refresh list.
        */
        function clear() {
            vm.documents.currentPage = 1;
            vm.documents.searchByAttribute = undefined;
            vm.documents.searchBy = undefined;
            get();
        };

        /* 
        * @name search
        * @desc Search documents using the input string.
        */
        function search(filterBy) {
            vm.documents.currentPage = 1;
            vm.documents.searchByAttribute = filterBy;
            get();
        };

        /* 
        * @name get
        * @desc Get a list of documents to display. 
        */
        function get() {
            var params = {
                pageSize: vm.documents.pageSize,
                pageNum: vm.documents.currentPage,
                sort: 'Name',
                sortDir: 'asc',
                filter: {}
            };

            // add filter if exists
            if (vm.documents.searchByAttribute) {
                params.filter[vm.documents.searchByAttribute] = vm.documents.searchBy;
            };

            // refresh results from db
            documentService.get(params, vm.case.Id).then(function (data) {
                for (var key in data.Items) {
                    var item = data.Items[key];
                    var lookup = Enumerable.from(vm.documentTypes).where('$.Key == "' + item.Type + '"').firstOrDefault(undefined);
                    if (lookup && lookup.Value) {
                        item.TypeName = lookup.Value;
                    }
                }
                vm.documents.items = data.Items;
                vm.documents.itemCount = data.ItemCount;
                if (vm.documents.items.length > 0)
                    select(0, vm.documents.items[0]);
                else
                    documentService.setSelected(undefined);
            });
        };

        /* 
        * @name select
        * @desc Select the Active document. 
        * @param {int} index - index of document in document.items array.
        * @param {object} document - document object.
        */
        function select(index, document) {
            document.Index = index;
            vm.documents.selected = document;
            documentService.setSelected(document);
        };

        /* 
        * @name add
        * @desc callback function when new document is added. 
        * @param {object} document - new document.
        */
        function add(document) {
            if (!document || document.Index < 0) {
                vm.documents.selected = undefined;
                vm.isNew = true;
                return;
            };

            var index = document.Index || 0;
            if (document.IsNew === true) {
                vm.documents.items.unshift(document);
            }
            else {
                vm.documents.items[index] = document;
            }
            vm.isNew = false;
            select(index, document);
            documentService.setSelected(document);
        };

        /* 
        * @name remove
        * @desc callback function when new document is removed. 
        * @param {object} document - deleted document.
        */
        function remove(document) {
            if (document && document.Index >= 0) {
                vm.documents.items.splice(document.Index, 1);
                if (vm.documents.items.length != 0) {
                    select(0, vm.documents.items[0]);
                }
                else {
                    documentService.setSelected(undefined);
                }
            };
        };

        /* 
        * @name drag
        * @desc open document Modal to add new document
        * @param {object} data - $flow.files
        */
        function show() {
            LxDialogService.open(dialogId);
        };

        /* 
        * @name close
        * @desc close Document Modal      
        */
        function close() {
            vm.uploader.clearQueue();
            LxDialogService.close(dialogId);
        };

        /**
        * @name upload
        * @desc upload new documents
        */
        function upload() {
            var items = vm.uploader.getNotUploadedItems().filter(function (item) {
                return !item.isUploading;
            });

            if (!items.length) return;

            angular.forEach(items, function (item) {
                item._prepareToUploading();
                item.upload();
            });
        };
    };
})();