/**
* @name claimServiceclaim
* @desc claim Service.
**/
(function () {
    'use strict';

    angular
        .module('CareGuard')
        .factory('invalidClaimsService', invalidClaimsService);

    /** Inject services into service. */
    invalidClaimsService.$inject = ['$http'];

    function invalidClaimsService($http) {
      var baseUrl = '/api/invalidclaims/';
        
        var service = {
            filterBills: filterBills,
            getImageFile: getImageFile,
            acknowledgeBill: acknowledgeBill,
            applyResolutions: applyResolutions,
            exportBills: exportBills
        };

        return service;

        
        function filterBills(acknowledged, recordtype, billtype, membernumber, lastname, claimnumber, startdate, enddate, pageindex, pagesize, sortcolumn, sortorder) {
          return $http.get(baseUrl + 'filterBills/' + acknowledged + '/' + recordtype + '/' + billtype + '/' + membernumber + '/' + lastname + '/' + claimnumber + '/' + startdate + '/' + enddate + '/' + pageindex + '/' + pagesize + '/' + sortcolumn + '/' + sortorder);
        }

        function getImageFile(membernumber, recordtype, documentname, extension) {
          return $http.get(baseUrl + 'getImageFile/', membernumber + '/' + recordtype + '/' + documentname + '/' + extension);
        }
      
        function acknowledgeBill(imagefile) {
          return $http.get(baseUrl + 'acknowledgeBill/' + imagefile + '/');
        }

        function applyResolutions(invalidclaimschanges) {
          return $http.post(baseUrl + 'applyResolutions', invalidclaimschanges);
        }

        function exportBills(acknowledged, recordtype, billtype, membernumber, lastname, claimnumber, startdate, enddate) {
          return $http.get(baseUrl + 'exportBills/' + acknowledged + '/' + recordtype + '/' + billtype + '/' + membernumber + '/' + lastname + '/' + claimnumber + '/' + startdate + '/' + enddate);
        }

    }
})();