/**contact
* @name claimGridController
* @desc claim grid controller.
**/
(function () {
    'use strict';

  angular
    .module('CareGuard')
    .controller('billReviewController', billReviewController);

    /** Inject services into controller. */
    billReviewController.$inject = ['$scope', '$location', '$state', '$timeout', 'billReviewService', 'lookupService', 'accountService', 'claimService', 'utilService'];

    /**
    * @name claimGridController.
    * @desc claim grid controller.   
    */
    function billReviewController($scope, $location, $state, $timeout, billReviewService, lookupService, accountService, claimService, utilService) {

      // variables
      var vm = this;
      //var iframe = undefined;
            
      vm.bills = undefined;
      vm.billsWithChanges = undefined;
      //vm.lookupdata = undefined;
      vm.startdate = undefined;
      vm.enddate = undefined;
      vm.audit = {
        Received: 0,
        Loaded: 0,
        NotLoaded: 0        
      };

      vm.isacknowledged = {
        data: [
          { Name: "All", Value: 2 },
          { Name: "Yes", Value: 1 },
          { Name: "No", Value: 0 }
        ]
      };

      vm.recordtypes = { //these are not currently a lookup type. See BillReviewErrorType enum values in Ametros.Jobs
        data: [
          { Name: "All", Value: "All" },
          { Name: "Invalid Claim ID", Value: "InvalidClaimId" },
          { Name: "Invalid Member Number", Value: "InvalidMemberNumber" },
          { Name: "Invalid Status", Value: "InvalidClaimStatus" },
          { Name: "Invalid Vendor Tax ID", Value: "InvalidVendorTaxId" },
          { Name: "Missing EOR Image", Value: "MissingEorImage" },
          { Name: "Missing Service Code", Value: "MissingServiceCode" }
        ]
      };

      vm.insurancetypes = {
        data: [
            { Name: "All", Value: "All" },
            { Name: "WC", Value: "WC" },
            { Name: "Liability", Value: "Liability" }
        ]
      };

      vm.pageSizes = {
        data: [
            { Name: 5, Value: 5 },
            { Name: 10, Value: 10 },
            { Name: 20, Value: 20 },
            { Name: 50, Value: 50 },
            { Name: 100, Value: 100 },
            { Name: 1000, Value: 1000 }
        ]
      };
      
      vm.filterData = {
        TotalPages: 1,
        TotalRows: undefined,
        PageSize: 20,
        PageIndex: 1,
        SortColumn: undefined,
        SortOrder: 'ASC',
        Acknowledged: undefined,
        RecordType: undefined,
        InsuranceType: undefined,
        ClaimID: undefined,
        StartDate: undefined,
        EndDate: undefined

      };


      // functions
      vm.filterBills = filterBills;
      vm.acknowledgeBill = acknowledgeBill;
      vm.applyResolutions = applyResolutions;
      vm.changeSort = changeSort;
      vm.clearFilterInfo = clearFilterInfo;
      vm.resetFilterInfo = resetFilterInfo;
      vm.refresh = refresh;
      vm.navigate = navigate;
      vm.getImageFile = getImageFile;
      vm.updateVendor = updateVendor;
      vm.getAuditInfo = getAuditInfo;
      vm.exportBills = exportBills;
      vm.doesBillHaveInvalidVendorTaxId = doesBillHaveInvalidVendorTaxId;

      init();

      /* 
      * @name init
      * @desc initialize module
      */
      function init() {

        //get default date range
        var firstofthemonth = undefined;
        var today = undefined;

        today = new Date();

        var dd = today.getDate();
        var mm = today.getMonth() + 1; //January is 0!
        var yyyy = today.getFullYear();

        if (dd < 10) {
          dd = '0' + dd
        }

        if (mm < 10) {
          mm = '0' + mm
        }
        today = mm + '-' + dd + '-' + yyyy;
        firstofthemonth = mm + '-01-' + yyyy;

        vm.enddate = today;
        vm.startdate = firstofthemonth;
        
        //iframe = document.createElement("iframe");

        resetFilterInfo();

        filterBills();
        
        
      };
        

      //filter bills
      function filterBills() {
        var tempAck = vm.filterData.Acknowledged;
        var tempRecType = vm.filterData.RecordType;
        var tempInsType = vm.filterData.InsuranceType;
        var tempCID = vm.filterData.ClaimID;
        var tempSortCol = vm.filterData.SortColumn;

        if (tempAck == undefined || tempAck == '')
          tempAck = null;
        
        if (tempRecType == undefined || tempRecType == '')
          tempRecType = null;

        if (tempInsType == undefined || tempInsType == '')
          tempInsType = null;

        if (tempCID == undefined || tempCID == '')
          tempCID = null;

        if (tempSortCol == undefined || tempSortCol == '')
          tempSortCol = null;

        
        billReviewService.filterBills(tempAck || 2, tempRecType, tempInsType, tempCID || 0, vm.filterData.StartDate || '01-01-0001', vm.filterData.EndDate || '01-01-0001', vm.filterData.PageIndex, vm.filterData.PageSize, tempSortCol, vm.filterData.SortOrder).then(function (result) {
          vm.bills = result.Data;

          //attempt to fill TotalPages
          try {
            vm.filterData.TotalRows = vm.bills[0].TotalRows;
            vm.filterData.TotalPages = Math.ceil(vm.filterData.TotalRows / vm.filterData.PageSize);
          }
          catch (ex) {
            vm.filterData.TotalRows = 0;
            vm.filterData.TotalPages = 1;
          };          

          getAuditInfo();
        });


        };

      //acknowledge bill
      function acknowledgeBill(billReviewID) {
        billReviewService.acknowledgeBill(billReviewID).then(function (result) {
          filterBills();

        });

      };
      
      function applyResolutions() {
        //console.log("applyResolutions");

        //this will get all possible resolutions
        if (vm.bills && vm.bills.length > 0)
        {
          vm.billsWithChanges = angular.copy(vm.bills);
          for (var i = vm.billsWithChanges.length - 1; i > -1; i--)
          {
            //console.log(vm.billsWithChanges[i]);
            if (vm.billsWithChanges[i].PayeeID == null)
            {
              //console.log(vm.billsWithChanges[i].ClaimID);
              vm.billsWithChanges.splice(i,1);
            }
          }

          //console.log(vm.billsWithChanges);
        }

        if (vm.billsWithChanges && vm.billsWithChanges.length > 0 && confirm("Are you sure you want to apply the selected changes?"))
        {
          
          billReviewService.applyResolutions(vm.billsWithChanges).then(function (result) {
            filterBills();

          });
        }        

      };

      function updateVendor(bill) {
        var vendor = bill.SelectedVendor.split("-");

        var payeeid = vendor[0];
        var payeeaddressid = vendor[1];

        bill.PayeeID = payeeid;
        bill.PayeeAddressID = payeeaddressid;

      };
      
      //sorts the columns
      function changeSort(column) {
        if (vm.filterData.SortColumn == column) {
          vm.filterData.SortOrder = (vm.filterData.SortOrder == 'ASC' ? 'DESC' : 'ASC')
        }
        else {
          vm.filterData.SortColumn = column;
          vm.filterData.SortOrder = 'ASC';
        }

        filterBills();
      };

      //refreshes or changes the page of data
      function refresh(page) {
        if (page == null || (page > 0 && page <= vm.filterData.TotalPages)) {
          vm.filterData.PageIndex = vm.filterData.TotalRows == 0 || page == null ? 1 : page;
          filterBills();
        }

      };

      //clears the filter data
      function clearFilterInfo() {
        vm.filterData.Acknowledged = "2";
        vm.filterData.RecordType = "All";
        vm.filterData.InsuranceType = "All";
        vm.filterData.ClaimID = null;
        vm.filterData.StartDate = null;
        vm.filterData.EndDate = null;

      };

      //resets the filter data
      function resetFilterInfo() {
        vm.filterData.Acknowledged = "0";
        vm.filterData.RecordType = "All";
        vm.filterData.InsuranceType = "All";
        vm.filterData.ClaimID = null;
        vm.filterData.StartDate = vm.startdate;
        vm.filterData.EndDate = vm.enddate;

      };

      function navigate(state, params) {

        $timeout(function () {
          $state.go(state, params);
        }, 250);

      };


      function getImageFile(file, membernumber) {

          if (file != undefined) {
          var str = encodeURIComponent(file);

          var values = str.split(".");

          var value1 = values[0];
          var value2 = values[1];

          //iframe.src = "/api/billreview/getImageFile/" + membernumber + '/' + value1 + '/' + value2;

          ////// This makes the IFRAME invisible to the user.
          //iframe.style.display = "none";

          ////// Add the IFRAME to the page.  This will trigger
          //////   a request to GenerateFile now.
          //document.body.appendChild(iframe);

          membernumber = (membernumber || " "); //handling null value as null gets converted into string "null" during ajax call
          var exportOptions = {
              method: 'GET',
              url: "/api/billreview/getImageFile/" + membernumber + '/' + value1 + '/' + value2
          };
          var openInNewTab = true;
          utilService.download(exportOptions, openInNewTab);
        }
      };

      //called from filterBills
      function getAuditInfo() {
        billReviewService.getAuditInfo().then(function (result) {
          //console.log(result.Data);
          vm.audit.Received = result.Data.ClaimCount;
          vm.audit.Loaded = result.Data.ValidClaims;
          vm.audit.NotLoaded = result.Data.InvalidClaims;

        });
      };

      function exportBills() {
        //console.log("called export");
        if (vm.filterData != undefined) {
          var tempAck = vm.filterData.Acknowledged;
          var tempRecType = vm.filterData.RecordType;
          var tempInsType = vm.filterData.InsuranceType;
          var tempCID = vm.filterData.ClaimID;
          var tempSDate = vm.filterData.StartDate;
          var tempEDate = vm.filterData.EndDate;


          if (tempAck == undefined || tempAck == '')
            tempAck = 2;

          if (tempRecType == undefined || tempRecType == '')
            tempRecType = null;

          if (tempInsType == undefined || tempInsType == '')
            tempInsType = null;

          if (tempCID == undefined || tempCID == '')
            tempCID = 0;

          if (tempSDate == undefined || tempSDate == '')
            tempSDate = '01-01-0001';
          
          if (tempEDate == undefined || tempEDate == '')
            tempEDate = '01-01-0001';


          //iframe.src = "/api/billreview/exportBills/" + tempAck + '/' + tempRecType + '/' + tempInsType + '/' + tempCID + '/' + tempSDate + '/' + tempEDate

          //console.log(iframe.src);
          //// This makes the IFRAME invisible to the user.
          //iframe.style.display = "none";

          //// Add the IFRAME to the page.  This will trigger
          ////   a request to GenerateFile now.
          //document.body.appendChild(iframe);
          var exportOptions = {
            method: 'GET',
            url: "/api/billreview/exportBills/" + tempAck + '/' + tempRecType + '/' + tempInsType + '/' + tempCID + '/' + tempSDate + '/' + tempEDate
          };
          utilService.download(exportOptions);
        }
      }

        function doesBillHaveInvalidVendorTaxId(bill) {
            return bill && (bill.RecordType.toUpperCase() == 'InvalidVendorTaxId'.toUpperCase());
        }

    };
})();