angular
    .module('CareGuard')
    .factory('1099Service', _1099Service);

_1099Service.$inject = ['$http'];

function _1099Service($http) {
    // External API calls
    const baseRoute = `documents/1099s`;

    function get1099Archives() {
        return $http.get(`${apiUrl}/${baseRoute}`);
    }

    return {
        get1099Archives
    };
}
