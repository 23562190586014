/**
* @name companyController
* @desc Company Controller.
**/
(function () {
    'use strict';

    angular
        .module('CareGuard')
        .controller('companyController', companyController)

    /** Inject services into controller. */
    companyController.$inject = ['companyService'];

    /**
    * @name companyController.
    * @desc Company Controller.
    * @param {object} companyService - company service.
    */
    function companyController(companyService) {
        var vm = this;

        vm.selectedTab = undefined;
        vm.isNew = false;
        vm.companies = {
            selected: undefined,
            searchBy: undefined,
            searchByAttribute: undefined,
            currentPage: 1,
            pageSize: 15,
            itemCount: 1,
            items: []
        };
        vm.selectTab = selectTab;
        vm.clear = clear;
        vm.get = get;
        vm.search = search;
        vm.select = select;

        init();

        /* 
        * @name init
        * @desc initialize module
        */
        function init() {
            get();
            companyService.setNewCallback(add);
        };

        /* 
        * @name selectTab
        * @desc set selected tab.
        */
        function selectTab(tab) {
            vm.selectedTab = tab;
        };

        /* 
        * @name clear
        * @desc Clear search bar and refresh list.
        */
        function clear() {
            vm.companies.currentPage = 1;
            vm.companies.searchByAttribute = undefined;
            vm.companies.searchBy = undefined;
            get();
        };

        /* 
        * @name search
        * @desc Search companies using the input string.
        */
        function search(filterBy) {
            vm.companies.currentPage = 1;
            vm.companies.searchByAttribute = filterBy;
            get();
        };

        /* 
        * @name get
        * @desc Get a list of companies to display. 
        */
        function get() {
            var params = {
                pageSize: vm.companies.pageSize,
                pageNum: vm.companies.currentPage,
                sort: 'Name',
                sortDir: 'asc',
                filter: {}
            };

            // add filter if exists
            if (vm.companies.searchByAttribute) {
                params.filter[vm.companies.searchByAttribute] = vm.companies.searchBy;
            }

            // refresh results from db
            companyService.get(params).then(function (data) {
                vm.companies.items = data.Items;
                vm.companies.itemCount = data.ItemCount;
                if (vm.companies.items.length > 0)
                    select(0, vm.companies.items[0]);
            });
        };

        /* 
        * @name select
        * @desc Select the Active company. 
        * @param {int} index - index of company in Company.Items array.
        * @param {object} company - Company object.
        */
        function select(index, company) {
            company.Index = index;
            vm.companies.selected = company;
            companyService.setSelected(company);
            selectTab('details');
        };

        /* 
        * @name add
        * @desc callback function when new company is added. 
        * @param {object} comp - new company.
        */
        function add(comp) {
            if (!comp || comp.Index < 0) {
                vm.companies.selected = undefined;
                vm.isNew = true;
                return;
            };

            var index = comp.Index || 0;
            if (comp.IsNew === true) {
                vm.companies.items.unshift(comp);
            }
            else if (comp.Remove === true) {
                vm.companies.items.splice(index, 1);
                index = 0;
            }
            else {
                vm.companies.items[index] = comp;
            };
            vm.isNew = false;
            select(index, comp);
            companyService.setSelected(comp);
        };
    };
})();