/**
* @name chronovoSelect
* @desc lx-select wrapper, translates values between model and selection.
* @usage: <lx-select ng-model="datefield" choices="items" chronovo-select/>
**/
(function () {
    'use strict';

    angular
        .module('CareGuard')
        .directive('chronovoSelect', chronovoSelect);

    /** Inject services into directive. */
    chronovoSelect.$inject = ['$compile', '$parse'];

    /**
    * @name chronovoSelect.
    * @desc lx-select wrapper, translates values between model and selection.
    * @param {object} $compile - angularjs compile service.
    */
    function chronovoSelect($compile, $parse) {
        var directive = {
            restrict: 'A',
            priority: 1000,
            terminal: true,
            replace: false,
            link: postLink
        };

        return directive;
        
        function postLink($scope, $element, $attrs) {
            $element.removeAttr('chronovo-select'); // necessary to avoid infinite compile loop
            $element.attr('model-to-selection', $attrs.name +'_toSelection(data, callback)');
            $element.attr('selection-to-model', $attrs.name + '_toModel(data, callback)');

            /*
             * Add toSelection function. 
             */
            $scope[$attrs.name + '_toSelection'] = function (data, callback) {
                var key = $attrs.chronovoSelect;
                var items = $scope.$eval($attrs.choices);
                var val = Enumerable.from(items).where('$.' + key + ' == "' + data + '"').firstOrDefault(undefined);
                if (val) {
                    callback(val);
                } else {
                    callback();
                }
            }

            /*
             * Add toModel function. 
             */
            $scope[$attrs.name + '_toModel'] = function (data, callback) {
                var key = $attrs.chronovoSelect;
                if (data && key) {
                    callback(data[key]);
                } else {
                    callback();
                }
            }

            $compile($element)($scope);
        }
    }
})();