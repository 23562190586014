/**
* @name lifecompanyController
* @desc Life Company Controller.
**/
(function () {
    'use strict';

    angular
        .module('CareGuard')
        .controller('lifecompanyController', lifecompanyController)

    /** Inject services into controller. */
    lifecompanyController.$inject = ['lifecompanyService'];

    /**
    * @name lifecompanyController.
    * @desc Life Company Controller.
    * @param {object} companyService - company service.
    */
    function lifecompanyController(lifecompanyService) {
        var vm = this;

        // variables
        vm.isNew = false;
        vm.companies = undefined;
        vm.selected = undefined;
        vm.selectedTab = undefined;

        // functions
        vm.select = select;
        vm.selectTab = selectTab;

        init();

        /* 
        * @name init
        * @desc initialize module
        */
        function init() {
            lifecompanyService.setNewCallback(add);
            var data = lifecompanyService.get();
            vm.companies = Enumerable.from(data).orderBy('$.Name').toArray();
            if (vm.companies.length > 0) {
                select(0, vm.companies[0]);
            };
        };

        /* 
        * @name selectTab
        * @desc set selected tab.
        */
        function selectTab(tab) {
            vm.selectedTab = tab;
        };

        /* 
        * @name select
        * @desc Select the Active company. 
        * @param {int} index - index of company in Company.Items array.
        * @param {object} company - Company object.
        */
        function select(index, company) {
            company.Index = index;
            vm.selected = company;
            lifecompanyService.setSelected(company);
            selectTab('details');
        };

        /* 
        * @name add
        * @desc callback function when new company is added. 
        * @param {object} comp - new company.
        */
        function add(comp) {
            if (!comp || comp.Index < 0) {
                vm.selected = undefined;
                vm.isNew = true;
                return;
            };

            var index = comp.Index || 0;
            if (comp.IsNew === true) {
                vm.companies.unshift(comp);
            }
            else {
                vm.companies[index] = comp;
            }
            vm.isNew = false;
            select(index, comp);
        };
    };
})();