/**
* @name annuitantModalController
* @desc annuitant controller.
**/
(function () {
    'use strict';

    angular
        .module('CareGuard')
        .controller('annuitantModalController', annuitantModalController);

    /** Inject services into controller. */
    annuitantModalController.$inject = ['$toastr', 'LxDialogService', 'lookupService', 'annuitantService'];

    /**
    * @name annuitantModalController.
    * @desc annuitant Controller.
    */
    function annuitantModalController($toastr, LxDialogService, lookupService, annuitantService) {
        var vm = this;

        // variables
        var dialogId = 'dlgAnnuitantModal';
        vm.annuitant = undefined;
        vm.original = undefined;
        vm.isNew = false;
        vm.lookupdata = undefined;

        // functions
        vm.save = save;

        init();

        /* 
        * @name init
        * @desc initilize service
        */
        function init() {
            vm.isNew = false;
            vm.lookupdata = {
                annuitantTypes: lookupService.getByCategory('Annuitant Type')
            };
            vm.original = annuitantService.getAnnuitant();
            if (!vm.original) {
                vm.annuitant = {};
                vm.isNew = true;
            }
            else {
                vm.annuitant = angular.copy(vm.original, {});
            };
        };

        /* 
        * @name save
        * @desc update service with annuitant and close dialog
        */
        function save() {
            if (!vm.original) {
                vm.original = {};
                var annuitants = annuitantService.getAnnuitants();
                if (!annuitants) {
                    annuitants = [];
                }
                annuitants.push(vm.original);
            };

            vm.original.FirstName = vm.annuitant.FirstName;
            vm.original.LastName = vm.annuitant.LastName;
            vm.original.Name = vm.annuitant.Name;
            vm.original.AnnuitantType = vm.annuitant.AnnuitantType;
            vm.original.BirthDate = vm.annuitant.BirthDate;
            vm.original.Age = vm.annuitant.Age;
            vm.original.Gender = vm.annuitant.Gender;
            vm.original.SSN = vm.annuitant.SSN;
            vm.original.Email = vm.annuitant.Email;

            annuitantService.fireUpdateCallback();
            LxDialogService.close(dialogId);
        };
    };
})();