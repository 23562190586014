require('./account/index.js');
require('./address/index.js');
require('./amethystclaim/index.js');
require('./annualfunding/index.js');
require('./banking/index.js');
require('./benefits/index.js');
require('./billreview/index.js');
require('./payBills');
require('./carequote/index.js');
require('./case/index.js');
require('./changehealthclaim/index.js');
require('./claim/index.js');
require('./claimDetails/index.js');
require('./company/index.js');
require('./diary/index.js');
require('./documents/index.js');
require('./sharepointDocuments/index.js');
require('./forms/index.js');
require('./invalidclaims/index.js');
require('./lifecompany/index.js');
require('./member/index.js');
require('./memberflags/index.js');
require('./memberonhold/index.js');
require('./homepage/index.js');
require('./notes/index.js');
require('./odglookup/index.js');
require('./payees/index.js');
require('./reports/index.js');
require('./shared/index.js');
require('./smart-quote/index.js');
require('./structurebroker/index.js');
require('./servicecode');
require('./user/index.js');
require('./betaPageMechanism/index.js');
require('./htmltopdf/index.js');
require('./memberStatements/index.js');
require('./annualAccounting/index.js');
require('./1099/index.js');
require('./customDocuments/index.js');
require('./funding-events/index.js');
require('./fundsExhaustion/index.js');
require('./bulkStatements/index.js');
require('./providers/index.js');
require('./payeeApproval/index.js');
require('./nonRedeemedClaims/index.js');
require('./californiaFeeSchedule/index.js');