angular
    .module('CareGuard')
    .directive('betaPageLink', betaPageLink);

betaPageLink.$inject = [];

function betaPageLink() {
    return {
        restrict: 'E',
        templateUrl: 'app/careguard/views/betaPageMechanism/betaPageLink.layout.html',
        controller: ['$scope', '$state', 'betaPageService', ctrl],
        controllerAs: 'vm',
        scope: {}
    }
}

function ctrl($scope, $state, betaPageService) {
    const vm = this;

    vm.isBetaPageOpened = isBetaPageOpened;
    vm.isOriginalPageOpened = isOriginalPageOpened;
    vm.navigateToOriginalPage = navigateToOriginalPage;
    vm.navigateToBetaPage = navigateToBetaPage;
    vm.setCurrentPageAsDefault = setCurrentPageAsDefault;

    vm.isPageSetAsDefaultForUser = false;

    $scope.$watch(() => $state.current.name, () => {
            refreshIsDefaultFlag();
        }, true);

    function isBetaPageOpened() {
        return betaPageService.isBetaPageOpened();
    }

    function isOriginalPageOpened() {
        return betaPageService.isOriginalPageOpened();
    }

    function navigateToOriginalPage() {
        betaPageService.navigateToOriginalPage();
    }

    function navigateToBetaPage() {
        betaPageService.navigateToBetaPage();
    }

    function setCurrentPageAsDefault() {
        betaPageService.setCurrentPageAsDefault().then(() => {
            refreshIsDefaultFlag();
        });
    }

    function refreshIsDefaultFlag() {
        betaPageService.isPageSetAsDefaultForUser().then(result => {
            vm.isPageSetAsDefaultForUser = result;
        });
    }
}