
angular
    .module('CareGuard')
    .factory('billReviewService', billReviewService);

billReviewService.$inject = ['$http', '$q', 'billReviewVendors'];

function billReviewService($http, $q, billReviewVendors) {
    // External API calls
    const baseRoute = `brvs`;

    function getById(id) {
        return $http.get(`${apiUrl}/${baseRoute}/${id}`);
    }

    function getBillReviewReprocessesByClaimId(claimId) {
        return $http.get(`${apiUrl}/${baseRoute}/claims/${claimId}/reprocesses`);
    }

    function getVendors() {
        return $http.get(`${apiUrl}/${baseRoute}/vendors`)
    }

    function createBillReviewReprocess(claimId, reason) {
        return $http.post(`${apiUrl}/${baseRoute}/reprocess`,
            {
                claimId,
                reason
            });
    }

    function updateBillReviewReprocess(billReviewReprocess) {
        return $http.patch(`${apiUrl}/${baseRoute}/reprocess`, billReviewReprocess);
    }

    function changeBillReviewReprocessStatus(reprocessId, statusId) {
        return $http.patch(`${apiUrl}/${baseRoute}/reprocess/${reprocessId}/status`, statusId);
    }

    // CH C# controller calls
    const baseUrl = '/api/billreview/';
   
    function filterBills(acknowledged, recordtype, insurancetype, claimid, startdate, enddate, pageindex, pagesize, sortcolumn, sortorder) {
        return $http.get(baseUrl + 'filterBills/' + acknowledged + '/' + recordtype + '/' + insurancetype + '/' + claimid + '/' + startdate + '/' + enddate + '/' + pageindex + '/' + pagesize + '/' + sortcolumn + '/' + sortorder);
    }

    function getImageFile(membernumber, documentname, extension) {
        return $http.get(baseUrl + 'getImageFile/', membernumber + '/' + documentname + '/' + extension);
    }
      
    function acknowledgeBill(billreviewid) {
        return $http.get(baseUrl + 'acknowledgeBill/' + billreviewid);
    }

    function applyResolutions(billreviewchanges) {
        return $http.post(baseUrl + 'applyResolutions', billreviewchanges);
    }

    function getAuditInfo() {
        return $http.get(baseUrl + 'getAuditInfo');
    }

    function exportBills(acknowledged, recordtype, insurancetype, claimid, startdate, enddate) {
        return $http.get(baseUrl + 'exportBills/' + acknowledged + '/' + recordtype + '/' + insurancetype + '/' + claimid + '/' + startdate + '/' + enddate);
    }

    const getBillReviewAssignments = (claimId) => {
        return $http.post(`${apiUrl}/${baseRoute}/assignments`, [claimId]);
    };

    const resetClaimForBillReview = (claimId, brVendorId) => {
        return $http.patch(`${apiUrl}/${baseRoute}/${brVendorId}/reset/${claimId}`);
    };

    const changeClaimBillReviewVendor = (claimId, billReviewVendorId) => {
        return $http.patch(`${apiUrl}/${baseRoute}/assignments`, {
            ClaimId: claimId,
            PrimaryBillReviewVendor: billReviewVendorId
        }).then(() => billReviewVendorId);
    };

    const getFeeScheduleOnlyBillReviewVendor = (billReviewAssignments => {
        if (billReviewAssignments && billReviewAssignments.length > 1) {
            var feeScheduleVendor = billReviewAssignments.find(a => a.requestFeeScheduleOnly);
            if (feeScheduleVendor && feeScheduleVendor.receivedDate) {
                return feeScheduleVendor.billReviewVendorId;
            }
        }

        return null;
    });

    const getBillReviewAssignmentVendors = function (claimId, existingBillReviewVendorId) {
        var brAssignments = {
            assignments: [],
            feeScheduleVendorName: null
        };
        // no need to call when claim is not reviewed
        if (!existingBillReviewVendorId) {
            return $q.when(brAssignments);
        }

        return getBillReviewAssignments(claimId)
            .then(assignmentResponse => {
                var assignments = assignmentResponse.data;
                brAssignments.assignments = assignments;

                var feeScheduleVendorId = getFeeScheduleOnlyBillReviewVendor(assignments);
                if (feeScheduleVendorId != null && feeScheduleVendorId != existingBillReviewVendorId) {
                    return getById(feeScheduleVendorId)
                        .then(function (vendor) {
                            brAssignments.feeScheduleVendorName = vendor.data.name;
                            return brAssignments;
                        });
                }

                return brAssignments;
            });
    };

    const setCurrentBillReviewVendor = (claimId, brVendorId) => {
        return $http.patch(`${apiUrl}/${baseRoute}/${brVendorId}/current/${claimId}`);
    };

    const cancelBillReview = (claimId, brVendorId) => {
        return $http.patch(`${apiUrl}/${baseRoute}/${brVendorId}/cancel/${claimId}`);
    };

    const setCurrentEor = function (claimId, documentId, isCurrent) {
        return $http.post(`${baseUrl}setCurrentEor/${claimId}/${documentId}/${isCurrent}`);
    };

    const addDocumentEor = function (claimId, documentId) {
        return $http.post(`${baseUrl}addDocumentEor/${claimId}/${documentId}`);
    };

    return {
        // External API calls
        getById,
        getBillReviewReprocessesByClaimId,
        getVendors,
        createBillReviewReprocess,
        updateBillReviewReprocess,
        changeBillReviewReprocessStatus,
        // CH C# controller calls
        filterBills,
        getImageFile,
        acknowledgeBill,
        applyResolutions,
        getAuditInfo,
        exportBills,
        getBillReviewAssignments,
        resetClaimForBillReview,
        changeClaimBillReviewVendor,
        getFeeScheduleOnlyBillReviewVendor,
        getBillReviewAssignmentVendors,
        setCurrentBillReviewVendor,
        cancelBillReview,
        setCurrentEor,
        addDocumentEor
    };
};