/**
* @name chronovoLink
* @desc Link button click.
* @usage <button chronovo-link="element">Home</button>
**/
(function () {
    'use strict';

    angular
        .module('CareGuard')
        .directive('chronovoLink', chronovoLink);

    /**
    * @name chronovoLink.
    * @desc Link button click.
    */
    function chronovoLink() {
        var directive = {
            restrict: "A",
            link: postLink
        };

        return directive;

        function postLink($scope, $element, $attrs, $controller) {
            var id = $attrs["chronovoLink"];
            
            $element.on("click", function () {
                var elem = document.getElementById(id);
                if (elem) {
                    elem.click();
                }
            });
        };
    };
})();