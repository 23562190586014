/**contact
* @name claimGridController
* @desc claim grid controller.
**/
(function () {
    'use strict';

  angular
    .module('CareGuard')
    .controller('invalidClaimsController', invalidClaimsController);

    /** Inject services into controller. */
  invalidClaimsController.$inject = ['$scope', '$location', '$state', '$timeout', 'invalidClaimsService', 'lookupService', 'accountService', 'claimService','utilService'];

  function invalidClaimsController($scope, $location, $state, $timeout, invalidClaimsService, lookupService, accountService, claimService, utilService) {

      // variables
      var vm = this;
      //var iframe = undefined;
            
      vm.bills = undefined;
      vm.billsWithChanges = undefined;
      //vm.lookupdata = undefined;
      vm.startdate = undefined;
      vm.enddate = undefined;

      vm.isacknowledged = {
        data: [
          { Name: "All", Value: 2 },
          { Name: "Yes", Value: 1 },
          { Name: "No", Value: 0 }
        ]
      };

      vm.recordtypes = { //these are not currently a lookup type
        data: [
          { Name: "All", Value: "All" },
          { Name: "Duplicate", Value: "2" },
          { Name: "Invalid Claim (no details)", Value: "3" },
          { Name: "Invalid Claim (not loaded)", Value: "5" },
          { Name: "Invalid Member Number", Value: "1" },
          { Name: "Missing Information", Value: "4" }
        ]
      };
      
      vm.billtypes = { //these are not currently a lookup type
        data: [
          { Name: "All", Value: "All" },
          { Name: "Provider", Value: "Provider" },
          { Name: "PBM", Value: "PBM" }
        ]
      };

      vm.pageSizes = {
        data: [
            { Name: 5, Value: 5 },
            { Name: 10, Value: 10 },
            { Name: 20, Value: 20 },
            { Name: 50, Value: 50 },
            { Name: 100, Value: 100 }
        ]
      };
      
      vm.filterData = {
        TotalPages: 1,
        TotalRows: undefined,
        PageSize: 20,
        PageIndex: 1,
        SortColumn: undefined,
        SortOrder: 'ASC',
        Acknowledged: undefined,
        RecordType: undefined,
        BillType: undefined,
        MemberNumber: undefined,
        LastName: undefined,
        ClaimNumber: undefined,
        StartDate: undefined,
        EndDate: undefined

      };


      // functions
      vm.filterBills = filterBills;
      vm.acknowledgeBill = acknowledgeBill;
      vm.applyResolutions = applyResolutions;
      vm.changeSort = changeSort;
      vm.clearFilterInfo = clearFilterInfo;
      vm.resetFilterInfo = resetFilterInfo;
      vm.refresh = refresh;
      vm.navigate = navigate;
      vm.getImageFile = getImageFile;
      vm.updateVendor = updateVendor;
      vm.exportBills = exportBills;

      init();

      /* 
      * @name init
      * @desc initialize module
      */
      function init() {

        //get default date range
        var firstofthemonth = undefined;
        var today = undefined;

        today = new Date();

        var dd = today.getDate();
        var mm = today.getMonth() + 1; //January is 0!
        var yyyy = today.getFullYear();

        if (dd < 10) {
          dd = '0' + dd;
        }

        if (mm < 10) {
          mm = '0' + mm;
        }
        today = mm + '-' + dd + '-' + yyyy;
        firstofthemonth = mm + '-01-' + yyyy;

        vm.enddate = today;
        vm.startdate = firstofthemonth;
        
        //iframe = document.createElement("iframe");

        resetFilterInfo();

        filterBills();
        
        
      }
        

      //filter bills
      function filterBills() {
        var tempAck = vm.filterData.Acknowledged;
        var tempRecType = vm.filterData.RecordType;
        var tempBillType = vm.filterData.BillType;
        var tempMemberNumber = vm.filterData.MemberNumber;
        var tempLastName = vm.filterData.LastName;
        var tempClaimNumber = vm.filterData.ClaimNumber;
        var tempSortCol = vm.filterData.SortColumn;

        if (tempAck === undefined || tempAck === '')
          tempAck = null;
        
        if (tempRecType === undefined || tempRecType === '')
          tempRecType = null;

        if (tempBillType === undefined || tempBillType === '')
          tempBillType = null;

        if (tempMemberNumber === undefined || tempMemberNumber === '')
          tempMemberNumber = null;

        if (tempLastName === undefined || tempLastName === '')
          tempLastName = null;

        if (tempClaimNumber === undefined || tempClaimNumber === '')
          tempClaimNumber = null;

        if (tempSortCol === undefined || tempSortCol === '')
          tempSortCol = null;

        //console.log(tempAck || 2, tempRecType, tempBillType, tempMemberNumber, tempLastName, tempClaimNumber, vm.filterData.StartDate || '01-01-0001', vm.filterData.EndDate || '01-01-0001', vm.filterData.PageIndex, vm.filterData.PageSize, tempSortCol, vm.filterData.SortOrder);

        invalidClaimsService.filterBills(tempAck || 2, tempRecType, tempBillType, tempMemberNumber, tempLastName, tempClaimNumber, vm.filterData.StartDate || '01-01-0001', vm.filterData.EndDate || '01-01-0001', vm.filterData.PageIndex, vm.filterData.PageSize, tempSortCol, vm.filterData.SortOrder).then(function (result) {
          vm.bills = result.Data;

          //attempt to fill TotalPages
          try {
            vm.filterData.TotalRows = vm.bills[0].TotalRows;
            vm.filterData.TotalPages = Math.ceil(vm.filterData.TotalRows / vm.filterData.PageSize);
          }
          catch (ex) {
            vm.filterData.TotalRows = 0;
            vm.filterData.TotalPages = 1;
          }

        });


      }
      
      //export bills
      function exportBills() {
        var tempAck = vm.filterData.Acknowledged;
        var tempRecType = vm.filterData.RecordType;
        var tempBillType = vm.filterData.BillType;
        var tempMemberNumber = vm.filterData.MemberNumber;
        var tempLastName = vm.filterData.LastName;
        var tempClaimNumber = vm.filterData.ClaimNumber;
        var tempSDate = vm.filterData.StartDate;
        var tempEDate = vm.filterData.EndDate;

        if (tempAck === undefined || tempAck === '')
          tempAck = null;
        
        if (tempRecType === undefined || tempRecType === '')
          tempRecType = null;

        if (tempBillType === undefined || tempBillType === '')
          tempBillType = null;

        if (tempMemberNumber === undefined || tempMemberNumber === '')
          tempMemberNumber = null;

        if (tempLastName === undefined || tempLastName === '')
          tempLastName = null;

        if (tempClaimNumber === undefined || tempClaimNumber === '')
          tempClaimNumber = null;

        if (tempSDate === undefined || tempSDate === '')
          tempSDate = '01-01-0001';

        if (tempEDate === undefined || tempEDate === '')
          tempEDate = '01-01-0001';

        //iframe.src = "/api/invalidclaims/exportBills/" + tempAck + '/' + tempRecType + '/' + tempBillType + '/' + tempMemberNumber + '/' + tempLastName + '/' + tempClaimNumber + '/' + tempSDate + '/' + tempEDate

        ////console.log(iframe.src);
        //// This makes the IFRAME invisible to the user.
        //iframe.style.display = "none";

        //// Add the IFRAME to the page.  This will trigger
        ////   a request to GenerateFile now.
        //document.body.appendChild(iframe);
        
        var exportOptions = {
          method: 'GET',
          url: "/api/invalidclaims/exportBills/" + tempAck + '/' + tempRecType + '/' + tempBillType + '/' + tempMemberNumber + '/' + tempLastName + '/' + tempClaimNumber + '/' + tempSDate + '/' + tempEDate
          };
        utilService.download(exportOptions);
      }



      //acknowledge bill
      function acknowledgeBill(imageFile) {
        console.log(imageFile);
        invalidClaimsService.acknowledgeBill(imageFile).then(function (result) {
          filterBills();

        });

      }
      
      function applyResolutions() {
        //console.log("applyResolutions");

        //this will get all possible resolutions
        if (vm.bills && vm.bills.length > 0)
        {
          vm.billsWithChanges = angular.copy(vm.bills);
          for (var i = vm.billsWithChanges.length - 1; i > -1; i--)
          {
            //console.log(vm.billsWithChanges[i]);
            if (vm.billsWithChanges[i].PayeeID === null)
            {
              //console.log(vm.billsWithChanges[i].ClaimID);
              vm.billsWithChanges.splice(i,1);
            }
          }

          //console.log(vm.billsWithChanges);
        }

        if (vm.billsWithChanges && vm.billsWithChanges.length > 0 && confirm("Are you sure you want to apply the selected changes?"))
        {
          
          invalidClaimsService.applyResolutions(vm.billsWithChanges).then(function (result) {
            filterBills();

          });
        }        

      }

      function updateVendor(bill) {
        var vendor = bill.SelectedVendor.split("-");

        var payeeid = vendor[0];
        var payeeaddressid = vendor[1];

        bill.PayeeID = payeeid;
        bill.PayeeAddressID = payeeaddressid;

      }
      
      //sorts the columns
      function changeSort(column) {
        if (vm.filterData.SortColumn === column) {
          vm.filterData.SortOrder = vm.filterData.SortOrder === 'ASC' ? 'DESC' : 'ASC';
        }
        else {
          vm.filterData.SortColumn = column;
          vm.filterData.SortOrder = 'ASC';
        }

        filterBills();
      }

      //refreshes or changes the page of data
      function refresh(page) {
        if (page === null || page > 0 && page <= vm.filterData.TotalPages) {
          vm.filterData.PageIndex = vm.filterData.TotalRows === 0 || page === null ? 1 : page;
          filterBills();
        }

      }

      //clears the filter data
      function clearFilterInfo() {
        vm.filterData.Acknowledged = "2";
        vm.filterData.RecordType = "All";
        vm.filterData.BillType = "All";
        vm.filterData.MemberNumber = null;
        vm.filterData.LastName = null;
        vm.filterData.ClaimNumber = null;
        vm.filterData.StartDate = null;
        vm.filterData.EndDate = null;

      }

      //resets the filter data
      function resetFilterInfo() {
        vm.filterData.Acknowledged = "0";
        vm.filterData.RecordType = "All";
        vm.filterData.BillType = "All";
        vm.filterData.MemberNumber = null;
        vm.filterData.LastName = null;
        vm.filterData.ClaimNumber = null;
        vm.filterData.StartDate = vm.startdate;
        vm.filterData.EndDate = vm.enddate;
        //console.log(vm.filterData.EndDate);

      }

      function navigate(state, params) {

        $timeout(function () {
          $state.go(state, params);
        }, 250);

      }


      function getImageFile(file, membernumber, recordtype) {

        if (file !== undefined) {
          var str = encodeURIComponent(file);

          var values = str.split(".");

          var value1 = values[0];
          var value2 = values[1];

          if (value2 === undefined)
              value2 = 'pdf';

          if (!recordtype) {
              recordtype = encodeURIComponent(' ');
          }

          //iframe.src = "/api/invalidclaims/getImageFile/" + membernumber + '/' + recordtype + '/' + value1 + '/' + value2;

          ////// This makes the IFRAME invisible to the user.
          //iframe.style.display = "none";

          ////// Add the IFRAME to the page.  This will trigger
          //////   a request to GenerateFile now.
          //document.body.appendChild(iframe);
          var exportOptions = {
            method: 'GET',
            url: "/api/invalidclaims/getImageFile/" + membernumber + '/' + recordtype + '/' + value1 + '/' + value2
            };
          utilService.download(exportOptions);
        }
      }


    }
})();