/**
* @name $toastr
* @desc Toastrjs Wrapper Service.
**/

(function () {
    'use strict';

    angular
        .module('CareGuard')
        .factory('$toastr', toastrService);

    function toastrService() {
        toastr.options = {
            debug: false,
            newestOnTop: true,
            progressBar: true,
            positionClass: 'toast-top-right',
            onclick: null,
            showDuration: 300,
            hideDuration: 300,
            timeOut: 5000,
            extendedTimeOut: 3000,
            showEasing: 'swing',
            hideEasing: 'linear',
            showMethod: 'fadeIn',
            hideMethod: 'fadeOut',
            closeButton: true,
            closeHtml: '<button><i class="mdi mdi-close"></i></button>'
        };

        return {
            show: show
        };

        /**
        * @name show.
        * @desc show the toastr msg.
        * @param {string} message - message to display.
        * @param {string} alertClass - success, warning, error or info.
        * @param {boolean} isSticky - should the message stick or auto close.
        */
        function show(message, alertClass, options) {
            if (!options) options = {};

            if (options.sticky) {
                options.timeOut = 0;
            }

            if (alertClass === 'success')
                toastr.success(message, "", options);
            else if (alertClass === 'error')
                toastr.error(message, "", options);
            else if (alertClass === 'warning')
                toastr.warning(message, "", options);
            else
                toastr.info(message, "", options);
        }
    }
})();
