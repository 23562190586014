angular
    .module('CareGuard')
    .service('lineItemsService', lineItemsService);

lineItemsService.$inject = [
    'gridUtils',
    'claimService'
];

function lineItemsService(gridUtils, claimService) {
    function formatDates(lineItems) {
        return lineItems?.map(detail => {
            detail.serviceDate = moment(detail.serviceDate).format().substring(0, 10);
            detail.serviceDateEnd = moment(detail.serviceDateEnd).format().substring(0, 10);
            return detail;
        });
    }

    function mapToCareHubCodeModelName(details, claimId) {
        return details.map(detail => {
            detail.claimID = claimId;
            detail.procedureCodeID = detail.procedureServiceCodeID;
            detail.revenueCodeID = detail.revenueServiceCodeID;
            detail.ndcCodeID = detail.ndcServiceCodeID;
            detail.modifierID = detail.modifier1ID;
            if (detail.drugQuantityMeasure === '') {
                detail.drugQuantityMeasure = null;
            }
            return detail;
        });
    }

    function mapToCareHubModelAndFormatDates(lineItems, claimId) {
        var detailsMappedToChModel = mapToCareHubCodeModelName(lineItems, claimId);
        return formatDates(detailsMappedToChModel);
    }

    function haveClaimReductionsChanged(gridOptionsLineItemsData, dataReferencesLineItems) {
        const updatedReductionsDetailMap = gridOptionsLineItemsData.map(({ lineNumber, billedAmount, billReviewAmount, networkAmount, outOfNetworkAmount }) => ({ lineNumber, billedAmount, billReviewAmount, networkAmount, outOfNetworkAmount }));
        const originalReductionsDetailMap = dataReferencesLineItems.map(({ lineNumber, billedAmount, billReviewAmount, networkAmount, outOfNetworkAmount }) => ({ lineNumber, billedAmount, billReviewAmount, networkAmount, outOfNetworkAmount }));

        return updatedReductionsDetailMap.some((element, index) => !angular.equals(element, originalReductionsDetailMap[index]));
    }

    function mapDiagnosisCodePointersToPlain(pointers, lineItems, diagnoses, diagnosisSequenceSelector) {
        if (!diagnosisSequenceSelector || typeof diagnosisSequenceSelector !== 'function')
            throw new Error('Invalid sequence selector supplied.');

        if (!lineItems?.length) return [];

        const pointersLocal = pointers || [];
        const diagnosesLocal = diagnoses || [];

        const diagnosesMap = new Map();
        for (let d of diagnosesLocal) {
            const sequenceAsLetter = castDiagnosisSequenceToLetter(d, diagnosisSequenceSelector);
            if (sequenceAsLetter != null) {
                diagnosesMap.set(d.id, sequenceAsLetter);
            }
        }

        const pointersPlainArr = [];
        for (let lineItem of lineItems) {
            const currentPointers = pointersLocal
                .filter(x => x.claimDetailsId === lineItem.id)
                .sort((a, b) => a.pointerSequence - b.pointerSequence);

            let pointersPlain = '';
            for (let p of currentPointers) {
                const diagnosisSequence = diagnosesMap.get(p.diagnosisCodeId);
                if (!diagnosisSequence) throw new Error(`Cannot find diagnosis sequence by id = ${p.diagnosisCodeId}`);

                pointersPlain += diagnosisSequence;
            }

            pointersPlainArr.push({
                lineItemId: lineItem.id,
                diagnosisCodePointersPlain: pointersPlain
            });
        }

        return pointersPlainArr;
    }

    function mapBackDiagnosisCodePointersFromPlain(claimId, lineItems, diagnoses, diagnosisSequenceSelector) {
        if (!diagnosisSequenceSelector || typeof diagnosisSequenceSelector !== 'function')
            throw new Error('Invalid sequence selector supplied.');

        if (!claimId) throw new Error(`Invalid claim id = ${claimId}`);
        if (!lineItems?.length) return [];

        const diagnosesMap = new Map();

        for (let d of diagnoses) {
            const sequenceAsLetter = castDiagnosisSequenceToLetter(d, diagnosisSequenceSelector);
            if (sequenceAsLetter != null) {
                diagnosesMap.set(sequenceAsLetter, d.id);
            }
        }

        const diagnosisPointers = [];
        for (let lineItem of lineItems) {
            if (!lineItem.diagnosisCodePointersPlain) continue;

            let pointerSequence = 1;
            for (let p of lineItem.diagnosisCodePointersPlain) {
                const diagnosisId = diagnosesMap.get(p);

                if (!diagnosisId) throw new Error('Diagnosis code id is not found with sequence = ' + p);

                diagnosisPointers.push({
                    claimDetailsId: lineItem.id,
                    claimId: claimId,
                    diagnosisCodeId: diagnosisId,
                    pointerSequence: pointerSequence++
                });
            }
        }

        return diagnosisPointers;
    }

    function castDiagnosisSequenceToLetter(diagnosis, diagnosisSequenceSelector) {
        return gridUtils.castDiagnosisSequenceToLetterBySelector(diagnosis, diagnosisSequenceSelector);
    }

    function saveClaimDetailDiagnosisPointers(claimId, lineItems, diagnoses, diagnosisSequenceSelector) {
        if (!diagnosisSequenceSelector || typeof diagnosisSequenceSelector !== 'function')
            throw new Error('Invalid sequence selector supplied.');

        var pointers = mapBackDiagnosisCodePointersFromPlain(
            claimId,
            lineItems,
            diagnoses,
            diagnosisSequenceSelector
        );

        return claimService.saveClaimDetailDiagnosisPointers(claimId, pointers);
    }

    function mapClaimDetailPlacesOfService(lineItems) {
        return lineItems.map(lineItem => ({
            claimDetailsId: lineItem.id,
            placeOfServiceCode: lineItem.placeOfServiceCode
        }));
    }

    return {
        formatDates,
        mapToCareHubCodeModelName,
        mapToCareHubModelAndFormatDates,
        haveClaimReductionsChanged,
        mapDiagnosisCodePointersToPlain,
        mapBackDiagnosisCodePointersFromPlain,
        saveClaimDetailDiagnosisPointers,
        mapClaimDetailPlacesOfService
    };
}