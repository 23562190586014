angular
    .module('CareGuard')
    .directive('threeOptionPopup', threeOptionPopup);

threeOptionPopup.$inject = ['LxDialogService', '$q'];

function threeOptionPopup(LxDialogService, $q) {
    const threeOptionPopupDialogIdPrefix = "threeOptionPopupDialogId";

    return {
        restrict: 'E',
        scope: {
            title: '=',
            text: '=',
            completeButtonText: '=',
            denyButtonText: '=',
            cancelButtonText: '=',
            popupCompleteAction: '&completeAction',
            popupDenyAction: '&denyAction',
            setOpenFunction: '&'
        },
        transclude: true,
        link: {
            pre: (scope) => {

                const popupDialogId = threeOptionPopupDialogIdPrefix + scope.$id;

                scope.popupDialogId = popupDialogId;

                scope.completeAction = completeAction;

                scope.denyAction = denyAction;

                scope.setOpenFunction({ openFunction: openPopup });

                function openPopup() {
                    LxDialogService.open(popupDialogId);
                }

                function completeAction() {
                    $q.when(scope.popupCompleteAction()).then(() => {
                        LxDialogService.close(popupDialogId);
                    });
                }

                function denyAction() {
                    $q.when(scope.popupDenyAction()).then(() => {
                        LxDialogService.close(popupDialogId);
                    });
                }
            }
        },
        templateUrl: 'app/careguard/directives/threeOptionPopup/threeOptionPopup.html'
    };
}