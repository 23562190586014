angular
    .module('CareGuard')
    .directive('extendedEmailValidation', extendedEmailValidation);

extendedEmailValidation.$inject = [];

function extendedEmailValidation() {
    const EMAIL_REGEXP = /^(?=[a-z0-9@.!#$%&'*+/=?^_`{|}~-]{6,254})(?=[a-z0-9.!#$%&'*+/=?^_`{|}~-]{1,64}@)[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:(?=[a-z0-9-]{1,255}\.)[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+(?=[a-z0-9-]{2,64})[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i;

    return {
        restrict: 'A',
        require: 'ngModel',
        link: function($scope, $elm, $attrs, ngModelController) {
            if (ngModelController && ngModelController.$validators.email) {
                ngModelController.$validators.email = function(modelValue) {
                    return ngModelController.$isEmpty(modelValue) || EMAIL_REGEXP.test(modelValue);
                };
            }
        }
    }
}