import createClaimTemplate from './createClaim.html';

angular
    .module('CareGuard')
    .directive('createClaim', createClaim)
    .controller('newClaimController', newClaimController);

createClaim.$inject = ['$timeout'];

function createClaim($timeout) {
    return {
        restrict: 'E',
        scope: {
            claim: '=',
            member: '=',
            parentState: '=?',
            lookupdata: '=?',
            claimStatuses: '=?',
            claimDetailStatuses: '=?',
            closePopupFn: '&?'
        },
        template: createClaimTemplate,
        controller: 'newClaimController',
        controllerAs: 'vm',
        bindToController: true,
        link: (scope) => {
            const body = $('body');
            $timeout(() => {
                body.css('overflow-y', 'hidden');
            }, 0);

            scope.$on('lx-dialog__close-start', () => {
                body.css('overflow-y', 'auto');
            });
        }
    };
}

newClaimController.$inject = [
    '$q',
    '$scope',
    '$state',
    '$timeout',
    '$toastr',
    'claimService',
    'documentService',
    'gridUtils',
    'claimStatusId',
    'dispositionStatusId',
    'addressType',
    'payeeService',
    'accountService',
    'lookupService',
    'addressService',
    'codeService',
    'utilService',
    'LxDialogService',
    'billTypeId',
    'documentTypeId',
    'billEntryTypeId',
    'billReviewService',
    'lineItemsService'
];

function newClaimController(
    $q,
    $scope,
    $state,
    $timeout,
    $toastr,
    claimService,
    documentService,
    gridUtils,
    claimStatusId,
    dispositionStatusId,
    addressType,
    payeeService,
    accountService,
    lookupService,
    addressService,
    codeService,
    utilService,
    LxDialogService,
    billTypeId,
    documentTypeId,
    billEntryTypeId,
    billReviewService,
    lineItemsService) {

    let vm = this;

    $scope.addfiles = addfiles;
    vm.initBillImageFileDrop = initBillImageFileDrop;
    vm.initEorImageFileDrop = initEorImageFileDrop;

    vm.onBillImageFileChange = onBillImageFileChange;
    vm.onEORImageFileChange = onEORImageFileChange;
    vm.selectPayee = selectPayee;
    vm.onPayMemberChange = onPayMemberChange;
    vm.isClaimInPaidStatus = isClaimInPaidStatus;
    vm.createClaim = createClaim;
    vm.updateClaimStatus = updateClaimStatus;

    vm.currentDate = moment().format().substring(0, 10);
    vm.newClaim = {};
    angular.copy(vm.claim, vm.newClaim);
    vm.newClaim.billImage = { memberID: vm.member.MemberID };
    vm.newClaim.EORImage = { memberID: vm.member.MemberID };
    vm.newClaim.diagnosisList = vm.newClaim.diagnosisList || [];
    vm.memberToValidate = {};

    vm.newClaim.ClaimID = null;
    vm.newClaim.gridOptionsLineItems = vm.newClaim.gridOptionsLineItems || {};
    vm.newClaim.lineItems = vm.newClaim.lineItems || [];
    vm.newClaim.gridOptionsLineItems.data = vm.newClaim.lineItems;

    if (vm.newClaim.lineItems.length) {
        vm.newClaim.lineItems.forEach(lineItem => lineItem.ClaimID = null);
    }

    vm.newClaim.gridOptionsDiagnosis = vm.newClaim.gridOptionsDiagnosis || {};
    vm.newClaim.gridOptionsDiagnosis.data = vm.newClaim.diagnosisList || [];

    vm.receiptAuditBillTypeIds = null;
    vm.enteredClaimStatus = {};
    vm.searchPayeeQuery = buildPayeeSearchRoute();

    vm.newClaim.MemberID = vm.member.MemberID;

    vm.newClaim.receivedDate = vm.currentDate;
    vm.newClaim.DispositionTypeId = dispositionStatusId.Standard;

    vm.availableClaimStatusesForNewClaim = {};
    vm.isClaimBeingCreated = false;

    vm.EORDisabled = false;
    vm.getTooltipForEORButton = getTooltipForEORButton;
    const EORImageDialogId = 'EORImageDialog';
    vm.openEORAttachmentProblemPopup = openEORAttachmentProblemPopup;
    vm.navigateToClaimDetail = navigateToClaimDetail;
    vm.documentTypes= [];
    vm.hasErrorInUploadEORDocument = false;
    vm.onBillTypeCodeChange = onBillTypeCodeChange;
    vm.onEntryTypeChange = onEntryTypeChange;
    vm.hasRole = hasRole;
    vm.expediteRemittanceTooltip = "In order to expedite remittance, entry type must be receipt";  //this message will be reset based on role and entry type
    vm.isExpediteRemittanceDisabled = true;

    const validBillTypesForEOR = [ billTypeId.AccountClosure, billTypeId.Correspondence, billTypeId.AnnuityRefund, billTypeId.Refund ];
    const billTypesForRequiredBillTypeCode = [billTypeId.UB04INPT, billTypeId.UB04OUTPT];
    const receiptBillTypeID = billEntryTypeId.Receipt;

    const newClaimReceiptAudit = 'Receipt Audit';
    const receiptAuditBillTypes = [
        'Dental',
        'DME',
        'Unknown',
        'HHC',
        'Receipt-Other',
        'Receipt-RX',
        'Receipt-DME',
        'Receipt-HHC',
        'Receipt-Provider',
        'Member Withdrawal',
        'Account Closure',
        'Receipt-Transportation',
        'Annuity Refund',
        'Refund',
    ];
    const allowedDocumentTypes = [
        'Annuity Refund',
        'Receipt-RX',
        'Receipt-DME',
        'Receipt-Dental',
        'Receipt-HHC',
        'Receipt-Provider',
        'Receipt-Transportation',
        'Receipt-Other',
        'Refund',
        'Dental',
        'RX',
        'DME',
        'HHC',
        'CMS-1500',
        'UB04-INPT',
        'UB04-OUTPT',
        'Account Closure',
        'Correspondence',
        'Member Withdrawal',
        'Unknown',
    ];

    (() => {
        if (!vm.lookupdata.billTypes && vm.lookupdata.claimtype) {
            vm.lookupdata.billTypes = vm.lookupdata.claimtype;
        }

        vm.receiptAuditBillTypeIds = vm.lookupdata.billTypes
            .filter(item => receiptAuditBillTypes.includes(item.DataSetValue))
            .map(bt => bt.DataSetID);

        let promises = [
            getBillEntryTypeData(),
            getDocumentTypeData(),
            getMemberPayeeData(vm.member.MemberID),
        ];

        if (!vm.claimStatuses?.length)
            promises.push(getClaimStatuses());

        if (!vm.claimDetailStatuses?.length)
            promises.push(getClaimDetailStatuses());

        //set the expediteRemittance tooltip
        vm.expediteRemittanceTooltip = getExpediteRemittanceTooltip();

        $q.all(promises).then(() => {
            vm.availableClaimStatusesForNewClaim = vm.claimStatuses?.filter(status => !claimService.isStatusAPaidStatus(status.id));
            vm.enteredClaimStatus = vm.claimStatuses?.find(status => status.id === claimStatusId.Entered);
            vm.newClaim.ClaimStatusID = vm.enteredClaimStatus?.id;
        });
    })();

    function getBillEntryTypeData() {
        lookupService.getLookUp('billEntryType').then(({ Data: types }) => {
            vm.lookupdata.billEntryTypes = types;
        });
    }

    function getDocumentTypeData() {
        lookupService.getLookUp('documentType').then(({ Data: types }) => {
            vm.documentTypes = types;
            vm.lookupdata.documentTypes = types?.filter(doc => allowedDocumentTypes.includes(doc.DataSetValue));
        }).then(setEORDocumentType);
    }

    function getMemberPayeeData(memberId) {
        payeeService.getPayeeByMemberId(memberId).then(payee => {
            if (!payee) return;

            vm.member.payee = payee;
        });
    }

    function getClaimStatuses() {
        claimService.getClaimStatuses().then(({ data: statuses }) => {
            vm.claimStatuses = statuses;
        });
    }

    function getClaimDetailStatuses() {
        claimService.getClaimDetailStatuses().then(({ data: statuses }) => {
            vm.claimDetailStatuses = statuses;
        });
    }

    function setEORDocumentType() {
        vm.newClaim.EORImage.documentTypeID = vm.documentTypes?.find(doc => doc.DataSetID === documentTypeId.EOR).DataSetID;
    }

    function buildPayeeSearchRoute() {
        const params = { pageNumber: 1, pageSize: 10, };
        const qs = Object.keys(params).map(key => key + '=' + params[key]).join('&');
        const route = payeeService.searchPayeesRoute();

        return `${route}?${qs}&searchBy=`;
    }

    function onBillImageFileChange() {
        vm.newClaim.billImage.physicalPath = vm.newClaim.billImage.file.name;
    }

    function selectPayee(payee) {
        if (!payee || !Object.keys(payee).length) {
            vm.searchPayeeBy = null;
            return;
        }

        addressService.getAddressesByPayeeId(payee.originalObject.id).then(({ data: addresses }) => {
            const newestAddress = getNewestAddress(addresses);
            vm.newClaim.payee = { ...payee.originalObject, address: newestAddress };
            vm.searchPayeeBy = payee.originalObject.name;
        });
    }

    function getNewestAddress(addresses) {
        return addresses.reduce((newestAddress, currentAddress) => newestAddress?.id > currentAddress.id ? newestAddress : currentAddress, {});
    }

    function onPayMemberChange() {
        if (!vm.newClaim.payMember) {
            resetPayee();
            return;
        } else
            vm.newClaim.payee = vm.member.payee;
    }

    function resetPayee() {
        vm.newClaim.payee = {};
        vm.searchPayeeBy = null;
    }

    function isClaimInPaidStatus() {
        if (!vm.newClaim || !vm.newClaim.ClaimStatusID) return;

        return claimService.isStatusAPaidStatus(vm.newClaim.ClaimStatusID);
    }

    function navigate(state, params) {
        $timeout(() => $state.go(state, params), 0);
    }

    function updateClaimStatus() {
        var billTypeId = +vm.newClaim.BillTypeID;

        if (claimService.isClaim837BillType(billTypeId)) {
            vm.newClaim.ClaimStatusID = claimStatusId.ManualEntry;
        }
        else if (vm.receiptAuditBillTypeIds.includes(billTypeId)) {
            const receiptAuditStatus = vm.claimStatuses?.find(st => st.status.toLowerCase() === newClaimReceiptAudit.toLowerCase());
            vm.newClaim.ClaimStatusID = receiptAuditStatus?.id;
        } else {
            vm.newClaim.ClaimStatusID = vm.enteredClaimStatus.id;
        }

        // If bill type is different than below condition then disabled EOR Image section.
        if (!validBillTypesForEOR.includes(parseInt(billTypeId))) {
            vm.EORDisabled = true;
            vm.isAddingNewEorImageFile = false;
            vm.newClaim.EORImage.physicalPath = null;
            vm.newClaim.EORImage.file = null;
        } else {
            vm.EORDisabled = false;
        }   

        // Update validations that depend on Bill Type
        vm.isBillTypeCodeRequired = billTypesForRequiredBillTypeCode.includes(billTypeId);    
        setDrgRequired();
    }

    function createClaim(form) {
        if (!isValidData(form)) return;

        vm.isClaimBeingCreated = true;

        const validationPromises = [
            isValidFileName(),
            isValidEORFileName()
        ];

        $q.all(validationPromises).then(validationResults => {
            if (!validationResults) return;
            if (validationResults.some(result => !result)) return;

            return addFullClaim();
        }).finally(() => vm.isClaimBeingCreated = false);
    }

    function isValidData(form) {
        if (form?.$invalid) {
            $toastr.show('Please correct invalid data before saving!', 'warning');
            return false;
        }

        // validation for expedite remittance
        if (vm.newClaim.isExpediteRemittance && Number(vm.newClaim.BillEntryTypeID) !== Number(receiptBillTypeID)) {
            $toastr.show('In order to expedite remittance, entry type must be receipt.', 'warning');
            return false;
        }


        if (vm.newClaim.ClaimStatusID === claimStatusId.ReadyForBillReview && vm.newClaim.payee.exBillReview) {
            $toastr.show('Provider is excluded from Bill Review. Sending this claim for Bill Review is prohibited.', 'warning');
            return false;
        }

        const validationDiagnosisResults = gridUtils.validateDiagnosisGrid(vm.newClaim.gridOptionsDiagnosis);
        vm.newClaim.gridOptionsDiagnosis.invalidMessage = validationDiagnosisResults?.resultInvalidMessage || null;

        if (validationDiagnosisResults?.resultInvalidMessage) {
            $toastr.show(validationDiagnosisResults.resultInvalidMessage, 'warning');
            return false;
        }

        if (!hasLineItems()) {
            $toastr.show('Please enter at least one line item.', 'warning');
            return false;
        }

        if (!validateLineItemAmounts()) {
            return false;
        }

        if (vm.newClaim.ClaimNumber.length < 8 || vm.newClaim.ClaimNumber.length > 30) {
            $toastr.show(`Claim number must contain between 8 and 30 characters.`, `warning`);
            return false;
        }

        const validationLineItemsResults = gridUtils.validateLineItemsGrid(
            vm.newClaim.gridOptionsLineItems,
            vm.newClaim,
            diagnosis => diagnosis.rowId
        );

        vm.newClaim.gridOptionsLineItems.invalidFields = validationLineItemsResults?.invalidFields;
        vm.newClaim.gridOptionsLineItems.customValidationErrors = validationLineItemsResults?.customValidationErrors;

        if (!angular.equals({}, vm.newClaim.gridOptionsLineItems.invalidFields)) {
            $toastr.show('Please fill the required fields before saving!', 'warning');
            return false;
        }

        if (validationLineItemsResults.customValidationErrors.length) {
            $toastr.show('Please correct invalid data before saving!', 'warning');
            return false;
        }
        if (vm.memberToValidate.FirstName.trim() !== vm.member.FirstName.trim() || vm.memberToValidate.LastName.trim() !== vm.member.LastName.trim()) {
            $toastr.show('The member First Name and Last Name should match the name on the member\'s profile page!', 'error');
            return false;
        }

        if (vm.newClaim?.EORImage?.physicalPath) {
            if (!validBillTypesForEOR.includes(parseInt(vm.newClaim.BillTypeID))) {
                $toastr.show(`Claim must be one of the following bill types in order to attach EOR through creation screen: Account Closure, Correspondence, Annuity Refund, Refund.`, `error`);
                return false;
            }

            const fileName = vm.newClaim.EORImage.physicalPath;
            if (!fileName.match(/(.pdf)$/)) {
                $toastr.show('EOR attachment must be in pdf format. Please update file type.', 'error');
                return false;
            }
        }
        return true;
    }

    function isValidFileName() {
        if (!hasDocumentAttached()) return $q.when(true);

        return doesDocumentExist().then(({ data: exists }) => {
            if (exists) {
                $toastr.show('The document already exists for this member.', 'warning');
                return false;
            }

            return true;
        }).catch(() => false);
    }

    function doesDocumentExist() {
        return documentService.doesMemberAzureBlobDocumentExist({ memberNumber: vm.member.MemberNumber, filename: vm.newClaim.billImage.physicalPath });
    }

    function validateLineItemAmounts() {
        const invalidLineItems = vm.newClaim.lineItems?.filter(detail => detail.billReviewAmount + detail.networkAmount + detail.outOfNetworkAmount + detail.feeAmount > detail.billedAmount);

        if (invalidLineItems.length) {
            $toastr.show(`Savings and fee total cannot exceed billed amount for a line item.`, `warning`);
            return false;
        }

        return true;
    }

    function flattenPayeeIdsToClaim() {
        vm.newClaim.PayeeID = vm.newClaim.payee.id;
        vm.newClaim.PayeeAddressID = vm.newClaim.payee.address.payeeAddressID;
    }

    function convertModelStringsToNumbers() {
        vm.newClaim.ClaimStatusID = +vm.newClaim.ClaimStatusID;
        vm.newClaim.lineItems?.map(detail => {
            detail.statusID = +detail.statusID;
        });
    }

    function addFullClaim() {
        reconcileModels();

        return addClaim()
            .then(({ data: claim }) => assignCreatedClaim(claim))
            .then(() => hasDiagnosisCodes()
                ? addDiagnosisCodes()
                : $q.when())
            .then(response => {
                const promises = [];

                promises.push(saveClaimDetailDiagnosisPointers(
                    vm.newClaim.id,
                    vm.newClaim?.lineItems,
                    response?.data || []
                ));

                const placesOfService = lineItemsService.mapClaimDetailPlacesOfService(vm.newClaim?.lineItems);
                promises.push(claimService.saveClaimDetailPlacesOfService(vm.newClaim.id, placesOfService));

                return $q.all(promises);
            })
            .then(() => hasDocumentAttached()
                ? uploadDocument().then(addDocumentData)
                : $q.when())
            .then(() => hasEORDocumentAttached()
                ? uploadEORDocument()
                : $q.when())
            // call below method at last for acknowledge for EOR attachment if there error in attach.
            .then(() => acknowledgeEORDocumentAttached()
                 ? openEORAttachmentProblemPopup() 
                 : navigateToClaimDetail()
            )    
            .catch(err => {
                $toastr.show(utilService.parseErrorMessage(err), `error`);

                if (!vm.newClaim.id) return;
                
                deleteClaimHeader()
                    .then(() => hasDocumentAttached()
                        ? deleteDocument()
                        : $q.when())
                    .then(() => hasEORDocumentAttached()
                        ? deleteEORDocument()
                        : $q.when())
            });
    }

    function reconcileModels() {
        flattenPayeeIdsToClaim();
        convertModelStringsToNumbers();
        formatDates();
    }

    function formatDates() {
        vm.newClaim.receivedDate = moment(vm.newClaim.receivedDate).format().substring(0, 10);
        vm.newClaim.lineItems?.map(detail => {
            detail.serviceDate = moment(detail.serviceDate).format().substring(0, 10);
            detail.serviceDateEnd = moment(detail.serviceDateEnd).format().substring(0, 10);
        });
        vm.newClaim.serviceFromDate = getMinServiceDate(vm.newClaim.lineItems);
        vm.newClaim.serviceToDate = getMaxServiceDateEnd(vm.newClaim.lineItems);
    }

    function getMinServiceDate(lineItems) {
        const minDate = _.min(lineItems.map(detail => new Date(detail.serviceDate)));
        return minDate.toJSON();
    }

    function getMaxServiceDateEnd(lineItems) {
        const maxDate = _.max(lineItems.map(detail => new Date(detail.serviceDateEnd)));
        return maxDate.toJSON();
    }

    function assignCreatedClaim(claim) {
        if (!claim) throw new Error("Claim can't be null");
        if (!claim.header) throw new Error("Claim header can't be null");
        if (!claim.details) throw new Error("Claim details can't be null");

        assignCreatedLineItemIds(claim.details);
        return assignCreatedClaimId(claim.header.id);
    }

    function assignCreatedLineItemIds(lineItems) {
        for (let lineItem of lineItems) {
            const existing = vm.newClaim.lineItems.find(x => x.lineNumber === lineItem.lineNumber);

            if (!existing) throw new Error(`Line item with line number = ${lineItem.lineNumber} is not found`);

            existing.id = lineItem.id;
        }
    }

    function assignCreatedClaimId(id) {
        if (!id) return $q.reject();

        vm.newClaim.id = id;
        vm.newClaim.billImage.claimID = id;
        vm.newClaim.EORImage.claimID = id;
        return $q.when();
    }

    function hasDiagnosisCodes() {
        return vm.newClaim?.diagnosisList?.length;
    }

    function hasDocumentAttached() {
        return vm.newClaim?.billImage?.file;
    }

    function hasLineItems() {
        return vm.newClaim?.lineItems?.length;
    }

    function addClaim() {
        return claimService.addClaim({ header: vm.newClaim, details: vm.newClaim?.lineItems });
    }

    function deleteClaimHeader() {
        return claimService.deleteClaimHeaderById(vm.newClaim.id);
    }

    function addDiagnosisCodes() {
        const codes = vm.newClaim?.diagnosisList.map(code => ({ ...code, sequence: code.rowId }));

        return codeService.addDiagnosisCodesToClaim({ claimID: vm.newClaim.id, codes });
    }

    function uploadDocument() {
        const request = {
            memberNumber: vm.member.MemberNumber,
            file: vm.newClaim.billImage.file,
            filename: vm.newClaim.billImage.physicalPath,
        };

        return documentService.uploadMemberAzureBlobDocument(request);
    }

    function addDocumentData() {
        return documentService.addDocument(vm.newClaim.billImage);
    }

    function deleteDocument() {
        return documentService.deleteMemberAzureBlobDocument({ memberNumber: vm.member.MemberNumber, filename: vm.newClaim.billImage.physicalPath });
    }

    function deleteEORDocument() {
        return documentService.deleteMemberAzureBlobDocument({ memberNumber: vm.member.MemberNumber, filename: vm.newClaim.EORImage.physicalPath });
    }

    function onEORImageFileChange() {
        vm.newClaim.EORImage.physicalPath = vm.newClaim.EORImage.file.name;
    }

    function getTooltipForEORButton() {
        if (vm.EORDisabled) {
            return 'EOR image attachment not available for currently selected bill type.';
        }
    }

    function hasEORDocumentAttached() {
        return vm.newClaim?.EORImage?.file;
    }

    function acknowledgeEORDocumentAttached() {
        return vm.hasErrorInUploadEORDocument;
    }

    function doesEORDocumentExist() {
        return documentService.doesMemberAzureBlobDocumentExist({ memberNumber: vm.member.MemberNumber, filename: vm.newClaim.EORImage.physicalPath });
    }

    function isValidEORFileName() {
        if (!hasEORDocumentAttached()) return $q.when(true);

        return doesEORDocumentExist().then(({ data: exists }) => {
            if (exists) {
                $toastr.show('The document already exists for this member.', 'warning');
                return false;
            }
            return true;
        }).catch(() => false);
    }

    function onBillTypeCodeChange() {
        // Update validations that depend on Bill Type Code
        setDrgRequired();
    }

    function setDrgRequired() {
        vm.isDRGRequired = vm.newClaim
            && (vm.newClaim.BillTypeID == billTypeId.UB04INPT)
            && new RegExp(/^1(1|2)\d$/g).test(vm.newClaim.BillTypeCode);
    }

    function onEntryTypeChange() {
        // enable Expedite Remittance checkbox
        enableExpediteRemittance();
    }

    function enableExpediteRemittance() {
        // set the tooltip text
        vm.expediteRemittanceTooltip = getExpediteRemittanceTooltip();

        if (Number(vm.newClaim.BillEntryTypeID) !== Number(receiptBillTypeID)) {
            // reset isExpediteRemittance flag if this is not receipt
            if (vm.newClaim.isExpediteRemittance) vm.newClaim.isExpediteRemittance = false;
            // disable the checkbox
            vm.isExpediteRemittanceDisabled = true;
            return;
        }

        // make sure this is disabled if not in correct role
        if (!vm.hasRole('ExpediteRemittance')) return;

        // enable expediteRemittance checkbox
        vm.isExpediteRemittanceDisabled = false;
    }

    function getExpediteRemittanceTooltip() {
        // if you don't have the role, only display this message
        if (!vm.hasRole('ExpediteRemittance')) {
            return 'You do not have permission to access Expedite Remittance.';
        } 
        // for those who have the role, the tooltip will be based on the entry type selection
        var tooltip = ' ';  // keep a space, otherwise the message does not get cleared if this is a blank (empty string).
        if (Number(vm.newClaim.BillEntryTypeID) !== Number(receiptBillTypeID)) {
            tooltip = 'In order to expedite remittance, entry type must be receipt.';
        }
        return tooltip;
    }

    function addEORDocumentData() {
        return documentService.addDocument(vm.newClaim.EORImage)
            .then(function (d) {
                return billReviewService.addDocumentEor(vm.newClaim.id, d.data.id);
            });
    }

    function uploadEORDocument() {
        const request = {
            memberNumber: vm.member.MemberNumber,
            documentTypeID: vm.newClaim.EORImage.documentTypeID,
            file: vm.newClaim.EORImage.file,
            filename: vm.newClaim.EORImage.physicalPath,
        };  
        return documentService.uploadMemberAzureBlobDocument(request)
            .then(addEORDocumentData)
            .then(() => 
                vm.hasErrorInUploadEORDocument = false
            )
            .catch(err => {
                deleteEORDocument();
                vm.hasErrorInUploadEORDocument = true;
            });
    }

    function openEORAttachmentProblemPopup() {
        LxDialogService.open(EORImageDialogId);
    }

    function navigateToClaimDetail() {
        vm.closePopupFn();
        navigate('layoutNoNav.claim-details', {
            memberID: vm.member.MemberID,
            claimValue: vm.newClaim.id,
            parentState: vm.parentState,
        });
    }

    function saveClaimDetailDiagnosisPointers(claimId, lineItems, diagnoses) {
        return lineItemsService.saveClaimDetailDiagnosisPointers(
            claimId,
            lineItems,
            diagnoses,
            diagnosis => diagnosis.sequence
        );
    }

    var fileDropElementCallBackMapping = {
        billImageDrop: addBillImageFile,
        eorImageDrop: addEorImageFile
    };

    function addfiles(files, evt) {
        var file = files[0]; //pick just one file
        var elementId = evt.target.id;

        var callBack = fileDropElementCallBackMapping[elementId];

        callBack && callBack(file);       
    };

    function initBillImageFileDrop() {
        vm.newClaim.billImage.file = vm.newClaim.billImage.physicalPath = null;
        vm.isAddingNewBillImageFile = true;
    }

    function addBillImageFile(file) {       
        var reader = new FileReader();
        reader.onload = (function (theFile) {
            return function (e) {
                vm.newClaim.billImage.file = theFile;
                vm.newClaim.billImage.physicalPath = theFile.name;
            };
        })(file);

        reader.readAsDataURL(file);
        vm.isAddingNewBillImageFile = false;
    }

    function initEorImageFileDrop() {
        vm.newClaim.EORImage.file = vm.newClaim.EORImage.physicalPath = null;
        vm.isAddingNewEorImageFile = true;
    }

    function addEorImageFile(file) {
        var reader = new FileReader();
        reader.onload = (function (theFile) {
            return function (e) {
                vm.newClaim.EORImage.file = theFile;
                vm.newClaim.EORImage.physicalPath = theFile.name;
            };
        })(file);

        reader.readAsDataURL(file);
        vm.isAddingNewEorImageFile = false;
    }

    function hasRole(role) {
        return accountService.isInRole(role);
    }

}