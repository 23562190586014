/**
* @name claimServiceclaim
* @desc claim Service.
**/
(function () {
    'use strict';

    angular
        .module('CareGuard')
        .factory('odgLookUpService', odgLookUpService);

    /** Inject services into service. */
    odgLookUpService.$inject = ['$http'];

    /**
    * @name claimService.
    * @desc claim Service.
    */
    function odgLookUpService($http) {
      var baseUrl = '/api/odglookup/';
        
        var service = {
            odgLookUp: odgLookUp
        };

        return service;

        
        function odgLookUp(memberNumber, diagnosis, cptCode) {
          return $http.get(`${baseUrl}/odgLookUp/?memberNumber=${memberNumber}&diagnosis=${diagnosis}&cptCode=${cptCode}`);
        };


    };
})();