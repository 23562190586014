const BREAKPOINT = 1024;

angular
    .module('CareGuard')
    .directive('mainNav', mainNav);

mainNav.$inject = ['$window'];

/**
 * @ngdoc component
 * @name main-nav
 * @description Main navigation of site with flexible and flipable dropdowns
 *
 * @example
 * <nav class="main-nav row column"
 *      data-selector="navigation-container"
 *      uk-main-nav>
 *  <ul>
 *      <li class="main-nav__item" data-selector="flip" ng-repeat="i in [0, 1, 2, 3, 4, 5, 6, 7, 8 ,9]">
 *          <a href="https://www.google.com">
 *              Level 1
 *          </a>
 *
 *          <div class="main-nav__dropdown" data-selector="dropdown">
 *              <ul class="main-nav__menu" data-selector="flex-width">
 *                  <li class="main-nav__menu-item" >
 *                      <a href="">
 *                          Level 2
 *                      </a>
 *                      <ul class="main-nav__sub-menu">
 *                          <li class="main-nav__sub-menu-item" ng-repeat="i in [0, 1, 2, 3, 4]">
 *                              <a href="https://www.google.com">
 *                                  Level 3
 *                              </a>
 *                          </li>
 *                      </ul>
 *                  </li>
 *              </ul>
 *          </div>
 *      </li>
 *  </ul>
 * </nav>
 */


function mainNav($window) {
    return {
        restrict: 'A',
        link: function (scope, element, attrs) {
            const flipElements = element.find('[data-selector="flip"]');
            const flexWidthElements = element.find('[data-selector="flex-width"]');

            initDropdownsFlip(flipElements);
            initDropdownsFlexWidth(flexWidthElements);

            function initDropdownsFlexWidth(elements) {
                elements.each((index, element) => {
                    element = angular.element(element);

                    adaptWidth(element);

                    angular.element($window).bind('resize', () => adaptWidth(element));
                });
            }

            function initDropdownsFlip(elements) {
                elements.each((index, element) => {
                    element = angular.element(element);

                    const dropdown = element.find('[data-selector="dropdown"]');
                    const parent = element.parents('[data-selector="navigation-container"]');

                    const dropdownLink = dropdown.prev();

                    dropdown.on('mouseenter', () => {
                        dropdownLink.addClass('active');
                    });

                    dropdown.on('mouseleave', () => {
                        dropdownLink.removeClass('active');
                    });

                    element.on('mouseenter', () => {
                        const headRightOffset = ($window.outerWidth - (element.offset().left + element.outerWidth()));
                        const parentRightOffset = ($window.outerWidth - (parent.offset().left + parent.outerWidth()));

                        if(dropdown.outerWidth() > headRightOffset - parentRightOffset) {
                            dropdown.css({
                                'right': '0',
                                'left': 'initial'
                            });
                        } else {
                            dropdown.css({
                                'right': 'initial',
                                'left': '0'
                            });
                        }
                    });
                });
            }

            function adaptWidth(element) {
                if (angular.element($window).width() <= BREAKPOINT) {
                    element.width('');

                    return;
                }

                const lastChild = element.children().last();

                element.parent().addClass('js-get-width');

                const newWidth = lastChild.position().left - element.position().left + lastChild.outerWidth(true);

                element.parent().removeClass('js-get-width');

                element.width(newWidth);
            }
        }
    }
}