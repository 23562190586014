angular
    .module('CareGuard')
    .controller('customDocumentsController', customDocuments);

customDocuments.$inject = [
    'utilService',
    '$toastr',
    'annualAccountingService',
    'memberStatementsService'];

function customDocuments(
    utilService,
    $toastr,
    annualAccountingService,
    memberStatementsService)
{
    let vm = this;

    vm.documentTypes = ['Annual Accounting', 'Member Statement'];
    vm.newDocument = {};
    vm.generateDocument = generateDocument;

    function generateDocument(form) {
        if (form?.$invalid) {
            $toastr.show('Please correct invalid data before saving!', 'warning');
            return;
        }

        if (vm.newDocument.type === 'Annual Accounting') {
            annualAccountingService.downloadCustomAnnualAccounting(vm.newDocument.memberId, vm.newDocument.startDate, vm.newDocument.endDate).then(response => {
                utilService.processResponse({ response });
            });
        } else if (vm.newDocument.type === 'Member Statement') {
            memberStatementsService.downloadCustomStatement(vm.newDocument.memberId, vm.newDocument.startDate, vm.newDocument.endDate).then(response => {
                utilService.processResponse({ response });
            });
        }
    }
}