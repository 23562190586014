/**
* @name chronovoTooltip
* @desc tooltip wrapper for tooltipster.
* @usage: <span chronovo-tooltip="content">Tooltip<span/>
**/
(function () {
    'use strict';

    angular
        .module('CareGuard')
        .directive('chronovoTooltip', chronovoTooltip);

    /** Inject services into directive. */
    chronovoTooltip.$inject = ['$window', '$compile', '$parse'];

    /**
    * @name chronovoTooltip.
    * @desc lx-select wrapper, translates values between model and selection.
    * @param {object} $compile - angularjs compile service.
    */
    function chronovoTooltip($window, $compile, $parse) {
        var directive = {
            restrict: 'A',
            link: postLink
        };

        return directive;

        function postLink($scope, $element, $attrs) {
            var isinitilized = false;
            var options = {
                contentAsHTML: true,
                position: 'bottom',
                theme: 'tooltipster-light',
                animation: 'fade',
                delay: 0
            };

            // options
            if ($attrs.position) {
                options.position = $attrs.position;
            };

            // build tooltip
            $element.tooltipster(options);

            // watch for changes and update content
            $scope.$watch($attrs.chronovoTooltip, function (val) {
                var tooltip = val;
                var tipster = $element.data('tooltipster-ns');
                if (tipster) {
                    $element.tooltipster('content', tooltip);
                } else {
                    options.content = tooltip;
                    $element.tooltipster(options);
                }
            });
        };
    };
})();