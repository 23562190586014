angular
    .module('CareGuard')
    .controller('memberStatementsController', memberStatements);

memberStatements.$inject = [
    'memberStatementsService',
    'documentService',
    'fileProcessingStatusNames',
    '$toastr'
];

function memberStatements(
    memberStatementsService,
    documentService,
    fileProcessingStatusNames,
    $toastr) {

    let vm = this;

    vm.isDataLoading = false;
    vm.memberStatements = [];
    vm.fileProcessing = [];
    vm.submitClick = submitClick;
    vm.fileProcessingStatusNames = fileProcessingStatusNames;

    (async () => {
        try {
            vm.isDataLoading = true;

            const response = await memberStatementsService.getMonthlyStatements();
            vm.memberStatements = response.data.map(populateDisplayName);

            if (!vm.memberStatements || !vm.memberStatements.length) {
                $toastr.show(`No monthly statement archives found.`, `warning`);
                return;
            }

            for (const statement of vm.memberStatements) {

                if (statement.fileProcessingId == null) {
                    statement.isSuccessful = false;
                    statement.processedDate = null;
                    statement.status = populateUpdateStatus(statement.uploadStatusId);
                    continue;
                }

                try {
                    const response = await documentService.getFileByFileProcessingId(statement.fileProcessingId);
                    vm.fileProcessing.push(response);
                    statement.isSuccessful = response.data.isSuccessful;
                    statement.processedDate = response.data.processedDate;
                    statement.status = populateUpdateStatus(statement.uploadStatusId);
                } catch (error) {
                    $toastr.show(`Error fetching file processing for statement ${statement.id}: ${error.message}`, `error`);
                }
            }

        } catch (error) {
            $toastr.show(`Something went wrong. Can't load monthly statements.`, `error`);
        } finally {
            vm.isDataLoading = false;
        }
    })();

    function submitClick(statement) {
        (async () => {
            try {
                vm.isDataLoading = true;
                const startResponse = await memberStatementsService.startKirkwoodMemberStatementSubmissionProcess(statement.id);

                if (startResponse.status !== 204) {
                                    $toastr.show(`Member Statement Archives not updated with fileProcessingId.`, `warning`);
                                    return;
                }

                statement.status = fileProcessingStatusNames.Processing;
                                $toastr.show(`Member Statement Archive submitted for processing.`, `success`);
            } catch (error) {
                $toastr.show(`Submit process failed.`, `error`);
            } finally {
                vm.isDataLoading = false;
            }
        })();
    }

    function populateDisplayName(statement) {
        if (!statement) return;
        if (!statement.effectiveMonth) return;

        let date = Date.parse(statement.effectiveMonth);
        if (!date) return;

        statement.displayName = new Date(date).toLocaleString('en-US', { year: "numeric", month: "short" });

        return statement;
    }

    function populateUpdateStatus(uploadStatusId) {
        if (!uploadStatusId) return null;

        var uploadStatus = "";

        switch (uploadStatusId) {
            case 1:
                uploadStatus = fileProcessingStatusNames.ReadyToSubmit;
                break;
            case 2:
                uploadStatus = fileProcessingStatusNames.Processing;
                break;
            case 3:
                uploadStatus = fileProcessingStatusNames.Submitted;
                break;
            default:
                throw new Error('Unexpected update status: ' + updateStatusId);
        }

        return uploadStatus;
    }
}