angular
    .module('CareGuard')
    .factory('nonRedeemedClaimsService', nonRedeemedClaimsService);

nonRedeemedClaimsService.$inject = [
    '$http', 'nonRedeemedClaimStatus'
];

function nonRedeemedClaimsService(
    $http, nonRedeemedClaimStatus) {

    // Api calls
    const baseRoute = `banks/nonredeemedpayment`;

    function getAwaitsReissueClaims() {
        return $http.post(`${apiUrl}/${baseRoute}/status/claims`, nonRedeemedClaimStatus.AwaitsReissue);
    }

    return {
        // Api calls
        getAwaitsReissueClaims        
    };
}