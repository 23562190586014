angular
    .module('CareGuard')
    .directive('onNgRepeatFinishRender', onNgRepeatFinishRender);

onNgRepeatFinishRender.$inject = ['$timeout'];

function onNgRepeatFinishRender($timeout) {
    return {
        restrict: 'A',
        link: function (scope, element, attr) {
            const parentElm = element.parent();
            if (parentElm[0]) {
                parentElm[0].finishRendering = false;
            }

            if (scope.$last === true) {
                $timeout(function () {
                    parentElm[0].finishRendering = true;
                }, 0);
            }
        }
    }
}