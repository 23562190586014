(function () {
    'use strict';

  angular
    .module('CareGuard')
    .controller('amethystclaimController', amethystclaimController);

    amethystclaimController.$inject = [
        '$q',
        '$state',
        '$timeout',
        '$toastr',
        'getUrlService',
        'claimService',
        'claimStatusId',
        'payeeService',
        'lookupService',
        'accountService',
        'memberID',
        'memberService',
        'utilService',
        'fileService'];

    function amethystclaimController(
        $q,
        $state,
        $timeout,
        $toastr,
        getUrlService,
        claimService,
        claimStatusId,
        payeeService,
        lookupService,
        accountService,
        memberID,
        memberService,
        utilService,
        fileService) {

        var vm = this;

      vm.claims = undefined;
      vm.lookupdata = undefined;
      vm.member = undefined;
      vm.searchVendorBy = undefined;
      vm.searchVendorPlaceholder = "Search [Vendor]";
      vm.searchVendors = undefined;
      vm.currentState = $state.current.name;

        vm.hasSearchVendorResult = false;
        vm.isSearchVendorInProgress = false;
      
        vm.filterClaims = filterClaims;
        vm.exportClaims = exportClaims;
        vm.changeSort = changeSort;

        vm.getFilterData = getFilterData;
        vm.getUrlFromState = getUrlFromState;
        vm.clearFilterInfo = clearFilterInfo;
        vm.refresh = refresh;
        vm.navigate = navigate;

        vm.toggleCheckAll = toggleCheckAll;
            
        vm.searchVendor = searchVendor;
        vm.clearSearchVendor = clearSearchVendor;
        vm.selectSearchVendor = selectSearchVendor;

        vm.getImageFile = getImageFile;
        vm.changeStatus = changeStatus;
        vm.zeroPay = zeroPay;
        vm.hasRole = hasRole;

      vm.pageSizes = {
        data: [
            { Name: 5, Value: 5 },
            { Name: 10, Value: 10 },
            { Name: 20, Value: 20 },
            { Name: 50, Value: 50 },
            { Name: 100, Value: 100 },
            { Name: 1000, Value: 1000 }
        ]
      };

                  
      vm.filterData = {
        TotalPages: 1,
        TotalRows: undefined,
        PageSize: 50,
        PageIndex: 1,
        SortColumn: undefined,
        SortOrder: 'ASC',

            MemberID: undefined,
            MemberNumber: undefined,
            MemberName: undefined,
            ClaimID: undefined,
            ClaimStatusID: undefined,
            PayeeID: undefined,
            ClaimNumber: undefined,
            BillTypeID: undefined,
            StartReceivedDate: undefined,
            EndReceivedDate: undefined,
            StartServiceDate: undefined,
            EndServiceDate: undefined,
            CheckNumber: undefined,
            IDList: undefined,
            StartPaidDate: undefined,
            EndPaidDate: undefined
        };

        vm.filterDataCopy = undefined;

        (function () {
            vm.lookupdata =
            {
                billtype: [],
                claimstatus: [],
                zeroPayClaimdetailstatus: [],
            }

            let promises = [
                getBillTypeData(),
                getClaimStatusData(),
                getClaimDetailStatusData(),
            ];

            if (memberID) {
                promises.push(getMemberData());
            }

            $q.all(promises).then(() => {
                filterClaims();
            });
        })();

        function getBillTypeData() {
            lookupService.getLookUp('claimType').then(result => {
                vm.lookupdata.billtype = result?.Data;
            });
        }

        function getClaimStatusData() {
            claimService.getClaimStatuses().then(({ data: statuses }) => {
                vm.lookupdata.claimstatus = statuses;
            });
        }

        function getClaimDetailStatusData() {
            claimService.getClaimDetailStatuses().then(({ data: statuses }) => {
                vm.lookupdata.zeroPayClaimdetailstatus = statuses.filter(status => status.category.isZeroPay);
            });
        }

        function getMemberData() {
            memberService.getById(memberID).then(result => {
                vm.member = result.Data;

                vm.filterData.MemberID = vm.member.MemberID;
                vm.filterData.MemberNumber = vm.member.MemberNumber;
                vm.filterData.MemberName = vm.member.MemberName;
            });
        }

        function filterClaims() {
            getFilterData();

            claimService.filterAmethystClaims(vm.filterDataCopy.MemberID, vm.filterDataCopy.MemberNumber, vm.filterDataCopy.MemberName, vm.filterDataCopy.ClaimID, vm.filterDataCopy.PayeeID, vm.filterDataCopy.ClaimNumber, vm.filterDataCopy.StartReceivedDate, vm.filterDataCopy.EndReceivedDate, vm.filterDataCopy.StartServiceDate, vm.filterDataCopy.EndServiceDate, vm.filterDataCopy.CheckNumber, vm.filterDataCopy.ClaimStatusID, vm.filterDataCopy.BillTypeID, vm.filterDataCopy.StartPaidDate, vm.filterDataCopy.EndPaidDate, vm.filterDataCopy.PageIndex, vm.filterDataCopy.PageSize, vm.filterDataCopy.SortColumn, vm.filterDataCopy.SortOrder).then(function (result) {
                vm.claims = result.Data;            
           
                try {
                vm.filterData.TotalRows = vm.claims[0].TotalRows;
                vm.filterData.TotalPages = Math.ceil(vm.filterData.TotalRows / vm.filterData.PageSize);
                }
                catch (ex) {
                vm.filterData.TotalPages = 1;
                };
          
                vm.checkedAll = undefined;          
            });
        };
               
        function getFilterData() {
            
            vm.filterDataCopy = undefined;

            vm.filterDataCopy = angular.copy(vm.filterData);

            if (vm.filterData.MemberNumber == undefined || vm.filterData.MemberNumber == '')
                vm.filterDataCopy.MemberNumber = null;
            if (vm.filterData.MemberName == undefined || vm.filterData.MemberName == '')
                vm.filterDataCopy.MemberName = null;
            if (vm.filterData.ClaimNumber == undefined || vm.filterData.ClaimNumber == '')
                vm.filterDataCopy.ClaimNumber = null;
            if (vm.filterData.CheckNumber == undefined || vm.filterData.CheckNumber == '')
                vm.filterDataCopy.CheckNumber = null;
            if (vm.filterData.SortColumn == undefined || vm.filterData.SortColumn == '')
                vm.filterDataCopy.SortColumn = null;

            if (!vm.filterData.MemberID)
                vm.filterDataCopy.MemberID = 0;
            if (!vm.filterData.ClaimID)
                vm.filterDataCopy.ClaimID = 0;
            if (!vm.filterData.PayeeID)
                vm.filterDataCopy.PayeeID = 0;
            if (!vm.filterData.StartReceivedDate)
                vm.filterDataCopy.StartReceivedDate = '01-01-0001';
            if (!vm.filterData.EndReceivedDate)
                vm.filterDataCopy.EndReceivedDate = '01-01-0001';
            if (!vm.filterData.StartServiceDate)
                vm.filterDataCopy.StartServiceDate = '01-01-0001';
            if (!vm.filterData.EndServiceDate)
                vm.filterDataCopy.EndServiceDate = '01-01-0001';
            if (!vm.filterData.ClaimStatusID)
                vm.filterDataCopy.ClaimStatusID = 0;
            if (!vm.filterData.BillTypeID)
                vm.filterDataCopy.BillTypeID = 0;
            if (!vm.filterData.StartPaidDate)
                vm.filterDataCopy.StartPaidDate = '01-01-0001';
            if (!vm.filterData.EndPaidDate)
                vm.filterDataCopy.EndPaidDate = '01-01-0001';
        };

        function getUrlFromState(stateName, params) {
            return getUrlService.get(stateName, params);
        }

        function exportClaims() {
            if (vm.filterData != undefined) {
                getFilterData();
          
                var exportOptions = {
                    method: 'GET',
                    url: "/api/claims/exportAmethystClaims/" + vm.filterDataCopy.MemberNumber + '/' + vm.filterDataCopy.MemberName + '/' + vm.filterDataCopy.ClaimID + '/' + vm.filterDataCopy.PayeeID + '/' + vm.filterDataCopy.ClaimNumber + '/' + vm.filterDataCopy.StartReceivedDate + '/' + vm.filterDataCopy.EndReceivedDate + '/' + vm.filterDataCopy.StartServiceDate + '/' + vm.filterDataCopy.EndServiceDate + '/' + vm.filterDataCopy.CheckNumber + '/' + vm.filterDataCopy.ClaimStatusID + '/' + vm.filterDataCopy.BillTypeID + '/' + vm.filterDataCopy.StartPaidDate + '/' + vm.filterDataCopy.EndPaidDate
                };

                utilService.download(exportOptions);
            }
        };
      
        function toggleCheckAll() {
            vm.totalpay = 0;
            vm.totalfee = 0;

            for (var i = 0; i < vm.claims.length; i++)
            {
                if (vm.claims[i].IsReversal) {
                    vm.claims[i].Checked = false;
                }
                else {
                    vm.claims[i].Checked = vm.checkedAll;

            if (vm.claims[i].Checked)
            {
              vm.totalpay = vm.claims[i].PaidAmount + vm.totalpay;
              vm.totalfee = vm.claims[i].FeeAmount + vm.totalfee;
            }
          }
            
        }
                
        };

        function changeSort(column) {
        if (vm.filterData.SortColumn == column) {
          vm.filterData.SortOrder = (vm.filterData.SortOrder == 'ASC' ? 'DESC' : 'ASC')
        }
        else {
          vm.filterData.SortColumn = column;
          vm.filterData.SortOrder = 'ASC';
        }

            filterClaims();
        };
        
        function refresh(page) {
            if(page == null || (page > 0 && page <= vm.filterData.TotalPages))
            {
                vm.filterData.PageIndex = vm.filterData.TotalRows == 0 || page == null ? 1: page;
                filterClaims();
            }
        };

        function navigate(state, params) {
            $timeout(function () {
                $state.go(state, params);
            }, 250);
        };

        function clearFilterInfo() {
            if (memberID == undefined || memberID <= 0)
            {
                vm.filterData.MemberID = undefined;
                vm.filterData.MemberNumber = undefined;
                vm.filterData.MemberName = undefined;
            }

            vm.filterData.ClaimID = undefined;
            vm.filterData.ClaimStatusID = undefined;
            vm.filterData.PayeeID = undefined;
            vm.filterData.ClaimNumber = undefined;
            vm.filterData.BillTypeID = undefined;
            vm.filterData.StartReceivedDate = undefined;
            vm.filterData.EndReceivedDate = undefined;
            vm.filterData.StartServiceDate = undefined;
            vm.filterData.EndServiceDate = undefined;
            vm.filterData.arrIDList = undefined;
            vm.filterData.IDList = undefined;
            vm.filterData.CheckNumber = undefined;
            vm.filterData.StartPaidDate = undefined;
            vm.filterData.EndPaidDate = undefined;

            vm.searchVendorBy = undefined;

            clearSearchVendor();
        };

              
        function searchVendor() {
            vm.searchVendors = undefined;

            if (!vm.searchVendorBy || vm.isSearchVendorInProgress == true) {
                return;
            } else if (vm.searchVendorBy.length >= 3 && (accountService.isInRole('AmethystSpecialist') && (accountService.isInRole('CareGuardFinance') || accountService.isInRole('CareGuard')))) {
                vm.isSearchVendorInProgress = true;

                payeeService.searchPayees({ searchBy: vm.searchVendorBy }).then(({ data: payees }) => {
                    vm.searchVendors = payees?.items;
                    vm.hasSearchVendorResult = true;
                    vm.isSearchVendorInProgress = false;
                });
            }
        };

        function selectSearchVendor(selectedVendor) {
            vm.isSearchVendorInProgress = true;
            vm.filterData.PayeeID = selectedVendor.id;
            vm.searchVendorBy = selectedVendor.name;

            clearSearchVendor();
        };

        function clearSearchVendor() {
            $timeout(() => {
                vm.searchVendors = [];
                vm.hasSearchVendorResult = false;
                vm.isSearchVendorInProgress = false;
            }, 200);
        };           

        function getImageFile(imageFileClaim) {
            if (imageFileClaim.ImageFile != undefined) {
                var str = encodeURIComponent(imageFileClaim.ImageFile);

                const fileName = fileService.getFileNameWithoutExtension(str);
                const extension = fileService.getExtension(str);

                var exportOptions = {
                method: 'GET',
                url: "/api/claims/getImageFile/" + imageFileClaim.MemberNumber + '/' + fileName + '/' + extension
                };
                utilService.download(exportOptions);
            }
        };

        function changeStatus() {
            if (vm.filterData != undefined && vm.filterData.newClaimStatusID != undefined && vm.claims.length > 0) {
                var claimIDList = new Array();
                for (var i = 0; i < vm.claims.length; i++) {
                    if (vm.claims[i].Checked) {
                        claimIDList.push(vm.claims[i].ClaimID);
                    }
                }

                if (claimIDList.length > 0) {
                    vm.filterData.IDList = claimIDList;
                }

                const claimIdsExpirationDate = vm.claims.filter(claim => claim.Checked && vm.filterData.newClaimStatusID === claimStatusId.ReadyForBillReview && claim.ServiceDate > claim.ExpirationDate);
            
                if (claimIdsExpirationDate.length) {
                    $toastr.show("Date of Service is after Date of Expiration for the selected claim(s). Sending this/these claim(s) for Bill Review is prohibited", "error");
                    return;
                }

                if (vm.filterData.IDList != undefined && vm.filterData.IDList.length > 0) {
                    getFilterData();

                    claimService.changeStatusAM(vm.filterDataCopy).then(function (result) {
                        vm.claims = result;

                        vm.filterData.IDList = undefined;
                        vm.filterData.newClaimStatusID = undefined;
                    });
                }
            }
        };

        function zeroPay() {
            if (vm.filterData != undefined && vm.filterData.newClaimDetailStatusID != undefined && vm.claims.length > 0) {
                var claimIDList = new Array();
                for (var i = 0; i < vm.claims.length; i++) {
                    if (vm.claims[i].Checked) {
                        claimIDList.push(vm.claims[i].ClaimID);
                    }
                }

                if (claimIDList.length > 0) {
                    vm.filterData.IDList = claimIDList;
                }

                if (vm.filterData.IDList != undefined && vm.filterData.IDList.length > 0) {
                    getFilterData();

                    claimService.zeroPayAM(vm.filterDataCopy).then(function (result) {
                        vm.claims = result;

                        vm.filterData.IDList = undefined;
                        vm.filterData.newClaimDetailStatusID = undefined;

                        filterClaims();
                    });
                }
            }
        };

      function hasRole(role) {
        return accountService.isInRole(role);
      }
   
    };
})();