/**
* @name chronovoPasswordPolicy
* @desc Check password policy.
* @usage <input chronovo-password-policy ng-model="model" />
**/
(function () {
    'use strict';

    angular
        .module('CareGuard')
        .directive('chronovoPasswordPolicy', chronovoPasswordPolicy);

    /** Inject services into directive. */
    chronovoPasswordPolicy.$inject = ['accountService'];

    /**
    * @name chronovoPasswordPolicy.
    * @desc Check password policy.
    * @param {object} accountService - account service.
    */
    function chronovoPasswordPolicy(accountService) {
        var directive = {
            restrict: "A",
            require: '^ngModel',
            scope: true,
            template: ' <div flex-container="row" class="password-policy">\
                        <ul class="list-inline">\
                            <li ng-repeat="rule in rules"><i ng-class="rule.className()"></i>&nbsp;{{rule.description}}</li>\
                        </ul>\
                    </div>',
            link: postLink
        };

        return directive;

        function postLink($scope, $element, $attrs, $controller) {
            $scope.rules = [];

            // get password rules from DB
            accountService.getPasswordPolicy().then(function (rules) {
                angular.forEach(rules, function (value, key) {
                    $scope.rules.push(new rule(value.Message, value.Validator));
                });
                update($scope.$eval($attrs.ngModel));
            });

            // invoked each time password text is changed
            function rule(description, validator) {
                this.description = description;
                this.validator = validator;
                this.isValid = undefined;
                this.className = function () { return this.isValid ? "text-success mdi mdi-4x mdi-check" : "text-danger mdi mdi-4x mdi-close"; }
            };

            // check each rule against password
            function update(val) {
                var isValid = val ? true : false;

                for (var i = 0; i < $scope.rules.length; i++) {
                    if (!val) {
                        $scope.rules[i].isValid = false;
                    }
                    else {
                        var match = val.match(RegExp($scope.rules[i].validator));
                        $scope.rules[i].isValid = match ? true : false;
                    }

                    isValid = isValid && $scope.rules[i].isValid;
                    $controller.$setValidity('password-policy', isValid);
                }
            };

            // watch password change, and invoke each rule with change
            $scope.$watch($attrs.ngModel, function (val) {
                update(val);
            });
        }
    }
})();