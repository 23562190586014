/**
* @name chronovoCompare
* @desc Compare the input field's input to another to see if they match.
* @usage <input ng-model="user.password"> <input ng-model="user.confirmPassword" chronovo-compare="user.password">
**/
(function () {
    'use strict';

    angular
        .module('CareGuard')
        .directive('chronovoCompare', chronovoCompare);

    /** Inject services into directive. */
    chronovoCompare.$inject = ['$parse'];

    /**
    * @name chronovoCompare.
    * @desc Compare the input field's input to another to see if they match.
    * @param {object} $parse - angularjs parse service.
    */
    function chronovoCompare($parse) {
        var directive = {
            restrict: "A",
            require: 'ngModel',
            link: postLink
        };

        return directive;

        function postLink($scope, $element, $attrs, $controller) {
            // watch own value and re-validate on change
            $scope.$watch($attrs.ngModel, function () {
                validate();
            });

            // observe the other value and re-validate on change
            $scope.$watch($attrs.chronovoCompare, function () {
                validate();
            });

            // Validate the values
            var validate = function () {
                var val1 = $controller.$viewValue;
                var val2 = $scope.$eval($attrs.chronovoCompare);
                
                // set validity
                if (!val1 || !val2)
                    $controller.$setValidity('compare', true);
                else
                    $controller.$setValidity('compare', val1 === val2);
            };
        };
    };
})();