/**
* @name chronovoMatchRoute
* @desc Adds active class to li tag if location.path matches the route.
**/
(function () {
    'use strict';

    angular
        .module('CareGuard')
        .directive('chronovoMatchRoute', chronovoMatchRoute);

    /** Inject services into directive. */
    chronovoMatchRoute.$inject = ['$location'];

    /**
    * @name chronovoMatchRoute.
    * @desc Adds active class to li tag if location.path matches the route.
    * @param {object} $location.
    */
    function chronovoMatchRoute($location) {
        var directive = {
            restrict: "A",
            link: postLink
        };

        return directive;

        function postLink($scope, $element, $attrs, $controller) {
            $scope.$watch(function () {
                return $location.path();
            }, function (newValue, oldValue) {
              
                $('li[data-match-route]', $element).each(function (k, li) {
                    var $li = angular.element(li), pattern = $li.attr('data-match-route'), regexp = new RegExp('^' + pattern + '$', ['i']);
                  
                    if (regexp.test(newValue)) {
                        $li.addClass('active');
                    } else {
                        $li.removeClass('active');
                    }
                });
            });
        }
    }
})();