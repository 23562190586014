import allocationTemplate from './html/benefits/allocationTemplate.html';

export const benefitsDefsGrid = [
    {
        field: 'IsPartOfMSA',
        displayName: 'Original Allocation',
        headerCellClass: 'grid-component_header',
        width: '4%',
        cellTemplate: allocationTemplate
    },
    {
        field: 'CodeType',
        displayName: 'Code Type',
        headerCellClass: 'grid-component_header',
        width: '4%'
    },
    {
        field: 'Description',
        displayName: 'Description',
        headerCellClass: 'grid-component_header',
        cellTooltip: true,
        width: '20%'
    },
    {
        field: 'CodeDesc',
        displayName: 'Code Notes',
        headerCellClass: 'grid-component_header'
    },
    {
        field: 'Code',
        displayName: 'Code',
        headerCellClass: 'grid-component_header'
    },
    {
        field: 'GPICode',
        displayName: 'GPI Code',
        headerCellClass: 'grid-component_header'
    },
    {
        field: 'BrandCode',
        displayName: 'Brand Code',
        headerCellClass: 'grid-component_header'
    },
    {
        field: 'InMacFile',
        displayName: 'In Mac File',
        headerCellClass: 'grid-component_header'
    },
    {
        field: 'AWPPrice',
        displayName: 'AWPPrice',
        cellFilter: 'currency',
        headerCellClass: 'grid-component_header'
    },
    {
        field: 'OurPrice',
        displayName: 'Our Price',
        cellFilter: 'currency',
        headerCellClass: 'grid-component_header'
    },
    {
        field: 'DispenseFee',
        displayName: 'Dispense Fee',
        cellFilter: 'currency',
        headerCellClass: 'grid-component_header'
    },
    {
        field: 'UpdatedDate',
        displayName: 'Updated Date',
        cellFilter: "date:\'MM/dd/yyyy\'",
        headerCellClass: 'grid-component_header'
    },
    {
        field: 'Actions',
        displayName: '',
        width: '2%',
        headerCellClass: 'grid-component_header grid-component_header_actions',
        cellClass: 'grid-component_actions'
    }
];