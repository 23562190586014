/**
* @name historyController
* @desc Contains logic for history page.
**/
(function () {
    'use strict';

    angular
        .module('CareGuard')
        .controller('historyController', historyController);

    /** Inject services into controller. */
    historyController.$inject = ['caseData', 'layoutService'];

    /**
    * @name historyController.
    * @desc Contains logic for history page.
    * @param {object} caseData - case data object.
    */
    function historyController(caseData, layoutService) {
        var vm = this;
        vm.quotes = undefined;
        init();

        function init() {
            layoutService.setSelected({ Name: caseData.CaseName });

            // make list of quotes
            vm.quotes = [];
            Enumerable.from(caseData.Quotes).ForEach(function (q) {
                var comp = Enumerable.from(q.LifeCompanies).where('$.IsSelected').firstOrDefault(undefined);
                var quote = {
                    Name: q.Name,
                    Number: q.Number,
                    QuoteDate: q.QuoteDate,
                    ExpirationDate: q.ExpirationDate,
                    PremiumAmount: comp != undefined ? comp.PremiumAmount : 0,
                    GuaranteedPayout: comp != undefined ? comp.GuaranteedPayout : 0,
                    ExpectedPayout: comp != undefined ? comp.ExpectedPayout : 0
                };
                vm.quotes.push(quote);
            });
        };
    };
})();