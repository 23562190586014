angular
    .module('CareGuard')
    .constant('claimStatusId', {
        Paid: 8,
        PaidByACH: 14,
        PaidByMember: 15,
        PaidReversedRefundPending: 18,
        PaidRefundByTransferInProgress: 21,
        PaidRefundedByTransfer: 22,
        PaidReversedRefunded: 17,
        ReadyForPayment: 7,
        Entered: 1,
        ReadyForBillReview: 2,
        AwaitingBillReview: 10,
        BillReviewReceived: 6,
        OnHold: 9,
        Reconsideration: 24,
        ACHOnlyReadyForPayment: 13,
        DuplicateForReview: 11,
        MemberNeedsReview: 12,
        Reversed: 16,
        TransferInProgress: 19,
        Transferred: 20,
        PendingInformation: 3,
        Created: 25,
        ReceiptAudit: 5,
        PortalReceiptReceived: 4,
        ProviderNeedsReview: 23,
        HoldPaymentForWCEDI: 27,
	    BillPayProcessing: 26,
        NonClassicRateReceived: 28,
        NonRedeemedPayment: 29,
        ReadyForReissue: 30,
        ManualEntry: 31
    });
