/**
 *  @nam chronovoAttribute
 *  @desc Add attribute based on condition.
 *  @usage: <input chronovo-attribute="{'attr-to-add': val == true'}" />
 **/

'use strict';

(function () {
    'use strict';

    angular
        .module('CareGuard')
        .directive('chronovoAttribute', chronovoAttribute);

    /**
    * @name chronovoAttribute.
    * @desc Add attribute based on condition.
    */
    function chronovoAttribute() {
        var directive = {
            restrict: 'A',
            priority: '50000',
            link: postLink
        };

        return directive;

        function postLink($scope, $element, $attrs, $controller) {
            var oldVal;
            var name = 'chronovoAttribute';

            $scope.$watch($attrs[name], ngWatchAction, true);

            // add an attribute
            function addAttributes(attributes) {
                var newAttributes = digestCounts(attributes, 1);

                angular.forEach(newAttributes, function (v, k) {
                    var index = v.indexOf(':');
                    var val = '';
                    var attr = v;
                    if (index > 0) {
                        attr = v.substring(0, index);
                        val = v.substring(index + 1);
                    };
                    $element.attr(attr, val);
                });
            }

            // remove given attribute
            function removeAttributes(attributes) {
                var newAttributes = digestCounts(attributes, -1);

                angular.forEach(newAttributes, function (v, k) {
                    $element.removeAttr(v);
                });
            }

            // parse attributes
            function digestCounts(attributes, count) {
                var attributeCounts = $element.data('$attributeCounts') || {};
                var attributesToUpdate = [];

                angular.forEach(attributes, function (attributeName) {
                    if (count > 0 || attributeCounts[attributeName]) {
                        attributeCounts[attributeName] = (attributeCounts[attributeName] || 0) + count;
                        if (attributeCounts[attributeName] === +(count > 0)) {
                            attributesToUpdate.push(attributeName);
                        }
                    }
                });

                $element.data('$attributeCounts', attributeCounts);
                return attributesToUpdate;
            }

            // watch attribute
            function ngWatchAction(newVal) {
                var newAttributes = arrayAttributes(newVal || []);

                if (!oldVal) {
                    addAttributes(newAttributes);
                }
                oldVal = shallowCopy(newVal);
            }


            function arrayAttributes(val) {
                if (angular.isArray(val)) {
                    return val;
                } else if (angular.isString(val)) {
                    return val.split(' ');
                } else if (angular.isObject(val)) {
                    var attributes = [];
                    angular.forEach(val, function (v, k) {
                        if (v) {
                            attributes = attributes.concat(k.split(' '));
                        }
                    });
                    return attributes;
                }
                return val;
            }

            // Creates a shallow copy of an object, an array or a primitive.
            function shallowCopy(src, dst) {
                if (angular.isArray(src)) {
                    dst = dst || [];

                    for (var i = 0, ii = src.length; i < ii; i++) {
                        dst[i] = src[i];
                    }
                } else if (angular.isObject(src)) {
                    dst = dst || {};

                    for (var key in src) {
                        if (!(key.charAt(0) === '$' && key.charAt(1) === '$')) {
                            dst[key] = src[key];
                        }
                    }
                }

                return dst || src;
            }
        };
    };
})();
