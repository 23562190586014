angular
    .module('CareGuard')
    .controller('codesController', codesController);

codesController.$inject = [
    '$toastr',
    'memberService',
    'memberFlagsService',
    'accountService',
    'memberID',
    'codeService',
];

function codesController(
    $toastr,
    memberService,
    memberFlagsService,
    accountService,
    memberID,
    codeService) {

    let vm = this;

    vm.lookupdata = {};
    vm.codes = [];
    vm.originalMemberCodeSet = [];
    vm.codesToAddToMember = [];
    vm.memberflags = [];

    vm.hasSearchResult = false;
    vm.isInProgress = false;
    vm.newServiceCode = false;
    vm.showEditPanel = false;
    vm.isNewCodeActive = false;

    vm.pageSizes = [5, 10, 20, 50];
    vm.isMemberProfilePage = false;

    vm.enableCodeEdit = enableCodeEdit;
    vm.enableCodeEditMemberProfile = enableCodeEditMemberProfile;
    vm.resetCode = resetCode;
    vm.resetCodeMemberProfile = resetCodeMemberProfile;
    vm.addEmptyCode = addEmptyCode;
    vm.cancelNewCode = cancelNewCode; 
    vm.addCode = addCode;
    vm.filterCodes = filterCodes;
    vm.filterMemberCodes = filterMemberCodes;
    vm.sortByKey = sortByKey;
    vm.resetFilters = resetFilters;
    vm.resetMemberCodes = resetMemberCodes;
    vm.changePage = changePage;
    vm.clearSearch = clearSearch;
    vm.addCodeToMember = addCodeToMember;
    vm.deleteCode = deleteCode;
    vm.searchCodesToAddToMember = searchCodesToAddToMember;
    vm.resetMemberCodesSearchFilters = resetMemberCodesSearchFilters;
    vm.updateCode = updateCode;
    vm.updateCodeMemberProfile = updateCodeMemberProfile;
    vm.hasRole = hasRole;

    (() => {
        setMemberProfileFlag();
        initializePaginationControls();
        initializeFilters();
        getAllCodeTypes();

        if (vm.isMemberProfilePage) {
            initializeMemberCodeSearchFilters();
            getMemberData();
            getMemberCodes();
            getMemberFlags();
        }
    })();

    function initializePaginationControls() {
        vm.paginationControls = {
            totalRows: 0,
            totalPages: 1,
            pageSize: 20,
            pageNumber: 1,
        };
    }

    function initializeFilters() {
        vm.sortFilters = {
            sortColumn: null,
            sortOrder: 'asc',
        }

        vm.filterData = {};

        if (vm.isMemberProfilePage)
            vm.filterData.memberID = memberID;
    }

    function initializeMemberCodeSearchFilters() {
        vm.searchToAddFilterData = {};
    }

    function setMemberProfileFlag() {
        if (!memberID) return;

        vm.isMemberProfilePage = true;
    }

    function getAllCodeTypes() {
        codeService.getAllCodeTypes().then(({ data: types }) => {
            vm.codeTypes = types;
        });
    }

    function getMemberData() {
        return memberService.getById(memberID).then(({ Data: member }) => {
            vm.member = member;
        });
    }

    function getMemberFlags() {
        return memberFlagsService.getMemberFlags(memberID).then(({ Data: flags }) => {
            vm.memberFlags = flags;
        });
    }

    function getMemberCodes() {
       return codeService.getMemberCodes(memberID).then(({ data: codes }) => {
            const flattenedCodes = codes.map(code => flattenMemberCode(code));
            vm.codes = [ ...flattenedCodes ];
            vm.originalMemberCodeSet = [...flattenedCodes];

            assignPaginationValues({ totalRows: codes.length, totalPages: 1, pageNumber: 1, pageSize: codes.length });
        });
    }

    function flattenMemberCode(code) {
        code.serviceCodeId = code.serviceCode?.id;
        code.memberServiceCodeId = code.id;
        return { ...code.serviceCode, ...code };
    }

    function searchCodes(filters) {
        if (vm.isInProgress) return;

        vm.isInProgress = true;

        return codeService.searchAllCodes({ ...filters }).then(({ data: codeSet }) => {
            if (!codeSet?.items?.length) {
                $toastr.show(`No codes found using this search criteria.`, `warning`);
                return;
            }

            return codeSet;
        }).finally(() => {
            vm.isInProgress = false;
        });
    }

    function hasSelectedFilters(filterObject) {
        return Object.values(filterObject)?.filter(Boolean)?.length;
    }

    function assignPaginationValues({ totalRows, totalPages, pageNumber, pageSize }) {
        vm.paginationControls = {
            totalRows,
            totalPages,
            pageNumber,
            pageSize,
        };
    }

    function filterCodes() {
        if (!hasSelectedFilters(vm.filterData)) {
            $toastr.show(`At least one filter criteria is required before filtering.`, `warning`);
            return;
        }

        resetPage();
        setNewCodeAsInactive();
        searchServiceCodes();
    }

    function searchServiceCodes() {
        searchCodes(getCodeSearchRequest()).then(codeSet => {
            vm.codes = codeSet.items;
            assignPaginationValues({ ...codeSet, pageSize: vm.paginationControls.pageSize });
        });
    }

    function filterMemberCodes() {
        const { memberID, ...filterKeys } = vm.filterData;

        vm.codes = Object.keys(filterKeys).reduce((codes, key) => {
            return codes.filter(code =>
                typeof code[key] == 'string'
                    ? code[key]?.toLowerCase().includes(vm.filterData[key]?.toLowerCase())
                    : code[key] == vm.filterData[key]);
        }, vm.codes);

        assignPaginationValues({ ...vm.paginationControls, totalRows: vm.codes.length });
    }

    function getCodeSearchRequest() {
        let request = { code: vm.filterData.code, description: vm.filterData.description, ...vm.paginationControls };

        if (vm.filterData.codeType)
            request.codeTypes = [vm.filterData.codeType];

        return request;
    }

    function searchCodesToAddToMember() {
        if (!hasSelectedFilters(vm.searchToAddFilterData)) {
            $toastr.show(`At least one search criteria is required before searching.`, `warning`);
            return;
        }

        searchCodes(getMemberCodeSearchRequest()).then(codeSet => {
            const unattachedCodes = removeCodesAlreadyAttached(codeSet.items);

            if (!unattachedCodes.length) {
                $toastr.show(`Found codes matching the search criteria have already been added to member.`, `warning`);
                return;
            }

            vm.codesToAddToMember = unattachedCodes;
            vm.hasSearchResult = true;
        });
    }

    function removeCodesAlreadyAttached(codes) {
        const codeMap = vm.codes.reduce((map, code) => map.set(code.serviceCodeId, code.serviceCodeId), new Map());
        return codes.filter(code => !codeMap.get(code.id));
    }

    function getMemberCodeSearchRequest() {
        let request = { code: vm.searchToAddFilterData.code, description: vm.searchToAddFilterData.description, pageSize: 20 };

        if (vm.searchToAddFilterData.codeType)
            request.codeTypes = [vm.searchToAddFilterData.codeType];

        return request;
    }

    function addEmptyCode() {
        const newCode = buildEmptyCode();
        addCodeToViewModel(newCode);
        setNewCodeIsActive();
        incrementTotalRows();
    }

    function buildEmptyCode() {
        return {
            isEditEnabled: true,
            isNew: true,
        };
    }

    function addCodeToViewModel(code) {
        vm.codes?.unshift(code);
    }

    function addCodeToOriginalMemberCodeSet(code) {
        vm.originalMemberCodeSet?.unshift(code);
    }

    function setNewCodeIsActive() {
        vm.isNewCodeActive = true;
    }

    function setNewCodeAsInactive() {
        vm.isNewCodeActive = false;
    }

    function incrementTotalRows() {
        vm.paginationControls.totalRows++;
    }

    function decrementTotalRows() {
        vm.paginationControls.totalRows--;
    }

    function cancelNewCode(index) {
        removeCodeFromViewModelByIndex(index);
        setNewCodeAsInactive();
        decrementTotalRows();
    }

    function removeCodeFromViewModelByIndex(index) {
        vm.codes = vm.codes.filter((_, idx) => idx !== index);
    }

    function enableCodeEdit(code) {
        cancelAllOtherActiveEdits(code.id);
        code.isEditEnabled = true;
        saveOriginalCodeVersion(code);
    }

    function enableCodeEditMemberProfile(code) {
        cancelAllOtherActiveEditsMemberProfile(code.id);
        code.isEditEnabledMemberProfile = true;
        saveOriginalCodeVersion(code);
    }

    function cancelAllOtherActiveEdits(codeId) {
        vm.codes.forEach(code => {
            if (code.id !== codeId && code.isEditEnabled && !code.isNew)
                vm.resetCode(code);
        })
    }

    function cancelAllOtherActiveEditsMemberProfile(codeId) {
        vm.codes.forEach(code => {
            if (code.id !== codeId && code.isEditEnabledMemberProfile && !code.isNew)
                vm.resetCodeMemberProfile(code);
        })
    }

    function saveOriginalCodeVersion(code) {
        const { originalCopy, ...codeKeys } = code;
        code.originalCopy = codeKeys;
    }

    function resetCode(code) {
        Object.assign(code, code.originalCopy);
        cancelCodeEdit(code);
    }

    function resetCodeMemberProfile(code) {
        Object.assign(code, code.originalCopy);
        cancelCodeEditMemberProfile(code);
    }

    function cancelCodeEdit(code) {
        code.originalCopy = {};
        code.isEditEnabled = false;
    }

    function cancelCodeEditMemberProfile(code) {
        code.originalCopy = {};
        code.isEditEnabledMemberProfile = false;
    }

    function addCode(code) {
        if (!validateCode(code)) return;

        code.isProcessing = true;

        codeService.addCode(code).then(({ data: newCode }) => {
            Object.assign(code, newCode);
            completeNewCodeInViewModel(code);
        }).catch(err => {
            $toastr.show(err?.data?.detail ?? `There was an issue creating the code.`, `error`);
        }).finally(() => {
            code.isProcessing = false;
        });
    }

    function completeNewCodeInViewModel(code) {
        setNewCodeAsInactive();
        cancelCodeEdit(code);
        code.isNew = false;
    }

    function resetPage() {
        vm.paginationControls.pageNumber = 1;
        vm.paginationControls.totalRows = 0;
        vm.paginationControls.totalPages = 1;
    }

    function sortByKey(key) {
        if (!vm.isMemberProfilePage) return;

        setCurrentlySortedColumn(key);
        vm.codes = getSortedSetByKey(key);
        toggleSortOrder();
    }

    function setCurrentlySortedColumn(key) {
        vm.sortFilters.sortColumn = key;
    }

    function getSortedSetByKey(key) {
        // Placing null values always at the end of the sorted array
        const nulls = vm.codes.filter(code => code[key] == null);
        const nonNulls = vm.codes.filter(code => code[key] != null);

        return [..._.orderBy(nonNulls, [key], [vm.sortFilters.sortOrder]), ...nulls];
    }

    function toggleSortOrder() {
        vm.sortFilters.sortOrder = vm.sortFilters.sortOrder == 'asc' ? 'desc' : 'asc';
    }

    function changePage(pageNumber) {
        if (!pageNumber || pageNumber > vm.paginationControls.totalPages || vm.isMemberProfilePage) return;

        vm.paginationControls.pageNumber = pageNumber;
        setNewCodeAsInactive();
        searchServiceCodes();
    }

    function resetFilters() {
        initializePaginationControls();
        initializeFilters();
        clearCodeResults();
        setNewCodeAsInactive();
    }

    function resetMemberCodes() {
        vm.codes = vm.originalMemberCodeSet;
        initializeFilters();
        assignPaginationValues({ ...vm.paginationControls, totalRows: vm.codes.length });
    }

    function resetMemberCodesSearchFilters() {
        initializeMemberCodeSearchFilters();
        clearMemberCodeSearchResults();
    }

    function clearCodeResults() {
        vm.codes = [];
    }

    function clearMemberCodeSearchResults() {
        vm.codesToAddToMember = [];
        vm.hasSearchResult = false;
    }

    function addCodeToMember(code) {
        codeService.addCodeToMember({
            memberID,
            serviceCodeID: code.id,
            isPartOfMSA: code.isPartOfMSA,
            frequency: code.frequency,
            numberOfYears: code.numberOfYears,
            lifeTimeCost: code.lifeTimeCost
        }).then(({ data: newCode }) => {
            resetMemberCodesSearchFilters();
            const flattenedCode = flattenMemberCode(newCode);
            addCodeToViewModel(flattenedCode);
            addCodeToOriginalMemberCodeSet(flattenedCode);
            incrementTotalRows();
            incrementPageSize();
        }).catch(err => {
            $toastr.show(err?.data?.detail ?? `There was an issue adding the member code.`, `error`);
        });
    }

    function incrementPageSize() {
        vm.paginationControls.pageSize++;
    }

    function deleteCode(code) {
        if (!confirm(`Are you sure you want to delete ${code.code ?? 'this code'} from this member?`)) return;

        code.isProcessing = true;

        codeService.deleteMemberCodeById(code.memberServiceCodeId).then(() => {
            deleteCodeFromViewModels(code);
            decrementTotalRows();
        }).finally(() => {
            code.isProcessing = false;
        });
    }

    function deleteCodeFromViewModels(codeToDelete) {
        if (!codeToDelete) return;
        vm.codes = vm.codes?.filter(code => code.memberServiceCodeId !== codeToDelete.memberServiceCodeId);
        vm.originalMemberCodeSet = vm.originalMemberCodeSet?.filter(code => code.memberServiceCodeId !== codeToDelete.memberServiceCodeId);
    }

    function updateCode(code) {
        if (checkIfEditedModelIsSameAsOriginal(code)) {
            cancelCodeEdit(code);
            return;
        }

        trimStrings(code);
        if (!validateCode(code)) return;

        code.isProcessing = true;

        codeService.updateCode(code).then(({ data: newCode }) => {
            updateViewModelForSameCodes(newCode);
            cancelCodeEdit(code);
        }).finally(() => {
            code.isProcessing = false;
        });
    }

    function updateCodeMemberProfile(code) {
        if (checkIfEditedModelIsSameAsOriginal(code)) {
            cancelCodeEditMemberProfile(code);
            return;
        }

        trimStrings(code);
        if (!validateCode(code)) return;

        code.isProcessing = true;

        codeService.updateCodeMemberProfile(code).then(({ data: newCode }) => {
            updateViewModelForSameCodes(newCode);
            cancelCodeEditMemberProfile(code);
        }).finally(() => {
            code.isProcessing = false;
        });
    }

    function checkIfEditedModelIsSameAsOriginal(code) {
        const { originalCopy, ...codeKeys } = code;
        return _.isEqual(codeKeys, code.originalCopy);
    }

    function trimStrings(code) {
        Object.keys(code).forEach(key => {
            if (typeof code[key] == 'string') {
                const trimmedString = code[key].trim();
                code[key] = trimmedString.length ? trimmedString : null;
            } else {
                code[key] = code[key];
            }
        });
    }

    function validateCode(code) {
        if (!code.codeType) {
            $toastr.show(`Code type is required.`, `warning`);
            return false;
        }

        if (!code.description) {
            $toastr.show(`Description is required.`, `warning`);
            return false;
        }

        if (code.description.length < 3 || code.description.length > 2000) {
            $toastr.show(`Description must contain between 3 and 2000 characters.`, `warning`);
            return false;
        }

        if (code.code && code.code.length > 15) {
            $toastr.show(`Code must contain between 1 and 15 characters.`, `warning`);
            return false;
        }

        return true;
    }

    function updateViewModelForSameCodes(newCode) {
        vm.codes.filter(code => code.id == newCode.id).forEach(code => {
            Object.assign(code, newCode);
        });
    }

    function clearSearch() {
        resetMemberCodesSearchFilters();
    }

    function hasRole(role) {
        return accountService.isInRole(role);
    }
}