angular
	.module('CareGuard')
	.controller('payeeController', payeeController);

payeeController.$inject = [
	'$state',
	'$toastr',
	'$q',
	'payeeService',
	'lookupService',
	'accountService',
	'addressService',
	'utilService',
	'claimService',
	'payeeStatusEnum'
];

function payeeController(
	$state,
	$toastr,
	$q,
	payeeService,
	lookupService,
	accountService,
	addressService,
	utilService,
	claimService,
	payeeStatusEnum) {

	var vm = this;

	vm.availablePageSizes = [5, 10, 20];
	vm.filterData = {};
	vm.closeOthers = false;
	vm.payees = [];
	vm.nextToggleAllValue = true;
	vm.toggleTextValue = `Open`;
	vm.isNewPayeeActive = false;
	vm.previousSearch;
	vm.payeeAddressEditDisabledText = "This address is already associated with a paid claim. Address details may not be edited.";

	vm.payeeStatusEnum = payeeStatusEnum;
	vm.getPayees = getPayees;
	vm.resetOpenAll = resetOpenAll;

	(() => {
		initializeFilters();

		if ($state.params.TaxID) {
			vm.filterData.searchBy = $state.params.TaxID;
			vm.getPayees();
		}

		lookupService.getLookUp('statecode').then(({ Data: codes }) => vm.stateCodes = codes);
	})();

	function getPayees() {
		vm.resetOpenAll();
		vm.isNewPayeeActive = false;

		if (!vm.filterData.searchBy) {
			$toastr.show(`Must specify search criteria.`, `warning`);
			return;
		};

		if (vm.previousSearch !== vm.filterData.searchBy) {
			resetPage();
			vm.previousSearch = vm.filterData.searchBy;
        }

		payeeService.searchPayees(vm.filterData).then(({ data: { items: payees, ...meta } }) => {
			if (!payees) {
				$toastr.show(`No payees found using this search criteria.`, `warning`);
				return;
			}

			$q.all(payees.map(payee => addressService.getAddressesByPayeeId(payee.id))).then(results => {
				const payeesWithAddresses = payees.map((payee, index) => {
					payee.addresses = results[index].data.map(x => { x.payeeId = payee.id; return x; });
					return payee;
				});

				assignValues({ meta, payeesWithAddresses });

				return claimService.getEditablePayeeAddressIds(getPayeeAddressIds(payeesWithAddresses));
			}).then(({ data: editablePayeeAddressIds }) => {
				setEditableFlags({ editablePayeeAddressIds });
            });
		});
	}

	function setEditableFlags({ editablePayeeAddressIds }) {
		const idSet = new Set(editablePayeeAddressIds);
		const currentUser = accountService.getAccountName();

		for (payee of vm.payees) {
			payee.isEditable = true; //start with editable payee
			var isPayeeApproved = payee.payeeStatus == payeeStatusEnum.Approved;
			var isOtherUserEditingPendingPayee = payee.payeeStatus == payeeStatusEnum.Pending && payee.createdBy != currentUser;

			for (address of payee.addresses) {
				address.linkedToPaidClaim = !idSet.has(address.payeeAddressID);
				address.isEditable = !(address.linkedToPaidClaim || isPayeeApproved || isOtherUserEditingPendingPayee);
				payee.isEditable &&= address.isEditable;
			}

			if (payee.addresses.some(x => x.linkedToPaidClaim)) {
				payee.editDisabledText = "This payee is already associated with a paid claim. Payee details may not be edited.";
			}
			else if (isPayeeApproved) {
				payee.editDisabledText = "This payee has already been approved. Payee details may not be edited";
			}
			else if (isOtherUserEditingPendingPayee) {
				payee.editDisabledText = "This payee is in the pending status. Only the original creator may edit this record.";
			}
		}
	}

	function getPayeeAddressIds(payeesWithAddresses) {
		return payeesWithAddresses.map(payee => payee.addresses.map(address => address.payeeAddressID)).flat();
	}

	vm.changePage = function (pageNumber) {
		if (!pageNumber || pageNumber < 1 || pageNumber > vm.filterData.totalPages) return;

		vm.filterData.pageNumber = pageNumber;
		vm.getPayees();
	}

	vm.changePageNumber = function () {
		resetPageNumber();
	}

	function resetPageNumber() {
		vm.filterData.pageNumber = 1;
	}

	vm.enablePayeeEdit = function (payee, event) {
		stopClick(event);
		cancelAllOtherActiveEdits({ payeeId: payee.Id });
		payee.isPayeeEditEnabled = true;
		const { originalCopy, ...payeeKeys } = payee;
		payee.originalCopy = payeeKeys;		
	}

	vm.resetPayee = function (payee, event = null) {
		stopClick(event);

		Object.assign(payee, payee.originalCopy);
		cancelPayeeEdit(payee);
	}

	vm.updatePayee = function updatePayee(payee, event) {
		stopClick(event);
		if (payee.isNew) payee.isNew = !payee.isNew;

		const { originalCopy, ...payeeKeys } = payee;
		if (_.isEqual(payeeKeys, payee.originalCopy)) {
			cancelPayeeEdit(payee);
			return;
		}

		utilService.trimStrings(payee);
		if (!payeeService.isValidPayee(payee)) return;

		payeeService.updatePayee(payee).then(() => {
			payee.friendlyDisplayName = buildFriendlyDisplayName(payee.name);
			cancelPayeeEdit(payee);
		});
	}

	vm.addEmptyPayee = function addEmptyPayee() {	
		const newPayee = buildEmptyPayee();
		vm.payees.unshift(newPayee);
		vm.isNewPayeeActive = true;
		vm.filterData.totalRows++;
	}

	vm.addPayeeWithAddress = function addPayeeWithAddress(payee, address) {
		utilService.trimStrings(payee);
		if (!payeeService.isValidPayee(payee)) return;
		utilService.trimStrings(address);
		if (!addressService.isValidAddress(address)) return;

		payee.isProcessing = true;

		addPayee(payee)
			.then(() => addAddressToPayee({ payeeID: payee.id, address }))
			.then(() => {
				payee.isNew = false;
				payee.isPayeeEditEnabled = false;
				vm.isNewPayeeActive = false;
				address.isAddressEditEnabled = false;
				address.payeeId = payee.id;
			})
			.catch(err => {
				$toastr.show(utilService.parseErrorMessage(err), 'error');

				if (payee.id)
					return deletePayee(payee.id);
            })
			.finally(() => {
				payee.isProcessing = false;
			});
	}

	function addPayee(payee) {
		return payeeService.addPayee(payee).then(({ data: newPayee }) => {
			payee.id = newPayee.id;
			payee.friendlyDisplayName = buildFriendlyDisplayName(payee.name);
		});
	}

	function addAddressToPayee({ payeeID, address }) {
		return addressService.addAddressToPayee({ payeeID, address }).then(({ data: newAddress }) => {
			address.id = newAddress.addressID;
			address.isEditable = true;
		});
	}

	function deletePayee(id) {
		return payeeService.deletePayee(id);
    }

	vm.cancelNewPayee = function cancelNewPayee(index) {
		vm.payees = vm.payees.filter((val, idx) => idx !== index);
		vm.isNewPayeeActive = false;
		vm.filterData.totalRows--;
	}

	vm.enableAddressEdit = function enableAddressEdit(address) {
		cancelAllOtherActiveEdits({ addressId: address.id });		
			address.isAddressEditEnabled = true;
		const { originalCopy, ...addressKeys } = address;
		address.originalCopy = addressKeys;
	}

	vm.resetAddress = function resetAddress(address) {
		Object.assign(address, address.originalCopy);
		cancelAddressEdit(address);
	}

	vm.updateAddress = function updateAddress(address) {
		const { originalCopy, ...addressKeys } = address;
		if (_.isEqual(addressKeys, address.originalCopy)) {
			cancelAddressEdit(address);
			return;
		}

		utilService.trimStrings(address);
		if (!addressService.isValidAddress(address)) return;

		return payeeService.getPayeeById(address.payeeId).then(({ data: payee }) => {
			if (payee.payeeStatus == payeeStatusEnum.Pending) {
				return addressService.updateAddress(address)
					.then(() => {
						cancelAddressEdit(address);
					});
			} else {
				$toastr.show(`Address update not available when Payee is already Approved.`, 'warning');
				return $q.reject(false);
			}
		});
	}

	vm.resetFilters = function resetFilters() {
		initializeFilters();
		vm.payees = [];
		vm.isNewPayeeActive = false;
		vm.resetOpenAll();
	}

	vm.toggleOpenAll = function toggleOpenAll() {
		vm.payees.map(payee => {
			if (!payee.isNew) {
				payee.isAccordionOpen = vm.nextToggleAllValue;
			}
		});

		vm.toggleTextValue = vm.nextToggleAllValue ? `Close` : `Open`;
		vm.nextToggleAllValue = !vm.nextToggleAllValue;
	}

	function resetOpenAll() {
		vm.toggleTextValue = `Open`;
		vm.nextToggleAllValue = true;
    }

	vm.hasRole = function hasRole(role) {
		return accountService.isInRole(role);
	}

	function initializeFilters() {
		vm.filterData = {
			pageNumber: 1,
			pageSize: 10,
			searchBy: null,
			totalRows: 0,
			includePendingPayees: true
		};

		vm.previousSearch = ``;
	}

	function resetPage() {
		vm.filterData.pageNumber = 1;
		vm.filterData.totalRows = 0;
    }

	function assignValues({ meta, payeesWithAddresses }) {
		vm.payees = payeesWithAddresses.map(payee => {
			payee.friendlyDisplayName = buildFriendlyDisplayName(payee.name);
			return payee;
		});

		vm.filterData.totalRows = meta.totalRows;
		vm.filterData.totalPages = meta.totalPages;
		vm.filterData.pageNumber = meta.pageNumber;
	}

	function buildFriendlyDisplayName(name) {
		if (!name) return name;

		const maxTextLength = 65;

		return name.length < maxTextLength
			? name
			: name.substring(0, maxTextLength) + `...`;
    }

	function cancelAllOtherActiveEdits(entity) {
		vm.payees.forEach(payee => {
			if (payee.id !== entity.id && payee.isPayeeEditEnabled)
				vm.resetPayee(payee);

			payee.addresses?.forEach(address => {
				if (address.id !== entity.id && address.isAddressEditEnabled)
					vm.resetAddress(address);
			});
        })
    }

	function stopClick(event) {
		if (!event) return;

		event.stopPropagation();
		event.preventDefault();
    }

	function cancelPayeeEdit(payee) {
		payee.originalCopy = {};
		payee.isPayeeEditEnabled = false;
    }

	function buildEmptyPayee() {
		return {
			isPayeeEditEnabled: true,
			isNew: true,
			isAccordionOpen: true,
			addresses: [{
				isAddressEditEnabled: true,
				isEditable: true
			}],
			originalCopy: {},
			isEditable: true,
			payeeStatus: payeeStatusEnum.Pending
		};
    }

	function cancelAddressEdit(address) {
		address.originalCopy = {};
		address.isAddressEditEnabled = false;
	}
}
