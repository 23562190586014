/**
* @name userDetailController
* @desc User Controller.
**/
(function () {
    'use strict';

    angular
        .module('CareGuard')
        .controller('userDetailController', userDetailController)

    /** Inject services into controller. */
    userDetailController.$inject = ['userService', 'contactService', 'companyData'];

    /**
    * @name userDetailController.
    * @desc User Controller.
    * @param {object} userService - user service.
    * @param {object} contactService - contact service.
    * @param {object} companyData - list of companies.
    */
    function userDetailController(userService, contactService, companyData) {
        var vm = this;

        // variables
        vm.user = undefined;
        vm.company = undefined;
        vm.userCopy = undefined;
        vm.companies = undefined;
        vm.contacts = undefined;
        vm.contact = undefined;
        vm.isEditing = false;

        // functions
        vm.selectCompany = selectCompany;
        vm.selectContact = selectContact;
        vm.add = add;
        vm.edit = edit;
        vm.cancel = cancel;
        vm.save = save;
        vm.activate = activate;

        init();

        /* 
        * @name init
        * @desc initialize module
        */
        function init() {
            vm.companies = companyData;
            update();
        };

        /* 
        * @name update
        * @desc update selected user.
        * @param {object} usr - user.
        */
        function update(usr) {
            if (!usr) {
                usr = userService.getSelected(update);
            }
            vm.user = usr;
            vm.isEditing = false;
            getContacts();
            vm.contact = Enumerable.from(vm.contacts).where('$.Id == "' + vm.user.ContactId + '"').firstOrDefault(undefined);
        };

        /* 
        * @name getContacts
        * @desc update contacts for the selected company.
        */
        function getContacts() {
            if (!vm.user.CompanyId) return;
            contactService.getByCompany(vm.user.CompanyId).then(function (result) {
                vm.contacts = result;
                vm.contact = Enumerable.from(vm.contacts).where('$.Id == "' + vm.user.ContactId + '"').firstOrDefault(undefined);
            });
        };

        /* 
        * @name selectCompany
        * @desc select company. 
        * @param {object} val - selected object.
        */
        function selectCompany(val) {
            if (!val) {
                vm.user.CompanyId = undefined;
                return;
            }

            vm.user.CompanyId = val.originalObject.Id;
            vm.user.CompanyName = val.originalObject.Name;
            getContacts();
        };

        /* 
        * @name selectContact
        * @desc select contact associated with the user. 
        * @param {object} val - selected object.
        */
        function selectContact(val) {
            if (!val) {
                vm.user.ContactId = undefined;
                vm.user.FirstName = undefined;
                vm.user.LastName = undefined;
                vm.user.UserName = undefined;
                return;
            }
          
            vm.user.ContactId = val.originalObject.Id;
            vm.user.FirstName = val.originalObject.FirstName;
            vm.user.LastName = val.originalObject.LastName;
            vm.user.UserName = val.originalObject.Email;
        };

        /* 
        * @name add
        * @desc show form to add new user. 
        */
        function add(form) {
            form.setPristine();
            vm.userCopy = angular.copy(vm.user, {});
            userService.fireNewCallback({ Index: -1, IsNew: false });
            vm.user = { IsNew: true };
            vm.company = undefined;
            vm.isEditing = true;
        };

        /* 
        * @name edit
        * @desc show form to edit existing user. 
        */
        function edit(form) {
            form.setPristine();
            vm.userCopy = angular.copy(vm.user, {});
            vm.user.IsNew = false;
            vm.isEditing = true;
        };

        /* 
        * @name cancel
        * @desc cancel add/update and hide the modal. 
        */
        function cancel() {
            update(vm.userCopy);
            userService.fireNewCallback(vm.userCopy);
        };

        /* 
        * @name save
        * @desc save (add/update) and hide the modal. 
        */
        function save() {
            if (!vm.user) return;

            // commit to db
            userService.save(vm.user).then(function (result) {
                result.IsNew = vm.user.IsNew;
                result.Index = vm.user.Index;
                userService.fireNewCallback(result);
                update(result);
                vm.user.Version = result.Version;
                userService.setSelected(vm.user);
            });
        };

        /* 
        * @name activate
        * @desc send activation email. 
        */
        function activate() {
            userService.activate(vm.user);
        };
    };
})();