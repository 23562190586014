angular
	.module('CareGuard')
	.factory('providerService', providerService);

providerService.$inject = ['$http'];

// External API calls
function providerService($http) {
	const baseRoute = `providers`;

	function getProvidersByClaimIds(filters) {
		return $http.post(`${apiUrl}/${baseRoute}/claims`, filters);
	}

	function getPPONetworkById(networkId) {
		return $http.get(`${apiUrl}/${baseRoute}/ppos/${networkId}`);
	}

	return {
		getProvidersByClaimIds,
		getPPONetworkById,
	};
}