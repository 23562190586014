/**
* @name chronovoValidator
* @desc validate custom life company underwriting rule.
* @usage: <lx-select ng-model="model" chronovo-validator="[]"/>
**/
(function () {
    'use strict';

    angular
        .module('CareGuard')
        .directive('chronovoValidator', chronovoValidator);

    /** Inject services into directive. */
    chronovoValidator.$inject = ['validationService'];

    /**
    * @name chronovoValidator.
    * @desc validate custom life company underwriting rule.
    * @param {object} validatorService - chronovo Validator service.
    */
    function chronovoValidator(validationService) {
        var directive = {
            restrict: 'A',
            require: 'ngModel',
            priority: -1,
            link: postLink
        };
        
        return directive;

        /**
        * @name postLink
        * @desc post link function
        */
        function postLink($scope, $element, $attrs, $controller) {
            /**
            * @name $watch.
            * @desc watch changes in model and execute rule
            */
            $scope.$watch($attrs.ngModel, function (newVal, oldVal) {
                var validator = $scope.$eval($attrs.chronovoValidator);
                validationService.validate(validator);
            });
        };
    };
})();