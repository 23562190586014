import noteTextTemplate from './html/notes/noteTextTemplate.html';
import noteActionsTemplate from './html/notes/noteActionsTemplate.html';

export const notesDefsGrid = [
    {
        field: 'CreatedDate',
        displayName: 'Created Date',
        enableCellEdit: false,
        allowCellFocus: false,
        cellFilter: 'utcToEst',
        headerCellClass: 'grid-component_header',
        cellClass: 'showExtendedContent',
        width: '6%',
        required: true,
        enableSorting: true
    },
    {
        field: 'CreatedBy',
        displayName: 'Created By',
        enableCellEdit: false,
        allowCellFocus: false,
        headerCellClass: 'grid-component_header',
        width: '5%',
        required: true,
        enableSorting: true
    },
    {
        field: 'UpdateDate',
        displayName: 'Update Date',
        enableCellEdit: false,
        allowCellFocus: false,
        cellFilter: 'utcToEst',
        headerCellClass: 'grid-component_header',
        cellClass: 'showExtendedContent',
        width: '6%',
        required: false,
        enableSorting: true
    },
    {
        field: 'UpdatedBy',
        displayName: 'Update By',
        enableCellEdit: false,
        allowCellFocus: false,
        headerCellClass: 'grid-component_header',
        width: '5%',
        required: false,
        enableSorting: true
    },
    {
        field: 'NoteType',
        displayName: 'Note Type',
        headerCellClass: 'grid-component_header',
        enableCellEdit: false,
        allowCellFocus: false,
        width: '8%',
        required: false,
        enableSorting: true
    },
    {
        field: 'ClaimID',
        displayName: 'Claim ID',
        headerCellClass: 'grid-component_header',
        enableCellEdit: false,
        allowCellFocus: false,
        width: '5%',
        required: false,
        enableSorting: true
    },
    {
        field: 'Amount',
        displayName: 'Amount',
        headerCellClass: 'grid-component_header',
        enableCellEdit: false,
        allowCellFocus: false,
        cellFilter: 'currency',
        width: '4%',
        required: false,
        enableSorting: true
    },
    {
        field: 'NoteText',
        displayName: 'Note Text',
        headerCellClass: 'grid-component_header',
        enableCellEdit: false,
        allowCellFocus: false,
        cellTemplate: noteTextTemplate,
        width: '59%',
        required: true,
        enableSorting: true
    },
    {
        field: 'Actions',
        displayName: '',
        width: '2%',
        enableCellEdit: false,
        headerCellClass: 'grid-component_header grid-component_header_actions',
        cellClass: 'grid-component_actions',
        cellTemplate: noteActionsTemplate
    }
];