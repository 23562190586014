import diagnosisCodeTemplate from './html/diagnosisCodeTemplate.html';
import diagnosisTypeTemplate from './html/diagnosisTypeTemplate.html';
import deleteDiagnosisTemplate from './html/deleteDiagnosisTemplate.html';
import diagnosisSequenceTemplate from './html/diagnosisSequenceTemplate.html';

export const diagnosisDefsGridForNewClaim = [
    {
        field: 'sequenceLettered',
        displayName: 'Sequence',
        enableCellEdit: false,
        headerCellClass: 'grid-component_header',
        cellTemplate: diagnosisSequenceTemplate,
        width: '6%'
    },
    {
        field: 'code',
        displayName: 'Code',
        headerCellClass: 'grid-component_header',
        cellClass: 'overflow-visible',
        width: '15%',
        enableCellEdit: false,
        allowCellFocus: false,
        cellTemplate: diagnosisCodeTemplate
    },
    {
        field: 'isPrimary',
        displayName: 'Is Primary',
        enableCellEdit: false,
        headerCellClass: 'grid-component_header',
        width: '15%',
        cellTemplate: diagnosisTypeTemplate
    },
    {
        field: 'codeType',
        displayName: 'Code Type',
        enableCellEdit: false,
        headerCellClass: 'grid-component_header',
        width: '10%'
    },
    {
        field: 'description',
        displayName: 'Description',
        enableCellEdit: false,
        headerCellClass: 'grid-component_header'
    },
    {
        field: 'Actions',
        displayName: '',
        width: '2%',
        enableCellEdit: false,
        headerCellClass: 'grid-component_header grid-component_header__visible_actions',
        cellClass: 'grid-component_actions',
        required: false,
        cellTemplate: deleteDiagnosisTemplate
    }
];