/**
* @name chronovoSubmit
* @desc Form submit helper, validates input before submitting.
* @usage <form name="form" id="form" novalidate chronovo-submit="submit()">Home</form>
**/
(function () {
    'use strict';

    angular
        .module('CareGuard')
        .directive('chronovoSubmit', chronovoSubmit);

    /** Inject services into directive. */
    chronovoSubmit.$inject = ['$parse', '$toastr'];

    /**
    * @name chronovoSubmit.
    * @desc Form Submit Directive.
    * @param {object} $parse - angularjs parse service.
    */
    function chronovoSubmit($parse, $toastr) {
        var directive = {
            restrict: 'A',
            require: ['chronovoSubmit', '?form'],
            controller: controllerFunc,
            compile: compileFunc
        };

        return directive;

        function controllerFunc() {
            //this.attempted = false;
            var formController = null;

            // if user attempeted to submit
            this.setAttempted = function (val) {
                formController.attempted = val;
            };

            // reset form
            this.setPristine = function () {
                this.$setPristine();
                formController.attempted = false;
            };

            // form controller
            this.setFormController = function (controller) {
                formController = controller;
                formController.attempted = false;
                formController.setPristine = this.setPristine
                formController.setAttempted = this.setAttempted;
            };
            
            this.isValidValue = function (fieldModelController, value) {
                if (!formController || !fieldModelController) return false;
                
                return value == undefined && (fieldModelController.$dirty || formController.attempted);
            };
                        
            // is the field valid
            this.isValid = function (fieldModelController) {
                if (!formController) return false;

                if (fieldModelController) {
                    if (fieldModelController.$modelValue && angular.isArray(fieldModelController.$modelValue)) {
                        return fieldModelController.$modelValue.length == 0 && (fieldModelController.$dirty || formController.attempted);
                    }
                    else
                        return fieldModelController.$invalid && (fieldModelController.$dirty || formController.attempted);
                } else {
                    return formController && formController.$invalid && (formController.$dirty || formController.attempted);
                }
            };

            this.isFormValid = function () {
                return formController && formController.$invalid && (formController.$dirty || formController.attempted);
            };
        };

        function compileFunc(cElement, cAttributes, transclude) {
            return {
                pre: function (scope, formElement, attributes, controllers) {
                    var submitController = controllers[0];
                    var formController = (controllers.length > 1) ? controllers[1] : null;

                    submitController.setFormController(formController);

                    scope.md = scope.md || {};
                    scope.md[attributes.name] = submitController;
                },
                post: function (scope, formElement, attributes, controllers) {

                    var submitController = controllers[0];
                    var formController = (controllers.length > 1) ? controllers[1] : null;
                    var fn = $parse(attributes.chronovoSubmit);
                   
                    // ensure form is valid upon submit
                    formElement.bind('submit', function (event) {
                        submitController.setAttempted(true);
                        if (!scope.$$phase) scope.$apply();
                        
                        if (!formController.$valid) {
                            $toastr.show('Please fix any errors before continuing.', 'error');
                            return false;
                        };

                        scope.$apply(function () {
                            fn(scope, { $event: event });
                            formController.setPristine();
                        });
                    });
                }
            };
        };
    }
})();