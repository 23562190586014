angular
    .module('CareGuard')
    .controller('fundingEventsController', fundingEventsController);

fundingEventsController.$inject = [
    'memberID',
    'fundingEventsService',
    'memberService',
];

function fundingEventsController(
    memberId,
    fundingEventsService,
    memberService
) {
    let vm = this;
    vm.hasCallBeenMade = false;
    vm.eventData = [];
    vm.currentDate = moment().format().substring(0, 10);
    vm.fromDate = '2020-02-01';
    vm.totalYears = 0;

    (() => {
        memberService.getById(memberId)
            .then(({ Data: member }) => vm.member = member)
            .then(() => fundingEventsService.buildFundsExhaustionEventsDataFromMemberId({ memberId }))
            .then(eventData => {
                vm.hasCallBeenMade = true;
                if (!eventData.length) return;

                vm.fromDate = getFromDateFromEventData(eventData);
                vm.totalYears = getTotalYearsFromEventData(eventData);
                vm.eventData = fundingEventsService.fillEventArray({ events: eventData });
            });
    })();

    function getFromDateFromEventData(events) {
        if (!events?.length) return;

        const earliestEventYear = fundingEventsService.getEarliestEventYear({ events });
        return `${earliestEventYear}-02-01`;
    }

    function getTotalYearsFromEventData(events) {
        const earliestEventYear = fundingEventsService.getEarliestEventYear({ events });
        return getCurrentYear() - earliestEventYear + 1;
    }

    function getCurrentYear() {
        return new Date().getFullYear();
    }
}