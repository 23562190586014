/**
* @name deferredInterceptorService
* @desc Response Interceptor, processes a deferred request
**/
(function () {
    'use strict';

    angular
        .module('CareGuard')
        .factory('deferredInterceptorService', deferredInterceptorService);

    /** Inject services into service. */
    deferredInterceptorService.$inject = ['$q', '$timeout', '$injector', '$interval'];

    const DEFERRED_REQUEST_HEADER = 'deferredaction';
    const TIMEOUT_DELAY_SECONDS = 10;

    function deferredInterceptorService($q, $timeout, $injector, $interval) {
        var service = {
            response: response
        };

        return service;

        function response(response) {
            if (response && response.data && response.headers) {

                if (response.headers(DEFERRED_REQUEST_HEADER)) {
                    return runDeferredRequestProcessing(response);
                }
            }

            return response;
        }

        function runDeferredRequestProcessing(response) {
            var deferred = $q.defer();

            var cfpLoadingBar = $injector.get('cfpLoadingBar');

            cfpLoadingBar.start();

            var isCompleted = false;

            //restart loading bar if it has been completed before deferred request is completed
            var intervalPromise = $interval(() => {
                    if (isCompleted) {
                        $interval.cancel(intervalPromise);
                        return;
                    }

                    cfpLoadingBar.start();
                },
                100);

            const defaultResponseType = 'json';

            var originalResponseType = response.config.responseType || defaultResponseType;

            var data = convertToJson(response.data, originalResponseType);

            var requestId = data.RequestId;

            if (!requestId) {
                throw Error('request id is not defined');
            }

            pollTillComplete(requestId, originalResponseType).then(deferredResponse => {
                deferred.resolve(deferredResponse);
            })
            .finally(_ => {
                    isCompleted = true;
                    cfpLoadingBar.complete();
                });

            return deferred.promise;
        }

        function convertToJson(data, responseType) {
            switch(responseType.toLowerCase()) {
                case 'json':
                    return data;
                case 'arraybuffer':
                    return convertArrayBufferToJson(data);
                default:
                    throw Error('Unexpected response type');
            }
        }

        function convertArrayBufferToJson(data) {
            var decodedString = String.fromCharCode.apply(null, new Uint8Array(data));
            var obj = JSON.parse(decodedString);

            return obj;
        }

        function pollTillComplete(requestId, responseType) {

            return $timeout(() => {
                var deferredService = $injector.get('deferredService');

                return deferredService.getDeferredRequestStatus(requestId).then(response => {
                    if (response.data.IsCompleted) {
                        return deferredService.getDeferredResponse(requestId, responseType);
                    } else {
                        return pollTillComplete(requestId, responseType);
                    }
                });
            }, TIMEOUT_DELAY_SECONDS * 1000);
        }
    }
})();