/**contact
* @name claimGridController
* @desc claim grid controller.
**/
(function () {
    'use strict';

  angular
    .module('CareGuard')
    .controller('odgLookUpController', odgLookUpController);

    /** Inject services into controller. */
  odgLookUpController.$inject = ['$scope', '$location', '$state', '$timeout', 'odgLookUpService', 'lookupService', 'accountService', 'memberID', 'memberService', 'memberFlagsService', '$toastr'];

    /**
    * @name claimGridController.
    * @desc claim grid controller.   
    */
  function odgLookUpController($scope, $location, $state, $timeout, odgLookUpService, lookupService, accountService, memberID, memberService, memberFlagsService, $toastr) {

      // variables
      var vm = this;

      vm.member = undefined;
      vm.memberflags = undefined;

      vm.odgResults = undefined;
      vm.lookupdata = undefined;
            
      vm.filterData = {

        MemberNumber: undefined,
        Diagnosis: undefined,
        CPTCodes: undefined
      };
      var cptCodeList = new Array();

      // functions
      vm.odgLookUp = odgLookUp;
      vm.clearInfo = clearInfo;
      vm.navigate = navigate;
      
      
      init();

      /* 
      * @name init
      * @desc initialize module
      */
      function init() {
        vm.lookupdata =
        {
          accountstatus: lookupService.getLookUp("accountStatus")          
        }
        
        lookupService.getLookUp("accountStatus").then(function (accountStatusdata) {
          vm.lookupdata.accountstatus = accountStatusdata.Data;

        });

        //check if memberID was passed in; if so, get member
        if (memberID > 0) {
          memberService.getById(memberID).then(function (result) {
            vm.member = result.Data;

            //this sets the default data for the member claims page
            vm.filterData.MemberID = vm.member.MemberID;
            vm.filterData.MemberNumber = vm.member.MemberNumber;
            vm.filterData.MemberName = vm.member.MemberName;

          });

          //member flags
          memberFlagsService.getMemberFlags(memberID).then(function (resultset) {
            vm.memberflags = resultset.Data;

          });


        }

      };
        

      //odg look up
      function odgLookUp() {
        var tempMemberNumber = vm.filterData.MemberNumber; //autopopulates if on member odglookup page
        var tempDiagnosis = vm.filterData.Diagnosis;
        var tempCPTCodes = vm.filterData.CPTCodes;
        

        //if (tempMemberNumber == undefined || tempMemberNumber == '' || tempMemberNumber == null)
        //{
        //  //non member page, check for cpt code and diagnosis code
          
        //  if (tempDiagnosis == undefined || tempDiagnosis == '' || tempDiagnosis == null)
        //  {
        //    alert("Member Number or Diagnosis is required.");
        //    return;
        //  }
        //  else if(tempCPTCodes == undefined || tempCPTCodes == '' || tempCPTCodes == null)
        //  {
        //    alert("Member Number or CPT Procedure Code is required.");
        //    return;
        //  }
        //}
        //else
        //{
          if ((tempDiagnosis == undefined || tempDiagnosis == '' || tempDiagnosis == null ) && (tempCPTCodes == undefined || tempCPTCodes == '' || tempCPTCodes == null)) {
              $toastr.show('Either Diagnosis or CPT Procedure Code is required.', 'warning');
            return;
          }
        //}


        //alert(tempMemberNumber);
        //alert(tempDiagnosis);
        //alert(tempCPTCodes);
        
        odgLookUpService.odgLookUp(tempMemberNumber, tempDiagnosis, tempCPTCodes).then(function (result) {
          vm.odgResults = result.Data;
          //console.log(vm.odgResults);

        });

      };
      
      //clears the filter data
      function clearInfo() {
        vm.filterData.MemberNumber = "";
        vm.filterData.CPTCodes = "";        
        vm.filterData.Diagnosis = "";

      };

      
      function navigate(state, params) {

        $timeout(function () {
          $state.go(state, params);
        }, 250);

      };

    };
})();