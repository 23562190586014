/**
* @name CareQuote service
* @desc CareQuote service.
**/
(function () {
    'use strict';

    angular
        .module('CareGuard')
        .factory('careQuoteService', careQuoteService);

    /** Inject services into service. */
    careQuoteService.$inject = ['$http', 'utilService'];

    /**
    * @name CareQuote service.
    * @desc CareQuote service.
    */
    function careQuoteService($http, utilService) {
        const baseUrl = '/api/quotes';

        function getQuotes(memberId) {
            return $http.get(`${baseUrl}/${memberId}`);
        };

        function saveQuote(quote) {
            return $http.post(`${baseUrl}/addQuote`, quote);
        }

        function deleteQuote(quoteId) {
            return $http.delete(`${baseUrl}/deleteQuote`, { params: { quoteId } });
        }

        function addQuoteDetail(detail) {
            return $http.post(`${baseUrl}/addQuoteDetail`, detail);
        }

        function editQuoteDetail(detail) {
            return $http.put(`${baseUrl}/editQuoteDetail`, detail);
        }

        function deleteQuoteDetail(quoteId, quoteDetailId) {
            return $http.delete(`${baseUrl}/deleteQuoteDetail`, { params: { quoteId, quoteDetailId } });
        }

        function markQuoteAsCompleted(quoteId) {
            return $http.put(`${baseUrl}/markQuoteAsCompleted`, quoteId);
        }

        function markQuoteAsSent(quoteId, sentdate) {
            return $http.put(`${baseUrl}/markQuoteAsSent`, quoteId);
        }

        function getServiceCodePricing(serviceCodeId, pricingGroupId) {
            return $http.get(`${baseUrl}/getServiceCodePricing`, { params: { serviceCodeId, pricingGroupId } });
        }

        function calculateQuoteDetails(detail) {
            return $http.put(`${baseUrl}/calculateQuoteDetails`, detail);
        }

        function recalculateQuoteTotals(quote) {
            return $http.put(`${baseUrl}/recalculateQuoteTotals`, quote);
        }

        function getPricingGroups() {
            return $http.get(`${baseUrl}/getPricingGroups`);
        }

        function updatePricingGroupCalculations(quote) {
            return $http.put(`${baseUrl}/updatePricingGroupCalculations`, quote);
        }

        function cloneQuote(quote) {
            return $http.post(`${baseUrl}/cloneQuote`, quote);
        }

        function downloadQuoteDocument(quote, includeDispenseFee) {
            utilService.download({
                method: `POST`,
                url: `${baseUrl}/exportToForm`,
                data: { Quote: quote, IncludeDispenseFee: includeDispenseFee }
            });
        }

        return {
            getQuotes,
            saveQuote,
            deleteQuote,
            addQuoteDetail,
            editQuoteDetail,
            deleteQuoteDetail,
            markQuoteAsCompleted,
            markQuoteAsSent,
            getServiceCodePricing,
            calculateQuoteDetails,
            recalculateQuoteTotals,
            getPricingGroups,
            updatePricingGroupCalculations,
            cloneQuote,
            downloadQuoteDocument
        }
    };
})();