/**
* @name accountService
* @desc account Service.
**/
(function () {
    'use strict';

    angular
        .module('CareGuard')
        .factory('accountService', accountService);

    /** Inject services into service. */
    accountService.$inject = ['$http', '$q' ,'$timeout', 'webStorageService', 'externalAuthService', 'xsrfService'];

    /**
    * @name accountService.
    * @desc account Service.
    */
    function accountService($http, $q, $timeout, webStorage, externalAuthService, xsrfService) {
        var baseUrl = '/api/account/';
        var identity = undefined;
        var service = {
            authenticate: authenticate,
            getUserName: getUserName,
            getAccountName: getAccountName,
            getUserId: getUserId,
            getCompanyId: getCompanyId,
            isAuthenticated: isAuthenticated,
            isInRole: isInRole,
            isInRoleById: isInRoleById,
            signOut: signOut,
            sendResetEmail: sendResetEmail,
            validateLink: validateLink,
            getPasswordPolicy: getPasswordPolicy,
            getSecurityQuestions: getSecurityQuestions,
            updateSecurityQuestions: updateSecurityQuestions,
            updatePassword: updatePassword,
            resetPassword: resetPassword,
            activate: activate,
            getUserNameEncrypted: getUserNameEncrypted,
            getLegacyCareGuardUrl: getLegacyCareGuardUrl
        };

        return service;
        
        /**
        * @name authenticate.
        * @desc checks if user is logged in server side, and stores role information on the client.
        */
        function authenticate() {
            var dfd = $q.defer();

            if (angular.isDefined(identity)) {
                $timeout(function () {
                    dfd.resolve();
                }, 0);

                return dfd.promise;
            }

            $http({ method: 'GET', url: baseUrl + 'user/identity' }).then(function (response) {

              if (response) {
                
                    identity = {
                        Id: response.data.Id,
                        Name: response.data.DisplayName,
                        Email: response.data.Email,
                        Roles: response.data.Roles
                    };
          
                }
                dfd.resolve();
            });

            return dfd.promise;
        };

        /**
        * @name getUserName.
        * @desc get user display name.
        */
        function getUserName() {       
          if (identity) {
            
                return identity.Name;
            };
        };


        // gets username
        function getAccountName() {
            if (identity) {

                return identity.Email;
            };
        };

      /**
     * @name getUserNameEncrypted.
     * @desc get user display name.
     */
        function getUserNameEncrypted() {
           return null;
        };

        function getLegacyCareGuardUrl() {
        };
        
        /**
        * @name getUserId.
        * @desc get user id.
        */
        function getUserId() {
            if (identity) {
                return identity.Id;
            };
        };

        /**
        * @name getCompanyId.
        * @desc get user's companyId.
        */
        function getCompanyId() {
            if (identity) {
                return identity.CompanyId;
            };
        };

        /**
        * @name isAuthenticated.
        * @desc check is use is authenticated.
        */
        function isAuthenticated() {
            return identity != undefined;
        };

        /**
        * @name isInRole.
        * @desc check if user has a given role by name.
        */
        function isInRole(roleNames) {
            if (!identity || !identity.Roles) return false;
      
            // Get array of passed in roles
            var paramRoles = roleNames.split(",");

            for (var i = 0; i < paramRoles.length; i++) {
                for (var x = 0; x < identity.Roles.length; x++) {
                  if (identity.Roles[x].RoleName.toLowerCase().trim() == paramRoles[i].toLowerCase().trim()) {
                        return true;
                    }
                };
            };
            return false;
        };

        /**
        * @name isInRoleById.
        * @desc check if user has a given role by name.
        */
        function isInRoleById(roleId) {
            if (!identity || !identity.Roles || !roleId) return false;

            // Get array of passed in roles
            var paramRoles = roleId.split(",");
            
            for (var i = 0; i < paramRoles.length; i++) {
                for (var x = 0; x < identity.Roles.length; x++) {
                    if (identity.Roles[x].Id == paramRoles[i]) {
                        return true;
                    }
                };
            };
            return false;
        };

        /**
        * @name signOut.
        * @desc log out a user by delete any tokens.
        */
        function signOut() {
            externalAuthService.getLogoutRedirectUrl().then(function(logoutUrl) {
                externalAuthService.clear();
                xsrfService.clear();
                identity = null;
                
                window.location = logoutUrl;
            });
        };

        /**
        * @name sendResetEmail.
        * @desc send password reset email.
        * @param {object} user - user object.
        */
        function sendResetEmail(user) {
            var dfd = $q.defer();

            $http.post(baseUrl + 'reset/sendemail', user)
                .then(function (response) {
                    if (response.data && response.data.HasError)
                        dfd.reject(response.data);
                    else
                        dfd.resolve();
                },
                function (response) {
                    dfd.reject(response.data);
                });

            return dfd.promise;
        };

        /**
        * @name validateLink.
        * @desc validate password reset or activation email link.
        * @param {guid} urlId - unique url id from the email.
        */
        function validateLink(urlId) {
            return $http.get(baseUrl + 'reset/validate/' + urlId);
        };

        /**
        * @name getPasswordPolicy.
        * @desc gets password policy rules.
        */
        function getPasswordPolicy() {
            return $http.get(baseUrl + 'reset/passwordpolicy', { cache: true });
        };

        /**
        * @name getSecurityQuestions.
        * @desc gets all security questions.
        */
        function getSecurityQuestions() {
            return $http.get(baseUrl + 'securityquestions', { cache: true });
        };

        /**
        * @name updateSecurityQuestions.
        * @desc update security questions.
        * @param {object} questions - list of questions and answers.
        */
        function updateSecurityQuestions(questions) {
            return $http.post(baseUrl + 'update/questions', questions);
        };

        /**
        * @name updatePassword.
        * @desc update user password.
        * @param {object} psswd - password reset information.
        */
        function updatePassword(psswd) {
            return $http.post(baseUrl + 'update/password', psswd);
        };

        /**
        * @name resetPassword.
        * @desc reset user password.
        * @param {object} psswd - password reset information.
        */
        function resetPassword(psswd) {
            return $http.post(baseUrl + 'reset/password', psswd);
        };

        /**
        * @name activate.
        * @desc activate user account.
        * @param {object} data - account password information.
        */
        function activate(data) {
            return $http.post(baseUrl + 'activate', data);
        };
    }
})();