angular
    .module('CareGuard')
    .filter('ssnFilter', () => {
        return (value, mask=false) => {
            if (value) {
                let val = value.toString().replace(/\D/g, "");
                let len = val.length;
                if (len < 4) {
                    return val
                } else if (3 < len && len < 6) {
                    if (mask) {
                        return "XXX-" + val.substr(3);
                    } else {
                        return val.substr(0, 3) + "-" + val.substr(3);
                    }
                } else if (len > 5) {
                    if (mask) {
                        return "XXX-XX-" + val.substr(5, 4);
                    } else {
                        return val.substr(0, 3) + "-" + val.substr(3, 2) + "-" + val.substr(5, 4);
                    }
                }

            }
        }
    })

    .filter('ssnReverse', () => (value) => {
        if (value) return value.replace(/\D/g, "").substr(0, 9);

        return value;
    });