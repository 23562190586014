/**
* @name userRoleController
* @desc Role Controller.
**/
(function () {
    'use strict';

    angular
        .module('CareGuard')
        .controller('userRoleController', userRoleController)

    /** Inject services into controller. */
    userRoleController.$inject = ['$q', '$timeout', 'userService'];

    /**
    * @name userRoleController.
    * @desc Role Controller.
    * @param {object} $q - defer service.
    * @param {object} userService - user service.
    */
    function userRoleController($q, $timeout, userService) {
        var vm = this;

        // variables
        vm.roles = undefined;
        vm.isEditing = false;
        vm.user = undefined;

        // functions
        vm.edit = edit;
        vm.save = save;
        vm.cancel = cancel;
        vm.validate = validate;

        init();

        /* 
        * @name init
        * @desc initialize module, display Roles for a user.
        */
        function init() {
            vm.isEditing = false;
            vm.user = userService.getSelected(updateUser);
            userService.getRoles(vm.user.Id).then(function (result) {
                vm.roles = result;
            });
        };

        /* 
        * @name updateUser
        * @desc update user callback.
        */
        function updateUser(usr) {
            vm.user = usr;
        };

        /* 
        * @name edit
        * @desc switch to editing mode.
        */
        function edit() {
            vm.rolesCopy = angular.copy(vm.roles);
            vm.isEditing = true;
        };

        /* 
        * @name save
        * @desc save roles & permissions.
        */
        function save() {
            var data = {
                Id: vm.user.Id,
                Version: vm.user.Version,
                Models: {
                    'ContactId': vm.user.ContactId,
                    'Roles': Enumerable.from(vm.roles).where('$.IsSelected == true').select('$.Id').toArray()
                }
            };

            userService.saveRoles(data).then(function (result) {
                vm.user.Version = result.Version;
                userService.setSelected(vm.user);
                vm.isEditing = false;
            });
        };

        /* 
        * @name cancel
        * @desc cancel roles update.
        */
        function cancel() {
            vm.isEditing = false;
            vm.roles = vm.rolesCopy;
        };

        /* 
        * @name validate
        * @desc validate role.
        */
        function validate(role) {
            // System Admin shouldn't have any other role except Broker
            // Adjuster may only have Team Lead
            // Broker may only have Team Lead or System Admin
            if (role.Key == 'SystemAdmin' && role.IsSelected) {
                Enumerable.from(vm.roles).where('$.Key != "SystemAdmin" && $.Key != "Broker"').forEach(function (r) {
                    r.IsSelected = false;
                });
            }
            else if (role.Key == 'Adjuster' && role.IsSelected) {
                Enumerable.from(vm.roles).where('$.Key != "Adjuster" && $.Key != "TeamLead"').forEach(function (r) {
                    r.IsSelected = false;
                });
            }
            else if (role.Key == 'Broker' && role.IsSelected) {
                Enumerable.from(vm.roles).where('$.Key == "Adjuster"').forEach(function (r) {
                    r.IsSelected = false;
                });
            };
            //else if (role.Key == 'TeamLead' && role.IsSelected) {
            //    Enumerable.from(vm.roles).where('$.Key == "SystemAdmin"').forEach(function (r) {
            //        r.IsSelected = false;
            //    });
            //}
        };
    };
})();