angular
    .module('CareGuard')
    .directive('validateDate', validateDate);

validateDate.$inject = [];

function validateDate() {
    return {
        restrict: 'A',
        require: 'ngModel',

        link: function($scope, $elm, $attrs, ngModelController) {
            ngModelController.$parsers.unshift(function (value) {
                if (!value) {
                    ngModelController.$setValidity('date', true);
                    return null;
                }

                const momentJsDate = moment(ngModelController.$viewValue, 'MM/DD/YYYY', true);
                const minDate = Date.parse($attrs.minDate) || moment('01/01/1970', 'MM/DD/YYYY', true).valueOf();
                const maxDate = Date.parse($attrs.maxDate) || null;

                const isLessOrEqualToMaxDate = function() {
                    if (maxDate) {
                        return momentJsDate.valueOf() <= maxDate;
                    } else {
                        return true;
                    }
                };

                const isGreaterOrEqualToMinDate = () => momentJsDate.valueOf() >= minDate;

                ngModelController.$setValidity('date', momentJsDate.isValid()
                    && isGreaterOrEqualToMinDate() && isLessOrEqualToMaxDate());

                return value;
            });

            ngModelController.$formatters.push(function(data) {
                if (!data) return null;

                data = moment(data).format('MM/DD/YYYY');

                return data;
            });
        }
    }
}