angular
    .module('CareGuard')
    .controller('annualAccountingController', annualAccounting);

annualAccounting.$inject = [
    '$scope',
    'annualAccountingService',
    'utilService'];

function annualAccounting(
    $scope,
    annualAccountingService,
    utilService)
{
    let vm = this;

    vm.isDataLoading = false;

    vm.annualAccountingEffectiveMonths = [];

    vm.downloadDocuments = downloadDocuments;

    init();

    function init() {

        vm.isDataLoading = true;

        annualAccountingService.getAnnualAccountingEffectiveMonths().then(response => {
            vm.annualAccountingEffectiveMonths = response.data;
        }).finally(() => vm.isDataLoading = false);
    }

    function downloadDocuments(effectiveMonth) {
        vm.isDataLoading = true;
        annualAccountingService.downloadAnnualAccounting(effectiveMonth).then(response => {
            utilService.processResponse({ response });
        }).finally(() => vm.isDataLoading = false);
    }

}