/**
* @name arrayUtility
* @desc used for various array operations.
**/
(function () {
    'use strict';

    angular
        .module('smart-grid')
        .factory('arrayUtility', arrayUtility);


    function arrayUtility() {
        var service = {
            removeAt: removeAt,
            insertAt: insertAt,
            moveAt: moveAt,
            sort: sort,
            filter: filter,
            fromTo: fromTo
        };

        return service;

        /**
        * @name removeAt
        * @desc remove the item at index from arrayRef and return the removed item.
        * @param {array} arrayRef - array object.
        * @param {int} index - index of item to be removed.
        * @returns {object} - array object.
        */
        function removeAt(arrayRef, index) {
            if (index >= 0 && index < arrayRef.length) {
                return arrayRef.splice(index, 1)[0];
            }
        };

        /**
        * @name removeAt
        * @desc insert item in arrayRef at index or a the end if index is wrong.
        * @param {array} arrayRef - array object.
        * @param {int} index - index of item to be removed.
        * @returns {object} - array object.
        */
        function insertAt(arrayRef, index, item) {
            if (index >= 0 && index < arrayRef.length) {
                arrayRef.splice(index, 0, item);
            } else {
                arrayRef.push(item);
            }
        };

        /**
        * @name moveAt
        * @desc move the item at oldIndex to newIndex in arrayRef
        * @param {array} arrayRef - array object.
        * @param {int} oldIndex - old index.
        * @param {int} newIndex - new index.
        */
        function moveAt(arrayRef, oldIndex, newIndex) {
            var elementToMove;
            if (oldIndex >= 0 && oldIndex < arrayRef.length && newIndex >= 0 && newIndex < arrayRef.length) {
                elementToMove = arrayRef.splice(oldIndex, 1)[0];
                arrayRef.splice(newIndex, 0, elementToMove);
            }
        };

        /**
        * @name sort
        * @desc sort arrayRef according to sortAlgorithm following predicate and reverse
        * @param {array} arrayRef - array object.
        * @param sortAlgorithm
        * @param predicate
        * @param reverse
        * @returns {object} - array object.
        */
        function sort(arrayRef, sortAlgorithm, predicate, sortDir) {
            if (!sortAlgorithm || !angular.isFunction(sortAlgorithm)) {
                return arrayRef;
            } else {
                return sortAlgorithm(arrayRef, predicate, sortDir == 'desc');
            }
        };

        /**
        * @name filter
        * @desc filter arrayRef according with filterAlgorithm and predicate
        * @param {array} arrayRef - array object.
        * @param filterAlgorithm
        * @param predicate
        * @returns {object} - array object.
        */
        function filter(arrayRef, filterAlgorithm, predicate) {
            if (!filterAlgorithm || !angular.isFunction(filterAlgorithm)) {
                return arrayRef;
            } else {
                return filterAlgorithm(arrayRef, predicate);
            }
        };

        /**
        * @name fromTo
        * @desc return an array, part of array ref starting at min and the size of length
        * @param {array} arrayRef - array object.
        * @param min
        * @param length
        * @returns {object} - array object.
        */
        function fromTo(arrayRef, min, length) {

            var out = [],
                limit,
                start;

            if (!angular.isArray(arrayRef)) {
                return arrayRef;
            }

            start = Math.max(min, 0);
            start = Math.min(start, (arrayRef.length - 1) > 0 ? arrayRef.length - 1 : 0);

            length = Math.max(0, length);
            limit = Math.min(start + length, arrayRef.length);

            for (var i = start; i < limit; i++) {
                out.push(arrayRef[i]);
            }
            return out;
        };
    }
})();
