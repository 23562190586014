angular
    .module('CareGuard')
    .factory('bankingService', bankingService);

bankingService.$inject = ['$http', 'bankEnum', 'utilService'];

function bankingService($http, bankEnum, utilService) {
    // External API calls
    const baseRoute = `banks`;

    function getTransactionsByClaimId(claimId) {
        return $http.get(`${apiUrl}/${baseRoute}/transactions/claims/${claimId}`);
    }

    function getTransactionsByClaimIds(claimIds) {
        return $http.post(`${apiUrl}/${baseRoute}/transactions/claims/bulk`, claimIds);
    }

    function getCurrentBalanceTotalsByMemberIds(filters) {
        return $http.post(`${apiUrl}/${baseRoute}/balances/members/totals`, filters);
    }

    function getExternalAccounts(memberId) {
        return $http.get(`${apiUrl}/${baseRoute}/members/${memberId}/accounts/externals`);
    }

    function getBanks() {
        return $http.get(`${apiUrl}/${baseRoute}`);
    }

    function addMemberBankAccountCreationRequests(requests) {
        return $http.post(`${apiUrl}/${baseRoute}/accounts/requests/creations`, requests);
    }

    function addMemberBankAccount(request) {
        return $http.post(`${apiUrl}/${baseRoute}/accounts/members`, request);
    }

    function getMemberBanks(memberId) {
        return $http.get(`${apiUrl}/${baseRoute}/accounts/members/${memberId}`);
    }

    function getBankAccountBalanceForMember(bankId, memberId) {
        return $http.get(`${apiUrl}/${baseRoute}/${bankId}/balances/members/${memberId}`);
    }

    function getBankCreationRequests(bankId, status) {
        return $http.get(`${apiUrl}/${baseRoute}/${bankId}/accounts/requests/creations?status=${status}`);
    }

    function setDefaultBank(bankId, memberId) {
        return $http.patch(`${apiUrl}/${baseRoute}/${bankId}/accounts/members/${memberId}`)
    }

    function getBankTransactionResponses(memberId, query) {
        return $http.get(`${apiUrl}/${baseRoute}/transactions/responses/members/${memberId}`, { params: query });
    }

    function updateBankTransactionResponseType(transactionId, transactionType) {
        return $http.patch(`${apiUrl}/${baseRoute}/transactions/${transactionId}/responses`, `\"${transactionType}\"`);
    }

    function getFundsExhaustionEventTypes() {
        return $http.get(`${apiUrl}/${baseRoute}/exhaustions/types`);
    }

    function getFundsExhaustionEventsByMemberId(memberId) {
        return $http.get(`${apiUrl}/${baseRoute}/exhaustions/members/${memberId}`);
    }

    function addMemberBankAccountClosureRequest(requests) {
        return $http.post(`${apiUrl}/${baseRoute}/accounts/requests/closures`, requests);
    }

    function updateBankAccountStatus(bankId, memberId, status) {
        return $http.patch(`${apiUrl}/${baseRoute}/${bankId}/accounts/members/${memberId}/status`, status);
    }

    function getReissueTransactionsByClaimId(claimIds) {
        return $http.post(`${apiUrl}/${baseRoute}/nonredeemedpayment/claims`, claimIds);
    }

    function addFileToFileProcessing(fileName, fileType) {
        return $http.post(`${apiUrl}/${baseRoute}/files/add/${fileName}/${fileType}`);
    }

    // Utilities
    function getBankNameById(id) {
        return utilService.getKeyByValue(bankEnum, id);
    }

    return {
        // External API calls
        getTransactionsByClaimId,
        getCurrentBalanceTotalsByMemberIds,
        getExternalAccounts,
        getBanks,
        addMemberBankAccountCreationRequests,
        addMemberBankAccount,
        getMemberBanks,
        getBankAccountBalanceForMember,
        getBankCreationRequests,
        setDefaultBank,
        getBankTransactionResponses,
        updateBankTransactionResponseType,
        getFundsExhaustionEventTypes,
        getFundsExhaustionEventsByMemberId,
        addMemberBankAccountClosureRequest,
        updateBankAccountStatus,
        getTransactionsByClaimIds,
        getReissueTransactionsByClaimId,
        addFileToFileProcessing,

        // Utilities
        getBankNameById,
    };
}