angular
    .module('CareGuard')
    .service('payBillsService', payBillsService);

payBillsService.$inject = ['$http'];

function payBillsService($http) {

    this.getRXVendors = function() {

        return $http.get('api/finance/getrxvendors').then(res => res.Data);
    };

    this.getBatchPaymentClaims = function (request) {

        return $http.post('api/finance/getBatchPaymentClaims', request)
            .then(res => {

                return res.Data;
            });
    };

    this.getClaimPaymentClaims = function (request) {

        return $http.post('api/finance/getClaimPaymentClaims', request)
            .then(res => {

                return res.Data;
            });
    };

    this.getAmethystPaymentClaims = function (request) {

        return $http.post('api/finance/getAmethystPaymentClaims', request)
            .then(res => {

                return res.Data;
            });
    };
    
    this.payRxSelectedClaims = function (request) {

        return $http({
            method: 'POST',
            url: 'api/finance/payRxBills',
            data: request,
            responseType: 'arraybuffer'
        });
    };

    this.payNonRedeemedPaymentClaims = function (request) {
        return $http({
            method: 'POST',
            url: 'api/finance/nonRedeemedPayments',
            data: request,
            responseType: 'arraybuffer'
        });
    }

    this.payNonRxSelectedClaims = function (request) {

        return $http({
            method: 'POST',
            url: 'api/finance/payNonRxBills',
            data: request,
            responseType: 'arraybuffer'
        });
    };

    this.payAmethystNonRxSelectedClaims = function (request) {

        return $http({
            method: 'POST',
            url: 'api/finance/payAmethystNonRxBills',
            data: request,
            responseType: 'arraybuffer'
        });
    };

    this.exportClaims = function (request) {
        return $http({
            method: 'POST',
            url: 'api/finance/exportClaims',
            data: request,
            responseType: 'arraybuffer'
        });
    }

    this.exportReadyForReissueClaims = function (request) {
        return $http({
            method: 'POST',
            url: 'api/finance/exportReadyForReissueClaims',
            data: request,
            responseType: 'arraybuffer'
        });
    }

    this.generateBillPayReconciliationClaims = function (request) {
        return $http({
            method: 'POST',
            url: 'api/finance/generateBillPayReconciliationClaims',
            data: request,
            responseType: 'arraybuffer'
        });
    }
}
