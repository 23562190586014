/**
* @name contactGridModalController
* @desc contactgridModalController.
**/
(function () {
    'use strict';

    angular
        .module('CareGuard')
        .controller('contactGridModalController', contactGridModalController);

    /** Inject services into controller. */
    contactGridModalController.$inject = ['$timeout', 'LxDialogService', 'lookupService', 'contactGridService', 'companyGridService', '$toastr', 'contactService'];

    /**
    * @name contactGridModalController.
    * @desc contactgridModalController.
    */
    function contactGridModalController($timeout, LxDialogService, lookupService, contactGridService, companyGridService, $toastr, contactService) {
        var vm = this;

        // variables
        var dialogId = 'dlgContactModal';
        vm.isNew = true;
        vm.confirm = false;
        vm.contact = undefined;
        vm.original = undefined;
        vm.lookupdata = undefined;
        vm.companies = undefined;
        vm.contacts = undefined;

        // functions
        vm.save = save;
        vm.select = select;
        vm.remove = remove;

        init();

        /* 
        * @name init
        * @desc initilize service
        */
        function init() {
            vm.companies = companyGridService.getCompanies();
            vm.original = contactGridService.getContact();
            getcontacts();
            vm.lookupdata = {
                roles: lookupService.getByCategory('Contact Role')
            };

            if (!vm.original) {
                vm.contact = {};
                vm.isNew = true;
            }
            else {
                vm.contact = angular.copy(vm.original, {});
                vm.isNew = false;
            };
        };

        /* 
        * @name getcontacts
        * @desc get contacts for companies selected on the case. 
        */
        function getcontacts() {
            $timeout(function () {
                var Ids = Enumerable.from(vm.companies).where('$.Id').select('$.Id').toArray();
                contactService.getByLocation(Ids).then(function (result) {
                    vm.contacts = result;
                });
            }, 200);
        };

        /* 
        * @name select
        * @desc select contact. 
        * @param {object} val - selected object.
        */
        function select(val) {
            if (!val) {
                vm.contact.Id = undefined;
                vm.contact.CompanyName = undefined;
                vm.contact.LocationName = undefined;
                vm.contact.LocationName = FullLocationName;
                vm.contact.PrimaryPhone = undefined;
                vm.contact.AlternativePhone = undefined;
                vm.contact.Email = undefined;
                vm.contact.ContactName = undefined;
                return;
            }
            var obj = val.originalObject;
            vm.contact.Id = obj.Id;
            vm.contact.CompanyId = obj.CompanyId;
            vm.contact.LocationId = obj.LocationId;
            vm.contact.CompanyName = obj.CompanyName;
            vm.contact.LocationName = obj.LocationName;
            vm.contact.FullLocationName = obj.FullLocationName;
            vm.contact.PrimaryPhone = obj.PrimaryPhone;
            vm.contact.AlternativePhone = obj.AlternativePhone;
            vm.contact.Email = obj.Email;
            vm.contact.ContactName = obj.ContactName;
        };

        /* 
        * @name save
        * @desc update service with contact and close dialog
        */
        function save() {
            if (!vm.original) {
                vm.original = {};
                var contacts = contactGridService.getContacts();
                if (!contacts)
                    contacts = [];

                contacts.push(vm.original);
            };

            vm.original.Id = vm.contact.Id;
            vm.original.CompanyId = vm.contact.CompanyId;
            vm.original.LocationId = vm.contact.LocationId;
            vm.original.Role = vm.contact.Role;
            vm.original.ContactName = vm.contact.ContactName;
            vm.original.CompanyName = vm.contact.CompanyName;
            vm.original.LocationName = vm.contact.LocationName;
            vm.original.FullLocationName = vm.contact.FullLocationName;
            vm.original.Email = vm.contact.Email;
            vm.original.PrimaryPhone = vm.contact.PrimaryPhone;
            vm.original.AlternativePhone = vm.contact.AlternativePhone;
            vm.original.ReferralSource = vm.contact.ReferralSource;

            contactGridService.fireUpdateCallback();
            LxDialogService.close(dialogId);
        };

        /* 
        * @name remove
        * @desc remove contact from the case
        */
        function remove() {
            var contacts = contactGridService.getContacts();
            for (var key in contacts) {
                if (contacts[key].Id == vm.contact.Id) {
                    contacts.splice(contacts[key], 1);
                }
            };
            contactGridService.setContacts(contacts);
            contactGridService.fireUpdateCallback();
            LxDialogService.close(dialogId);
        };
    };
})();