/**
* @name chronovoFormatBoolean
* @desc Format boolean.
* @usage <input ng-model="" chronovo-format-boolean>Home</button>
**/
(function () {
    'use strict';

    angular
        .module('CareGuard')
        .directive('chronovoFormatBoolean', chronovoFormatBoolean);

    /** Inject services into directive. */
    chronovoFormatBoolean.$inject = ['$filter'];

    /**
    * @name chronovoFormatBoolean.
    * @desc Format boolean Directive.
    * @param {object} $filter - angularjs filter service.
    */
    function chronovoFormatBoolean($filter) {
        var directive = {
            restrict: 'A',
            require: 'ngModel',
            link: postLink
        };

        return directive;

        function postLink($scope, $element, $attrs, $controller) {
            $controller.$formatters.unshift(function (modelValue) {
                return $filter('chronovoYesNo')(modelValue);
            });

            $controller.$parsers.unshift(function (viewValue) {
                return $filter('chronovoYesNo')(modelValue);
            });
        };
    };
})();