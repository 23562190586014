/**
* @name toastrInterceptorService
* @desc Response Interceptor, shows a toastr with error/sucess message.
**/
(function () {
    'use strict';

    angular
        .module('CareGuard')
        .factory('toastrInterceptorService', toastrInterceptorService);

    /** Inject services into service. */
    toastrInterceptorService.$inject = ['$q', '$toastr'];

    /**
    * @name toastrInterceptorService
    * @desc Response Interceptor, shows a toastr with error/sucess message.
    **/
    function toastrInterceptorService($q, $toastr) {
        /**
        * @name response.
        * @desc response pipeline.
        * @param {object} response.
        */
        function response(response) {
          if (response && response.data && response.data.MsgText && response.data.Unwrap) {
                
                $toastr.show(response.data.MsgText, response.data.HasError === true ? 'error' : 'success');

                if (response.data.HasError) {
                    return $q.reject(response);
                }
            }
            
            if (response && response.data && response.data.Data) {
                if (response.data.Unwrap)
                    return response.data.Data;
                else
                    return response.data;
            }
            return response;
        }

        /**
        * @name responseError.
        * @desc response error pipeline.
        * @param {object} response.
        */
        function responseError(response) {
            if (response && response.data) {
                if (response.data.Message) {
                    showError(response.data.Message);
                }
                else if (response.data.error && response.data.error_description) {
                    showError(response.data.error_description);
                }
                else if (response.config.responseType === 'arraybuffer' && response.statusText) {
                    showError(response.statusText, { extendedTimeOut: 2000, timeOut: 5000 });
                }
                else if (response.status === 500 && (response.data.ExceptionMessage || response.data.Message)) {
                    showError(response.data.ExceptionMessage || response.data.Message);
                }
            }

            return $q.reject(response);

            function showError(message, config) {
                 $toastr.show(message, 'error', config);
            }
        }

        return {
            response,
            responseError
        }
    }
})();