/**
* @name chronovoAuthorize
* @desc hides element if not authorized
* @usage <div chronovo-authorize="'Broker,Adjuster'" ></div>
**/
(function () {
    'use strict';

    angular
        .module('CareGuard')
        .directive('chronovoAuthorize', chronovoAuthorize);

    /** Inject services into directive. */
    chronovoAuthorize.$inject = ['accountService', '$animate'];

    /**
    * @name chronovoAuthorize
    * @desc hides element if not authorized
    */
    function chronovoAuthorize(accountService, $animate) {
        var directive = {
            multiElement: true,
            transclude: 'element',
            priority: 600,
            terminal: true,
            restrict: 'A',
            $$tlb: true,
            link: postLink
        };

        return directive;

        function postLink($scope, $element, $attrs, $controller, $transclude) {
            var block, childScope, previousElements;

            // watch attribute change
            $scope.$watch($attrs.chronovoAuthorize, function (value) {
                if (!value) return;
                var isInRole = accountService.isInRole(value);
                if (isInRole) {
                    if (!childScope) {
                        $transclude(function (clone, newScope) {
                            childScope = newScope;
                            clone[clone.length++] = document.createComment(' end role: ' + $attrs.role + ' ');

                            // Note: We only need the first/last node of the cloned nodes.
                            // However, we need to keep the reference to the jqlite wrapper as it might be changed later
                            // by a directive with templateUrl when its template arrives.
                            block = {
                                clone: clone
                            };
                            $animate.enter(clone, $element.parent(), $element);
                        });
                    };
                } else {
                    if (previousElements) {
                        previousElements.remove();
                        previousElements = null;
                    };
                    if (childScope) {
                        childScope.$destroy();
                        childScope = null;
                    };
                    if (block) {
                        previousElements = getBlockNodes(block.clone);
                        $animate.leave(previousElements).then(function () {
                            previousElements = null;
                        });
                        block = null;
                    };
                };
            });
        };
    };
})();
