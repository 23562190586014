angular
    .module('CareGuard')
    .constant('billTypeId', {
        AccountClosure: 815,
        AnnuityRefund: 825,
        CMS1500: 211,
        Correspondence: 816,
        Dental: 213,
        DME: 365,
        HHC: 470,
        MemberWithdrawal: 618,
        ReceiptDME: 606,
        ReceiptHHC: 607,
        ReceiptOther: 533,
        ReceiptProvider: 608,
        ReceiptRX: 605,
        ReceiptTransportation: 817,
        Refund: 826,
        Rx: 21,
        TransferToMCP: 694,
        TransferToMSA: 693,
        UB04INPT: 366,
        UB04OUTPT: 212,
        Unknown: 367,
    });