/**
* @name userQuotaController
* @desc Quota Controller.
**/
(function () {
    'use strict';

    angular
        .module('CareGuard')
        .controller('userQuotaController', userQuotaController)

    /** Inject services into controller. */
    userQuotaController.$inject = ['$q', '$timeout', 'userService'];

    /**
    * @name userQuotaController.
    * @desc Quota Controller.
    * @param {object} $q - defer service.
    * @param {object} userService - user service.
    */
    function userQuotaController($q, $timeout, userService) {
        var vm = this;
        vm.user = undefined;
        vm.cancel = cancel;
        vm.userQuota = {};
        vm.userQuotaCopy = {};
        vm.min = undefined;
        vm.max = undefined;
        vm.save = save;
        vm.isEditing = false;
        vm.edit = edit;
        
        // variables


        // functions

        init();

        /* 
        * @name init
        * @desc initialize module, display Quotas for a user.
        */
        function init() {
            vm.user = userService.getSelected();
            if (!vm.user) return;
            getUserQuota();
        };

        /* 
       * @name getUserQuota
       * @desc update list of quota for the user.
       */
        function getUserQuota() {
            userService.getQuota(vm.user.Id).then(function (result) {
                result = Enumerable.from(result).orderBy('$.MonthNumber').toArray();
                vm.userQuota = result;
                vm.userQuotaCopy = angular.copy(vm.userQuota);
            });

        };

        function edit() {           
            vm.isEditing = true;
        };

        function cancel()
        {
            vm.isEditing = false;
            vm.userQuota = vm.userQuotaCopy;
        }

        function save()
        {
            var data = {
                Id: vm.user.Id,
                Version: vm.user.Version,
                Models: {
                    'Quota': vm.userQuota                   
                }
            };

            userService.saveQuota(data).then(function (result) {
                vm.user.Version = result.Version;
                userService.setSelected(vm.user);                            
            });
            vm.isEditing = false;
            
        }

    };
})();