/**
* @name chronographController
* @desc Contains logic for Quote page.
**/
(function () {
    'use strict';

    angular
        .module('CareGuard')
        .controller('chronographController', chronographController);

    /** Inject services into controller. */
    chronographController.$inject = ['$scope', '$q', '$toastr', '$filter', '$timeout', 'LxDialogService', 'LxProgressService', 'lookupService', 'layoutService', 'chronographService',
                                     'validationService', 'lifecompanyService', 'caseData', 'utilService', 'accountService'];

    /**
    * @name chronographController.
    * @desc chronograph Controller.
    * @param {object} caseData - case information.
    */
    function chronographController($scope, $q, $toastr, $filter, $timeout, LxDialogService, LxProgressService, lookupService, layoutService, chronographService,
                                   validationService, lifecompanyService, caseData, utilService, accountService) {
        var vm = this;

        // variables
        var dlgLockIn = 'dlgLockIn';
        var lifeCompanyData = undefined;
        vm.rules = {};
        vm.tooltips = undefined;
        vm.quote = undefined;
        vm.case = undefined;
        vm.lookupdata = undefined;
        vm.overwrite = true;
        vm.chart = { market: {}, schedule: {} };
        vm.wizard = undefined;
        vm.isExpired = undefined;
        vm.isDisabled = false;
        vm.canLockIn = false;
        vm.QuoteType = undefined;
        vm.selected = {
            modal: undefined,
            type: undefined,
            entity: undefined,
            index: undefined
        };

        // public functions
        vm.updateFunds = updateFunds;
        vm.selectQuote = selectQuote;
        vm.calcQuoteCost = calcQuoteCost;
        vm.selectCompany = selectCompany;
        vm.show = show;
        vm.close = close;
        vm.select = select;
        vm.add = add;
        vm.clone = clone;
        vm.remove = remove;
        vm.stepUp = stepUp;
        vm.stepDown = stepDown;
        vm.calcCost = calcCost;
        vm.saveQuote = saveQuote;
        vm.save = save;
        vm.refresh = refresh;
        vm.print = print;
        vm.confirmLockIn = confirmLockIn;
        vm.lockIn = lockIn;

        init();

        /* 
        * @name init
        * @desc initialize module
        */
        function init() {
            lifeCompanyData = lifecompanyService.get();
            vm.lookupdata = {
                quoteTypes: lookupService.getByCategory('Quote Type'),
                states: lookupService.getKeysByCategory('State'),
                benefitTypes: lookupService.getByCategory('Benefit Type'),
                taxBrackets: lookupService.getByCategory('Tax Bracket'),
                annuitantTypes: lookupService.getByCategory('Annuitant Type'),
                calculationTypes: [{ Key: 0, Value: 'Payment' }, { Key: 1, Value: 'Cost' }],
                annuityTypes: [],
                termTypes: [],
                companies: []
            };

            vm.case = caseData;
            layoutService.setSelected({ Name: vm.case.CaseName });
            initWatch();
            initTooltips();
            initChart();
            selectQuote();
            updateLifeCompanies();
            updateLockIn();                   
        };

        /* 
        * @name updateLockIn
        * @desc determine if Lock In button should show. 
        */
        function updateLockIn() {
            // determine if fields should be disabled
            // if status is LockedIn, Awaiting, Settled or Closed then Adjuster may not edit
            // if status is Settled or Closed then nobody may edit
            var isAdjuster = accountService.isInRole('Adjuster');
            vm.isDisabled = false;
            if (isAdjuster && (vm.case.Status == 'L' || vm.case.Status == 'A' || vm.case.Status == 'S') || vm.case.Status == 'C') {
                vm.isDisabled = true;
            } else if (vm.case.Status == 'S' || vm.case.Status == 'C') {
                vm.isDisabled = true;
            };

            // can the case locked in
            if (vm.case.Status == 'Q' || vm.case.Status == 'P') {
                vm.canLockIn = true;
            } else {
                vm.canLockIn = false;
            };
        };

        /* 
        * @name updateFunds
        * @desc updates the quote settlement amount. 
        */
        function updateFunds() {
            if (vm.quote) {
                vm.quote.SettlementAmount = vm.quote.SettlementAmountCopy;
            }
        };

        /* 
        * @name selectQuote
        * @desc updates IsSelected on the quotes to false except the given Id. 
        */
        function selectQuote(quoteId, keep) {
            // mark selected quote
            if (quoteId) {
                Enumerable.from(vm.case.Quotes).forEach(function (quote) {
                    quote.IsSelected = false;
                    if (quote.Id == quoteId) {
                        quote.IsSelected = true;
                    }
                });
            };
            vm.quote = Enumerable.from(vm.case.Quotes).where('$.IsSelected').firstOrDefault(undefined);
            vm.quote.SettlementAmountCopy = vm.quote.SettlementAmount;
            vm.isExpired = moment().isAfter(vm.quote.ExpirationDate);
            vm.QuoteType = Enumerable.from(vm.lookupdata.quoteTypes).where('$.Key == "' + vm.quote.QuoteType + '"').select('$.Value').firstOrDefault(undefined);
            updateBenefits();
            updateChart();
            
            // udpate totals on quote for display purposes
            var company = Enumerable.from(vm.quote.LifeCompanies).where('$.IsSelected').firstOrDefault(undefined);
            vm.quote.GuaranteedPayout = company ? company.GuaranteedPayout : 0;
            vm.quote.ExpectedPayout = company ? company.ExpectedPayout : 0;
            calcQuoteCost();
        };

        /* 
        * @name calcQuoteCost
        * @desc calculate quote cost, updates the Balance when slider values or textboxes change.
        */
        function calcQuoteCost() {
            vm.quote.TotalCost = Enumerable.from(vm.quote.Benefits).sum('parseFloat($.PremiumAmount) || 0');
            if (vm.quote.QuoteType != 'MSA') {
                vm.quote.TotalCost += parseFloat(vm.quote.AttorneyFees) || 0;
                vm.quote.TotalCost += parseFloat(vm.quote.LienAmount) || 0;
                vm.quote.TotalCost += parseFloat(vm.quote.CashAmount) || 0;
            } else {
                vm.quote.TotalCost += parseFloat(vm.quote.SeedAmount) || 0;
            };
        };

        /* 
        * @name initWatch
        * @desc initialize variable watch
        */
        function initWatch() {

            // watch annuity type change and update name
            $scope.$watch('vm.selected.entity.AnnuityType', function (newVal, oldVal) {
                if (newVal == oldVal) return;
                if (!vm.selected || !vm.selected.entity) return;
                var benefit = vm.selected.entity;
                var annuityTypes = Enumerable.from(vm.lookupdata.annuityTypes).where('$.Key == "' + benefit.AnnuityType + '"').firstOrDefault(undefined);
                benefit.AnnuityTypeName = annuityTypes == undefined ? '' : annuityTypes.Value;
            });

            // watch calculation type and clear field
            // 0 : Payment, 1: Cost
            //$scope.$watch('vm.selected.entity.CalculationType', function (newVal, oldVal) {
            //    if (newVal == undefined) return;
            //    if (parseInt(newVal) == 0) vm.selected.entity.PaymentAmount = undefined;
            //    if (parseInt(newVal) == 1) vm.selected.entity.PremiumAmount = undefined;
            //});

            // watch quote type change 
            // CalculationType => 0 : Payment, 1: Cost
            $scope.$watch('vm.wizard.quote.QuoteType', function (newVal, oldVal) {
                if (newVal == undefined) return;
                if (!vm.selected || !vm.selected.entity || !vm.selected.entity.CalculationType) return;

                if (newVal == 'MSA') {
                    vm.selected.entity.CalculationType = 1;
                } else {
                    vm.selected.entity.CalculationType = 0;
                };
            });

            // listen for slider change and update total cost
            $scope.$on('slideEnded', function () {
                $timeout(function () {
                    calcQuoteCost();
                },200);
            });

            // watch annuitant selection on a benefit and udpate age
            $scope.$watch('vm.selected.entity.AnnuitantId', function (Id, oldId) {
                if (Id == undefined) return;
                var annuitant = Enumerable.from(vm.wizard.case.Annuitants).where('$.Id == "' + Id + '"').firstOrDefault(undefined);
                vm.selected.entity.AnnuitantName = annuitant == undefined ? '' : annuitant.Name;
                vm.selected.entity.BirthDate = annuitant == undefined ? undefined : annuitant.BirthDate;
            });
        };

        /* 
        * @name initTooltips
        * @desc initialize tooltips
        */
        function initTooltips() {
            vm.tooltips = {
                StartDate: '<strong>Start Date</strong><div>Start of the Benefit.</div><br/><strong>Age</strong><div>Age of the Annuitant at the time of the Benefit Start.</div><br/>\
                            Either enter the Start Date or the Age and system will automatically calculate the other.',
                AnnuityType: '<strong>Period Certain</strong>\
                               <div>Payments are for a guaranteed period only, not for a lifetime.</div><br/>\
                               <strong>Life Period Certain</strong>\
                               <div>Payments are for the lifetime of the claimant with a guarantee period.</div><br/>\
                               <strong>Life Only</strong>\
                               <div>Payments are only for the lifetime of the claimant, with no guarantee period and cease upon the death of the claimant.</div><br/>\
                               <strong>Temporary Life</strong>\
                               <div>Payments are life contingent with no guarantee, but end with the earlier of the claimants\'s death, or the last scheduled payment.</div><br/>\
                               <strong>Guaranteed Lump Sum</strong>\
                               <div>The lump sum benefit is payable on a specific date, regardless of whether the claimant is living at the time.'
            }

            vm.tooltips.ratings = [];
            for (var key in lifeCompanyData) {
                var comp = lifeCompanyData[key];
                var html = "<table><tbody>";
                html += "<tr><td class='text-grey text-bold text-right border-right'>AM Best</td><td class='text-primary'><strong>" + comp.Ratings.AMBest + "</strong></td></tr>";
                html += "<tr><td class='text-grey text-bold text-right border-right'>Moody's</td><td class='text-primary'><strong>" + comp.Ratings.Moody + "</strong></td></tr>";
                html += "<tr><td class='text-grey text-bold text-right border-right'>Fitch</td><td class='text-primary'><strong>" + comp.Ratings.Fitch + "</strong></td></tr>";
                html += "<tr><td class='text-grey text-bold text-right border-right'>Standard & Poor</td><td class='text-primary'><strong>" + comp.Ratings.StandardPoor + "</strong></td></tr>";
                html += "</tbody></table>";
                vm.tooltips.ratings[comp.Id] = html;
            };
        };

        /* 
        * @name initChart
        * @desc initialize chart module
        */
        function initChart() {
            vm.chart = { analysis: {}, schedule: {}, analysisprint: {}, scheduleprint: {} };
            vm.chart.analysis = {
                api: {},
                type: 'LineChart',
                displayed: true,
                options: {
                    title: 'Structure vs. Market',
                    titleTextStyle: {
                        color: '#333435',
                        bold: false
                    },
                    isStacked: 'true',
                    chartArea: {
                        bottom: 0,
                        width: '700px',
                        height: '250px'
                    },
                    displayExactValues: false,
                    legend: {
                        textStyle: {
                            color: '#333435',
                            fontSize: 10
                        },
                        alignment: 'center'
                    },
                    vAxis: {
                        format: '$#,###k',
                        minorGridlines: { count: 3 }
                    },
                    hAxis: {
                        gridlines: { count: 10 },
                        title: 'Future Years',
                        maxAlternation: 1
                    },
                    animation: {
                        duration: 500,
                        easing: 'out',
                        startup: true
                    },
                    colors: ['#39BB9D', '#F05B4F']
                }
            };

            vm.chart.schedule = {
                api: {},
                type: 'ColumnChart',
                isStacked: true,
                displayed: true,
                options: {
                    title: 'Structure Benefit Schedule',
                    titleTextStyle: {
                        color: '#333435',
                        bold: false
                    },
                    isStacked: 'true',
                    chartArea: {
                        bottom: 0,
                        width: '700px',
                        height: '250px'
                    },
                    legend: {
                        textStyle: {
                            color: '#333435',
                            fontSize: 10
                        },
                        alignment: 'center'
                    },
                    vAxis: {
                        format: '$#,###k',
                        minorGridlines: { count: 3 },
                        viewWindowMode: 'pretty'
                    },
                    hAxis: {
                        gridlines: { count: 10 },
                        title: 'Future Years',
                        maxAlternation: 1
                    },
                    animation: {
                        duration: 500,
                        easing: 'out',
                        startup: true
                    },
                    colors: ['#00B9E7', '#F37021']
                }
            };
        };

        /* 
        * @name updateBenefits
        * @desc update benefits with extra fields for display purposes
        */
        function updateBenefits(benefits) {
            var annuityTypeLookup = lookupService.getByCategory('Annuity Type');
            var termTypeLookup = lookupService.getByCategory('Term Type');
            if (!benefits) {
                benefits = vm.quote.Benefits;
            }
            for (var i = 0; i < benefits.length; i++) {
                var benefit = benefits[i];
                var lc = Enumerable.from(lifeCompanyData).where('$.Id == "' + benefit.CompanyId + '"').firstOrDefault(undefined);
                var annuitant = Enumerable.from(vm.case.Annuitants).where('$.Id == "' + benefit.AnnuitantId + '"').firstOrDefault(undefined);
                var annuityTypes = Enumerable.from(annuityTypeLookup).where('$.Key == "' + benefit.AnnuityType + '"').firstOrDefault(undefined);
                var termTypes = Enumerable.from(termTypeLookup).where('$.Key == "' + benefit.TermType + '"').firstOrDefault(undefined);
                benefit.CompanyName = lc == undefined ? '' : lc.Name;
                benefit.AnnuitantName = annuitant == undefined ? '' : annuitant.Name;
                benefit.BirthDate = annuitant == undefined ? undefined : annuitant.BirthDate;
                benefit.AnnuityTypeName = annuityTypes == undefined ? '' : annuityTypes.Value;
                benefit.TermTypeName = termTypes == undefined ? '' : termTypes.Value;
                benefit.CalculationType = benefit.CalculationType ? benefit.CalculationType : '0';
                benefits[i] = benefit;
            };
        };

        /* 
        * @name updateLifeCompanies
        * @desc update lifecompany list with names and rules.
        */
        function updateLifeCompanies() {
            if (!vm.quote.LifeCompanies) return;

            var annuityLookup = lookupService.getByCategory('Annuity Type');
            var termLookup = lookupService.getByCategory('Term Type');
            var annuityTypes = [];
            var termTypes = [];
            vm.lookupdata.annuityTypes = [];
            vm.lookupdata.termTypes = [];

            // update life companies for the wizard from the approved panel
            var companies = [];
            lifecompanyService.getPanel(vm.case.CompanyId).then(function (panel) {
                for (var key in panel) {
                    var lc = panel[key];
                    lc.validation = { error: false };
                    companies.push(lc);

                    // list of annuity types based on users role
                    if (lc.Rules && lc.Rules.AnnuityTypes) {
                        Enumerable.from(lc.Rules.AnnuityTypes).forEach(function (val) {
                            if (accountService.isInRoleById(val.Roles.join())) {
                                annuityTypes = annuityTypes.concat(val.Id);
                            }
                        });
                    };

                    // list of Term Types
                    if (lc.Rules && lc.Rules.TermTypes) {
                        termTypes = termTypes.concat(lc.Rules.TermTypes);
                    };
                };

                // common lookup values
                Enumerable.from(annuityLookup).forEach(function (val) {
                    if (annuityTypes.indexOf(val.Id) >= 0) {
                        vm.lookupdata.annuityTypes.push(val);
                    }
                });
                Enumerable.from(termLookup).forEach(function (val) {
                    if (termTypes.indexOf(val.Id) >= 0) {
                        vm.lookupdata.termTypes.push(val);
                    }
                });

                // sort company names for showing
                vm.lookupdata.companies = Enumerable.from(companies).orderBy('$.Name').toArray();
            });
        };

        /* 
        * @name updateChart
        * @desc update chart data.
        */
        function updateChart() {
            if (vm.quote.Analysis) {
                vm.chart.analysis.data = [['Year', 'Structure', { type: 'string', role: 'tooltip' }, 'Market', { type: 'string', role: 'tooltip' }]];
                var min = undefined;
                var max = undefined;
                for (var key in vm.quote.Analysis) {
                    var item = vm.quote.Analysis[key];
                    var obj = [];
                    obj.push(item.Label);
                    obj.push(item.Values.Structure / 1000);
                    obj.push($filter('currency')(item.Values.Structure, '$', 0));
                    obj.push(item.Values.Market / 1000);
                    obj.push($filter('currency')(item.Values.Market, '$', 0));
                    vm.chart.analysis.data.push(obj);
                    if (!min) min = item.Label;
                    max = item.Label;
                };
                vm.chart.analysis.options.hAxis.gridlines.count = vm.quote.Analysis.length / 2;
                vm.chart.analysis.options.hAxis.title = 'Years (20' + min + ' - 20' + max + ')';
            }

            if (vm.quote.Schedule) {
                vm.chart.schedule.data = [['Year', 'Guaranteed', { type: 'string', role: 'tooltip' }, 'Expected', { type: 'string', role: 'tooltip' }]];
                var min = undefined;
                var max = undefined;
                var maxval = 0;
                for (var key in vm.quote.Schedule) {
                    var item = vm.quote.Schedule[key];
                    var obj = [];
                    obj.push(item.Label);
                    obj.push(item.Values.Guaranteed / 1000);
                    obj.push($filter('currency')(item.Values.Guaranteed, '$', 0));
                    obj.push(item.Values.Expected / 1000);
                    obj.push($filter('currency')(item.Values.Expected, '$', 0));
                    vm.chart.schedule.data.push(obj);
                    if (!min) min = item.Label;
                    max = item.Label;

                    if (maxval < item.Values.Guaranteed / 1000) {
                        maxval = item.Values.Guaranteed / 1000;
                    }
                    if (maxval < item.Values.Expected / 1000) {
                        maxval = item.Values.Expected / 1000;
                    }
                };
                vm.chart.schedule.options.hAxis.gridlines.count = vm.quote.Schedule.length / 2;
                vm.chart.schedule.options.hAxis.title = 'Years (20' + min + ' - 20' + max + ')';
                vm.chart.schedule.options.vAxis.viewWindow = { max: maxval + maxval * .2 };

                vm.chart.analysisprint = angular.copy(vm.chart.analysis, {});
                vm.chart.scheduleprint = angular.copy(vm.chart.schedule, {});
            };
        };

        /* 
        * @name selectCompany
        * @desc select life company that's passed in and unselect any others
        */
        function selectCompany(comp) {
            for (var key in vm.lookupdata.companies) {
                var obj = vm.lookupdata.companies[key];
                if (comp.Id != obj.Id) {
                    obj.IsSelected = false;
                };
            };
            calcCost();
        };

        /* 
        * @name show
        * @desc shows Annuitant/Benefit modal
        */
        function show(form, modal, type) {
            if (type != 'Save' && type != 'Assumptions' && type != 'Details' && type != 'Wizard' && type != 'Refresh') {
                return;
            };

            LxDialogService.open(modal);
            vm.selected = {
                modal: modal,
                type: type
            };

            if (type != 'Wizard' && type != 'Refresh') return;
            vm.overwrite = true;
            vm.wizard = {
                step: type == 'Refresh' ? 3 : 1,
                'case': angular.copy(vm.case, {}),
                quote: angular.copy(vm.quote, {})
            };
            
            select(0);
        };

        /* 
        * @name close
        * @desc close dialog if there are no errors
        */
        function close() {
            if (vm.selected && vm.selected.modal) {
                LxDialogService.close(vm.selected.modal);
            };
        };

        /* 
        * @name select
        * @desc set selected entity unless creating new entity.
        */
        function select(index, entity, form) {
            var modal = vm.selected.modal;
            var type = vm.selected.type;
            var oldindex = vm.selected.index;

            // add error object for the selected item, if any, before selecting next item
            // need to validate before moving to next step in wizard
            if (form && oldindex != undefined) {
                var obj = undefined;
                if (vm.wizard.step == 1) obj = vm.wizard.case.Annuitants[oldindex];
                if (vm.wizard.step == 2) obj = vm.wizard.quote.Benefits[oldindex];
                if (!obj.validation) obj.validation = {};
                obj.validation.error = form.$invalid;
                form.setAttempted(true);

                if (form.$invalid) {
                    $toastr.show('Please fix any errors.', 'error');
                    return;
                }
            };
            
            if (!entity) {
                if (vm.wizard.step == 1 && vm.wizard.case.Annuitants[index]) {
                    entity = vm.wizard.case.Annuitants[index];
                }
                if (vm.wizard.step == 2 && vm.wizard.quote.Benefits[index]) {
                    entity = vm.wizard.quote.Benefits[index];
                }
            }

            vm.selected = {
                modal: modal,
                type: type,
                entity: entity,
                index: index
            };
        };

        /* 
        * @name add
        * @desc add new annuitant or benefit. Adds an empty element to the object.
        */
        function add() {
            var modal = vm.selected.modal;
            var type = vm.selected.type;
            var entity = {};

            if (vm.wizard.step == 1) {
                var index = vm.wizard.case.Annuitants.length;

                // generate MonogoDb Id
                var objectId = ObjectId();
                entity.Id = objectId.toString();
                vm.wizard.case.Annuitants.push(entity);
            } else if (vm.wizard.step == 2) {
                var index = vm.wizard.quote.Benefits.length;
                vm.wizard.quote.Benefits.push(entity);
            }

            vm.selected = {
                modal: modal,
                type: type,
                entity: entity,
                isNew: true,
                index: index
            };
        };

        /* 
        * @name clone
        * @desc clone annuitant or benefit.
        */
        function clone() {
            if (!vm.selected || !vm.selected.entity) return;

            var modal = vm.selected.modal;
            var type = vm.selected.type;
            var entity = angular.copy(vm.selected.entity, {});

            if (vm.wizard.step == 1) {
                var index = vm.wizard.case.Annuitants.length;

                // generate MonogoDb Id
                var objectId = ObjectId();
                entity.Id = objectId.toString();
                vm.wizard.case.Annuitants.push(entity);
            } else if (vm.wizard.step == 2) {
                var index = vm.wizard.quote.Benefits.length;
                entity.Id = undefined;
                vm.wizard.quote.Benefits.push(entity);
            }

            vm.selected = {
                modal: modal,
                type: type,
                entity: entity,
                isNew: true,
                index: index
            };
        };

        /* 
        * @name remove
        * @desc remove annuitant or benefit. At least 1 annuitant or benefit is required.
        */
        function remove() {
            var index = vm.selected.index;
            var type = vm.selected.type;
            if (index == undefined) return;

            if (vm.wizard.step == 1) {
                if (vm.wizard.case.Annuitants.length == 1) {
                    $toastr.show('At least one Annuitant is required.', 'error');
                    return;
                } else {
                    // remove annuitant only if its not selected on any benefits
                    var id = vm.selected.entity.Id;

                    for (var i = 0; i < vm.wizard.case.Quotes.length; i++) {
                        var quote = vm.wizard.case.Quotes[i];
                        for (var b = 0; b < quote.Benefits.length; b++) {
                            var benefit = quote.Benefits[b];
                            if (benefit.AnnuitantId == id) {
                                $toastr.show('One or more benefit(s) is assigned to this annuitant. Please adjust benefit accordingly before deleting.', 'error');
                                return;
                            }
                        }
                    };

                    vm.wizard.case.Annuitants.splice(index, 1);
                };
            } else if (vm.wizard.step == 2) {
                if (vm.wizard.quote.Benefits.length == 1) {
                    $toastr.show('At least one Benefit is required.', 'error');
                    return;
                } else {
                    vm.wizard.quote.Benefits.splice(index, 1);
                }
            };

            select(0);
        };

        /* 
        * @name stepDown
        * @desc decrease wizard step.
        */
        function stepDown(form) {
            form.setPristine();
            vm.wizard.step = vm.wizard.step - 1;
            if (vm.wizard.step < 1) {
                vm.wizard.step = 1;
            } else if (vm.wizard.step == 2) {
                updateBenefits(vm.wizard.quote.Benefits);
            };
            
            select(0);
        };

        /* 
        * @name stepUp
        * @desc advance wizard step, check for errors.
        */
        function stepUp(form) {
            if (form) {
                form.setAttempted(true);
            };

            // validate form all entites
            if (vm.wizard.quote.QuoteType == 'MSA') {
                var hasError = form != undefined ? form.$invalid : false;
            } else if (vm.selected && vm.selected.entity) {
                var entities = vm.wizard.step == 1 ? vm.wizard.case.Annuitants : vm.wizard.quote.Benefits
                var hasError = Enumerable.from(entities).where('$.validation && $.validation.error && $.Id != "' + vm.selected.entity.Id + '"').select('$.validation.error').firstOrDefault(false);
                hasError = form != undefined ? form.$invalid || hasError : hasError;
            };

            if (hasError) {
                $toastr.show('Please fix any errors before continuing.', 'error');
                return;
            };

            // advance to next step
            vm.wizard.step = vm.wizard.step + 1;
            if (vm.wizard.step > 3) vm.wizard.step = 3;
            select(0);

            // Set calculation type to Payment when it's and Adjuster
            // this is needed in case a Broker has calculated Cost on this quote, adjuster won't be able to change Cost.
            // CalculationType => 0 : Payment, 1: Cost
            if (vm.wizard.step != 1 && accountService.isInRole('Adjuster')) {
                for (var key in vm.wizard.quote.Benefits) {
                    var benefit = vm.wizard.quote.Benefits[key];
                    benefit.CalculationType = 0;
                }
            };

            // get quote on step 3
            if (vm.wizard.step == 3) {
                getQuote();
            };
        };

        /* 
        * @name getQuote
        * @desc validate benefit input before moving to step 3
        *       if there is at least 1 life company without error then run the quote, otherwise display message to user (cann't run against any LC)
        */
        function getQuote() {
            vm.wizard.hasError = false;

            // validate and determine if quote can be run against at least 1 LC
            validationService.validateAll({ 'case': vm.wizard.case, companies: vm.lookupdata.companies, quote: vm.wizard.quote, validateAmount: false });
            vm.wizard.canQuote = false;
            vm.wizard.isSearching = true;
            Enumerable.from(vm.lookupdata.companies).forEach(function (company) {
                if (company.validation && company.validation.error) {
                    company.IsSelected = false;
                } else {
                    vm.wizard.canQuote = true
                    company.IsSelected = true;
                }
            });

            // if at least 1 LC availabe then get quote, otherwise show msg
            if (!vm.wizard.canQuote) {
                vm.wizard.isSearching = true;
                return;
            };

            // show progress circle
            $timeout(function () {
                LxProgressService.circular.show('#5fa2db', '#progress');
            }, 150);
            
            // clear object from benefits 
            Enumerable.from(vm.wizard.quote.Benefits).forEach(function (benefit) {
                benefit.validation = undefined;
                benefit.LifeCompanies = undefined;
            });

            // add pre-validated LC's to the quote, software will only run against LC's that have IsSelected = true
            addLifeCompanies(vm.wizard.quote);

            // only send the active quote, no need to send all quotes
            vm.wizard.case.Quotes = undefined;
            vm.wizard.case.Quotes = [];
            vm.wizard.case.Quotes.push(vm.wizard.quote);

            // get quote, and re-validate
            // there is only a single quote in the result, b/c we removed the others
            chronographService.getQuote(vm.wizard.case).then(function (result) {
                vm.wizard.case = result;
                vm.wizard.quote = result.Quotes[0];
                calcPrice();
            }, function () {
                vm.wizard.hasError = true;
            })
            .finally(function () {
                vm.wizard.isSearching = false;
                $timeout(function () {
                    LxProgressService.circular.hide();
                }, 250);
            });
        };

        /* 
        * @name calcPrice
        * @desc calculate best price
        */
        function calcPrice() {
            // update lookupdata companies with results
            // used in validation service
            Enumerable.from(vm.lookupdata.companies).forEach(function (comp) {
                var lc = Enumerable.from(vm.wizard.quote.LifeCompanies).where('$.Id == "' + comp.Id + '"').firstOrDefault(undefined);
                comp.PaymentAmount = lc ? lc.PremiumAmount : 0;
                comp.PremiumAmount = lc ? lc.PremiumAmount : 0;
                comp.GuaranteedPayout = lc ? lc.GuaranteedPayout : 0;
                comp.ExpectedPayout = lc ? lc.ExpectedPayout : 0;
                comp.IRR = lc ? lc.IRR : undefined;
            });

            // validate underwriting rules
            validationService.validateAll({ 'case': vm.wizard.case, companies: vm.lookupdata.companies, quote: vm.wizard.quote, validateAmount: true });

            // check valid companies
            var companies = [];
            Enumerable.from(vm.lookupdata.companies).forEach(function (comp) {
                comp.IsSelected = false;
                if (comp.validation && comp.validation.error) {
                    comp.PaymentAmount = undefined;
                    comp.PremiumAmount = undefined;
                    comp.GuaranteedPayout = undefined;
                    comp.ExpectedPayout = undefined;
                } else {
                    comp.PaymentAmount = 0;
                    comp.PremiumAmount = 0;
                    comp.GuaranteedPayout = 0;
                    comp.ExpectedPayout = 0;

                    // total all benefit amounts
                    Enumerable.from(vm.wizard.quote.Benefits).ForEach(function (benefit) {
                        var lc = Enumerable.from(benefit.LifeCompanies).where('$.Id == "' + comp.Id + '"').firstOrDefault(undefined);
                        if (lc) {
                            comp.PaymentAmount += lc.PaymentAmount;
                            comp.PremiumAmount += lc.PremiumAmount;
                            comp.GuaranteedPayout += lc.GuaranteedPayout;
                            comp.ExpectedPayout += lc.ExpectedPayout;
                        };
                    });

                    companies.push(comp);
                }
            });

            if (!companies || companies.length == 0) {
                vm.wizard.canQuote = false;
                return;
            };

            // select best life company
            // if there is a default then that's the best 
            // otherwise for MSA best price is lowest cost
            vm.wizard.bestCompanies = [];
            var enabledCompanies = Enumerable.from(companies).where('$.IsEnabled');
            var defaultCompany = Enumerable.from(enabledCompanies).where('$.IsDefault').firstOrDefault(undefined);
            if (defaultCompany != undefined) {
                bestCompany = defaultCompany;
                bestCompany.IsSelected = true;
                vm.wizard.bestCompanies.push(bestCompany);
            }
            else if (vm.wizard.quote.QuoteType == 'MSA' && enabledCompanies != undefined) {
                var bestCompany = Enumerable.from(enabledCompanies).minBy('$.PremiumAmount');
                bestCompany.IsSelected = true;
                vm.wizard.bestCompanies.push(bestCompany);
            } else if (enabledCompanies != undefined) {
                // if there are benefits calculating cost then select lowest cost company
                // if there are benefits calculating payment then select company that pays higest
                // 0 : Payment, 1: Cost
                var byCost = Enumerable.from(vm.wizard.quote.Benefits).where('$.CalculationType == 1').count();
                var byPayment = Enumerable.from(vm.wizard.quote.Benefits).where('$.CalculationType == 0').count();
                if (byCost > 0) {
                    var bestCompany = Enumerable.from(enabledCompanies).minBy('$.PremiumAmount');
                    bestCompany.IsSelected = true;
                    vm.wizard.bestCompanies.push(bestCompany);
                };
                if (byPayment > 0) {
                    var bestCompany = Enumerable.from(enabledCompanies).maxBy('$.GuaranteedPayout');
                    bestCompany.IsSelected = true;
                    vm.wizard.bestCompanies.push(bestCompany);
                };
            };

            // calculate total cost
            calcCost();
        };

        /* 
        * @name calcCost
        * @desc calculate total cost based on best life companies
        */
        function calcCost() {
            vm.wizard.quote.TotalCost = 0;
            vm.wizard.quote.TotalCash = 0;
            if (vm.wizard.quote.QuoteType != 'MSA') {
                vm.wizard.quote.TotalCost += vm.wizard.quote.AttorneyFees || 0;
                vm.wizard.quote.TotalCost += vm.wizard.quote.LienAmount || 0;
                vm.wizard.quote.TotalCost += vm.wizard.quote.CashAmount || 0;
            } else {
                vm.wizard.quote.TotalCost += vm.wizard.quote.SeedAmount || 0;
            };
            vm.wizard.quote.TotalCash = vm.wizard.quote.TotalCost;
            vm.wizard.quote.TotalCost += Enumerable.from(vm.lookupdata.companies).where('$.IsSelected').sum('$.PremiumAmount || 0');
            vm.wizard.quote.TotalCost = vm.wizard.quote.TotalCost || 0;
        };

        /* 
        * @name generate
        * @desc generate quote from wizard
        */
        function saveQuote() {
            // ensure at least one Life company is selected
            var count = Enumerable.from(vm.lookupdata.companies).count('$.IsSelected');
            if (count <= 0) {
                $toastr.show('Must select at least one Life Company.', 'error');
                return;
            };
            if (count > 1) {
                $toastr.show('Must select only one Life Company.', 'error');
                return;
            };
            if (vm.wizard.quote.SettlementAmount - vm.wizard.quote.TotalCost < 0) {
                $toastr.show('You have exceeded your Available funds limit. Please increase the Funds or lower the Benefit amounts before continuing.', 'error');
                return;
            };

            var selectedCompany = Enumerable.from(vm.lookupdata.companies).where('$.IsSelected').firstOrDefault(undefined);

            // keep a copy, in case there is an error saving so we can reset.
            vm.caseCopy = angular.copy(vm.case, {});

            // add quote to the case
            addQuote(selectedCompany);

            // add life companies to the quote
            addLifeCompanies(vm.wizard.quote);
            vm.case.Annuitants = vm.wizard.case.Annuitants;

            // clear benefit Id's if adding as new quote, new Id's will be created in db
            // add selected LC's amounts to each benefit
            Enumerable.from(vm.wizard.quote.Benefits).forEach(function (benefit) {
                benefit.Id = vm.overwrite == true ? benefit.Id : undefined;
                benefit.validation = undefined;

                var comp = Enumerable.from(benefit.LifeCompanies).where('$.Id == "' + selectedCompany.Id + '"').firstOrDefault(undefined);
                if (comp) {
                    benefit.CompanyId = comp.Id;
                    benefit.PaymentAmount = comp.PaymentAmount;
                    benefit.PremiumAmount = comp.PremiumAmount;
                    benefit.GuaranteedPayout = comp.GuaranteedPayout;
                    benefit.ExpectedPayout = comp.ExpectedPayout;
                } else {
                    benefit.CompanyId = undefined;
                    benefit.PaymentAmount = undefined;
                    benefit.PremiumAmount = undefined;
                    benefit.GuaranteedPayout = undefined;
                    benefit.ExpectedPayout = undefined;
                };
                
                // for MSA case calculate Availabe Funds, should be seed amount + benefit premium
                // otherwise clear MSA amount field
                if (vm.wizard.quote.QuoteType != 'MSA') {
                    benefit.MSAAmount = undefined;
                };
            });
            
            // save
            return chronographService.save(vm.case).then(function (result) {
                vm.case = result;
                vm.caseCopy = undefined;
                close();
                selectQuote(undefined, true);
            }, function () {
                // error handler
                vm.case = angular.copy(vm.caseCopy, {});
                vm.caseCopy = undefined;
                vm.wizard.hasError = true;
            });
        };

        /* 
        * @name addQuote
        * @desc update wizard's quote and add to the case for saving to db
        * @param {object} selectedCompany - selected Life Company
        */
        function addQuote(selectedCompany) {
            // add expiration date
            var expdays = 7;
            if (selectedCompany.Rules && selectedCompany.Rules.QuoteExpiration) {
                expdays = selectedCompany.Rules.QuoteExpiration;
            }
            vm.wizard.quote.QuoteDate = moment().format('MM/DD/YYYY');
            vm.wizard.quote.ExpirationDate = moment().add(expdays, 'day').format('MM/DD/YYYY');
            
            // set data from wizard to the actual case
            // for MSA only quote delete all other benefits except the first
            if (vm.wizard.quote.QuoteType == 'MSA') {
                var benefit = vm.wizard.quote.Benefits[0];
                vm.wizard.quote.Benefits = [];
                vm.wizard.quote.Benefits.push(benefit);
                vm.wizard.quote.AttorneyFees = undefined;
                vm.wizard.quote.LeinAmount = undefined;
            } else {
                vm.wizard.quote.SeedAmount = undefined;
            };

            // add quote as new, set currently selected quote to false and new quote to selected = true
            if (vm.overwrite == true) {
                vm.wizard.quote.IsSelected = true;
                for (var i = 0; i < vm.case.Quotes.length; i++) {
                    if (vm.case.Quotes[i].Id == vm.wizard.quote.Id) {
                        vm.case.Quotes[i] = vm.wizard.quote;
                        break;
                    }
                };
            } else {
                vm.quote.IsSelected = false;
                vm.wizard.quote.IsSelected = true;
                vm.wizard.quote.Id = undefined;
                vm.wizard.quote.Number = undefined;
                vm.wizard.quote.Name = vm.case.CaseName + ' - ' + (vm.case.Quotes.length + 1);
                vm.case.Quotes.push(vm.wizard.quote);
            };
        };

        /* 
        * @name addLifeCompanies
        * @desc add Life Companies to the given quote
        */
        function addLifeCompanies(quote) {
            quote.LifeCompanies = [];
            Enumerable.from(vm.lookupdata.companies).forEach(function (comp) {
                quote.LifeCompanies.push({
                    Id: comp.Id,
                    Type: comp.Type,
                    IsAssigned: comp.IsAssigned,
                    IsSelected: comp.IsSelected,
                    PaymentAmount: comp.PaymentAmount,
                    PremiumAmount: comp.PremiumAmount,
                    GuaranteedPayout: comp.GuaranteedPayout,
                    ExpectedPayout: comp.ExpectedPayout,
                    IRR: comp.IRR
                });
            });
        };

        /* 
        * @name save
        * @desc save case information, if overwrite then create a new quote otherwise overwrite existing
        */
        function save() {
            // clear un-needed data
            Enumerable.from(vm.quote.LifeCompanies).forEach(function (comp) {
                comp.Ratings = undefined;
                comp.Rules = undefined;
            });

            // commit to db and close dialog
            return chronographService.save(vm.case).then(function (result) {
                vm.case = result;
                selectQuote(undefined, true);
                close();
            });
        };

        /* 
        * @name refresh
        * @desc get/refresh a quote
        */
        function refresh(form) {
            // show modal
            show(form, 'dlgWizard', 'Refresh');
            stepUp(form);
            vm.wizard.isRefresh = true;
        };

        /* 
        * @name printQuote
        * @desc print a quote
        */
        function print() {
            var quote = angular.copy(vm.quote, {});
            quote.AnalysisChartURI = vm.chart.analysisprint.api.export();
            quote.ScheduleChartURI = vm.chart.scheduleprint.api.export();
            var data = angular.copy(vm.case, {});
            data.Quotes = [];
            data.Quotes.push(quote);
            var options = {
                method: 'POST',
                url: '/api/chronograph/proposal',
                data: data
            };
            utilService.download(options).then(function () {
                data = undefined;
            });
        };

        /* 
        * @name confirmLockIn
        * @desc confirm quote lock in.
        */
        function confirmLockIn() {
            LxDialogService.open(dlgLockIn);
        };

        /* 
        * @name lockIn
        * @desc quote lock in.
        */
        function lockIn() {
            var status = vm.case.Status;
            Enumerable.from(vm.case.Quotes).forEach(function (quote) {
                quote.IsLocked = false;
                if (quote.IsSelected == true) {
                    quote.IsLocked = true;
                }
            });
            vm.case.Status = 'L';
            vm.case.StatusDate = new Date();

            // commit to db and close dialog
            return chronographService.save(vm.case).then(function (result) {
                vm.case = result;
                updateLockIn();
                LxDialogService.close(dlgLockIn);
            }, function () {
                vm.case.Status = status;
            });
        };
    };
})();