/**
* @name globalContactController
* @desc Global Contact Controller.
**/
(function () {
    'use strict';

    angular
        .module('CareGuard')
        .controller('globalContactController', globalContactController)

    /** Inject services into controller. */
    globalContactController.$inject = ['companyService', 'locationService', 'contactService'];

    /**
    * @name globalContactController.
    * @desc Global Contact Controller.
    * @param {object} companyService - company service.
    * @param {object} locationService - location service.
    * @param {object} contactService - contact service.
    */
    function globalContactController(companyService, locationService, contactService) {
        var vm = this;

        vm.company = undefined;
        vm.locations = undefined;
        vm.contacts = {
            selected: undefined,
            searchBy: undefined,
            searchByAttribute: undefined,
            currentPage: 1,
            pageSize: 15,
            itemCount: 1,
            items: []
        };
        vm.clear = clear;
        vm.search = search;
        vm.get = get;
        vm.select = select;

        init();

        /* 
        * @name init
        * @desc initialize module
        */
        function init() {
            get();
            contactService.setNewCallback(add);
            contactService.setRemoveCallback(remove);
        };

        /* 
        * @name clear
        * @desc Clear search bar and refresh list.
        */
        function clear() {
            vm.contacts.currentPage = 1;
            vm.contacts.searchByAttribute = undefined;
            vm.contacts.searchBy = undefined;
            get();
        };

        /* 
        * @name search
        * @desc Search contacts using the input string.
        */
        function search(filterBy) {
            vm.contacts.currentPage = 1;
            vm.contacts.searchByAttribute = filterBy;
            get();
        };

        /* 
        * @name get
        * @desc Get a list of contacts to display. 
        */
        function get() {
            var params = {
                pageSize: vm.contacts.pageSize,
                pageNum: vm.contacts.currentPage,
                sort: 'ContactName',
                sortDir: 'asc',
                filter: {}
            };

            // add filter if exists
            if (vm.contacts.searchByAttribute) {
                params.filter[vm.contacts.searchByAttribute] = vm.contacts.searchBy;
            }

            // refresh results from db
            contactService.get(params).then(function (data) {
                vm.contacts.items = data.Items;
                vm.contacts.itemCount = data.ItemCount;
                if (vm.contacts.items.length > 0)
                    select(0, vm.contacts.items[0]);
            });
        };

        /* 
        * @name select
        * @desc Select the Active contact. 
        * @param {int} index - index of contact in contact.items array.
        * @param {object} contact - contact object.
        */
        function select(index, contact) {
            contact.Index = index;
            vm.contacts.selected = contact;
            contactService.setSelected(contact);
        };

        /* 
        * @name add
        * @desc callback function when new contact is added. 
        * @param {object} contact - new contact.
        */
        function add(contact) {
            if (!contact || contact.Index < 0) {
                vm.contacts.selected = undefined;
                vm.isNew = true;
                return;
            };

            var index = contact.Index || 0;
            if (contact.IsNew === true) {
                vm.contacts.items.unshift(contact);
            }
            else {
                vm.contacts.items[index] = contact;
            }
            vm.isNew = false;
            select(index, contact);
            contactService.setSelected(contact);
        };
        
        /* 
        * @name remove
        * @desc callback function when new contact is removed. 
        * @param {object} contact - deleted contact.
        */
        function remove(contact) {
            if (contact && contact.Index >= 0) {
                vm.contacts.items.splice(contact.Index, 1);
                select(0, vm.contacts.items[0]);
            }
        };
    };
})();