/**
* @name lookupService
* @desc Lookup Service.
**/
(function () {
    'use strict';

    angular
        .module('CareGuard')
        .factory('lookupService', lookupService);

    /** Inject services into service. */
    lookupService.$inject = ['$http', '$q', '$cache'];

    /**
    * @name lookupService.
    * @desc Lookup Service.
    */
    function lookupService($http, $q, $cache) {
        var baseUrl = '/api/lookup/';
        var key = 'lookupService.lookupData';

        var service = {
            cache: cache,
            getByCategory: getByCategory,
            getKeysByCategory: getKeysByCategory,
            getValuesByCategory: getValuesByCategory,
            getDefaultKey: getDefaultKey,
            getDefaultValue: getDefaultValue,
            getLookUp: getLookUp,
            getLookUpsBatch: getLookUpsBatch
        };

        return service;
        
        /**
        * @name cache
        * @desc get all lookups values and saves them to cache
        */
        function cache() {
            var dfd = $q.defer();
            $http.get(baseUrl + 'get').then(successFunc, errorFunc);

            function successFunc(result) {
                $cache.put(key, result);
                dfd.resolve(result);
            }

            function errorFunc() {
                dfd.resolve();
            }

            return dfd.promise;
        }

        /**
        * @name getByCategory.
        * @desc return sorted lookup by category.
        * @param {string} category - category to search.
        */
        function getByCategory(category) {
          var data = $cache.get(key);
            return Enumerable.from(data)
                             .where("$.Category == '" + category + "'")
                             .orderBy("$.Sort")
                             .toArray();
        }


        function getLookUp(category) {
            return $http.get(baseUrl + 'getLookUp/' + category);
            
        }

        function getLookUpsBatch (req) {
            return $http.post(baseUrl + 'getLookUpsBatch/', req)
        }
      
        /**
        * @name getKeysByCategory.
        * @desc return sorted lookup by category.
        * @param {string} category - category to search.
        */
        function getKeysByCategory(category) {
            var data = $cache.get(key);
            return Enumerable.from(data)
                             .Where("$.Category == '" + category + "'")
                             .OrderBy('$.Sort')
                             .Select('$.Key')
                             .ToArray();
        }

        /**
        * @name getValuesByCategory.
        * @desc return sorted lookup by category.
        * @param {string} category - category to search.
        */
        function getValuesByCategory(category) {
            var data = $cache.get(key);
            return Enumerable.from(data)
                             .where("$.Category == '" + category + "'")
                             .orderBy('$.Sort')
                             .select('$.Value')
                             .toArray();
        }

        /**
        * @name getDefaultKey.
        * @desc return default key.
        * @param {string} category - category to search.
        */
        function getDefaultKey(category) {
            var data = $cache.get(key);
            return Enumerable.from(data)
                             .where("$.Category == '" + category + "' && $.IsDefault == true")
                             .select('$.Key')
                             .firstOrDefault(undefined);
        }

        /**
        * @name getDefaultValue.
        * @desc return default value.
        * @param {string} category - category to search.
        */
        function getDefaultValue(category) {
            var data = $cache.get(key);
            return Enumerable.from(data)
                             .where("$.Category == '" + category + "' && $.IsDefault == true")
                             .select('$.Value')
                             .firstOrDefault(undefined);
        }
    }
})();
