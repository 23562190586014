angular
    .module('CareGuard')
    .directive('scrollIntoView', scrollIntoView);

scrollIntoView.$inject = ['$timeout'];

function scrollIntoView($timeout) {
    return {
        restrict: 'A',
        link: function (scope, elm, attr) {
            $timeout(function () {
                var elem = ((elm && elm[0]));
                if (elem) elem.scrollIntoView();
            }, 0);
        }
    }
}