/**
* @name formsService
* @desc member Service.
**/
(function () {
    'use strict';

    angular
        .module('CareGuard')
        .factory('formsService', formsService);

    /** Inject services into service. */
    formsService.$inject = ['$http'];

    /**
    * @name contactService.
    * @desc contact Service.
    */
    function formsService($http) {
      var baseUrl = '/api/forms/';
       
      var service = {
        getFormLetters: getFormLetters,
        selectForm: selectForm
      };

       
      return service;

       
      function getFormLetters(formType) {
        return $http.get(baseUrl + 'getFormLetters/' + formType);
      };
      
      function selectForm(memberID, memberNumber, selectedFormID) {
        return $http.get(baseUrl + 'selectForm/' + memberID + '/' + memberNumber + '/' + selectedFormID)
      };

    };
})();