angular
    .module('CareGuard')
    .directive('confirmationPopup', confirmationPopup);

confirmationPopup.$inject = ['LxDialogService', '$q'];

function confirmationPopup(LxDialogService, $q) {
    const confirmationPopupDialogIdPrefix = "confirmationPopupDialogId";

    return {
        restrict: 'A',
        scope: {
            title: '=?confirmationPopupTitle',
            text: '=?confirmationPopupText',
            completeAction: '&ngClick'
        },
        transclude: true,
        link: {
            pre: (scope, element) => {

                const defaultTitle = 'Confirmation';
                const defaultText = 'Are you sure you want to perform this action?';

                const confirmationPopupDialogId = confirmationPopupDialogIdPrefix + scope.$id;

                scope.confirmationPopupDialogId = confirmationPopupDialogId;

                scope.title = scope.title || defaultTitle;
                scope.text = scope.text || defaultText;

                scope.setOpenFunction = setOpenFunction;

                element.on('click touchstart', function (e) {
                    e.stopImmediatePropagation();
                    e.preventDefault();

                    scope.openFunction();
                });

                function setOpenFunction(openFunction) {
                    scope.openFunction = openFunction;
                }
            }
        },
        templateUrl: 'app/careguard/directives/confirmationPopup/confirmationPopup.html'
    };
}
