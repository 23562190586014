/**
* @name ValidationService
* @desc validation Service.
**/
(function () {
    'use strict';

    angular
        .module('CareGuard')
        .factory('validationService', validationService);

    /** Inject services into directive. */
    validationService.$inject = ['$filter', 'accountService', 'lookupService'];

    /**
    * @name validationService.
    * @desc validation Service.
    * @param {object} $filter - angular filter service.
    */
    function validationService($filter, accountService, lookupService) {
        var rules = undefined;
        var termTypeLookup = lookupService.getByCategory('Term Type');
        var service = {
            validate: validate,
            validateAll: validateAll
        };

        return service;

        /**
        * @name validate
        * @desc validate against underwriting rules
        * @param {object} vm - validation object
        */
        function validate(vm) {
            if (!vm) return;
            if (!vm.benefit) return;

            vm.benefit.validation = {};
            checkBenefit(vm.benefit);
            return;
        };

        /**
        * @name validateAll
        * @desc validate all benefits against underwriting rules
        * @param {object} vm - validation object
        */
        function validateAll(vm) {
            if (!vm) return;
            if (!vm.case || !vm.companies || !vm.quote) return;
            
            var benefits = vm.quote.Benefits;
            var annuitants = vm.case.Annuitants;
            Enumerable.from(vm.companies).forEach(function (company) {
                company.validation = {
                    error: false,
                    tooltip: ''
                };

                // validate case
                if (company.Rules) {
                    validateCase(company, vm.case);
                };

                // validate annuitants 
                if (annuitants && company.Rules) {
                    validateAge(company, annuitants);
                };

                // validate benefits
                if (benefits && company.Rules) {
                    validateDeferral(company, vm.case, vm.quote, benefits);
                    validateTerm(company, vm.case, vm.quote, benefits);
                    validateAnnuityType(company, vm.case, vm.quote, benefits);
                    validateCOLA(company, vm.case, vm.quote, benefits);

                    // validate amounts only if true or there is no error in validation 
                    if (vm.validateAmount && !company.validation.error) {
                        validateAmounts(company, vm.case, vm.quote, benefits);
                    }
                };

                // smart quote
                //if (company.Rules && vm.smartQuote) {
                //    validateSmartQuote(company, vm.smartQuote);
                //};
            });
        };
        
        /**
        * @name checkBenefit
        * @desc check Term and COLA and determine which fields should be visible
        */
        function checkBenefit(benefit) {
            // COLA not required for Lump Sum
            if (isLumpSum(benefit)) {
                benefit.COLA = undefined;
                benefit.validation.COLA = { disabled: true };
            }

            // Term not required for life only
            if (isLifeOnly(benefit)) {
                benefit.TermYears = undefined;
                benefit.TermMonths = undefined;
                benefit.TermWeeks = undefined;
                benefit.validation.TermYears = { disabled: true };
                benefit.validation.TermMonths = { disabled: true };
                benefit.validation.TermWeeks = { disabled: true };
                return;
            };

            // Term should be Annually for Lump Sum
            if (isLumpSum(benefit)) {
                benefit.TermType = 'A';
                benefit.TermTypeAsString = 'Annually';
                benefit.TermYears = 1;
                benefit.TermMonths = undefined;
                benefit.TermWeeks = undefined;
                benefit.validation.TermType = { disabled: true };
                benefit.validation.TermYears = { disabled: true };
                benefit.validation.TermMonths = { disabled: true };
                benefit.validation.TermWeeks = { disabled: true };
                return;
            };

            // validate TermType and TermMonths            
            if (benefit.TermType == 'A') {
                benefit.TermMonths = undefined;
                benefit.TermWeeks = undefined;
                benefit.validation.TermMonths = { disabled: true };
                benefit.validation.TermWeeks = { disabled: true };
            }
            else if (benefit.TermType == 'S') {
                benefit.TermWeeks = undefined;
                benefit.validation.TermWeeks = { disabled: true };
                var months = parseInt(benefit.TermMonths);
                benefit.validation.Term = {
                    error: months && months != 6,
                    msg: 'Month must be blank or 6'
                };
            }
            else if (benefit.TermType == 'Q') {
                benefit.TermWeeks = undefined;
                benefit.validation.TermWeeks = { disabled: true };
                var months = parseInt(benefit.TermMonths);
                benefit.validation.Term = {
                    error: months && months % 3 != 0,
                    msg: 'Month must be 3, 6 or 9'
                };
            }
            else if (benefit.TermType == 'M') {
                benefit.TermWeeks = undefined;
                benefit.validation.TermWeeks = { disabled: true };
            }
            else if (benefit.TermType == 'B' && benefit.TermWeeks) {
                var weeks = parseInt(benefit.TermWeeks);
                benefit.validation.Term = {
                    error: weeks % 2 != 0,
                    msg: 'Weeks must be even number'
                };
            }

            var termYears = !benefit.TermYears ? 0 : benefit.TermYears;
            var termMonths = !benefit.TermMonths ? 0 : benefit.TermMonths;
            var termWeeks = !benefit.TermWeeks ? 0 : benefit.TermWeeks;
            if (termYears <= 0 && termMonths <= 0 && termWeeks <= 0) {
                benefit.validation.Term = {
                    error: true,
                    msg: 'Term is required'
                };
            }
            else if (termMonths && termMonths >= 12) {
                benefit.validation.Term = {
                    error: true,
                    msg: 'Month must be less than 12'
                };
            }
            else if (termWeeks && termWeeks >= 52) {
                benefit.validation.Term = {
                    error: true,
                    msg: 'Week must be less than 52'
                };
            };
        };
        
        /**
        * @name validateCase
        * @desc validate case attributes
        */
        function validateCase(company, cs) {
            if (company.Rules.NonQualCases == undefined) return;
            if (company.Rules.NonQualCases == false && cs.IsQualified) {
                company.validation.error = true;
                addRule(company, true, 'Non Qualified cases not accepted');
            };
        };

        /**
        * @name validateAge
        * @desc validate annuitant age
        */
        function validateAge(company, annuitants) {
            company.validation.age = {};
            if (!company.Rules.MaxAge) return;

            company.validation.maxAge = {
                error: false,
                msg: 'Age must not exceed ' + company.Rules.MaxAge
            };

            // check for each annuitant
            Enumerable.from(annuitants).forEach(function (annuitant) {
                var error = annuitant.Age > company.Rules.MaxAge;
                company.validation.error = error || company.validation.error;
                company.validation.maxAge.error = error;
            });
            addRule(company, company.validation.maxAge.error, company.validation.maxAge.msg);
        };

        /**
        * @name validateDeferral
        * @desc validate deferral rules for a benefits
        */
        function validateDeferral(company, cs, quote, benefits) {
            // MSA Only quote does not show Start Date, it's auotmatically calculated
            if (quote.QuoteType == 'MSA') return;

            var hasLumpSum = false;
            var purchaseDate = moment(cs.PurchaseDate);
            var minDeferral = purchaseDate.add(company.Rules.MinDeferral, 'day').format('MM/DD/YYYY');
            var minLumpSumDeferral = purchaseDate.add(company.Rules.MinLumpSumDeferral, 'month').format('MM/DD/YYYY');
            var maxLumpSumDeferral = purchaseDate.add(company.Rules.MaxLumpSumDeferral, 'year').format('MM/DD/YYYY');
            company.validation.minLumpSumDeferral = {
                error: false,
                msg: 'Start Date for Lump Sum must be on or after ' + minLumpSumDeferral
            };
            company.validation.maxLumpSumDeferral = {
                error: false,
                msg: 'Start Date for Lump Sum must not exceed ' + maxLumpSumDeferral
            };
            company.validation.minDeferral = {
                error: false,
                msg: 'Benefits must start on or after ' + minDeferral
            };

            // check each benefit
            Enumerable.from(benefits).forEach(function (benefit) {
                // default StartDate 
                //var startDate;
                //if (benefit.TermType == 'A') {
                //    startDate = moment(cs.PurchaseDate).add(1, 'year').format('MM/DD/YYYY');
                //} else if (benefit.TermType == 'S') {
                //    startDate = moment(cs.PurchaseDate).add(6, 'month').format('MM/DD/YYYY');
                //} else if (benefit.TermType == 'Q') {
                //    startDate = moment(cs.PurchaseDate).add(3, 'month').format('MM/DD/YYYY');
                //} else if (benefit.TermType == 'M') {
                //    startDate = moment(cs.PurchaseDate).add(1, 'month').format('MM/DD/YYYY');
                //} else if (benefit.TermType == 'B') {
                //    startDate = moment(cs.PurchaseDate).add(2, 'week').format('MM/DD/YYYY');
                //} else if (benefit.TermType == 'W') {
                //    startDate = moment(cs.PurchaseDate).add(1, 'week').format('MM/DD/YYYY');
                //};

                // Lump Sump validation               
                if (isLumpSum(benefit)) {
                    hasLumpSum = true;

                    // Min Deferral 
                    if (new Date(benefit.StartDate) < new Date(minLumpSumDeferral)) {
                        company.validation.error = true;
                        company.validation.minLumpSumDeferral.error = true;
                    };

                    // Max Deferral
                    if (new Date(benefit.StartDate) > new Date(maxLumpSumDeferral)) {
                        company.validation.error = true;
                        company.validation.maxLumpSumDeferral.error = true;
                    };
                }
                else {
                    // Min Deferral
                    if (new Date(benefit.StartDate) < new Date(minDeferral)) {
                        company.validation.error = true;
                        company.validation.minDeferral.error = true;
                    };
                };
            });

            // deferral accross all benefits
            var deferral = moment(cs.PurchaseDate).add(company.Rules.MaxDeferral, 'year').format('MM/DD/YYYY');
            company.validation.maxDeferral = {
                error: false,
                msg: 'At least one Benefit must start by ' + deferral
            };
            var minDate = Enumerable.from(benefits).min('new Date($.StartDate)');
            if (new Date(minDate) > new Date(deferral)) {
                company.validation.error = true;
                company.validation.maxDeferral.error = true;
            };

            // add rules
            if (company.Rules.MaxDeferral) {
                addRule(company, company.validation.maxDeferral.error, company.validation.maxDeferral.msg);
            };
            if (company.Rules.MinDeferral) {
                addRule(company, company.validation.minDeferral.error, company.validation.minDeferral.msg);
            };
            if (hasLumpSum && company.Rules.MinLumpSumDeferral) {
                addRule(company, company.validation.minLumpSumDeferral.error, company.validation.minLumpSumDeferral.msg);
            };
            if (hasLumpSum && company.Rules.MaxLumpSumDeferral) {
                addRule(company, company.validation.maxLumpSumDeferral.error, company.validation.maxLumpSumDeferral.msg);
            };
        };
        
        /**
        * @name validateSmartQuote
        * @desc validate premium and payout rules for a smart quote
        */
        //function validateSmartQuote(company, smartQuote) {
        //    company.validation.minPremiumAmount = {
        //        error: false,
        //        msg: 'Structure amount must be at least ' + toCurrency(company.Rules.MinPremiumAmount)
        //    };
        //    company.validation.maxPremiumAmount = {
        //        error: false,
        //        msg: 'Structure amount must not exceed ' + toCurrency(company.Rules.MaxPremiumAmount)
        //    };

        //    if (company.Rules.MinPremiumAmount && smartQuote.StructureAmount && smartQuote.StructureAmount < company.Rules.MinPremiumAmount) {
        //        company.validation.error = true;
        //        company.validation.minPremiumAmount.error = true;
        //    };
        //    if (company.Rules.MaxPremiumAmount && smartQuote.StructureAmount && smartQuote.StructureAmount > company.Rules.MaxPremiumAmount) {
        //        company.validation.error = true;
        //        company.validation.maxPremiumAmount.error = true;
        //    };

        //    // add rule
        //    if (company.Rules.MinPremiumAmount) {
        //        addRule(company, company.validation.minPremiumAmount.error, company.validation.minPremiumAmount.msg);
        //    };
        //    if (company.Rules.MaxPremiumAmount) {
        //        addRule(company, company.validation.maxPremiumAmount.error, company.validation.maxPremiumAmount.msg);
        //    };
        //};

        /**
        * @name validateAmounts
        * @desc validate premium and payout rules for a benefit
        */
        function validateAmounts(company, cs, quote, benefits) {
            var isMSA = quote.QuoteType == 'MSA';
            var hasLumpSum = false;
            var totalCost = 0;

            // default messages for a company
            if (!isMSA) {
                company.validation.minPaymentAmount = {
                    error: false,
                    msg: 'Payment must be at least ' + toCurrency(company.Rules.MinPaymentAmount)
                };
                company.validation.maxLumpSumAmount = {
                    error: false,
                    msg: 'Lump Sum Payment must not exceed ' + toCurrency(company.Rules.MaxLumpSumAmount)
                };
                company.validation.minPremiumAmount = {
                    error: false,
                    msg: 'Cost must be at least ' + toCurrency(company.Rules.MinPremiumAmount)
                };
                company.validation.maxPremiumAmount = {
                    error: false,
                    msg: 'Cost must not exceed ' + toCurrency(company.Rules.MaxPremiumAmount)
                };
            } else {
                company.validation.minPremiumAmount = {
                    error: false,
                    msg: 'MSA Amount must be at least ' + toCurrency(company.Rules.MinPremiumAmount)
                };
                company.validation.maxPremiumAmount = {
                    error: false,
                    msg: 'MSA Amount must not exceed ' + toCurrency(company.Rules.MaxPremiumAmount)
                };
            }
            
            // check for each benefit
            // Calculation Type: 0 = Payment, 1 = Cost
            Enumerable.from(benefits).forEach(function (benefit) {
                var comp = Enumerable.from(benefit.LifeCompanies).where('$.Id == "' + company.Id + '"').firstOrDefault(undefined);
                if (comp) {
                    totalCost += comp.PremiumAmount || 0;
                    var payment = parseFloat(comp.PaymentAmount) || 0;

                    // MSA only quote, only shows the Cost
                    if (isMSA) {                     
                        company.validation.minPremiumAmount = {
                            error: false,
                            msg: 'MSA Amount must be at least ' + toCurrency(company.Rules.MinPaymentAmount * benefit.TermYears)
                        };
                        if (payment < company.Rules.MinPaymentAmount) {
                            company.validation.error = true;
                            company.validation.minPremiumAmount.error = true;
                        };
                    }
                    else {
                        // Min Payment
                        if (payment < company.Rules.MinPaymentAmount) {
                            company.validation.error = true;
                            company.validation.minPaymentAmount.error = true;
                        };

                        // Max Payment Amount for Lump Sum
                        if (isLumpSum(benefit)) {
                            hasLumpSum = true;
                            if (payment > company.Rules.MaxLumpSumAmount) {
                                company.validation.error = true;
                                company.validation.maxLumpSumAmount.error = true;
                            };
                        };
                    };
                };
            });

            // validate cost
            // Min Premium Amount
            if (!isMSA && totalCost < company.Rules.MinPremiumAmount) {
                company.validation.error = true;
                company.validation.minPremiumAmount.error = true;
            };

            // Max Premium Amount
            if (totalCost > company.Rules.MaxPremiumAmount) {
                company.validation.error = true;
                company.validation.maxPremiumAmount.error = true;
            };
            
            // add rules
            if (!isMSA && company.Rules.MinPaymentAmount) {
                addRule(company, company.validation.minPaymentAmount.error, company.validation.minPaymentAmount.msg);
            };
            if (company.Rules.MinPremiumAmount) {
                addRule(company, company.validation.minPremiumAmount.error, company.validation.minPremiumAmount.msg);
            };
            if (company.Rules.MaxPremiumAmount) {
                addRule(company, company.validation.maxPremiumAmount.error, company.validation.maxPremiumAmount.msg);
            };
            if (hasLumpSum && company.Rules.MaxLumpSumAmount) {
                addRule(company, company.validation.maxLumpSumAmount.error, company.validation.maxLumpSumAmount.msg);
            };
        };
                
        /**
        * @name validateAnnuityType
        * @desc validate Annuity Type field
        */
        function validateAnnuityType(company, cs, quote, benefits) {
            // MSA Only quote limits the Annuity Types, it's auotmatically calculated
            if (quote.QuoteType == 'MSA') return;

            company.validation.minLumpSumCount = {};
            if (!company.Rules.MinLumpSumCount) return;

            company.validation.minLumpSumCount = {
                error: false,
                msg: 'At least ' + company.Rules.MinLumpSumCount + ' Lump Sums are required'
            };

            // minimum number of Lump Sums
            var count = 0;
            Enumerable.from(benefits).forEach(function (benefit) {
                if (isLumpSum(benefit)) {
                    count++;
                };
            });
            
            var error = company.Rules && company.Rules.MinLumpSumCount && count > 0 && count < company.Rules.MinLumpSumCount ? true : false;
            if (error) {
                company.validation.error = true;
                company.validation.minLumpSumCount.error = true;
            };

            // Some companies do not require more than 1 Lump Sum
            if (company.Rules && company.Rules.MinLumpSumCount && company.Rules.MinLumpSumCount > 1 && count > 0) {
                addRule(company, company.validation.minLumpSumCount.error, company.validation.minLumpSumCount.msg);
            };
        };
                
        /**
        * @name validateTerm
        * @desc validate guarantee period terms
        */
        function validateTerm(company, cs, quote, benefits) {
            // MSA Only quote does not show Term, it's auotmatically calculated
            if (quote.QuoteType == 'MSA') return;

            var hasTerm = false;
            company.validation.maxGuarantee = {
                error: false,
                msg: 'Guarantee period must not exceed ' + company.Rules.MaxGuarantee + ' years'
            };
            company.validation.minTerm = {
                error: false,
                msg: 'Term must be at least ' + company.Rules.MinTerm + ' year(s)'
            };
            company.validation.maxTerm = {
                error: false,
                msg: 'Term must not exceed ' + company.Rules.MaxTerm + ' years'
            };
            company.validation.Term = {
                error: false,
                msg: []
            };

            // validate each benefit
            for (var key in benefits) {
                var benefit = benefits[key];

                // Is Term offered
                if (company.Rules.TermTypes && benefit.TermType) {
                    var term = Enumerable.from(termTypeLookup).where('$.Key == "' + benefit.TermType + '"').firstOrDefault(undefined);
                    var exists = Enumerable.from(company.Rules.TermTypes).where('$ == "' + term.Id + '"').firstOrDefault(undefined);
                    if (!exists) {
                        company.validation.error = true;
                        company.validation.Term.error = true;
                        company.validation.Term.msg.push(term.Value);
                    }
                };

                // Term not required for life only or Lump sum
                if (isLifeOnly(benefit) || isLumpSum(benefit)) {
                    continue;
                };                

                var termYears = !benefit.TermYears ? 0 : benefit.TermYears;
                var termMonths = !benefit.TermMonths ? 0 : benefit.TermMonths;
                var termWeeks = !benefit.TermWeeks ? 0 : benefit.TermWeeks;

                // company rules
                var maxDeferral = moment().add(company.Rules.MaxGuarantee, 'year').format('MM/DD/YYYY');
                var deferral = moment(cs.PurchaseDate).add(termYears, 'year').add(termMonths, 'month').add(termWeeks, 'week').format('MM/DD/YYYY');
                var max = moment(cs.PurchaseDate).add(company.Rules.MaxGuarantee, 'year').format('MM/DD/YYYY');
                if (new Date(deferral) > new Date(maxDeferral)) {
                    // Max guarantee period from purchase date
                    company.validation.error = true;
                    company.validation.maxGuarantee.error = true;
                }
                else if (isPeriodCertain(benefit) || isTempLife(benefit)) {
                    hasTerm = true;
                    var term = (parseFloat(benefit.TermYears) || 0) + (parseFloat(benefit.TermMonths) / 12 || 0) + (parseFloat(benefit.TermWeeks) / 52 || 0);
                    if (term < company.Rules.MinTerm) {
                        // Min Period Certain Duration
                        company.validation.error = true;
                        company.validation.minTerm.error = true;
                    }
                    else if (term > company.Rules.MaxTerm) {
                        // Max Period Certain Duration
                        company.validation.error = true;
                        company.validation.maxTerm.error = true;
                    };
                };
            };

            // add rules
            if (company.Rules.MaxGuarantee) {
                addRule(company, company.validation.maxGuarantee.error, company.validation.maxGuarantee.msg);
            };
            if (company.Rules.MinTerm && hasTerm) {
                addRule(company, company.validation.minTerm.error, company.validation.minTerm.msg);
            };
            if (company.Rules.MaxTerm && hasTerm) {
                addRule(company, company.validation.maxTerm.error, company.validation.maxTerm.msg);
            };
            if (company.Rules.TermTypes && company.validation.Term.error) {
                addRule(company, company.validation.Term.error, company.validation.Term.msg.join('') + ' term(s) not accepted');
            };
        };

        /**
        * @name validateCOLA
        * @desc validate COLA fields
        */
        function validateCOLA(company, cs, quote, benefits) {
            // MSA Only quote does not show COLA, it's auotmatically calculated
            if (quote.QuoteType == 'MSA') return;

            company.validation.maxCOLA = {};
            if (!company.Rules.MaxCOLAPercent) return;

            company.validation.maxCOLA = {
                error: false,
                msg: 'COLA must be less than ' + company.Rules.MaxCOLAPercent + ' %'
            };

            // check for each benefit
            Enumerable.from(benefits).forEach(function (benefit) {
                // COLA not needed for Lump Sum
                if (!isLumpSum(benefit) && parseInt(benefit.COLA) > company.Rules.MaxCOLAPercent) {
                    company.validation.error = true;
                    company.validation.maxCOLA.error = true;
                };
            });

            // add rule
            if (company.Rules.MaxCOLAPercent) {
                addRule(company, company.validation.maxCOLA.error, company.validation.maxCOLA.msg);
            }
        };

        /**
        * @name refreshRules
        * @desc refresh rules fro companies
        */
        //function refreshRules(companies) {
        //    rules = {};
            
        //    // Max Age
        //    rules.MaxAge = Enumerable.From(companies).Max('$.Rules ? $.Rules.MaxAge : 1');

        //    // Min Deferral
        //    rules.MinDeferral = Enumerable.From(companies).Min('$.Rules ? $.Rules.MinDeferral : 100');
            
        //    // Max Deferral
        //    rules.MaxDeferral = Enumerable.From(companies).Max('$.Rules ? $.Rules.MaxDeferral : 0');

        //    // Min Lump Sum Deferral
        //    rules.MinLumpSumDeferral = Enumerable.From(companies).Min('$.Rules ? $.Rules.MinLumpSumStart : 100');

        //    // Max Lump Sum Deferral
        //    rules.MaxLumpSumDeferral = Enumerable.From(companies).Max('$.Rules ? $.Rules.MaxLumpSumDeferral : 0');

        //    // Min Payment
        //    rules.MinPaymentAmount = Enumerable.From(companies).Min('$.Rules ? $.Rules.MinPaymentAmount : 10000');

        //    // Max Payment Amount for Lump Sum
        //    rules.MaxLumpSumAmount = Enumerable.From(companies).Max('$.Rules ? $.Rules.MaxLumpSumAmount : 100');

        //    // Min Premium Amount
        //    rules.MinPremiumAmount = Enumerable.From(companies).Min('$.Rules ? $.Rules.MinPremiumAmount : 10000');

        //    // Max Premium Amount
        //    rules.MaxPremiumAmount = Enumerable.From(companies).Max('$.Rules ? $.Rules.MaxPremiumAmount : 100');

        //    // Min Lump Sum Count
        //    rules.MinLumpSumCount = Enumerable.From(companies).Min('$.Rules ? $.Rules.MinLumpSumCount : 100');

        //    // Max guarantee period from purchase date
        //    rules.MaxGuarantee = Enumerable.From(companies).Max('$.Rules ? $.Rules.MaxGuarantee : 1');

        //    // Min Period Certain Duration
        //    rules.MinTerm = Enumerable.From(companies).Min('$.Rules ? $.Rules.MinTerm : 1000');

        //    // Max Period Certain Duration
        //    rules.MaxTerm = Enumerable.From(companies).Max('$.Rules ? $.Rules.MaxTerm : 1');

        //    // Max COLA percent
        //    rules.MaxCOLAPercent = Enumerable.From(companies).Max('$.Rules ? $.Rules.MaxCOLAPercent : 0');
        //};

        /**
        * @name addRule
        * @desc adds validation rule to life company
        */
        function addRule(company, error, msg) {
            if (!company.validation) company.validation = { tooltip: ''};

            if (error) {
                company.validation.tooltip += '<div class="text-danger"><i class="margin-right-5 mdi mdi-2x mdi-close"></i>' + msg + ' </div>';
            } else {
                company.validation.tooltip += '<div class=""><i class="margin-right-5 mdi mdi-2x mdi-check text-success"></i>' + msg + ' </div>';
            }
        };

        /**
        * @name calculateFees
        * @desc calculate Fees for each life company
        */
        function calculateFees(companies, benefits, quote) {
            if (!quote) return;

            quote.FeeAmount = 0;
            
            Enumerable.from(benefits).distinct('$.CompanyId').forEach(function (item) {
                var company = Enumerable.from(companies).where('$.Id == "' + item.CompanyId + '"').firstOrDefault(undefined);
                
                // Assignment Fee
                if (company && company.Rules && company.Rules.AssignmentFee) {
                    quote.FeeAmount += company.Rules.AssignmentFee;
                }

                // Small Case Fee
                if (company && company.Rules && company.Rules.SmallCaseAmount) {
                    var premium = Enumerable.from(benefits).sum('$.PremiumAmount');
                    if (parseFloat(premium) < parseFloat(company.Rules.SmallCaseAmount)) {
                        quote.FeeAmount += company.Rules.SmallCaseAmount;
                    }
                };
            });
        };

        /**
        * @name toCurrency
        * @desc formats value as currency
        */
        function toCurrency(amt) {
            var currency = $filter('currency');
            return currency(amt, '$', 0);
        };

        /**
        * @name isLumpSum
        * @desc check if annuity type is lump sum
        */
        function isLumpSum(benefit) {
            if (!benefit) return false;
            if (!benefit.AnnuityType) return false;

            return benefit.AnnuityType == 'LS' || benefit.AnnuityType == 'LL';
        };

        /**
        * @name isLifeOnly
        * @desc check if annuity type is Life Only
        */
        function isLifeOnly(benefit) {
            if (!benefit) return false;
            if (!benefit.AnnuityType) return false;

            return benefit.AnnuityType == 'LO';
        };

        /**
        * @name isLifeWithRefund
        * @desc check if annuity type is Life With Refund
        */
        function isLifeWithRefund(benefit) {
            if (!benefit) return false;
            if (!benefit.AnnuityType) return false;

            return benefit.AnnuityType == 'IR';
        };

        /**
        * @name isPeriodCertain
        * @desc check if annuity type is Period Certain
        */
        function isPeriodCertain(benefit) {
            if (!benefit) return false;
            if (!benefit.AnnuityType) return false;

            return benefit.AnnuityType == 'CL' || benefit.AnnuityType == 'PC';
        };

        /**
        * @name isTempLife
        * @desc check if annuity type is Temp Life
        */
        function isTempLife(benefit) {
            if (!benefit) return false;
            if (!benefit.AnnuityType) return false;

            return benefit.AnnuityType == 'TL';
        };        
    }
})();