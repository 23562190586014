angular
    .module('CareGuard')
    .constant('claimDetailStatusId', {
        AccountClosed: 1,
        DuplicateClaim: 2,
        FundsExhuastedBillSecondaryInsurance: 3,
        InappropriateBilling: 4,
        InappropriateBillingBilledToWrongAccount: 5,
        InappropriateBillingCareGuardPrimaryBilling: 6,
        InappropriateBillingMissingCode: 7,
        InclusiveToPrimaryProcedure: 8,
        IncompletePayerAndLocationAddress: 9,
        InsufficientDocumentation: 10,
        InsufficientFundsBillSecondaryInsurance: 11,
        MemberRequested: 12,
        NotCoveredByMedicare: 13,
        NotRelatedToCoveredInjury: 14,
        PricedAccordingToReview: 15,
        Reconsidered: 16,
        ResubmitIllegibleClaimImage: 17,
        ResubmitWithCorrectProcedureCode: 18,
        ServicePriorToMemberEffectiveDate: 19,
        ReconsiderationMedicarePhysicianFeeApplied: 20,
        PartialPayFundsExhausted: 21,
        NegotiatedRate: 22,
        AppealApproved: 23,
        InappropriateBillingInvalidModifier: 24,
        FundsTemporarilyExhaustedBillSecondaryInsurance: 25,
        PartialPayFundsTemporarilyExhausted: 26
    });