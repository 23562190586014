/**
* @name externalAuthService
* @desc External Authentication Requests.
**/
(function () {
    'use strict';

    angular
        .module('CareGuard')
        .factory('externalAuthService', externalAuthService);

    /** Inject services into service. */
    externalAuthService.$inject = ['$http', '$q', 'webStorageService', 'externalAuthConfigService'];

    /**
    * @name externalAuthService.
    * @desc External Authentication Service.
    */
    function externalAuthService($http, $q, webStorage, externalAuthConfigService) {

        const authStateKey = 'auth.state';
        const accessTokenKey = 'auth.access_token';
        const refreshTokenKey = 'auth.refresh_token';
        const explicitLoginPageKey = 'auth.explicitLoginPage';

        var self = this;

        var redirecting = false;

        this.redirectToIdentityServer = function (hashToIgnore) {
            if (!redirecting) {
                redirecting = true;
                var showPwdPage = webStorage.get(explicitLoginPageKey) == true;
                var hash = location.hash != hashToIgnore ? location.hash : '';
                self.createAuthenticationUrl(hash, showPwdPage).then(function (url) {
                    console.log('Auth redirect: ' + url);
                    window.location = url;   // bypass location since it is an external call
                });
            }
            else {
                console.log('Already redirecting, skipping redirect request');
            }
            // return nothing to stop other interceptor handling
        };
        
        this.createAuthenticationUrl = function(hash, showPwdPage) {

            var state = Date.now() + "" + Math.random() + encodeURIComponent(hash || '');

            webStorage.set(authStateKey, state);

            return externalAuthConfigService.getAuthConfig().then(function(config) {

                var acrValues = "";
                if (showPwdPage) {
                    acrValues = '&acr_values=' + encodeURIComponent(config.ShowPasswordLoginPageAcr);  // explicitly ask to show login page
                }

                var url = config.AuthorizeEndpoint + '?' +
                    "client_id=" + encodeURIComponent(config.ClientId) + "&" +
                    "redirect_uri=" + encodeURIComponent(config.RedirectUrl) + "&" +
                    "response_type=code" + "&" +
                    "response_mode=fragment" + "&" +
                    "scope=" + encodeURIComponent(config.Scopes) + "&" +
                    "state=" + encodeURI(state) + acrValues;

                return $q.when(url);
            }); 
        };

        this.enableLoginPage = function() {

            webStorage.set(explicitLoginPageKey, '1');
        };

        this.exchangeCodeForToken = function (authCode, state) {

            var originalState = webStorage.get(authStateKey);

            if (originalState != state) {
                return createErrorResponse('Invalid authentication state');
            }

            return externalAuthConfigService.getAuthConfig().then(function(config) {

                return $http.post(config.TokenEndpoint, {
                                client_id: config.ClientId,
                                client_secret: atob(config.ClientSecret),
                                code: authCode,
                                grant_type: 'authorization_code',
                                redirect_uri: config.RedirectUrl
                            }, getFormUrlConfig()
                        ).then(function(r) {
                            processTokenReponse(r);
                        })
                        .catch(function(e){
                            return createErrorResponse(e.data.error || e.data);
                        });
            });
        };

        this.refreshToken = function () {

            return externalAuthConfigService.getAuthConfig().then(function(config) {
                
                return $http.post(config.TokenEndpoint, {
                    client_id: config.ClientId,
                    client_secret: atob(config.ClientSecret),
                    refresh_token: webStorage.get(refreshTokenKey),
                    grant_type: 'refresh_token',
                    redirect_uri: config.RedirectUrl
                }, getFormUrlConfig())
                    .then(processTokenReponse)
                    .catch(function(e){
                        return createErrorResponse(e.data.error);
                    });
            });
        };

        this.configureHeaders = configureHeaders;

        this.clear = function() {
            processTokenReponse({ data: {} });
            webStorage.set(explicitLoginPageKey, null);
        };

        this.getLogoutRedirectUrl = function() {
            return externalAuthConfigService.getAuthConfig().then(function(config) {
               return config.EndSessionEndpoint + '?id_token_hint=' + webStorage.get(accessTokenKey) + '&post_logout_redirect_uri=' + encodeURIComponent(config.RedirectUrl);
            });
        };

        function getFormUrlConfig() {
            return {
                headers: {'Content-Type': 'application/x-www-form-urlencoded'},
                transformRequest: function(obj) {
                    var str = [];
                    for(var p in obj) {
                      str.push(p + "=" + obj[p]);  // encoding of URLs is not recognized by IdentityServer for some reason (TokenRequestValidator:267)
                    }
                    return str.join("&");
               }
            }
        }

        function processTokenReponse(r) {
            webStorage.set(refreshTokenKey, r.data.refresh_token);
            setAccessToken(r.data.access_token);     
        }

        function setAccessToken(accessToken) {
            webStorage.set(accessTokenKey, accessToken);
        }

        function configureHeaders(headers) {
            headers["Authorization"] = 'Bearer ' + (webStorage.get(accessTokenKey) || '');
        }

        function createErrorResponse(errorText) {
            return $q.reject({
                error: errorText
            });
        };

        return this;
    }
})();