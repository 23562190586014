/**
* @name chronovoNote
* @desc add note functionality
* @usage <input ng-model="field" chronovo-note="field" />
**/
(function () {
    'use strict';

    angular
        .module('CareGuard')
        .directive('chronovoNote', chronovoNote);

    /** Inject services into directive. */
    chronovoNote.$inject = [];

    /**
    * @name chronovoNote
    * @desc add note functionality
    */
    function chronovoNote() {
        var directive = {
            restrict: 'A',
            require: '^ngModel',
            scope: {
                ngModel: '='
            },
            template: '<div flex-container="column" flex-item>\
                         <div flex-container="column" class="post-wrapper">\
                           <div flex-container="column" flex-item ng-repeat="obj in ngModel | orderBy: \'CreatedDate\': true" class="post">\
                             <div class="post-header">{{obj.NoteText}}</div>\
                             <div class="author">by {{obj.CreatedBy}} @ {{obj.CreatedDate | date : \'MM/dd/yyyy h:mm a\'}}</div>\
                           </div>\
                         </div>\
                       </div>',
            link: postLink
        };

        return directive;

        function postLink($scope, $element, $attrs, $controller) {
            $scope.model = undefined;
        };
    };
})();