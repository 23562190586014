/**
* @name chronovoLinearGauge
* @desc draws a linear gauge
* @usage <canvas id="canvas" chronovo-linear-gauge="" width="500" height="260"></canvas>
**/
(function () {
    'use strict';

    angular
        .module('CareGuard')
        .directive('chronovoLinearGauge', chronovoLinearGauge);

    /** Inject services into directive. */
    chronovoLinearGauge.$inject = ['$parse'];

    /**
    * @name chronovoLinearGauge
    * @desc draws a linear gauge
    */
    function chronovoLinearGauge($parse) {
        var directive = {
            restrict: 'A',        
            scope: {
                chronovoLinearGauge: '='
            },
            link: postLink
        };

        return directive;

        function postLink($scope, $element, $attrs, $controller) {
            var options = {
                triangle: { color: '#00B9E7', width: 10, height: 20 },
                text: { primary_text: 0, primary_color: '#00B9E7', secondary_color: '#C0C0C0', primary_font: '24px Arial', secondary_font: '18px Arial', secondary_text: 0 }
            };

            options.ranges = [{
                start: 0,
                end: 40,
                width: 20,
                background: { color: '#EEE', color2: '#DDD' }
            },
            {
                start: 40,
                end: 80,
                width: 20,
                background: { color: '#E7F6FD', color2: '#7DC3E3' }
            },
            {
                start: 80,
                end: 100,
                width: 20,
                background: { color: '#428BCA', color2: '#7FC3E8' }
            }];
            
            //if ($attrs.chronovoLinearGauge) {
            //    options = angular.extend(options, $scope.$eval($attrs.chronovoLinearGauge));
            //}
            //console.log($scope.$eval($attrs.chronovoLinearGauge), options);
            var canvas = undefined;
            var ctx = undefined;
            var animationLoop = undefined;
            var factor = undefined;
            var animStart = undefined;
            var animEnd = undefined;
            

            $scope.$watch('chronovoLinearGauge', function (newVal, oldVal) {
                if (newVal == oldVal) return;

                options = angular.extend({}, options, newVal);
                init();
            });

            //$(ctx.canvas).css("width", "100%");
            //$(ctx.canvas).css("height", '100%');
                       

            /*
             * @name initi
             * @desc initilize
             */
            function init() {
                canvas = $element[0];
                ctx = canvas.getContext('2d');
                factor = getFactor();
                animStart = options.triangle.width;
                animEnd = options.text.primary_text;
                ctx.translate(0.5, 0.5);

                //Cancel any movement animation if a new chart is requested
                if (typeof animationLoop != undefined) {
                    clearInterval(animationLoop);
                }

                var difference = animEnd - animStart;

                //This will animate the gauge to new positions
                //The animation will take 1 second
                //time for each frame is 1sec / difference in animStart
                animationLoop = setInterval(animate, 100 / difference);
            }

            /*
             * @name getFactor
             * @desc Get multiplication factor to expand the gauge
             */
            function getFactor() {
                var limit = 0;
                for (var i = 0; i < options.ranges.length; i++) {
                    var range = options.ranges[i];
                    if (limit < range.end) {
                        limit = range.end
                    }
                }
                
                return (canvas.width - 10) / limit;
            }

            /*
             * @name animate
             * @desc called from animation loop on set interval, increment animStart by 1
             */
            function animate() {
                if (animStart * factor > animEnd * factor) {
                    clearInterval(animationLoop);
                }
                animStart++;
                if (animStart * factor > animEnd * factor) {
                    animStart = animEnd;
                }
                draw();
            }

            /*
             * @name draw
             * @desc draw the gauge
             */
            function draw() {
                ctx.clearRect(0, 0, canvas.width, canvas.height);

                for (var i = 0; i < options.ranges.length; i++) {
                    var range = options.ranges[i];

                    ctx.beginPath();

                    var grd = ctx.createLinearGradient(0, 0, 0, canvas.height);
                    grd.addColorStop(0, range.background.color);
                    grd.addColorStop(.25, range.background.color2 || range.background.color);
                    grd.addColorStop(1, range.background.color);
                    ctx.strokeStyle = grd;

                    ctx.lineWidth = range.width;
                    ctx.moveTo(range.start * factor - 1, canvas.height / 2);
                    ctx.lineTo(range.end * factor, canvas.height / 2)
                    ctx.stroke();
                }

                drawTriangle();
            };

            /*
             * @name drawTriangle
             * @desc draw the triangle and the text label
             */
            function drawTriangle() {
                var offset = canvas.height / 2 + 10;

                // triangle
                ctx.beginPath();
                ctx.moveTo(options.triangle.width / 2 + animStart * factor, offset); // Top Corner
                ctx.lineTo(options.triangle.width / 2 + animStart * factor + options.triangle.width, options.triangle.height + offset); // Bottom Right
                ctx.lineTo(options.triangle.width / 2 + animStart * factor - options.triangle.width, options.triangle.height + offset); // Bottom Left
                ctx.closePath();

                ctx.fillStyle = options.triangle.color;
                ctx.fill();

                // primary text
                offset += options.triangle.height + 5;
                var textprop = options.text;
                ctx.fillStyle = textprop.primary_color;
                var text = animStart;
                ctx.font = textprop.primary_font;
                var textWidth = ctx.measureText(text).width;
                ctx.fillText(text, options.triangle.width / 2 + animStart * factor - textWidth / 2, options.triangle.height + offset);

                // secondary text
                ctx.fillStyle = textprop.secondary_color;
                ctx.font = textprop.secondary_font;
                text = '/' + textprop.secondary_text;
                ctx.fillText(text, options.triangle.width / 2 + animStart * factor + textWidth / 2, options.triangle.height + offset);
            }
        };
    };
})();