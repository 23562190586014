angular
    .module('CareGuard')
    .directive('ssnField', ssnField);

ssnField.$inject = ['$compile', '$filter', 'ssnValidation'];

function ssnField($compile, $filter, ssnValidation){
    return {
        restrict: 'A',
        require: 'ngModel',
        scope: {
            ngModel: '=',
        },
        link: (scope, element, attrs, modelCtrl) => {
            const ssnFilter = $filter('ssnFilter'),
                  ssnReverse = $filter('ssnReverse');

            let isBlurred;

            const formatter = (value) => {
                return ssnFilter(value, true);
            };
            const parser = (value) => {
                const formatted = ssnReverse(value);

                if (isBlurred) {
                    isBlurred = false;
                    return modelCtrl.$modelValue;
                }

                modelCtrl.$setViewValue(ssnFilter(formatted));
                modelCtrl.$render();

                return formatted;
            };

            modelCtrl.$validators.length = function (value) {

                return ssnValidation.validateLength(value || modelCtrl.$modelValue);
            };

            modelCtrl.$validators.value = function (value) {

                return ssnValidation.validateSSN(value) || ssnValidation.validateITIN(value);
            };

            modelCtrl.$formatters.push(formatter);
            modelCtrl.$parsers.unshift(parser);

            $(element).on('focus', function(){
                if (isBlurred) {
                    isBlurred = false;
                }

                if (modelCtrl.$modelValue) {
                    modelCtrl.$setViewValue(ssnFilter(modelCtrl.$modelValue));
                    modelCtrl.$render();
                }
            });

            $(element).on('blur', function(value){
                if (modelCtrl.$modelValue) {
                    isBlurred = true;
                    modelCtrl.$setViewValue(ssnFilter(modelCtrl.$modelValue, true));
                    modelCtrl.$render();
                }
            });

        }
    }
}