angular
    .module('CareGuard')
    .config(config);

    config.$inject = [
        '$stateProvider',
        '$urlRouterProvider',
        '$httpProvider',
        'webStorageServiceProvider',
        'cfpLoadingBarProvider',
        'uiMask.ConfigProvider'];

    function config (
        $state,
        $urlRouter,
        $http,
        webStorage,
        cfpLoadingBarProvider,
        uiMask) {

        cfpLoadingBarProvider.includeSpinner = false;

        uiMask.maskDefinitions({'A': /[a-z]/, '*': /[a-zA-Z0-9]/});
        uiMask.clearOnBlur(false);

        webStorage.setPrefix('cq');
        webStorage.setStorageType('sessionStorage');

        $http.interceptors.unshift('authInterceptorService');
        $http.interceptors.unshift('toastrInterceptorService');
        $http.interceptors.unshift('deferredInterceptorService');

        $urlRouter.when('','/');

        $urlRouter.when(/code=(.+)&.+&state=(.+)/, ['$match', '$stateParams', '$state', function ($match, $stateParams, $state) {

             var authCode = $match[1];
             var state = $match[2];

             $state.go('authResponse', {                         
                authCode: authCode,
                state: state
             });
            
             return true;
        }]);


        $urlRouter.otherwise('/invalid');

        $state
            .state('authResponse', {
                url: '/authResponse',
                templateUrl: 'app/CareGuard/views/account/authResponse.html',
                controller: 'authResponseController',
                controllerAs: 'vm',
                params: {
                   authCode: undefined,
                   state: undefined
                },
                requiresLogIn: false
            })
            // optional controller to request internalAuth page at Identity Server when Client configured with external-auth only.
            .state('internalAuth', {
                url: '/internalAuth',
                controller: 'internalAuthRedirectController',
                controllerAs: 'vm',
                requiresLogIn: false
            })

            .state('invalid', {
                url: '/invalid',
                templateUrl: 'app/CareGuard/views/invalid/invalid.html',
                requiresLogIn: true
            })

            .state('layout', {
                abstract: true,
                templateUrl: 'app/careguard/views/layouts/layout.html'
            })

            .state('layoutNoNav', {
                abstract: true,
                templateUrl: 'app/careguard/views/layouts/layoutNoNav.html'
            })

            .state('layoutAmethyst', {
                abstract: true,
                templateUrl: 'app/careguard/views/layouts/layoutAmethyst.html'
            })

            .state('layout.home', {
                url: '/',
                title: 'DashBoard',
                requiresLogIn: true,
                resolve: {
                    redirect: [
                        '$q', '$state', 'accountService', function ($q, $state, accountService) {

                            $state.go('layoutNoNav.homepage');

                            return $q.reject();
                        }
                    ]
                }
            })

            .state('layout.dashboard', {
                url: '/dashboard',
                title: 'DashBoard',
                roles: 'CareGuardFinance,CareGuard,ThirdPartyUser',
                templateUrl: 'app/careguard/views/dashboard/dashboard.html',
                controller: 'dashboardController',
                controllerAs: 'vm',
                cache: false
            })

            .state('layoutNoNav.homepage', {
                url: '/homepage',
                cache: false,
                title: 'New Referral',
                roles: 'CareGuardFinance,CareGuard,ThirdPartyUser',
                templateUrl: 'app/careguard/views/homepage/homepage.html',
                controller: 'homepageController',
                controllerAs: 'vm',
                resolve: {
                    'successFunc': ['$state', function ($state) {
                        return function () {
                            $state.go("{layoutNoNav.homepage}");
                        };
                    }]
                }
            })

            .state('layout.profile', {
                url: '/profile/:id',
                cache: false,
                title: 'Member',
                roles: 'CareGuardFinance,CareGuard,ThirdPartyUser',
                templateUrl: 'app/careguard/views/member/member.html',
                controller: 'memberController',
                controllerAs: 'vm',
                resolve: {
                    'memberData': ['$stateParams', function ($stateParams) {
                        return (($stateParams.id > 0) ? $stateParams.id : 0);
                    }],
                    'successFunc': ['$state', function ($state) {
                        return function () {
                            $state.go("{layout.profile/:id}");
                        };
                    }]
                }
            })

            .state('layout.oldMember', {
                url: '/member/:id',
                cache: false,
                title: 'Member',
                roles: 'CareGuardFinance,CareGuard,ThirdPartyUser',
                templateUrl: 'app/careguard/views/member/member.html',
                controller: 'memberController',
                controllerAs: 'vm',
                resolve: {
                    'memberData': ['$stateParams', function ($stateParams) {
                        return (($stateParams.id > 0) ? $stateParams.id : 0);
                    }],
                    'successFunc': ['$state', function ($state) {
                        return function () {
                            $state.go("{layout.profile/:id}");
                        };
                    }]
                }
            })

            .state('layout.address', {
                url: '/address',
                roles: 'CareGuardFinance, CareGuard,ThirdPartyUser',
                templateUrl: 'app/careguard/views/address/address.html',
                controller: 'addressController',
                controllerAs: 'vm'
            })

            .state('layout.memberCodes', {
                url: '/memberCodes/:id',
                title: 'Codes',
                roles: 'CareGuardFinance,CareGuard,ThirdPartyUser',
                templateUrl: 'app/careguard/views/servicecode/code.html',
                controller: 'codesController',
                controllerAs: 'vm',
                cache: false,
                resolve: {
                    'memberID': ['$stateParams', function ($stateParams) {
                        return (($stateParams.id > 0) ? $stateParams.id : 0);
                    }],
                    'successFunc': ['$state', function ($state) {
                        return function () {
                            $state.go("{layout.memberCodes/:id}");
                        };
                    }]
                }
            })

            .state('layoutNoNav.codes', {
                url: '/codes/',
                title: 'Codes',
                roles: 'CareGuardFinance,CareGuard,ThirdPartyUser',
                templateUrl: 'app/careguard/views/servicecode/code.html',
                controller: 'codesController',
                controllerAs: 'vm',
                cache: false,
                resolve: {
                    'memberID': ['$stateParams', function ($stateParams) {
                        return (0);
                    }],
                    'successFunc': ['$state', function ($state) {
                        return function () {
                            $state.go("{layoutNoNav.codes/:id}");
                        };
                    }]
                }
            })

            .state('layoutNoNav.californiaFeeSchedule', {
                url: '/californiaFeeSchedule/',
                title: 'CaliforniaFeeSchedule',
                roles: 'CaliforniaFeeSchedule',
                templateUrl: 'app/careguard/views/californiaFeeSchedule/californiaFeeSchedule.html',
                controller: 'californiaFeeScheduleController',
                controllerAs: 'vm',
                cache: false,
                resolve: {
                    'successFunc': ['$state', function ($state) {
                        return function () {
                            $state.go("{layoutNoNav.californiaFeeSchedule}");
                        };
                    }]
                }
            })

            .state('layout.notes', {
                url: '/notes/:id',
                title: 'Notes',
                roles: 'CareGuardFinance,CareGuard,ThirdPartyUser',
                templateUrl: 'app/careguard/views/notes/notes.html',
                controller: 'notesController',
                controllerAs: 'vm',
                cache: false,
                resolve: {
                    'memberData': ['$stateParams', function ($stateParams) {
                        return (($stateParams.id > 0) ? $stateParams.id : 0);
                    }],
                    'successFunc': ['$state', function ($state) {
                        return function () {
                            $state.go("{layout.notes/:id}");
                        };
                    }]
                }
            })

            .state('layoutNoNav.payBills', {
                url: '/payBills/',
                title: 'Pay Bills',
                roles: 'CareGuardFinance',
                templateUrl: 'app/careguard/views/payBills/payBills.html',
                controller: 'payBillsController',
                controllerAs: 'vm',
                resolve: {
                    'successFunc': ['$state', function ($state) {
                        return function () {
                            $state.go("layoutNoNav.payBills");
                        };
                    }]
                }
            })

            .state('layoutAmethyst.amethystclaims', {
                url: '/amethystclaims/',
                title: 'Amethyst Claims',
                roles: 'AmethystSpecialist,ThirdPartyUser',
                templateUrl: 'app/careguard/views/amethystclaim/amethystclaim.html',
                controller: 'amethystclaimController',
                controllerAs: 'vm',
                resolve: {
                    'memberID': ['$stateParams', function ($stateParams) {
                        return (0);
                    }],
                    'successFunc': ['$state', function ($state) {
                        return function () {
                            $state.go("layoutAmethyst.amethystclaims/:id");
                        };
                    }]
                }
            })

            .state('layoutNoNav.claims', {
                url: '/claims/',
                title: 'Claims',
                roles: 'CareGuardFinance,CareGuard,ThirdPartyUser',
                templateUrl: 'app/careguard/views/claim/claim.html',
                controller: 'claimController',
                controllerAs: 'vm',
                resolve: {
                    'memberID': ['$stateParams', function ($stateParams) {
                        return null;
                    }],
                    'successFunc': ['$state', function ($state) {
                        return function () {
                            $state.go("layoutNoNav.claims");
                        };
                    }]
                }
            })

            .state('layout.claims', {
                url: '/claimsHistory/:id',
                title: 'Claims',
                roles: 'CareGuardFinance,CareGuard,ThirdPartyUser',
                templateUrl: 'app/careguard/views/claim/claim.html',
                controller: 'claimController',
                controllerAs: 'vm',
                resolve: {
                    'memberID': ['$stateParams', function ($stateParams) {
                        return $stateParams.id > 0 ? $stateParams.id : 0;
                    }],
                    'successFunc': ['$state', function ($state) {
                        return function () {
                            $state.go("layout.claims/:id");
                        };
                    }]
                }
            })

            .state('layoutNoNav.claims-detail', {
                url: '/claimdetailOld/:claimValue',
                title: 'Claim Details',
                roles: 'CareGuardFinance,CareGuard,ThirdPartyUser',
                templateUrl: 'app/careguard/views/claim/detailOld.html',
                controller: 'claimDetailControllerOld',
                controllerAs: 'vm',
                redirectTo: 'layoutNoNav.claim-details'
            })

            .state('layoutNoNav.claim-details', {
                url: '/claimdetail/:claimValue',
                title: 'Claim Details',
                roles: 'CareGuardFinance,CareGuard,ThirdPartyUser',
                templateUrl: 'app/careguard/views/claimDetails/detail.html',
                controller: 'claimDetailController',
                controllerAs: 'vm',
                params: {
                    claimValue: null,
                    memberID: null,
                    previousMemberID: null,
                    parentState: null
                }
            })

            .state('layout.memberFlags', {
                url: '/memberFlags/:id',
                title: 'Member Flags',
                roles: 'CareGuardFinance,CareGuard,ThirdPartyUser',
                templateUrl: 'app/careguard/views/memberFlags/memberFlags.html',
                controller: 'memberFlagsController',
                controllerAs: 'vm',
                cache: false,
                resolve: {
                    'memberID': ['$stateParams', function ($stateParams) {
                        return (($stateParams.id > 0) ? $stateParams.id : 0);
                    }],
                    'successFunc': ['$state', function ($state) {
                        return function () {
                            $state.go("{layout.memberFlags/:id}");
                        };
                    }]
                }
            })

            .state('layoutNoNav.billReview', {
                url: '/billreview/',
                title: 'Bill Review',
                roles: 'CareGuardFinance,CareGuard',
                templateUrl: 'app/careguard/views/billreview/billreview.html',
                controller: 'billReviewController',
                controllerAs: 'vm',
                resolve: {
                    'successFunc': ['$state', function ($state) {
                        return function () {
                            $state.go("layoutNoNav.billReview");
                        };
                    }]
                }
            })

            .state('layoutNoNav.memberOnHold', {
                url: '/memberonhold/',
                title: 'Member On Hold',
                roles: 'CareGuardFinance,CareGuard,ThirdPartyUser',
                templateUrl: 'app/careguard/views/memberonhold/memberonhold.html',
                controller: 'memberOnHoldController',
                controllerAs: 'vm',
                resolve: {
                    'successFunc': ['$state', function ($state) {
                        return function () {
                            $state.go("layoutNoNav.memberOnHold");
                        };
                    }]
                }
            })

            .state('layoutNoNav.invalidClaims', {
                url: '/invalidclaims/',
                title: 'Invalid Claims',
                roles: 'CareGuardFinance,CareGuard,ThirdPartyUser',
                templateUrl: 'app/careguard/views/invalidclaims/invalidclaims.html',
                controller: 'invalidClaimsController',
                controllerAs: 'vm',
                resolve: {
                    'successFunc': ['$state', function ($state) {
                        return function () {
                            $state.go("layoutNoNav.invalidClaims");
                        };
                    }]
                }
            })

            .state('layoutNoNav.odglookup', {
                url: '/odglookup/',
                title: 'ODG Look Up',
                roles: 'CareGuardFinance,CareGuard,ThirdPartyUser',
                templateUrl: 'app/careguard/views/odglookup/odglookup.html',
                controller: 'odgLookUpController',
                controllerAs: 'vm',
                resolve: {
                    'memberID': ['$stateParams', function ($stateParams) {
                        return (0);
                    }],
                    'successFunc': ['$state', function ($state) {
                        return function () {
                            $state.go("layout.odglookup/:id");
                        };
                    }]
                }
            })

            .state('layout.odglookup', {
                url: '/odglookup/:id',
                title: 'ODG Look Up',
                roles: 'CareGuardFinance,CareGuard,ThirdPartyUser',
                templateUrl: 'app/careguard/views/odglookup/odglookup.html',
                controller: 'odgLookUpController',
                controllerAs: 'vm',
                resolve: {
                    'memberID': ['$stateParams', function ($stateParams) {
                        return (($stateParams.id > 0) ? $stateParams.id : 0);
                    }],
                    'successFunc': ['$state', function ($state) {
                        return function () {
                            $state.go("layout.odglookup/:id");
                        };
                    }]
                }
            })

            .state('layout.memberAnnualFunding', {
                url: '/memberAnnualFunding/:id',
                title: 'Annual Funding',
                roles: 'CareGuardFinance,CareGuard,ThirdPartyUser',
                templateUrl: 'app/careguard/views/annualfunding/annualfunding.html',
                controller: 'annualFundingController',
                controllerAs: 'vm',
                resolve: {
                    'memberID': ['$stateParams', function ($stateParams) {
                        return (($stateParams.id > 0) ? $stateParams.id: 0);
                    }],
                    'successFunc': ['$state', function ($state) {
                        return function () {
                            $state.go("layout.memberAnnualFunding/:id");
                        };
                    }]
                }
            })

            .state('layoutNoNav.annualFunding', {
                url: '/annualfunding/',
                title: 'Annual Funding',
                roles: 'CareGuardFinance,CareGuard,ThirdPartyUser',
                templateUrl: 'app/careguard/views/annualfunding/annualfunding.html',
                controller: 'annualFundingController',
                controllerAs: 'vm',
                resolve: {
                    'memberID': ['$stateParams', function ($stateParams) {
                        return (0);
                    }],
                    'successFunc': ['$state', function ($state) {
                        return function () {
                            $state.go("layoutNoNav.annualFunding/:id");
                        };
                    }]
                }
            })

            .state('layoutNoNav.reports', {
                url: '/reports/',
                title: 'Reports',
                roles: 'CareGuard',
                templateUrl: 'app/careguard/views/reports/reports.html',
                controller: 'reportsController',
                controllerAs: 'vm',
                resolve: {
                    'successFunc': ['$state', function ($state) {
                        return function () {
                            $state.go("layoutNoNav.reports");
                        };
                    }]
                }
            })

            .state('layout.forms', {
                url: '/forms/:id',
                title: 'Forms',
                roles: 'CareGuardFinance,CareGuard,ThirdPartyUser',
                templateUrl: 'app/careguard/views/forms/forms.html',
                controller: 'formsController',
                controllerAs: 'vm',
                resolve: {
                    'memberID': ['$stateParams', function ($stateParams) {
                        return (($stateParams.id > 0) ? $stateParams.id : 0);
                    }],
                    'successFunc': ['$state', function ($state) {
                        return function () {
                            $state.go("layout.forms/:id");
                        };
                    }]
                }
            })

            .state('layoutNoNav.structureBroker', {
                url: '/structurebroker/',
                title: 'Structure Broker',
                roles: 'CareGuard',
                templateUrl: 'app/careguard/views/structurebroker/structurebroker.html',
                controller: 'structureBrokerController',
                controllerAs: 'vm',
                resolve: {
                    'successFunc': ['$state', function ($state) {
                        return function () {
                            $state.go("layoutNoNav.structureBroker");
                        };
                    }]
                }
            })

            .state('layout.memberStopPaymentRequests', {
                url: '/memberStopPaymentRequests/:id',
                title: 'Stop Payment Requests',
                roles: 'CareGuardFinance,CareGuard,ThirdPartyUser',
                templateUrl: 'app/careguard/views/claim/stoppaymentrequests.html',
                controller: 'stopPaymentRequestsController',
                controllerAs: 'vm',
                resolve: {
                    'memberID': ['$stateParams', function ($stateParams) {
                        return (($stateParams.id > 0) ? $stateParams.id : 0);
                    }],
                    'successFunc': ['$state', function ($state) {
                        return function () {
                            $state.go("layout.memberStopPaymentRequests/:id");
                        };
                    }]
                }
            })

            .state('layoutNoNav.stopPaymentRequests', {
                url: '/stoppaymentrequests/',
                title: 'Stop Payment Requests',
                roles: 'CareGuardFinance,CareGuard',
                templateUrl: 'app/careguard/views/claim/stoppaymentrequests.html',
                controller: 'stopPaymentRequestsController',
                controllerAs: 'vm',
                resolve: {
                    'memberID': ['$stateParams', function ($stateParams) {
                        return (0);
                    }],
                    'successFunc': ['$state', function ($state) {
                        return function () {
                            $state.go("layoutNoNav.stopPaymentRequests/:id");
                        };
                    }]
                }
            })

            .state('layoutNoNav.payees', {
                url: '/payees/:TaxID',
                title: 'Payees',
                roles: 'AddToVendorTable,EditVendorTable',
                templateUrl: 'app/careguard/views/payees/payees.html',
                controller: 'payeeController',
                controllerAs: 'vm',
                params: {
                    TaxID: null
                },
                resolve: {
                    'successFunc': ['$state', function ($state) {
                        return function () {
                            $state.go("layoutNoNav.payees");
                        };
                    }]
                }
            })

            .state('layoutNoNav.payeeApproval', {
                url: '/payeeApproval/',
                title: 'Payee Approval',
                roles: 'AccessPayeeApprovalPage',
                templateUrl: 'app/careguard/views/payeeApproval/payeeApproval.html',
                controller: 'payeeApprovalController',
                controllerAs: 'vm',               
                resolve: {
                    'successFunc': ['$state', function ($state) {
                        return function () {
                            $state.go("layoutNoNav.payeeApproval");
                        };
                    }]
                }
            })

            .state('layout.banking', {
                url: '/banking/:id',
                title: 'Banking',
                roles: 'CareGuardFinance,CareGuard,ThirdPartyUser',
                templateUrl: 'app/careguard/views/banking/banking.html',
                controller: 'bankingController',
                controllerAs: 'vm',
                cache: false,
                resolve: {
                    'memberID': ['$stateParams', function ($stateParams) {
                        return (($stateParams.id > 0) ? $stateParams.id : 0);
                    }],
                    'successFunc': ['$state', function ($state) {
                        return function () {
                            $state.go("{layout.banking/:id}");
                        };
                    }]
                }
            })

            .state('layoutNoNav.annualAccounting', {
                url: '/annualAccounting/',
                title: 'Annual Accounting',
                roles: 'CareGuardFinance,CareGuard',
                templateUrl: 'app/careguard/views/annualAccounting/annualAccounting.html',
                controller: 'annualAccountingController',
                controllerAs: 'vm'
            })

            .state('layoutNoNav.customDocuments', {
                url: '/customDocuments/',
                title: 'Custom Documents',
                roles: 'CustomStatementGenerationPage',
                templateUrl: 'app/careguard/views/customDocuments/customDocuments.html',
                controller: 'customDocumentsController',
                controllerAs: 'vm',
            })

            //Documents (legacy member correspondence)
            .state('layout.memberDocuments', {
                url: '/memberDocuments/:id',
                title: 'Documents',
                roles: 'CareGuardFinance,CareGuard,ThirdPartyUser',
                templateUrl: 'app/careguard/views/documents/documents.html',
                controller: 'documentsController',
                controllerAs: 'vm',
                cache: false,
                resolve: {
                    'memberID': ['$stateParams', function ($stateParams) {
                        return (($stateParams.id > 0) ? $stateParams.id : 0);
                    }],
                    'successFunc': ['$state', function ($state) {
                        return function () {
                            $state.go("{layout.memberDocuments/:id}");
                        };
                    }]
                }
            })
            .state('layout.memberSharepointDocuments', {
                url: '/memberSharepointsDocuments/:id',
                title: 'Sharepoint',
                roles: 'CareGuardFinance,CareGuard',
                templateUrl: 'app/careguard/views/sharepointDocuments/sharepointDocuments.html',
                controller: 'sharepointDocumentsController',
                controllerAs: 'vm',
                cache: false,
                resolve: {
                    'memberID': ['$stateParams', function ($stateParams) {
                        return (($stateParams.id > 0) ? $stateParams.id : 0);
                    }],
                    'successFunc': ['$state', function ($state) {
                        return function () {
                            $state.go("{layout.memberSharepointDocuments/:id}");
                        };
                    }]
                }
            })

            .state('layout.search', {
                url: '/search.modal',
                templateUrl: 'app/careguard/views/case/search.modal.html',
                controller: 'searchController',
                controllerAs: 'vm',
            })
            
            .state('layoutNoNav.htmlToPdf', {
                url: '/htmlToPdf',
                title: 'HTML to PDF',
                roles: 'CareGuard,ViewHtmlToPdfPage',
                templateUrl: 'app/careguard/views/htmltopdf/htmltopdf.html',
                controller: 'htmlToPdfController',
                controllerAs: 'vm'
            })

            .state('layoutNoNav.memberStatements', {
                url: '/memberStatements',
                title: 'Member Statements',
                roles: 'CareGuard,CareGuardFinance',
                templateUrl: 'app/careguard/views/memberStatements/memberStatements.html',
                controller: 'memberStatementsController',
                controllerAs: 'vm'
            })

            .state('layout.fundingEvents', {
                url: '/fundingevents/:id',
                title: 'Funding Events',
                roles: 'CareGuard,ThirdPartyUser',
                templateUrl: 'app/careguard/views/funding-events/funding-events.html',
                controller: 'fundingEventsController',
                controllerAs: 'vm',
                resolve: {
                    'memberID': ['$stateParams', function ($stateParams) {
                        return (($stateParams.id > 0) ? $stateParams.id : 0);
                    }],
                    'successFunc': ['$state', function ($state) {
                        return function () {
                            $state.go("{layout.fundingEvents/:id}");
                        };
                    }]
                }
            })

            .state('layoutNoNav.1099', {
                url: '/1099',
                title: '1099',
                roles: 'CareGuardFinance,CareGuard',
                templateUrl: 'app/careguard/views/1099/1099.html',
                controller: '1099Controller',
                controllerAs: 'vm'
            })

            .state('layoutNoNav.fundsExhaustion', {
                url: '/fundsExhaustion/',
                title: 'Funds Exhaustion',
                roles: 'CareGuard',
                templateUrl: 'app/careguard/views/fundsExhaustion/fundsExhaustion.html',
                controller: 'fundsExhaustionController',
                controllerAs: 'vm'
            })

            .state('layoutNoNav.bulkStatements', {
                url: '/bulkStatements/',
                title: 'Bulk Statements',
                roles: 'CareGuard',
                templateUrl: 'app/careguard/views/bulkStatements/bulkStatements.html',
                controller: 'bulkStatementsController',
                controllerAs: 'vm'
            })

            .state('layoutNoNav.nonRedeemedClaims', {
                url: '/nonredeemedclaims/:ClaimId',
                title: 'Non Redeemed Claims',
                roles: 'CareGuardFinance,ClaimActions',
                templateUrl: 'app/careguard/views/nonRedeemedClaims/nonRedeemedClaims.html',
                controller: 'nonRedeemedClaimsController',
                controllerAs: 'vm',
                params: {
                    ClaimId: null
                },
                resolve: {
                    'successFunc': ['$state', function ($state) {
                        return function () {
                            $state.go("layoutNoNav.nonRedeemedClaims");
                        };
                    }]
                }
            });
    }