angular
    .module('CareGuard')
    .constant('documentTypeId', {
        AccountClosure: 818,
        ADA: 27,
        AnnualAccounting: 813,
        AnnuityDocuments: 740,
        AnnuityRefund: 828,
        CareQuoteReport: 741,
        CMSDetermination: 506,
        CMS1500: 25,
        Correspondence: 28,
        Dental: 394,
        DME: 349,
        EOR: 466,
        EORState: 467,
        HHC: 471,
        IDCard: 30,
        InvalidClaim: 733,
        LetterofMedicalNecessity: 743,
        MedicarePermanentDepletionLetter: 736,
        MedicareTemporaryDepletionLetter: 734,
        MemberLetter: 744,
        MemberPermanentDepletionLetter: 737,
        MemberStatement: 31,
        MemberTemporaryDepletionLetter: 735,
        MemberWelcomeKit: 29,
        MemberWithdrawal: 745,
        MSA: 277,
        PBMReferralForm: 278,
        PreFundDepletionLetter: 738,
        PriorAuthorization: 742,
        ReceiptDME: 620,
        ReceiptHHC: 621,
        ReceiptOther: 534,
        ReceiptProvider: 622,
        ReceiptRX: 619,
        ReceiptTransportation: 746,
        Refund: 827,
        Rx: 348,
        SettlementDocuments: 739,
        SignedMemberAgreement: 711,
        UB04Inpt: 393,
        UB04Outpt: 26,
        Unknown: 395,
    });