//Directive provides an ability to edit file name without extension.
angular
    .module('CareGuard')
    .directive('editFileName', editFileName);

editFileName.$inject = ['fileService'];

function editFileName(fileService) {
    return {
        restrict: 'A',
        require: 'ngModel',
        scope: {
            ngModel: '='
        },
        link: (scope, element, attrs, modelCtrl) => {
            const formatter = (value) => {
                return fileService.getFileNameWithoutExtension(value);
            };
            const parser = (value) => {
                const extension = fileService.getExtension(modelCtrl.$modelValue);

                if (!extension) {
                    return value;
                }

                return (value || '') + '.' + extension;
            };

            if (!modelCtrl.$options) {
                modelCtrl.$options = {};
            }
            modelCtrl.$options.allowInvalid = true;
            modelCtrl.$options.updateOnDefault = true;

            modelCtrl.$formatters.push(formatter);
            modelCtrl.$parsers.unshift(parser);
        }
    };
}