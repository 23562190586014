angular
    .module('CareGuard')
    .directive('formatAnnualFundingDate', formatAnnualFundingDate);

formatAnnualFundingDate.$inject = ['$filter'];

function formatAnnualFundingDate($filter) {
    return {
        restrict: 'A',
        require: 'ngModel',
        link: function($scope, $elm, $attrs, ngModelController) {
            const onlyNumbers = $filter('positiveNumbers');

            function convertAnnualFundingDate(fundDate) {
                return fundDate && (fundDate[0]+fundDate[1]+'/'+fundDate[2]+fundDate[3]);
            }

            ngModelController.$parsers.unshift(function(data) {
                const currentDate = new Date();
                const currentYear = currentDate.getFullYear();
                const momentJsDate = moment(data + '/' + currentYear.toString(), 'MM/DD/YYYY', true);

                ngModelController.$setValidity('date', momentJsDate.isValid());

                if (data) {
                    return onlyNumbers(data);
                } else {
                    return null;
                }
            });

            ngModelController.$formatters.push(function(data) {
                if (data) {
                    return convertAnnualFundingDate(data);
                } else {
                    return '-'
                }
            });
        }
    }
}