angular
    .module('CareGuard')
    .factory('pbmGroupService', pbmGroupService);

pbmGroupService.$inject = ['$http'];

function pbmGroupService($http) {

    const baseRoute = 'members';

    const service = {
        getAllPbmPricingLevels: getAllPbmPricingLevels,
        shouldFilterByPbm: shouldFilterByPbm,
        getMatchingGroupForMember: getMatchingGroupForMember
    };

    return service;

    function getAllPbmPricingLevels() {
        return $http.get(`${apiUrl}/${baseRoute}/pricingLevels`);
    }

    function shouldFilterByPbm(pricingLevel, member) {
        return pricingLevel.pbmVendorId == member.PBMVendorID;
    }

    function getMatchingGroupForMember(pbmPricingLevels, member) {
        var oldPricingLevel = pbmPricingLevels.find(pl => pl.pricingLevelId == member.PricingLevel);  // first one will do 
        var oldPricingLevelId = oldPricingLevel ? oldPricingLevel.pricingLevelId : null;

        var availablePricingLevels = pbmPricingLevels.filter(pl => shouldFilterByPbm(pl, member));
        return availablePricingLevels.find(obj => obj.pricingLevelId === oldPricingLevelId);
    }
}
