/**
* @name smartGrid
* @desc smart-grid module.
**/
(function () {
    'use strict';

    angular.module('smart-grid', [])
        .constant('TemplateConfig', {
            tableTemplate: 'app/CareGuard/directives/smart-grid/templates/table.html',
            checkboxTemplate: 'app/CareGuard/directives/smart-grid/templates/checkbox.html',
            selectAllTemplate: 'app/CareGuard/directives/smart-grid/templates/select-all.html',
            headerTemplate: 'app/CareGuard/directives/smart-grid/templates/header.html',
            cellTemplate: 'app/CareGuard/directives/smart-grid/templates/cell.html',
            filterTemplate: 'app/CareGuard/directives/smart-grid/templates/filter.html',
            paginationTemplate: 'app/CareGuard/directives/smart-grid/templates/pagination.html'
        })
        .constant('ColumnConfig', {
            sortable: true,
            visible: true,
            filterable: true,
            resizeable: false,
            type: 'text',
            filterType: 'text', // select, multi-select, date, date-range
            filterKey: undefined,
            key: undefined,
            label: undefined,
            placeholder: undefined,
            headerTemplate: undefined,
            filterTemplate: undefined,
            cellTemplate: undefined,
            headerClass: undefined,
            headerStyle: undefined,
            cellClass: undefined,
            cellStyle: undefined,
            sortPredicate: undefined,
            formatFunction: undefined,
            formatParameter: undefined,
            filterPredicate: undefined
        })
        .constant('TableConfig', {
            captionTemplate: undefined,
            caption: undefined,
            hoverable: true,
            selectionMode: 'single', //single, multiple, none
            displaySelectionCheckbox: false,
            exportable: false,
            exportFunction: undefined,
            sortable: true,
            sortFunction: undefined,
            filterable: true,
            filterFunction: undefined,
            resizeable: false,
            remoteDataFunction: undefined,
            dblClick: undefined,
            pageable: true,
            rowNumList: [5, 10, 15, 25, 50, 100],
            rowNum: 5,
            pagerContent: undefined,
            pagerId: undefined,
            pagerClass: undefined,
            events: {},
            filters: ['currency', 'date', 'json', 'lowercase', 'number', 'uppercase']
        });
})();