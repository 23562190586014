angular
    .module('CareGuard')
    .factory('htmlToPdfService', htmlToPdfService);

htmlToPdfService.$inject = ['$http'];

function htmlToPdfService($http) {

    const baseUrl = '/api/Marketing';

    const generatePdf = (file) => {
        return $http({
            url: `${baseUrl}/generatePdfFile`,
            method: 'POST',
            data: file,
            withCredentials: true,
            headers: { 'Content-Type': undefined },
            transformRequest: angular.identity,
            responseType: 'arraybuffer'
        });
    };

    return {
        generatePdf: generatePdf
    };
}
