// Optional support for explicit request for password authenticaiton
// This is useful when Client (CareHub) is configured with EnableLocalLogin = false and uses External Auth by default

(function () {
    'use strict';

    angular
        .module('CareGuard')
        .controller('internalAuthRedirectController', authResponseController);

    authResponseController.$inject = ['externalAuthService'];

    function authResponseController(externalAuthService) {

        externalAuthService.enableLoginPage();
        externalAuthService.redirectToIdentityServer(location.hash);
    }
})();