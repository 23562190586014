/**
* @name shopratesController
* @desc Contains logic for shop rates page.
**/
(function () {
    'use strict';

    angular
        .module('CareGuard')
        .controller('shopratesController', shopratesController);

    /** Inject services into controller. */
    shopratesController.$inject = ['$scope', 'caseData', 'lookupService', 'lifecompanyService', 'layoutService'];

    /**
    * @name shopratesController.
    * @desc Contains logic for shop rates page.
    * @param {object} $state - ui-router state.
    */
    function shopratesController($scope, caseData, lookupService, lifecompanyService, layoutService) {
        var vm = this;

        // variables
        var lifeCompanyData = undefined;
        vm.totals = [];
        vm.case = undefined;
        vm.lookupdata = undefined;
        vm.benefits = undefined;
        vm.type = undefined;
        vm.bestPrice = undefined;

        init();

        /*
         @name: init
         @desc: initilize module.
        */
        function init() {
            lifeCompanyData = lifecompanyService.get();
            vm.case = caseData;
            vm.quote = Enumerable.from(vm.case.Quotes).where('$.IsSelected').firstOrDefault(undefined);
            vm.type = vm.quote.QuoteType == 'MSA' ? 'cost' : 'payout';
            vm.benefits = vm.quote.Benefits;
            vm.lookupdata = {
                annuityTypes: lookupService.getByCategory('Annuity Type'),
                benefitTypes: lookupService.getByCategory('Benefit Type')
            };

            // create lookup arrays with key values
            vm.lookupdata.benefitTypes = [];
            var benefitTypes = lookupService.getByCategory('Benefit Type');
            for (var key in benefitTypes) {
                var val = benefitTypes[key];
                vm.lookupdata.benefitTypes[val.Key] = val.Value;
            }

            vm.lookupdata.annuityTypes = [];
            var annuityTypes = lookupService.getByCategory('Annuity Type');
            for (var key in annuityTypes) {
                var val = annuityTypes[key];
                vm.lookupdata.annuityTypes[val.Key] = val.Value;
            }

            // update header in layout
            layoutService.setSelected({ Name: vm.case.CaseName });

            // watch for select Quote dropdown
            $scope.$watch('vm.quote', function (newVal, oldVal) {
                if (newVal == oldVal) return;
                vm.type = vm.quote.QuoteType == 'MSA' ? 'cost' : 'payout';
                update();
            });
            $scope.$watch('vm.type', function (newVal, oldVal) {
                if (newVal == oldVal) return;
                update();
            });
            update();
        };

        /*
         @name: update
         @desc: add annuitant name to benefit for display purposes
        */
        function update() {
            vm.benefits = [];
            vm.totals = {};

            for (var key in vm.quote.Benefits) {
                var benefit = vm.quote.Benefits[key];

                // add annuitant names
                for (var idx in vm.case.Annuitants) {
                    var annuitant = vm.case.Annuitants[idx];
                    if (annuitant.Id == benefit.AnnuitantId) {
                        benefit.AnnuitantType = annuitant.AnnuitantType;
                        benefit.AnnuitantName = annuitant.Name;
                    }
                };

                for (var idx in benefit.LifeCompanies) {
                    var lc = benefit.LifeCompanies[idx];
                    lc.Name = Enumerable.from(lifeCompanyData).where('$.Id == "' + lc.Id + '"').select('$.Name').firstOrDefault(undefined);
                    lc.IsSelected = Enumerable.from(vm.quote.LifeCompanies).where('$.Id == "' + lc.Id + '"').select('$.IsSelected').firstOrDefault(undefined);
                    if (!vm.totals[lc.Id]) vm.totals[lc.Id] = {};

                    vm.totals[lc.Id].GuaranteedPayout = vm.totals[lc.Id].GuaranteedPayout ? vm.totals[lc.Id].GuaranteedPayout + lc.GuaranteedPayout : lc.GuaranteedPayout;
                    vm.totals[lc.Id].PaymentAmount = vm.totals[lc.Id].PaymentAmount ? vm.totals[lc.Id].PaymentAmount + lc.PaymentAmount : lc.PaymentAmount;
                    vm.totals[lc.Id].PremiumAmount = vm.totals[lc.Id].PremiumAmount ? vm.totals[lc.Id].PremiumAmount + lc.PremiumAmount : lc.PremiumAmount;
                }

                // create Life Company list, with totals
                //for (var idx in benefit.LifeCompanies) {
                //    var lc = benefit.LifeCompanies[idx];
                //    lc.Name = Enumerable.From(lifeCompanyData).Where('$.Id == "' + lc.Id + '"').Select('$.Name').FirstOrDefault(undefined);

                //    if (!vm.totals[lc.Id]) vm.totals[lc.Id] = {};

                //    vm.totals[lc.Id].GuaranteedPayout = vm.totals[lc.Id].GuaranteedPayout ? vm.totals[lc.Id].GuaranteedPayout + lc.GuaranteedPayout : lc.GuaranteedPayout;
                //    vm.totals[lc.Id].PaymentAmount = vm.totals[lc.Id].PaymentAmount ? vm.totals[lc.Id].PaymentAmount + lc.PaymentAmount : lc.PaymentAmount;
                //    vm.totals[lc.Id].PremiumAmount = vm.totals[lc.Id].PremiumAmount ? vm.totals[lc.Id].PremiumAmount + lc.PremiumAmount : lc.PremiumAmount;
                //};

                vm.benefits.push(benefit);
            };

            // find lowest total
            //if (vm.type == 'payout') {
            //    vm.bestPrice = Enumerable.From(vm.totals).Max('$.Value.GuaranteedPayout');
            //} else {
            //    vm.bestPrice = Enumerable.From(vm.totals).Min('$.Value.PremiumAmount');
            //}
        };
    }
})();