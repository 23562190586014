/**
* @name columnProvider
* @desc defines a column.
**/
(function () {
    'use strict';

    angular
        .module('smart-grid')
        .provider('columnProvider', columnProvider);

    /** Inject services. */
    columnProvider.$inject = ['ColumnConfig', 'TemplateConfig'];

    /**
    * @name columnProvider
    * @desc defines a column.
    */
    function columnProvider(defaultConfig, templateConfig) {
        function Column(config) {
            if (!(this instanceof Column)) {
                return new Column(config);
            }
            angular.extend(this, config);
        };

        this.setDefaultOption = function (option) {
            angular.extend(Column.prototype, option);
        };

        defaultConfig.headerTemplate = templateConfig.headerTemplate;
        defaultConfig.filterTemplate = templateConfig.filterTemplate;
        defaultConfig.cellTemplate = templateConfig.cellTemplate;
        this.setDefaultOption(defaultConfig);

        this.$get = function () {
            return Column;
        };
    }
})();


