/**
* @name teamService
* @desc Team Service.
**/
(function () {
    'use strict';

    angular
        .module('CareGuard')
        .factory('teamService', teamService);

    /** Inject services into service. */
    teamService.$inject = ['$http'];

    /**
    * @name teamService.
    * @desc Team Service.
    */
    function teamService($http) {
        var baseUrl = '/api/teams/';

        var service = {
            get: get,
            getById: getById,
            save: save,
            remove: remove
        };

        return service;

        /**
        * @name get.
        * @desc get list of all teams for the company.
        * @return list of teams.
        */
        function get(companyId) {
            return $http.get(baseUrl + 'get/' + companyId);
        };
        
        /**
        * @name getByID.
        * @desc get a team given Id.
        * @param {object} id - id of the team to get.
        * @return single team.
        */
        function getById(id) {
            return $http.get(baseUrl + 'getById/' + id);
        };

        /**
        * @name save.
        * @desc save a team.
        * @param {object} team - team to be saved.
        * @return saved team from database.
        */
        function save(team) {
            return $http.post(baseUrl + 'save', team);
        };

        /**
        * @name remove.
        * @desc remove a team.
        * @param {object} team - team to be deleted.
        */
        function remove(team) {
            return $http.post(baseUrl + 'delete', team);
        };
    }
})();