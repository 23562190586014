///**
//* @name chronovoDisabled
//* @desc disables element if not authorized
//* @usage <div chronovo-disabled="{roles:'Broker,Adjuster', status: 'Settled', statusField:'vm.case.Status'}" ></div>
//**/
//(function () {
//    'use strict';

//    angular
//        .module('CareGuard')
//        .directive('chronovoDisabled', chronovoDisabled);

//    /** Inject services into directive. */
//    chronovoDisabled.$inject = ['accountService'];

//    /**
//    * @name chronovoDisabled
//    * @desc disables element if not authorized
//    */
//    function chronovoDisabled(accountService) {
//        var directive = {
//            restrict: 'A',
//            priority: 100,
//            link: postLink
//        };

//        return directive;

//        function postLink($scope, $element, $attrs, $controller) {
            
//            // watch change in config 
//            $scope.$watch($attrs.chronovoDisabled, function (options) {
//                if (!options) return;
                
//                if (options.data && options.statusField) {
//                    setWatch(options);
//                } else if (options.roles) {
//                    var isInRole = accountService.isInRole(options.roles);
//                    set(isInRole);
//                };
//            });

//            /*
//            * @name setWatch
//            * @desc set attribute value when status field changes
//            */
//            function setWatch(options) {
//                $scope.$watch(options.statusField, function (value) {
//                    if (value == undefined) return;
                    
//                    for (var key in options.data) {
//                        var obj = options.data[key];
//                        console.log(obj);
//                        var isInRole = accountService.isInRole(obj.roles);
//                        if (!isInRole) {
//                            set(false);
//                        } else {
//                            var status = obj.status.split(',');
//                            for (var i = 0; i < status.length; i++) {
//                                if (status[i] == value) {
//                                    set(true);
//                                    break;
//                                }
//                            };
//                        };
//                    };
//                });
//            };

//            /*
//            * @name set
//            * @desc set attribute value
//            */
//            function set(val) {
//                $attrs.$set('disabled', val);
//            };
//        };
//    };
//})();
