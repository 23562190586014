(function () {
    'use strict';

    angular
        .module('CareGuard')
        .service('sidebarService', sidebarService);

    function sidebarService() {
        var self = this;
        self.isSidebarShown = false;

        self.toggleSidebar = function() {
            self.isSidebarShown = !self.isSidebarShown;
        }

        return self;
    }

})();