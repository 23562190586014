/**
* @name chronovoNavbar
* @desc Main naviation, see nav.html.
**/
(function () {
    'use strict';

    angular
        .module('CareGuard')
        .directive('chronovoNavbar', chronovoNavbar);

    /** Inject services into directive. */
    chronovoNavbar.$inject = ['$location'];

    /**
    * @name chronovoNavbar.
    * @desc chronovoNavbar Directive.
    * @param {object} $location.
    */
    function chronovoNavbar($location) {
        var directive = {
            restrict: 'A',
            replace: true,
            transclude: true,
            templateUrl: 'app/CareGuard/views/layouts/nav.html',
            link: postLink
        };

        return directive;

        function postLink($scope, $element, $attrs, $controller) {
            $scope.$watch(function () {
                return $location.path();
            }, function (newValue, oldValue) {
                $('li[data-match-route]', $element).each(function (k, li) {
                    var $li = angular.element(li),
                        pattern = $li.attr('data-match-route'),
                        regexp = new RegExp('^' + pattern + '$', ['i']);
                    if (regexp.test(newValue)) {
                        $li.addClass('active');
                    } else {
                        $li.removeClass('active');
                    }
                });
            });
        }
    }
})();