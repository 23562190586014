angular
    .module('CareGuard')
    .factory('claimService', claimService);

claimService.$inject = ['$http', 'claimStatusId', 'billTypeId', '$q'];

function claimService($http, claimStatusId, billTypeId) {
    // External API calls
    const baseRoute = `claims`;

    const searchClaims = (filters) => {
        return $http.get(`${apiUrl}/${baseRoute}/headers`, { params: filters });
    };

    const addClaim = ({ header, details }) => {
        return $http.post(`${functionsUrl}/${baseRoute}`, { header, details });
    };

    const getClaimDetailsByClaimId = (claimId) => {
        return $http.get(`${apiUrl}/${baseRoute}/${claimId}/details`);
    };

    const addClaimHeader = (claim) => {
        return $http.post(`${apiUrl}/${baseRoute}/headers`, claim);
    };

    const addClaimDetails = ({ claimId, details }) => {
        return $http.post(`${apiUrl}/${baseRoute}/${claimId}/details`, { details });
    };

    const getClaimStatuses = () => {
        return $http.get(`${apiUrl}/${baseRoute}/statuses`);
    };

    const updateClaimStatuses = (request) => {
        return $http.patch(`${apiUrl}/${baseRoute}/statuses`, request);
    };

    const getClaimDetailStatuses = () => {
        return $http.get(`${apiUrl}/${baseRoute}/details/statuses`);
    };

    const zeroPayClaims = (filters) => {
        return $http.patch(`${apiUrl}/${baseRoute}/details/statuses`, filters);
    };

    const deleteClaimHeaderById = (id) => {
        return $http.delete(`${apiUrl}/${baseRoute}/headers/${id}`);
    };

    const resetClaimForBillReview = (claimId) => {
        return $http.patch(`${apiUrl}/${baseRoute}/${claimId}/billReview`);
    };

    const removeFees = (claimsIds) => {
        return $http.patch(`${apiUrl}/${baseRoute}/fees`, claimsIds);
    };

    const getEditablePayeeAddressIds = (payeeAddressIds) => {
        return $http.post(`${apiUrl}/${baseRoute}/payees/addresses/editable`, payeeAddressIds);
    };

    const getClaimHeadersByIds = (claimIds) => {
        return $http.post(`${apiUrl}/${baseRoute}/headers/bulk`, claimIds);
    };

    const updateClaimPayeeWithAddress = (request) => {
        return $http.patch(`${apiUrl}/${baseRoute}/payees/addresses`, request);
    };
    
    const assignClaimToPreBillReviewFlow = (claimId) => {
        return $http.patch(`${apiUrl}/${baseRoute}/${claimId}/preBillReview`);
    };

    const getClaimDetailDiagnosisPointers = (claimId) => {
        return $http.get(`${apiUrl}/${baseRoute}/${claimId}/pointers`);
    };

    const saveClaimDetailDiagnosisPointers = (claimId, pointers) => {
        return $http.post(`${apiUrl}/${baseRoute}/${claimId}/pointers`, pointers);
    };

    const getClaimDetailPlacesOfService = (claimId) => {
        return $http.get(`${apiUrl}/${baseRoute}/${claimId}/placeOfService`);
    };

    const saveClaimDetailPlacesOfService = (claimId, placesOfService) => {
        return $http.post(`${apiUrl}/${baseRoute}/${claimId}/placeOfService`, placesOfService);
    };

    //Azure functions calls
    const cloneClaim = (claimId, cloneType) => {
        return $http.post(`${functionsUrl}/${baseRoute}/clone`, { parentClaimId: claimId, cloneType });
    };

    // CH C# controller calls
    const baseUrl = '/api/claims';

    const getByID = (claimId) => {
        return $http.get(`${baseUrl}/getByID/${claimId}`);
    };

    let claim = {};

    const setClaimDetails = function (claimData) {
        if (!claimData) return;

        claim = claimData;
    };

    const filterClaimsCH = (memberid, membernumber, membername, claimid, payeeid, claimnumber, startreceiveddate, endreceiveddate, startservicedate, endservicedate, billreviewstartsentdate, billreviewendsentdate, billreviewstartreceiveddate, billreviewendreceiveddate, checknumber, claimstatusid, billtypeid, paymentflag, startpaiddate, endpaiddate, payor, isreconsideration, stopwhenreceived, pageindex, pagesize, sortcolumn, sortorder) => {
        return $http.get(`${baseUrl}/filterClaims/${memberid}/${membernumber}/${membername}/${claimid}/${payeeid}/${claimnumber}/${startreceiveddate}/${endreceiveddate}/${startservicedate}/${endservicedate}/${billreviewstartsentdate}/${billreviewendsentdate}/${billreviewstartreceiveddate}/${billreviewendreceiveddate}/${checknumber}/${claimstatusid}/${billtypeid}/${paymentflag}/${startpaiddate}/${endpaiddate}/${payor}/${isreconsideration}/${stopwhenreceived}/${pageindex}/${pagesize}/${sortcolumn}/${sortorder}`);
    };

    function filterChangeHealthClaims(memberid, membernumber, membername, claimid, payeeid, claimnumber, startreceiveddate, endreceiveddate, startservicedate, endservicedate, checknumber, claimstatusid, billtypeid, startpaiddate, endpaiddate, payor, payperiodid, pageindex, pagesize, sortcolumn, sortorder) {
        return $http.get(baseUrl + '/filterChangeHealthClaims/' + memberid + '/' + membernumber + '/' + membername + '/' + claimid + '/' + payeeid + '/' + claimnumber + '/' + startreceiveddate + '/' + endreceiveddate + '/' + startservicedate + '/' + endservicedate + '/' + checknumber + '/' + claimstatusid + '/' + billtypeid + '/' + startpaiddate + '/' + endpaiddate + '/' + payor + '/' + payperiodid + '/' + pageindex + '/' + pagesize + '/' + sortcolumn + '/' + sortorder);
    }

    const filterAmethystClaims = (memberid, membernumber, membername, claimid, payeeid, claimnumber, startreceiveddate, endreceiveddate, startservicedate, endservicedate, checknumber, claimstatusid, billtypeid, startpaiddate, endpaiddate, pageindex, pagesize, sortcolumn, sortorder) => {
        return $http.get(`${baseUrl}/filterAmethystClaims/${memberid}/${membernumber}/${membername}/${claimid}/${payeeid}/${claimnumber}/${startreceiveddate}/${endreceiveddate}/${startservicedate}/${endservicedate}/${checknumber}/${claimstatusid}/${billtypeid}/${startpaiddate}/${endpaiddate}/${pageindex}/${pagesize}/${sortcolumn}/${sortorder}`);
    };

    const exportChangeHealthClaims = (vmChangeHealth) => {
        return $http.post(`${baseUrl}/exportChangeHealthClaims`, vmChangeHealth);
    };

    const exportAmethystClaims = (membernumber, membername, claimid, payeeid, claimnumber, startreceiveddate, endreceiveddate, startservicedate, endservicedate, checknumber, claimstatusid, billtypeid, startpaiddate, endpaiddate) => {
        return $http.get(`${baseUrl}/exportAmethystClaims/${membernumber}/${membername}/${claimid}/${payeeid}/${claimnumber}/${startreceiveddate}/${endreceiveddate}/${startservicedate}/${endservicedate}/${checknumber}/${claimstatusid}/${billtypeid}/${startpaiddate}/${endpaiddate}`);
    };

    const changeStatusCHC = (filterdata) => {
        return $http.post(`${baseUrl}/changeStatusCHC`, filterdata);
    };

    const changeStatusAM = (filterdata) => {
        return $http.post(`${baseUrl}/changeStatusAM`, filterdata);
    };

    const generateCustomEOR = (idlist) => {
        return $http.post(`${baseUrl}/generateCustomEOR`, idlist);
    };

    const zeroPay = (filterdata) => {
        return $http.post(`${baseUrl}/zeroPay`, filterdata);
    };

    const zeroPayCHC = (filterdata) => {
        return $http.post(`${baseUrl}/zeroPayCHC`, filterdata);
    };

    const zeroPayAM = (filterdata) => {
        return $http.post(`${baseUrl}/zeroPayAM`, filterdata);
    };

    const completeAction = (filterdata) => {
        return $http.post(`${baseUrl}/completeAction`, filterdata);
    };

    const applyReversal = (filterdata) => {
        return $http.post(`${baseUrl}/applyReversal`, filterdata);
    };

    const getSearchClaims = () => {
        return baseUrl + '/getClaimsBySearchId/';
    };

    const getSearchClaimsQuery = () => {
        return baseUrl + '/searchClaims/';
    };

    const getSearchCheckNumberQuery = () => {
        return baseUrl + '/searchCheckNumbers/';
    };

    const getSearchParentClaimsQuery = () => {
        return baseUrl + '/searchParentClaims/';
    };

    const saveClaim = (claims) => {
        return $http.post(`${baseUrl}/saveClaim`, claims);
    };

    const addClaimProvider = (provider) => {
        return $http.post(`${baseUrl}/addClaimProvider`, provider);
    };

    const saveClaimProviders = (provider) => {
        return $http.post(`${baseUrl}/saveClaimProviders`, provider);
    };

    const saveClaimDetail = (lineitem, claimId) => {
        return $http.post(`${baseUrl}/saveClaimDetail`, { LineItems: lineitem, ClaimId: claimId });
    };

    const saveClaimDiagnosis = (diagnoses, claimId) => {
        return $http.post(`${baseUrl}/saveClaimDiagnosis`, { Diagnoses: diagnoses, ClaimId: claimId });
    };

    const saveClaimTransaction = (amethystClaimTransaction) => {
        return $http.post(`${baseUrl}/saveClaimTransaction`, amethystClaimTransaction);
    };

    const recalculateFees = (claimId) => {
        return $http.put(`${baseUrl}/${claimId}/recalculateFees`);
    }

    const deleteLineItem = (lineItem) => {
        return $http.post(`${baseUrl}/deleteLineItem`, lineItem);
    };

    const deleteProvider = (provider) => {
        return $http.post(`${baseUrl}/deleteProvider`, provider);
    };

    const deleteClaim = (claimID) => {
        return $http.get(`${baseUrl}/deleteClaim/${claimID}`);
    };

    const getVendorAddress = (payeeId) => {
        return $http.get(`${baseUrl}/getVendorAddress/${payeeId}`);
    };

    const getVendorByTaxID = (taxID) => {
        return $http.get(`${baseUrl}/getVendorByTaxID/${taxID}`);
    };

    const getImageFile = (memberNumber, documentName, extension) => {
        return $http.get(`${baseUrl}/getImageFile/${memberNumber}/${documentName}/${extension}`);
    };

    const getAmethystTransactions = (memberID) => {
        return $http.get(`${baseUrl}/getAmethystTransactions/${memberID}`);
    };

    const getLastSyncDateTime = () => {
        return $http.get(`${baseUrl}/getLastSyncDateTime`);
    };

    const getCHPayPeriods = () => {
        return $http.get(`${baseUrl}/getCHPayPeriods`);
    };

    const changeMemberAccountForClaim = (oldmembernumber, newmembernumber, claimid) => {
        return $http.get(`${baseUrl}/changeMemberAccountForClaim/${oldmembernumber}/${newmembernumber}/${claimid}`);
    };

    const stopPaymentRequests_Create = (stoppaymentrequest) => {
        return $http.post(`${baseUrl}/stopPaymentRequestsCreate`, stoppaymentrequest);
    };

    const stopPaymentRequests_Get = (sprid, memberid, claimid) => {
        return $http.get(`${baseUrl}/stopPaymentRequestsGet/${sprid}/${memberid}/${claimid}`);
    };

    const stopPaymentRequests_SendRequestEmail = (sprid) => {
        return $http.get(`${baseUrl}/stopPaymentRequestsSendRequestEmail/${sprid}`);
    };

    const stopPaymentRequests_SendApprovalEmail = (stoppaymentrequestlist) => {
        return $http.post(`${baseUrl}/stopPaymentRequestsSendApprovalEmail`, stoppaymentrequestlist);
    };

    const stopPaymentRequests_Approve = (sprid) => {
        return $http.get(`${baseUrl}/stopPaymentRequestsApprove/${sprid}`);
    };

    const stopPaymentRequests_ApproveList = (spridlist) => {
        return $http.post(`${baseUrl}/stopPaymentRequestsApprove`, spridlist);
    };

    const stopPaymentRequests_Delete = (sprid) => {
        return $http.post(`${baseUrl}/stopPaymentRequestsDelete/${sprid}`);
    };

    const stopPaymentRequests_Submit = (sprid) => {
        return $http.post(`${baseUrl}/stopPaymentRequestsSubmit/${sprid}`);
    };

    const getPayors = (param) => {
        return $http.get(`${baseUrl}/getPayors/${param}`);
    };

    const getMissingAuths = (payperiodid) => {
        return $http.get(`${baseUrl}/getMissingAuths/${payperiodid}`);
    };

    const searchServiceCodes = (searchBy, codeTypes) => {
        return $http.post(`${baseUrl}/searchServiceCodes`, { searchBy, codeTypes })
    };

    const getMemberAsPayee = (memberId) => {
        return $http.get(`${baseUrl}/getMemberAsPayee?memberId=${memberId}`);
    };

    const getAllPaymentFlags = () => {
        return $http.get(`${baseUrl}/getAllPaymentFlags`);
    };

    function searchClaim(query) {
        return $http.get(baseUrl + 'searchClaims?searchPhrase=' + query);
    }

    function searchCheckNumbers(query) {
        return $http.get(baseUrl + 'SearchCheckNumbers?searchPhrase=' + query);
    }

    const getClaimHistory = (claimID) => {
        return $http.get(`${baseUrl}/getClaimHistory?claimId=${claimID}`);
    };

    const getClaimHierarchy = (claimID) => {
        return $http.get(`${baseUrl}/getClaimHierarchy?claimId=${claimID}`);
    };

    const removeSavings = (data) => {
        return $http.post(`${baseUrl}/removeSavings`, data);
    };

    const getRuleResults = (claimID) => {
        return $http.get(`${baseUrl}/getRuleResults?claimId=${claimID}`);
    };

    const getBillReviewRuleResults = (claimID) => {
        return $http.get(`${baseUrl}/getBillReviewRuleResults?claimId=${claimID}`);
    };

    const assignBillReviewVendor = (claimId) => {
        return $http.post(`${baseUrl}/assignBillReviewVendor/`, claimId);
    };

    const getDataForClaimReductions = (claimId) => {
        return $http.get(`${baseUrl}/getDataForClaimReductions/${claimId}`)
            .then(res => {
                if (res?.data?.length) {
                    res.data.sort((a, b) => a.LineNumber - b.LineNumber);
                }
                return res;
            });
    };    

    // Utility
    function isStatusAPaidStatus(statusId) {
        return [
            claimStatusId.Paid,
            claimStatusId.PaidByACH,
            claimStatusId.PaidByMember,
            claimStatusId.Reversed,
            claimStatusId.PaidReversedRefunded,
            claimStatusId.PaidReversedRefundPending,
            claimStatusId.TransferInProgress,
            claimStatusId.Transferred,
            claimStatusId.PaidRefundByTransferInProgress,
            claimStatusId.PaidRefundedByTransfer,
            claimStatusId.BillPayProcessing,
            claimStatusId.NonRedeemedPayment,
            claimStatusId.ReadyForReissue,
        ].includes(statusId);
    }

    function doesStatusAllowInternalReduction(statusId) {
        return [
            claimStatusId.NonClassicRateReceived,
            claimStatusId.HoldPaymentForWCEDI,
            claimStatusId.Reconsideration,
            claimStatusId.ProviderNeedsReview,
            claimStatusId.MemberNeedsReview,
            claimStatusId.DuplicateForReview,
            claimStatusId.OnHold,
            claimStatusId.ReadyForPayment,
            claimStatusId.BillReviewReceived,
            claimStatusId.ReceiptAudit,
            claimStatusId.PortalReceiptReceived,
            claimStatusId.PendingInformation,
            claimStatusId.ReadyForBillReview,
            claimStatusId.Entered,
        ].includes(statusId);
    }

    function isBasicEditableStatus(statusId) {
        return [
            claimStatusId.AwaitingBillReview,
            claimStatusId.Reconsideration,
            claimStatusId.BillReviewReceived,
            claimStatusId.Entered,
            claimStatusId.ReadyForBillReview,
            claimStatusId.ReadyForPayment,
            claimStatusId.OnHold,
            claimStatusId.MemberNeedsReview,
            claimStatusId.DuplicateForReview,
            claimStatusId.ProviderNeedsReview,
            claimStatusId.PendingInformation,
            claimStatusId.ACHOnlyReadyForPayment,
            claimStatusId.ReceiptAudit,
            claimStatusId.PortalReceiptReceived,
            claimStatusId.NonClassicRateReceived,
            claimStatusId.HoldPaymentForWCEDI,
            claimStatusId.ManualEntry
        ].includes(statusId);
    }

    // status which should be set once to initiate some process and no longer re-set for the claim
    function isOneTimeSystemStatus(statusId) {
        return [
            claimStatusId.Created,
            claimStatusId.ManualEntry
        ].includes(statusId);
    }

    function canBeZeroPaid(claim) {
        if (!claim || !claim.claimStatusID) return false;

        if (isStatusAPaidStatus(claim.claimStatusID)) return false;

        if (claim.billTypeID && claim.billTypeID === billTypeId.Rx) return false;

        return true;
    }

    function isClaimNonRedeemed(statusId) {
        return [
            claimStatusId.NonRedeemedPayment,
            claimStatusId.ReadyForReissue
        ].includes(statusId);
	}

    function isClaim837BillType(billTypeIdValue) {
        switch (billTypeIdValue) {
            case billTypeId.CMS1500:
            case billTypeId.UB04INPT:
            case billTypeId.UB04OUTPT:
                return true;
            default:
                return false;
        }
    }

    return {
        // External API calls
        searchClaims,
        addClaim,
        getClaimDetailsByClaimId,
        addClaimHeader,
        addClaimDetails,
        getClaimStatuses,
        getClaimDetailStatuses,
        zeroPayClaims,
        deleteClaimHeaderById,
        resetClaimForBillReview,
        removeFees,
        getEditablePayeeAddressIds,
        getClaimHeadersByIds,
        updateClaimStatuses,
        updateClaimPayeeWithAddress,
        assignClaimToPreBillReviewFlow,
        getClaimDetailDiagnosisPointers,
        saveClaimDetailDiagnosisPointers,
        getClaimDetailPlacesOfService,
        saveClaimDetailPlacesOfService,

        // CH C# controller calls
        getByID,
        setClaimDetails,
        filterClaimsCH,
        filterChangeHealthClaims,
        filterAmethystClaims,
        changeStatusCHC,
        changeStatusAM,
        generateCustomEOR,
        zeroPay,
        zeroPayCHC,
        zeroPayAM,
        exportChangeHealthClaims,
        exportAmethystClaims,
        getSearchClaimsQuery,
        getSearchCheckNumberQuery,
        getVendorAddress,
        getVendorByTaxID,
        cloneClaim,
        deleteClaim,
        addClaimProvider,
        deleteProvider,
        saveClaimProviders,
        saveClaim,
        saveClaimDetail,
        saveClaimDiagnosis,
        recalculateFees,
        deleteLineItem,
        getImageFile,
        completeAction,
        applyReversal,
        getLastSyncDateTime,
        getAmethystTransactions,
        saveClaimTransaction,
        getCHPayPeriods,
        changeMemberAccountForClaim,
        stopPaymentRequests_Create,
        stopPaymentRequests_Get,
        stopPaymentRequests_SendRequestEmail,
        stopPaymentRequests_SendApprovalEmail,
        stopPaymentRequests_Approve,
        stopPaymentRequests_ApproveList,
        stopPaymentRequests_Delete,
        stopPaymentRequests_Submit,
        getPayors,
        getMissingAuths,
        searchServiceCodes,
        getMemberAsPayee,
        getAllPaymentFlags,
        searchClaim,
        searchCheckNumbers,
        getSearchClaims,
        getClaimHistory,
        getClaimHierarchy,
        getSearchParentClaimsQuery,
        removeSavings,
        getRuleResults,
        assignBillReviewVendor,
        getBillReviewRuleResults,
        getDataForClaimReductions,

        // Utility
        isStatusAPaidStatus,
        isBasicEditableStatus,
        isOneTimeSystemStatus,
        doesStatusAllowInternalReduction,
        canBeZeroPaid,
        isClaimNonRedeemed,
        isClaim837BillType
    };
}
