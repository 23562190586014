/**
* @name layoutService
* @desc layout Service.
**/
(function () {
    'use strict';

    angular
        .module('CareGuard')
        .factory('layoutService', layoutService);

    /** Inject services into service. */
    layoutService.$inject = ['$http'];

    /**
    * @name layoutService.
    * @desc layout Service.
    */
    function layoutService($http) {
        var annuitant = undefined;
        var selectedCallback = undefined;
        var service = {
            setSelected: setSelected,
            getSelected: getSelected
        };
        
        return service;

        /**
        * @name setSelected.
        * @desc set current selected annuitant.
        * @param {object} clmnt - selected annuitant.
        */
        function setSelected(clmnt) {
            annuitant = {
                Name: clmnt.Name
            };
            if (selectedCallback)
                selectedCallback(annuitant);
        };

        /**
        * @name getSelected.
        * @desc get current selected annuitant.
        * @param {function} func - call function when selected annuitant is changed.
        */
        function getSelected(func) {
            selectedCallback = func;
            return annuitant;
        };
    }
})();