/**
* @name GridController
* @desc grid controller.
**/
(function () {
    'use strict';

    angular
        .module('CareGuard')
        .controller('documentsController', documentsController); 

    /** Inject services into controller. */
    documentsController.$inject = [
        "$scope",
        "$state",
        "$timeout",
        "$q",
        "documentService",
        "lookupService",
        "accountService",
        "memberService",
        "memberFlagsService",
        "memberID",
        "utilService",
        "fileService",
        "$toastr",
        "claimService",
        "documentTypeId",
        "billReviewService"
    ];

    /**
    * @name GridController.
    * @desc grid controller.   
    */
    function documentsController(
        $scope,
        $state,
        $timeout,
        $q,
        documentService,
        lookupService,
        accountService,
        memberService,
        memberFlagsService,
        memberID,
        utilService,
        fileService,
        $toastr,
        claimService,
        documentTypeId,
        billReviewService
    ) {

        // variables
        var vm = this;

        vm.member = {};
        vm.members = {};
        vm.memberflags = {};

        vm.lookupdata = {};
        vm.memberDocuments = {};
        vm.isNewMemberDocuments = false;
        vm.newMemberDocuments = new Array();
        vm.files = [];
        vm.file = {};
        vm.checkedAll = false;
        vm.memberStatements = {};

        vm.pageSizes = {
            data: [
                { Name: 5, Value: 5 },
                { Name: 10, Value: 10 },
                { Name: 20, Value: 20 },
                { Name: 50, Value: 50 },
                { Name: 100, Value: 100 },
                { Name: 500, Value: 500 },
                { Name: 1000, Value: 1000 }
            ]
        };
        
        vm.filterData = {
            TotalPages: 1,
            TotalRows: undefined,
            PageSize: 20,
            PageIndex: 1,
            SortColumn: 'CreatedDate',
            SortOrder: 'DESC',

            MemberID: undefined,
            MemberNumber: undefined,
            MemberLastName: undefined,
            DocumentTypeID: undefined,
            CreatedDate: undefined,
            ClaimID: undefined,

            MemberFirstName: undefined,
            AccountStatusID: undefined,
            SettlementMonth: undefined,
            StartDate: null,
            EndDate: null,
            IsDetailed: false,
            IncludePaperless: false
        };

        vm.settlementMonth = {
            data: [
                { Name: "January", Value: 1 },
                { Name: "February", Value: 2 },
                { Name: "March", Value: 3 },
                { Name: "April", Value: 4 },
                { Name: "May", Value: 5 },
                { Name: "June", Value: 6 },
                { Name: "July", Value: 7 },
                { Name: "August", Value: 8 },
                { Name: "September", Value: 9 },
                { Name: "October", Value: 10 },
                { Name: "November", Value: 11 },
                { Name: "December", Value: 12 }
            ]
        };


        // functions    
        vm.changeSort = changeSort;
        vm.resetFilterInfo = resetFilterInfo;
        vm.refresh = refresh;
        vm.navigate = navigate;
        vm.isEdit = isEdit;
        vm.toggleCheckAll = toggleCheckAll;

        vm.getMemberDocuments = getMemberDocuments;
        vm.filterMemberDocuments = filterMemberDocuments;
        vm.closeNewDocument = closeNewDocument;
        vm.addNewMemberDocument = addNewMemberDocument; //dummy method for refreshing
        vm.addNewMemberDocuments = addNewMemberDocuments;
        vm.updateDocument = updateDocument;
        vm.cancelEditDocument = cancelEditDocument;
        vm.deleteMemberDocument = deleteMemberDocument;

        vm.filterMembers = filterMembers;

        vm.getDocumentFile = getDocumentFile; //downloads from server to user (allows viewing of file)

        $scope.addfile = addfile;
        $scope.addfiles = addfiles;

        vm.hasRole = hasRole;

        vm.isMemberStatement = isMemberStatement;

        vm.isAnnualAccounting = isAnnualAccounting;

        vm.isAmetros1099 = isAmetros1099;

        let _memberStatementDocumentTypeId = null;

        let _annualAccountingDocumentTypeId = null;

        let _ametros1099DocumentTypeId = null;

        init();

        /* 
        * @name init
        * @desc initialize module
        */
        function init() {            
            if (memberID) {
                memberService.getById(memberID).then(function (result) {
                    vm.member = result.Data;
                });
                memberFlagsService.getMemberFlags(memberID).then(function (resultset) {
                    vm.memberflags = resultset.Data;

                });

            }
            else {
                vm.member = undefined;
                vm.members = {};
            }

            //look ups and drop downs
            lookupService.getLookUp("documentType").then(function (result) {
                vm.lookupdata.documentTypes = result.Data;
                _memberStatementDocumentTypeId = (vm.lookupdata.documentTypes.find(x => x.DataSetValue == 'Member Statement') || {}).DataSetID;
                _annualAccountingDocumentTypeId = (vm.lookupdata.documentTypes.find(x => x.DataSetValue == 'Annual Accounting') || {}).DataSetID;
                _ametros1099DocumentTypeId = (vm.lookupdata.documentTypes.find(x => x.DataSetValue == 'Ametros 1099') || {}).DataSetID;
            });
            lookupService.getLookUp("accountStatus").then(function (result) {
                vm.lookupdata.accountStatuses = result.Data;
            });

            resetFilterInfo();
           
            if (memberID) {
                getMemberDocuments();
            }
            else {
                filterMembers();
            }

        };

        //filter Members
        function filterMembers() {
            if (vm.filterData.MemberNumber == "")
                vm.filterData.MemberNumber = null;
            if (vm.filterData.MemberLastName == "")
                vm.filterData.MemberLastName = null;
            if (vm.filterData.MemberFirstName == "")
                vm.filterData.MemberFirstName = null;
            if (vm.filterData.SSNLastFour == "")
                vm.filterData.SSNLastFour = null;
            if (vm.filterData.FundType == "")
                vm.filterData.FundType = null;
            if (vm.filterData.SettlementMonth == "")
                vm.filterData.SettlementMonth = null;

            documentService.searchMembers(vm.filterData.MemberNumber,
                vm.filterData.MemberLastName,
                vm.filterData.MemberFirstName,
                vm.filterData.SSNLastFour,
                vm.filterData.DateOfBirth || '01-01-0001',
                vm.filterData.AccountStatusID,
                vm.filterData.SettlementMonth,
                vm.filterData.FundType,
                vm.filterData.PageIndex,
                vm.filterData.PageSize,
                vm.filterData.SortColumn,
                vm.filterData.SortOrder).then(function (resultset) {
                vm.members = resultset.Data;

                try {

                    vm.filterData.TotalRows = vm.members[0].TotalRows;
                    vm.filterData.TotalPages = Math.ceil(vm.filterData.TotalRows / vm.filterData.PageSize);

                }
                catch (ex) {
                    vm.filterData.TotalPages = 1;
                };

            });

        };

        function filterMemberDocuments() {
            vm.filterData.PageIndex = 1;
            getMemberDocuments();
        }

        //Member Documents
        function getMemberDocuments() {
            if (memberID) {
                documentService.getMemberDocuments({ memberID, ...vm.filterData, pageNumber: vm.filterData.PageIndex }).then(({ data: documents }) => {
                    vm.memberDocuments = documents.items;
                    vm.filterData.TotalRows = documents.totalRows;
                    vm.filterData.TotalPages = documents.totalPages;
                });
            }
        };

        //Closes the add new document window
        function closeNewDocument() {
            vm.isNewMemberDocuments = false;
            vm.newMemberDocuments = new Array();
            vm.files = {};
            vm.file = {};

        };

        //DUMMY METHOD FOR REFRESHING
        function addNewMemberDocument() {
        };

        function sendDocumentRequest(document) {

            var documentRequest = {};

            documentRequest.MemberID = memberID;            
            documentRequest.DocumentTypeID = document.DocumentTypeID || null;
            documentRequest.SentDate = utilService.formatDateForApi(document.SentDate);
            documentRequest.ConfirmedDate = utilService.formatDateForApi(document.ConfirmedDate);
            documentRequest.ClaimID = document.ClaimID || null;
            documentRequest.ITCN = document.ITCN || null;
            documentRequest.Description = document.Description || null;
            documentRequest.PhysicalPath = document.PhysicalPath;

            return documentService.addDocument(documentRequest)
                .then((d) => {
                    if (documentRequest.ClaimID && documentRequest.DocumentTypeID == documentTypeId.EOR) {
                        return billReviewService.addDocumentEor(documentRequest.ClaimID, d.data.id);
                    }
                    return $q.when(true);
                });
        }

        function validateMemberDocumentRequest(claimId, paramDocumentTypeId, documentId) {
            if (!claimId) {
                //validations below are for case when claimId is supplied. so returning from here when it's not.
                return $q.when(true);
            }

            paramDocumentTypeId = parseInt(paramDocumentTypeId);

            return claimService.searchClaims({ claimIDs: [claimId], memberIDs: [memberID] }).then(({ data: { items: memberClaims } }) => {
                if (!memberClaims?.length) {
                    $toastr.show('Invalid ClaimID for Member.', 'warning');
                    return $q.reject();
                }

                var paidClaim = memberClaims.find(c => c.paidDate);
                if (paidClaim) {
                    $toastr.show('EOR can not be added to paid claims.', 'warning');
                    return $q.reject();
                }

                return documentService.getMemberDocuments({ memberID, claimId }).then(({ data: { items: documents } }) => {
                    var existingDocumentTypesForClaim = documents?.filter(x => !documentId || x.id != documentId)
                        .map(x => x.documentTypeID);

                    if (paramDocumentTypeId == documentTypeId.EORState && existingDocumentTypesForClaim?.includes(documentTypeId.EORState)) {
                        $toastr.show('Only one EOR Image (State) is allowed.', 'warning');
                        return $q.reject();
                    }

                    var eorDocTypes = [documentTypeId.EOR, documentTypeId.EORState];
                    if (!eorDocTypes.includes(paramDocumentTypeId) && existingDocumentTypesForClaim?.some(x => !eorDocTypes.includes(x))) {
                        $toastr.show('Only one Claim Image is allowed.', 'warning');
                        return $q.reject();
                    }

                    return $q.when(true);
                });
            });
        }

        //REAL METHOD FOR SAVING NEW DOCUMENTS
        function addNewMemberDocuments() {
            //files detected
            if (vm.newMemberDocuments.length > 0) {
                var baddocs = Enumerable.from(vm.newMemberDocuments).where(function (x) { return x.DocumentTypeID == undefined || x.DocumentTypeID == 0 }).toArray();
                
                if (baddocs.length > 0) {
                    $toastr.show('Document Type not detected for ' + baddocs.length.toString() + ' files!', 'warning');
                    return;
                }

                if (vm.newMemberDocuments.some(x => isMemberStatement(x))) {
                    $toastr.show('Member statement manual uploading is prohibited. ' +
                        'If you need to upload member statement, please create a HelpDesk request.', 'warning');
                    return;
                }

                if (vm.newMemberDocuments.some(x => isAnnualAccounting(x))) {
                    $toastr.show('Annual accounting manual uploading is prohibited. ' +
                        'If you need to upload an annual accounting document, please create a HelpDesk request.', 'warning');
                    return;
                }

                if (vm.newMemberDocuments.some(x => isAmetros1099(x))) {
                    $toastr.show('Ametros 1099 manual uploading is prohibited. ' +
                        'If you need to upload an Ametros 1099 document, please create a HelpDesk request.', 'warning');
                    return;
                }

                //loop not needed at this time; but left for easier conversion to multiple files if desired
                //currently take only first file
                var newDocument = vm.newMemberDocuments[0];
                validateMemberDocumentRequest(newDocument.ClaimID, newDocument.DocumentTypeID).then(_ => {
                    uploadFile(newDocument).then(result => {
                        newDocument.PhysicalPath = result.data.fileName;

                        return sendDocumentRequest(newDocument).then(_ => {
                            getMemberDocuments();
                            closeNewDocument();
                            $toastr.show("Member document uploaded successfully.", 'success');
                        }).catch(err => {
                            documentService.deleteMemberAzureBlobDocument({ memberNumber: vm.member.MemberNumber, filename: newDocument.PhysicalPath });
                            throw err;
                        });
                    }).catch(err => {
                        $toastr.show(`${utilService.parseErrorMessage(err)}`, `error`);
                    });
                });               
            }
            else {
                $toastr.show("No Files detected!", 'warning');
            }

        };

        //this will attempt to get the file name, and validate file types
        //this will also add the filename to newMemberDocuments for ui
        function addfile(file) {
            vm.file = file;

            var f = file; //used for preview
            switch (true) {
                case f.name.toLowerCase().endsWith(".txt"):
                    var reader = new FileReader();
                    reader.onload = (function (theFile) {
                        return function (e) {

                            var newDocument = {
                                PhysicalPath: theFile.name,
                                DataText: reader.result
                            };

                            vm.newMemberDocuments.push(newDocument);

                            document.getElementById("newDocumentRefresh").click();
                        };
                    })(f);
                    reader.readAsText(f);

                    break;

                case f.name.toLowerCase().endsWith(".jpg"):
                case f.name.toLowerCase().endsWith(".png"):

                    var reader = new FileReader();
                    reader.onload = (function (theFile) {
                        return function (e) {

                            var newDocument = {
                                PhysicalPath: theFile.name,
                                DataURL: reader.result
                            };

                            vm.newMemberDocuments.push(newDocument);

                            document.getElementById("newDocumentRefresh").click();
                        };
                    })(f);
                    reader.readAsDataURL(f);

                    break;
                case f.name.toLowerCase().endsWith(".doc"):
                case f.name.toLowerCase().endsWith(".docx"):
                case f.name.toLowerCase().endsWith(".pdf"):
                case f.name.toLowerCase().endsWith(".xls"):
                case f.name.toLowerCase().endsWith(".xlsx"):
                    var reader = new FileReader();
                    reader.onload = (function (theFile) {
                        return function (e) {

                            var newDocument = {
                                PhysicalPath: theFile.name,
                                DataURL: reader.result
                            };

                            vm.newMemberDocuments.push(newDocument);

                            document.getElementById("newDocumentRefresh").click();
                        };
                    })(f);
                    reader.readAsDataURL(f);


                    break;
                default:
                    $toastr.show("File Unsupported! Please inform your IT Team.", 'warning');
            }
        };

        //used to simplify files vs files
        function addfiles(files) {
            vm.files = files;

            //reset array
            vm.newMemberDocuments = new Array();

            ////ONLY ALLOW FIRST FILE TO BE UPLOADED
            addfile(vm.files[0]);
        };

        //This will take all vm.files and upload them to the server
        //updated to received filename, to restrict file drop to one at a time (and validate the file being moved)
        function uploadFile(document) {
            var file = vm.files[0];

            if (file) {
                const request = {
                    memberNumber: vm.member.MemberNumber,
                    file: file,
                    filename: document.PhysicalPath,
                };

                return documentService.uploadMemberAzureBlobDocument(request);
            }

            return $q.reject('File is not found');
        }

        function getDocumentFile(fileNameWithExt) {
            return documentService.downloadMemberAzureBlobDocument({ memberNumber: vm.member.MemberNumber, filename: fileNameWithExt }).then(response => {
                if (Array.isArray(response?.data) && !response?.data?.length) {
                    $toastr.show(`Document not found.`, `warning`);
                    return;
                }
                utilService.processResponse({ response });
            });            
        }

        //Save Member Document - Single Document Only - using the existing stored procedure
        function updateDocument(documentID) {
            var editDocument = Enumerable.from(vm.memberDocuments).where(function (x) { return x.id == documentID }).single();
            editDocument.claimID = editDocument.claimID || null;

            if (editDocument.documentTypeID == undefined || editDocument.documentTypeID == 0) {
                $toastr.show('No Document Type detected!', 'warning');
                return;
            }

            if (isMemberStatement(editDocument)) {
                $toastr.show('Member statement document cannot be edited. ' +
                    'If you need to edit a member statement, please create a HelpDesk request.', 'warning');
                return;
            }

            if (isAnnualAccounting(editDocument)) {
                $toastr.show('Annual accounting document cannot be edited. ' +
                    'If you need to edit an annual accounting document, please create a HelpDesk request.', 'warning');
                return;
            }

            validateMemberDocumentRequest(editDocument.claimID, editDocument.documentTypeID, editDocument.id).then(_ => {
                documentService.updateDocument(editDocument).then(getMemberDocuments);
            });          
        };

        function deleteMemberDocument(documentID) {
            var deleteDocument = Enumerable.from(vm.memberDocuments).where(function (x) { return x.id == documentID }).single();

            if (isAnnualAccounting(deleteDocument)) {
                $toastr.show('Annual accounting document deletion is prohibited. ' +
                    'If you need to delete an annual accounting document, please create a HelpDesk request.', 'warning');
                return;
            }

            if (confirm('Are you sure you want to delete this document: [' + deleteDocument.physicalPath + '] ?')) {
                documentService.deleteDocumentById(documentID).then(function (result) {
                    getMemberDocuments();
                });
            }

        };

        //selects all available members displayed
        function toggleCheckAll() {
            for (var i = 0; i < vm.members.length; i++) {
                vm.members[i].Checked = vm.checkedAll;
            }
        };

        //assumes the extension is 'zip' - but is not sent in (may need to update this to handle different exts)
        function exportDownloadFile(tempFileName, downloadFileName) {
            const exportOptions = {
                method: 'GET',
                url: `/api/documents/exportMemberStatements/${tempFileName}/${downloadFileName}/`
            };

            utilService.download(exportOptions);
        };

        //Edit (Any Object)
        function isEdit(object) {
            //this will fix the date format when trying to edit documents      
            if (object.SentDate) {
                object.SentDate = object.SentDate.substring(0, object.SentDate.indexOf('T'));
            }
            if (object.ConfirmedDate) {
                object.ConfirmedDate = object.ConfirmedDate.substring(0, object.ConfirmedDate.indexOf('T'));
            }

            if (object.isEdit) {
                object.isEdit = false;
            }
            else {
                object.isEdit = true;
            }
        };

        function cancelEditDocument(document) {
            getMemberDocuments();

        };

        //sorts the columns
        function changeSort(column) {
            if (vm.filterData.SortColumn == column) {
                vm.filterData.SortOrder = (vm.filterData.SortOrder == 'ASC' ? 'DESC' : 'ASC');
            }
            else {
                vm.filterData.SortColumn = column;
                vm.filterData.SortOrder = 'ASC';
            }

            if (memberID) {
                getMemberDocuments();
            }
            else {
                filterMembers();
            }

        };

        //refreshes or changes the page of data
        function refresh(page) {
            if (page == null || (page > 0 && page <= vm.filterData.TotalPages)) {
                vm.filterData.PageIndex = vm.filterData.TotalRows == 0 || page == null ? 1 : page;

                if (memberID) {
                    getMemberDocuments();
                }
                else {
                    filterMembers();
                }
            }

        };

        //resets the filter data
        function resetFilterInfo() {
            vm.filterData.DocumentTypeID = null;
            vm.filterData.CreatedDate = null;
            vm.filterData.SentDate = null;
            vm.filterData.ConfirmedDate = null;
            vm.filterData.ClaimID = null;

            vm.filterData.MemberNumber = null;
            vm.filterData.LastName = null;
            vm.filterData.FirstName = null;
            vm.filterData.AccountStatusID = null;
            vm.filterData.SettlementMonth = null;

        };

        function hasRole(role) {
            return accountService.isInRole(role);
        }

        //navigates
        function navigate(state, params) {
            $timeout(function () {
                $state.go(state, params);
            }, 250);

        }

        function isMemberStatement(document) {
            if (!document) return false;

            return document.documentTypeID == _memberStatementDocumentTypeId;
        }

        function isAnnualAccounting(document) {
            if (!document) return false;

            return document.documentTypeID == _annualAccountingDocumentTypeId;
        }

        function isAmetros1099(document) {
            if (!document) return false;

            return document.documentTypeID == _ametros1099DocumentTypeId;
        }
    };
})();