/**
* @name structureBrokerGridController
* @desc structure Broker grid controller.
**/
(function () {
    'use strict';

  angular
    .module('CareGuard')
    .controller('structureBrokerController', structureBrokerController);

    /** Inject services into controller. */
    structureBrokerController.$inject = ['$scope', '$location', '$state', '$timeout', 'structureBrokerService', 'lookupService', 'accountService','utilService'];

    /**
    * @name structureBrokerGridController.
    * @desc structure Broker grid controller.   
    */
    function structureBrokerController($scope, $location, $state, $timeout, structureBrokerService, lookupService, accountService,utilService) {

      // variables
      var vm = this;
      //var iframe = undefined;

      vm.lookupdata = {};
      vm.structureBrokerGroupedData = {};
      vm.structureBrokerData = {};
      vm.structureBrokerTableData = {};
      vm.structureBrokerCompanies = {}; //StructureBrokerID, CompanyName, ProgramStartDate
      
      vm.startDate = undefined;
      vm.structureBrokerIndividual = undefined;

      vm.pointLifespans = {
        data: [
          { Name: 10, Value: 10 },
          { Name: 15, Value: 15 }
        ]
      };

      vm.newPeriodStartDate = '01-01-1900'; //defaults to min value here; but during the init, it is change the to the first of the month
      vm.numberOfMonths = 1; //defaults to Monthly

      // functions
      vm.getStructureBrokerData = getStructureBrokerData;
      vm.getStructureBrokerDetailData = getStructureBrokerDetailData;

      //vm.exportData = exportData;
      //vm.exportToExcel = exportToExcel;
      vm.exportReport = exportReport;
      vm.export_DownloadFile = export_DownloadFile;

      vm.toggleCheckAll = toggleCheckAll;
      vm.hasSelectedBroker = hasSelectedBroker;
      
      init();

      /* 
      * @name init
      * @desc initialize module
      */
      function init() {
        
        //get default date range
        var firstofthemonth = undefined;
        var today = undefined;
        
        today = new Date();
            
        var dd = today.getDate();
        var mm = today.getMonth() + 1; //January is 0!
        var yyyy = today.getFullYear();

        if (dd < 10) {
          dd = '0' + dd
        }

        if (mm < 10) {
          mm = '0' + mm
        }
        firstofthemonth = mm + '-01-' + yyyy;
        today = mm + '-' + dd + '-' + yyyy;

        //vm.enddate = today;
        vm.startDate = firstofthemonth;        
        vm.newPeriodStartDate = vm.startDate;

        //iframe = document.createElement("iframe");
        
        structureBrokerService.getStructureBrokerCompanies().then(function (result) {
          vm.structureBrokerCompanies = result.Data;          
          console.log(vm.structureBrokerCompanies);
        });
                
      };
        
      //this will get the structure broker point data
      function getStructureBrokerData() {
        //console.log(vm.careGuardPointValue);
        if (vm.structureBrokerCompany == undefined || vm.structureBrokerCompany == "" || vm.newPeriodStartDate == undefined || vm.newPeriodStartDate == "")
        {
          alert("Point Period and Structure Broker Company are required!");
          return;
        }
        else if (vm.newPeriodStartDate != undefined && vm.newPeriodStartDate != "") {
          vm.newPeriodStartDate = vm.newPeriodStartDate.substring(0, 10); //needed to remove the TimeStamp
        }
        
        if (!isNumeric(vm.numberOfMonths) || vm.numberOfMonths < 1 || vm.numberOfMonths > 12)
        {
          alert("Number Of Months (1-12) is required.");
        }

        //CareGuard Point Value
        var cgPointValue = "0";
        if(vm.careGuardPointValue > 0)
        {
          cgPointValue = vm.careGuardPointValue.replace('.', 'xdotx');
        }
        
        //Amethyst Point Value
        var amPointValue = "0";
        if (vm.amethystPointValue > 0) {
          amPointValue = vm.amethystPointValue.replace('.', 'xdotx');
        }
        
        //console.log(vm.newPeriodStartDate + ':' + vm.structureBrokerCompany + ':' + vm.careGuardPointValue + ':' + vm.amethystPointValue + ':' + vm.selectedPointLifespan);
        console.log(vm.selectedPointLifespan);

        //this gets it all from the database by company
        structureBrokerService.getStructureBrokerReport(vm.newPeriodStartDate, vm.structureBrokerCompany, cgPointValue, amPointValue, vm.selectedPointLifespan || 0, vm.numberOfMonths || 0).then(function (result) {
          vm.structureBrokerGroupedData = result.Data;

          vm.structureBrokerData = vm.structureBrokerGroupedData.StructureBrokerData;
          vm.structureBrokerTableData = {};

        });
      };

      //loads the details into the ui
      function getStructureBrokerDetailData(structureBroker) {
        vm.structureBrokerIndividual = structureBroker;

        vm.structureBrokerTableData = Enumerable.from(vm.structureBrokerGroupedData.StructureBrokerTableData).where(x => x.StructureBrokerIndv = structureBroker).toArray();
        
        vm.structureBrokerData = {}; //resets it to keep it all clean
      };

      //function exportData() {
        
      //  if (vm.structureBrokerData != undefined)
      //  {
      //    iframe.src = "/api/structurebroker/getStructureBrokerReport/" + vm.newPeriodStartDate + '/' + vm.structureBrokerCompany + '/' + vm.careGuardPointValue + '/' + vm.amethystPointValue + '/'
      //    // This makes the IFRAME invisible to the user.
      //    iframe.style.display = "none";

      //    // Add the IFRAME to the page.  This will trigger
      //    //   a request to GenerateFile now.
      //    document.body.appendChild(iframe);
      //  }        

      //};
      
      function exportReport() {
        //check if company is selected
        if (vm.structureBrokerData != undefined && vm.structureBrokerData.length > 0)
        {
          var groupedData = {
            StructureBrokerData:{},
            StructureBrokerTableData:{}
          };

          var selected = Enumerable.from(vm.structureBrokerData).where(x => x.Checked === true).toArray();

          //console.log(selected);
          //console.log(selected.length);

          groupedData.StructureBrokerData = selected;
          groupedData.StructureBrokerTableData = vm.structureBrokerGroupedData.StructureBrokerTableData; //currently has all data, not just selected

          //console.log(groupedData);

          //if there is data and at least one individual is selected, export that report
          if (selected.length > 0) //vm.structureBrokerTableData != undefined && vm.structureBrokerTableData.length > 1 &&
          {
            
            //structureBrokerService.exportToExcelC(groupedData).then(function (result) {
            structureBrokerService.exportToFormC(groupedData).then(function (result) {
              
              var fileName = result.data; //does not currently return the extension; so it is included below when calling the download method

              //console.log(fileName);
              export_DownloadFile(fileName.toString(), ".zip");

            });

          }
          else
          {
            alert("None selected!");
          }
        }
      };

      //assumes the extension is 'zip' - but is not sent in (may need to update this to handle different exts)
      function export_DownloadFile(fileName, fileExtension) {
        //console.log("/api/claims/payBillsD/downloadFile/" + fileName);
        //iframe.src = "/api/structurebroker/exportToForm/downloadFile/" + fileName + '/' + fileExtension + '/';

        //// This makes the IFRAME invisible to the user.
        //iframe.style.display = "none";

        //// Add the IFRAME to the page.  This will trigger
        ////   a request to GenerateFile now.
        //document.body.appendChild(iframe);

        var exportOptions = {
          method: 'GET',
          url: "/api/structurebroker/exportToForm/downloadFile/" + fileName + '/' + fileExtension + '/'
          };
        utilService.download(exportOptions);
      };


      //selects/deselects all available brokers
      function toggleCheckAll() {
        //console.log(vm.checkedAll);
        for (var sb = 0; sb < vm.structureBrokerData.length; sb++) {
          vm.structureBrokerData[sb].Checked = vm.checkedAll;
          vm.structureBrokerGroupedData.StructureBrokerData[sb].Checked = vm.checkedAll;
        }
        
      };

      //returns true if a broker has been selected
      function hasSelectedBroker() {
        var selected = Enumerable.from(vm.structureBrokerData).where(x => x.Checked === true).toArray();

        if (selected.length > 0)
          return true;

        return false;
      };

      function navigate(state, params) {
        $timeout(function () {
          $state.go(state, params);
        }, 250);

      };
      
      function isNumeric(n) {
        return !isNaN(parseFloat(n)) && isFinite(n);
      }

      //END OF CONTROLLER
    };
})();