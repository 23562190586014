angular
    .module('CareGuard')
    .directive('allowNumberLetter', allowNumberLetter);

allowNumberLetter.$inject = ['$filter'];

function allowNumberLetter($filter) {
    return {
        restrict: 'A',
        require: 'ngModel',
        link: function($scope, $elm, $attrs, ngModelController) {
            const onlyNumbersLetters = $filter('numbersLetters');

            ngModelController.$parsers.unshift(function(value) {
                const filtered = onlyNumbersLetters(value);

                ngModelController.$setViewValue(filtered);
                ngModelController.$render();

                return filtered;
            });
        }
    }
}