angular
    .module('CareGuard')
    .filter('phoneNumberDashesAndParentheses', phoneNumberDashesAndParentheses);

function phoneNumberDashesAndParentheses() {
    return function (value) {
        if (!value) return;

        value = value.toString();

        if (value.length < 4) {
            return '(' + value + ')'
        } else if (value.length >= 4 && value.length <= 6) {
            return '(' + value.slice(0,3) + ') ' + value.slice(3, value.length)
        } else if (value.length > 6) {
            return '(' + value.slice(0,3) + ') ' + value.slice(3, 6) + '-' + value.slice(6, 10)
        }
    };
}