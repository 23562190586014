angular
    .module('CareGuard')
    .controller('searchController', searchController);

searchController.$inject = [
    '$state',
    '$timeout',
    '$toastr',
    'accountService',
    'memberService'];

function searchController(
    $state,
    $timeout,
    $toastr,
    accountService,
    memberService) {

    let vm = this;

    vm.member = [];
    vm.searchBy = '';
    vm.isInProgress = false;
    vm.isMemberAccessRestricted = false;
    const memberNumberLength = 7;
    const regexp = /^[a-zA-Z0-9][0-9]{6}$/;
    let allocationTypes = {};


    vm.search = search;
    vm.onSubmit = onSubmit;
    vm.navigate = navigate;
    vm.clear = clear;
    vm.selectMember = selectMember;
    vm.prepareSearchQueryParameters = prepareSearchQueryParameters;
    vm.memberSearchResponseFormatter = memberSearchResponseFormatter;

    init();
    function init() {
        vm.routeName = $state.current.title;

        if (!accountService.isInRole('CareGuardFinance')) {
            vm.isMemberAccessRestricted = true;
        }

        vm.searchQuery = `${memberService.searchMemberRoute()}`;
        vm.checkRoles = !accountService.isInRole('CareGuardFinance,AmethystSpecialist,ChangeHealthSpecialist,CareGuard,ThirdPartyUser');
        memberService.getAllocationTypes().then(({ data: allocationData }) => {
            allocationTypes = allocationData;
        });
    }

    function search() {
        if (!vm.searchBy || vm.isInProgress) {
            return;
        } else if (vm.searchBy.length >= 3 && (accountService.isInRole('CareGuard')
                    || accountService.isInRole('CareGuardFinance') || accountService.isInRole('AmethystSpecialist')
                        || accountService.isInRole('ChangeHealthSpecialist'))) {
            vm.isInProgress = true;
            memberService.searchMember(vm.searchBy, vm.isMemberAccessRestricted).then(function (result) {
                vm.member = result.data;
            })['finally'](_ => vm.isInProgress = false);
        }
    }

    function onSubmit () {
        if ((vm.searchBy.length < memberNumberLength) || !regexp.test(vm.searchBy)) return;

        vm.isInProgress = true;
        memberService.searchMember(vm.searchBy, vm.isMemberAccessRestricted)
            .then(result => {
                if (result.data.length === 1) {
                    vm.navigate('layout.profile', { id: result.data[0].id })
                } else {
                    $toastr.show('Please enter an exact member ID or use autocomplete', 'warning')
                }
            })['finally'](_ => clear());
    }

    function selectMember(selected) {
        clear();
        vm.navigate('layout.profile', { id: selected.originalObject.id });
    }

    function clear() {
        vm.member = [];
        vm.searchBy = "";
        vm.isInProgress = false;
    }

    function navigate(state, params) {
        $timeout(function () {
            $state.go(state, params);
        }, 0);
    }

    function prepareSearchQueryParameters(query) {
        return {
            searchPhrase: parseDateIfPossible(query),
            isMemberAccessRestricted: vm.isMemberAccessRestricted
        };
    }

    function parseDateIfPossible(query) {
        const applicableDateFormats = ['YYYY-MM-DD', 'DD/MM/YYYY'];

        let date = moment(query, applicableDateFormats, true);

        if (date.isValid()) {
            return date.format('YYYY-MM-DD');
        }

        return query;
    }

    function memberSearchResponseFormatter(response) {
        var membersArray = response.map(member => {
            member.dateOfBirth = moment(new Date(member.dateOfBirth)).format('YYYY-MM-DD');
            member.fullName = member.lastName + ', ' + member.firstName;
            member.allocationType = allocationTypes.find(allocationType => {
                return allocationType.id == member.allocationTypeId;
            })?.type;
            return member;
        });
        return {
            data: _.sortBy(membersArray, ['lastName', 'firstName'])
        };
    }
}