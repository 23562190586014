export const claimHistoryDefsGrid = [
    {
        field: 'UpdatedDate', //this might be interchangeable with ActionDate if we have already adjusted how the claimshistory table is being populated
        displayName: 'Updated Date',
        headerCellClass: 'grid-component_header',
        cellFilter: "date:\'MM/dd/yyyy hh:mm a\'"
    },
    {
        field: 'UpdatedBy',
        displayName: 'Updated By',
        headerCellClass: 'grid-component_header'
    },
    {
        field: 'UpdatedValue', //this is the Name of the updated field
        displayName: 'Updated Value',
        headerCellClass: 'grid-component_header'
    },
    {
        field: 'BeforeValue',
        displayName: 'Before Value',
        headerCellClass: 'grid-component_header'
        //, cellFilter: ? How will data be displayed correctly for each field if they may be different data types? Do we just convert everything to varchar/string?
    },
    {
        field: 'AfterValue',
        displayName: 'After Value',
        headerCellClass: 'grid-component_header'
        //, cellFilter: ? How will data be displayed correctly for each field if they may be different data types? Do we just convert everything to varchar/string?
    },
    {
        field: 'Actions',
        displayName: '',
        width: '2%',
        headerCellClass: 'grid-component_header grid-component_header_actions',
        cellClass: 'grid-component_actions'
    }
];