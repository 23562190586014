/**
* @name userController
* @desc User Controller.
**/
(function () {
    'use strict';

    angular
        .module('CareGuard')
        .controller('userController', userController)

    /** Inject services into controller. */
    userController.$inject = ['userService'];

    /**
    * @name userController.
    * @desc User Controller.
    * @param {object} userService - user service.
    */
    function userController(userService) {
        var vm = this;

        // variables
        vm.selectedTab = undefined;
        vm.isNew = false;
        vm.users = {
            selected: undefined,
            searchBy: undefined,
            searchByAttribute: undefined,
            currentPage: 1,
            pageSize: 15,
            itemCount: 1,
            items: []
        };

        // functions
        vm.selectTab = selectTab;
        vm.clear = clear;
        vm.get = get;
        vm.search = search;
        vm.select = select;

        init();

        /* 
        * @name init
        * @desc initialize module
        */
        function init() {
            get(1);
            userService.setNewCallback(addNew);
        };

        /* 
        * @name selectTab
        * @desc set selected tab.
        */
        function selectTab(tab) {
            vm.selectedTab = tab;
        };

        /* 
        * @name clear
        * @desc clear search bar and refresh list.
        */
        function clear() {
            vm.users.currentPage = 1;
            vm.users.searchByAttribute = undefined;
            vm.users.searchBy = undefined;
            get();
        };

        /* 
        * @name search
        * @desc search users using the input string.
        */
        function search(filterBy) {
            vm.users.currentPage = 1;
            vm.users.searchByAttribute = filterBy;
            get();
        };

        /* 
        * @name get
        * @desc get a list of users to display. 
        */
        function get() {
            var params = {
                pageSize: vm.users.pageSize,
                pageNum: vm.users.currentPage,
                sort: 'UserName',
                sortDir: 'asc',
                filter: {}
            };

            // add filter if exists
            if (vm.users.searchByAttribute) {
                params.filter[vm.users.searchByAttribute] = vm.users.searchBy;
            }

            // refresh results from db
            userService.get(params).then(function (data) {
                vm.users.items = data.Items;
                vm.users.itemCount = data.ItemCount;
                if (vm.users.items.length > 0)
                    select(0, vm.users.items[0]);
            });
        };

        /* 
        * @name select
        * @desc select the Active user. 
        * @param {int} index - index of company in Company.Items array.
        * @param {object} company - Company object.
        */
        function select(index, user) {
            user.Index = index;
            vm.users.selected = user;
            userService.setSelected(user);
            selectTab('details');
        };

        /* 
        * @name addNew
        * @desc callback function when new user is added. 
        * @param {object} usr - new user.
        */
        function addNew(usr) {
            if (!usr || usr.Index < 0) {
                vm.users.selected = undefined;
                vm.isNew = true;
                return;
            };

            var index = usr.Index || 0;
            if (usr.IsNew === true) {
                vm.users.items.unshift(usr);
            }
            else {
                vm.users.items[index] = usr;
            }
            vm.isNew = false;
            select(index, usr);
            userService.setSelected(usr);
        };
    };
})();