(function () {
    'use strict';

    angular
    .module('CareGuard')
    .controller('changehealthclaimController', changehealthclaimController);

    changehealthclaimController.$inject = [
        '$state',
        '$timeout',
        '$toastr',
        'claimService',
        'lookupService',
        'accountService',
        'claimStatusId',
        'memberID',
        'memberService',
        'utilService',
        'fileService'];

    function changehealthclaimController(
        $state,
        $timeout,
        $toastr,
        claimService,
        lookupService,
        accountService,
        claimStatusId,
        memberID,
        memberService,
        utilService,
        fileService) {

    	var vm = this;

    	vm.claims = null;
    	vm.lookupdata = null;
    	vm.member = null;
    	vm.payperiods = null;
    	vm.totalpay = 0;
    	vm.pncreversalamount = 0;
    	vm.payors = {};
    	vm.missingAuths = {};

    	vm.filterClaims = filterClaims;
    	vm.exportClaims = exportClaims;
    	vm.changeSort = changeSort;

    	vm.getFilterData = getFilterData;
    	vm.clearFilterInfo = clearFilterInfo;
    	vm.refresh = refresh;
    	vm.navigate = navigate;

    	vm.toggleCheckAll = toggleCheckAll;
    	
    	vm.getImageFile = getImageFile;
    	vm.changeStatus = changeStatus;
    	vm.zeroPay = zeroPay;
    	vm.ApplyReversal = ApplyReversal;
    	vm.CalculatePay = CalculatePay;
    	vm.hasRole = hasRole;

    	vm.pageSizes = {
    		data: [
			    { Name: 5, Value: 5 },
			    { Name: 10, Value: 10 },
			    { Name: 20, Value: 20 },
			    { Name: 50, Value: 50 },
			    { Name: 100, Value: 100 },
			    { Name: 1000, Value: 1000 },
			    { Name: 5000, Value: 5000 },
			    { Name: 100000, Value: 100000 },
    		]
    	};

    	vm.filterData = {
    		TotalPages: 1,
    		TotalRows: null,
    		PageSize: 100000,
    		PageIndex: 1,
    		SortColumn: null,
    		SortOrder: 'ASC',

    		MemberID: null,
    		MemberNumber: null,
    		MemberName: null,
    		ClaimID: null,
    		ClaimStatusID: null,
    		PayeeID: null,
    		ClaimNumber: null,
    		BillTypeID: null,
    		StartReceivedDate: null,
    		EndReceivedDate: null,
    		StartServiceDate: null,
    		EndServiceDate: null,
    		CheckNumber: null,
    		IDList: null,
    		arrIDList: null,
    		newClaimStatusID: null,
    		newClaimDetailStatusID: null,
    		StartPaidDate: null,
    		EndPaidDate: null,
    		Payor: null,
    		PayPeriodID: null
    	};

        vm.filterDataCopy = null;

    	init();

        function init() {
            claimService.getCHPayPeriods().then((resultData) => {
              vm.payperiods = resultData.Data;
            });

            claimService.getMissingAuths(null).then((resultData) => {
              vm.missingAuths = resultData.Data;
            });

            vm.lookupdata = {
              billtype: lookupService.getLookUp('claimType'),
              claimstatus: lookupService.getLookUp('claimStatus'),
              claimdetailstatus: lookupService.getLookUp('claimDetailStatus')
            }            

            lookupService.getLookUp("claimType").then((claimTypedata) => {
              vm.lookupdata.billtype = claimTypedata.Data;
            });

            claimService.getClaimStatuses().then(({ data: statuses }) => {
                vm.lookupdata.claimstatus = statuses;
            });

            claimService.getClaimStatuses().then(({ data: statuses }) => {
                vm.lookupdata.claimdetailstatus = statuses;
            })
        
            claimService.getPayors(null).then((resultset) => {
              vm.payors = resultset.Data;
            });

            if (memberID > 0) {
                memberService.getById(memberID).then((result) =>{
                    vm.member = result.Data;

                    vm.filterData.MemberID = vm.member.MemberID;
                    vm.filterData.MemberNumber = vm.member.MemberNumber;
                    vm.filterData.MemberName = vm.member.MemberName;
                });
            }
        };

        function filterClaims() {        
            getFilterData();

            vm.claims = undefined;

            claimService.filterChangeHealthClaims(vm.filterDataCopy.MemberID, vm.filterDataCopy.MemberNumber, vm.filterDataCopy.MemberName, vm.filterDataCopy.ClaimID, vm.filterDataCopy.PayeeID, vm.filterDataCopy.ClaimNumber, vm.filterDataCopy.StartReceivedDate, vm.filterDataCopy.EndReceivedDate, vm.filterDataCopy.StartServiceDate, vm.filterDataCopy.EndServiceDate, vm.filterDataCopy.CheckNumber, vm.filterDataCopy.ClaimStatusID, vm.filterDataCopy.BillTypeID, vm.filterDataCopy.StartPaidDate, vm.filterDataCopy.EndPaidDate, vm.filterDataCopy.Payor, vm.filterDataCopy.PayPeriodID, vm.filterDataCopy.PageIndex, vm.filterDataCopy.PageSize, vm.filterDataCopy.SortColumn, vm.filterDataCopy.SortOrder).then(function (result) {
                vm.claims = result.Data;            

                try {
                    vm.filterData.TotalRows = vm.claims[0].TotalRows;
                    vm.filterData.TotalPages = Math.ceil(vm.filterData.TotalRows / vm.filterData.PageSize);
                }
                catch (ex) {
                    vm.filterData.TotalPages = 1;
                };

                vm.checkedAll = null;
            });
        };
      
        function getFilterData() {
            vm.totalpay = 0;
            vm.pncreversalamount = 0;
        
            vm.filterDataCopy = undefined;

            vm.filterDataCopy = angular.copy(vm.filterData);

            if (!vm.filterData.MemberNumber)
                vm.filterDataCopy.MemberNumber = null;
            if (!vm.filterData.MemberName)
                vm.filterDataCopy.MemberName = null;
            if (!vm.filterData.ClaimNumber)
                vm.filterDataCopy.ClaimNumber = null;
            if (!vm.filterData.CheckNumber)
                vm.filterDataCopy.CheckNumber = null;
            if (!vm.filterData.SortColumn)
                vm.filterDataCopy.SortColumn = null;

            if (!vm.filterData.MemberID)
                vm.filterDataCopy.MemberID = 0;
            if (!vm.filterData.ClaimID)
                vm.filterDataCopy.ClaimID = 0;
            if (!vm.filterData.PayeeID)
                vm.filterDataCopy.PayeeID = 0;
            if (!vm.filterData.StartReceivedDate)
                vm.filterDataCopy.StartReceivedDate = '01-01-0001';
            if (!vm.filterData.EndReceivedDate)
                vm.filterDataCopy.EndReceivedDate = '01-01-0001';
            if (!vm.filterData.StartServiceDate)
                vm.filterDataCopy.StartServiceDate = '01-01-0001';
            if (!vm.filterData.EndServiceDate)
                vm.filterDataCopy.EndServiceDate = '01-01-0001';        
            if (!vm.filterData.ClaimStatusID)
                vm.filterDataCopy.ClaimStatusID = 0;
            if (!vm.filterData.BillTypeID)
                vm.filterDataCopy.BillTypeID = 0;
            if (!vm.filterData.StartPaidDate)
                vm.filterDataCopy.StartPaidDate = '01-01-0001';
            if (!vm.filterData.EndPaidDate)
                vm.filterDataCopy.EndPaidDate = '01-01-0001';
            if (vm.filterData.Payor == undefined || vm.filterData.Payor == '')
                vm.filterDataCopy.Payor = null;

            //this will supercede all other filter criteria
            if (!vm.filterData.PayPeriodID) {
                vm.filterDataCopy.PayPeriodID = 0;          
            }
            else {
                //clear filter criteria - payperiods should supercede any other filter criteria
                vm.filterDataCopy.MemberNumber = null;
                vm.filterDataCopy.MemberName = null;
                vm.filterDataCopy.ClaimNumber = null;
                vm.filterDataCopy.CheckNumber = null;

                vm.filterDataCopy.MemberID = 0;
                vm.filterDataCopy.ClaimID = 0;
                vm.filterDataCopy.PayeeID = 0;
                vm.filterDataCopy.StartReceivedDate = '01-01-0001';
                vm.filterDataCopy.EndReceivedDate = '01-01-0001';
                vm.filterDataCopy.StartServiceDate = '01-01-0001';
                vm.filterDataCopy.EndServiceDate = '01-01-0001';
                vm.filterDataCopy.ClaimStatusID = 0;
                vm.filterDataCopy.BillTypeID = 0;
                vm.filterDataCopy.StartPaidDate = '01-01-0001';
                vm.filterDataCopy.EndPaidDate = '01-01-0001';
                vm.filterDataCopy.Payor = null;
            }  
        };
      
        function exportClaims() {
            if (vm.filterData) {
                const exportOptions = {
                    method: 'POST',
                    url: "/api/claims/exportChangeHealthClaims",
                    data: vm.filterData
                };

                utilService.download(exportOptions);
            }
        };
       
        function toggleCheckAll() {
            vm.totalpay = 0;        
            vm.pncreversalamount = 0;
        
            for (var c = 0; c < vm.claims.length; c++)
            {
                if (vm.claims[c].IsReversal) {
                    vm.claims[c].Checked = false;
                }
                else {
                    vm.claims[c].Checked = vm.checkedAll;

                    if (vm.claims[c].Checked) {
                        vm.totalpay = vm.claims[c].PaidAmount + vm.totalpay;
                    }
                }
          
                if(vm.filterData.PayPeriodID)
                {
                    if (vm.claims[c].IsReversal) {
                        if (vm.claims[c].ReversalFlag == 'yellow' || vm.claims[c].ReversalFlag == 'orange') {
                            vm.claims[c].Checked = vm.checkedAll;

                            if (vm.claims[c].Checked)
                                vm.totalpay = vm.claims[c].PaidAmount + vm.totalpay;
                        }                                      

                        if (vm.claims[c].ReversalFlag == 'pink' || vm.claims[c].ReversalFlag == 'purple') {
                            vm.claims[c].Checked = vm.checkedAll;

                            if (vm.claims[c].Checked) {
                                vm.totalpay = vm.totalpay - vm.claims[c].PaidAmount;

                                if (vm.claims[c].BankName.toUpperCase() == 'PNC')
                                    vm.pncreversalamount = vm.claims[c].PaidAmount + vm.pncreversalamount;
                            }
                        }                
                    }            
                }
            }
        };
        
        function CalculatePay(claim) {
            if (claim.Checked)
            {
                if (!claim.IsReversal || claim.ReversalFlag == 'yellow' || claim.ReversalFlag == 'orange')
                    vm.totalpay = claim.PaidAmount + vm.totalpay;

                if ((claim.IsReversal && claim.ReversalFlag != 'yellow' && claim.ReversalFlag != 'orange') || claim.ReversalFlag == 'pink' || claim.ReversalFlag == 'purple') {
                    vm.totalpay = vm.totalpay - claim.PaidAmount;
                    if (claim.BankName.toUpperCase() == 'PNC')
                        vm.pncreversalamount = claim.PaidAmount + vm.pncreversalamount;
                }
            }
            else
            {
                if (!claim.IsReversal || claim.ReversalFlag == 'yellow' || claim.ReversalFlag == 'orange')
                    vm.totalpay = vm.totalpay - claim.PaidAmount;

                if ((claim.IsReversal && claim.ReversalFlag != 'yellow' && claim.ReversalFlag != 'orange') || claim.ReversalFlag == 'pink' || claim.ReversalFlag == 'purple') {
                    vm.totalpay = claim.PaidAmount + vm.totalpay;
                    if (claim.BankName.toUpperCase() == 'PNC')
                        vm.pncreversalamount = vm.pncreversalamount - claim.PaidAmount;
                }
            }
        };

        function changeSort(column) {
            if (vm.filterData.SortColumn == column) {
                vm.filterData.SortOrder = (vm.filterData.SortOrder == 'ASC' ? 'DESC' : 'ASC')
            }
            else {
                vm.filterData.SortColumn = column;
                vm.filterData.SortOrder = 'ASC';
            }

            filterClaims();
        };
        
        function refresh(page) {
            if(!page || (page > 0 && page <= vm.filterData.TotalPages))
            {
                vm.filterData.PageIndex = vm.filterData.TotalRows === 0 || !page ? 1: page;
                filterClaims();
            }
        };

        function navigate(state, params) {
            $timeout(function () {
                $state.go(state, params);
            }, 250);
        };

        function clearFilterInfo() {
            vm.filterDataCopy = null;

            if (memberID == null || memberID <= 0) {
                vm.filterData.MemberID = null;
                vm.filterData.MemberNumber = null;
                vm.filterData.MemberName = null;
            }

            vm.filterData.ClaimID = null;
            vm.filterData.ClaimStatusID = null;
            vm.filterData.PayeeID = null;
            vm.filterData.ClaimNumber = null;
            vm.filterData.BillTypeID = null;
            vm.filterData.StartReceivedDate = null;
            vm.filterData.EndReceivedDate = null;
            vm.filterData.StartServiceDate = null;
            vm.filterData.EndServiceDate = null;
            vm.filterData.IDList = null;
            vm.filterData.arrIDList = null;
            vm.filterData.newClaimStatusID = null;
            vm.filterData.newClaimDetailStatusID = null;
            vm.filterData.CheckNumber = null;
            vm.filterData.StartPaidDate = null;
            vm.filterData.EndPaidDate = null;
            vm.filterData.PayPeriodID = null;        
            vm.filterData.Payor = null;

        };
        
        function getImageFile(imageFileClaim) {
            if (imageFileClaim.ImageFile) {
                const str = encodeURIComponent(imageFileClaim.ImageFile);

                const fileName = fileService.getFileNameWithoutExtension(str);
                const extension = fileService.getExtension(str);

                const exportOptions = {
                    method: 'GET',
                    url: `/api/claims/getImageFile/${imageFileClaim.MemberNumber}/${fileName}/${extension}`
                };

                utilService.download(exportOptions);
            }
        };
            
        function ApplyReversal() {
            var claimIDList = new Array();

            for (var i = 0; i < vm.claims.length; i++) {
                if (vm.claims[i].Checked) {
                    if (!vm.claims[i].IsReversal) {
                        confirm(`Only Reversal Claims can be refunded. You cannot select claim [${vm.claims[i].ClaimID}].`);
                    }
                    else {
                        claimIDList.push(vm.claims[i].ClaimID);
                    }
                }
            }

            if (claimIDList.length > 0) {
                vm.filterData.arrIDList = claimIDList.join();
            }
        
            if (vm.filterData.arrIDList && vm.filterData.arrIDList.length > 0) {
                if (confirm(`Are you sure you want to Apply Refunds on [${vm.filterData.arrIDList}]?`)) {
                    claimService.applyReversal(vm.filterData).then((result) => {
                        vm.claims = result;

                        vm.filterData.arrIDList = null;
                    });
                }
            }
        };

      
        function changeStatus() {
            if (vm.filterData && vm.filterData.newClaimStatusID && vm.claims.length > 0) {
                const claimIDList = vm.claims.filter(claim => claim.Checked).map(claim => claim.ClaimID);

                if (claimIDList.length > 0) {
                    vm.filterData.IDList = claimIDList;
                }

                const claimIdsExpirationDate = vm.claims.filter(claim => claim.Checked && vm.filterData.newClaimStatusID === claimStatusId.ReadyForBillReview && claim.ServiceDate > claim.ExpirationDate);

                if (claimIdsExpirationDate.length) {
                    $toastr.show("Date of Service is after Date of Expiration for the selected claim(s). Sending this/these claim(s) for Bill Review is prohibited", "error");
                    return;
                }                       

                if (vm.filterData.IDList && vm.filterData.IDList.length > 0) {
                    claimService.changeStatusCHC(vm.filterData).then((result) => {
                        vm.claims = result;

                        vm.filterData.IDList = null;
                        vm.filterData.newClaimStatusID = null;
                    });
                }
            }
        };

        function zeroPay() {
            if (vm.filterData && vm.filterData.newClaimDetailStatusID && vm.claims.length > 0) {
                const claimIDList = vm.claims.filter(claim => claim.Checked).map(claim => claim.ClaimID);

                if (claimIDList.length > 0) {
                    vm.filterData.IDList = claimIDList;
                }

                if (vm.filterData.IDList && vm.filterData.IDList.length > 0) {
                    claimService.zeroPayCHC(vm.filterData).then((result) => {
                        vm.claims = result;

                        vm.filterData.IDList = null;
                        vm.filterData.newClaimDetailStatusID = null;
                    });
                }
            }
        };

        function hasRole(role) {
            return accountService.isInRole(role);
        }
    };
})();