/**
* @name structurebroker.service
* @desc used for structure broker point report
**/
(function () {
    'use strict';

    angular
        .module('CareGuard')
        .factory('structureBrokerService', structureBrokerService);

    /** Inject services into service. */
    structureBrokerService.$inject = ['$http'];

    /**
    * @name structureBrokerService.
    * @desc Structure Broker Service
    */
    function structureBrokerService($http) {
      var baseUrl = '/api/structurebroker/';
        
      var service = {
        getStructureBrokerReport: getStructureBrokerReport,
        getStructureBrokerCompanies: getStructureBrokerCompanies,
        //exportToExcelC: exportToExcelC,
        //exportToExcelD: exportToExcelD,
        exportToFormC: exportToFormC,
		exportToFormD: exportToFormD
        
      };

      return service;
      
      function getStructureBrokerCompanies() {
        return $http.get(baseUrl + 'getStructureBrokerCompanies');
      };
      
      function getStructureBrokerReport(newperiodstartdate, structurebrokercompany, careguardpointvalue, amethystpointvalue, pointlifespan, numberofmonths) { 
        return $http.get(baseUrl + 'getStructureBrokerReport/' + newperiodstartdate + '/' + structurebrokercompany + '/' + careguardpointvalue + '/' + amethystpointvalue + '/' + pointlifespan + '/' + numberofmonths + '/');
      };
      
      //function exportToExcelC(groupedData) {
      //  return $http.post(baseUrl + 'exportToExcel/createFile', groupedData)
      //};

      //function exportToExcelD(fileName) {
      //  return $http.get(baseUrl + 'exportToExcel/downloadFile/' + fileName)
      //};

      function exportToFormC(groupedData) {
        return $http.post(baseUrl + 'exportToForm/createFile', groupedData)
      };

      function exportToFormD(fileName, extension) {
        return $http.get(baseUrl + 'exportToForm/downloadFile/' + fileName + '/' + extension + '/')
      };
    };
})();