angular
    .module('CareGuard')
    .directive('ngDisabledTooltip', ngDisabledTooltip);

ngDisabledTooltip.$inject = ['$compile'];

function ngDisabledTooltip($compile) {
    return {
        restrict: 'A',
        link: function (scope, element, attrs) {
            scope.$watch(() => element[0] && element[0].disabled,
                (newVal, oldVal) => {
                    element[0].title = !!newVal ? attrs.ngDisabledTooltip : '';
                });
        }
    };
}
