angular
    .module('CareGuard')
    .constant('bankEnum', {
        BankOfTampa: 1,
        Pnc: 2,
        Sabadell: 3,
        Radius: 4,
        LeaderBank: 5,
        Webster: 6,
        Midwest: 7,
        Encore: 8
    });