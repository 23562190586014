angular
    .module('CareGuard')
    .filter('numbersLetters', () => {
        return (value) => {
            if (value) {
                return value.toString().replace(/[^0-9a-zA-Z]/gi, "");
            } else {
                return value;
            }
        }
    });