/**
* @name beneficiaryGridController
* @desc beneficiary grid controller.
**/
(function () {
    'use strict';

    angular
        .module('CareGuard')
        .controller('beneficiaryGridController', beneficiaryGridController)

    /** Inject services into controller. */
    beneficiaryGridController.$inject = ['LxDialogService', 'beneficiaryService'];

    /**
    * @name beneficiaryGridController.
    * @desc beneficiary grid controller.   
    */
    function beneficiaryGridController(LxDialogService, beneficiaryService) {

        // variables
        var vm = this;
        var dialogId = 'dlgBeneficiaryModal';
        vm.beneficiaries = undefined;
        vm.annuitants = undefined;

        // beneficiary grid config
        var events = {
            add: show
        };

        vm.config = {
            selectionMode: 'none',
            rowNum: 15,
            filterable: false,
            dblClick: show,
            events: events
        };

        vm.columns = [
            {
                label: 'Annuitant', key: 'AnnuitantId', formatFunction: function (value, formatParameter) {
                    if (value && vm.annuitants) {
                        return Enumerable.from(vm.annuitants).where('$.Id == "' + value + '"').select('$.Name').firstOrDefault(undefined);
                    }
                }
            },
            { label: 'Name', key: 'Name' },
            { label: 'Birth Date', key: 'BirthDate', formatFunction: 'date' },
            { label: 'Age', key: 'Age' },
            { label: 'Primary Phone', key: 'PrimaryPhone' },
            { label: 'Email', key: 'Email' },
            {
                label: 'Percent', key: 'Percent', formatFunction: function (value, formatParameter) {
                    if (value) {
                        return value + ' %';
                    }
                }
            }
        ];

        init();

        /* 
        * @name init
        * @desc initialize module
        */
        function init() {
            vm.annuitants = beneficiaryService.getAnnuitants();
            vm.beneficiaries = beneficiaryService.getBeneficiaries();
        };

        /* 
        * @name edit
        * @desc open beneficiary modal for adding/editing
        * @param {object} row - optional beneficiary row from the grid
        */
        function show(beneficiary) {
            beneficiaryService.setBeneficiaries(vm.beneficiaries);
            beneficiaryService.setBeneficiary(beneficiary);
            LxDialogService.open(dialogId);
        };
    };
})();