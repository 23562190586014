 angular
    .module('CareGuard')
    .factory('sharepointDocumentService', sharepointDocumentService);

sharepointDocumentService.$inject = ['$http'];

function sharepointDocumentService ($http) {
    const baseUrl = '/api/documents';

    function getSharepointDocuments({ memberNumber, firstName, lastName, paging, filters }, { ignoreLoadingBar }) {
        return $http.post(`${baseUrl}/getSharepointDocuments`, { memberNumber, firstName, lastName, paging, Filter: filters }, { ignoreLoadingBar });
    }

    return {
        getSharepointDocuments
    };
};