/**
* @name locationService
* @desc Location Service.
**/
(function () {
    'use strict';

    angular
        .module('CareGuard')
        .factory('locationService', locationService);

    /** Inject services into service. */
    locationService.$inject = ['$http'];

    /**
    * @name locationService.
    * @desc Location Service.
    */
    function locationService($http) {
        var baseUrl = '/api/locations/';

        var service = {
            get: get,
            getAll: getAll,
            getByCompany: getByCompany,
            getById: getById,
            save: save,
            remove: remove
        };
        
        return service;

        /**
        * @name get.
        * @desc get list of locations.
        * @param {numeric} companyId - Id of company.
        * @param {object} params - search parameter.
        */
        function get(companyId, params) {
            return $http.get(baseUrl + 'get/' + companyId, { params: params });
        };

        /**
        * @name getAll.
        * @desc get list of all locations.
        */
        function getAll() {
            return $http.get(baseUrl + 'getAll');
        };

        /**
        * @name getByCompany.
        * @desc get list of locations for a company.
        * @param {numeric} companyId - Id of company.
        */
        function getByCompany(companyId) {
            return $http.get(baseUrl + 'getByCompany/' + companyId);
        };

        /**
        * @name getByID.
        * @desc get a location given Id.
        * @param {object} id - id of the location to get.
        */
        function getById(id) {
            return $http.get(baseUrl + 'getById/' + id);
        };

        /**
        * @name save.
        * @desc save a location.
        * @param {object} loc - location to be saved.
        */
        function save(loc) {
            return $http.post(baseUrl + 'save', loc);
        };

        /**
        * @name remove.
        * @desc remove a location.
        * @param {object} location - location to be removed.
        */
        function remove(location) {
            return $http.post(baseUrl + 'remove', location);
        };
    }
})();