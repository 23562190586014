angular
    .module('CareGuard')
    .filter('utcToEst', utcToEst);

function utcToEst() {

    return function (value, dateFormat) {
        if (!value) return;

        const defaultFormat = 'MMM-DD-YYYY h:mm A zz';
        dateFormat = dateFormat || defaultFormat;

        return  moment.utc(value).tz('America/New_York').format(dateFormat);
    };
}