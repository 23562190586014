angular
    .module('CareGuard')
    .directive('chronovoNavigate', chronovoNavigate);

chronovoNavigate.$inject = ['$state', '$stateParams'];

function chronovoNavigate($state, $stateParams) {
    return {
        restrict: 'A',
        replace: true,
        link: postLink
    };
    
    function postLink($scope, $element, $attrs) {
        $element.bind('click', function () {
            $scope.$apply(function () {
                if ($attrs.chronovoNavigate)
                    $state.go($attrs.chronovoNavigate, $stateParams);
            });
        });
    }
}