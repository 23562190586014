angular
    .module("CareGuard")
    .directive('ngSelect', selectPickerDirective);

selectPickerDirective.$inject = ['$parse', '$timeout'];

function selectPickerDirective($parse, $timeout) {
    return {
        restrict: 'A',
        require: 'ngModel',
        link: function (scope, element, attrs, ngModelController) {
            $timeout(function() {
                element.selectpicker($parse(attrs.ngSelect)(scope));

                const selectButton = document.getElementById(element[0].id).nextSibling;
                const selectDropDown = selectButton.nextSibling;
                selectDropDown.style.width = selectButton.offsetWidth + 'px';

                const buttonElement = angular.element(selectButton);
                const dropDownWrapperElement = angular.element(selectButton.parentNode);

                scope.$watch(() => buttonElement.hasClass('bs-placeholder'), (newVal, oldVal) => {
                    if (newVal == null) return;

                    dropDownWrapperElement.toggleClass('not-empty', !newVal);

                    if (!newVal && attrs.required && ngModelController.$viewValue) {
                        ngModelController.$setValidity('required', true);
                    }
                });

                scope.$watch(attrs.ngModel, function (newVal, oldVal) {
                    if (element[0]) {
                        for (let i = 0; i < element[0].length; i++) {
                            let tmpContent = element[0][i].textContent;
                            element[0][i].textContent = element[0][i].textContent.trim();

                            if (tmpContent.length > 2 && tmpContent === element[0][i].textContent) break;
                        }

                        scope.$parent[attrs.ngModel] = newVal;
                        scope.$evalAsync(function () {
                            if (!attrs.ngOptions || /track by/.test(attrs.ngOptions)) element.val(newVal);
                            element.selectpicker('refresh');
                        });
                    }
                });

                scope.$watch(() => element[0].finishRendering, function (newVal, oldVal) {
                    if (newVal) element.selectpicker('refresh');
                });

                if (attrs.exprToWatch) {
                    scope.$watch(attrs.exprToWatch, function (newVal, oldVal) {
                        if (newVal && oldVal) {
                            $timeout(function () {
                                element.selectpicker('refresh');
                            });
                        }
                    }, true);
                }

                if (attrs.ngDisabled) {
                    scope.$watch(attrs.ngDisabled, function (newVal, oldVal) {
                        if (newVal != null) element.selectpicker('refresh');
                    });
                }

                scope.$on('$destroy', function () {
                    scope.$evalAsync(function () {
                        element.selectpicker('destroy');
                    });
                });
            }, 0)
        }
    };
}

