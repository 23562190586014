angular
    .module('CareGuard')
    .factory('fundsExhaustionService', fundsExhaustionService);

fundsExhaustionService.$inject = ['$http'];

function fundsExhaustionService($http) {
    const baseUrl = '/api/fundsExhaustion/';

    const generateFundsExhaustionLetter = (memberNumber, templateName) => {
        //return $http.get(`${baseUrl}/generateFundsExhaustionLetter?memberNumber=${memberNumber}&templateName=${templateName}`);
        return $http({
            url: `${baseUrl}/generateFundsExhaustionLetter?memberNumber=${memberNumber}&templateName=${templateName}`,
            method: 'GET',
            withCredentials: true,
            transformRequest: angular.identity,
            responseType: 'arraybuffer'
        });
    };

    const generateFundsExhaustionLetters = (memberNumbers, templateName) => {
        //return $http.get(`${baseUrl}/generateFundsExhaustionLetters?templateName=${templateName}`, memberNumbers);
        return $http({
            url: `${baseUrl}/generateFundsExhaustionLetters?memberNumbers=${memberNumbers}&templateName=${templateName}`,
            method: 'GET',
            withCredentials: true,
            transformRequest: angular.identity,
            responseType: 'arraybuffer'
        });
    };

    return {
        generateFundsExhaustionLetter,
        generateFundsExhaustionLetters
    };
}