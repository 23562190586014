export const columnDefsGrid = [
    {
        field: 'MemberNumber',
        displayName: 'Member Number',
        width: '4%',
        enableSorting: true
    },
    {
        field: 'MemberName',
        displayName: 'Member Name',
        cellClass: 'showExtendedContent',
        width: '8%',
        enableSorting: true,
    },
    {
        field: 'ClaimID',
        displayName: 'Claim Id',
        width: '4%',
        enableSorting: true
    },
    {
        field: 'Vendor',
        displayName: 'Vendor',
        cellClass: 'showExtendedContent',
        width: '10%',
        enableSorting: true
    },
    {
        field: 'ClaimStatus',
        displayName: 'Claim Status',
        width: '7%',
        cellTooltip: true,
        enableSorting: true
    },
    {
        field: 'BillType',
        displayName: 'Bill Type',
        width: '5%',
        enableSorting: true
    },
    {
        field: 'BrandCode',
        cellFilter: 'ifNullThenNa: row.entity.BrandCode',
        displayName: 'Brand Code',
        width: '4%',
        enableSorting: true
    },
    {
        field: 'ReceivedDate',
        displayName: 'Received Date',
        type: 'date',
        cellFilter: "date:\'MM/dd/yyyy\'",
        width: '5%',
        enableSorting: true
    },
    {
        field: 'ServiceDate',
        displayName: 'Service Date',
        type: 'date',
        cellFilter: "date:\'MM/dd/yyyy\'",
        width: '5%',
        enableSorting: true
    },
    {
        field: 'PharmacyDueAmount',
        cellFilter: 'ifNullThenNa: row.entity.PharmacyDueAmount',
        displayName: 'Pharmacy Due Amount',
        width: '5%',
        enableSorting: true
    },
    {
        field: 'PharmacyName',
        displayName: 'Pharmacy Name',

        cellTooltip: true,
        enableSorting: true
    },
    {
        field: 'PaidAmount',
        displayName: 'Payable',
        width: '5%',
        cellFilter: 'currency',
        enableSorting: true
    },
    {
        field: 'MemberFee',
        width: '5%',
        displayName: 'Member Fee',
        cellFilter: 'currency',
        enableSorting: true
    },
    {
        field: 'TotalAmount',
        displayName: 'Total',
        width: '5%',
        cellFilter: 'currency',
        enableSorting: true
    },
    {
        field: 'IsReconciled',
        displayName: 'Is Reconciled',
        width: '5%',
        cellFilter: 'chronovoYesNo: row.entity.IsReconciled',
        enableSorting: true,
    },
    {
        field: 'VendorMargin',
        cellFilter: 'currency',
        displayName: 'Vendor Due',
        width: '4%',
        enableSorting: true
    },
    {
        field: 'AdministrativeFee',
        cellFilter: 'ifNullThenNa: row.entity.AdministrativeFee',
        displayName: 'Admin Fee',
        width: '3%',
        enableSorting: false,
    },
    {
        field: 'AmetrosMargin',
        width: '4%',
        displayName: 'Ametros Due',
        cellFilter: 'ifNullThenNa: row.entity.Margin',
        enableSorting: true
    },
    {
        field: 'FirstPbmSentAmount',
        cellFilter: 'currency',
        width: '4%',
        displayName: 'First Pbm Sent Amount',
        enableSorting: true,
        visible: false
    },
    {
        field: 'LastPbmSentAmount',
        cellFilter: 'currency',
        width: '4%',
        displayName: 'Last Pbm Sent Amount',
        enableSorting: true,
        visible: false
    },
    {
        field: 'PbmHasOverride',
        width: '4%',
        displayName: 'Pbm Has Override',
        enableSorting: true,
        visible: false
    }
];