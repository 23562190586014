/**
* @name contactService
* @desc Contact Service.
**/
(function () {
    'use strict';

    angular
        .module('CareGuard')
        .factory('contactService', contactService);

    /** Inject services into service. */
    contactService.$inject = ['$http'];

    /**
    * @name contactService.
    * @desc Contact Service.
    */
    function contactService($http) {
        var baseUrl = '/api/contacts/';
        var selected = undefined;
        var selectedCallback = undefined;
        var addNewCallback = undefined;
        var removeCallback = undefined;

        var service = {
            setSelected: setSelected,
            getSelected: getSelected,
            setNewCallback: setNewCallback,
            setRemoveCallback: setRemoveCallback,
            fireNewCallback: fireNewCallback,
            fireRemoveCallback: fireRemoveCallback,
            get: get,
            getByCompany: getByCompany,
            getByLocation: getByLocation,
            getAll: getAll,
            getAllById : getAllById,
            getById: getById,
            save: save,
            remove: remove
        };
        
        return service;

        /**
        * @name setSelected.
        * @desc set current selected contact.
        * @param {object} contact - selected contact.
        */
        function setSelected(contact) {
            selected = contact;
            if (selectedCallback)
                selectedCallback(selected);
        };

        /**
        * @name getSelected.
        * @desc get current selected contact.
        * @param {function} func - call function when selected contact is changed.
        */
        function getSelected(func) {
            selectedCallback = func;
            return selected;
        };

        /**
        * @name setNewCallback.
        * @desc set callback when new contact is added.
        * @param {function} func - call function when selected contact is changed.
        */
        function setNewCallback(func) {
            addNewCallback = func;
        };

        /**
        * @name setRemoveCallback.
        * @desc set callback when new contact is removed.
        * @param {function} func - call function when selected contact is removed.
        */
        function setRemoveCallback(func) {
            removeCallback = func;
        };

        /**
        * @name fireNewCallback.
        * @desc fires callback when new contact is added.
        * @param {object} contact - new/changed contact.
        */
        function fireNewCallback(contact) {
            if (addNewCallback)
                addNewCallback(contact);
        };

        /**
        * @name fireRemoveCallback.
        * @desc fires callback when new contact is removed.
        * @param {object} contact - removed contact.
        */
        function fireRemoveCallback(contact) {
            if (removeCallback)
                removeCallback(contact);
        };

        /**
        * @name get.
        * @desc get list of contacts.
        * @param {object} params - search parameter.
        */
        function get(params) {
            return $http.get(baseUrl + 'get', { params: params });
        };
        
        /**
        * @name getByCompany.
        * @desc get list of contacts for a company.
        * @param {object} params - search parameter.
        */
        function getByCompany(companyId, params) {
            if (params) {
                return $http.get(baseUrl + 'get/' + companyId, { params: params });
            }
            else {
                return $http.get(baseUrl + 'getByCompany/' + companyId);
            }
        };

        /**
        * @name getByLocation.
        * @desc get list of contacts for given locations.
        * @param {object} params - list of location Ids.
        */
        function getByLocation(params) {
            return $http.post(baseUrl + 'getByLocation', params);
        };

        /**
       * @name getAll.
       * @desc get All list of contacts.
       */
        function getAll(idList) {
            return $http.post(baseUrl + 'getAll');
        };

        /**
       * @name getAllById.
       * @desc get All list of contacts for the given company id's.
       * @param {object} idList - list of Ids.
       */
        function getAllById(idList) {
            return $http.post(baseUrl + 'getAllById', idList);
        };

        /**
        * @name getByID.
        * @desc get a contact given Id.
        * @param {object} id - id of the contact to get.
        */
        function getById(id) {
            return $http.get(baseUrl + 'getById/' + id);
        };

        /**
        * @name save.
        * @desc save a contact.
        * @param {object} contact - contact to be saved.
        */
        function save(contact) {
            return $http.post(baseUrl + 'save', contact);
        };

        /**
        * @name remove.
        * @desc remove a contact given Id.
        * @param {object} contact - contact to be removed.
        */
        function remove(contact) {
            return $http.post(baseUrl + 'remove', contact);
        }; 
    }
})();