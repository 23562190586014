angular
    .module('CareGuard')
    .controller('careQuoteController', careQuoteController);

careQuoteController.$inject = ['$toastr', 'LxDialogService', 'careQuoteService', 'memberID', 'memberService', 'memberFlagsService', 'benefitsService'];

function careQuoteController($toastr, LxDialogService, careQuoteService, memberID, memberService, memberFlagsService, benefitsService) {

    let vm = this;

    vm.member = {};
    vm.memberflags = [];
    vm.pricingGroups = {};
    vm.quotes = [];
    vm.selectedQuote = {};
    vm.newQuoteDetail = {};
    vm.originalQuoteDetail = {};
    vm.detailToDelete = {};
    vm.quoteToDelete = {};
    vm.pricingGroups = [];
    vm.searchCodes = [];
    vm.Codes = [];

    vm.sortBy = 'QuoteDetailID';
    vm.sortDescending = false;

    vm.hasSearchCodeResult = false;
    vm.codeSearchInProgress = false;
    vm.enableAddQuoteDetailScreen = false;
    vm.includeDispenseFee = true;
    vm.creatingNewQuote = false;

    vm.numberOfFills = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

    const SOURCE_CODE_TYPE_RESTRICTION = 'NDC';
    const deleteQuoteDialogID = 'promptDeletingQuote';
    const deleteQuoteDetailDialogID = 'promptDeletingQuoteDetail';
    const DAYS_UNTIL_DUE = 4;
    const DEFAULT_FILLS_PER_YEAR = 12;
    const DEFAULT_QUANTITY = 1;

    vm.changeSort = changeSort;
    vm.createNewQuote = createNewQuote;
    vm.cloneQuote = cloneQuote;
    vm.selectQuote = selectQuote;
    vm.saveQuote = saveQuote;
    vm.deleteQuote = deleteQuote;
    vm.openPopupForDeletingQuote = openPopupForDeletingQuote;
    vm.addQuoteDetail = addQuoteDetail;
    vm.saveQuoteDetailEdit = saveQuoteDetailEdit;
    vm.deleteQuoteDetail = deleteQuoteDetail;
    vm.openPopupForDeletingQuoteDetail = openPopupForDeletingQuoteDetail;
    vm.exportToForm = exportToForm;
    vm.markQuoteAsCompleted = markQuoteAsCompleted;
    vm.markQuoteAsSent = markQuoteAsSent;
    vm.searchCode = searchCode;
    vm.clearSearchCode = clearSearchCode;
    vm.selectCode = selectCode;
    vm.openAddQuoteDetailScreen = openAddQuoteDetailScreen;
    vm.closeAddQuoteDetailScreen = closeAddQuoteDetailScreen;
    vm.updateQuoteCalculations = updateQuoteCalculations;
    vm.updateDetailCalculations = updateDetailCalculations;
    vm.cancelNewQuote = cancelNewQuote;
    vm.enableQuoteDetailEdit = enableQuoteDetailEdit;
    vm.cancelQuoteDetailEdit = cancelQuoteDetailEdit;

    function init() {
        const serviceCalls = [
            memberFlagsService.getMemberFlags(memberID),
            memberService.getById(memberID),
            careQuoteService.getPricingGroups(),
            careQuoteService.getQuotes(memberID)
        ];

        Promise.all(serviceCalls).then(result => {
            Object.assign(vm.memberflags, result[0].Data);
            Object.assign(vm.member, result[1].Data);
            Object.assign(vm.pricingGroups, result[2].Data);
            Object.assign(vm.quotes, result[3].data);

            vm.quotes.length ? selectQuote(vm.quotes[0].QuoteID) : createNewQuote();
        });
    }
    init();

    function changeSort(column) {
        vm.sortBy = column;
        vm.sortDescending = !vm.sortDescending;
    }

    function createNewQuote() {
        vm.creatingNewQuote = true;
        vm.selectedQuote = loadDefaultNewQuoteValues();
    }

    function loadDefaultNewQuoteValues() {
        return {
            ReceivedDate: getTodaysDate(),
            DueDate: getDueDate(),
            MemberID: memberID,
            MemberNumber: vm.member.MemberNumber,
            LifeExpectancy: vm.member.LifeExpectancy
        };
    }

    function cloneQuote(quote) {
        if (vm.creatingNewQuote) {
            $toastr.show(`Current Quote must be saved in order to clone.`, `warning`);
            return;
        }

        let clonedQuote = Object.assign({}, quote);

        clonedQuote.QuoteID = 0;
        clonedQuote.CompletedDate = null;
        clonedQuote.SentDate = null;

        careQuoteService.cloneQuote(clonedQuote).then(result => {
            Object.assign(clonedQuote, result);
            vm.quotes.push(clonedQuote);
            selectQuote(clonedQuote.QuoteID);
        });
    }

    function exportToForm(quote) {
        if (!vm.creatingNewQuote) {
            careQuoteService.downloadQuoteDocument(quote, vm.includeDispenseFee);
        } else {
            $toastr.show(`You must save your current quote before printing document.`, `warning`);
        }
    }

    function selectQuote(quoteId) {
        if (vm.selectedQuote.QuoteID === quoteId) {
            return;
        }

        closeAddQuoteDetailScreen();
        const quote = vm.quotes.find(quote => quote.QuoteID === quoteId);
        angular.copy(quote, vm.selectedQuote);
    }

    function saveQuote(quote) {
        careQuoteService.saveQuote(quote).then(result => {
            if (vm.creatingNewQuote) {
                quote.QuoteID = result;
                quote.PlanName = getPlanName(quote.PricingGroupID);

                let tmpQuote = {};
                angular.copy(quote, tmpQuote);
                vm.quotes.push(tmpQuote);


                vm.creatingNewQuote = false;
            }
        });
    }

    function getPlanName(pricingGroupId) {
        const plan = vm.pricingGroups.find(group => group.PricingGroupID === +pricingGroupId);

        return plan.PlanName;
    }

    function openPopupForDeletingQuote() {
        LxDialogService.open(deleteQuoteDialogID);
    }

    function deleteQuote() {
        careQuoteService.deleteQuote(vm.selectedQuote.QuoteID).then(result => {
            LxDialogService.close(deleteQuoteDialogID);

            vm.quotes = vm.quotes.filter(quote => quote.QuoteID !== Number(vm.selectedQuote.QuoteID));
            vm.quotes.length ? selectQuote(vm.quotes[0].QuoteID) : createNewQuote();
        });
    }

    function openAddQuoteDetailScreen() {
        vm.enableAddQuoteDetailScreen = true;
    }

    function closeAddQuoteDetailScreen() {
        vm.enableAddQuoteDetailScreen = false;
        vm.newQuoteDetail = {};
    }

    function addQuoteDetail(detail) {
        if (detail.Code && detail.Quantity) {
            detail.QuoteID = vm.selectedQuote.QuoteID;

            careQuoteService.addQuoteDetail(detail).then(result => {
                detail.QuoteDetailID = result;
                if (!vm.selectedQuote.Details) {
                    detail.RowNumber = 1;
                    vm.selectedQuote.Details = [];
                }else {
                    detail.RowNumber = vm.selectedQuote.Details.length + 1;
                }

                vm.selectedQuote.Details.push(detail);

                return careQuoteService.recalculateQuoteTotals(vm.selectedQuote);
            }).then(result => {
                angular.copy(result.data, vm.selectedQuote);
                const selectedQuoteIndex = vm.quotes.findIndex(item => item.QuoteID === result.data.QuoteID);
                vm.quotes.splice(selectedQuoteIndex, 1, result.data);

                closeAddQuoteDetailScreen();
            });
        } else {
            $toastr.show(`You must have a selected code and an entered quantity in order to save.`, `warning`);
        }
    }

    function openPopupForDeletingQuoteDetail(detail) {
        Object.assign(vm.detailToDelete, detail);
        LxDialogService.open(deleteQuoteDetailDialogID);
    }

    function deleteQuoteDetail() {
        careQuoteService.deleteQuoteDetail(vm.detailToDelete.QuoteID, vm.detailToDelete.QuoteDetailID).then(result => {
            LxDialogService.close(deleteQuoteDetailDialogID);

            const updatedDetailList = vm.selectedQuote.Details.filter(detail => detail.QuoteDetailID !== vm.detailToDelete.QuoteDetailID);
            vm.selectedQuote.Details = reorderDetailRowNumbers(updatedDetailList);
            vm.detailToDelete = {};
            updateQuoteCalculations(vm.selectedQuote);
        });
    }

    function reorderDetailRowNumbers(detailList) {
        detailList.forEach((detail, i) => {
            detail.RowNumber = i + 1;
        });

        return detailList;
    }

    function markQuoteAsCompleted(quote) {
        if (!quote.Details || (quote.Details && !quote.Details.length)) {
            $toastr.show(`Details must be added in order to mark this quote as completed.`, `warning`);
            return;
        }

        quote.CompletedDate = getTodaysDate();
        careQuoteService.markQuoteAsCompleted(quote.QuoteID).then(result => {
            updateQuoteCalculations(quote);
        });
    }

    function markQuoteAsSent(quote) {
        if (!quote.Details || (quote.Details && !quote.Details.length)) {
            $toastr.show(`Details must be added in order to mark this quote as sent.`, `warning`);
            return;
        }
        if (!quote.CompletedDate) {
            $toastr.show(`Quote must be marked as completed before being marked as sent.`, `warning`);
            return;
        }

        quote.SentDate = getTodaysDate();
        careQuoteService.markQuoteAsSent(quote.QuoteID);
    }

    function searchCode(detail) {
        if (!detail.Code || detail.Code.length < 3) {
            return;
        }

        benefitsService.getBenefits(detail.Code, SOURCE_CODE_TYPE_RESTRICTION).then(result => {
            const codes = result.Data;
            detail.searchCodes = codes.filter(code => code.HasPrice);
            vm.hasSearchCodeResult = true;
        }).catch(() => {
            $toastr.show(`You do not have permission for this action.`, `warning`);
        });
    }

    function selectCode(selectedCode, detail) {
        vm.codeSearchInProgress = true;
        vm.hasSearchCodeResult = false;

        Object.assign(detail, selectedCode, {
            LengthForMedicationInYears: vm.member.LifeExpectancy,
            FillsPerYear: DEFAULT_FILLS_PER_YEAR,
            Quantity: DEFAULT_QUANTITY
        });

        careQuoteService.getServiceCodePricing(detail.ServiceCodeID, vm.selectedQuote.PricingGroupID).then(result => {
            Object.assign(detail, result.Data);
            vm.codeSearchInProgress = false;

            return careQuoteService.calculateQuoteDetails(detail);
        }).then(result => {
            Object.assign(detail, result.Data);
        });

        vm.newQuoteDetail.searchCodes = [];
    }

    function updateDetailCalculations(detail) {
        careQuoteService.calculateQuoteDetails(detail).then(result => {
            detail.isEditActive = true;
            Object.assign(detail, result.Data);
        });
    }

    function updateQuoteCalculations(quote) {
        if (!quote.Details || !quote.Details.length) {
            return;
        }

        careQuoteService.updatePricingGroupCalculations(quote).then(result => {
            Object.assign(quote, result.data);
        });
    }

    function clearSearchCode(quote) {
        quote.Code = null;
        quote.searchCodes = [];
        vm.hasSearchCodeResult = false;
    }

    function enableQuoteDetailEdit(detail) {
        Object.assign(vm.originalQuoteDetail, detail);
        detail.isEditActive = true;
    }

    function cancelQuoteDetailEdit(detail) {
        Object.assign(detail, vm.originalQuoteDetail);
        vm.originalQuoteDetail = {};
        detail.isEditActive = false;
    }

    function cancelNewQuote(quote) {
        vm.creatingNewQuote = false;
        selectQuote(vm.quotes[0].QuoteID);
    }

    function saveQuoteDetailEdit(detail) {
        if (detail.Quantity <= 0 || !Number.isInteger(detail.Quantity)) {
            $toastr.show(`Invalid Quantity value.`, `warning`);
            return;
        }

        if (detail.Quantity != vm.originalQuoteDetail.Quantity ||
            detail.LengthForMedicationInYears != vm.originalQuoteDetail.LengthForMedicationInYears ||
            detail.FillsPerYear != vm.originalQuoteDetail.FillsPerYear) {

            detail.Quantity = +detail.Quantity;
            detail.LengthForMedicationInYears = +detail.LengthForMedicationInYears;
            detail.FillsPerYear = +detail.FillsPerYear;

            careQuoteService.editQuoteDetail(detail).then(result => {
                detail.isEditActive = false;
            });
        }
        detail.isEditActive = false;
    }

    function getTodaysDate() {
        return moment().format('MM-DD-YYYY');
    }

    function getDueDate() {
        return moment().add(DAYS_UNTIL_DUE, 'days').format('MM-DD-YYYY');
    }
}