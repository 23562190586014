angular
    .module('CareGuard')
    .directive('inputPercentFormat', inputPercentFormat);

inputPercentFormat.$inject = ['$filter'];

function inputPercentFormat($filter) {
    return {
        restrict: 'A',
        require: 'ngModel',
        link: function($scope, elm, attrs, ngModelController) {
            if (!ngModelController) return;

            const onlyNumbers = $filter('positiveNumbers');
            const decimalSeparator = '.';

            ngModelController.$parsers.unshift(function(value) {
                let valueAsNumber = Math.floor(onlyNumbers(value) * 10) / 10;

                if (valueAsNumber > 100) {
                    valueAsNumber = 100;
                }

                let filtered = '';

                if (value === '' || value === 0) {
                    filtered = onlyNumbers(value);

                } else {
                    const  dotIndex = onlyNumbers(value).indexOf(decimalSeparator);
                    if (dotIndex > -1) {
                        let valueBeforeDot = onlyNumbers(value).substr(0, dotIndex + 1);
                        let valueAfterDot = onlyNumbers(value).substr(dotIndex + 1, onlyNumbers(value).length);
                        filtered = valueBeforeDot + valueAfterDot;
                    } else {
                        filtered = onlyNumbers(value);
                    }
                }

                ngModelController.$setViewValue(filtered);
                ngModelController.$render();

                return valueAsNumber;
            });
        }
    }
}
