angular
    .module('CareGuard')
    .controller('bulkStatementsController', bulkStatements);

bulkStatements.$inject = [
    'utilService',
    'memberStatementsService'];

function bulkStatements(
    utilService,
    memberStatementsService)
{
    let vm = this;

    vm.generateStatements = generateStatements;

    function generateStatements() {
        const memberIds = vm.memberIds.split(',').map(id => Number(id.trim()));
        memberStatementsService.generateBulkStatements({ memberIds, effectiveMonth: vm.effectiveMonth.toISOString() }).then(response => {
            utilService.processResponse({ response } );
        });
    }
}