angular
    .module('CareGuard')
    .factory('fileService', fileService);

function fileService() {
    function createFileWithName(file, filename) {
        if (!file || !filename) return;

        const newFile = new File([file], filename, { type: file.type, lastModified: file.lastModified });
        const fileFormData = new FormData();
        fileFormData.append('file', newFile);

        return fileFormData;
    }

    function getFileNameWithoutExtension(fileName) {
        if (!fileName) return null;

        const dotIndex = fileName.lastIndexOf('.');

        if (dotIndex === -1) {
            return fileName;
        }

        return fileName.substring(0, dotIndex);
    }

    function getExtension(fileName) {
        if (!fileName) return null;

        const dotIndex = fileName.lastIndexOf('.');

        if (dotIndex === -1) {
            return null;
        }

        return fileName.substring(dotIndex + 1);
    }

    return {
        createFileWithName,
        getFileNameWithoutExtension,
        getExtension,
    };
}