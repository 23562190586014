angular
    .module('CareGuard')
    .service('gridUtils', gridUtilsService);

gridUtilsService.$inject = [
    'billTypeId'
];

function gridUtilsService(billTypeId) {

    this.populateRowId = function (originalData) {
        if (!originalData) return null;

        return originalData.map((item, index) => {
            item.rowId = index + 1;

            return item;
        });
    };

    this.populateLineNumber = function (originalData) {
        if (!originalData) return null;

        return originalData.map((item, index) => {
            item.lineNumber = index + 1;

            return item;
        });
    };

    this.getEmptyRowEntityFromColumnDefs = function (entity) {
        if (!entity  || entity.length < 1) return;

        let defaultRow = {};
        entity.forEach(field => {
            if (field.displayName) {
                defaultRow[field.field] = null;
            }
        });

        return defaultRow;
    };

    this.generateRowId = function (data) {
        let maxRowId = data.reduce((maxId, current) => {
            const rowId = current.rowId;
            if (!rowId) {
                return maxId;
            }

            return rowId > maxId ? rowId : maxId;
        }, 0);

        return maxRowId + 1;
    };

    this.generateLineItemNumber = function (data) {
        return data.reduce((max, curr) => Math.max(max, curr.lineNumber), 0) + 1;
    }

    this.validateLineItemsGrid = function (gridOptionsLineItems, claim, diagnosisSequenceSelector) {
        if (!diagnosisSequenceSelector || typeof diagnosisSequenceSelector !== 'function')
            throw new Error('Invalid sequence selector supplied.');

        let invalidFields = {},
            conditionallyRequiredPresentFields = {},
            conditionallyRequiredFieldsNames = {},
            customValidationErrors = [];

        const gridToValidate = gridOptionsLineItems;

        if (!gridToValidate) return;

        gridToValidate.data.forEach((row, index) => {
            invalidFields[index + 1] = [];
            conditionallyRequiredPresentFields[index + 1] = [];
            conditionallyRequiredFieldsNames[index + 1] = [];

            for (let field in row ) {
                const fieldConfig = gridToValidate.columnDefs.find(config => config.field === field);
                if (fieldConfig && fieldConfig.required && (!row[field])) {
                    invalidFields[index + 1].push(fieldConfig.displayName);
                }

                if (fieldConfig?.conditionallyRequired) {
                    conditionallyRequiredFieldsNames[index + 1].push(fieldConfig.displayName);
                    if (row[field]) {
                        conditionallyRequiredPresentFields[index + 1].push(fieldConfig.displayName);
                    }
                }
            }

            if (!conditionallyRequiredPresentFields[index + 1].length) {
                const conditionallyRequiredFieldsMessage = "At least one of the fields: " + [ ...conditionallyRequiredFieldsNames[index + 1]];
                invalidFields[index + 1].push(conditionallyRequiredFieldsMessage);
            }

            let datesValidationErrors = validateLineItemDates(row['serviceDate'], row['serviceDateEnd'], index);
            let ndcQuantityValidationErrors = validateNdcQuantity(row['drugQuantity'], row['drugQuantityMeasure'], index);
            let diagnosisPointersValidationErrors = this.validateDiagnosisCodePointersForGrid(row, index, claim, diagnosisSequenceSelector);

            customValidationErrors = customValidationErrors
                .concat(datesValidationErrors)
                .concat(ndcQuantityValidationErrors)
                .concat(diagnosisPointersValidationErrors);

            if (!invalidFields[index + 1].length) {
                delete invalidFields[index + 1]
            }
        });

        let placeOfServiceValidationErrors = validatePlaceOfServiceForGrid(gridToValidate.data, claim);
        customValidationErrors = customValidationErrors.concat(placeOfServiceValidationErrors);

        return { invalidFields, customValidationErrors };
    };

    function validateLineItemDates(serviceDate, serviceDateEnd, rowIndex) {
        const currentDate = new Date();
        const serviceDateObj = new Date(serviceDate);
        const serviceDateEndObj = new Date(serviceDateEnd);

        let validationErrors = [];

        if (serviceDateObj > currentDate) {
            validationErrors.push(`"From Date" in row ${rowIndex + 1} cannot be the future date`);
        }

        if (serviceDateEndObj > currentDate) {
            validationErrors.push(`"To Date" in row ${rowIndex + 1} cannot be the future date`);
        }

        if (serviceDateObj > serviceDateEndObj) {
            validationErrors.push(`The date in the field "From Date" in row ${rowIndex + 1} cannot be after the date in the field "To Date"`);
        }

        return validationErrors;
    }

    function validateNdcQuantity(drugQuantity, drugQuantityMeasure, rowIndex) {
        let validationErrors = [];

        if ((drugQuantity && !drugQuantityMeasure) || (!drugQuantity && drugQuantityMeasure)) {
            validationErrors.push(`Please fill both NDC quantity and NDC quantity measure in row ${rowIndex + 1}!`);
        }

        return validationErrors;
    }

    function validatePlaceOfServiceForGrid(lineItems, claim) {
        const validationErrors = [];

        //due to different naming convention on create claim popup and claim details page
        const claimBillTypeId = claim?.billTypeID || claim?.BillTypeID;

        //required for Bill Type Id = 211 (CMS-1500)
        if (claimBillTypeId == billTypeId.CMS1500) {
            lineItems.forEach((lineItem, rowIndex) => {
                if (!lineItem.placeOfServiceCode) {
                    validationErrors.push(`Please fill Place of Service (POS) in row ${rowIndex + 1}`);
                }
            });

            return validationErrors;
        }

        //otherwise, POS must be either empty for all lines or non-empty for all lines
        if (lineItems.some(lineItem => !lineItem.placeOfServiceCode)
            && lineItems.some(lineItem => !!lineItem.placeOfServiceCode)) {
            validationErrors.push(`Place of Service (POS) must either be empty for all line items or non-empty for all line items`);
        }

        return validationErrors;
    }

    this.validateDiagnosisCodePointersForGrid = function (row, rowIndex, claim, diagnosisSequenceSelector) {
        if (!diagnosisSequenceSelector || typeof diagnosisSequenceSelector !== 'function')
            throw new Error('Invalid sequence selector supplied.');

        const validationErrors = [];

        if (!this.validateDiagnosisCodePointersForLineItem(row, claim, diagnosisSequenceSelector)) {
            validationErrors.push(`The diagnosis pointers in row ${rowIndex + 1} should match one of the diagnoses on the claim`);
        }

        return validationErrors;
    }

    this.validateDiagnosisGrid = function(gridOptionsDiagnosis) {
        if (!gridOptionsDiagnosis || !gridOptionsDiagnosis.data.length) return;

        let isAnyPrimaryDiagnosis = false;
        let isAnyNonUniqueCode = false;
        let emptyRow = [];

        gridOptionsDiagnosis.data.forEach((item, index) => {
            if (item.isPrimary) {
                isAnyPrimaryDiagnosis = true;
            }

            if (!item.code || !item.codeType) {
                emptyRow.push(index + 1);
            }

            const isThisCodeNonUnique = gridOptionsDiagnosis.data.some((code, codeIndex) => {
                if (codeIndex !== index && code.code) {
                    return code.code === item.code;
                }
            });

            if (isThisCodeNonUnique) {
                isAnyNonUniqueCode = true;
            }
        });

        const diagnosisPrimaryInvalidMessage = !isAnyPrimaryDiagnosis ? 'Exactly one diagnosis has to be marked as Primary!' : '';
        const diagnosisNonUniqueInvalidMessage = isAnyNonUniqueCode ? 'Diagnoses cannot have any duplicates!' : null;
        const emptyRowsStr = emptyRow.join(', ');
        const diagnosisEmptyRowMessage = emptyRow.length ? 'Please populate row(s) ' + emptyRowsStr + ' in diagnoses with autocomplete!' : null;

        let resultInvalidMessage = diagnosisPrimaryInvalidMessage;

        if (diagnosisNonUniqueInvalidMessage) {
            resultInvalidMessage = resultInvalidMessage.concat('\n', diagnosisNonUniqueInvalidMessage);
        }

        if (diagnosisEmptyRowMessage) {
            resultInvalidMessage = resultInvalidMessage.concat('\n', diagnosisEmptyRowMessage);
        }

        if (!resultInvalidMessage) {
            resultInvalidMessage = null;
        }

        return {
            isAnyPrimaryDiagnosis,
            isAnyNonUniqueCode,
            resultInvalidMessage,
        };
    };

    this.hasGridChanged = function (currentData, originalData) {
        if (!currentData?.length && !originalData?.length) {
            return false;
        }

        const result = currentData?.length === originalData?.length
            && currentData?.every((element, index) => {
                return angular.equals(element, originalData[index]);
            });

        return !result;
    }

    this.castDiagnosisSequenceToLetterBySelector = function (diagnosis, sequenceSelector) {
        if (!sequenceSelector || typeof sequenceSelector !== 'function')
            throw new Error('Invalid sequence selector supplied.');
        if (!diagnosis) return null;

        const key = sequenceSelector(diagnosis);

        //check if number
        if (!key || +key !== key) return null;

        let aCode = 'A'.charCodeAt(0);

        let currentCode = key - 1 + aCode;
        return String.fromCharCode(currentCode);
    }

    this.validateDiagnosisCodePointersForLineItem = function (lineItem, claim, diagnosisSequenceSelector) {
        if (!diagnosisSequenceSelector || typeof diagnosisSequenceSelector !== 'function')
            throw new Error('Invalid sequence selector supplied.');

        if (!lineItem?.diagnosisCodePointersPlain) {
            //due to different naming convention on create claim popup and claim details page
            const claimBillTypeId = claim?.billTypeID || claim?.BillTypeID;

            //required for Bill Type Id = 211 (CMS-1500) only
            if (claimBillTypeId == billTypeId.CMS1500) return false;
            return true;
        }
        if (!claim?.diagnosisList) return false;
        if (!claim.diagnosisList.length) return false;

        const diagnosisSequencesLettered = claim.diagnosisList
            .map(diagnosis => this.castDiagnosisSequenceToLetterBySelector(diagnosis, diagnosisSequenceSelector));

        for (let char of lineItem.diagnosisCodePointersPlain) {
            if (diagnosisSequencesLettered.indexOf(char) === -1) {
                return false;
            }
        }

        return true;
    }

}