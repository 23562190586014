/**
* @name chronovoFieldValidity
* @desc sets validity attribute for the input element.
* @usage: <input type="button" name="txtName" chornovo-field-validity="true" />
**/
(function () {
    'use strict';

    angular
        .module('CareGuard')
        .directive('chronovoFieldValidity', chronovoFieldValidity);
    
    /**
    * @name chronovoFieldValidity.
    * @desc sets validity attribute for the input element, name is required.
    */
    function chronovoFieldValidity() {
        var directive = {
            restrict: 'A',
            require: 'ngModel',
            link: postLink
        };
        
        return directive;

        /**
        * @name postLink
        * @desc post link function
        */
        function postLink($scope, $element, $attrs, $controller) {
            
            /**
            * @name $watch.
            * @desc watch changes validity variable
            */
            $scope.$watch($attrs.chronovoFieldValidity, function (newVal, oldVal) {
                $controller.$setValidity('rule', !newVal);
            });
        };
    };
})();