angular
    .module('CareGuard')
    .factory('$cache', ['$cacheFactory', function ($cacheFactory) {
        return $cacheFactory('cq');
    }])
    .run(run);

run.$inject = [
    '$rootScope',
    '$state',
    '$location',
    'webStorageService',
    'accountService',
    'sidebarService',
    'betaPageService'
];

function run (
    $rootScope,
    $state,
    $location,
    webStorage,
    accountService,
    sidebarService,
    betaPageService) {

    $rootScope.sidebar = sidebarService;
    $rootScope.accsrvc = accountService;

    const webStorageKeyForClaimDetailsState = 'previousStateForClaimDetails';
    const claimDetailStateName = 'layoutNoNav.claim-details';

    // keep track of previous state, so we send the user back to state in case they have to log in
    $rootScope.$on('$stateChangeStart', function (evt, toState, toParams, fromState, fromParams) {
        $state.previous = { state: fromState, params: fromParams };

        const changeStatePromise = betaPageService.changeState(toState);

        // if requires Login is not defined or is set to true, check to make sure user is logged in
        // otherwise direct to login page (authinterceptor takes care of that)
        if (toState.requiresLogIn == undefined || toState.requiresLogIn == true) {
            evt.preventDefault();

            accountService.authenticate().then(function () {

                // Check for roles on route, if they exists, check to see if the user has the proper role
                // if they do not redirect to invalid page if they are valid redirect to the destination
                if (toState.roles) {
                    var isInRole = accountService.isInRole(toState.roles);
                    if (!isInRole) {
                        $location.path('/invalid');
                        return;
                    }
                }

                if (toState.redirectTo) {
                    return $state.go(toState.redirectTo, toParams, {location: 'replace'}).then(_  => {
                        const newState = $state.get(toState.redirectTo);
                        $rootScope.$broadcast('$stateChangeSuccess', newState, toParams, fromState, fromParams);
                    });
                }

                changeStatePromise.then(state => {
                    $state.go(state.name, toParams, {notify: false}).then(function () {
                        $rootScope.$broadcast('$stateChangeSuccess', state, toParams, fromState, fromParams);
                    });
                });
            });
        }
    });

    // clear the previous state if you succesfully transition to a state, user is authenticated.
    $rootScope.$on('$stateChangeSuccess', function (evt, toState, toStateParams, fromState, fromStateParams) {
        if ($state.current.name !== claimDetailStateName) {
            webStorage.set(webStorageKeyForClaimDetailsState, null);
        }

        if (toState.name != 'login') {
            $state.previous = undefined;
        }
    });

    window.onbeforeunload = function () {
        const previouslyStoredState = webStorage.get(webStorageKeyForClaimDetailsState);
        if (($state.current.name === claimDetailStateName) && previouslyStoredState
                && previouslyStoredState.parentState && previouslyStoredState.memberID
                && !$state.params.parentState && !$state.params.memberID) {
            $state.params.memberID = previouslyStoredState.memberID;
            $state.params.parentState = previouslyStoredState.parentState;
        }

        const parentStateParams = $state.current.name === claimDetailStateName ? $state.params : null;
        webStorage.set(webStorageKeyForClaimDetailsState, parentStateParams);
    };
}
