/**
* @name chronovoBind
* @desc Bind Event Directive.
* @usage <button chronovo-bind="func()" bind-event="click">Home</button>
**/
(function () {
    'use strict';

    angular
        .module('CareGuard')
        .directive('chronovoBind', chronovoBind);

    /** Inject services into directive. */
    chronovoBind.$inject = ['$parse'];

    /**
    * @name chronovoBind.
    * @desc Bind Event Directive.
    * @param {object} $parse - angularjs parse service.
    */
    function chronovoBind($parse) {
        var directive = {
            restrict: "A",
            link: postLink
        };

        return directive;

        function postLink($scope, $element, $attrs, $controller) {
            var event = 'keydown keypress';

            if ($attrs.bindEvent)
                event = $attrs.bindEvent;
            
            var fn = $parse($attrs.chronovoBind);
            $element.bind(event, function () {
                fn($scope);
                return false;
            });
        }
    }
})();