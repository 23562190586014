/**
* @name lifecompanyService
* @desc Life Company Service.
**/
(function () {
    'use strict';

    angular
        .module('CareGuard')
        .factory('lifecompanyService', lifecompanyService);

    /** Inject services into service. */
    lifecompanyService.$inject = ['$http', '$q', '$cache'];

    /**
    * @name lifecompanyService.
    * @desc Life Company Service.
    */
    function lifecompanyService($http, $q, $cache) {
        var baseUrl = '/api/lifecompanies/';
        var panelKey = 'lifecompanyService.paneldata';
        var companyKey = 'lifecompanyService.data';
        var selected = undefined;
        var selectedCallback = undefined;
        var addNewCallback = undefined;
        var service = {
            setSelected: setSelected,
            getSelected: getSelected,
            setNewCallback: setNewCallback,
            fireNewCallback: fireNewCallback,
            get: get,
            cache: cache,
            getPanel: getPanel,
            save: save,
            getRules: getRules,
            saveRules: saveRules
        };
        
        return service;

        /**
        * @name setSelected.
        * @desc set current selected company.
        * @param {object} comp - selected company.
        */
        function setSelected(comp) {
            selected = comp;
            if (selectedCallback)
                selectedCallback(selected);
        };

        /**
        * @name getSelected.
        * @desc get current selected company.
        * @param {function} func - call function when selected company is changed.
        */
        function getSelected(func) {
            selectedCallback = func;
            return selected;
        };

        /**
        * @name setNewCallback.
        * @desc set callback when new company is added.
        * @param {function} func - call function when selected company is changed.
        */
        function setNewCallback(func) {
            addNewCallback = func;
        };

        /**
        * @name fireNewCallback.
        * @desc fires callback when new company is added.
        * @param {object} comp - selected company.
        */
        function fireNewCallback(comp) {
            if (addNewCallback)
                addNewCallback(comp);
        };

        /**
        * @name cache.
        * @desc cache list of life companies.
        */
        function cache() {
            return $http.get(baseUrl + 'get').then(function (data) {
                $cache.put(companyKey, data);
            });
        };

        /**
        * @name get.
        * @desc get list of all companies.
        */
        function get() {
            return $cache.get(companyKey);
        };

        /**
        * @name getPanel.
        * @desc get list of companies on the approved panel.
        * @param {string} companyId - company id for which to get the panel.
        */
        function getPanel(companyId) {
            return $http.get(baseUrl + 'get/panel/' + companyId);
        };
        
        /**
        * @name save.
        * @desc save a company.
        * @param {object} company - company to be saved.
        */
        function save(company) {
            return $http.post(baseUrl + 'save', company);
        };

        /**
        * @name getRules.
        * @desc get company rules.
        * @param {string} id - id of the company to get.
        */
        function getRules(id) {
            return $http.get(baseUrl + 'get/rules/' + id);
        };

        /**
        * @name saveRules.
        * @desc save a company.
        * @param {object} company - company to be saved.
        */
        function saveRules(rules) {
            return $http.post(baseUrl + 'save/rules', rules);
        };
    }
})();