/**
* @name 
* @desc 
**/
(function () {
    'use strict';

    angular
        .module('CareGuard')
        .factory('reportsService', reportsService);

    /** Inject services into service. */
    reportsService.$inject = ['$http'];

    
    function reportsService($http) {
        var baseUrl = '/api/reports/';
        
        var service = {
            filterResults: filterResults,
            exportResults: exportResults,
            getReportFields: getReportFields
        };

        return service;

        
        function filterResults(reporttype, startdate, enddate, pageindex, pagesize, sortcolumn, sortorder) {
          return $http.get(baseUrl + 'filterResults/' + reporttype + '/' + startdate + '/' + enddate + '/' + pageindex + '/' + pagesize + '/' + sortcolumn + '/' + sortorder);
        }

        
        function exportResults(reporttype, startdate, enddate) {
          return $http.get(baseUrl + 'exportResults/' + reporttype + '/' + startdate + '/' + enddate);
        }

        function getReportFields(reporttype) {
          return $http.get(baseUrl + 'getReportFields/' + reporttype);
        }
    }
})();