/**
* @name companiesgridModalController
* @desc companies modal controller.
**/
(function () {
    'use strict';

    angular
        .module('CareGuard')
        .controller('companiesgridModalController', companiesgridModalController);

    /** Inject services into controller. */
    companiesgridModalController.$inject = ['$toastr', 'LxDialogService', 'companyGridService', 'contactGridService', 'lookupService', 'locationService'];

    /**
    * @name companiesgridModalController.
    * @desc companies modal controller.
    */
    function companiesgridModalController($toastr, LxDialogService, companyGridService, contactGridService, lookupService, locationService) {
        var vm = this;

        // variables
        var dialogId = 'dlgCompanyModal';
        vm.company = undefined;
        vm.original = undefined;
        vm.lookupdata = undefined;
        vm.locations = undefined;
        vm.confirm = false;

        // functions
        vm.save = save;
        vm.select = select;
        vm.remove = remove;

        init();

        /* 
        * @name init
        * @desc initilize service
        */
        function init() {
            vm.lookupdata = {
                roles: lookupService.getByCategory('Company Role')
            };
            vm.original = companyGridService.getCompany();
            locationService.getAll().then(function (result) {
                vm.locations = result;
            });
            if (!vm.original) {
                vm.company = {};
            }
            else {
                vm.company = angular.copy(vm.original, {});
            };
        };

        /* 
       * @name select
       * @desc select company. 
       * @param {object} val - selected object.
       */
        function select(val) {
            if (!val) return;
            var comp = Enumerable.from(vm.locations).where('$.FullLocationName =="' + val.originalObject.FullLocationName + '"').firstOrDefault(undefined);
            vm.company.Name = comp.FullLocationName;
            vm.company.Id = comp.Id;
        };

        /* 
        * @name save
        * @desc update service with company and close dialog
        */
        function save() {
            if (!vm.original) {
                vm.original = {};
                var companies = companyGridService.getCompanies();
                if (!companies) {
                    companies = [];
                }
                companies.push(vm.original);
            }

            vm.original.Role = vm.company.Role;
            vm.original.Name = vm.company.Name;
            vm.original.ClaimNumber = vm.company.ClaimNumber;
            vm.original.PolicyNumber = vm.company.PolicyNumber;
            vm.original.Id = vm.company.Id;
            companyGridService.fireUpdateCallback();
            LxDialogService.close(dialogId);
        };

        /* 
        * @name remove
        * @desc remove company from the case
        */
        function remove() {
            var companies = companyGridService.getCompanies();
            var contacts = contactGridService.getContacts();
            
            // check if there are any contacts from this company
            var exists = Enumerable.from(contacts).any('$.LocationId == "' + vm.company.Id + '"');
            if (exists) {
                $toastr.show('Unable to delete company. Please first remove all contacts that belong to this company from the case.','error');
                return;
            };
           
            for (var key in companies) {
                if (companies[key].Id == vm.company.Id) {
                    companies.splice(companies[key], 1);
                }
            };
            companyGridService.setCompanies(companies);
            companyGridService.fireUpdateCallback();
            LxDialogService.close(dialogId);
        };
    };
})();