/**
* @name casedataController
* @desc Contains logic for case data page.
**/
(function () {
    'use strict';

    angular
        .module('CareGuard')
        .controller('casedataController', casedataController)

    /** Inject services into controller. */
    casedataController.$inject = ['LxDialogService', 'lookupService', 'layoutService', 'caseService', 'caseData', 'brokerList', 'diaryService',
                                  'annuitantService', 'beneficiaryService', 'companyGridService', 'contactGridService', 'contactService'];

    /**
    * @name casedataController.
    * @desc Contains logic for case data page.
    * @param {object} util - utility service.
    * @param {object} caseData - case data.
    * @param {object} lookupData - lookup list. 
    */
    function casedataController(LxDialogService, lookupService, layoutService, caseService, caseData, brokerList, diaryService,
                                annuitantService, beneficiaryService, companyGridService, contactGridService, contactService) {

        // variables
        var vm = this;
        vm.lookupdata = undefined;
        vm.adjuster = undefined;
        vm.activeTab = undefined;

        //functions
        vm.save = save;
        vm.show = show;
        vm.select = select;

        init();

        /* 
        * @name init
        * @desc initialize module
        */
        function init() {
            vm.case = angular.copy(caseData, {});
            vm.lookupdata = {
                statuses: lookupService.getByCategory('Case Status'),
                clientcasestatus: lookupService.getByCategory('Client Case Status'),
                brokers: brokerList,
                adjusters: undefined,
                states: lookupService.getKeysByCategory('State'),
                injurytypes: lookupService.getByCategory('Injury Type'),
                injuryseverities: lookupService.getByCategory('Injury Severity'),
                additionalparty: lookupService.getByCategory('Additional Party'),
                liabilityCaseTypes: lookupService.getByCategory('Liability Case Type')
            };

            // get adjuster list
            contactService.getByCompany(vm.case.CompanyId).then(function (result) {
                vm.lookupdata.adjusters = result;
                vm.adjuster = Enumerable.from(vm.lookupdata.adjusters).where('$.Id == "' + vm.case.AdjusterId + '"').firstOrDefault(undefined);
            });

            //vm.wcstatus = lookupService.getValuesByCategory('WC Status');

            if (vm.case.Beneficiaries == undefined) {
                vm.case.Beneficiaries = [];
            };
            if (vm.case.Companies == undefined) {
                vm.case.Companies = [];
            };
            if (vm.case.Contacts == undefined) {
                vm.case.Contacts = [];
            };
            layoutService.setSelected({ Name: vm.case.CaseName });
            annuitantService.setAnnuitants(vm.case.Annuitants);
            beneficiaryService.setAnnuitants(vm.case.Annuitants);
            beneficiaryService.setBeneficiaries(vm.case.Beneficiaries);
            companyGridService.setCompanies(vm.case.Companies)
            contactGridService.setContacts(vm.case.Contacts);
            caseService.setCase(vm.case);

            annuitantService.setUpdateCallback(save);
            beneficiaryService.setUpdateCallback(save);
            companyGridService.setUpdateCallback(save);
            contactGridService.setUpdateCallback(save);
        };

        /* @name save
         * @desc save case information
         */
        function save() {
            if (vm.case.Beneficiaries == undefined || vm.case.Beneficiaries.length == 0) {
                vm.case.Beneficiaries = undefined;
            };
            if (vm.case.Companies == undefined || vm.case.Companies.length == 0) {
                vm.case.Companies = undefined;
            };
            if (vm.case.Contacts == undefined || vm.case.Contacts.length == 0) {
                vm.case.Contacts = undefined;
            };
            
            // update status date if changing the status
            if (caseData.Status != vm.case.Status) {
                vm.case.StatusDate = new Date();
            };

            // reset/set locked in quote
            Enumerable.from(vm.case.Quotes).forEach(function (quote) {
                quote.IsLocked = false;
                if (vm.case.Status != 'Q' && vm.case.Status != 'A' && quote.IsSelected == true) {
                    quote.IsLocked = true;
                }
            });

            caseService.save(vm.case).then(function (result) {
                vm.case = result;
            });
        };

        /* @name show
         * @desc show Dialog for each grid
         */
        function show(dialogId) {
            annuitantService.setAnnuitant(undefined);
            beneficiaryService.setBeneficiary(undefined);
            contactGridService.setContact(undefined);
            LxDialogService.open(dialogId);
        };

        /* @name select
         * @desc select adjuster from autocomplete
        */
        function select(val) {
            if (!val) {
                vm.case.AdjusterId = undefined;
                vm.case.CompanyId = undefined;
                vm.adjuster = undefined;
                return;
            }
            vm.adjuster = val.originalObject;
            vm.case.AdjusterId = vm.adjuster.Id;
            vm.case.CompanyId = vm.adjuster.CompanyId;
        };
    };
})();