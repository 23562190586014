/**
* @name smartGridHeaderCell
* @desc Handles header click event, used in sorting.
**/
(function () {
    'use strict';

    angular
        .module('smart-grid')
        .directive('smartGridHeaderCell', smartGridHeaderCell);

    /**
    * @name smartGridHeaderCell.
    * @desc Handles header click event, used in sorting.
    */
    function smartGridHeaderCell() {
        var directive = {
            restrict: 'AC',
            require: '^smartGrid',
            link: postLink
        };

        return directive;

        function postLink($scope, $element, $attrs, $controller) {
            if ($scope.vm.sortable == false)
                return;

            $element.bind('click', function () {
                if (!$scope.column.sortable) return;
                $scope.$apply(function () {
                    $controller.sort($scope.column);
                });
            });
        };
    };
})();
