angular
    .module('CareGuard')
    .filter('positiveNumbers', () => {
        return (value) => {
            if (value) {
                const valueWithOneDot = value.toString().replace( /^([^.]*\.)(.*)$/, ( a, b, c ) => b + c.replace( /\./g, '' ));
                return valueWithOneDot.replace(/[^0-9.]/gi, "");
            } else {
                return 0;
            }
        }
    });