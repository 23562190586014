angular
    .module('CareGuard')
    .directive('closeDropdownOnClick', closeDropdownOnClick);

closeDropdownOnClick.$inject = [];

function closeDropdownOnClick() {
    return {
        restrict: 'A',
        link: function (scope, elm, attr) {
            $(elm).click(function(e){
                $(elm).closest(".dropdown-menu").prev().dropdown("toggle");
            })
        }
    }
}