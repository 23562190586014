/**
* @name companyGridController
* @desc company grid controller
**/
(function () {
    'use strict';

    angular
        .module('CareGuard')
        .controller('companyGridController', companyGridController)

    /** Inject services into controller. */
    companyGridController.$inject = ['LxDialogService', 'lookupService', 'companyGridService'];

    /**
    * @name companyGridController.
    * @desc company grid controller
    */
    function companyGridController(LxDialogService, lookupService, companyGridService) {

        // variables
        var vm = this;
        var dialogId = 'dlgCompanyModal';
        vm.companies = undefined;
        vm.roles = undefined;

        // Companies grid config
        var events = {
            add: show
        };

        vm.config = {
            selectionMode: 'none',
            rowNum: 15,
            filterable: false,
            dblClick: show,
            events: events
        };

        vm.columns = [
          {
              label: 'Company Role', key: 'Role', formatFunction: function (value, formatParameter) {
                  if (value && vm.roles) {
                      return Enumerable.from(vm.roles).where('$.Key == "' + value + '"').select('$.Value').firstOrDefault(undefined);
                  }
              }
          },
          { label: 'Company Name', key: 'Name' },
          { label: 'Claim Number', key: 'ClaimNumber' },
          { label: 'Policy Number', key: 'PolicyNumber' }
        ];

        init();

        /* 
        * @name init
        * @desc initialize module
        */
        function init() {
            vm.roles = lookupService.getByCategory('Company Role');
            vm.companies = companyGridService.getCompanies();
        };

        /* 
        * @name edit
        * @desc open company modal for adding/editing
        * @param {object} row - optional company row from the grid
        */
        function show(company) {
            companyGridService.setCompany(company);
            LxDialogService.open(dialogId);
        };
    };
})();