angular
    .module('CareGuard')
    .directive('autoexpandTextarea', autoexpandTextarea);

autoexpandTextarea.$inject = ['$timeout'];

function autoexpandTextarea($timeout) {
    return {
        restrict: 'A',
        require: 'ngModel',
        link: function($scope, $elm, $attrs, Ctrl) {
            const maxRows = $attrs.uiGridRowExpand ? (+$attrs.rows || 3) : 3;
            const borderWidthBias = 2;
            const textareaPaddingsBias = 8;

            function expand() {
                if (!$elm || !$elm[0]) return;

                const lineHeight = parseInt($elm.css('lineHeight'), 10);
                const lines = Math.floor($elm[0].scrollHeight / lineHeight);

                if ($attrs.uiGridRowExpand && !$elm[0].scrollHeight) {
                    $attrs.$set('rows', maxRows);

                } else if ($attrs.uiGridRowExpand) {
                    $elm[0].style.height = 'auto';
                    $elm[0].style.height = $elm[0].scrollHeight+'px';

                } else {
                    if (lines > maxRows) {
                        $attrs.$set('rows', lines);
                    } else {
                        $attrs.$set('rows', maxRows);
                    }
                }

                if ($attrs.uiGridRowExpand && $elm[0].scrollHeight) {
                    setRowHeightOnTextareaChanging();
                }
            }

            function getParentCellElm (elm) {
                if (!elm) return;

                const parentCellElm = elm.closest('.ui-grid-cell');

                return parentCellElm;
            }

            function getParentRowElm(cellElm) {
                if (!cellElm) return;

                const parentRowElm = cellElm.closest('.ui-grid-row');

                return parentRowElm;
            }

            function getCanvasElm(rowElm) {
                if (!rowElm) return;

                const canvasElm = rowElm.closest('.ui-grid-canvas');

                return canvasElm;
            }

            function setRowHeightOnTextareaChanging() {
                const parentCellElm = getParentCellElm($elm);
                const parentRowElm = getParentRowElm(parentCellElm);
                const canvasElm = getCanvasElm(parentRowElm);

                let uiGridCellElements = parentRowElm && parentRowElm[0].querySelectorAll('.ui-grid-cell');
                const textareaElmHeight = $elm[0].scrollHeight + textareaPaddingsBias;

                uiGridCellElements.forEach(cellElm => {
                    const cellContentElm = angular.element(cellElm.firstElementChild);

                    cellContentElm.css('height', textareaElmHeight + borderWidthBias + 'px');
                    angular.element(cellElm).css('height', textareaElmHeight + 2*borderWidthBias + 'px');
                });

                canvasElm.css('height', textareaElmHeight + 3*borderWidthBias + 'px');
                parentRowElm.css('height', textareaElmHeight + 3*borderWidthBias + 'px');
            }

            $scope.$watch($attrs.ngModel, (newValue, oldValue)=> {
                if (!newValue) return;

                if (oldValue && (newValue.length < oldValue.length)) {
                    $timeout(() => expand(), 0);
                } else {
                    return expand();
                }
            });

            if ($attrs.uiGridRowExpand ) {
                $elm.on('focus', () => expand());
            }
        }
    }
}