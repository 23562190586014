angular
    .module('CareGuard')
    .factory('annualAccountingService', annualAccountingService);

annualAccountingService.$inject = ['$http'];

function annualAccountingService($http) {
    const baseUrl = '/api/annualAccounting/';

    const service = {
        getAnnualAccountingEffectiveMonths: getAnnualAccountingEffectiveMonths,
        downloadAnnualAccounting: downloadAnnualAccounting,
        downloadCustomAnnualAccounting: downloadCustomAnnualAccounting
    };

    return service;

    function getAnnualAccountingEffectiveMonths() {
        return $http.get(baseUrl + '/getMonthlyAnnualAccounting');
    }

    function downloadAnnualAccounting(effectiveMonth) {
        return $http({
            url: `${baseUrl}/downloadAnnualAccounting?effectiveMonth=${effectiveMonth}`,
            method: 'GET',
            withCredentials: true,
            transformRequest: angular.identity,
            responseType: 'arraybuffer'
        });
    }

    function downloadCustomAnnualAccounting(memberId, startDate, endDate) {
        return $http({
            url: `${baseUrl}/downloadCustomAnnualAccounting?memberId=${memberId}&startDate=${startDate}&endDate=${endDate}`,
            method: 'GET',
            withCredentials: true,
            transformRequest: angular.identity,
            responseType: 'arraybuffer'
        });
    }


}