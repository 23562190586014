angular
    .module('CareGuard')
    .filter('phoneNumberParentheses', phoneNumberParentheses);

function phoneNumberParentheses() {
    return function (value) {
        if (!value) return;

        value = value.toString();

        if (value.length < 4) {
            return '(' + value + ')'
        } else if (value.length > 6 && value.length < 11) {
            return '(' + value.slice(0,3) + ')' + value.slice(3, value.length)
        } else if (value.length > 10) {
            return '(' + value.slice(0,3) + ')' + value.slice(3, 10)
        }
    };
}