/**
* @name globalContactDetailController
* @desc Global Contact Controller.
**/
(function () {
    'use strict';

    angular
        .module('CareGuard')
        .controller('globalContactDetailController', globalContactDetailController)

    /** Inject services into controller. */
    globalContactDetailController.$inject = ['LxDialogService', 'contactService', 'locationService', 'companyService'];

    /**
    * @name globalContactDetailController.
    * @desc Global Contact Controller.
    * @param {object} LxDialogService - modal service.
    * @param {object} contactService - contact service.
    * @param {object} locationService - location service.
    * @param {object} companyService - company service.
    */
    function globalContactDetailController(LxDialogService, contactService, locationService, companyService) {
        var vm = this;

        vm.contact = undefined;
        vm.contactCopy = undefined;
        vm.locations = undefined;
        vm.companies = undefined;
        vm.isEditing = false;
        vm.selectCompany = selectCompany;
        vm.selectLocation = selectLocation;
        vm.add = add;
        vm.edit = edit;
        vm.cancel = cancel;
        vm.save = save;
        vm.confirmRemove = confirmRemove;
        vm.remove = remove;

        init();

        /* 
        * @name init
        * @desc initialize module
        */
        function init() {
            update();            
        };

        /* 
        * @name update
        * @desc update selected contact.
        */
        function update(contact) {
            if (!contact) {
                contact = contactService.getSelected(update);
            };

            vm.contact = contact;
            vm.isEditing = false;
        };

        /* 
        * @name updateCompanies
        * @desc update autocomplete list for companies.
        */
        function updateCompanies() {
            companyService.getAll().then(function (result) {
                vm.companies = result;
            });
        };

        /* 
        * @name updateLocations
        * @desc update autocomplete list for locations.
        */
        function updateLocations() {
            if (!vm.contact || !vm.contact.CompanyId) {
                vm.locations = undefined;
                return;
            }

            // list of locations for autocomplete
            locationService.getByCompany(vm.contact.CompanyId).then(function (result) {
                vm.locations = result;
            });
        };

        /* 
        * @name selectCompany
        * @desc select company. 
        * @param {object} val - selected object.
        */
        function selectCompany(val) {
            if (!val) {
                vm.contact.CompanyId = undefined;
                vm.contact.LocationId = undefined;
                vm.contact.LocationName = undefined;
                vm.locations = undefined;
                return;
            }

            vm.contact.CompanyId = val.originalObject.Id;
            vm.contact.CompanyName = val.originalObject.Name;
            updateLocations();
        };

        /* 
        * @name selectLocation
        * @desc select location. 
        * @param {object} val - selected object.
        */
        function selectLocation(val) {
            if (!val) {
                vm.contact.LocationId = undefined;
                return;
            }

            vm.contact.LocationId = val.originalObject.Id;
            vm.contact.LocationName = val.originalObject.LocationName;
        };

        /* 
        * @name add
        * @desc show form to add new contact. 
        */
        function add(form) {
            form.setPristine();
            updateCompanies();

            vm.contactCopy = angular.copy(vm.contact, {});
            contactService.fireNewCallback({ Index: -1, IsNew: false });
            vm.contact = { IsNew: true, IsActive: true };
            vm.isEditing = true;
            vm.locations = undefined;
        };

        /* 
        * @name edit
        * @desc show form to edit existing contact. 
        */
        function edit(form) {
            form.setPristine();
            vm.contactCopy = angular.copy(vm.contact, {});
            vm.contact.IsNew = false;
            vm.isEditing = true;
        };

        /* 
        * @name cancel
        * @desc cancel add/update and hide the modal. 
        */
        function cancel() {
            contactService.fireNewCallback(vm.contactCopy);
        };

        /* 
        * @name save
        * @desc save (add/update) and hide the modal. 
        */
        function save() {
            if (!vm.contact) return;

            if (!vm.contact.CompanyId || !vm.contact.LocationId)
                return;

            // commit to db
            contactService.save(vm.contact).then(function (result) {
                result.IsNew = vm.contact.IsNew;
                result.Index = vm.contact.Index;
                contactService.fireNewCallback(result);
                update(result);
                vm.contact.Version = result.Version;
                contactService.setSelected(vm.contact);
            });
        };

        /* 
        * @name confirmRemove
        * @desc show delete confirmation dialog.
        */
        function confirmRemove() {
            LxDialogService.open('dlgConfirm');
        };

        /* 
        * @name remove
        * @desc remove contact.
        */
        function remove() {
            contactService.remove(vm.contact).then(function (result) {
                if (result == true) {
                    contactService.fireRemoveCallback(vm.contact);
                }
            });
        };
    };
})();