/**
* @name chronovoMax
* @desc if the value of field exceeds max then reset it to max
* @usage <input ng-model="field" chronovo-max="field" />
**/
(function () {
    'use strict';

    angular
        .module('CareGuard')
        .directive('chronovoMax', chronovoMax);

    /** Inject services into directive. */
    chronovoMax.$inject = ['$parse'];

    /**
    * @name chronovoMax
    * @desc if the value of field exceeds max then reset it to max
    */
    function chronovoMax($parse) {
        var directive = {
            restrict: 'A',
            require: '^ngModel',
            link: postLink
        };

        return directive;

        function postLink($scope, $element, $attrs, $controller) {

            // observe the value
            $scope.$watch($attrs.ngModel, function (newVal, oldVal) {
                if (newVal == undefined) return;
                var max = $parse($attrs.chronovoMax)($scope);
                if (parseFloat(newVal) > parseFloat(max)) {
                    $controller.$setViewValue(max);
                    $controller.$render();
                };
            });
        };
    };
})();