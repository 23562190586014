import isCurrentTemplate from './html/isCurrentTemplate.html';
import addressTypeTemplate from './html/addressTypeTemplate.html';
import contactStateTemplate from './html/contactStateTemplate.html';
import zipTemplate from './html/zipTemplate.html';
import deleteContactTemplate from './html/deleteContactTemplate.html';

export const contactColumnDefsGrid = [
    {
        field: 'IsCurrent',
        displayName: 'Current',
        headerCellClass: 'grid-component_header',
        cellClass: 'grid-component_checkbox',
        headerTooltip: false,
        cellTooltip: true,
        enableCellEdit: false,
        allowCellFocus: false,
        cellTemplate: isCurrentTemplate
    },
    {
        field: 'AddressTypeId',
        displayName: 'Address Type',
        cellClass: 'showExtendedContent',
        headerCellClass: 'grid-component_header',
        headerTooltip: false,
        cellTooltip: true,
        enableCellEdit: false,
        allowCellFocus: false,
        required: true,
        cellTemplate: addressTypeTemplate
    },
    {
        field: 'Address1',
        displayName: 'Address 1',
        headerCellClass: 'grid-component_header',
        headerTooltip: false,
        cellTooltip: true,
        required: true
    },
    {
        field: 'Address2',
        displayName: 'Address 2',
        headerCellClass: 'grid-component_header',
        headerTooltip: false,
        cellTooltip: true,
    },
    {
        field: 'City',
        displayName: 'City',
        headerCellClass: 'grid-component_header',
        headerTooltip: false,
        cellTooltip: true,
        required: true
    },
    {
        field: 'State',
        displayName: 'State',
        enableCellEdit: false,
        allowCellFocus: false,
        headerCellClass: 'grid-component_header',
        headerTooltip: false,
        cellTooltip: true,
        required: true,
        cellTemplate: contactStateTemplate
    },
    {
        field: 'Zip',
        displayName: 'Zip',
        headerCellClass: 'grid-component_header',
        enableCellEdit: true,
        headerTooltip: false,
        cellTooltip: true,
        editableCellTemplate: zipTemplate,
        required: true
    },
    {
        field: 'Actions',
        displayName: '',
        width: '3%',
        enableCellEdit: false,
        headerCellClass: 'grid-component_header grid-component_header_actions',
        cellClass: 'grid-component_actions',
        required: true,
        cellTemplate: deleteContactTemplate
    }
];