angular
    .module('CareGuard')
    .factory('addressService', addressService);

addressService.$inject = ['$http', '$toastr'];

function addressService($http, $toastr) {
    const baseRoute = `addresses`;

    function getAddressById(id) {
        return $http.get(`${apiUrl}/${baseRoute}/${id}`);
    }

    function getAddressesByPayeeId(id) {
        return $http.get(`${apiUrl}/${baseRoute}/payees/${id}`);
    }

    function addAddressToPayee(request) {
        return $http.post(`${apiUrl}/${baseRoute}/payees`, request);
    }

    function getAddressesByMemberId(id) {
        return $http.get(`${apiUrl}/${baseRoute}/members/${id}`);
    }

    function addAddressToMember(mapping) {
        return $http.post(`${apiUrl}/${baseRoute}/members`, mapping);
    }

    function updateAddress(address) {
        return $http.put(`${apiUrl}/${baseRoute}/${address.id}`, address);
    }

    function addAddress(address) {
        return $http.post(`${apiUrl}/${baseRoute}`, address);
    }

    function isValidAddress(address) {
        if (!address.address1 || address.address1.length < 6 || address.address1.length > 256) {
            $toastr.show(`Address1 is required. Must contain between 6 and 256 characters.`, `warning`);
            return false;
        }

        if (address.address2 && (address.address2.length < 2 || address.address2 > 256)) {
            $toastr.show(`Address2 must contain between 2 and 256 characters.`, `warning`);
            return false;
        }

        if (!address.city || address.city.length < 2 || address.city.length > 32 || !address.city.match(/^[a-zA-Z -.']+$/)) {
            $toastr.show(`City is required. Must contain between 2 and 32 letters.`, `warning`);
            return false;
        }

        if (!address.state || !address.state.match(/^[a-zA-Z]{2}$/)) {
            $toastr.show(`State is required. Must contain 2 letters.`, `warning`);
            return false;
        }

        if (!address.zip || !address.zip.match(/^\d{5}(?:-\d{4})?$/)) {
            $toastr.show(`Zip is required. Must match format 12345 or 12345-6789.`, `warning`);
            return false;
        }

        return true;
    }

    return {
        // Api calls
        getAddressById,
        getAddressesByPayeeId,
        addAddressToPayee,
        getAddressesByMemberId,
        addAddressToMember,
        updateAddress,
        addAddress,

        // Utilities
        isValidAddress,
    };
};