import ruleResultTriggeredTemplate from './html/Rules/ruleResultTriggeredTemplate.html';
import executionDateTemplate from './html/Rules/executionDateTemplate.html';

export const billReviewRuleResultsColumnDefs = [
    {
        field: 'Description',
        width: '1%',
        displayName: null,
        headerCellClass: 'grid-component_header',
        enableSorting: false
    },
    {
        field: 'RuleName',
        width: '25%',
        displayName: 'Rule',
        headerCellClass: 'grid-component_header'
    },
    {
        field: 'IsPassed',
        displayName: 'Result',
        width: '15%',
        headerCellClass: 'grid-component_header',
        cellTemplate: ruleResultTriggeredTemplate
    },
    {
        field: 'ExecutionDate',
        displayName: 'Execution Date',
        width: '15%',
        headerCellClass: 'grid-component_header',
        cellTemplate: executionDateTemplate
    },
    {
        field: 'UpdatedBy',
        displayName: 'Updated By',
        headerCellClass: 'grid-component_header',
        cellTemplate: executionDateTemplate
    },
    {
        field: 'Actions',
        displayName: '',
        width: '2%',
        headerCellClass: 'grid-component_header grid-component_header_actions',
        cellClass: 'grid-component_actions',
        enableSorting: false
    }
];