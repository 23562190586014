import providerTypeTemplate from './html/providers/providerTypeTemplate.html';
import npiTemplate from './html/providers/npiTemplate.html';
import stateTemplate from './html/providers/stateTemplate.html';
import zipTemplate from './html/providers/zipTemplate.html';
import deleteProviderTemplate from './html/providers/deleteProviderTemplate.html';

export const providersDefsGrid = [
    {
        field: 'providerType',
        displayName: 'Provider Type',
        width: '8%',
        headerCellClass: 'grid-component_header',
        enableCellEdit: false,
        allowCellFocus: false,
        enableCellEditOnFocus: false,
        cellTemplate: providerTypeTemplate,
        required: true
    },
    {
        field: 'npiNumber',
        displayName: 'Provider NPI',
        headerCellClass: 'grid-component_header',
        enableCellEdit: true,
        editableCellTemplate: npiTemplate,
        required: true
    },
    {
        field: 'firstName',
        displayName: 'First Name',
        headerCellClass: 'grid-component_header',
        enableCellEdit: true,
        required: true
    },
    {
        field: 'lastName',
        displayName: 'Last Name',
        headerCellClass: 'grid-component_header',
        enableCellEdit: true,
        width: '15%',
        required: true
    },
    {
        field: 'address1',
        displayName: 'Address 1',
        width: '15%',
        enableCellEdit: true,
        headerCellClass: 'grid-component_header',
        cellTooltip: true,
        required: true
    },
    {
        field: 'address2',
        displayName: 'Address 2',
        enableCellEdit: true,
        headerCellClass: 'grid-component_header',
        cellTooltip: true
    },
    {
        field: 'city',
        displayName: 'City',
        headerCellClass: 'grid-component_header',
        enableCellEdit: true,
        required: true
    },
    {
        field: 'state',
        displayName: 'State',
        enableCellEdit: false,
        headerCellClass: 'grid-component_header',
        allowCellFocus: false,
        width: '6%',
        required: true,
        cellTemplate: stateTemplate
    },
    {
        field: 'zip',
        displayName: 'Zip',
        headerCellClass: 'grid-component_header',
        enableCellEdit: true,
        headerTooltip: false,
        editableCellTemplate: zipTemplate,
        width: '6%',
        required: true
    },
    {
        field: 'Actions',
        displayName: '',
        width: '2%',
        enableCellEdit: false,
        headerCellClass: 'grid-component_header grid-component_header_actions',
        cellClass: 'grid-component_actions',
        cellTemplate: deleteProviderTemplate
    }
];