/**contact
* @name annualFundingController
* @desc Annual Funding Controller.
**/
(function () {
  'use strict';

  angular
    .module('CareGuard')
    .controller('annualFundingController', annualFundingController);

  /** Inject services into controller. */
  annualFundingController.$inject = ['$scope', '$location', '$state', '$timeout', '$toastr', 'lookupService', 'accountService', 'memberService', 'memberFlagsService', 'annualFundingService', 'memberID','utilService'];

  function annualFundingController($scope, $location, $state, $timeout, $toastr, lookupService, accountService, memberService, memberFlagsService, annualFundingService, memberID, utilService) {

    var vm = this;
    
    vm.annualfundings = undefined;
    vm.lookupdata = undefined;
    vm.member = undefined;
    vm.memberflags = undefined;    
    vm.AnnualFundingDate = undefined;
    
    // functions
    vm.getAnnualFunding = getAnnualFunding;
    vm.exportAnnualFunding = exportAnnualFunding;
    vm.saveAnnualFunding = saveAnnualFunding;
    vm.updateAnnualFunding = updateAnnualFunding;
    vm.changeSort = changeSort;
    vm.navigate = navigate;  
    vm.refresh = refresh;
    vm.pastDue = pastDue;
    vm.toggleOverrideReceivedFlag = toggleOverrideReceivedFlag;
    vm.toggleOverrideMissingFlag = toggleOverrideMissingFlag;

    vm.pageSizes = {
      data: [
          { Name: 5, Value: 5 },
          { Name: 10, Value: 10 },
          { Name: 20, Value: 20 },
          { Name: 50, Value: 50 },
          { Name: 100, Value: 100 },
          { Name: 1000, Value: 1000 }
      ]
    };
    
    vm.filterData = {
      TotalPages: 1,
      TotalRows: null,
      PageSize: 100,
      PageIndex: 1,
      SortColumn: null,
      SortOrder: 'ASC',
      MemberID: null,
      MemberNumber: null,
      MemberName: null,
      StartExpectedDate: null,
      EndExpectedDate: null,
      StartReceivedDate: null,
      EndReceivedDate: null,
      StartPostedDate: null,
      EndPostedDate: null,
      Note: null,
      OverrideReceivedFlag: null,
      OverrideMissingFlag: null
    };
    
    init();

    /* 
    * @name init
    * @desc initialize module
    */
    function init() {
      // Check if memberID was passed in; if so, get member
      if (memberID > 0) {
        memberService.getById(memberID).then((result) => {
            vm.member = result.Data;

            // This sets the default data for the member claims page
            vm.filterData.MemberID = vm.member.MemberID;
            vm.filterData.MemberNumber = vm.member.MemberNumber;
            vm.filterData.MemberName = vm.member.MemberName;

            getAnnualFunding();
        });

        memberFlagsService.getMemberFlags(memberID).then((result) => {
            vm.memberflags = result.Data;
        });
      } else {
        getAnnualFunding();
      }
    };

    // Get annual funding data
    function getAnnualFunding() {
        annualFundingService.getAnnualFunding(vm.filterData).then((result) => {
            vm.annualfundings = result;              

            try {
              if (vm.filterData.MemberID > 0) // Only one member
                vm.AnnualFundingDate = vm.annualfundings[0].ExpectedDate;
                vm.filterData.TotalRows = vm.annualfundings[0].TotalRows;
                vm.filterData.TotalPages = Math.ceil(vm.filterData.TotalRows / vm.filterData.PageSize);
            }
            catch (ex) {
              vm.filterData.TotalPages = 1;
        };
      });
    };

    // Export annual funding data to excel spreadsheet
    function exportAnnualFunding() {
        const exportOptions = {
            method: 'POST',
            url: "/api/annualfunding/exportAnnualFunding",
            data: vm.filterData
        };

        utilService.download(exportOptions);
    };

    // Save annual funding info
    function saveAnnualFunding() {
      annualFundingService.saveAnnualFunding(vm.annualfundings).then((result) => {
        getAnnualFunding();
      });      
    };

    // Update annual funding
    function updateAnnualFunding() {
        annualFundingService.updateAnnualFunding().then((result) => {
            $toastr.show('Annual Funding data updated', 'success');
            getAnnualFunding();
        });
    };

    // Sorts the columns
    function changeSort(column) {
      if (vm.filterData.SortColumn == column) {
        vm.filterData.SortOrder = (vm.filterData.SortOrder == 'ASC' ? 'DESC' : 'ASC')
      }
      else {
        vm.filterData.SortColumn = column;
        vm.filterData.SortOrder = 'ASC';
      }

      getAnnualFunding();
    };

    function navigate(state, params) {
      $timeout(() => {
        $state.go(state, params);
      }, 250);
    };

    // Refreshes or changes the page of data
    function refresh(page) {
      if (!page || (page > 0 && page <= vm.filterData.TotalPages)) {
        vm.filterData.PageIndex = vm.filterData.TotalRows == 0 || !page ? 1 : page;
        getAnnualFunding();
      }
    };

    // Determines if funding is past due
    function pastDue(annualfunding) {
      if (annualfunding.OverrideMissingFlag) return true;   // Show missing no matter what
      if (annualfunding.OverrideReceivedFlag) return false; // We dont want to show missing if override marked as received 

      const isTodayAfterDueDate = new Date() > new Date(annualfunding.ExpectedDate) ? true : false;

      return isTodayAfterDueDate;
    };

    function toggleOverrideReceivedFlag(annualfunding) {
      if (annualfunding.OverrideReceivedFlag == 1 && confirm("Are you sure you want to stop seeing this flag?"))
      {
        annualFundingService.toggleOverrideReceivedFlag(annualfunding).then((result) => {
          //refresh member flag(s)
          memberFlagsService.getMemberFlags(memberID).then((resultset) => {
            vm.memberflags = resultset.Data;
          });
        });
      }

      if (annualfunding.OverrideReceivedFlag == 0 && confirm("Are you sure you want to start seeing this flag again?")) {
        annualFundingService.toggleOverrideReceivedFlag(annualfunding).then((result) => {                 
          memberFlagsService.getMemberFlags(memberID).then((resultset) => {
            vm.memberflags = resultset.Data;
          });
        });
      }
    };

    function toggleOverrideMissingFlag(annualfunding) {
      if (annualfunding.OverrideMissingFlag == 1 && confirm("Are you sure you want to start seeing this flag?")) {
        annualFundingService.toggleOverrideMissingFlag(annualfunding).then((result) => {
          //refresh member flag(s)
          memberFlagsService.getMemberFlags(memberID).then((resultset) => {
            vm.memberflags = resultset.Data;
          });
        });
      }
            
      if (annualfunding.OverrideMissingFlag == 0 && confirm("Are you sure you want to stop seeing this flag again?")) {
        annualFundingService.toggleOverrideMissingFlag(annualfunding).then((result) => {
          memberFlagsService.getMemberFlags(memberID).then((resultset) => {
            vm.memberflags = resultset.Data;
          });
        });
      }
    };
  };
})();