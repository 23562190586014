angular
    .module('CareGuard')
    .filter('annualDate', annualDate);

function annualDate() {
    function convertAnnualDate(date) {
        return date && (date[0]+date[1]+'/'+date[2]+date[3]);
    }
    return function (value) {
        if (!value || value.length !== 4) return;

        const currentDate = new Date();
        const currentYear = currentDate.getFullYear();
        const momentJsDate = moment(convertAnnualDate(value) + '/' + currentYear.toString(), 'MM/DD/YYYY', true);

        return momentJsDate.format('MMM Do');
    };
}