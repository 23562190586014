/**
* @name format
* @desc used to format cell values.
**/
(function () {
    'use strict';

    angular
        .module('smart-grid')
        .filter('format', format);

    /** Inject services into directive. */
    format.$inject = ['$filter', 'TableConfig'];

    /**
    * @name format.
    * @desc used to format cell values.
    */
    function format($filter, tableConfig) {
        return returnFunction;
        
        function returnFunction(value, formatFunction, filterParameter) {
            var func;

            if (formatFunction && angular.isFunction(formatFunction)) {
                func = formatFunction;
            } else if (formatFunction && tableConfig.filters.indexOf(formatFunction) !== -1)
                func = $filter(formatFunction);
            else if (formatFunction && $filter(formatFunction)) {
                func = $filter(formatFunction);
            }
            else {
                func = function (value) {
                    return value;
                };
            }
            return func(value, filterParameter);
        };
    }
})();