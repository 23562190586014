/**
* @name externalAuthService
* @desc External Authentication Requests.
**/
(function () {
    'use strict';

    angular
        .module('CareGuard')
        .factory('externalAuthConfigService', externalAuthConfigService);

     /** Inject services into service. */
    externalAuthConfigService.$inject = ['$q'];

    /**
    * @name externalAuthService.
    * @desc Endpoint configuration for External Authentication Service.
    */
    function externalAuthConfigService($q) {
        this.getAuthConfig = function() {
            return $q.when(window.IdpConfig);
        };

        return this;
    }
})();