/**
* @name contactGridService
* @desc Contact Service.
**/
(function () {
    'use strict';

    angular
        .module('CareGuard')
        .factory('contactGridService', contactGridService);

    /**
    * @name contactgridService.
    * @desc Contact Service.
    */
    function contactGridService() {
        var contact = undefined;
        var contacts = undefined;
        var selectedCallback = undefined;
        var updateCallback = undefined;

        var service = {
            getContact: getContact,
            setContact: setContact,
            getContacts: getContacts,
            setContacts: setContacts,
            setUpdateCallback: setUpdateCallback,
            fireUpdateCallback: fireUpdateCallback
        };

        return service;


        /**
        * @name setCallback.
        * @desc set callback when record is added.
        * @param {function} func - call function when selected COntact is changed.
        */
        function setUpdateCallback(func) {
            updateCallback = func;
        };

        /**
        * @name fireCallback.
        * @desc fires callback when record is added.
        * @param {object} case - selected Contact.
        */
        function fireUpdateCallback(caseData) {
            if (updateCallback) {
                updateCallback(caseData);
            };
        };

        /**
        * @name getContact.
        * @desc return the Contact.
        */
        function getContact() {
            return contact;
        };

        /**
        * @name setContact.
        * @desc store the Contact.
        * @param {object} data - COntact to be stored.
        */
        function setContact(data) {
            contact = data;
        };

        /**
        * @name setContacts.
        * @desc store Contacts.
        * @param {array} data - Contacts to be stored.
        */
        function setContacts(data) {
            contacts = data;
        };

        /**
        * @name getContacts.
        * @desc get Contacts.
        */
        function getContacts() {
            return contacts;
        };
    };
})();