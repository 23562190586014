/**
* @name californiaFeeScheduleService
* @desc california Fee Schedule Service.
**/
angular
    .module('CareGuard')
    .factory('californiaFeeScheduleService', californiaFeeScheduleService);

californiaFeeScheduleService.$inject = ['$http'];

function californiaFeeScheduleService($http) {    
    var drugListBaseUrl = 'codes/capitalrx';
    var pricingCalculatorBaseUrl = 'capitalrx';

    /**
    * @name getDrugList.
    * @desc gets all drugs belongs to a name or NDC.
    */
    const getDrugList = function (ndcSearchTerm) {

        var isNdcCode = true;

        // Check to see if the user entered drug name or NDC code.
        if (ndcSearchTerm.match("[A-Za-z]+")) {
            isNdcCode = false;
        };

        // create request object and payload
        var drugSearchData = {
            // Include either code or description
            code: (isNdcCode ? ndcSearchTerm : undefined),
            description: (isNdcCode ? undefined : ndcSearchTerm)
        };

        // Call ndc pricing end point
        return $http.post(`${functionsUrl}/${drugListBaseUrl}/druglist`, drugSearchData);
    }

    /**
    * @name getDrugPricing.
    * @desc gets pricing of a drug.
    */
    const getDrugPricing = function (ndc, quantity) {
        var ndcPricingSearchData = {
            ndc: ndc,
            quantity: quantity
        };

        // Call ndc pricing end point
        return $http.post(`${functionsUrl}/${pricingCalculatorBaseUrl}/cafs/price`, ndcPricingSearchData);
    }

    return {
        // External API calls
        getDrugList,
        getDrugPricing,
    };
}