/**
* @name chronovoBusy
* @desc Shows a busy msg when a button is clicked.
* @usage <button chronovo-busy class="button" ng-click="isBusy = !isBusy" data-is-busy="isBusy" data-busy-label="Please wait">Busy Button</button>
**/
(function () {
    'use strict';

    angular
        .module('CareGuard')
        .directive('chronovoBusy', chronovoBusy);

    /**
    * @name chronovoBusy.
    * @desc Compare the input field's input to another to see if they match.
    */
    function chronovoBusy() {
        var directive = {
            restrict: 'A',
            scope: {
                isBusy: '=',
                busyLabel: '@',
                busyText: '@'
            },
            compile: compileFunc
        };

        return directive;

        function compileFunc($element, $attrs) {
            var config = {
                busy: 'is-busy'
            };

            var element = angular.element($element.find('span'));

            if (!element[0]) {
                $element.html('<span>' + $element.html() + '</span>');
                element = angular.element($element.find('span'));
            }

            return function postLink($scope, iElement) {
                var idleLabelHtml = iElement.html(),
                    busyLabelHtml = $scope.busyLabel || 'Please wait ...',
                    busyTextHtml = $scope.busyText;

                $scope.$watch('isBusy', function (value) {                    
                    if (value) {
                        iElement.addClass(config.busy);
                        iElement.html(busyLabelHtml);
                        iElement.attr('disabled', 'disabled');
                    } else {
                        iElement.removeClass(config.busy);
                        iElement.html(busyTextHtml || idleLabelHtml);
                        iElement.removeAttr('disabled');
                    }
                });

                $attrs.$observe('busyLabel', function (value) {
                    busyLabelHtml = value;
                });

                $attrs.$observe('busyText', function (value) {
                    busyTextHtml = value;
                });
            };
        }
    }
})();