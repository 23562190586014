/**
* @name memberController
* @desc member Controller.
**/
(function () {
    'use strict';

    angular
        .module('CareGuard')
        .controller('memberFlagsController', memberFlagsController);


    /** Inject services into controller. */
    memberFlagsController.$inject = ['$scope', '$location', '$state', '$timeout', 'accountService', 'memberService', 'memberFlagsService', 'lookupService', 'memberID', 'utilService', '$toastr'];

    /**
    * @name memberController.
    * @desc member Controller.
    * @param {object} searchService - search service.
    */
    function memberFlagsController($scope, $location, $state, $timeout, accountService, memberService, memberFlagsService, lookupService, memberID, utilService, $toastr) {
        var vm = this;
        vm.member = undefined;
        vm.memberflags = undefined;
        vm.newflag = undefined;
        vm.lookupdata = undefined;
        vm.hasResult = false;
        vm.Error = false;
        vm.hasRole = hasRole;
        //functions
        vm.addMemberFlag = addMemberFlag;
        vm.deleteMemberFlag = deleteMemberFlag;

        init();

        /* 
        * @name init
        * @desc initialize module
        */
        function init() {

            vm.lookupdata = {};

            if (memberID) {
                vm.hasResult = true;
                memberService.getById(memberID).then(function (result) {
                    vm.member = result.Data;
                });
            }
            else {
                vm.Error = true;
            }

            lookupService.getLookUp("accountstatus").then(function (resultset) {
                vm.lookupdata.accountstatus = resultset.Data;

            });

            loadMemberFlags(memberID);
        }

        function getFlagTypeNameFromId(flagTypeId) {
            return vm.lookupdata.flags?.find(flag => flag.flagTypeId === flagTypeId)?.flag;
        }

        function loadMemberFlags(memberId) {
            return memberFlagsService.getMemberFlags(memberId).then(({ Data: memberFlags, flagTypes }) => {
                vm.memberflags = memberFlags;
                vm.lookupdata.flags = flagTypes;
            });
        }

        function addMemberFlag() {
            if (!vm.newflag.Note?.trim()) {
                vm.newflag.Note = null;
            }

            if (!isValidFlag(vm.newflag)) {
                $toastr.show(`This note already exists for this member`, `warning`);
                return;
            }

            vm.newflag.MemberID = vm.member.MemberID;

            return memberFlagsService.addMemberFlag(vm.newflag).then(({ data: flag }) => {
                if (flag.rowId) {
                    vm.newflag = null;
                    flag.flag = getFlagTypeNameFromId(flag.flagId);
                    vm.memberflags.push(flag);
                }
                else {
                    $toastr.show('Error creating member flag', `error`);
                }
            }).catch(err => {
                $toastr.show(`${utilService.parseErrorMessage(err)}`, `error`);
            });
        }

        function deleteMemberFlag(flag) {
            if (confirm("Are you sure you want to delete Member Flag [" + flag.flag + "] ?")) {
                return memberFlagsService.deleteMemberFlag(flag.rowId).then(() => {
                    vm.memberflags = vm.memberflags?.filter(memberFlag => memberFlag.rowId !== flag.rowId);
                }).catch(err => {
                    $toastr.show(`${utilService.parseErrorMessage(err)}`, `error`);
                });
            }
        }

        function hasRole(role) {
            return accountService.isInRole(role);
        }

        function isValidFlag(newFlag) {
            return !vm.memberflags?.some(flag => flag.flagId == newFlag.FlagID && flag.note == newFlag.Note);
        }

    }
})();