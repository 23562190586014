angular
    .module('CareGuard')
    .controller('routenameController', routenameController);

routenameController.$inject = ['$state', '$location', '$rootScope', 'getUrlService'];

function routenameController($state, $location, $rootScope, getUrlService) {

    let vm = this;
    vm.getUrlFromState = getUrlFromState;
    vm.flag = undefined;
    vm.routeName = $state.current.title;
    vm.location = $location.path();
    $rootScope.$on('$stateChangeStart', function (event, toState, toParams, fromState, fromParams) {
        vm.routeName = toState.title;
        vm.dataTransfer = $location.path();
        vm.location = toState.url;
    });

    function getUrlFromState(stateName, params) {
        return getUrlService.get(stateName, params);
    }
}