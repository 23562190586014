angular
    .module('CareGuard')
    .controller('htmlToPdfController', htmlToPdfController);

htmlToPdfController.$inject = [
    '$toastr',
    'fileService',
    'htmlToPdfService',
    'utilService'];

function htmlToPdfController(
    $toastr,
    fileService,
    htmlToPdfService,
    utilService) {

    let vm = this;

    vm.file = null;
    vm.fileName = null;
    vm.generatePdf = generatePdf;
    vm.onFileChange = onFileChange;
    vm.clear = clear;


    function generatePdf() {
        if (!vm.file) {
            $toastr.show('Please, upload an HTML file', 'warning');
            return;
        }

        const formFile = fileService.createFileWithName(vm.file, vm.fileName);

        htmlToPdfService.generatePdf(formFile).then(response => {
            utilService.processResponse({ response });
        });
    }

    function onFileChange() {
        vm.fileName = vm.file.name;
    }

    function clear() {
        vm.file = null;
        vm.fileName = null;
    }
}
