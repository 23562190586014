angular
    .module('CareGuard')
    .controller('californiaFeeScheduleController', californiaFeeScheduleController);

californiaFeeScheduleController.$inject = [
    '$q',
    '$toastr',
    'californiaFeeScheduleService',
    'accountService',
    'utilService',
];

function californiaFeeScheduleController(
    $q,
    $toastr,
    californiaFeeScheduleService,
    accountService,
    utilService,
    ) {
    // variables
    var vm = this;
    vm.selectedNdc = undefined;
    vm.drugLookupBusy = false;
    vm.drugSearch = {
        ndcSearchTerm: undefined,
        quantity: undefined,
    };
    vm.drugSearchResults = {};
    var drugSearchResults = {
        ndc: undefined,
        drugName: undefined,
        quantity: undefined,
        dosage: undefined,
        dosageForm: undefined,
        price: undefined,
    };

    // functions
    vm.getDrugList = getDrugList;
    vm.getDrugPricing = getDrugPricing;
    vm.ndcItemSelected = ndcItemSelected;
    vm.resetFilters = resetFilters;

    function resetFilters() {
        drugSearchResults = {};
        vm.drugSearch = {};
        vm.drugSearchResults = {};
        vm.selectedNdc = null;
    }

    function ndcItemSelected(selected) {
        if (!selected || !Object.keys(selected).length) return;

        // Get the properties of the selected drug
        drugSearchResults.ndc = selected.originalObject.ndc;
        drugSearchResults.drugName = selected.originalObject.drugName;
        drugSearchResults.dosage = selected.originalObject.dosage;
        drugSearchResults.dosageForm = selected.originalObject.dosageForm;

        vm.selectedNdc = selected.originalObject.ndc;
    }

    function getDrugList(userInputString) {
        if (!userInputString || vm.selectedNdc) {           //invalid input or ndc already selected
            return $q.reject([]);
        }
        if (userInputString.match("^[0-9]{1,10}$")) {       //only digits but less than 11; allow user to finish typing ndc.
            return $q.when([]);
        }

        vm.drugLookupBusy = true;

        return californiaFeeScheduleService.getDrugList(userInputString)
            .catch(err => {
                $toastr.show(`Unable to fetch drug info. ${err.data}`, `error`);
                return [];
            }).finally(_ => {
                vm.drugLookupBusy = false;
                setTimeout(_ => $('#searchNDCDropdown_value').focus(), 250);
            });
    }
    function getDrugPricing() {
        if (!drugSearchResults.ndc ||
            !vm.drugSearch.quantity ||
            hasRole('CaliforniaFeeSchedule') == false) {
                $toastr.show(`Please input both NDC and Quantity`, `warning`);
                return;
        }

        return californiaFeeScheduleService.getDrugPricing(drugSearchResults.ndc, vm.drugSearch.quantity)
            .then(function (response) {
                // Retrieve the data returned from end point succesfully
                drugSearchResults.price = response.data.price;
                drugSearchResults.quantity = vm.drugSearch.quantity;

                // Update the model so that it can be displayed in the view.
                vm.drugSearchResults = drugSearchResults;
            }).catch(err => {
                $toastr.show(`Unable to fetch Drug pricing. ${err.data}`, `error`);
                console.log(utilService.parseErrorMessage(err), `error`);
                return;
            });
    }

    function hasRole(role) {
        return accountService.isInRole(role);
    }
}