/**
* @name chronovoEnter
* @desc Bind Enter event Directive.
* @usage <input chronovo-enter="func()"></button>
**/
(function () {
    'use strict';

    angular
        .module('CareGuard')
        .directive('chronovoEnter', chronovoEnter);

    /** Inject services into directive. */
    chronovoEnter.$inject = ['$parse'];

    /**
    * @name chronovoEnter.
    * @desc Bind Enter Event Directive.
    * @param {object} $parse - angularjs parse service.
    */
    function chronovoEnter($parse) {
        var directive = {
            restrict: "A",
            link: postLink
        };

        return directive;

        function postLink($scope, $element, $attrs, $controller) {
            var key = 'keydown keypress';
           
            var fn = $parse($attrs.chronovoEnter);
            $element.bind(key, function (event) {
                if (event.which === 13) {
                    if (fn) {
                        fn($scope);
                    } 
                    return false;
                }
            });
        }
    }
})();