export const reductionColumnDefs = [
    {
        field: 'LineNumber',
        displayName: 'Line Number',
        headerCellClass: 'grid-component_header showExtendedContent',
        headerTooltip: false,
        enableCellEdit: false,
        allowCellFocus: false //, cellTemplate: require('./html/lineNumberTemplate.html')
    },
    {
        field: 'ProcedureServiceCode',
        displayName: 'Procedure',
        headerCellClass: 'grid-component_header',
        cellFilter: "",
        required: false
    },
    {
        field: 'BilledAmount',
        displayName: 'Billed',
        headerCellClass: 'grid-component_header',
        cellFilter: "currency",
        required: false
    },
    {
        field: 'BillReviewAmount',
        displayName: 'Bill Review',
        headerCellClass: 'grid-component_header',
        cellFilter: "currency",
        required: false
    },
    {
        field: 'NetworkAmount',
        displayName: 'Network',
        headerCellClass: 'grid-component_header',
        cellFilter: "currency",
        required: false,
        editableCellTemplate: require('./html/networkTemplate.html')
    },
    {
        field: 'OutOfNetworkAmount',
        displayName: 'Audit Reduction',
        headerCellClass: 'grid-component_header',
        cellFilter: "currency",
        required: false,
        editableCellTemplate: require('./html/outOfNetworkTemplate.html')
    },
    {
        field: 'PayableAmount',
        displayName: 'Payable',
        headerCellClass: 'grid-component_header',
        cellFilter: "currency",
        required: false,
        editableCellTemplate: require('./html/payableTemplate.html')
    },
    {
        field: 'FeeAmount',
        displayName: 'Fee',
        headerCellClass: 'grid-component_header',
        cellFilter: "currency",
        required: false
    }
];