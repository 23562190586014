/**
* @name beneficiaryModalController
* @desc beneficiary controller.
**/
(function () {
    'use strict';

    angular
        .module('CareGuard')
        .controller('beneficiaryModalController', beneficiaryModalController);

    /** Inject services into controller. */
    beneficiaryModalController.$inject = ['$toastr', 'LxDialogService', 'beneficiaryService', 'lookupService', 'addressService'];

    /**
    * @name beneficiaryModalController.
    * @desc beneficiary Controller.
    */
    function beneficiaryModalController($toastr, LxDialogService, beneficiaryService, lookupService, addressService) {
        var vm = this;

        // variables
        var dialogId = 'dlgBeneficiaryModal';
        vm.isValidating = false;
        vm.isNew = true;
        vm.confirm = false;
        vm.beneficiary = undefined;
        vm.original = undefined;
        vm.lookupdata = undefined;

        // functions
        vm.validateAddress = validateAddress;
        vm.confirmAddress = confirmAddress;
        vm.goback = goback;
        vm.save = save;
        vm.remove = remove;

        init();

        /* 
        * @name init
        * @desc initilize module
        */
        function init() {
            vm.isValidating = false;
            vm.lookupdata = {
                states: lookupService.getByCategory('State'),
                annuitants: beneficiaryService.getAnnuitants()
            };

            vm.original = beneficiaryService.getBeneficiary();
            if (!vm.original) {
                vm.beneficiary = {};
                vm.isNew = true;
            }
            else {
                vm.beneficiary = angular.copy(vm.original, {});
                vm.isNew = false;
            };
        };

        /* 
        * @name validateAddress
        * @desc validate address against USPS
        */
        function validateAddress(beneficiaryModalForm) {
            if (vm.beneficiary.Address == undefined)
                $toastr.show('Please enter an address to validate.', 'error');
            else {
                beneficiaryModalForm.setAttempted(true)
                if (beneficiaryModalForm.$invalid) return;

                var promise = addressService.validate(vm.beneficiary.Address);
                if (promise && promise.then) {
                    promise.then(function (result) {
                        vm.isValidating = true;
                        vm.originalAddress = angular.copy(vm.beneficiary.Address, {});
                        vm.validatedAddress = angular.copy(result, {})
                        vm.choose = 'usps';
                    });
                };
            };
        };

        /* 
        * @name confirmAddress
        * @desc confirm and hide the modal. 
        */
        function confirmAddress() {
            if (vm.choose == 'usps') {
                vm.beneficiary.Address = vm.validatedAddress;
            }
            vm.isValidating = false;
        };

        /* 
        * @name goback
        * @desc change view back to beneficiary detail from address validation. 
        */
        function goback() {
            vm.isValidating = false;
        };

        /* 
        * @name show
        * @desc show address validation modal
        */
        function show(validatedAddress) {
            vm.originalAddress = angular.copy(vm.beneficiary.Address, {});
            vm.validatedAddress = angular.copy(validatedAddress, {})
            vm.choose = 'usps';
        };

        /* 
        * @name save
        * @desc update service with beneficiary and close dialog
        */
        function save() {
            if (!vm.original) {
                vm.original = {};
                var beneficiaries = beneficiaryService.getBeneficiaries();

                if (!beneficiaries) {
                    beneficiaries = [];
                }
                beneficiaries.push(vm.original);
            }
            vm.original.Name = vm.beneficiary.Name;
            vm.original.BirthDate = vm.beneficiary.BirthDate;
            vm.original.Age = vm.beneficiary.Age;
            vm.original.PrimaryPhone = vm.beneficiary.PrimaryPhone;
            vm.original.AlternativePhone = vm.beneficiary.AlternativePhone;
            vm.original.FaxNumber = vm.beneficiary.FaxNumber;
            vm.original.Address = vm.beneficiary.Address;
            vm.original.Relationship = vm.beneficiary.Relationship;
            vm.original.Email = vm.beneficiary.Email;
            vm.original.AnnuitantId = vm.beneficiary.AnnuitantId;
            vm.original.Percent = vm.beneficiary.Percent;

            beneficiaryService.fireUpdateCallback();
            LxDialogService.close(dialogId);
        };

        /* 
        * @name remove
        * @desc remove beneficiary from the case
        */
        function remove() {
            var beneficiaries = beneficiaryService.getBeneficiaries();
            for (var key in beneficiaries) {
                if (beneficiaries[key].Id == vm.beneficiary.Id) {
                    beneficiaries.splice(beneficiaries[key], 1);
                }
            };
            beneficiaryService.setBeneficiaries(beneficiaries);
            beneficiaryService.fireUpdateCallback();
            LxDialogService.close(dialogId);
        };
    };
})();