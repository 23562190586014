/**
* @name smartGridDataCell
* @desc formats the cell value.
**/
(function () {
    'use strict';

    angular
        .module('smart-grid')
        .directive('smartGridDataCell', smartGridDataCell);

    /** Inject services into directive. */
    smartGridDataCell.$inject = ['$filter', '$parse'];

    /**
    * @name smartGridDataCell.
    * @desc formats the cell value.
    */
    function smartGridDataCell($filter, $parse) {
        var directive = {
            restrict: 'AC',
            //require: '^smartGrid',
            link: postLink
        };

        return directive;

        function postLink($scope, $element, $attrs, $controller) {
            var column = $scope.column,
                row = $scope.dataRow,
                format = $filter('format'),
                getter = $parse(column.key);

            $scope.formatedValue = format(getter(row), column.formatFunction, column.formatParameter);
            
            $scope.$watch('column.cellTemplate', function (value) {
                if (typeof $scope.formatedValue === 'boolean') {
                    if ($scope.formatedValue)
                        $element.html('<i class="mdi mdi-checkbox-marked-circle mdi-4x text-success"></i>');
                }
                else if ($scope.formatedValue != undefined) {
                    $element.text($scope.formatedValue);
                };
            });
        }
    }
})();