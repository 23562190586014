/**
* @name smartGridPagination
* @desc handles pagination.
**/
(function () {
    'use strict';

    angular
        .module('smart-grid')
        .directive('smartGridPagination', smartGridPagination);

    /** Inject services into directive. */
    smartGridPagination.$inject = ['TableConfig', 'TemplateConfig'];

    /**
    * @name smartGridPagination.
    * @desc handles pagination.
    */
    function smartGridPagination(tableConfig, templateConfig) {
        var directive = {
            restrict: 'C',
            require: '^smartGrid',
            scope: true,
            templateUrl: templateConfig.paginationTemplate,
            replace: true,
            link: postLink
        };

        return directive;

        function postLink($scope, $element, $attrs, $controller) {
            var timer = null;
            var wait = false;

            $scope.$watch(['vm.currentPage', 'vm.totalRows'], function (newVal, oldVal) {
                build();
            });

            // Main build function
            function build() {
                // Block divide by 0 and empty page size
                if (!$scope.vm.rowNum || $scope.vm.rowNum < 0) {
                    return;
                }

                // Assign scope values
                setDefaults();

                // local variables
                var start,
                    size = $scope.vm.adjacent * 2,
                    pageCount = Math.ceil($scope.vm.totalRows / $scope.vm.rowNum);
                
                // Validate Scope
                validate(pageCount);

                // Calculate Counts and display
                addPrev(pageCount);
                if (pageCount < (5 + size)) {
                    start = 1;
                    addRange(start, pageCount);

                } else {
                    var finish;

                    if ($scope.vm.currentPage <= (1 + size)) {
                        start = 1;
                        finish = 2 + size + ($scope.vm.adjacent - 1);

                        addRange(start, finish);
                        addLast(pageCount);
                    } else if (pageCount - size > $scope.vm.currentPage && $scope.vm.currentPage > size) {
                        start = $scope.vm.currentPage - $scope.vm.adjacent;
                        finish = $scope.vm.currentPage + $scope.vm.adjacent;

                        addFirst();
                        addRange(start, finish);
                        addLast(pageCount);
                    } else {
                        start = pageCount - (1 + size + ($scope.vm.adjacent - 1));
                        finish = pageCount;

                        addFirst();
                        addRange(start, finish);
                    }
                }
                addNext(pageCount);
            };
            
            // Assign null-able scope values from settings
            function setDefaults() {
                $scope.vm.List = [];
                $scope.vm.currentPage = parseInt($scope.vm.currentPage) || 1;
                $scope.vm.totalRows = parseInt($scope.vm.totalRows) || 0;
                $scope.vm.dots = $scope.vm.dots || '...';
                $scope.vm.adjacent = parseInt($scope.vm.adjacent) || 2;
                $scope.vm.disabledClass = $scope.vm.disabledClass || 'disabled';
                $scope.vm.iconBack = 'fa fa-backward';
                $scope.vm.iconBegin = 'fa fa-fast-backward';
                $scope.vm.iconForward = 'fa fa-forward';
                $scope.vm.iconEnd = 'fa fa-fast-forward';

                $scope.vm.rowNumList = angular.isDefined($scope.vm.rowNumList) ? $scope.vm.rowNumList : tableConfig.rowNumList;
                $scope.vm.rowNum = angular.isDefined($scope.vm.rowNum) ? $scope.vm.rowNum : tableConfig.rowNum;
            };
                        
            // Validate and clean up any scope values
            // This happens after we have set the
            // scope values
            function validate(pageCount) {

                // Block where the page is larger than the pageCount
                if ($scope.vm.currentPage > pageCount) {
                    $scope.vm.currentPage = pageCount;
                }

                // Block where the page is less than 0
                if ($scope.vm.currentPage <= 0) {
                    $scope.vm.currentPage = 1;
                }

                // Block where adjacent value is 0 or below
                if ($scope.vm.adjacent <= 0) {
                    $scope.vm.adjacent = 2;
                }
            };

            // Internal Paging Click Action
            function click(page) {
                // Block clicks we try to load the active page
                if ($scope.vm.currentPage == page) {
                    return;
                }

                // Update the page in scope and fire any paging actions
                $scope.vm.currentPage = page;
                $controller.changePage(parseInt($scope.vm.currentPage), $scope.vm.rowNum);
            };

            // Add Range of Numbers
            function addRange(start, finish) {
                var i = 0;
                for (i = start; i <= finish; i++) {

                    var item = {
                        value: i,
                        itemClass: 'page',
                        title: 'Page ' + i,
                        activeClass: $scope.currentPage == i ? $scope.activeClass : '',
                        action: function () {
                            click(this.value);
                        }
                    };

                    $scope.vm.List.push(item);
                }
            };

            // Add Dots ie: 1 2 [...] 10 11 12 [...] 56 57
            function addDots() {
                $scope.vm.List.push({
                    value: $scope.vm.dots
                });
            };

            // Add First Pages
            function addFirst() {
                addRange(1, 2);
                addDots();
            };

            // Add Last Pages
            function addLast(pageCount) {
                addDots();
                addRange($scope.vm.pageCount - 1, pageCount);
            };

            // Adds the first, previous text if desired   
            function addPrev(pageCount) {

                // Ignore if there are no pages to display
                if (pageCount < 1) {
                    return;
                }

                // Calculate the previous page and if the click actions are allowed
                // blocking and disabling where page <= 0
                var disabled = $scope.vm.currentPage - 1 <= 0;
                var prevPage = $scope.vm.currentPage - 1 <= 0 ? 1 : $scope.vm.currentPage - 1;

                var first = {
                    value: '',
                    itemClass: $scope.vm.iconBegin,
                    title: 'First Page',
                    liClass: disabled ? $scope.vm.disabledClass : '',
                    action: function () {
                        if (!disabled) {
                            click(1);
                        }
                    }
                };

                var prev = {
                    value: '',
                    itemClass: $scope.vm.iconBack,
                    title: 'Previous Page',
                    liClass: disabled ? $scope.vm.disabledClass : '',
                    action: function () {
                        if (!disabled) {
                            click(prevPage);
                        }
                    }
                };

                $scope.vm.List.push(first);
                $scope.vm.List.push(prev);
            };

            // Adds the next, last text if desired
            function addNext(pageCount) {

                // Ignore if there are no pages to display
                if (pageCount < 1) {
                    return;
                }

                // Calculate the next page number and if the click actions are allowed
                // blocking where page is >= pageCount
                var disabled = $scope.vm.currentPage + 1 > pageCount;
                var nextPage = $scope.vm.currentPage + 1 >= pageCount ? pageCount : $scope.vm.currentPage + 1;

                var last = {
                    value: '',
                    itemClass: $scope.vm.iconEnd,
                    title: 'Last Page',
                    liClass: disabled ? $scope.vm.disabledClass : '',
                    action: function () {
                        if (!disabled) {
                            click(pageCount);
                        }
                    }
                };

                var next = {
                    value: '',
                    itemClass: $scope.vm.iconForward,
                    title: 'Next Page',
                    liClass: disabled ? $scope.vm.disabledClass : '',
                    action: function () {
                        if (!disabled) {
                            internalAction(nextPage);
                        }
                    }
                };

                $scope.vm.List.push(next);
                $scope.vm.List.push(last);
            };            
        }
    }
})();