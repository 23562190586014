angular
    .module('CareGuard')
    .constant('formFields', {
        '2pointOwnerInd': '2nd Point Owner Individual',
        AddressType: 'Address Type',
        CMSApproved: 'CMS Approved',
        CMSSubmissionDate: 'CMS Submission Date',
        DMEAllocation: 'DME Allocation',
        HHCAllocation: 'HHC Allocation',
        IsCurrent: 'Is Current',
        MSADate: 'MSA Date',
        PBMAnnualFee: 'PBM Annual Fee',
        RxAllocation: 'Rx Allocation',
        accountStatus: 'Account Status',
        accountType: 'Account Type',
        adjuster: 'Adjuster',
        adminFeesReceivedDate: 'Admin Fees Received Date',
        allFourDate: 'All Four Date',
        allocationSubtype: 'Allocation Subtype',
        allocationType: 'Allocation Type',
        annualFeePaymentDate: 'Annual Fee Payment Date',
        annualFundingDate: 'Annual Funding Date',
        approvalLetterDate: 'Approval Letter Date',
        available: 'Available',
        bankBalance: 'Bank Balance',
        beneficiaryType: 'Beneficiary Type',
        benefitsEntered: 'Benefits Entered',
        billAmount: 'Bill Amount',
        caseLostReason: 'Case Lost Reason',
        caseSize: 'Case Size',
        channelSourceCompany: 'Channel Source Company',
        channelSourceEmail: 'Channel Source Email',
        channelSourceInd: 'Channel Source Ind.',
        channelSourceState: 'Channel Source State',
        channelSourceType: 'Channel Source Type',
        claimNumber: 'Claim Number',
        claimPrefix: 'Claim Prefix',
        claimType: 'Claim Type',
        currentAge: 'Current Age',
        dateOfBirth: 'Date Of Birth',
        dateOfDeath: 'Date Of Death',
        dateOfInjury: 'Date Of Injury',
        dme: 'DME',
        effectiveDate: 'Effective Date',
        email: 'Email',
        emailStatus: 'Email Status',
        employer: 'Employer',
        feeAmount: 'Fee Amount',
        feeType: 'Fee Type',
        finalYearFee: 'Final Year Fee',
        finalYearFunding: 'Final Year Funding',
        firstFundingDate: 'First Annuity Date',
        firstName: 'First Name',
        fullyFundedDate: 'Fully Funded Date',
        fundAmount: 'Fund Amount',
        fundingDate: 'Funding Date',
        fundingFrequency: 'Funding Frequency',
        fundsExhaustedDate: 'Funds Exhausted Date',
        futureMemberDate: 'Future Member Date',
        gender: 'Gender',
        group: 'Group',
        hicn: 'HICN',
        holdAmount: 'Hold Amount',
        homePhone: 'Home Phone',
        jurisdictionState: "Jurisdiction State",
        lastName: 'Last Name',
        leadType: 'Lead Type',
        lifeExpectancy: 'Life Expectancy',
        lifeInsuranceCompany: 'Life Insurance Company',
        lostCaseDate: 'Lost Case Date',
        mailOrder: 'Mail Order',
        medicareNotificationDate: 'Medicare Notification Date',
        memberContactDate: 'Member Contact Date',
        memberDate: 'Member Date',
        memberNeedsReview: 'Member Needs Review',
        memberNotificationDate: 'Member Notification Date',
        memberNumber: 'Member ID',
        memberVM: 'Voice Mail',
        middleInitial: 'Middle Initial',
        mobilePhone: 'Mobile Phone',
        msaFundingReceivedDate: 'Funding Received Date',
        msaVendor: 'MSA Vendor',
        nextAction: 'Next Action',
        notSettlingReasons: 'Not Settling Reasons',
        onBoardingCompleteDate: 'Onboarding Complete',
        onHoldDate: 'On Hold Date',
        paperless: 'Paperless',
        payor: 'Payor',
        payorAnnualFee: 'Payor Annual Fee',
        payorBranch: 'Payor Branch',
        pbm: 'PBM',
        pharmacyContactedDate: 'Pharmacy Contacted Date',
        plaintiffAttorneyCompany: 'Plaintiff Attorney Company',
        plaintiffAttorneyEmail: 'Plaintiff Attorney Email',
        plaintiffAttorneyInd: 'Plaintiff Attorney Ind.',
        pointOwnerCompany: 'Point Owner Company',
        pointOwnerInd: 'Point Owner Ind.',
        pointOwnerIndividual2Email: '2nd Point Owner Email',
        preSettlementServices: 'Pre-Settlement Services',
        preferredContactNumber: 'Preferred Contact Number',
        pricingMechanism: 'Pricing Mechanism',
        providerContactDate: 'Provider Contact Date',
        providerVM: 'Voice Mail',
        providerNotTreating: 'Not Treating',
        pharmacyNotTreating: 'Not Treating',
        ratedAge: 'Rated Age',
        referralDate: 'Referral Date',
        secondPointOwnerCompany: '2nd Point Owner Company',
        seedAmount: 'Seed Amount',
        sendMarketingMaterials: 'Send Marketing Materials',
        sendStatements: 'Send Statements',
        settlementDate: 'Settlement Date',
        settlementDocumentsReceived: 'Settlement Documents Received',
        settlementType: 'Settlement Type',
        signedAgreementReceivedDate: 'Signed Agreement Received Date',
        spokenLanguage: 'Spoken Language',
        ssn: 'SSN/ITIN',
        startupFee: 'Startup Fee',
        state: 'State',
        structureBrokerCompany: 'Structure Broker Company',
        structureBrokerEmail: 'Structure Broker Email',
        structureBrokerInd: 'Structure Broker Ind.',
        structuredFeeType: 'Structured Fee Type',
        structuredSettlementType: 'Structured Settlement Type',
        submittedToCMS: 'Submitted To CMS',
        terminationDate: 'Termination Date',
        title: 'Title',
        totalFundAmount: 'Total Fund Amount',
        tpa: 'TPA',
        treatmentAllocation: 'Treatment Allocation',
        trustCompany: 'Trust Company',
        trustName: 'Trust Name',
        trustTaxID: 'Trust Tax ID',
        trustee: 'Trustee',
        welcomePackageDate: 'Welcome Package Date',
        workPhone: 'Work Phone'
    });