angular
    .module('CareGuard')
    .controller('stopPaymentRequestsController', stopPaymentRequestsController);

stopPaymentRequestsController.$inject = [
    '$q',
    'claimService',
    '$timeout',
    'lookupService',
    'memberID',
    'successFunc',
    'memberService',
    '$state',
    'accountService',
    '$toastr'];

function stopPaymentRequestsController(
    $q,
    claimService,
    $timeout,
    lookupService,
    memberID,
    successFunc,
    memberService,
    $state,
    accountService,
    $toastr) {

    let vm = this;

    vm.member = undefined;
    vm.lookupdata = undefined;
    vm.OtherReasonOption = undefined;
    vm.stopPaymentRequests = {};
    vm.paymentsSearch = {
        SortColumn: 'IssueDate',
        SortOrder: 'ASC'
    };

    vm.getRequests = getRequests;
    vm.changeSort = changeSort;
    vm.approveRequests = approveRequests;
    vm.sendApprovalEmail = sendApprovalEmail;
    vm.deleteRequest = deleteRequest;
    vm.markSubmitted = markSubmitted;
    vm.toggleCheckAll = toggleCheckAll;
    vm.navigate = navigate;
    vm.hasRole = hasRole;

    function init() {
        const promise = [];

        vm.lookupdata = {
            cancelreason: lookupService.getLookUp("cancelReason")
        };

        promise.push(lookupService.getLookUp("cancelreason"), getRequests());

        $q.all(promise).then(result => {
            vm.lookupdata.cancelReason = result[0].Data;
        });
    }

    init();

    function getRequests() {

        const promise = [];

        if (memberID > 0) {
            promise.push(
                memberService.getById(memberID),
                claimService.stopPaymentRequests_Get(null, memberID, null)
            );

        } else {
            promise.push(claimService.stopPaymentRequests_Get(null, null, null));
        }

        $q.all(promise).then(result => {

            if (memberID) {
                vm.member = result[0].Data;
                vm.stopPaymentRequests = result[1].Data;
            } else {
                vm.stopPaymentRequests = result[0].Data;
            }

            sortPayments();
        });
    }

    function changeSort(column) {
        if (vm.paymentsSearch.SortColumn === column) {
            vm.paymentsSearch.SortOrder = (vm.paymentsSearch.SortOrder === 'ASC' ? 'DESC' : 'ASC')
        } else {
            vm.paymentsSearch.SortColumn = column;
            vm.paymentsSearch.SortOrder = 'ASC';
        }

        sortPayments();
    }

    function sortPayments() {
        vm.stopPaymentRequests.sort(function (a, b) {
            let firstValue = b;
            let secondValue = a;

            if(vm.paymentsSearch.SortOrder === 'DESC') {
                firstValue = a;
                secondValue = b;
            }

            let firstInitialValue, secondInitialValue;

            if (vm.paymentsSearch.SortColumn === 'CheckNumber') {
                firstInitialValue = +firstValue[vm.paymentsSearch.SortColumn];
            } else if (typeof firstValue[vm.paymentsSearch.SortColumn] === 'string') {
                firstInitialValue = firstValue[vm.paymentsSearch.SortColumn].toLowerCase();
            } else {
                firstInitialValue = firstValue[vm.paymentsSearch.SortColumn];
            }

            if (vm.paymentsSearch.SortColumn === 'CheckNumber') {
                secondInitialValue = +secondValue[vm.paymentsSearch.SortColumn];
            } else if (typeof secondValue[vm.paymentsSearch.SortColumn] === 'string') {
                secondInitialValue = secondValue[vm.paymentsSearch.SortColumn].toLowerCase();
            } else {
                secondInitialValue = secondValue[vm.paymentsSearch.SortColumn];
            }

            if (!firstInitialValue || firstInitialValue < secondInitialValue) {
                return -1;
            }
            if (firstInitialValue > secondInitialValue) {
                return 1;
            }

            return 0;
        });
    }

    function approveRequests() {
        if (hasRole('StopPaymentRequestsApproval')) {
            var approvalRequestList = [];
            var approvalRequestIDList = [];

            for (var i = 0; i < vm.stopPaymentRequests.length; i++) {
                if (vm.stopPaymentRequests[i].Checked && vm.stopPaymentRequests[i].ApprovedDate == null) {
                    approvalRequestList.push(vm.stopPaymentRequests[i]);
                    approvalRequestIDList.push(vm.stopPaymentRequests[i].StopPaymentRequestID);
                }
            }

            if (approvalRequestIDList.length > 0) {
                claimService.stopPaymentRequests_ApproveList(approvalRequestIDList).then(function (result) {
                    sendApprovalEmail(approvalRequestList);

                    getRequests();
                });
            } else {
                $toastr.show("No Valid Requests selected!");
            }
        }
    }

    function sendApprovalEmail(sprIDList) {
        if (sprIDList.length > 0)
            claimService.stopPaymentRequests_SendApprovalEmail(sprIDList).then(function (result) {});
    }

    function deleteRequest(spr) {
        if (spr.StopPaymentRequestID > 0) {

            claimService.stopPaymentRequests_Delete(spr.StopPaymentRequestID).then(function (result) {
                getRequests();
            });
        }
    }

    function markSubmitted(spr) {
        if (spr.StopPaymentRequestID > 0) {
            claimService.stopPaymentRequests_Submit(spr.StopPaymentRequestID).then(function (result) {
                getRequests();
            });
        }
    }

    function toggleCheckAll() {
        for (var i = 0; i < vm.stopPaymentRequests.length; i++) {
            if (vm.stopPaymentRequests[i].ApprovedDate != null) {
                vm.stopPaymentRequests[i].Checked = false;
            } else {
                vm.stopPaymentRequests[i].Checked = vm.checkedAll;
            }
        }
    }

    function navigate(state, params) {
        $timeout( () => $state.go(state, params), 0);
    }

    function hasRole(roleName) {
        return accountService.isInRole(roleName);
    }
}