/**
* @name smartGridDataRow
* @desc Handles row click event, used for selection.
**/
(function () {
    'use strict';

    angular
        .module('smart-grid')
        .directive('smartGridDataRow', smartGridDataRow);
    
    /**
    * @name smartGridDataRow.
    * @desc Handles row click event, used for selection.
    */
    function smartGridDataRow() {
        var directive = {
            restrict: 'AC',
            require: '^smartGrid',
            link: postLink
        };

        return directive;

        function postLink($scope, $element, $attrs, $controller) {
            $element.bind('dblclick', function () {
                $scope.$apply(function () {
                    if ($controller.dblClick) {
                        $controller.dblClick($scope.dataRow);
                    }
                });
            });

            $element.bind('click', function () {
                $scope.$apply(function () {
                    $controller.toggleSelection($scope.dataRow);
                });
            });
        }
    }
})();



