angular
    .module('CareGuard')
    .directive('sidebarNav', sidebarNav);

sidebarNav.$inject = ['$location', '$state', 'memberService', '$q'];

function sidebarNav($location, $state, memberService, $q) {
    return {
        restrict: 'A',
        replace: true,
        transclude: true,
        controller: ['$scope', '$state', 'memberService', 'bankingService', ctrl],
        controllerAs: 'vm',
        templateUrl: 'app/careguard/views/layouts/sidebar.html',
        link: postLink,
        isSidebarShown: true
    };

    function ctrl($scope, $state, memberService, bankingService) {
        let vm = this;
        vm.member = {};
        vm.memberBalances = {};
        vm.getMember = getMember;
        vm.refreshMember = refreshMember;

        let accountTypes = [];
        let allocationTypes = [];
        let accountStatuses = [];

        function getMember(memberID) {
            if (!memberID) return;

            let promises = [
                memberService.getMemberById(memberID),
                memberService.getAllocationTypes(),
                memberService.getAccountTypes(),
                bankingService.getCurrentBalanceTotalsByMemberIds({ ids: [memberID], fields: `memberId,totalBankBalance,balanceDate,holdAmount,totalAvailableBalance,billAmount` }),
                bankingService.getExternalAccounts(memberID),
                memberService.getBeneficiariesByMemberId(memberID),
                memberService.getAccountStatuses()
            ];

            return Promise.all(promises).then(([
                { data: memberInfo },
                { data: allocations },
                { data: allAccountTypes },
                { data: [balances] },
                { data: externalAccounts },
                { data: memberBeneficiaries },
                { data: statuses }
            ]) => {
                vm.member = memberInfo;

                allocationTypes = allocations;
                accountTypes = allAccountTypes;
                accountStatuses = statuses;
                setBeneficiaryType(memberBeneficiaries.beneficiaries, vm.member);
                mapAllocationTypeField(allocationTypes, vm.member);
                mapAccountType(accountTypes, vm.member);
                setAccountBalances(balances, vm.member);
                vm.member.numberOfRadiusAccounts = externalAccounts?.externalBankAccounts?.length || 0;
                return $q.when(memberService.setMember(vm.member));
            });
        }

        function setAccountBalances(balances, member) {
            if (!balances) {
                member.currentBalances.totalBankBalance = 0.00;
                member.currentBalances.totalAvailableBalance = 0.00;
                member.currentBalances.billAmount = 0.00;
                member.currentBalances.holdAmount = 0.00;
            } else {
                member.currentBalances = balances;
            } 
            
        }

        function setBeneficiaryType(beneficiaries, member) {
            if (!beneficiaries) {
                return;
            }

            beneficiaries = _.sortBy(beneficiaries, (beneficiary) => -beneficiary.beneficiaryType);
            member.beneficiaryType = beneficiaries[0].beneficiaryType ?? '';
        }

        function mapAllocationTypeField(allocationTypes, member) {
            //Needed until all pages use new getMember api call
            const memberAllocationTypeId = getAllocationTypeFromModel(member);

            member.allocationType = allocationTypes.find(type => type.id == memberAllocationTypeId)?.type;
        }

        function mapAccountType(accountTypes, member) {
            const accountTypeId = getAccountTypeFromModel(member);
            const accountType = accountTypes.find(type => type.id == accountTypeId)?.accountType;

            if (accountType) //needed until account type for amethyst members is fixed
                member.accountType = accountType;
        }

        function getAllocationTypeFromModel(member) {
            return member.allocationTypeId ?? member.AllocationTypeId;
        }

        function getAccountTypeFromModel(member) {
            return member.accountTypeId ?? member.AccountTypeID;
        }

        function getAvailableBalanceFromModel(member) {
            return member.currentBalances?.totalAvailableBalance ?? member.BalanceAmount;
        }

        function getHoldAmountFromModel(member) {
            return member.currentBalances?.holdAmount ?? member.HoldAmount;
        }

        function getAccountStatusIdFromModel(member) {
            return member.accountStatusId ?? member.AccountStatusID;
        }

        function getPreferredNumberFromModel(member) {
            return member.preferredContactNumber ?? member.PreferredContactNumber;
        }

        function getFundingDateFromModel(member) {
            return member.annualFundingDate ?? member.AnnualFundingDate;
        }

        function refreshMember(memberData) {
            if (!allocationTypes.length || !accountTypes.length || !accountStatuses.length) {
                vm.member = memberData;
            } else {
                //needed until all of member page is refactored to use apis completely
                const newAccountTypeId = getAccountTypeFromModel(memberData);
                const oldAccountTypeId = getAccountTypeFromModel(vm.member);

                if (newAccountTypeId != oldAccountTypeId) {
                    vm.member.accountTypeId = newAccountTypeId;
                    mapAccountType(accountTypes, vm.member);
                }

                const newAllocationTypeId = getAllocationTypeFromModel(memberData);
                const oldAllocationTypeId = getAllocationTypeFromModel(vm.member);

                if (newAllocationTypeId != oldAllocationTypeId) {
                    vm.member.allocationTypeId = newAllocationTypeId;
                    mapAllocationTypeField(allocationTypes, vm.member);
                }

                const newAvailableBalance = getAvailableBalanceFromModel(memberData);
                if (getAvailableBalanceFromModel(vm.member) !== newAvailableBalance) {
                    vm.member.currentBalances.totalAvailableBalance = newAvailableBalance;
                    vm.member.currentBalances.holdAmount = getHoldAmountFromModel(memberData);
                    vm.member.BalanceAmount = null;
                    vm.member.HoldAmount = null;
                }

                const newAccountStatusId = getAccountStatusIdFromModel(memberData);
                if (getAccountStatusIdFromModel(vm.member) !== newAccountStatusId) {
                    vm.member.accountStatusId = newAccountStatusId;
                    vm.member.accountStatus = accountStatuses.find(status => status.id == newAccountStatusId)?.status;
                }

                const newPreferredNumber = getPreferredNumberFromModel(memberData);
                if (getPreferredNumberFromModel(vm.member) !== newPreferredNumber)
                    vm.member.preferredContactNumber = newPreferredNumber;

                const newAnnualFundingDate = getFundingDateFromModel(memberData); 
                if (getFundingDateFromModel(vm.member) !== newAnnualFundingDate)
                    vm.member.annualFundingDate = newAnnualFundingDate;
            }
        }
    }

    function postLink($scope, $element, $attrs, ctrl) {
        $scope.$watch(() => $location.path(), (newValue, oldValue) => {
            $('li[data-match-route]', $element).each(function (k, li) {
                const $i = $('i', li),
                    $li = angular.element(li),
                    pattern = $li.attr('data-match-route'),
                    regexp = new RegExp('/' + pattern + '/', ['i']);

                if (regexp.test(newValue)) {
                    $li.addClass('active');
                    $scope[pattern + 'RouteActive'] = true;

                } else {
                    $li.removeClass('active');
                    $scope[pattern + 'RouteActive'] = false;
                }
            });
        });

        $scope.$watch(() => $state.params && $state.params.id, (newValue, oldValue) => {
            return ctrl.getMember(newValue);
        });

        $scope.$watchCollection(() => memberService.getMember(), (newValue, oldValue) => {
            if (!angular.equals({}, newValue)) {
                return ctrl.refreshMember(newValue)
            }
        });


        $(window).on('resize', calcSideBarHeight);
        $(document).ready(calcSideBarHeight);

        function calcSideBarHeight() {
            const screenWidth = $(window).width();
            if (screenWidth < 1210) {
                const mainContentElement =  $('[data-selector="main-content-wrapper"]');
                const mainContentElementHeight = mainContentElement.height();
                $element.height(mainContentElementHeight);
            } else {
                $element.css("height", '100%');
            }
        }
    }
}