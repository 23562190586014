/**
* @name userAccountController
* @desc user account Controller.
**/
(function () {
    'use strict';

    angular
        .module('CareGuard')
        .controller('userAccountController', userAccountController)

    /** Inject services into controller. */
    userAccountController.$inject = ['$timeout', 'questionInfo', 'accountService'];

    /**
    * @name userAccountController.
    * @desc user account Controller.
    * @param {object} $timeout - angular timer service.
    * @param {object} accountService - account service.
    */
    function userAccountController($timeout, questionInfo, accountService) {
        var vm = this;
        var form = undefined;
        vm.questions = questionInfo;
        vm.isBusy = false
        vm.user = {
            Password: undefined,
            OldPassword: undefined,
            ConfirmPassword: undefined
        };
        vm.updatePassword = updatePassword;
        vm.updateQuestions = updateQuestions;
        
        /**
        * @name update.
        * @desc update user password.
        */
        function updatePassword(resetForm) {
            vm.isBusy = true;
            vm.showMessage = false;
            form = resetForm;

            var data = {
                Password: vm.user.Password,
                OldPassword: vm.user.OldPassword,
                ConfirmPassword: vm.user.ConfirmPassword
            };

            accountService.updatePassword(data)
                          .then(successFunc, errorFunc);
        };

        /**
        * @name udpateQuestions.
        * @desc update security question/answers.
        */
        function updateQuestions() {
            vm.isBusy = true;
            vm.showMessage = false;

            var questionList = [{ Id: vm.user.Question1.Id, AnswerText: vm.user.Answer1 }, { Id: vm.user.Question2.Id, AnswerText: vm.user.Answer2 }];

            accountService.updateSecurityQuestions(questionList)
                          .then(successFunc, errorFunc);
        };

        /**
        * @name successFunc.
        * @desc success handler.
        */
        function successFunc() {
            if (form) {
                form.setPristine();
            };
            vm.isBusy = false;
            vm.user = {
                Password: undefined,
                OldPassword: undefined,
                ConfirmPassword: undefined
            };
        };

        /**
        * @name errorFunc.
        * @desc error handler.
        */
        function errorFunc() {
            vm.isBusy = false;
        };
    };
})();