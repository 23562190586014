angular
    .module("CareGuard")
    .controller("homepageController", homepageController);

homepageController.$inject = [
    '$state',
    '$timeout',
    '$toastr',
    'claimService'
];

function homepageController(
    $state,
    $timeout,
    $toastr,
    claimService) {

    let vm = this;

    vm.isInProgress = false;

    vm.claim = [];

    vm.navigate = navigate;
    vm.onClaimSubmit = onClaimSubmit;

    function onClaimSubmit(claimId) {
        if (!claimId || !claimId.match(/^[0-9]+$/)) {
            $toastr.show('No claim id entered for search.', 'warning');
            return;
        }

        vm.isInProgress = true;
        claimService.searchClaims({ claimIDs: [claimId] }).then(({ data: { items } }) => {
            if (!items?.length) {
                $toastr.show('No claim found with this id.', 'warning');
                return;
            }
            vm.navigate('layoutNoNav.claim-details', { 'claimValue': claimId });
        }).finally(_ => {
            vm.isInProgress = false;
        });
    }

    function navigate(state, params) {
        $timeout(function () {
            $state.go(state, params);
        }, 0);
    }
}