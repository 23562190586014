angular
    .module('CareGuard')
    .directive('preventClosingOnClick', preventClosingOnClick);

preventClosingOnClick.$inject = [];

function preventClosingOnClick() {
    return {
        restrict: 'A',
        link: function (scope, elm, attr) {
            $(elm).click(function(e){
                e.stopPropagation();
            })
        }
    }
}