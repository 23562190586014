angular
    .module('CareGuard')
    .directive('tp2StickyHeader', tp2StickyHeader);

tp2StickyHeader.$inject = [];

function tp2StickyHeader() {
    return {
        restrict: 'A',
        link: function ($scope) {
            if (!$scope.colContainer || !$scope.colContainer.grid.appScope.vm.gridOptionsClaims) return;

            function settingGridMaxHeight () {
                const windowHeight = window.innerHeight|| document.documentElement.clientHeight;
                const navBar = document.querySelector('.navbar-carehub');
                const navHeight = navBar.offsetHeight || navBar.clientHeight;

                const grid = document.querySelector('.grid-component2');

                if ($scope.colContainer.grid.appScope.vm.gridOptionsClaims.data.length
                                > $scope.colContainer.grid.appScope.vm.gridOptionsClaims.minRowsToShow) {
                    angular.element(grid).css('height', windowHeight - navHeight + 'px');
                } else {
                    const rowHeight = $scope.colContainer.grid.appScope.vm.gridOptionsClaims.rowHeight;
                    const minRowsToShow = $scope.colContainer.grid.appScope.vm.gridOptionsClaims.minRowsToShow;
                    const biasForHeaderAndPagination = 60;
                    angular.element(grid).css('height', minRowsToShow * rowHeight + biasForHeaderAndPagination + 'px');
                }
            }

            $scope.$watch('colContainer.grid.appScope.vm.gridOptionsClaims.data',  (newVal, oldVal) => {
                if (newVal && newVal.length) {
                    settingGridMaxHeight();
                }
            });
        }
    }
}