import React from 'react';
import { createRoot } from 'react-dom/client';
import { ResponsiveCalendar } from '@nivo/calendar';
import './_calendar.scss';

angular
    .module('CareGuard')
    .directive('calendar', ['utilService', (utilService) => {
        return {
            restrict: 'E',
            scope: {
                events: '=',
                from: '=',
                to: '=',
                totalYears: '='
            },
            template: '<div id="react-calendar-app"></div>',
            link: function (scope) {
                const root = createRoot(document.getElementById('react-calendar-app'));

                scope.$watch('events', (newValue) => {
                    if (angular.isDefined(newValue) && scope.totalYears > 0) {
                        const totalYearHeight = 270 + 250 * (scope.totalYears - 1);

                        root.render(
                            <div flex-item="flex-item" style={{ height: totalYearHeight, width: `100%` }}>
                                <ResponsiveCalendar
                                    data={newValue}
                                    from={scope.from}
                                    to={scope.to}
                                    minValue={100}
                                    maxValue={2100}
                                    emptyColor="#eeeeee"
                                    colors={['#fc2403', '#fc6703', '#fceb03', '#97e3d5', '#a503fc']}
                                    margin={{ top: 40, right: 40, bottom: 50, left: 40 }}
                                    monthSpacing={10}
                                    monthBorderColor="#ffffff"
                                    dayBorderWidth={2}
                                    dayBorderColor="#ffffff"
                                    tooltip={(event) => {
                                        const fullEvent = newValue?.find(ev => ev.day == event.day && ev.value == event.value);
                                        return <div className="calendar-tooltip">
                                            {fullEvent.type && <h4>{utilService.splitWordByCapitalLetters(fullEvent.type)}</h4>}
                                            <div>{formatDate(event.day)} {utilService.splitWordByCapitalLetters(fullEvent.bankName)}</div>
                                        </div>
                                    }}
                                />
                            </div>
                        );
                    }
                }, true);
            }
        };
    }]);

function formatDate(date) {
    return moment(date).format('M/D/YYYY');
}