/**
* @name annualFundingService
* @desc Annual Funding Service.
**/
(function () {
  'use strict';

  angular
      .module('CareGuard')
      .factory('annualFundingService', annualFundingService);

  /** Inject services into service. */
  annualFundingService.$inject = ['$http'];

  function annualFundingService($http) {

    const baseUrl = '/api/annualfunding';

    const getAnnualFunding = (vmAnnualFunding) => {
        return $http.post(`${baseUrl}/getAnnualFunding`, vmAnnualFunding);
    };
    
    const saveAnnualFunding = (vmAnnualFunding) => {
        return $http.post(`${baseUrl}/saveAnnualFunding`, vmAnnualFunding);
    };

    const updateAnnualFunding = () => {
        return $http.get(`${baseUrl}/updateAnnualFunding`);
    };

    const toggleOverrideReceivedFlag = (vmAnnualFunding) => {
        return $http.post(`${baseUrl}/toggleOverrideReceivedFlag`, vmAnnualFunding);
    };

    const toggleOverrideMissingFlag = (vmAnnualFunding) => {
        return $http.post(`${baseUrl}/toggleOverrideMissingFlag`, vmAnnualFunding);
    };

    return {
        getAnnualFunding,
        saveAnnualFunding,
        updateAnnualFunding,
        toggleOverrideReceivedFlag,
        toggleOverrideMissingFlag
    };
  };
})();