/**
* @name chronovoAge
* @desc calculates age from today
* @usage <input ng-model="datefield" chronovo-age />
**/
(function () {
    'use strict';

    angular
        .module('CareGuard')
        .directive('chronovoAge', chronovoAge);

    /** Inject services into directive. */
    chronovoAge.$inject = ['$timeout'];

    /**
    * @name chronovoAge
    * @desc calculates age from today
    */
    function chronovoAge($timeout) {
        var directive = {
            restrict: 'A',
            require: '^ngModel',
            priority: 1000,
            link: postLink
        };

        return directive;

        function postLink($scope, $element, $attrs, $controller) {

            /*
            * @name watch
            * @desc observe the options
            */
            $scope.$watch($attrs.chronovoAge, function (options) {
                if (!options) return;

                if (options.type == 'age') {
                    caculateAge(options);
                } else if (options.type == 'date') {
                    caculateDate(options);
                }
            });

            /*
            * @name caculateAge
            * @desc calculate age based on today or asofdate
            */
            function caculateAge(options) {
                if (!options.birthdate) return;

                // watch changes in birthdate, so age can be updated
                $scope.$watch(options.birthdate, function (newVal) {
                    if (!newVal) return;

                    var value = Date.parse(newVal);
                    if (!value) return;
                    
                    var birthdate = new Date(value);
                    var asofdate = options.asofdate ? new Date($scope.$eval(options.asofdate)) : new Date();
                    var age = calculate(birthdate, asofdate);
                    render(age);
                });

                // watch changes in birthdate, so age can be updated
                $scope.$watch(options.asofdate, function (newVal) {
                    if (!newVal) return;

                    var value = Date.parse(newVal);
                    if (!value) return;

                    var birthdate = options.birthdate ? new Date($scope.$eval(options.birthdate)) : new Date();
                    var asofdate = new Date(value);
                    var age = calculate(birthdate, asofdate);
                    render(age);
                });
            };

            /*
            * @name caculateDate
            * @desc calculate date based on age and date of birth
            */
            function caculateDate(options) {
                if (!options.birthdate || !options.age) return;

                // watch changes in age and update the date
                $scope.$watch(options.age, function (newVal) {
                    if (!newVal) return;

                    var age = $scope.$eval(newVal);
                    var birthdate = new Date($scope.$eval(options.birthdate));
                    if (!age || !birthdate) return;

                    var start = moment(birthdate).add(age, 'year').format("MM/DD/YYYY");
                    render(start);
                });
            };

            /*
            * @name render
            * @desc set controller view value
            */
            function render(val) {
                $controller.$setViewValue(val);
                $controller.$render();
            };
            
            /*
            * @name calculate
            * @desc calculate age
            */
            function calculate(birthdate, asofdate) {
                if (!asofdate) {
                    asofdate = new Date();
                }

                var asofYear = asofdate.getUTCFullYear(),
                    asofMonth = asofdate.getUTCMonth(),
                    asofDay = asofdate.getUTCDate()
                ;

                var birthYear = birthdate.getUTCFullYear(),
                    birthMonth = birthdate.getUTCMonth(),
                    birthDay = birthdate.getUTCDate();

                var age = asofYear % birthYear;

                // do not update age unless it is birthday
                if (asofMonth < birthMonth && asofDay > birthDay ||
                    asofMonth < birthMonth && asofDay < birthDay ||
                    asofMonth < birthMonth && asofDay === birthDay ||
                    asofMonth == birthMonth && asofDay < birthDay) {
                    age -= 1;
                }
                return age;
            };
        };
    };
})();