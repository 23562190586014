/**
* @name contactGridController
* @desc Contacts grid controller.
**/
(function () {
    'use strict';

    angular
        .module('CareGuard')
        .controller('contactGridController', contactGridController)

    /** Inject services into controller. */
    contactGridController.$inject = ['LxDialogService', 'lookupService', 'contactGridService', 'contactService'];

    /**
    * @name contactGridController.
    * @desc Contacts grid controller.   
    */
    function contactGridController(LxDialogService, lookupService, contactGridService, contactService) {

        // variables
        var vm = this;
        var dialogId = 'dlgContactModal';
        vm.contacts = undefined;
        vm.roles = undefined;

        // Contact grid config
        var events = {
            add: show
        };

        vm.config = {
            selectionMode: 'none',
            rowNum: 15,
            filterable: false,
            dblClick: show,
            events: events
        };

        vm.columns = [
            {
                label: 'Contact Role', key: 'Role', formatFunction: function (value, formatParameter) {
                    if (value && vm.roles) {
                        return Enumerable.from(vm.roles).where('$.Key == "' + value + '"').select('$.Value').firstOrDefault(undefined);
                    }
                }
            },
            { label: 'Contact Name', key: 'ContactName' },
            { label: 'Location', key: 'FullLocationName' },
            { label: 'Email', key: 'Email' },
            { label: 'Primary Phone', key: 'PrimaryPhone' },
            { label: 'Referral Source', key: 'ReferralSource' }
        ];

        init();

        /* 
        * @name init
        * @desc initialize module
        */
        function init() {
            vm.roles = lookupService.getByCategory('Contact Role');
            vm.contacts = contactGridService.getContacts();
        };

        /* 
        * @name edit
        * @desc open Contact modal for adding/editing
        * @param {object} row - optional Contact row from the grid
        */
        function show(contact) {
            contactGridService.setContacts(vm.contacts);
            contactGridService.setContact(contact);
            LxDialogService.open(dialogId);
        };
    };
})();