const selectAll = '<input type="checkbox" ng-model="grid.appScope.vm.areAllRowsSelected" ng-change="grid.appScope.vm.onChangeSelectAllRows()">';
const selectRow = '<input type="checkbox" ng-model="row.entity.Selected" ng-change="grid.appScope.vm.checkRowsSelectionAndSumTotalAmount()">';

export const checkboxesColumn = {
    field: 'SelectClaim',
    displayName: 'Member Number',
    headerCellTemplate: selectAll,
    cellTemplate: selectRow,
    headerCellClass: 'alignedCheckbox',
    cellClass: 'alignedCheckbox',
    width: '2%'
};