/**
* @name smartGrid
* @desc smart-grid directive.
**/
(function () {
    'use strict';

    angular
        .module('smart-grid')
        .directive('smartGrid', smartGrid);

    /** Inject services into directive. */
    smartGrid.$inject = ['$timeout', 'ColumnConfig', 'TemplateConfig', 'TableConfig'];

    /**
    * @name smartGrid.
    * @desc smart-grid directive.
    */
    function smartGrid($timeout, columnConfig, templateConfig, tableConfig) {
        var directive = {
            restrict: 'AEC',
            scope: {
                columnCollection: '=columns',
                dataCollection: '=rows',
                config: '='
            },
            replace: true,
            templateUrl: templateConfig.tableTemplate,
            controller: 'smartGridController',
            controllerAs: 'vm',
            link: postLink
        };

        return directive;

        function postLink($scope, $element, $attrs, $controller) {
            $scope.vm.config = angular.extend({}, tableConfig, $scope.config);
            $scope.vm.config.events = angular.isDefined($scope.vm.config.events) ? $scope.vm.config.events : tableConfig.events;
            $scope.vm.dataCollection = $scope.dataCollection;

            // refresh grid, called from parent $scope/$controller.
            $scope.vm.config.events.refresh = function () {
                $controller.sort();
            };

            // remove item from grid
            $scope.vm.config.events.remove = function (attr, value) {
                $controller.remove(attr, value);
            };

            // add item from grid
            $scope.vm.config.events.save = function (attr, value, row) {
                $controller.save(attr, value, row);
            };

            // add resizeable handlers
            if ($scope.vm.config.resizeable) {
                $timeout(function () {
                    $element.find('table').resizableColumns({ store: undefined, selector: 'tr th:visible.smart-table-header-cell' });
                }, 200);
            };
                        
            // handle config change
            $scope.$watch('config', function (config) {
                var newConfig = angular.extend({}, columnConfig, config),
                    length = $scope.vm.columns !== undefined ? $scope.vm.columns.length : 0;

                $controller.setGlobalConfig(newConfig);

                //remove the checkbox column if needed
                if (newConfig.selectionMode !== 'multiple' || newConfig.displaySelectionCheckbox !== true) {
                    for (var i = length - 1; i >= 0; i--) {
                        if ($scope.vm.columns[i].isSelectionColumn === true) {
                            $controller.removeColumn(i);
                        }
                    }
                } else {                    
                    //add selection box column if required
                    var options = {
                        cellTemplate: templateConfig.checkboxTemplate,
                        headerTemplate: templateConfig.selectAllTemplate,
                        isSelectionColumn: true,
                        filterable: false,
                        sortable: false
                    };

                    $controller.insertColumn(options, 0);
                }
            }, true);

            // handle column change, adds/removes columns to the table
            $scope.$watch('columnCollection', function (newValue, oldValue) {
                $controller.removeAll();
                for (var i = 0, l = $scope.columnCollection.length; i < l; i++) {
                    var column = $scope.columnCollection[i];
                    $controller.insertColumn(column);
                }
                $controller.sort();
            }, true);

            // handle row change, and update grid with new data
            $scope.$watch('dataCollection', function (rows) {
                $controller.update(rows);
            }, true);

            // When the destroy event is triggered, check to see if the above
            // data is still available.
            $element.on('$destroy', function () {
                $scope.vm = undefined;
            });
        };
    };
})();


