import documentNameTemplate from './html/sharepointDocuments/documentNameTemplate.html';

export const sharepointDocsDefsGrid = [
    {
        field: 'FileName',
        displayName: 'Document Name',
        cellTooltip: true,
        headerCellClass: 'grid-component_header',
        cellTemplate: documentNameTemplate,
        enableSorting: true
    },
    {
        field: 'DocumentType',
        displayName: 'DocumentType',
        cellTooltip: true,
        headerCellClass: 'grid-component_header'
    },
    {
        field: 'SourceType',
        displayName: 'Source Type',
        headerCellClass: 'grid-component_header'
    },
    {
        field: 'CreatedOn',
        displayName: 'Created Date',
        cellFilter: "date:\'MM/dd/yyyy\'",
        headerCellClass: 'grid-component_header',
        enableSorting: true
    },
    {
        field: 'SourceActivityType',
        displayName: 'Source Activity Type',
        headerCellClass: 'grid-component_header'
    }
];