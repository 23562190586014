/**
* @name documentDetailController
* @desc document Controller.
**/
(function () {
    'use strict';

    angular
        .module('CareGuard')
        .controller('documentDetailController', documentDetailController)

    /** Inject services into controller. */
    documentDetailController.$inject = ['LxDialogService', 'documentService', 'lookupService', 'utilService', 'FileUploader'];

    /**
    * @name documentDetailController.
    * @desc document Controller.
    * @param {object} LxDialogService - modal service.
    * @param {object} documentService - document service.
    */
    function documentDetailController(LxDialogService, documentService, lookupService, utilService, FileUploader) {
        var vm = this;
        var dialogId = 'dlgAddDocument';
        var dialogConfirmId = 'dlgConfirm';

        // variables
        vm.uploader;
        vm.document = undefined;
        vm.documenttypes = undefined;
        vm.documentCopy = undefined;
        vm.isEditing = false;

        // functions
        vm.edit = edit;
        vm.download = download;
        vm.cancel = cancel;
        vm.save = save;
        vm.confirm = confirm;
        vm.remove = remove;

        init();

        /* 
        * @name init
        * @desc initialize module
        */
        function init() {
            vm.documentTypes = lookupService.getByCategory('Document Type');
            update();
            updateFile();
        };

        /* 
        * @name updateFile
        * @desc update selected File.
        */
        function updateFile(uploader) {
            if (!uploader) {
                uploader = documentService.getSelectedFile(updateFile);
            };
            vm.uploader = uploader;
        };

        /* 
        * @name update
        * @desc update selected document.
        */
        function update(document) {
            if (!document) {
                document = documentService.getSelected(update);
            };
            vm.document = document;
            vm.isEditing = false;
        };

        /* 
        * @name edit
        * @desc show form to edit existing document. 
        */
        function edit() {
            vm.documentCopy = angular.copy(vm.document, {});
            vm.document.IsNew = false;
            vm.isEditing = true;
        };

        /* 
        * @name cancel
        * @desc cancel add/update and hide the modal. 
        */
        function cancel() {                    
            update(vm.documentCopy);
            documentService.fireNewCallback(vm.documentCopy);
        };

        /* 
        * @name save
        * @desc save (add/update) and hide the modal. 
        */
        function save() {
            if (!vm.document) return;

            // commit to db          
            documentService.save(vm.document).then(function (result) {
                var lookup = Enumerable.from(vm.documentTypes).where('$.Key == "' + result.Type + '"').firstOrDefault(undefined);
                result.TypeName = lookup != undefined ? lookup.Value : '';
                result.IsNew = vm.document.IsNew;
                result.Index = vm.document.Index;
                vm.document.Version = result.Version;
                update(result);
                documentService.fireNewCallback(result);
                documentService.setSelected(vm.document);
            });
        };

        /* 
        * @name confirmRemove
        * @desc show delete confirmation dialog.
        */
        function confirm() {
            LxDialogService.open(dialogConfirmId);
        };

        /* 
        * @name remove
        * @desc remove document.
        */
        function remove() {
            documentService.remove(vm.document).then(function (result) {
                if (result == true) {
                    documentService.fireRemoveCallback(vm.document);
                    LxDialogService.close(dialogConfirmId);
                }
            });
        };

        /* 
         * @name download
         * @desc download document
         */
        function download() {
            if (!vm.document) return;

            var options = {
                method: 'GET',
                url: '/api/documents/Download/' + vm.document.Id
            };
            utilService.download(options);
        };
    };
})();