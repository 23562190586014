/**
* @name userTeamController
* @desc Team Controller.
**/
(function () {
    'use strict';

    angular
        .module('CareGuard')
        .controller('userTeamController', userTeamController)

    /** Inject services into controller. */
    userTeamController.$inject = ['userService', 'teamService', 'LxDialogService', '$state'];

    /**
    * @name userTeamController.
    * @desc Team Controller.
    * @param {object} userService - user service.
    * @param {object} teamService - team service.
    */
    function userTeamController(userService, teamService, LxDialogService, $state) {
        var vm = this;

        // variables
        vm.userTeams = {};
        vm.teamList = undefined;
        vm.team = undefined;
        vm.user = undefined;
        vm.teams = {
            selected: undefined,
            searchBy: undefined,
            pageSize: 10,
            items: []
        };
        vm.mode = undefined;
        var dialogId = 'dlgManageTeams';

        // functions
        vm.assign = assign;
        vm.unassign = unassign;
        vm.manage = manage;
        vm.cancel = cancel;
        vm.get = getTeams;
        vm.add = add;
        vm.edit = edit;
        vm.save = save;
        vm.remove = remove;

        init();

        /* 
        * @name init
        * @desc initialize module, display teams for a user.
        */
        function init() {
            vm.mode = 'assign';
            vm.user = userService.getSelected();
            getTeams();
            getUserTeams();
        };

        /* 
        * @name getTeams
        * @desc update list of teams availabe in the dropdown.
        */
        function getTeams() {
            if (!vm.user) return;
            teamService.get(vm.user.CompanyId).then(function (result) {
                vm.teamList = result;
            });
        };

        /* 
        * @name getUserTeams
        * @desc update list of teams for the user.
        */
        function getUserTeams() {
            if (!vm.user) return;
            userService.getTeams(vm.user.Id).then(function (result) {
                result = Enumerable.from(result).orderBy('$.TeamName').toArray();
                vm.userTeams = result;
            });
        };

        /* 
        * @name assign
        * @desc assign team to a user.
        */
        function assign() {
            if (!vm.team) return;
            var exists = Enumerable.from(vm.userTeams).where("$.Id == '" + vm.team.Id + "'").firstOrDefault(undefined);
            if (exists)
                return;

            var teamName = vm.team.TeamName;

            var data = {
                Id: vm.user.Id,
                Models: { 'CompanyId': vm.user.CompanyId, 'Team': vm.team.Id },
                TeamName: teamName,
                Version: vm.user.Version
            };

            userService.assignTeam(data).then(function (result) {
                vm.userTeams.unshift(data);
                Enumerable.from(result).orderBy('$.TeamName').toArray();
                vm.user.Version = result.Version;
                userService.setSelected(vm.user);
            });
        };

        /* 
        * @name unassign
        * @desc remove user from a team. 
        */
        function unassign(index, team) {
            var data = {
                Id: vm.user.Id,
                Models: { 'CompanyId': vm.user.CompanyId, 'Team': team.Id },
                Version: vm.user.Version
            };
            userService.unassignTeam(data).then(function (result) {
                vm.userTeams.splice(index, 1);
                vm.user.Version = result.Version;
                userService.setSelected(vm.user);
            });
        };

        /* 
        * @name manage
        * @desc show all teams modal. 
        */
        function manage() {
            vm.mode = 'list';
            refresh();
            LxDialogService.open(dialogId);
        };

        /* 
        * @name refresh
        * @desc refresh all teams. 
        */
        function refresh() {
            if (!vm.user) return;
            // refresh results from db
            teamService.get(vm.user.CompanyId).then(function (result) {
                vm.teams.items = result;
            });
        };

        /* 
        * @name cancel
        * @desc cancel mange team, revert back to user team view. 
        */
        function cancel() {
            vm.mode = 'assign';
            getTeams();
            getUserTeams();
            LxDialogService.close(dialogId);
        }

        /* 
        * @name add
        * @desc adds new team. 
        */
        function add() {
            vm.mode = 'new';
            vm.teams.selected = {
                CompanyId: vm.user.CompanyId,
                IsNew: true
            };
        };

        /* 
        * @name edit
        * @desc edit team. 
        */
        function edit(index, team) {
            vm.mode = 'new';
            team.Index = index;
            team.IsNew = false;
            vm.teams.selected = angular.copy(team, {});
        };

        /* 
        * @name save
        * @desc saves teamt to db. 
        */
        function save() {
            teamService.save(vm.teams.selected).then(function (result) {
                vm.mode = 'list';
                if (vm.teams.selected.IsNew) {
                    vm.teams.items.unshift(result);
                } else {
                    var index = vm.teams.selected.Index || 0;
                    vm.teams.items[index] = result;
                }
                vm.team = undefined;
            });
        };

        /* 
        * @name remove
        * @desc delete a team.  
        */
        function remove() {
            teamService.remove(vm.teams.selected).then(function () {
                vm.mode = 'list';
                refresh();
                getUserTeams();
            });
        };
    };
})();