/**
* @name contactController
* @desc Contact Controller.
**/
(function () {
    'use strict';

    angular
        .module('CareGuard')
        .controller('contactController', contactController)

    /** Inject services into controller. */
    contactController.$inject = ['LxDialogService', 'companyService', 'locationService', 'contactService'];

    /**
    * @name contactController.
    * @desc Contact Controller.
    * @param {object} LxDialogService - modal service.
    * @param {object} companyService - company service.
    * @param {object} locationService - location service.
    * @param {object} contactService - contact service.
    */
    function contactController(LxDialogService, companyService, locationService, contactService) {
        var vm = this;

        vm.mode = 'list';
        vm.company = undefined;
        vm.locations = undefined;
        vm.contacts = {
            selected: undefined,
            searchBy: undefined,
            searchByAttribute: undefined,
            currentPage: 1,
            pageSize: 15,
            itemCount: 1,
            items: []
        };
        vm.clear = clear;
        vm.get = get;
        vm.search = search;
        vm.view = view;
        vm.add = add;
        vm.edit = edit;
        vm.cancel = cancel;
        vm.cancelEdit = cancelEdit;
        vm.select = select;
        vm.save = save;
        vm.confirmRemove = confirmRemove;
        vm.remove = remove;

        init();

        /* 
        * @name init
        * @desc initialize module
        */
        function init() {
            vm.mode = 'list';
            vm.company = companyService.getSelected();
            if (!vm.company || !vm.company.Id)
                return;

            // list of contacts for the company
            get();
        };

        /* 
        * @name clear
        * @desc Clear search bar and refresh list.
        */
        function clear() {
            vm.contacts.currentPage = 1;
            vm.contacts.searchByAttribute = undefined;
            vm.contacts.searchBy = undefined;
            get();
        };

        /* 
        * @name search
        * @desc Search contacts using the input string.
        */
        function search(filterBy) {
            vm.contacts.currentPage = 1;
            vm.contacts.searchByAttribute = filterBy;
            get();
        };

        /* 
        * @name get
        * @desc Get a list of contacts to display. 
        */
        function get() {
            var params = {
                pageSize: vm.contacts.pageSize,
                pageNum: vm.contacts.currentPage,
                sort: 'ContactName',
                sortDir: 'asc',
                filter: {}
            };

            // add filter if exists
            if (vm.contacts.searchByAttribute) {
                params.filter[vm.contacts.searchByAttribute] = vm.contacts.searchBy;
            }

            // refresh results from db
            contactService.getByCompany(vm.company.Id, params).then(function (data) {
                vm.contacts.items = data.Items;
                vm.contacts.itemCount = data.ItemCount;
            });
        };

        /* 
        * @name view
        * @desc view existing contact. 
        * @param {object} contact - contact.
        * @param {int} index - contact index within contacts array.
        */
        function view(contact, index) {
            vm.mode = 'view';
            vm.contacts.selected = angular.copy(contact);
            vm.contacts.selected.Index = index;
        };

        /* 
        * @name add
        * @desc add a new contact. 
        */
        function add() {
            vm.mode = 'edit';

            // list of locations for autocomplete
            locationService.getByCompany(vm.company.Id).then(function (result) {
                vm.locations = result;
            });
           
            vm.contacts.selected = {
                IsNew: true,
                IsActive: true
            }; 
        };

        /* 
        * @name edit
        * @desc edit existing contact. 
        */
        function edit() {
            vm.mode = 'edit';
        };

        /* 
        * @name cancel
        * @desc cancel viewing contact. 
        */
        function cancel() {
            vm.mode = 'list';
        };

        /* 
        * @name cancelEdit
        * @desc cancel adding/editing contact. 
        */
        function cancelEdit() {
            vm.mode = vm.contacts.selected.IsNew == true ? 'list' : 'view';
        };
        
        /* 
        * @name select
        * @desc select location. 
        * @param {object} val - selected object.
        */
        function select(val) {
            if (!val) {
                vm.contacts.selected.LocationId = undefined;
                return;
            }

            vm.contacts.selected.LocationId = val.originalObject.Id;
            vm.contacts.selected.LocationName = val.originalObject.LocationName;
        };

        /* 
        * @name save
        * @desc save contact. 
        */
        function save() {
            vm.contacts.selected.CompanyId = vm.company.Id;
            vm.contacts.selected.CompanyName = vm.company.Name;

            if (!vm.contacts.selected.LocationId)
                return;

            contactService.save(vm.contacts.selected).then(function (result) {
                var index = vm.contacts.selected.Index || 0;

                if (vm.contacts.selected.IsNew) {
                    vm.contacts.items.unshift(result);
                }
                else {
                    vm.contacts.items[index] = result;
                }
                result.Index = index;
                vm.contacts.selected = result;
                vm.mode = 'view';
            });
        };

        /* 
        * @name confirmRemove
        * @desc show delete confirmation dialog.
        */
        function confirmRemove() {
            LxDialogService.open('dlgConfirm');
        };

        /* 
        * @name remove
        * @desc remove contact.
        */
        function remove() {
            contactService.remove(vm.contacts.selected).then(function (result) {
                if (result == true) {
                    vm.contacts.items.splice(vm.contacts.selected.Index, 1);
                    vm.mode = 'list';
                }
            });
        };
    };
})();