/**
* @name casedataController
* @desc Contains logic for case data page.
**/
(function () {
    'use strict';

    angular
        .module('CareGuard')
        .controller('testdataController', testdataController)

    /** Inject services into controller. */
    testdataController.$inject = ['caseService'];

    /**
    * @name casedataController.
    * @desc Contains logic for case data page.
    */
    function testdataController(caseService) {

        // variables
        var vm = this;
        vm.generate = function () {
            caseService.test();
        };
    }
})();