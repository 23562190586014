/**contact
* @name memberonhold Controller
* @desc memberonhold controller.
**/
(function () {
	'use strict';

	angular
		.module('CareGuard')
		.controller('memberOnHoldController', memberOnHoldController);

	/** Inject services into controller. */
	memberOnHoldController.$inject = ['$scope', '$location', '$state', '$timeout', 'memberOnHoldService','utilService', 'getUrlService'];

    
	function memberOnHoldController($scope, $location, $state, $timeout, memberOnHoldService, utilService, getUrlService) {

		// variables
		var vm = this;
		//var iframe = undefined;
		vm.holds = {};
		
		vm.pageSizes = {
			data: [
				{ Name: 5, Value: 5 },
				{ Name: 10, Value: 10 },
				{ Name: 20, Value: 20 },
				{ Name: 50, Value: 50 },
				{ Name: 100, Value: 100 }
			]
		};

		vm.filterData = {
			TotalPages: 1,
			TotalRows: undefined,
			PageSize: 20,
			PageIndex: 1,
			SortColumn: undefined,
			SortOrder: 'ASC',
			MemberNumber: undefined,
			LastName: undefined,
			FirstName: undefined
		};


		// functions
        vm.getUrlFromState = getUrlFromState;
		vm.filterOnHolds = filterOnHolds;
		vm.changeSort = changeSort;
		vm.clearFilterInfo = clearFilterInfo;
		vm.resetFilterInfo = resetFilterInfo;
		vm.refresh = refresh;
		vm.navigate = navigate;
		vm.exportOnHolds = exportOnHolds;
		
		init();

		/* 
		* @name init
		* @desc initialize module
		*/
		function init() {

	
			resetFilterInfo();
			filterOnHolds();
			//iframe = document.createElement("iframe");

		};

        function getUrlFromState(stateName, params) {
            return getUrlService.get(stateName, params);
        }

		//filter Member On hold Accounts
		function filterOnHolds() {
			var tempMemberNumber = vm.filterData.MemberNumber;
			var tempLastName = vm.filterData.LastName;
			var tempFirstName = vm.filterData.FirstName;
			
			if (tempMemberNumber == undefined || tempMemberNumber == '')
				tempMemberNumber = null;

			if (tempLastName == undefined || tempLastName == '')
				tempLastName = null;

			if (tempFirstName == undefined || tempFirstName == '')
				tempFirstName = null;

			console.log(tempMemberNumber, tempLastName, tempFirstName, vm.filterData.PageIndex, vm.filterData.PageSize, vm.filterData.SortOrder);

			memberOnHoldService.filterOnHolds(tempMemberNumber, tempLastName, tempFirstName, vm.filterData.PageIndex, vm.filterData.PageSize, vm.filterData.SortColumn, vm.filterData.SortOrder).then(function (result) {
				vm.holds = result.Data;

				//attempt to fill TotalPages
				try {
					vm.filterData.TotalRows = vm.holds[0].TotalRows;
					vm.filterData.TotalPages = Math.ceil(vm.filterData.TotalRows / vm.filterData.PageSize);
				}
				catch (ex) {
					vm.filterData.TotalRows = 0;
					vm.filterData.TotalPages = 1;
				};

			});


		};

		//export MembersOnHold
		function exportOnHolds() {
			var tempMemberNumber = vm.filterData.MemberNumber;
			var tempLastName = vm.filterData.LastName;
			var tempFirstName = vm.filterData.FirstName;
			
			if (tempMemberNumber == undefined || tempMemberNumber == '')
				tempMemberNumber = null;

			if (tempLastName == undefined || tempLastName == '')
				tempLastName = null;

			if (tempFirstName == undefined || tempFirstName == '')
				tempFirstName = null;

			//iframe.src = "/api/memberonhold/exportOnHolds/" + tempMemberNumber + '/' + tempLastName + '/' + tempFirstName + '/' 
			
			////console.log(iframe.src);
			//// This makes the IFRAME invisible to the user.
			//iframe.style.display = "none";

			//// Add the IFRAME to the page.  This will trigger
			////   a request to GenerateFile now.
		  //document.body.appendChild(iframe);
			var exportOptions = {
			  method: 'GET',
			  url: "/api/memberonhold/exportOnHolds/" + tempMemberNumber + '/' + tempLastName + '/' + tempFirstName + '/'
			  };
			utilService.download(exportOptions);
		};
			

		//sorts the columns
		function changeSort(column) {
			if (vm.filterData.SortColumn == column) {
				vm.filterData.SortOrder = (vm.filterData.SortOrder == 'ASC' ? 'DESC' : 'ASC')
			}
			else {
				vm.filterData.SortColumn = column;
				vm.filterData.SortOrder = 'ASC';
			}

			filterOnHolds();
		};

		//refreshes or changes the page of data
		function refresh(page) {
			if (page == null || (page > 0 && page <= vm.filterData.TotalPages)) {
				vm.filterData.PageIndex = vm.filterData.TotalRows == 0 || page == null ? 1 : page;
				filterOnHolds();
			}

		};

		//clears the filter data
		function clearFilterInfo() {
			vm.filterData.MemberNumber = null;
			vm.filterData.LastName = null;
			vm.filterData.FirstName = null;

		};

		//resets the filter data
		function resetFilterInfo() {
			vm.filterData.MemberNumber = null;
			vm.filterData.LastName = null;
			vm.filterData.FirstName = null;
		

		};

		function navigate(state, params) {

			$timeout(function () {
				$state.go(state, params);
			}, 250);

		};

	};
})();