angular
    .module('CareGuard')
    .factory('notesService', notesService);

notesService.$inject = ['$http'];

function notesService($http) {
    const baseUrl = '/api/notes';

    function getMemberNotes(memberId) {
        return $http.get(`${baseUrl}/getMemberNotes/${memberId}`);
    }

    function getClaimNotes(claimId) {
        return $http.get(`${baseUrl}/getClaimNotes/${claimId}`);
    }

    function addNote(note) {
        return $http.post(`${baseUrl}/addNote`, note);
    }

    function updateNote(note) {
        return $http.put(`${baseUrl}/updateNote`, note);
    }

    function deleteNote(noteId) {
        return $http.delete(`${baseUrl}/deleteNote`, { params: { noteId } });
    }

    return {
        getMemberNotes,
        getClaimNotes,
        addNote,
        updateNote,
        deleteNote
    } 
}