/**
* @name chronovoRichTextbox
* @desc wrapper for jquery based rich textbox.
**/
(function () {
    'use strict';

    angular
        .module('CareGuard')
        .directive('chronovoRichTextbox', chronovoRichTextbox);
    
    /**
    * @name chronovoRichTextbox.
    * @desc rich textbox wrapper directive.
    * @param {object} $location.
    */
    function chronovoRichTextbox() {
        var directive = {
            restrict: 'A',
            require: '^ngModel',
            scope: {
                ngModel: '='
            },
            replace: true,
            link: postLink
        };

        return directive;

        function postLink($scope, $element, $attrs, $controller) {
            var height = $attrs.height || 150;
            var minHeight = $attrs.minHeight || 50;
            var maxHeight = $attrs.maxHeight || 150;

            $element.summernote({
                focus: true,
                height: height,
                minHeight: minHeight,
                maxHeight: maxHeight,
                toolbar: [
                   ['style', ['bold', 'italic', 'underline', 'clear']],
                   ['para', ['ul', 'ol', 'paragraph']],
                   ['fontsize', ['fontsize']],
                   ['view', ['undo', 'redo', 'fullscreen']]
                ],
                onChange: function (contents, $editable) {
                    $controller.$setViewValue(contents);
                }
            });

            $scope.$watch('ngModel', function (newVal, oldVal) {
                $element.code(newVal);
            });

            $element.next().find('.note-editable p').css({ 'line-height': '1' });
        }
    }
})();