/**
* @name dispositionService
* @desc Disposition Service.
**/
    angular
        .module('CareGuard')
        .factory('dispositionService', dispositionService);

/** Inject services into service. */
dispositionService.$inject = ['$http'];

/**
* @name dispositionService.
* @desc Disposition Service.
*/
function dispositionService($http) {
    const baseUrl = '/api/disposition/';

    const service = {
        getAll: getAll
    };

    return service;

    function getAll() {
        return $http.get(baseUrl + 'getAll/');
    }

}
