angular
    .module('CareGuard')
    .factory('utilService', utilService);

utilService.$inject = ['$http', '$q', '$toastr', '$filter'];

function utilService($http, $q, $toastr, $filter) {
    function download(options, openInNewTab) {
        const defaults = {
            url: undefined,
            method: 'POST',
            data: undefined,
            contentType: 'application/octet-stream'
        };

        const properties = Object.assign(defaults, options);
        if (!properties.url) return;

        var dfd = $q.defer();

        $http({
            method: properties.method,
            url: properties.url,
            data: properties.method === 'POST' ? properties.data : undefined,
            params: properties.method === 'GET' ? properties.data : undefined,
            responseType: 'arraybuffer'
        }).then(response => {
            processResponse({ response, contentType: properties.contentType, openInNewTab: openInNewTab });
        });

        return dfd.promise;
    }

    function processResponse({ response, fileNameOverride, contentType, openInNewTab }) {
        const headers = response.headers();
        const filename = getFileNameFromDispositionHeader(headers['content-disposition']) ?? headers['x-filename'] ?? fileNameOverride ?? 'export';
        const url = window.URL.createObjectURL(new Blob([response.data], { type: headers['content-type'] ?? contentType }));

        createAnchorToDownload({ url, filename, openInNewTab });
    }

    function getFileNameFromDispositionHeader(header) {
        if (!header) return null;

        return header.split(";")[1].trim().split("=")[1].replace(/"/g, '');
    }

    function createAnchorToDownload({ url, filename, openInNewTab }) {
        const link = document.createElement('a');
        link.href = url;
        if (!openInNewTab) {
            link.setAttribute('download', filename);
        }
        else {
            link.setAttribute("target", "_blank");
        }
        document.body.appendChild(link);
        link.click();
    }

    function encodedUri(val) {
        return encodeURIComponent(val).replace(new RegExp('%', 'g'), '!');
    }

    function getDocumentFile(fileNameWithExt, MemberNumber) {
        if (!fileNameWithExt || !MemberNumber) {
            $toastr.show('An error occur while loading file', 'warning');
            return;
        }

        const filename = fileNameWithExt.substring(0, fileNameWithExt.lastIndexOf(".") + 1);
        const ext = fileNameWithExt.substring(fileNameWithExt.lastIndexOf(".") + 1);

        const exportOptions = {
            method: 'GET',
            url: "/api/documents/getDocumentFile/" + MemberNumber + '/' + btoa(filename) + '/' + ext + '/'
        };

        return download(exportOptions);
    }

    function parseErrorMessage(err) {
        return err?.data?.MsgText
            ?? err?.data?.errors?.Details
            ?? err?.data?.detail
            ?? Object.values(err?.data?.errors || [[]])[0][0]
            ?? (typeof(err?.data) == "string" && err.data ? err.data : null)
            ?? `There has been an error.`;
    }

    function trimStrings(obj) {
        Object.keys(obj).forEach(key => obj[key] = typeof obj[key] == 'string' ? obj[key].trim() : obj[key]);
    }

    function getKeyByValue(obj, value) {
        return Object.keys(obj).find(key => obj[key] === value);
    }

    function splitWordByCapitalLetters(word) {
        return word?.split(/(?=[A-Z])/).join(' ')
    }

    //DateTime properties in api dtos/models expect date input in 'YYYY-MM-DD' format
    function formatDateForApi(dateStr, formatStr) {
        var dtObj = moment(dateStr);
        return dtObj._isValid ? dtObj.format(formatStr || 'YYYY-MM-DD') : null;
    }
   
    let formatUtcToEst = $filter("utcToEst");

    return {
        download,
        processResponse,
        encodedUri,
        getDocumentFile,
        parseErrorMessage,
        trimStrings,
        getKeyByValue,
        splitWordByCapitalLetters,
        formatDateForApi,
        formatUtcToEst,
    };
}