angular
    .module('CareGuard')
    .directive('inputCurrencyFormat', inputCurrencyFormat);

inputCurrencyFormat.$inject = ['$filter'];

function inputCurrencyFormat($filter) {
    return {
        restrict: 'A',
        require: 'ngModel',
        priority: 10,
        link: function($scope, elm, attrs, ngModelController) {
            if (!ngModelController) return;

            const onlyNumbers = $filter('positiveNumbers');
            const currency = $filter('currency');
            const currencySymbol = '$';
            const decimalSeparator = '.';
            const groupSeparator = ',';

            ngModelController.$parsers.unshift(function(value) {
                const valueAsNumber = Math.floor(Math.round(onlyNumbers(value) * 100)) / 100;

                let filtered = '';

                if (value === '' || value === 0) {
                    filtered = currencySymbol + onlyNumbers(value);

                } else {
                    const  dotIndex = onlyNumbers(value).indexOf(decimalSeparator);
                    if (dotIndex > -1) {
                        let valueBeforeDot = onlyNumbers(value).substr(0, dotIndex + 1);
                        let valueAfterDot = onlyNumbers(value).substr(dotIndex + 1, onlyNumbers(value).length);
                        filtered = currencySymbol + valueBeforeDot.replace(/\B(?=(\d{3})+(?!\d))/g, groupSeparator) + valueAfterDot;
                    } else {
                        filtered = currencySymbol + onlyNumbers(value).replace(/\B(?=(\d{3})+(?!\d))/g, groupSeparator);
                    }
                }

                ngModelController.$setViewValue(filtered);
                ngModelController.$render();

                return valueAsNumber;
            });

            ngModelController.$formatters.push(function(data) {
                if (Math.floor(data) === data) {
                    return currency(data, currencySymbol, 0);
                } else {
                    return currency(data);
                }
            });

            $(elm).on('blur', function(){
                if ((ngModelController.$modelValue != null) && !attrs.readonly) {
                    ngModelController.$setViewValue(ngModelController.$modelValue, true);
                    ngModelController.$render();
                }
            });
        }
    }
}
