angular
    .module('CareGuard')
    .factory('memberService', memberService);

memberService.$inject = ['$http'];

function memberService($http) {
    // External API calls
    const baseRoute = `members`;

    function getMembersByIds(filters) {
        return $http.get(`${apiUrl}/${baseRoute}`, { params: filters });
    }

    function getMembersByIdsByBulk(filters) {
        return $http.post(`${apiUrl}/${baseRoute}/bulk`, filters);
    }

    function getMemberIdsByNumberOrName(filters) {
        return $http.get(`${apiUrl}/${baseRoute}/ids/search`, { params: filters });
    }

    function searchMember(searchPhrase, isMemberAccessRestricted) {
        var filters = {
            searchPhrase: searchPhrase,
            isMemberAccessRestricted: isMemberAccessRestricted
        };
        return $http.get(searchMemberRoute(), { params: filters });
    }

    function searchMemberRoute() {
        return `${apiUrl}/${baseRoute}/search`;
    }

    function getAllocationTypes() {
        return $http.get(`${apiUrl}/${baseRoute}/allocations/types`);
    }

    function getMemberById(id) {
        return $http.get(`${apiUrl}/${baseRoute}/${id}`);
    }

    function getAccountStatuses() {
        return $http.get(`${apiUrl}/${baseRoute}/accounts/statuses`);
    }

    function getAccountTypes() {
        return $http.get(`${apiUrl}/${baseRoute}/accounts/types`);
    }

    function getLeadTypes() {
        return $http.get(`${apiUrl}/${baseRoute}/leads/types`);
    }

    function getPbmVendors() {
        return $http.get(`${apiUrl}/${baseRoute}/vendors/pbms`);
    }

    function getBeneficiariesByMemberId(memberId) {
        return $http.get(`${apiUrl}/${baseRoute}/${memberId}/beneficiaries`);
    }

    function getMailOrderVendors() {
        return $http.get(`${apiUrl}/${baseRoute}/vendors/mail`);
    }

    function getMemberHoldsByMemberId(memberId) {
        return $http.get(`${apiUrl}/${baseRoute}/${memberId}/holds`);
    }

    function addMemberHold(memberId, memberHold) {
        return $http.post(`${apiUrl}/${baseRoute}/${memberId}/holds`, memberHold);
    }

    function clearMemberHold(holdId) {
        return $http.patch(`${apiUrl}/${baseRoute}/holds/${holdId}`);
    }

    function getPbmAccumulatorHistory(memberId, query) {
        return $http.get(`${apiUrl}/${baseRoute}/${memberId}/pbms/responses`, { params: query });
    }

    function getPbmAccumulatorDateHistory(memberServiceDateList) {
        return $http.post(`${apiUrl}/${baseRoute}/members/responses/billpay`, memberServiceDateList);
    }

    // CH C# controller calls
    let memberData = {};
    const baseUrl = '/api/member/';

    function getById(Id) {
        return $http.get(baseUrl + 'getByID/' + Id);
    }

    function getByAccountNumber(accountNumber, bankID) {
        return $http.get(baseUrl + 'getMemberByAccountNumber/' + accountNumber + '/' + bankID);
    }

    function getMemberIdByMemberNumber(memberNumber) {
        return $http.get(`${baseUrl}/getMemberIdByMemberNumber/${memberNumber}`);
    }

    function loadAddresses(Id) {
        return $http.get(baseUrl + 'loadAddresses/' + Id);
    }

    function getUsers(searchQuery) {
        return $http.get(baseUrl + 'getUsers?query=' + encodeURIComponent(searchQuery));
    }

    function getUsersParameterFormat(query) {
        return { 'query': query };
    }

    function getUserByEmail(email) {
        return $http.get(baseUrl + 'getUserByEmail?email=' + encodeURIComponent(email));
    }

    function get() {
        return $http.get(baseUrl + 'get/');
    }

    function saveMember(memberInfo) {
        return $http.post(baseUrl + 'saveMember', memberInfo);
    }

    function deleteMember(memberID) {
        return $http.post(baseUrl + 'deleteMember/' + memberID);
    }

    function getMember() {
        return memberData;
    }

    function setMember(memberInfo) {
        memberData = memberInfo;
    }

    function saveAddresses(memberId, addresses) {
        return $http.post(baseUrl + 'saveAddresses/' + memberId, addresses);
    }

    function saveBeneficiaries(memberID, beneficiaryList) {
        return $http.post(baseUrl + 'saveBeneficiaries/' + memberID, beneficiaryList);
    }

    function getBeneficiaries(memberID) {
        return $http.get(baseUrl + 'getBeneficiaries/' + memberID);
    }

    function getMemberLanguages(memberID) {
        return $http.get(baseUrl + 'getMemberLanguages/' + memberID + '/');
    }

    function saveMemberLanguage(memberLanguageID, memberID, languageID, communicationTypeID) {
        return $http.post(baseUrl + 'saveMemberLanguage/' + memberLanguageID + '/' + memberID + '/' + languageID + '/' + communicationTypeID + '/');
    }

    function addNote(note) {
        return $http.post('/api/notes/addNote', note);
    }

    function autoPopulatePricingMechanism(member, feeScheduleData) {
        if (member.JurisdictionState) {
            const jurisdictionStateValue = member.JurisdictionState;
            const pricingMechanismData = feeScheduleData;

            const reg = `^(${jurisdictionStateValue})$|(${jurisdictionStateValue})(\\W)`;
            const currentPricingMechanism = pricingMechanismData.find(tax => tax.DataSetValue.match(reg));

            if (currentPricingMechanism) {
                member.PricingMechanismID = currentPricingMechanism.DataSetID;
            }
        }
    }

    function populateBeneficiaryType(member) {
        getBeneficiaries(member.MemberID).then(result => {
            setBeneficiaryType(result, member);
        });
    }

    function setBeneficiaryType(result, member) {
        if (!result || !result.Data.length) {
            member.BeneficiaryType = '';
            return;
        }

        const isReversionary = result.Data.some(item => item.BeneficiaryType === 'Reversionary');
        const isEstate = result.Data.some(item => item.BeneficiaryType === 'Estate');
        const isContingent = result.Data.some(item => item.BeneficiaryType === 'Contingent');

        if (isReversionary) {
            member.BeneficiaryType = 'Reversionary';
            return;
        }
        else if (isEstate) {
            member.BeneficiaryType = 'Estate';
            return;
        }
        else if (isContingent) {
            member.BeneficiaryType = 'Contingent';
            return;
        }
        else {
            member.BeneficiaryType = '';
        }
    }

    function getContacts(Id) {
        return $http.get(baseUrl + 'getContacts/' + Id);
    }

    function isValidMemberSSN(ssn) {
        return $http.post(`${apiUrl}/${baseRoute}/ssn/validate`, '"' + ssn + '"');
    }

    return {
        // External API calls
        getMembersByIds,
        getMembersByIdsByBulk,
        getMemberIdsByNumberOrName,
        searchMember,
        searchMemberRoute,
        getAllocationTypes,
        getMemberById,
        getAccountStatuses,
        getAccountTypes,
        getLeadTypes,
        getPbmVendors,
        getBeneficiariesByMemberId,
        getMailOrderVendors,
        getMemberHoldsByMemberId,
        addMemberHold,
        clearMemberHold,
        getPbmAccumulatorHistory,
        getPbmAccumulatorDateHistory,
        isValidMemberSSN,

        // CH C# controller calls
        get,
        getById,
        getByAccountNumber,
        getMemberIdByMemberNumber,
        saveMember,
        addNote,
        setMember,
        getMember,
        getUsers,
        getUsersParameterFormat,
        getUserByEmail,
        loadAddresses,
        saveAddresses,
        deleteMember,
        saveBeneficiaries,
        getBeneficiaries,
        getMemberLanguages,
        saveMemberLanguage,
        autoPopulatePricingMechanism,
        populateBeneficiaryType,
        setBeneficiaryType,
        getContacts,
    };
}