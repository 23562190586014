(function () {
    'use strict';

    angular
        .module('CareGuard')
        .controller('configController', configController);

    /** Inject services into controller. */
    configController.$inject = ['lookupService', 'configService', 'smartConfigData', 'LxDialogService'];

    /**
    * @name configController.
    * @desc config Controller.
    */
    function configController(lookupService, configService, configData, LxDialogService) {
        var vm = this;

        // variables
        var dialogId = 'dlgSmartQuoteConfig';
        var confirmId = 'dlgConfirm';
        vm.lookupdata = undefined
        vm.smartQuote = undefined;
        vm.categorydata = undefined;
        vm.config = undefined;
        vm.columns = undefined;
        vm.category = undefined;;
        var events = {
            open: function (row) {
                vm.smartQuote = angular.copy(row, {});
                open();
            },
            confirm: function (row) {
                vm.smartQuote = angular.copy(row, {});
                LxDialogService.open(confirmId);
            }
        };
        vm.config = {
            selectionMode: 'none',
            rowNum: 15,
            filterable: false,
            events: events
        };

        // public functions
        vm.update = update;
        vm.open = open;
        vm.close = close;
        vm.save = save;
        vm.remove = remove;
        vm.add = add;

        // initialize
        init();

        /* 
        * @name init
        * @desc initialize module
        */
        function init() {
            vm.categorydata = configData.data ? { ConfigType: 'SmartQuote' } : configData;
            vm.lookupdata = {
                annuityTypes: lookupService.getByCategory('Annuity Type'),
                frequencies: lookupService.getByCategory('Term Type')
            };
            var categories = lookupService.getByCategory('Quote Category');
            if (categories) {
                vm.category = categories[0].Key;
            };
            update();
        };

        /* 
         * @name update
         * @desc update grid data
        */
        function update() {
            if (vm.category == 'L') {
                vm.columns = [
               { label: 'Action', cellTemplate: 'addIcon.html', filterable: false, sortable: false },
               { label: 'From Age', key: 'FromAge' },
               { label: 'To Age', key: ' ToAge' },
               { label: 'Frequency', key: 'FrequencyAsString' },
               { label: 'Annuity Type', key: 'AnnuityTypeAsString' },
               { label: 'COLA', key: 'COLA' },
               { label: 'From Settlement', key: 'SettlementFrom' },
               { label: 'To Settlement', key: 'SettlementTo' },
               { label: 'Term', key: 'TermYears' },
               { label: 'Start Date(Years)', key: 'StartDate' }
                ];
            }
            else if (vm.category == 'WC') {
                vm.columns = [
                { label: 'Action', cellTemplate: 'addIcon.html', filterable: false, sortable: false },
                { label: 'From Age', key: 'FromAge' },
                { label: 'To Age', key: ' ToAge' },
                { label: 'Frequency', key: 'FrequencyAsString' },
                { label: 'Annuity Type', key: 'AnnuityTypeAsString' },
                { label: 'COLA', key: 'COLA' },
                { label: 'Term', key: 'TermYears' },
                { label: 'Start Date(Years)', key: 'StartDate' }
                ];
            };
            if (vm.categorydata && vm.categorydata.SmartQuotes) {
                vm.rows = Enumerable.from(vm.categorydata.SmartQuotes).where('$.QuoteType == "' + vm.category + '"').toArray();
            };
        };

        /*
        * @name open
        * @desc open diary dialog
        */
        function open() {
            LxDialogService.open(dialogId);
        };

        /*
        * @name close
        * @desc close case dialog
        */
        function close() {
            vm.smartQuote = {};
            LxDialogService.close(dialogId);
            LxDialogService.close(confirmId);
        };

        /* 
        * @name add
        * @desc add a new config record
        */
        function add() {
            vm.smartQuote = {
                QuoteType: vm.category
            };
            open();
        };

        /* 
         * @name remove
         * @desc remove Quote.
        */
        function remove() {
            for (var key in vm.categorydata.SmartQuotes) {
                var item = vm.categorydata.SmartQuotes[key];
                if (item.Id == vm.smartQuote.Id) {
                    vm.categorydata.SmartQuotes.splice(key, 1);
                    break;
                };
            };
            save();
        };

        /* 
        * @name save
        * @desc save diary information
        */
        function save() {
            if (!vm.categorydata.SmartQuotes) {
                vm.categorydata.SmartQuotes = [];
            };
            if (vm.smartQuote.Id) {
                for (var key in vm.categorydata.SmartQuotes) {
                    var item = vm.categorydata.SmartQuotes[key];
                    if (item.Id == vm.smartQuote.Id) {
                        vm.categorydata.SmartQuotes[key] = vm.smartQuote;
                        break;
                    };
                };
            } else {
                vm.categorydata.SmartQuotes.push(vm.smartQuote);
            };

            configService.save(vm.categorydata).then(function (result) {
                vm.categorydata = result;
                update();
                close();
            });
        };
    };
})();