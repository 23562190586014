///**
//* @name chronovoSlider
//* @desc wrapper for uiSlider
//* @usage <divchronovo-slider="field" data-min="0" data-max="100"></div>
//**/
//(function () {
//    'use strict';

//    angular
//        .module('CareGuard')
//        .directive('chronovoSlider', chronovoSlider);

//    /** Inject services into directive. */
//    chronovoSlider.$inject = ['$parse', '$timeout'];

//    /**
//    * @name chronovoSlider
//    * @desc wrapper for uiSlider
//    */
//    function chronovoSlider($parse, $timeout) {
//        var nextUniqueId = ['0', '0', '0'];
//        var recurse = false;
//        var shouldupdate = true;

//        var directive = {
//            restrict: 'A',
//            scope: {
//                chronovoSlider: '=',
//                min: '=?',
//                max: '=?',
//                step: '=?',
//                total: '=?',
//                collection: '=?'
//            },
//            link: postLink
//        };

//        return directive;

//        /*
//        * @name postLink
//        * @desc link for slider directive
//        */
//        function postLink($scope, $element, $attrs, $controller) {

//            // options
//            var options = {
//                start: $scope.chronovoSlider || 0,
//                //step: $scope.step || .01,
//                connect: 'lower',
//                orientation: 'horizontal',
//                behaviour: 'tap',
//                animate: true,
//                range: {
//                    min: parseFloat($scope.min || 0),
//                    max: parseFloat($scope.max || 1000000)
//                }
//            };
            
//            // create id if none defined and update total
//            if (!$attrs.id) {
//                $attrs.id = nextId();
//            }

//            $scope.collection[$attrs.id] = $scope.chronovoSlider || 0;

//            // create slider and wire events
//            var slider = $element.noUiSlider(options);
//            slider.on({
//                slide: update,
//                set: set
//            });

//            // set watch to update when model is changed in parent controller
//            $scope.$watch('max', function (val, old) {
//                if (!val || val == old) return;
//                old = parseFloat(old);
//                val = parseFloat(val);
//                var range = {
//                    'min': parseFloat($scope.min || 0),
//                    'max': val || 100
//                };
//                slider = $element.noUiSlider({ range: range }, true);

//                var total = getTotal(true);
//                // uncomment to reset slider to zero when total changes
//                //slider.val(0);
//                update();
//            });

//            // set watch to update when model is changed in parent controller
//            $scope.$watch('chronovoSlider', function (newVal, oldVal) {
//                $timeout(function () {
//                    //if (!shouldupdate) {
//                    //    shouldupdate = true;
//                    //}
//                    slider.val(parseFloat(newVal));
//                    refresh();
//                }, 50);
//            });

//            /*
//            * @name set
//            * @desc called when slider stops moving, check against limit
//            */
//            function set(event, value) {
//                if ($scope.max == undefined) return;
//                if (recurse == true) {
//                    recurse = false;
//                    return;
//                }
//                var id = $attrs.id;
//                var val = parseFloat(value);
//                var total = getTotal();
//                var limit = parseFloat($scope.max) - parseFloat(total);

//                // check against limit
//                if (val >= limit) {
//                    recurse = true;
//                    $(this).val(limit);
//                    update();
//                }

//                // update total
//                val = parseFloat($(this).val());
//                $scope.collection[id] = val;
//                setTotal(getTotal() + val);
//            };

//            /*
//            * @name update
//            * @desc called when slider is being dragged or clicked, update model
//            */
//            function update() {
//                var val = parseFloat($(slider).val());
//                $timeout(function () {
//                    //shouldupdate = false;
//                    $scope.chronovoSlider = val;
//                }, 50);
//            };

//            /*
//            * @name refresh
//            * @desc can be called from parent controller to refresh totals
//            */
//            function refresh() {
//                setTotal(getTotal(true));
//            };

//            /*
//            * @name getTotal
//            * @desc get a total of all slider values (including/excluding the current)
//            */
//            function getTotal(inclusive) {
//                var id = $attrs == undefined ? undefined : $attrs.id;
//                var total = 0;
                
//                for (var key in $scope.collection) {
//                    if (key != id || inclusive == true) {
//                        total += parseFloat($scope.collection[key]);
//                    }
//                };
//                return total;
//            };

//            /*
//            * @name setTotal
//            * @desc set $scope.total variable value
//            */
//            function setTotal(val) {
//                $timeout(function () {
//                    $scope.total = val;
//                }, 50);
//            };

//            /*
//            * @name nextId
//            * @desc creates a unique alphanumeric id [A001]
//            */
//            function nextId() {
//                var index = nextUniqueId.length;
//                var digit;

//                while (index) {
//                    index--;
//                    digit = nextUniqueId[index].charCodeAt(0);
//                    if (digit == 57 /*'9'*/) {
//                        nextUniqueId[index] = 'A';
//                        return nextUniqueId.join('');
//                    }
//                    if (digit == 90  /*'Z'*/) {
//                        nextUniqueId[index] = '0';
//                    } else {
//                        nextUniqueId[index] = String.fromCharCode(digit + 1);
//                        return nextUniqueId.join('');
//                    }
//                }
//                nextUniqueId.unshift('0');
//                return nextUniqueId.join('');
//            };
//        };
//    };
//})();