import { diagnosisDefsGrid } from './diagnosisColumnDefsForClaimDetails';
import { diagnosisDefsGridForNewClaim } from './diagnosisColumnDefsForNewClaim';
import { defaultGridOptions } from '../../constant';
import diagnosisRowTemplate from './html/rowTemplate.html';
import diagnosisFooterTemplate from './html/diagnosisFooterTemplate.html';

angular
    .module('CareGuard')
    .controller('diagnosisController', diagnosisController);

diagnosisController.$inject = [
    '$toastr',
    '$q',
    'uiGridConstants',
    'claimService',
    'gridUtils',
    'codeService',
];

function diagnosisController(
    $toastr,
    $q,
    uiGridConstants,
    claimService,
    gridUtils,
    codeService,
) {

    let vm = this;

    const isClaimDetail = !!vm.isClaimDetail;

    vm.isClaimInPaidStatus = isClaimInPaidStatus;
    vm.updateDiagnosis = updateDiagnosis;

    vm.deleteDiagnosisRow = deleteDiagnosisRow;
    vm.addDiagnosisRow = addDiagnosisRow;
    vm.hasDiagnosisGridChanged = hasDiagnosisGridChanged;
    vm.cancelDiagnosisGridChanges = cancelDiagnosisGridChanges;
    vm.getTooltipForSaveCancelButtons = getTooltipForSaveCancelButtons;
    vm.getTooltipForAddNewCodeDelete = getTooltipForAddNewCodeDelete;
    vm.searchDiagnosisCodes = searchDiagnosisCodes;
    vm.selectDiagnosisCode = selectDiagnosisCode;

    vm.onFocusSearchDiagnosis = onFocusSearchDiagnosis;
    vm.onBlurSearchDiagnosis = onBlurSearchDiagnosis;
    vm.getDiagnosisGridHeight = getDiagnosisGridHeight;
    vm.setDiagnosisAsPrimary = setDiagnosisAsPrimary;
    
    vm.castSequenceToLetter = castSequenceToLetter;

    vm.isDataLoading = false;

    let dataReferences = {
        gridOptionsDiagnosis: []
    };

    vm.gridOptionsDiagnosis = defaultGridOptions({
        data: [],
        columnDefs: diagnosisDefsGrid,
        rowHeight: 48,
        headerHeight: 48,
        minRowsToShow: 2,
        rowTemplate: diagnosisRowTemplate,
        enableVerticalScrollbar: uiGridConstants.scrollbars.NEVER,
        enablePaginationControls: false,
        enableCellEditOnFocus: true,
        enableSorting: false,
        useExternalPagination: false,
        useExternalSorting: false,
        totalItems: 0,
        enableExpandableRowHeader: false,
        gridFooterTemplate: diagnosisFooterTemplate,
        showGridFooter: true
    });

    vm.gridOptionsDiagnosis.columnDefs = isClaimDetail ? diagnosisDefsGrid : diagnosisDefsGridForNewClaim;
    vm.gridOptionsDiagnosis.invalidFields = {};

    fillDiagnosisGrid();

    function fillDiagnosisGrid() {
        if (angular.equals({}, vm.claim) || !vm.claim.diagnosisList) return;

        vm.gridOptionsDiagnosis.data = vm.claim.diagnosisList;

        vm.gridOptionsDiagnosis.data = gridUtils.populateRowId(vm.gridOptionsDiagnosis.data);
        angular.copy(vm.gridOptionsDiagnosis.data, dataReferences.gridOptionsDiagnosis);
        vm.claim.gridOptionsDiagnosis = vm.gridOptionsDiagnosis;

        setPrimaryDiagnosis();

        vm.gridOptionsDiagnosis.minRowsToShow = vm.gridOptionsDiagnosis?.data?.length + 2;
    }

    function setPrimaryDiagnosis() {
        if (!vm.gridOptionsDiagnosis?.data?.length) return;

        vm.primaryDiagnosis = vm.gridOptionsDiagnosis.data.find(item => item.isPrimary);
    }

    function addDiagnosisRow() {
        if (isClaimInPaidStatus()) return;

        ++vm.gridOptionsDiagnosis.minRowsToShow;

        const emptyRow = gridUtils.getEmptyRowEntityFromColumnDefs(diagnosisDefsGrid);
        emptyRow.rowId = gridUtils.generateRowId(vm.gridOptionsDiagnosis.data);
        emptyRow.isPrimary = false;

        vm.gridOptionsDiagnosis.data.push(emptyRow);
        vm.gridOptionsDiagnosis.minRowsToShow = vm.gridOptionsDiagnosis?.data?.length + 2;
        vm.claim.diagnosisList = vm.gridOptionsDiagnosis.data;

        if (vm.gridOptionsDiagnosis?.data?.length === 1) {
            vm.gridOptionsDiagnosis.data[0].isPrimary = true;
            vm.primaryDiagnosis = vm.gridOptionsDiagnosis.data[0];
        }
    }

    function deleteDiagnosisRow(note) {
        if (isClaimInPaidStatus()) return;

        let index = vm.gridOptionsDiagnosis.data.findIndex(x => x.rowId === note.rowId);

        if (index > -1) {
            let indexToSetPrimary;
            if (vm.gridOptionsDiagnosis.data[index].isPrimary) {
                indexToSetPrimary = 1;
            }

            vm.gridOptionsDiagnosis.data.splice(index, 1);

            if (indexToSetPrimary && vm.gridOptionsDiagnosis?.data?.length) {
                vm.gridOptionsDiagnosis.data[indexToSetPrimary - 1].isPrimary = true;
                vm.primaryDiagnosis = vm.gridOptionsDiagnosis.data[indexToSetPrimary - 1];
            }
        }

        vm.gridOptionsDiagnosis.invalidFields = {};
        vm.gridOptionsDiagnosis.invalidMessage = null;
        vm.gridOptionsDiagnosis.minRowsToShow = vm.gridOptionsDiagnosis?.data?.length + 2;

        vm.gridOptionsDiagnosis.data = gridUtils.populateRowId(vm.gridOptionsDiagnosis.data);
        vm.claim.diagnosisList = vm.gridOptionsDiagnosis.data;
    }

    function updateDiagnosis() {
        const validationDiagnosisResults = gridUtils.validateDiagnosisGrid(vm.gridOptionsDiagnosis);
        vm.gridOptionsDiagnosis.invalidMessage = validationDiagnosisResults
                                            && validationDiagnosisResults.resultInvalidMessage || null;

        if (validationDiagnosisResults && validationDiagnosisResults.resultInvalidMessage) {
            $toastr.show(validationDiagnosisResults.resultInvalidMessage, 'warning');
            return;
        }

        if (!vm.claim.id) {
            $toastr.show('Something went wrong! Cannot update without Claim Id!', 'warning');
            return;
        }

        vm.isDataLoading = true;
        return claimService.saveClaimDiagnosis(vm.gridOptionsDiagnosis.data, vm.claim.id)
            .then(() => $q.when(vm.onSave()))
            .then(fillDiagnosisGrid)
            .finally(_ => {
                vm.isDataLoading = false;
            });
    }

    function getTooltipForSaveCancelButtons() {
        if (isClaimInPaidStatus() || !hasDiagnosisGridChanged()) {
            return 'Claim has been Paid and cannot be edited, or the diagnoses data hasn\'t been changed!';
        }
    }

    function getTooltipForAddNewCodeDelete() {
        if(isClaimInPaidStatus()) {
            return 'Claim has been Paid and cannot be edited!'
        }
    }

    function hasDiagnosisGridChanged() {
        return gridUtils.hasGridChanged(vm.gridOptionsDiagnosis?.data, dataReferences.gridOptionsDiagnosis);
    }

    function cancelDiagnosisGridChanges() {
        if (!dataReferences.gridOptionsDiagnosis || !dataReferences.gridOptionsDiagnosis?.length) return;

        angular.copy(dataReferences.gridOptionsDiagnosis, vm.gridOptionsDiagnosis?.data);
        setPrimaryDiagnosis();
        vm.gridOptionsDiagnosis.invalidFields = {};
        vm.gridOptionsDiagnosis.invalidMessage = null;
    }

    function selectDiagnosisCode(selected, entity) {
        if (!selected || angular.equals({}, selected)) return;

        Object.assign(entity, selected.originalObject);
        entity.serviceCodeID = selected.originalObject.id;
        vm.gridOptionsDiagnosis.minRowsToShow = vm.gridOptionsDiagnosis?.data?.length + 2;

        return entity;
    }

    function isClaimInPaidStatus() {
        if (!vm.claim || !vm.claim.ClaimStatusID) return;

        return claimService.isStatusAPaidStatus(vm.claim.ClaimStatusID);
    }

    function searchDiagnosisCodes(query) {
        if (!query) return;

        return codeService.searchDiagnosisCodes({ code: query }).then(({ data: { items: codes }}) => {
            if (!codes?.length) return;

            return codes.map(code => {
                if (!code.description || code.description === 'Unknown') {
                    code.codeTypeAndDescription = code.codeType;
                } else {
                    code.codeTypeAndDescription = `${code.codeType} - ${code.description}`;
                }

                return code;
            });
        });
    }

    function onFocusSearchDiagnosis() {
        vm.gridOptionsDiagnosis.minRowsToShow = vm.gridOptionsDiagnosis.minRowsToShow + 4;
    }

    function onBlurSearchDiagnosis(entity) {
        vm.gridOptionsDiagnosis.minRowsToShow = vm.gridOptionsDiagnosis.minRowsToShow - 4;

        if (!entity.code || !entity.codeType) {
            entity.code = null;
            entity.codeType = null;
            entity.description = null;
            return;
        }

        if (entity.code
            && entity.code === dataReferences.gridOptionsDiagnosis[entity.rowId - 1].code) return;

        if (entity.code && entity.description === dataReferences.gridOptionsDiagnosis[entity.rowId - 1].description
                && entity.code !== dataReferences.gridOptionsDiagnosis[entity.rowId - 1].code) {
            entity.code = dataReferences.gridOptionsDiagnosis[entity.rowId - 1].code;
        }
    }

    function getDiagnosisGridHeight() {
        const rowsToShow = (vm.gridOptionsDiagnosis?.data?.length + 2) > vm.gridOptionsDiagnosis.minRowsToShow ?
            vm.gridOptionsDiagnosis?.data?.length + 2 : vm.gridOptionsDiagnosis.minRowsToShow;
        return rowsToShow * vm.gridOptionsDiagnosis.rowHeight + 'px';
    }

    function setDiagnosisAsPrimary(rowId) {
        if (!vm.gridOptionsDiagnosis?.data?.length) return;

        if (vm.gridOptionsDiagnosis.data[rowId - 1].isPrimary) return;

        vm.gridOptionsDiagnosis.data.forEach(diagnosis => {
            if (diagnosis.rowId === rowId) {
                diagnosis.isPrimary = true;
            } else {
                diagnosis.isPrimary = false;
            }
        });
    }

    function castSequenceToLetter(diagnosis) {
        const sequenceSelector = isClaimDetail
            ? d => d.sequence
            : d => d.rowId;

        return gridUtils.castDiagnosisSequenceToLetterBySelector(diagnosis, sequenceSelector);
    }
}