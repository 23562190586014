angular
    .module('CareGuard')
    .constant('accountStatus', {
        Member:	1,
        TerminatedMember: 2,
        Prospective: 3,
        FutureMember: 303,
        FundedFutureMember: 304,
        OnHoldMember: 305,
        TerminatedReferral: 364,
        FundingReceived: 521,
        FeesReceived: 523,
        AmethystMember: 569,
        TerminatedAmethystMember: 570,
        AmethystProspective: 573,
        PendingAmethystMember: 574,
        ActiveAmethystMember: 575,
        PreReferral: 712,
        PendingMember: 800
    });
