// ********************* Example of gridFooterTemplate for Table Pattern 1 ******************
//
// ********************* Need to be imported in Controller:
//
// ********************* import {defaultGridOptions} from "../../constant";
// ********************* import {contactColumnDefsGrid} from "../../components/Table_Pattern1/contactColumnDefs";
// ********************* import {contactRowTemplate} from "../../components/Table_Pattern1/contactRowTemplate";
// ********************* import {gridFooterTemplate} from "../../components/Table_Pattern1/gridFooterTemplate";
//
//  *****  vm.validateContactGrid(), vm.gridOptionsContact and vm.addContactRow() need to be replaced with new functions


export const contactGridFooterTemplate =
    `<div class="ui-grid-cell-contents grid-component_footer" col-index="renderIndex">
        <div class="col-xs-9 grid-component_validation">
            <div  ng-show="grid.appScope.vm.memberForm.$submitted" ng-repeat="(rowIndex, values) in grid.appScope.vm.gridOptionsContact.invalidFields">
                <p class="text-danger">
                    <span ng-repeat="field in values">
                        <span ng-bind-template="{{field}}"
                              ng-if="($first && values.length < 3) || (!$first && !$last  && $index === (values.length - 2))"></span>
                        <span ng-bind-template="{{field}}," ng-if="!$last && values.length > 2 && $index !== (values.length - 2)"></span>
                        <span ng-bind-template=" and {{field}}" ng-if="$last && values.length !== 1"></span>
                    </span>
                    <span ng-if="values.length === 1">is</span>
                    <span ng-if="values.length !== 1">are</span>
                     required to save row {{rowIndex}}</p>
            </div>
            <div  ng-show="grid.appScope.vm.memberForm.$submitted && !grid.appScope.vm.gridOptionsContact.isCurrentAddressAmountValid">
			    <p class="text-danger">There must be exactly one current address for each address type</p>
		    </div>
        </div>
        <div class="col-xs-3 text-right text-uppercase grid-component_addRow">
             <span ng-show="false" ng-click="grid.appScope.vm.addContactRow()">Add Row</span>
        </div>
    </div>`;