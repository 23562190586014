import documentNameTemplate from './html/documents/documentNameTemplate.html';

export const docsDefsGrid = [
    {
        field: 'documentName',
        displayName: 'Document Name',
        cellTooltip: true,
        headerCellClass: 'grid-component_header',
        cellTemplate: documentNameTemplate
    },
    {
        field: 'documentType',
        displayName: 'Document Type',
        cellTooltip: true,
        width: '9%',
        headerCellClass: 'grid-component_header'
    },
    {
        field: 'claimID',
        displayName: 'Claim ID',
        width: '7%',
        headerCellClass: 'grid-component_header'
    },
    {
        field: 'createdDate',
        displayName: 'Created Date',
        width: '7%',
        cellFilter: "date:\'MM/dd/yyyy\'",
        headerCellClass: 'grid-component_header'
    },
    {
        field: 'description',
        displayName: 'Description',
        cellTooltip: true,
        headerCellClass: 'grid-component_header'
    },
    {
        field: 'Actions',
        displayName: '',
        width: '2%',
        headerCellClass: 'grid-component_header grid-component_header_actions',
        cellClass: 'grid-component_actions'
    }
];