angular
    .module('CareGuard')
    .factory('payeeService', payeeService);

payeeService.$inject = [
    '$http',
    '$q',
    'addressService',
    '$toastr',
];

function payeeService(
    $http,
    $q,
    addressService,
    $toastr) {

    // Api calls
    const baseRoute = `payees`;

    function searchPayees(query) {
        return $http.get(searchPayeesRoute(), { params: query });
    }

    function searchPayeesRoute() {
        return `${apiUrl}/${baseRoute}/search`;
    }

    function getPayeesByIds(filters) {
        return $http.get(`${apiUrl}/${baseRoute}`, { params: filters });
    }

    function getPayeesByIdsByBulk(filters) {
        return $http.post(`${apiUrl}/${baseRoute}/bulk`, filters);
    }

    function getPayeeById(id) {
        return $http.get(`${apiUrl}/${baseRoute}/${id}`);
    }

    function updatePayee(payee) {
        return $http.put(`${apiUrl}/${baseRoute}/${payee.id}`, payee);
    }

    function addPayee(payee) {
        return $http.post(`${apiUrl}/${baseRoute}`, payee);
    }

    function deletePayee(id) {
        return $http.delete(`${apiUrl}/${baseRoute}/${id}`);
    }

    function getPayeeAddressIdByMemberId(memberId) {
        return $http.get(`${apiUrl}/${baseRoute}/addresses/members/${memberId}`);
    }

    function getMappingIdsByPayeeAddressId(payeeAddressId) {
        return $http.get(`${apiUrl}/${baseRoute}/mapping/${payeeAddressId}`);
    }

    function getPayeesPendingReview(filters) {
        return $http.get(`${apiUrl}/${baseRoute}/pendingReview/`, { params: filters });
    }

    function getPayeeByMemberId(memberId) {
        let memberAsPayee = {
            address: {}
        };

		return getPayeeAddressIdByMemberId(memberId).then(({ data: payeeAddressId }) => {
			if (Array.isArray(payeeAddressId) && !payeeAddressId?.length) return $q.when({ data: null });

            memberAsPayee.address.payeeAddressID = payeeAddressId;

			return getMappingIdsByPayeeAddressId(payeeAddressId);
		}).then(({ data: mapping }) => {
			if (!mapping) return $q.when(null);

			const { payeeID, addressID } = mapping;
            return $q.all([
                getPayeeById(payeeID),
                addressService.getAddressById(addressID),
            ]);
		}).then(results => {
			if (!results) return $q.when(null);

			const [payee = {}, address = {}] = results;
			Object.assign(memberAsPayee, payee?.data);
			Object.assign(memberAsPayee.address, address?.data);

            return memberAsPayee;
        });
    }

    // Utilities
    function isValidPayee(payee) {
        if (!payee.taxID || !payee.taxID.match(/^[0-9]{9}$/)) {
            $toastr.show(`Tax id is required. Must contain 9 numbers.`, `warning`);
            return false;
        }

        if (payee.npiNumber && !payee.npiNumber.match(/^[0-9]{10}$/)) {
            $toastr.show(`NPI number must contain 10 numbers.`, `warning`);
            return false;
        }

        if (!payee.name || payee.name.length < 3 || payee.name.length > 128) {
            $toastr.show(`Name is required. Must contain between 3 and 128 characters.`, `warning`);
            return false;
        }

        if (payee.exBillReview && payee.garnetRateEligibility) {
            $toastr.show(`Cannot be excluded from bill review and eligible for garnet rate reductions.`, `warning`);
            return false;
        }

        if (payee.exBillReview && payee.diamondRateEligibility) {
            $toastr.show(`Payee cannot be excluded from bill review and eligible for Diamond rate reductions`, `warning`);
            return false;
        }

        if (payee.exBillReview && payee.medicarePricingEligibility) {
            $toastr.show(`Payee cannot be excluded from bill review and eligible for Medicare Pricing workflow`, `warning`);
            return false;
        }

        return true;
    }

    return {
        // Api calls
        searchPayees,
        searchPayeesRoute,
        getPayeesByIds,
        getPayeesByIdsByBulk,
        getPayeeById,
        updatePayee,
        addPayee,
        deletePayee,
        getPayeeByMemberId,
        getMappingIdsByPayeeAddressId,
        // Utilities
        isValidPayee,
        getPayeesPendingReview,
    };
}