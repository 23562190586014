/**
* @name deferredService
* @desc Deferred Service.
**/

(function () {
    'use strict';

    angular
        .module('CareGuard')
        .factory('deferredService', deferredService);

    deferredService.$inject = ['$http'];

    function deferredService($http) {
        var baseUrl = '/api/deferred/';

        var service = {
            getDeferredRequestStatus: getDeferredRequestStatus,
            getDeferredResponse: getDeferredResponse
        };

        return service;

        function getDeferredRequestStatus(requestId) {
            return $http.get(baseUrl + 'GetDeferredRequestStatus?requestId=' + requestId);
        }

        function getDeferredResponse(requestId, responseType) {
            return $http.get(baseUrl + 'GetDeferredResponse?requestId=' + requestId, { responseType: responseType });
        }
    }
})();
