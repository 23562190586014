import saveButtonTemplate from './saveButtonView.html';

angular
    .module("CareGuard")
    .directive('saveButton', saveButtonDirective);

saveButtonDirective.$inject = ['$q'];

function saveButtonDirective($q) {
    return {
        template: saveButtonTemplate,
        require: '^form',
        transclude: true,
        scope: {
            saveFn: '&',
            formSubmitting: '='
        },
        link: function (scope, element, attrs, formCtrl) {
            scope.sbCtrl = formCtrl;
            scope.submitForm = () => {
                formCtrl.$setSubmitted();

                $q.when(scope.saveFn()).then(_ => {
                    if (formCtrl.$invalid) return;

                    formCtrl.$setPristine();
                    formCtrl.$setUntouched();
                });
            }
        }
    };
}