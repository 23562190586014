import ruleDescriptionTemplate from './html/Rules/ruleDescriptionTemplate.html';
import ruleResultTemplate from './html/Rules/ruleResultTemplate.html';
import executionDateTemplate from './html/Rules/executionDateTemplate.html';
import failureReasonsTemplate from './html/Rules/failureReasonsTemplate.html';

export const ruleResultsDefsGrid = [
    {
        field: 'Description',
        width: '1%',
        displayName: null,
        headerCellClass: 'grid-component_header',
        enableSorting: false,
        cellTemplate: ruleDescriptionTemplate
    },
    {
        field: 'RuleName',
        width: '15%',
        displayName: 'Rule',
        headerCellClass: 'grid-component_header'
    },
    {
        field: 'IsPassed',
        displayName: 'Result',
        width: '15%',
        headerCellClass: 'grid-component_header',
        cellTemplate: ruleResultTemplate
    },
    {
        field: 'FailureReasons',
        displayName: 'Failure Reasons',
        cellTooltip: true,
        headerCellClass: 'grid-component_header',
        enableSorting: false,
        cellTemplate: failureReasonsTemplate
    },
    {
        field: 'ExecutionDate',
        displayName: 'Execution Date',
        width: '10%',
        headerCellClass: 'grid-component_header',
        cellTemplate: executionDateTemplate
    },
    {
        field: 'Actions',
        displayName: '',
        width: '2%',
        headerCellClass: 'grid-component_header grid-component_header_actions',
        cellClass: 'grid-component_actions',
        enableSorting: false
    }
];