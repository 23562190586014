import holdAmountsActionsColumnTemplate from './html/holdAmounts/holdAmountsActionsColumnTemplate.html';
import holdAmountsNoteColumnTemplate from './html/holdAmounts/holdAmountsNoteColumnTemplate.html';

export const holdAmountsDefsGrid = [
    {
        field: 'amount',
        displayName: 'Amount',
        width: '9%',
        headerCellClass: 'grid-component_header',
        cellFilter: 'currency'
    },
    {
        field: 'holdDate',
        displayName: 'Hold Date',
        width: '9%',
        headerCellClass: 'grid-component_header',
        cellFilter: "date:\'MM/dd/yyyy\'",
    },
    {
        field: 'createdBy',
        displayName: 'Created By',
        width: '20%',
        headerCellClass: 'grid-component_header',
        cellClass: 'text-lowercase'
    },
    {
        field: 'note',
        displayName: 'Note',
        headerCellClass: 'grid-component_header',
        cellTemplate: holdAmountsNoteColumnTemplate
    },
    {
        field: 'Actions',
        displayName: '',
        width: '2%',
        headerCellClass: 'grid-component_header grid-component_header_actions',
        cellClass: 'grid-component_actions',
        cellTemplate: holdAmountsActionsColumnTemplate
    }
];