import claimDetailDiagnosisTemplate from './diagnosisForClaimDetail.html';
import newClaimDiagnosisTemplate from './diagnosisForNewClaim.html';

angular
    .module('CareGuard')
    .directive('diagnosisForClaimDetail', diagnosisForClaimDetail)
    .directive('diagnosisForNewClaim', diagnosisForNewClaim);

function diagnosisForClaimDetail() {
    return {
        restrict: 'E',
        scope: {
            claim: '=',
            isClaimDetail: '&',
            onSave: '&'
        },
        template: claimDetailDiagnosisTemplate,
        controller: 'diagnosisController',
        controllerAs: 'vm',
        bindToController: true
    };
}

function diagnosisForNewClaim() {
    return {
        restrict: 'E',
        scope: {
            claim: '='
        },
        template: newClaimDiagnosisTemplate,
        controller: 'diagnosisController',
        controllerAs: 'vm',
        bindToController: true
    };
}