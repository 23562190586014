angular
    .module('CareGuard')
    .factory('memberStatementsService', memberStatementsService);

memberStatementsService.$inject = ['$http'];

function memberStatementsService($http) {
    // External API calls
    const baseRoute = `documents/statements`;

    function getMonthlyStatements() {
        return $http.get(`${apiUrl}/${baseRoute}/archives`);
    }

    function addOrUpdateStatementArchive(memberStatementMonthlyArchiveRequestDto) {
        return $http.post(`${apiUrl}/${baseRoute}/archives`, memberStatementMonthlyArchiveRequestDto);
    }

    function startKirkwoodMemberStatementSubmissionProcess(Id) {
        return $http.post(`${apiUrl}/${baseRoute}/start/kirkwood`, null, { params: { Id } });
    }

    // CH C# controller calls
    const baseUrl = '/api/memberStatements/';

    function downloadCustomStatement(memberId, startDate, endDate) {
        return $http({
            url: `${baseUrl}/downloadCustomStatement?memberId=${memberId}&startDate=${startDate}&endDate=${endDate}`,
            method: 'GET',
            withCredentials: true,
            transformRequest: angular.identity,
            responseType: 'arraybuffer'
        });
    }

    function generateBulkStatements({ memberIds, effectiveMonth }) {
        return $http({
            url: `${baseUrl}/generateBulkStatements/`,
            method: 'POST',
            data: {
                MemberIds: memberIds,
                EffectiveMonth: effectiveMonth
            },
            withCredentials: true,
            responseType: 'arraybuffer'
        });
    }

    return {
        // External API calls
        getMonthlyStatements,
        addOrUpdateStatementArchive,
        startKirkwoodMemberStatementSubmissionProcess,

        // CH C# controller calls
        downloadCustomStatement,
        generateBulkStatements
    };
}
