/**
* @name lifecompanyDetailController
* @desc Life Company detail Controller.
**/
(function () {
    'use strict';

    angular
        .module('CareGuard')
        .controller('lifecompanyDetailController', lifecompanyDetailController)

    /** Inject services into controller. */
    lifecompanyDetailController.$inject = ['lookupService', 'lifecompanyService'];

    /**
    * @name lifecompanyDetailController.
    * @desc Life Company detail Controller.
    * @param {object} lookupService - lookup service.
    * @param {object} lifecompanyService - company service.
    */
    function lifecompanyDetailController(lookupService, lifecompanyService) {
        var vm = this;

        // variables
        vm.company = undefined;       
        vm.companyCopy = undefined;
        vm.types = undefined;
        vm.states = undefined;
        vm.isEditing = false;

        // functions
        vm.add = add;
        vm.edit = edit;
        vm.cancel = cancel;
        vm.save = save;

        init();

        /* 
        * @name init
        * @desc initialize module
        */
        function init() {
            vm.types = lookupService.getByCategory('Company Type');
            vm.states = lookupService.getKeysByCategory('State');
            update();          
        };

        /* 
        * @name update
        * @desc update selected company.
        */
        function update(comp) {
            if (!comp) {
                comp = lifecompanyService.getSelected(update);                                        
            }           
            vm.company = comp;
            vm.isEditing = false;
        };
        
        /* 
        * @name add
        * @desc show form to add new company. 
        */
        function add(form) {
            form.setPristine();
            vm.companyCopy = angular.copy(vm.company, {});
            lifecompanyService.fireNewCallback({ Index: -1, IsNew: false });
            vm.company = { IsNew: true };
            vm.isEditing = true;
        };

        /* 
        * @name edit
        * @desc show form to edit existing company. 
        */
        function edit(form) {
            form.setPristine();
            vm.companyCopy = angular.copy(vm.company, {});
            vm.company.IsNew = false;
            vm.isEditing = true;
        };

        /* 
        * @name cancel
        * @desc cancel add/update and hide the modal. 
        */
        function cancel() {
            update(vm.companyCopy);
            lifecompanyService.fireNewCallback(vm.companyCopy);
        };

        /* 
        * @name save
        * @desc save (add/update) and hide the modal. 
        */
        function save() {
            if (!vm.company) return;
            
            // commit to db
            lifecompanyService.save(vm.company).then(function (result) {
                result.IsNew = vm.company.IsNew;
                result.Index = vm.company.Index;
                vm.company = result;                
                lifecompanyService.fireNewCallback(result);
                lifecompanyService.setSelected(vm.company);
            });
        };
    };
})();