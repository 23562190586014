/**
* @name xsrfService
* @desc Manages XSRF token
**/
(function () {
    'use strict';

    angular
        .module('CareGuard')
        .factory('xsrfService', xsrfService);

    /** Inject services into service. */
    xsrfService.$inject = ['$http', '$q', 'webStorageService'];

    /**
    * @name xsrfService.
    * @desc XSRF token service.
    */
    function xsrfService($http, $q, webStorage) {

        const baseUrl = '/api/account/';
        const xsrfTokenKey = 'auth.xsrf';

        var self = this;
        
        this.createXsrfToken = function () {

            return $http.get(baseUrl + 'user/xsrf').then(function(r) {
                processTokenReponse(r);
            });
        };

        this.configureHeaders = configureHeaders;

        this.clear = function() {
            processTokenReponse({ data:{} });
        };

        function processTokenReponse(r) {
           setXsrfToken(r.data);
        }

        function setXsrfToken(xsrfToken) {
            webStorage.set(xsrfTokenKey, xsrfToken);
        }

        function configureHeaders(headers) {
            headers['X-XSRF-Token'] = webStorage.get(xsrfTokenKey);
        }

        return this;
    }
})();