import beneficiaryTypeTemplate from './html/beneficiaryTypeTemplate.html';
import beneficiaryStateTemplate from './html/beneficiaryStateTemplate.html';
import zipTemplate from './html/zipTemplate.html';
import phoneTemplate from './html/phoneTemplate.html';
import reversionaryTemplate from './html/reversionaryTemplate.html';
import deleteBeneficiaryTemplate from './html/deleteBeneficiaryTemplate.html';

export const beneficiaryDefsGrid = [
    {
        field: 'BeneficiaryType',
        displayName: 'Type',
        width: '9%',
        headerCellClass: 'grid-component_header',
        headerTooltip: false,
        cellTooltip: true,
        enableCellEdit: false,
        allowCellFocus: false,
        enableCellEditOnFocus: false,
        cellTemplate: beneficiaryTypeTemplate
    },
    {
        field: 'FirmName',
        displayName: 'Firm Name',
        cellClass: 'showExtendedContent',
        headerCellClass: 'grid-component_header',
        headerTooltip: false,
        cellTooltip: true,
        required: true
    },
    {
        field: 'FirstName',
        displayName: 'First Name',
        headerCellClass: 'grid-component_header',
        headerTooltip: false,
        cellTooltip: true,
        required: true
    },
    {
        field: 'MiddleInitial',
        displayName: 'MI',
        width: '4%',
        headerCellClass: 'grid-component_header',
        headerTooltip: false,
        cellTooltip: true
    },
    {
        field: 'LastName',
        displayName: 'Last Name',
        headerCellClass: 'grid-component_header',
        headerTooltip: false,
        cellTooltip: true,
        required: true
    },
    {
        field: 'Address1',
        displayName: 'Address 1',
        headerCellClass: 'grid-component_header',
        headerTooltip: false,
        cellTooltip: true
    },
    {
        field: 'Address2',
        displayName: 'Address 2',
        headerCellClass: 'grid-component_header',
        headerTooltip: false,
        cellTooltip: true
    },
    {
        field: 'City',
        displayName: 'City',
        headerCellClass: 'grid-component_header',
        headerTooltip: false,
        cellTooltip: true
    },
    {
        field: 'State',
        displayName: 'State',
        enableCellEdit: false,
        headerCellClass: 'grid-component_header',
        headerTooltip: false,
        cellTooltip: true,
        allowCellFocus: false,
        cellTemplate: beneficiaryStateTemplate
    },
    {
        field: 'Zip',
        displayName: 'Zip',
        headerCellClass: 'grid-component_header',
        enableCellEdit: true,
        headerTooltip: false,
        editableCellTemplate: zipTemplate,
        cellTooltip: true
    },
    {
        field: 'Telephone',
        displayName: 'Phone',
        headerCellClass: 'grid-component_header',
        headerTooltip: false,
        cellFilter: 'phoneNumberDashes: row.entity.Telephone',
        editableCellTemplate: phoneTemplate,
        cellTooltip: true
    },
    {
        field: 'ReversionaryPercent',
        displayName: '%Reversionary',
        headerCellClass: 'grid-component_header',
        headerTooltip: false,
        cellFilter: 'percent: row.entity.ReversionaryPercent',
        editableCellTemplate: reversionaryTemplate,
        cellTooltip: true
    },
    {
        field: 'Notes',
        displayName: 'Notes',
        headerCellClass: 'grid-component_header',
        headerTooltip: false,
        cellTooltip: true
    },
    {
        field: 'Actions',
        displayName: '',
        width: '3%',
        enableCellEdit: false,
        headerCellClass: 'grid-component_header grid-component_header_actions',
        cellClass: 'grid-component_actions',
        cellTemplate: deleteBeneficiaryTemplate
    }
];