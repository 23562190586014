import flagsTemplate from './flagsView.html';

angular
    .module('CareGuard')
    .directive('flagsComponent', flags);

function flags() {
    return {
        scope: {
            flags: '='
        },
        template: flagsTemplate
    };
}