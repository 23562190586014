/**
* @name documentService
* @desc Document Service.
**/
(function () {
    'use strict';

    angular
        .module('CareGuard')
        .factory('documentService', documentService);

    /** Inject services into service. */
    documentService.$inject = ['$http'];

    /**
    * @name documentService.
    * @desc document Service.
    */
    function documentService($http) {
        var baseUrl = '/api/documents/';
        var selected = undefined;
        var selectedFile = undefined;
        var selectedCallback = undefined;
        var selectedFileCallback = undefined;
        var addNewCallback = undefined;
        var removeCallback = undefined;

        var service = {
            setSelected: setSelected,
            getSelected: getSelected,
            setSelectedFile: setSelectedFile,
            getSelectedFile: getSelectedFile,
            setNewCallback: setNewCallback,
            setRemoveCallback: setRemoveCallback,
            fireNewCallback: fireNewCallback,
            fireRemoveCallback: fireRemoveCallback,
            get: get,
            //getByDocument: getByDocument,
            save: save,
            remove: remove,
            upload: upload
        };

        return service;

        /**
        * @name setSelected.
        * @desc set current selected document.
        * @param {object} document - selected document.
        */
        function setSelected(document) {
            selected = document;
            if (selectedCallback)
                selectedCallback(selected);
        };

        /**
        * @name setSelectedFile.
        * @desc set current selected fileupload.
        * @param {object} document - selected fileupload.
        */
        function setSelectedFile(fileupload) {
            selectedFile = fileupload;
            if (selectedFileCallback) {
                selectedFileCallback(selectedFile);
            }
        };

        /**
        * @name getSelectedFile.
        * @desc get current selected document.
        * @param {function} func - call function when selected document is changed.
        */
        function getSelectedFile(func) {
            selectedFileCallback = func;
            return selectedFile;
        };

        /**
      * @name getSelected.
      * @desc get current selected document.
      * @param {function} func - call function when selected document is changed.
      */
        function getSelected(func) {
            selectedCallback = func;
            return selected;
        };

        /**
        * @name setNewCallback.
        * @desc set callback when new document is added.
        * @param {function} func - call function when selected document is changed.
        */
        function setNewCallback(func) {
            addNewCallback = func;
        };

        /**
        * @name setRemoveCallback.
        * @desc set callback when new document is removed.
        * @param {function} func - call function when selected document is removed.
        */
        function setRemoveCallback(func) {
            removeCallback = func;
        };

        /**
        * @name fireNewCallback.
        * @desc fires callback when new document is added.
        * @param {object} document - new/changed document.
        */
        function fireNewCallback(document) {
            if (addNewCallback) {
                addNewCallback(document);
            }
        };

        /**
        * @name fireRemoveCallback.
        * @desc fires callback when new document is removed.
        * @param {object} document - removed document.
        */
        function fireRemoveCallback(document) {
            if (removeCallback)
                removeCallback(document);
        };

        /**
        * @name get.
        * @desc get list of documents.
        * @param {object} params - search parameter.
        */
        function get(params, caseid) {
            return $http.get(baseUrl + 'get/' + caseid, { params: params });
        };

        ///**
        //* @name getByCompany.
        //* @desc get list of documents for a company.
        //* @param {object} params - search parameter.
        //*/
        function getByDocument(documentId, params) {
            return $http.get(baseUrl + 'get/' + documentId, { params: params });
        };

        /**
        * @name save.
        * @desc save a document.
        * @param {object} document - document to be saved.
        */
        function save(document) {
            return $http.post(baseUrl + 'save/', document);
        };

        /**
        * @name remove.
        * @desc remove a document given Id.
        * @param {object} document - document to be removed.
        */
        function remove(document) {
            return $http.post(baseUrl + 'remove', document);
        };

        /**
        * @name remove.
        * @desc remove a document given Id.
        * @param {object} document - document to be removed.
        */
        function upload(data) {
            return $http.post(baseUrl + 'upload', data);
        };
    }
})();