angular
    .module('CareGuard')
    .controller('fundsExhaustionController', fundsExhaustion);

fundsExhaustion.$inject = [
    'fundsExhaustionService',
    'utilService'];

function fundsExhaustion(
    fundsExhaustionService,
    utilService) {
    let vm = this;

    vm.templates = {
        list: [
            { Name: 'MSA Structure - Approaching', Value: 'msa-structure-funds-approaching-depletion-letter-template.cshtml' },
            { Name: 'MSA Structure - Temporary', Value: 'msa-structure-funds-temporary-depletion-letter-template.cshtml' },

            { Name: 'MSA Lump Sum - Approaching', Value: 'msa-lumpsum-funds-approaching-depletion-letter-template.cshtml' },
            { Name: 'MSA Lump Sum - Permanent', Value: 'msa-lumpsum-funds-permanent-depletion-letter-template.cshtml' },

            { Name: 'MCP Structure - Approaching', Value: 'mcp-structure-funds-approaching-depletion-letter-template.cshtml' },
            { Name: 'MCP Structure - Temporary', Value: 'mcp-structure-funds-temporary-depletion-letter-template.cshtml' },

            { Name: 'MCP Lump Sum - Approaching', Value: 'mcp-lumpsum-funds-approaching-depletion-letter-template.cshtml' },
            { Name: 'MCP Lump Sum - Permanent', Value: 'mcp-lumpsum-funds-permanent-depletion-letter-template.cshtml' },

            { Name: 'CMS - Temporary', Value: 'cms-temporary-depletion-letter-template.cshtml' },
            { Name: 'CMS - Permanent', Value: 'cms-permanent-depletion-letter-template.cshtml' },
            { Name: 'CMS - Account Closure - Deceased', Value: 'cms-account-closure-deceased-template.cshtml' },
            { Name: 'CMS - Account Closure - Self Administer', Value: 'cms-account-closure-self-administer-template.cshtml' },

            { Name: 'Funds Replenished', Value: 'funds-replenished-letter-template.cshtml' }
           
        ]
    };

    vm.isDataLoading = false;
    vm.generateFundsExhaustionLetter = generateFundsExhaustionLetter;
    vm.generateFundsExhaustionLetters = generateFundsExhaustionLetters;
    vm.generateLetters = generateLetters;

    function generateLetters(input, templateName) {
        var memberNumberList = [];

        if (input.includes(',')) {
            //multiple
            memberNumberList = input.split(',');
            generateFundsExhaustionLetters(memberNumberList, templateName);
        }
        else {
            //single
            generateFundsExhaustionLetter(input, templateName);
        }
    }

    function generateFundsExhaustionLetter(memberNumber, templateName) {
        vm.isDataLoading = true;
        fundsExhaustionService.generateFundsExhaustionLetter(memberNumber, templateName)
            .then(response => utilService.processResponse({ response }))
            .finally(_ => vm.isDataLoading = false, alert('FDL is being generated!'));
    }

    function generateFundsExhaustionLetters(memberNumbers, templateName) {
        vm.isDataLoading = true;
        fundsExhaustionService.generateFundsExhaustionLetters(memberNumbers, templateName)
            .then(response => utilService.processResponse({ response }))
            .finally(_ => vm.isDataLoading = false, alert('FDLs are being generated!'));
    }
}