/**
* @name locationController
* @desc Location Controller.
**/
(function () {
    'use strict';

    angular
        .module('CareGuard')
        .controller('locationController', locationController)

    /** Inject services into controller. */
    locationController.$inject = ['LxDialogService', 'lookupService', 'companyService', 'locationService', 'userData'];

    /**
    * @name locationController.
    * @desc Location Controller.
    * @param {object} companyService - company service.
    * @param {object} locationService - location service.
    */
    function locationController(LxDialogService, lookupService, companyService, locationService, userData) {
        var vm = this;

        var dialogId = 'dlgConfirm';
        vm.company = undefined;
        vm.state = undefined;
        vm.states = lookupService.getByCategory('State');;
        vm.brokers = undefined;
        vm.broker = undefined;
        vm.locations = {
            selected: undefined,
            searchBy: undefined,
            searchByAttribute: undefined,
            currentPage: 1,
            pageSize: 5,
            itemCount: 1,
            items: []
        };
        vm.clear = clear;
        vm.get = get;
        vm.search = search;
        vm.add = add;
        vm.edit = edit;
        vm.cancel = cancel;
        vm.save = save;
        vm.confirmRemove = confirmRemove;
        vm.remove = remove;
        vm.selectBroker = selectBroker;

        init();

        /* 
        * @name init
        * @desc initialize module
        */
        function init() {
            vm.brokers = userData;
            vm.company = companyService.getSelected();
            get();
        };
        
        /* 
        * @name clear
        * @desc Clear search bar and refresh list.
        */
        function clear() {
            vm.locations.currentPage = 1;
            vm.locations.searchByAttribute = undefined;
            vm.locations.searchBy = undefined;
            get();
        };

        /* 
        * @name search
        * @desc Search locations using the input string.
        */
        function search(filterBy) {
            vm.locations.currentPage = 1;
            vm.locations.searchByAttribute = filterBy;
            get();
        };

        /* 
        * @name get
        * @desc Get a list of locations to display. 
        */
        function get() {
            var params = {
                pageSize: vm.locations.pageSize,
                pageNum: vm.locations.currentPage,
                sort: 'BusinessUnit',
                sortDir: 'asc',
                filter: {}
            };

            // add filter if exists
            if (vm.locations.searchByAttribute) {
                params.filter[vm.locations.searchByAttribute] = vm.locations.searchBy;
            }

            // refresh results from db
            locationService.get(vm.company.Id, params).then(function (data) {
                vm.locations.items = data.Items;
                vm.locations.itemCount = data.ItemCount;
            });
        };

        /* 
        * @name add
        * @desc add a new location. 
        */
        function add() {
            vm.isEditing = true;
            vm.locations.selected = {
                IsNew: true,
                IsActive: true
            };
            vm.state = undefined;
        };

        /* 
        * @name edit
        * @desc edit existing location. 
        * @param {object} loc - location.
        * @param {int} index - location index within locations array.
        */
        function edit(loc, index) {
            vm.isEditing = true;
            vm.locations.selected = angular.copy(loc);
            vm.state = {
                Key: vm.locations.selected.State
            };
            vm.locations.selected.Index = index;
            updateBroker(loc.BrokerId);
        };

        /* 
        * @name cancel
        * @desc cancel adding/editing location. 
        * @param {object} form - entry form.
        */
        function cancel() {
            vm.isEditing = false;
        };
        
        /* 
        * @name save
        * @desc save location. 
        */
        function save(form) {
            if (form && form.$invalid) {
                $toastr.show('Please fix any errors before continuing.', 'error');
                return;
            };
            vm.locations.selected.CompanyId = vm.company.Id;
            vm.locations.selected.CompanyName = vm.company.Name;
            vm.locations.selected.State = vm.state.Key;

            locationService.save(vm.locations.selected).then(function (result) {
                var index = vm.locations.selected.Index || 0;

                if (vm.locations.selected.IsNew) {
                    vm.locations.items.unshift(result);
                }
                else {
                    vm.locations.items[index] = result;
                }
                result.Index = index;
                vm.locations.selected = result;
                vm.isEditing = false;
            });
        };        

        /* 
        * @name confirmRemove
        * @desc show delete confirmation dialog.
        */
        function confirmRemove() {
            LxDialogService.open(dialogId);
        };

        /* 
        * @name remove
        * @desc remove location.
        */
        function remove() {
            locationService.remove(vm.locations.selected).then(function (result) {
                if (result == true) {
                    vm.locations.items.splice(vm.locations.selected.Index, 1);
                    vm.isEditing = false;
                    LxDialogService.close(dialogId);
                }
            });
        };

        /* 
        * @name updateBroker
        * @desc update broker given Id or default to company broker. 
        * @param {string} brokerId - broker id.
        */
        function updateBroker(brokerId) {
            vm.broker = Enumerable.from(vm.brokers).where('$.Id == "' + brokerId + '"').firstOrDefault(undefined);
        };

        /* 
        * @name selectBroker
        * @desc select broker. 
        * @param {object} val - selected object.
        */
        function selectBroker(val) {
            if (!val) {
                vm.locations.selected.BrokerId = undefined;
                return;
            }

            vm.locations.selected.BrokerId = val.originalObject.Id;
        };
    };
})();