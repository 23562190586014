angular
    .module('CareGuard')
    .controller('1099Controller', _1099);

_1099.$inject = ['1099Service'];

function _1099(
    _1099Service) {

    let vm = this;

    vm.isDataLoading = false;
    vm._1099Reports = [];
    
    (() => {
        vm.isDataLoading = true;
        _1099Service.get1099Archives().then(response => {
            vm._1099Reports = response.data;
        })
        .finally(() => vm.isDataLoading = false);
    })();
}