/**
* @name chronographService
* @desc chronograph Service.
**/
(function () {
    'use strict';

    angular
        .module('CareGuard')
        .factory('chronographService', chronographService);

    /** Inject services into service. */
    chronographService.$inject = ['$http', '$q'];

    /**
    * @name chronographService.
    * @desc chronograph Service.
    */
    function chronographService($http, $q) {
        var baseUrl = '/api/chronograph/';
        var service = {
            get: get,
            getById: getById,
            getQuote: getQuote,
            save: save,
            generate: generate,
            checkClaimNumber: checkClaimNumber
        };
        
        return service;

        /**
        * @name get.
        * @desc search cases.
        * @param {object} params - search params.
        */
        function get(params) {
            return $http.get(baseUrl + 'get', { params: params });
        };

        /**
        * @name getByID.
        * @desc get case given Id.
        * @param {object} ) Id - Id of case.
        */
        function getById(Id) {
            return $http.get(baseUrl + 'getByID/' + Id);
        };
        
        /**
        * @name getQuote.
        * @desc get a new quote.
        * @param {object} caseInfo - case to be saved.
        */
        function getQuote(caseInfo) {
            return $http.post(baseUrl + 'quote', caseInfo);
        };

        /**
        * @name save.
        * @desc save case data.
        * @param {object} caseInfo - case to be saved.
        */
        function save(caseInfo) {
            return $http.post(baseUrl + 'save', caseInfo);
        };

        /**
        * @name generate.
        * @desc generate smart Quote.
        * @param {object} caseInfo - case info to be used in the quote.
        */
        function generate(caseInfo) {
          return $http.post(baseUrl + 'generate', caseInfo);
        };

        /**
        * @name checkClaimNumber.
        * @desc check for duplicate claim number.
        * @param {object} data - claim number & company Id.
        */
        function checkClaimNumber(data) {
            return $http.post(baseUrl + 'check', data);
        };
    }
})();