angular
    .module('CareGuard')
    .directive('toggleEmptyClass', toggleEmptyClass);

toggleEmptyClass.$inject = [];

function toggleEmptyClass() {
    return {
        restrict: 'A',
        require: 'ngModel',
        link: function($scope, elm, attrs, ngModelController) {

            ngModelController.$parsers.unshift(function(value) {
                const addOrRemoveClass = value != null && value !== '';
                $(elm).toggleClass('not-empty', addOrRemoveClass);

                return value;
            });

            ngModelController.$formatters.push(function(data) {
                const addOrRemoveClass = data != null && data !== '';
                $(elm).toggleClass('not-empty', addOrRemoveClass);

                return data;
            });
        }
    }
}