angular
    .module('CareGuard')
    .controller('reportsController', reportsController);

    reportsController.$inject = [
        '$scope',
        '$location',
        '$state',
        '$timeout',
        '$toastr',
        'reportsService',
        'lookupService',
        'accountService',
        'utilService'
    ];

    function reportsController(
        $scope,
        $location,
        $state,
        $timeout,
        $toastr,
        reportsService,
        lookupService,
        accountService,
        utilService
    ) {

    let vm = this;

    vm.results = [];
    vm.reportFields = [];
    vm.lookupdata = {};
    vm.startdate = null;
    vm.enddate = null;
    vm.today = null;
    vm.firstofthemonth = null;
    vm.firstoftheyear = null;

    vm.filterData = {
        //default
        TotalPages: 1,
        TotalRows: null,
        PageSize: 20,
        PageIndex: 1,
        SortColumn: null,
        SortOrder: 'ASC',

        //specific to reports
        ReportType: null,
        StartDate: null,
        EndDate: null
    };

    vm.pageSizes = {
        data: [
            { Name: 5, Value: 5 },
            { Name: 10, Value: 10 },
            { Name: 20, Value: 20 },
            { Name: 50, Value: 50 },
            { Name: 100, Value: 100 },
            { Name: 1000, Value: 1000 }
        ]
    };

    vm.changeSort = changeSort;
    vm.resetFilterInfo = resetFilterInfo;
    vm.clearFilterInfo = clearFilterInfo;
    vm.refresh = refresh;
    vm.navigate = navigate;

    vm.filterResults = filterResults; //this is used as a dynamic filter object for a given report
    vm.exportResults = exportResults;
    vm.selectReport = selectReport;

    vm.isVisible = isVisible;
    vm.hasFilterCriteria = hasFilterCriteria; //this holds the report list for now
    vm.hasControl = hasControl;
    vm.goBack = goBack;

    vm.isReportEnabled = isReportEnabled;

    const disabledReports = [
        'Referral Tracker',
        'Member Onboarding',
        'Account Status',
        'Referral Dashboard',
        'Pace Of Spending'
    ];

    init();
    function init() {
        //get default date range
        vm.today = new Date();

        var dd = vm.today.getDate();
        var mm = vm.today.getMonth() + 1; //January is 0!
        var yyyy = vm.today.getFullYear();

        if (dd < 10) {
          dd = '0' + dd;
        }

        if (mm < 10) {
          mm = '0' + mm;
        }

        vm.today = mm + '-' + dd + '-' + yyyy;
        vm.firstofthemonth = mm + '-01-' + yyyy;
        vm.firstoftheyear = '01-01-' + yyyy;

        lookupService.getLookUp("reportType").then(function (resultset) {
            vm.lookupdata.reporttypes = resultset.Data;
            resetFilterInfo();
        });
    }


    function filterResults() {
        const tempReportType = vm.filterData.ReportType;

        let tempSortCol = vm.filterData.SortColumn;

        if (!tempReportType) {
            alert("Invalid Report Type");
            return;
        }

        if (!isReportEnabled(tempReportType)) {
            return;
        }

        if (!tempSortCol) {
            tempSortCol = null;
        }

        reportsService.filterResults(
            vm.filterData.ReportType,
            vm.filterData.StartDate || '01-01-0001',
            vm.filterData.EndDate || '01-01-0001',
            vm.filterData.PageIndex,
            vm.filterData.PageSize,
            tempSortCol,
            vm.filterData.SortOrder
        ).then(function (result) {
            switch (vm.filterData.ReportType) {
                case 'RX Claims':
                    { vm.results = result.Data.RXClaims; } break;
                case 'Stuck In Ready For Bill Review - WC':
                    { vm.results = result.Data.StuckInReadyForBillReviewWC; } break;
                case 'Referral Tracker':
                    { vm.results = result.Data.ReferralTracker; } break;
                case 'Referral Dashboard':
                    { vm.results = result.Data.ReferralDashboard; } break;
                //case 'Referral Dashboard': //tll - commented this out because it was labelled red for duplicate case label
                //    { vm.results = result.Data.ReferralDashboard.FullyFundedMember; } break;
                case 'Client Summary- AIG':
                    { vm.results = result.Data.ClientSummaryAIG; } break;
                case 'Client Summary- Electric Insurance':
                    { vm.results = result.Data.ClientSummaryElectricInsurance; } break;
                case 'Client Summary- Liberty Mutual':
                    { vm.results = result.Data.ClientSummaryLibertyMutual; } break;
                case 'Client Summary- State Fund':
                    { vm.results = result.Data.ClientSummaryStateFund; } break;
                case 'Inquiries Tracker':
                    { vm.results = result.Data.InquiriesTracker; } break;
                case 'Bill Status':
                    { vm.results = result.Data.BillStatus; } break;
                case 'Call Tracker':
                    { vm.results = result.Data.CallTracker; } break;
                case 'PBM and DME Providers':
                    { vm.results = result.Data.PBMDMEProviders; } break;
                case 'DME FollowUp':
                    { vm.results = result.Data.DMEFollowUp; } break;
                case 'Member Issues':
                    { vm.results = result.Data.MemberIssues; } break;
                case 'Fee Payments':
                    { vm.results = result.Data.FeePayments; } break;
                case 'Special Handling':
                    { vm.results = result.Data.SpecialHandling; } break;
                case 'Member Onboarding':
                    { vm.results = result.Data.MemberOnboarding; } break;
                case 'Member Banking':
                    { vm.results = result.Data.MemberBanking; } break;
                case 'Bank Balances':
                    { vm.results = result.Data.BankBalances; } break;
                default:
                { vm.results = result.Data; } break;
            }

            try {
                vm.filterData.TotalRows = vm.results[0].TotalRows;
                vm.filterData.TotalPages = Math.ceil(vm.filterData.TotalRows / vm.filterData.PageSize);
            }
            catch (ex) {
                vm.filterData.TotalRows = 0;
                vm.filterData.TotalPages = 1;
            }

        });
    }

    function changeSort(column) {
        if (vm.filterData.SortColumn === column) {
          vm.filterData.SortOrder = vm.filterData.SortOrder === 'ASC' ? 'DESC' : 'ASC';
        }
        else {
            vm.filterData.SortColumn = column;
            vm.filterData.SortOrder = 'ASC';
        }

        filterResults();
    }

    //refreshes or changes the page of data
    function refresh(page) {
        if (page === null || page > 0 && page <= vm.filterData.TotalPages) {
            vm.filterData.PageIndex = vm.filterData.TotalRows === 0 || page === null ? 1 : page;
            filterResults();
        }
    }

    //clears the filter data
    function clearFilterInfo() {
        vm.filterData.StartDate = null;
        vm.filterData.EndDate = null;
    }

    //resets the filter data
    function resetFilterInfo() {
        switch (vm.filterData.ReportType) {
            case 'RX Claims': {
                    vm.filterData.StartDate = vm.firstoftheyear;
                    vm.filterData.EndDate = vm.today;
            }
                break;
            case 'Member Onboarding': {
                vm.filterData.StartDate = vm.firstoftheyear;
                vm.filterData.EndDate = vm.today;
            }
            break;
            default: {
                vm.filterData.StartDate = '01-01-1900';
                vm.filterData.EndDate = vm.today;
            }
            break;
        }
    }

    function navigate(state, params) {
        $timeout(function () {
            $state.go(state, params);
        }, 0);
    }

    function exportResults() {
        if (!vm.filterData) return;

        const tempReportType = vm.filterData.ReportType;
        let tempSDate = vm.filterData.StartDate;
        let tempEDate = vm.filterData.EndDate;

        if (!tempReportType) {
            $toastr.show("Invalid Report Type", 'warning');
            return;
        }

        if (!tempSDate) {
            tempSDate = '01-01-0001';
        }

        if (!tempEDate) {
            tempEDate = '01-01-0001';
        }

        const exportOptions = {
            method: 'GET',
            url: "/api/reports/exportResults/" + tempReportType + '/' + tempSDate + '/' + tempEDate
        };

        utilService.download(exportOptions);
    }

    //for returning back to previous page
    function goBack() {
        location.reload();
    }

    function selectReport(selection) {
        vm.filterData.ReportType = selection;

        reportsService.getReportFields(selection).then(function (result) {
            vm.reportFields = result.Data;
            filterResults();
        });
    }

    function isVisible(controlName) {

        if (controlName === 'noReport' && !vm.filterData.ReportType || vm.reportFields.length && hasControl(controlName)) {
            //no report selected or report with no filter options is selected
            return true;

        } else if (controlName === 'noReport') {
            //report has been selected
            return false;

        } else {
            return false;
        }

    }

    function hasFilterCriteria() {
        switch (vm.filterData.ReportType) {
            case 'RX Claims': {
                return true;
            }
            //break;

            case 'Member Onboarding': {
                return true;
            }
            //break;

            default: {
                return false;
            }
            //break;
        }
    }

    //gets control/field list based on report type
    function hasControl(control) {
        for (let i = 0; i < vm.reportFields.length; i++) {
            if (control === vm.reportFields[i]) {
                return true;
            }
        }

        return false;
    }

        function isReportEnabled(reportType) {
            return disabledReports.indexOf(reportType) === -1;
        }

}