export const commonGridOptions = {
	paginationPageSizes: [10, 25, 50, 100, 1000, 10000],
	paginationPageSize: 50,
	enableHorizontalScrollbar: 0,
	enableVerticalScrollbar: 2,
	enablePaging: true,
	multiSelect: false,
	showColumnMenu: false,
	enableFiltering: false,
	enableRowSelection: false,
	enableColumnMenus: false
};



export function defaultGridOptions(options) {
	return Object.assign({}, commonGridOptions, options);
}