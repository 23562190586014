(function (root, factory) {
    'use strict';
    root = window;

    root['angular-flatpickr'] = factory(root.angular, root.flatpickr);
}(this, function (angular, flatpickr) {

    'use strict';
    var ngFlatpickr = angular.module('angular-flatpickr', []);
    ngFlatpickr.directive('ngFlatpickr', [function () {
        return {
            require: 'ngModel',
            restrict: 'A',
            scope: {
                fpOpts: '&',
                fpOnSetup: '&'
            },
            link: function (scope, element, attrs, ngModel) {
                var vp;

                if (!flatpickr) {
                    return console.warn('Unable to find any flatpickr installation');
                }

                if (flatpickr) {
                    vp = new flatpickr(element[0], scope.fpOpts());
                } else if (FlatpickrInstance) {
                    vp = new FlatpickrInstance(element[0], scope.fpOpts());
                }

                if (scope.fpOnSetup) {
                    scope.fpOnSetup({
                        fpItem: vp
                    });

                    if (vp.config.defaultDate && !angular.equals({}, vp.config.defaultDate)) {
                        ngModel.$setViewValue(vp.config.defaultDate.join(' to '));
                    }
                }

                // destroy the flatpickr instance when the dom element is removed
                element.on('$destroy', function () {
                    vp.destroy();
                });
            }
        };
    }]);

    return ngFlatpickr;
}));
