/**
* @name chronovoInputMask
* @desc input Mask wrapper Directive.
* @usage <button chronovo-input-mask="[0-9]">Home</button>
* http://tech.chitgoks.com/2015/01/17/how-to-use-jquery-input-mask-with-angular-js-directive-to-mask-date/
**/
(function () {
    'use strict';

    angular
        .module('CareGuard')
        .directive('chronovoInputMask', chronovoInputMask);
    
    /**
    * @name chronovoInputMask.
    * @desc input Mask wrapper Directive.
    */
    function chronovoInputMask() {
        var directive = {
            restrict: "A",
            require: '^ngModel',
            link: postLink
        };

        return directive;

        function postLink($scope, $element, $attrs, $controller) {
            // defaults
            var options = {
                placeholder: $attrs.placeholder,
                autoUnmask: true,
                clearIncomplete: false,
                showMaskOnHover: false,
                oncomplete: complete,
                onincomplete: clear,
                oncleared: clear,
                rightAlign: false,
                clearMaskOnLostFocus: true,
                digitsOptional: true
            };

            // support for data- attributes
            if ($attrs.autoUnmask) {
                options.autoUnmask = $scope.$eval($attrs.autoUnmask);
            };

            if ($attrs.regex) {
                options.regex = $attrs.regex;
            };

            // overwrite default options with user defined options
            if ($attrs.options) {
                angular.extend(options, $scope.$eval($attrs.options));
            };
            
            // check for the validity of the mask when model value is set
            //$controller.$formatters.unshift(function (val) {
            //    //if ($controller.$viewValue != val) {
            //        //$controller.$setViewValue(val);
            //    //};
            //   // $controller.$render();
            //    console.log('formatters', $controller.$name, val, $controller.$viewValue, $element.val());
            //    //validate(val);
            //    return val;
            //});

            // clear textbox, reset mask
            function clear() {
                $scope.$apply(function () {
                    $controller.$setViewValue('');
                    validate();
                });
            };

            // set field to valid when mask is completed
            function complete() {
                $scope.$apply(function () {
                    var val = $element.val();
                    if ($controller.$viewValue != val) {
                        $controller.$setViewValue(val);
                    };
                    validate(val);
                });
            };

            // validates the mask
            function validate(val) {
                val = val == undefined ? $element.val() : val;
                var isComplete = $element.inputmask("isComplete");
                
                if (isComplete) {
                    $controller.$setValidity('mask', true);
                }
                else if ($attrs.required && !isComplete) {
                    $controller.$setValidity('mask', false);
                }
                else if (!isComplete && (val || val.length)) {
                    $controller.$setValidity('mask', false);
                } else {
                    $controller.$setValidity('mask', true);
                };

                // validate date before 
                if ($attrs.dateBefore) {
                    var db = moment($attrs.dateBefore, 'MM/DD/YYYY');
                    $controller.$setValidity('date-before', moment(val, 'MM/DD/YYYY').isBefore(db));
                };

                // validate date after 
                if ($attrs.dateAfter) {
                    var da = moment($attrs.dateAfter, 'MM/DD/YYYY');
                    $controller.$setValidity('date-after', moment(val, 'MM/DD/YYYY').isAfter(da));
                };
            };

            $element.inputmask($attrs.chronovoInputMask, options);
        };
    };
})();