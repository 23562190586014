/**
* @name diaryController
* @desc Contains logic for diary page.
**/
(function () {
    'use strict';

    angular
        .module('CareGuard')
        .controller('diaryController', diaryController);

    /** Inject services into controller. */
    diaryController.$inject = ['$scope', '$location', '$state', '$stateParams', 'LxDialogService', 'lookupService', 'layoutService', 'utilService', 'diaryService', 'chronographService', 'accountService'];

    /**
    * @name chronographController.
    * @desc chronograph Controller.
    * @param {object} caseData - case information.
    */
    function diaryController($scope, $location, $state, $stateParams, LxDialogService, lookupService, layoutService, utilService, diaryService, chronographService, accountService) {
        var vm = this;

        // variables
        var dlgDiary = 'dlgDiary';
        var dlgConfirm = 'dlgConfirm';
        vm.diary = undefined;
        vm.caseId = undefined;
        vm.case = undefined;
        vm.lookupdata = undefined;
        vm.searchBy = undefined;
        vm.searchByAttribute = undefined;
        vm.showHeader = true;
        vm.columns = undefined;
        vm.events = undefined;
        vm.config = undefined;

        // public functions
        vm.search = search;
        vm.clear = clear;
        vm.close = close;
        vm.save = save;
        vm.add = add;
        vm.archive = archive;

        // initialize
        init();

        /* 
        * @name init
        * @desc initialize module
        */
        function init() {
            // hide header if its the case diary screen
            // get case information and set Case Name in header
            if ($location.path().indexOf('case') > -1) {
                vm.showHeader = false;
                vm.caseId = $stateParams.Id;
                chronographService.getById(vm.caseId).then(function (result) {
                    vm.case = result;
                    layoutService.setSelected({ Name: vm.case.CaseName });
                });
            };

            // set callback
            diaryService.setFunc({ show: setData });

            vm.lookupdata = {
                actions: lookupService.getByCategory('Diary Action')
            };

            // smart grid variables
            vm.columns = [
              { label: 'Action', cellTemplate: vm.showHeader ? 'addIconShare.html' : 'addIcon.html', sortable: false },
              { label: 'Created Date', key: 'CreatedDate', formatFunction: 'date' },
              { label: 'Created By ', key: 'CreatedBy' },
              { label: 'Completed Date', key: 'CompletedDate', formatFunction: 'date', visible: !vm.showHeader },
              { label: 'Completed By ', key: 'CompletedBy', visible: !vm.showHeader },
              { label: 'Broker', key: ' Broker' }, ,
              { label: 'Case Name', key: 'CaseName' },
              { label: 'Claim Number', key: 'ClaimNumber' },
              { label: 'Company', key: 'CompanyName' },
              { label: 'Adjuster', key: 'Adjuster' },
              { label: 'Required Action', key: 'ActionReqAsString' },
              { label: 'Follow Up', key: 'FollowUpDate', sortDir: 'desc', formatFunction: 'date' },
              { label: 'Notes', cellTemplate: 'note.html', sortable: false }
              //{
              //    label: 'Notes', key: 'Notes', formatFunction: function (value, formatParameter) {
              //        if (value && value.length > 0) {
              //            var note = Enumerable.from(value).OrderByDescending('$.CreatedDate').firstOrDefault(undefined);
              //            return note.NoteText.length > 20 ? note.NoteText.substring(0, 20) : note.NoteText;
              //        }
              //    }
              //},
            ];

            var events = {
                show: function (row, mode) {
                    row.mode = mode;
                    show(row);
                },
                confirm: confirm,
                archive: archive,
                showcase: function (row) {
                    $state.go('layout.case.chronograph', { Id: row.CaseId });
                }
            };

            // grid configuration
            vm.config = {
                selectionMode: 'none',
                rowNum: 15,
                remoteDataFunction: remoteData,
                filterable: false,
                pagerId: 'pgrDiary',
                events: events
            };
        };

        /*
        * @name search
        * @desc filter grid results
        */
        function search(attr) {
            vm.searchByAttribute = attr;
            vm.config.events.refresh();
        };

        /* 
        * @name remoteData
        * @desc Function to get remote data for the grid
        */
        function remoteData(sortPredicate, sortDir, filter, pageNum, pageSize) {
            var params = {
                sort: sortPredicate == undefined ? 'FollowUpDate' : sortPredicate,
                sortdir: sortDir == undefined ? 'desc' : sortDir,
                pageSize: pageSize,
                pageNum: pageNum,
                filter: {}
            };

            // add filter if exists
            if (vm.searchByAttribute) {
                params.filter[vm.searchByAttribute] = vm.searchBy;
            }

            if (vm.caseId) {
                params.filter['CaseId'] = vm.caseId;
            };
            return diaryService.get(params);
        };

        /* 
        * @name clear
        * @desc clear search bar
        */
        function clear() {
            vm.searchByAttribute = undefined;
            vm.searchBy = undefined;
            vm.config.events.refresh();
        };

        /*
        * @name close
        * @desc close case dialog
        */
        function close() {
            if (vm.diary.close == undefined || vm.diary.close === true) {
                LxDialogService.close(dlgDiary);
            };
            diaryService.close(vm.diary);
        };

        /* 
        * @name save
        * @desc save diary information
        */
        function save() {
            // if called from outside save the auto close flag
            var autoClose = vm.diary.close;

            // add new note
            if (vm.diary.Note != undefined && vm.diary.Note.trim().length > 0) {
                vm.diary.Notes = vm.diary.Notes || [];
                var note = {
                    NoteText: vm.diary.Note.trim(),
                    CreatedDate: new Date(),
                    CreatedBy: accountService.getUserName()
                };
                vm.diary.Notes.push(note);
            }

            // save to db
            diaryService.save(vm.diary).then(function (result) {
                vm.diary = result;
                if (autoClose != undefined) {
                    vm.diary.close = autoClose;
                }
                if (vm.config.events.save) {
                    vm.config.events.save('Id', vm.diary.Id, vm.diary);
                };
                close();
            });
        };

        /*
        * @name add
        * @desc open diary dialog and populate fields. Should be called from outside of diary, i.e. Case Diary page
        */
        function add() {
            var obj = {
                CaseId: vm.case.Id,
                CaseName: vm.case.CaseName,
                ClaimNumber: vm.case.ClaimNumber,
                CompanyId: vm.case.CompanyId,
                AdjusterId: vm.case.AdjusterId,
                BrokerId: vm.case.BrokerId,
                mode: 'add'
            };
            show(obj);
        };

        /*
        * @name show
        * @desc open diary dialog and populate fields
        */
        function show(obj) {
            setData(obj);
            LxDialogService.open(dlgDiary);
        };

        /*
        * @name setData
        * @desc set diary data based on mode
        */
        function setData(data) {
            vm.diary = data;
            if (data.mode == 'add') {
                vm.diary.Id = undefined;
                vm.diary.Notes = undefined;
                vm.diary.Version = undefined;
                vm.diary.CreatedDate = undefined;
                vm.diary.CreatedBy = undefined;
                vm.diary.FollowUpDate = moment().add(30, 'day').format('MM/DD/YYYY');
                vm.diary.ActionReq = lookupService.getDefaultKey('Diary Action');
            };
        };

        /* 
        * @name confirm
        * @desc show archive confirmation dialog.
        */
        function confirm(row) {
            setData(row);
            LxDialogService.open(dlgConfirm);
        };

        /* 
        * @name archive
        * @desc archive diary entry and remove from the grid.
        */
        function archive() {
            diaryService.archive(vm.diary).then(function () {
                // remove row from table and close
                if (vm.config.events.save) {
                    vm.config.events.remove("Id", vm.diary.Id);
                };
                LxDialogService.close(dlgConfirm);
            });
        };
    };
})();