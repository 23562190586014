/**
* @name authInterceptorService
* @desc Response Interceptor, shows a toastr with error/sucess message.
**/
angular
    .module('CareGuard')
    .factory('authInterceptorService', authInterceptorService);

authInterceptorService.$inject = ['$q', '$location', '$injector'];

/* Pending refresh token request */
let currentRefreshPromise = null;

function authInterceptorService($q, $location, $injector) {

    var getExternalAuthService = function () {
        return $injector.get('externalAuthService');  // depends on $http, will create infinite recursion if resolved explicitly
    };

    var getXsrfService = function () {
        return $injector.get('xsrfService');
    }

    function configureHeaders(config) {
        if (config.url.indexOf('.html') == config.url.length - '.html'.length) {
            return config;
        }

        var headers = config.headers;
        var externalAuthService = getExternalAuthService();
        externalAuthService.configureHeaders(headers);
        var xsrfService = getXsrfService();
        xsrfService.configureHeaders(headers);
        return config;
    }

    /* @name responseError.
     * @desc response error pipeline.
     * @param {object} response.
     */
    function responseError(response) {
        const url = response.config.url;
        const isApiCall = url.match(/localhost:2[0-9]{1}00/) || url.match(/ametros-api-service/);

        if (response.status == 401) {
            const $http = $injector.get('$http');
            const config = response.config;

            if (!config.authInterceptor) {
                config.authInterceptor = true;

                return getRefreshPromise()
                    .then(() => $http(config))
                    .catch(() => getExternalAuthService().redirectToIdentityServer());
            } else {
                // recursive call - refresh didn't heal
                return getExternalAuthService().redirectToIdentityServer();
            }
        }
        if (response.status == 403) {   // xsrf failure or role denied
            return getExternalAuthService().redirectToIdentityServer();
        } else if (response.status === 404 && !isApiCall) {
            $location.path('/invalid');
            return $q.reject(response);
        } else if (response.status === 404 && isApiCall) {
            response.data = [];
            return $q.when(response);
        } else {
            return $q.reject(response);
        }
    }

    function getRefreshPromise() {
        if (currentRefreshPromise) return currentRefreshPromise;

        currentRefreshPromise = getExternalAuthService().refreshToken()
            .finally(() => {
                currentRefreshPromise = null;
            });

        return currentRefreshPromise;
    }

    return {
        request: configureHeaders,
        responseError
    };
}